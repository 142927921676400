import "./SolutionPageSecurity.css";
import Img from "../../Image";
import { useState, useEffect } from "react";

const SolutionPageSecurity = ({ heading, content, img }) => {
  const changeRoute = (Route) => {
    window.location.href = Route;
  };
  const [getstartedroute, setgetstartedroute] = useState("/contactus");
  useEffect(() => {
    if (window.location.pathname === "/solutions/NextGenTV") {
      setgetstartedroute("/marketplace/NextGenTV");
    }
  }, []);
  return (
    <div className="secContainerSP1" id="resources">
      <div className="secContentContainerSP">
        <div className="leftdiv">
          <h1>Get in touch with our Certified Solutions Expert</h1>
          <h5>Get expert assistance on Custom Solutions and Enquiries</h5>
          <button
            onClick={() => {
              window.open(
                "https://calendly.com/d/2sn-wnj-xnc/sales-discovery-call?primary_color=a513a7"
              );
            }}
          >
            Speak to an Expert
          </button>
        </div>
      </div>
      <div className="rightdiv">
        <Img
          id="imagesec"
          src={
            "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fsecurity%2F8.webp?alt=media&token=6e0bc2ad-c883-4aad-af21-d23642096320"
          }
        ></Img>
      </div>
    </div>
  );
};

export default SolutionPageSecurity;
