//Product images

//suhas

let shb =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Fsuhaas_banner.webp?alt=media&token=356dbda0-fa5f-4a3b-bcb1-45ebe4e09c9e";
let sh1 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Fsuhaas_feature1.webp?alt=media&token=dd4125ef-936d-4af8-962b-51b56a57e98b";
let sh2 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Fsuhaas_feature2.webp?alt=media&token=78a97574-c813-4497-852b-b503c47e221b";
let sh3 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Fsuhaas_feature3.webp?alt=media&token=132111c5-f212-4a6b-9ddc-e872ec4f5909";

let sh4 = "/aidtaasImages/images/imagesnew/products/suhaas_benefits1.svg";
let sh5 = "/aidtaasImages/images/imagesnew/products/suhaas_benefits2.svg";
let sh6 = "/aidtaasImages/images/imagesnew/products/suhaas_benefits3.svg";

let sh7 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Fizak.webp?alt=media&token=e99edcf9-0ea2-48ef-b883-43244ad446f4";
let sh8 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Fzoombot_case_study.webp?alt=media&token=f1c72498-6919-4927-b665-ba141d99af17";

//dbass
let db1 = "/aidtaasImages/images/imagesnew/products/ImpressIO_banner.png";
let db2 = "/aidtaasImages/images/imagesnew/products/ImpressIO_features1.png";
let db3 = "/aidtaasImages/images/imagesnew/products/ImpressIO_features2.png";
let db4 = "/aidtaasImages/images/imagesnew/products/ImpressIO_features3.png";
let db5 = "/aidtaasImages/images/imagesnew/products/ImpressIO_benefits1.svg";
let db6 = "/aidtaasImages/images/imagesnew/products/ImpressIO_benefits2.svg";
let db7 = "/aidtaasImages/images/imagesnew/products/ImpressIO_benefits3.svg";
let db8 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2FDBaaS.webp?alt=media&token=a4ebc021-078e-4ba1-a1f5-30292d5a6de4";
let t11 = "/aidtaasImages/images/imagesnew/products/311.png";

//live-news

let lv1 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Flive_news%2F1.webp?alt=media&token=7a702cc0-cece-4abd-b726-98c624c193e2";
let lv2 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Flive_news%2F2.webp?alt=media&token=348fec67-0070-474b-90e0-01eee74ec049";
let lv3 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Flive_news%2F3.webp?alt=media&token=647f16d9-6e52-4333-a6eb-238ff4ecd933";
let lv4 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Flive_news%2FGroup%20590.webp?alt=media&token=0468b253-fcdc-4b79-a14e-f81f14d9c67a";
let lv5 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Flive_news%2FGroup%20591.webp?alt=media&token=6b4d6f1d-3db0-4904-bbdd-68602b393866";
let lv6 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Flive_news%2FGroup%20592.webp?alt=media&token=5db6195b-9287-4d04-b65e-a4119af8e513";
let lv7 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Flive_news%2FMask_group.webp?alt=media&token=8064a743-3541-4241-aa98-af3c044e979a";

//fra

let f1 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Ffra%2Fmonitoring%201.webp?alt=media&token=bd29e9a0-6889-4093-8d06-1491bda820d3";
let f2 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Ffra%2Fconnection%201.webp?alt=media&token=e7612410-54d3-4ebd-ac26-e804c25df0f1";
let f3 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Ffra%2Fcollab%201.webp?alt=media&token=e95fef39-0a10-4570-a231-8260d97d3a51";
let f4 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Ffra%2FGroup%20593.webp?alt=media&token=84fa7572-50ee-4617-b1b7-bc41c85ac543";
let f5 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Ffra%2FGroup%20594.webp?alt=media&token=cf69a0f8-d3c6-4248-a8bc-8083d026688e";
let f6 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Ffra%2FGroup%20595.webp?alt=media&token=eae4330d-506a-48b9-84d9-2a0b4a01b23e";
let f7 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2Ffra%2FMask%20group.webp?alt=media&token=53de77db-0ab2-4f6a-8710-a81180f2dd4b";

//updated images
let webinarimage =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2FWebinar%201.webp?alt=media&token=29767083-fbdc-42c9-97f7-9cd5e4ebdde1";
//landingpage-updated images
let piimg =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2FlandingPage_imagesNew%2FPi%20landing%20page.webp?alt=media&token=060025b2-78b6-4323-9c78-a431d3e04f45";
let monetimg =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2FlandingPage_imagesNew%2FMonet%20experience%201.webp?alt=media&token=969c738a-2600-4049-b50c-73375074c2ea";
let bobimg1 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2FlandingPage_imagesNew%2FBoB%20Workflows%20edito4r%20right%20widget%20open03%201.webp?alt=media&token=2c9e4053-8a72-49f6-b4b4-bd9342df41c7";
let bobimg2 =
  "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2Fproducts%2FlandingPage_imagesNew%2FBoB%20Workflows%20editor%20right%20widget%20open03%204.webp?alt=media&token=493d6f94-fceb-4fe5-ba38-101b87aae401";
// eslint-disable-next-line
export default {
  piimg,
  monetimg,
  bobimg1,
  bobimg2,
  webinarimage,

  t11,
  shb,
  sh1,
  sh2,
  sh3,
  sh4,
  sh5,
  sh6,
  sh7,
  sh8,

  db1,
  db2,
  db3,
  db4,
  db5,
  db6,
  db7,
  db8,

  lv1,
  lv2,
  lv3,
  lv4,
  lv5,
  lv6,
  lv7,

  f1,
  f2,
  f3,
  f4,
  f5,
  f6,
  f7,
};
