export const secondHeadingData = {
  secondHeading: {
    text: "Build the next generation of Broadcast Internet experiences",
    text2:
      "Visit us at The ATSC NextGen Broadcast Conference 2024, and discover how the US’ leading broadcasters are working with NexusConnect to make every moment of every day better. Learn just how easy it is to build intelligent apps by leveraging the latest innovations in Deep Tech from Gaian, and drive more innovation and growth for your business, the bricolage way.",
  },

  secondHeading2: {
    text: "Join our Marketplaces",
    text2:
      "Innovate with bricolage partners to create new, ambient experiences.",
  },
};
