import React from "react";
import style from "./index.module.css";
import Img from "../../components/Image";

function PoppedupImage() {
  return (
    <div className={style.poppedContainer}>
      <div className={style.poppedContentCont}>
        <div className={style.poppedLeft}>
          <h1>Build the future of wealth with us</h1>
          <p>
            We are shaping the future of investment - placing the power back in
            the hands of individuals.
          </p>
        </div>
        <div className={style.poppedRight}>
          <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FpopedUpImage%2Frobo.webp?alt=media&token=6ad4e481-bebf-4466-bcb1-f2d5e17cf447"></Img>
        </div>
      </div>
    </div>
  );
}

export default PoppedupImage;
