export const cardsData = {
  ecosystemCards: {
    head: "A snapshot of the SwissBorg ecosystem",
    para: "The following figures show the general growth and health of tissBorg ecosystem. The numbers are updated every hour.",
    cards: [
      {
        imgURL:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FecosystemCards%2FswissBorgEcosystem%2Fcard1.webp?alt=media&token=61122ba6-9360-4058-86c8-bf720ea6d67f",
        number: "57M",
        more: "",
        name: "SwissBorg app's weekly volume",
        moredisplay: "none",
      },
      {
        imgURL:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FecosystemCards%2FswissBorgEcosystem%2Fcard2.webp?alt=media&token=062694a6-71a4-44d8-80dd-9ad2c8fa5843",
        number: "46,063",
        more: "(+399 last 7 days)",
        name: "Premium users",
        moredisplay: "",
      },
      {
        imgURL:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FecosystemCards%2FswissBorgEcosystem%2Fcard3.webp?alt=media&token=7f7338ef-c626-4a8f-8c3c-6994eb081105",
        number: "225.52M",
        more: "(22.55% of circulating supply)",
        name: "BORG locked",
        moredisplay: "",
      },
      {
        imgURL:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FecosystemCards%2FswissBorgEcosystem%2Fcard4.webp?alt=media&token=e66ec66f-4992-42b4-aaee-b51a4417b257",
        number: "5.7/10",
        more: "",
        name: "Community Index",
        moredisplay: "none",
      },
    ],
  },
  borgBuyBack: {
    head: "BORG Buyback performance",
    para: "The following figures show the percentage and the corresponding amount collected from yield and transaction fees reserved for the Buyback mechanism. See transactions",
    cards: [
      {
        imgURL:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FecosystemCards%2FbuyBackPerformance%2Fcard1.webp?alt=media&token=fa72a894-4af0-44f7-a652-0d6ef5ebd9f0",
        number: "70%",
        more: "",
        name: "Percentage of yield fees for Buyback",
        moredisplay: "none",
      },
      {
        imgURL:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FecosystemCards%2FbuyBackPerformance%2Fcard2.webp?alt=media&token=1af34909-20a9-40ab-9c0b-428426ec791f",
        number: "37",
        more: "",
        name: "Total number of transactions to the Buyback address",
        moredisplay: "none",
      },
      {
        imgURL:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FecosystemCards%2FbuyBackPerformance%2Fcard3.webp?alt=media&token=bf3c4236-af56-49a9-b35f-632df9685b01",
        number: "5.6377M",
        more: "",
        name: "Total number of BORG in the Buyback pool",
        moredisplay: "none",
      },
      {
        imgURL:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FecosystemCards%2FbuyBackPerformance%2Fcard4.webp?alt=media&token=23cb8874-fdb3-4bf2-9919-8029079ff72c",
        number: "1,181,488",
        more: "",
        name: "USD equivalent of BORG in the Buyback pool",
        moredisplay: "none",
      },
    ],
  },
};
