import React from "react";
import Modal from "react-modal";
import Img from "../../components/Image";
import st from "./index.module.css";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push } from "firebase/database";

const ModalForm = ({ isOpen, closeModal, handleInputChange, formData }) => {
  const submitForm = async (data) => {
    try {
      // push(ref(db, "nexusConnect/"), {
      //   data,
      // });
      closeModal();
    } catch (error) {
      //console.error('Error submitting form data:', error);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submitForm(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className={st.modalFormStyle}
    >
      <div className={st.modalContainer}>
        <h2 className={st.h11}>
          {" "}
          Get in Touch
          <Img
            src={"/Icons/x2.svg"}
            className={st.svgg}
            onClick={closeModal}
          ></Img>
        </h2>
        <h2 style={{ fontSize: "16px" }}>
          Our team would love to hear from you.
        </h2>
        <br />
        <form onSubmit={onSubmit} className={st.form}>
          <div className={st.first}>
            <label>
              First Name*
              <input
                type="text"
                name="firstname"
                onChange={handleInputChange}
                className={st.inputField}
              />
            </label>
            <label>
              Last Name*
              <input
                type="text"
                name="lastname"
                onChange={handleInputChange}
                className={st.inputField}
              />
            </label>
          </div>

          <br />
          <label>
            Job Title*
            <input
              type="text"
              name="job"
              onChange={handleInputChange}
              className={st.inputField}
            />
          </label>
          <br />
          <br />
          <label>
            Company name*
            <input
              type="text"
              name="company"
              onChange={handleInputChange}
              className={st.inputField}
            />
          </label>
          <br />
          <br />
          <label>
            Business Email*
            <input
              type="email"
              name="email"
              onChange={handleInputChange}
              className={st.inputField}
            />
          </label>
          <br />
          <br />
          <label>
            Phone Number
            <input
              type="tel"
              name="phoneNumber"
              onChange={handleInputChange}
              className={st.inputField}
            />
          </label>
          <br />
          <br />
          <br />
          <button type="submit" className={st.submitButton}>
            Apply Now
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalForm;
