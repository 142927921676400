import React from "react";
import style from "./index.module.css";

function Newsletter() {
  return (
    <div className={style.newsletterContainer}>
      <div className={style.newsletterContentContainer}>
        <div className={style.newsletterTop}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Faddbtn.webp?alt=media&token=d2f1b592-312f-43bc-b6df-ee6a53b57829"
            alt="addbtnimage"
          />
          <p>Proudly engineered in Switzerland</p>
        </div>
        <div className={style.newsletterBottom}>
          <div className={style.newsletterLeft}>
            <div className={style.newsletterLeftPara}>
              <p>Subscribe to our newsletter</p>
              <p>Receive official updates when you join our mailing list</p>
            </div>
            <div className={style.newsletterLeftInputCont}>
              <div className={style.newletterLeftInput}>
                <input placeholder="Name (required)" />
              </div>
              <div className={style.newsletterLeftInputBtn}>
                <input placeholder="Email (required)" />
                <button className={style.newletterLeftBtn}>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Farrow.webp?alt=media&token=5adad41f-dd73-4110-b86e-7bcf92fd93c3"
                    alt="buttonimage"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={style.newsletterRight}>
            <div className={style.newsletterRightPara}>
              <p>Be part of our growing global community</p>
              <p>Follow and subscribe to our channels</p>
            </div>
            <div className={style.newsletterRightSocial}>
              {/* Twitter */}
              <a href="https://twitter.com/Gaian_Solutions" target="_blank">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Ftwitter.webp?alt=media&token=52594266-4031-43af-adb7-29caf5ce7163"
                  alt="twitterimage"
                />
              </a>
              {/* Github */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Fgithub.webp?alt=media&token=67cb6cbb-0f0d-481c-a2d9-c95a914f1e5b"
                alt="githubimage"
              />
              {/* Youtube */}
              <a href="https://www.youtube.com/@Gaiansolutions" target="_blank">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Fyoutube.webp?alt=media&token=71d252f3-97d3-4819-94b3-07756f0d4d35"
                  alt="youtubeimage"
                />
              </a>
              {/* Facebook */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Ffacebook.webp?alt=media&token=973f7976-9ac3-4575-be66-256c8f394d68"
                alt="facebookimage"
              />
              {/* Instagram */}
              <a
                href="https://www.instagram.com/gaiansolutions/"
                target="_blank"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Finstagram.webp?alt=media&token=7aa5ef4f-033e-4ae4-b4da-14da113001d3"
                  alt="instagramimage"
                />
              </a>
              {/* LinkedIn */}
              <a
                href="https://www.linkedin.com/company/mobius-by-gaian/"
                target="_blank"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Flinkedin.webp?alt=media&token=a05c70b8-3e78-4caa-8a27-06db9bded771"
                  alt="linkedinimage"
                />
              </a>
              {/* Telegram */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Ftelegram.webp?alt=media&token=2f1b926a-17e0-43b8-abe6-f7f413eb1303"
                alt="telegramimage"
              />
              {/* Tiktok */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Ftiktok.webp?alt=media&token=82932374-9c12-442e-a897-42b282d9b6e9"
                alt="tiktokimage"
              />
              {/* Message */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FnewsLetter%2Fmessage.webp?alt=media&token=5c2c89f9-858e-4aea-8c7a-3babe9af1845"
                alt="messageimage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;

