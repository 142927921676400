import "./SolutionPagePS.css";

const SolutionPagePS = ({ problemData }) => {
  // //console.log(problemData);
  const changeRoute = (Route) => {
    window.location.href = Route;
  };

  return (
    <div className="psContainer" id="statement">
      <div className="bgimgPS">
        <div className="psContentContainer">
          <div className="leftcont">
            <img src={problemData.imageUrl} alt="psimg" />
          </div>
          <div className="rightcont">
            <h1>{problemData.heading}</h1>
            <p>{problemData.description}</p>
            <button
              onClick={() => {
                window.open(
                  "https://calendly.com/d/2sn-wnj-xnc/sales-discovery-call?primary_color=a513a7"
                );
              }}
            >
              Speak to an Expert
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionPagePS;
