import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Img from "../../components/Image";
import PhoneInput from "react-phone-number-input";
import { ref as dbRef, push } from "firebase/database";
import { database } from "../../db/firebase";
import st from "./index.module.css";
import "react-phone-number-input/style.css";
import emailjs from "@emailjs/browser";

const FormComponent = () => {
  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    job: "",
    company: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const form = useRef();

  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

  const sendEmail = () => {
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (response) => {
        console.log("Email sent successfully:", response.text);
        setSent(true);
      },
      (error) => {
        console.error("Failed to send email:", error.text);
      }
    );
  };

  // EMAIL VALIDATION
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // PHONE NUMBER VALIDATION
  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+[1-9]\d{1,14}$/;
    return regex.test(phoneNumber);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.job) newErrors.job = "Job title is required";
    if (!formData.company) newErrors.company = "Company name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid phone number";
    }
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const submitForm = async () => {
    try {
      const contactRef = dbRef(database, "Contact Us");
      await push(contactRef, formData);
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length === 0) {
      submitForm();
      setSent(true);
      sendEmail();
    } else {
      setErrors(newErrors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
    setErrors({ ...errors, phoneNumber: "" });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className={st.contentContainer}>
      <div className={st.mainCont}>
        <div className={st.imgCont}>
          <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2FContact%2FObject.webp?alt=media&token=e7c7eac9-c1b1-496f-90b4-4194c2d74c98" />
        </div>
        {!sent ? (
          <div className={st.modalContainer}>
            <h2 className={st.h11}>Get in Touch</h2>
            <h2 style={{ fontSize: "16px" }}>
              Our team would love to hear from you.
            </h2>
            <br />
            <form onSubmit={onSubmit} className={st.form} ref={form}>
              <label>
                Name*
                <input
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                  className={st.inputField}
                />
                {errors.name && <span className={st.error}>{errors.name}</span>}
              </label>
              <br />
              <br />
              <label>
                Job Title*
                <input
                  type="text"
                  name="job"
                  onChange={handleInputChange}
                  className={st.inputField}
                />
                {errors.job && <span className={st.error}>{errors.job}</span>}
              </label>
              <br />
              <br />
              <label>
                Company name*
                <input
                  type="text"
                  name="company"
                  onChange={handleInputChange}
                  className={st.inputField}
                />
                {errors.company && (
                  <span className={st.error}>{errors.company}</span>
                )}
              </label>
              <br />
              <br />
              <label>
                Business Email*
                <input
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                  className={st.inputField}
                />
                {errors.email && (
                  <span className={st.error}>{errors.email}</span>
                )}
              </label>
              <br />
              <br />
              <label>
                Phone Number*
                <PhoneInput
                  className={st.inputField}
                  id={st.phinpt}
                  placeholder="Enter phone number"
                  defaultCountry="IN"
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  name="phone"
                />
                {errors.phoneNumber && (
                  <span className={st.error}>{errors.phoneNumber}</span>
                )}
              </label>
              <br />
              <br />
              <label>
                Message*
                <textarea
                  type="text"
                  name="message"
                  onChange={handleInputChange}
                  className={st.inputField}
                  id={st.txtArea}
                />
                {errors.message && (
                  <span className={st.error}>{errors.message}</span>
                )}
              </label>
              <br />
              <br />
              <button type="submit" className={st.submitButton}>
                Send message
              </button>
            </form>
          </div>
        ) : (
          <div className={st.tTemp}>
            <h1>THANK YOU!</h1>
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2FContact%2Fmsg.webp?alt=media&token=70f40232-15c0-4f15-abb2-748fd89ec935" />
            <p>
              Your message has been received <br /> we will contact you very
              soon!!
            </p>
            <button onClick={handleClose}>Continue</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormComponent;
