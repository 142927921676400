export const headnImgData = {
  fiatCrypto: {
    heading: "Go from Fiat to Crypto in an instant!",
    para: "Connect to Binance, HitBTC, LMAX, Kraken, Bitfinex, Orca and Phoenix exchanges and get the best liquidity and rates in seconds.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2FfiatCrypto.webp?alt=media&token=8046a95c-2f1a-4bda-888e-9db5dc8b99cd",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "",
    btnDisplay: "none",
    btnbgColor: "",
    btnTxtColor: "",
  },
  Basket: {
    heading: "More than a basket of tokens",
    para: "Strengthen your investments with the most promising crypto themes.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2Fbasket.webp?alt=media&token=6b6cd554-d8ac-45e9-bb6b-85777c7ca0f0",
    bgColor: "linear-gradient(180deg, #004A81 0%, #007CD7 100%)",
    headParaTxtColor: "#FFF",
    btnctn: "Get Started",
    btnDisplay: "flex",
    btnbgColor: "#FFA200",
    btnTxtColor: "#FFF",
  },
  appAssets: {
    heading: "SwissBorg app supported assets",
    para: "The SwissBorg app provides support for different cryptocurrencies. Watch this space for updates as we strive to bring you more listings.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2FappAsset.webp?alt=media&token=6ba6eb88-d87e-4c68-a642-c33025085c94",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "",
    btnDisplay: "none",
    btnbgColor: "",
    btnTxtColor: "",
  },
  accountAnalysis: {
    heading: "Useful Account Analysis at the tap of a finger",
    para: "Portfolio Visualizer, a free tool, provides in-depth investment analysis and statistics in an easy-to-understand format.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2FaccountAnalysis.webp?alt=media&token=b1f7a5c5-a5bd-4609-bb88-e8cb164642fa",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "",
    btnDisplay: "none",
    btnbgColor: "",
    btnTxtColor: "",
  },
  swissBorgEarn: {
    heading: "Earn crypto your way with SwissBorg Earn",
    para: "Investors aren’t one-size-fits-all, so why is your yield? Generate the right yield for you with SwissBorg Earn.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2Fswissborgearn.webp?alt=media&token=ca99badb-751d-4996-b6ed-dc2135b65781",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "Notify Me",
    btnDisplay: "flex",
    btnbgColor: "#01C38D",
    btnTxtColor: "#FFF",
  },
  topUp: {
    heading: "Top Up and invest in seconds!",
    para: "You can now fund your account using your card and start buying cryptos. It’s fast, secure and EASY!",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2Ftopup.webp?alt=media&token=0766afe9-f2c2-4312-b6fe-fb9bc1a54a70",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "Notify Me",
    btnDisplay: "flex",
    btnbgColor: "#01C38D",
    btnTxtColor: "#FFF",
  },
  smartInvestment: {
    heading: "Make smart investment decisions with the help of AI",
    para: "Automated crypto asset analysis will boost your investment intelligence.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2FsmartInvestment.webp?alt=media&token=f9631db2-0c16-4a48-966c-b0aa8148256e",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "",
    btnDisplay: "none",
    btnbgColor: "",
    btnTxtColor: "",
  },
  autoInvest: {
    heading: "Automate your investments",
    para: "Wealth building requires patience, knowledge and discipline. What if all it took was just one good start? Say goodbye to worries! Unlock your wealth potential with Auto- Invest today.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2FautoInvest.webp?alt=media&token=ed6e20a5-eac4-4923-8cd7-d8fd300e0b1a",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "Get Started",
    btnDisplay: "flex",
    btnbgColor: "#FFA200",
    btnTxtColor: "#FFF",
  },
  heroes: {
    heading: "SwissBorg Heroes",
    para: "Conquer market cycles on your wealth management journey with SwissBorg and unlock exclusive rewards along the way",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2Fheroes.webp?alt=media&token=92f6b5ca-88c8-4930-88f9-962f8020fe65",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "Go to Hall of Fame",
    btnDisplay: "flex",
    btnbgColor: "#007CD7",
    btnTxtColor: "#FFF",
  },
  borg: {
    heading: "BORG is here",
    para: "With SwissBorg, you have the opportunity not only to be a user but to become a real contributor by holding BORG tokens and benefiting from the growth of the ecosystem.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2FBORG.webp?alt=media&token=cedbb74a-8cc6-4ff2-b85f-3a8420bc5f4b",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "Go to Hall of Fame",
    btnDisplay: "flex",
    btnbgColor: "#007CD7",
    btnTxtColor: "#FFF",
  },
  zeroFees: {
    heading: "Zero fees, zero time, zero borders!",
    para: "Send cryptos easily, no matter the coin or the occasion, with 0 gas fees, in an instant. The Smart Send feature works with all the crypto assets in our app and for users in over 115 countries.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2Fglobe.webp?alt=media&token=8d3f6529-23ab-441f-adc9-a86c3b12e036",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "",
    btnDisplay: "none",
    btnbgColor: "#007CD7",
    btnTxtColor: "#FFF",
  },
  unlock: {
    heading: "Unlock unique benefits with Premium",
    para: "Boost your yield and lower your fees for just 500 BORG",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2Funlock.webp?alt=media&token=6946ace3-48b2-47d0-9406-635d63e6e6eb",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "How to upgrade",
    btnDisplay: "flex",
    btnbgColor: "#007CD7",
    btnTxtColor: "#FFF",
  },
  secure: {
    heading: "Secure with SwissBorg",
    para: "By prioritising transparency, SwissBorg enables every community member to verify that their custodied assets have been  accounted for and included in the company’s total users liabilities.",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FHeadnimg%2Fsecure.webp?alt=media&token=78e8c22d-86d5-4e0a-9fe7-1459368bb7a2",
    bgColor: "none",
    headParaTxtColor: "#4C4C4C",
    btnctn: "Validate my balance",
    btnDisplay: "flex",
    btnbgColor: "#007CD7",
    btnTxtColor: "#FFF",
  },
};
