import React from "react";
import style from "./index.module.css";
import Img from "../../components/Image";

function AdvisoryBoard() {
  return (
    <div className={style.advisoryContainer}>
      <div className={style.advisoryContentCont}>
        <div className={style.advisoryTop}>
          <div className={style.advisoryTopImg}>
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2FadvisoryImg.webp?alt=media&token=b4b5ff49-f697-4d5e-b64b-3cfa1bfea11c"></Img>
          </div>
          <div className={style.advisoryTopContent}>
            <h1>A global force with a proven track record</h1>
            <p>
              We started our journey with a team of 9 and a successful ICO in
              2018. We are now a team of more <br />
              <span>
                than 200 committed to create the bank of the future. Empowered
                by our diversity and passion, we will keep going for years to
                come.
              </span>
            </p>
          </div>
        </div>
        <div className={style.advisoryBottom}>
          <div className={style.advisoryBottomHeader}>
            <h1>Senior Advisors to the Board</h1>
          </div>
          <div className={style.advisoryBottomContentCont}>
            <div className={style.advisoryBottomContent}>
              <div className={style.advisoryBottomImg}>
                <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Fprofile.webp?alt=media&token=7ed48d68-efec-41d3-9ff7-d2f2b792bfb1"></Img>
              </div>
              <div className={style.advisoryBottomName}>
                <h1>Lorem Ipsum </h1>
                <p>View profile </p>
              </div>
            </div>
            <div className={style.advisoryBottomContent}>
              <div className={style.advisoryBottomImg}>
                <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Fprofile.webp?alt=media&token=7ed48d68-efec-41d3-9ff7-d2f2b792bfb1"></Img>
              </div>
              <div className={style.advisoryBottomName}>
                <h1>Lorem Ipsum</h1>
                <p>View profile</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvisoryBoard;
