import React, { useRef, useState } from "react";
import style from "./index.module.css";
import { ref as dbRef, push } from "firebase/database";
import { database } from "../../db/firebase";
import emailjs from "@emailjs/browser";

const WhereToFind = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const form = useRef();

  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID1;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

  const sendEmail = () => {
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (response) => {
        console.log("Email sent successfully:", response.text);
        setSubmitted(true);
      },
      (error) => {
        console.error("Failed to send email:", error.text);
      }
    );
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+?[1-9]\d{1,14}$/;
    return regex.test(phoneNumber);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid phone number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const eventRef = dbRef(
          database,
          "ATSC NextGen Broadcast Conference 2024"
        );
        await push(eventRef, formData);
      } catch (error) {
        console.error("Error submitting form data:", error);
      }
      console.log("Form data submitted successfully:", formData);
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
      });
      setSubmitted(true);
      sendEmail();
    }
  };

  return (
    <div className={style.main}>
      <div className={style.internal}>
        <h1 className={style.heading}>
          Sign up to receive exclusive ATSC NextGen Broadcasting Insights
        </h1>
        <h1 className={style.subheading}>
          Get updates on the future of ambient computing and AI directly in your
          inbox
        </h1>
        {!submitted ? (
          <div className={style.inputs}>
            <form onSubmit={onSubmit} ref={form}>
              <label className={style.subheading}>
                <input
                  type="text"
                  name="name"
                  placeholder="Full name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={style.input1}
                />
                {errors.name && <p className={style.error}>{errors.name}</p>}
              </label>
              <br />
              <label className={style.subheading}>
                <input
                  type="email"
                  name="email"
                  placeholder="Business Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={style.input1}
                />
                {errors.email && <p className={style.error}>{errors.email}</p>}
              </label>
              <br />
              <label className={style.subheading}>
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone number"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  className={style.input1}
                />
                {errors.phoneNumber && (
                  <p className={style.error}>{errors.phoneNumber}</p>
                )}
              </label>
              <br />
              <button type="submit" className={style.submitButton}>
                Apply Now
              </button>
            </form>
          </div>
        ) : (
          <div>
            <h1 className={style.heading}>Your response has been recorded!</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default WhereToFind;
