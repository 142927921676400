export const mobileAndTextData = {
  mobileData1: {
    mobileText1: {
      heading: "Diversify like the pros injust 3 steps. Start now!",
      description: {
        desc1:
          " In the Invest tab in the SwissBorg app look for the Thematics product.",
        desc2:
          "Head to the Thematics overview screen and choose your Thematic.",
        desc3:
          "Invest your desired amount & watch the full power of Thematics strengthen your portfolio.",
      },
      images: {
        img1: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fappstore.webp?alt=media&token=520be866-b51f-41fb-87c3-281f3213c854",
        img2: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fgooglestore.webp?alt=media&token=d8a99fa1-129f-417f-a9c8-89810425a08f",
        img3: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fscanner.webp?alt=media&token=35f03a5c-0459-4f14-879e-751fd7083a95",
      },
      mobImg:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fmobileimage.webp?alt=media&token=ec4ac3e1-a26e-4ba7-b039-79e013e46419",
    },
  },
  mobileData2: {
    mobileText2: {
      heading: "Use your card, add funds,and start investing in minutes!",
      description: {
        desc1: " Tap Deposit button on the main screen",
        desc2: "Choose add card",
        desc3: "Provide card details",
        desc4: "Select the currency and enter amount",
        desc5: "Authenticate payment with 3D Secure",
      },
      images: {
        img1: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fappstore.webp?alt=media&token=520be866-b51f-41fb-87c3-281f3213c854",
        img2: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fgooglestore.webp?alt=media&token=d8a99fa1-129f-417f-a9c8-89810425a08f",
        img3: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fscanner.webp?alt=media&token=35f03a5c-0459-4f14-879e-751fd7083a95",
      },
      mobImg:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fmobileimage1.webp?alt=media&token=2a4cf872-804e-419a-a2dd-e1747161edd7",
    },
  },
  mobileData3: {
    mobileText3: {
      heading: "Start earning now with SwissBorg Earn",
      description: {
        desc1: " Choose the asset and strategy that suits you.",
        desc2:
          "Enter the amount and accept the terms of use by swiping the button.",
        desc3:
          "Check to see you’ve entered the correct sum, click top up and start earning.",
      },
      images: {
        img1: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fappstore.webp?alt=media&token=520be866-b51f-41fb-87c3-281f3213c854",
        img2: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fgooglestore.webp?alt=media&token=d8a99fa1-129f-417f-a9c8-89810425a08f",
        img3: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fscanner.webp?alt=media&token=35f03a5c-0459-4f14-879e-751fd7083a95",
      },
      mobImg:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fmobileimage2.webp?alt=media&token=ec0dfd26-ed4b-4668-879d-e9c6e56c9331",
    },
  },
  mobileData4: {
    mobileText4: {
      heading: "Automate your crypto investments with SwissBorg ",
      description: {
        desc1: "Choose the asset you want to invest in",
        desc2: "Select the frequency of your investments",
        desc3: "Choose the source of funds account",
        desc4: "Input the recurrent amount",
        desc5: "Confirm. Done!",
      },
      images: {
        img1: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fappstore.webp?alt=media&token=520be866-b51f-41fb-87c3-281f3213c854",
        img2: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fgooglestore.webp?alt=media&token=d8a99fa1-129f-417f-a9c8-89810425a08f",
        img3: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fscanner.webp?alt=media&token=35f03a5c-0459-4f14-879e-751fd7083a95",
      },
      mobImg:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fscanner.webp?alt=media&token=35f03a5c-0459-4f14-879e-751fd7083a95",
    },
  },
  mobileData5: {
    mobileText5: {
      heading: "How to transfer your favourite cryptos with Smart Send? ",
      description: {
        desc1: "Tap on the coin you want to send.",
        desc2:
          "Find the recipient in your contact list or type in their phone number and enter the amount you want to send.",
        desc3: "Tap on Send, and the transfer will be done in an instant.",
      },
      images: {
        img1: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fappstore.webp?alt=media&token=520be866-b51f-41fb-87c3-281f3213c854",
        img2: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fgooglestore.webp?alt=media&token=d8a99fa1-129f-417f-a9c8-89810425a08f",
        img3: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fscanner.webp?alt=media&token=35f03a5c-0459-4f14-879e-751fd7083a95",
      },
      mobImg:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmobileAndText%2Fscanner.webp?alt=media&token=35f03a5c-0459-4f14-879e-751fd7083a95",
    },
  },
};
