import st from "./index.module.css";
import Img from "../../components/Image";
import ChartComponent from "./linechart";
import ChartComponent2 from "./barchart";
import ChartComponent6 from "./yearlyChart3";
import ChartComponent3 from "./lineSeriesdata";
import aggregateDataForDMAsAndPlatforms from "./logic";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import jsonData from "./data";

const data = [
  {
    DMA: "NEW YORK",
    "DMA Annual City Budget": 107008271000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 90957030.35,
    "% of cLINKAllocation to Nexus": 909570.3034999999,
    "Per Month": 75797.52529166666,
    YEAR_1: 702071.3945180003,
    YEAR_2: 975882.5264766676,
    YEAR_3: 1021353.7650892396,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 76744.84276276191,
    M5: 77060.615253127,
    M6: 77376.38774349209,
    M7: 77692.16023385717,
    M8: 78007.93272422225,
    M9: 78323.70521458733,
    M10: 78639.47770495241,
    M11: 78955.2501953175,
    M12: 79271.02268568258,
    M13: 79586.79517604767,
    M14: 79902.56766641275,
    M15: 80218.34015677783,
    M16: 80534.11264714292,
    M17: 80849.885137508,
    M18: 81165.65762787308,
    M19: 81481.43011823816,
    M20: 81797.20260860324,
    M21: 82112.97509896834,
    M22: 82428.74758933342,
    M23: 82744.5200796985,
    M24: 83060.29257006358,
    M25: 83376.06506042866,
    M26: 83691.83755079375,
    M27: 84007.61004115883,
    M28: 84323.38253152391,
    M29: 84639.155021889,
    M30: 84954.92751225409,
    M31: 85270.70000261917,
    M32: 85586.47249298425,
    M33: 85902.24498334933,
    M34: 86218.01747371441,
    M35: 86533.7899640795,
    M36: 86849.56245444459,
  },
  {
    DMA: "Los Angeles",
    "DMA Annual City Budget": 11800000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 10030000,
    "% of cLINKAllocation to Nexus": 100300,
    "Per Month": 8358.333333333334,
    YEAR_1: 77418.71145,
    YEAR_2: 107612.37150000001,
    YEAR_3: 112626.56910000002,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 8462.795783333335,
    M5: 8497.616600000001,
    M6: 8532.437416666668,
    M7: 8567.258233333334,
    M8: 8602.07905,
    M9: 8636.899866666667,
    M10: 8671.720683333335,
    M11: 8706.541500000001,
    M12: 8741.362316666668,
    M13: 8776.183133333334,
    M14: 8811.00395,
    M15: 8845.824766666667,
    M16: 8880.645583333335,
    M17: 8915.466400000001,
    M18: 8950.287216666668,
    M19: 8985.108033333334,
    M20: 9019.92885,
    M21: 9054.749666666667,
    M22: 9089.570483333335,
    M23: 9124.391300000001,
    M24: 9159.212116666667,
    M25: 9194.032933333334,
    M26: 9228.85375,
    M27: 9263.674566666668,
    M28: 9298.495383333335,
    M29: 9333.316200000001,
    M30: 9368.137016666667,
    M31: 9402.957833333334,
    M32: 9437.77865,
    M33: 9472.599466666667,
    M34: 9507.420283333335,
    M35: 9542.241100000001,
    M36: 9577.061916666667,
  },
  {
    DMA: "Chicago",
    "DMA Annual City Budget": 16400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 13940000,
    "% of cLINKAllocation to Nexus": 139400,
    "Per Month": 11616.666666666666,
    YEAR_1: 107598.88709999999,
    YEAR_2: 149562.95700000005,
    YEAR_3: 156531.8418,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 11761.851766666667,
    M5: 11810.246799999999,
    M6: 11858.641833333333,
    M7: 11907.036866666665,
    M8: 11955.4319,
    M9: 12003.826933333332,
    M10: 12052.221966666666,
    M11: 12100.617,
    M12: 12149.012033333333,
    M13: 12197.407066666667,
    M14: 12245.802099999999,
    M15: 12294.197133333333,
    M16: 12342.592166666665,
    M17: 12390.9872,
    M18: 12439.382233333334,
    M19: 12487.777266666666,
    M20: 12536.1723,
    M21: 12584.567333333332,
    M22: 12632.962366666667,
    M23: 12681.357399999999,
    M24: 12729.752433333333,
    M25: 12778.147466666665,
    M26: 12826.5425,
    M27: 12874.937533333334,
    M28: 12923.332566666666,
    M29: 12971.7276,
    M30: 13020.122633333332,
    M31: 13068.517666666667,
    M32: 13116.912699999999,
    M33: 13165.307733333333,
    M34: 13213.702766666665,
    M35: 13262.0978,
    M36: 13310.492833333334,
  },
  {
    DMA: "Philadelphia",
    "DMA Annual City Budget": 5300000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 4505000,
    "% of cLINKAllocation to Nexus": 45050,
    "Per Month": 3754.1666666666665,
    YEAR_1: 34772.811075,
    YEAR_2: 48334.37024999999,
    YEAR_3: 50586.50985,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 3801.0862416666664,
    M5: 3816.7261,
    M6: 3832.3659583333333,
    M7: 3848.0058166666663,
    M8: 3863.6456749999998,
    M9: 3879.285533333333,
    M10: 3894.9253916666667,
    M11: 3910.5652499999997,
    M12: 3926.205108333333,
    M13: 3941.8449666666666,
    M14: 3957.484825,
    M15: 3973.124683333333,
    M16: 3988.7645416666664,
    M17: 4004.4044,
    M18: 4020.0442583333333,
    M19: 4035.6841166666663,
    M20: 4051.323975,
    M21: 4066.9638333333332,
    M22: 4082.6036916666667,
    M23: 4098.24355,
    M24: 4113.883408333333,
    M25: 4129.523266666666,
    M26: 4145.163125,
    M27: 4160.802983333333,
    M28: 4176.442841666667,
    M29: 4192.0827,
    M30: 4207.722558333333,
    M31: 4223.362416666667,
    M32: 4239.002275,
    M33: 4254.642133333333,
    M34: 4270.281991666667,
    M35: 4285.92185,
    M36: 4301.561708333333,
  },
  {
    DMA: "Dallas-Ft. Worth",
    "DMA Annual City Budget": 6938786440,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 5897968.473999999,
    "% of cLINKAllocation to Nexus": 58979.68474,
    "Per Month": 4914.973728333333,
    YEAR_1: 45524.73772979091,
    YEAR_2: 63279.5986559697,
    YEAR_3: 66228.11105549178,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 4976.401069990044,
    M5: 4996.87685054228,
    M6: 5017.352631094517,
    M7: 5037.828411646753,
    M8: 5058.30419219899,
    M9: 5078.7799727512265,
    M10: 5099.255753303463,
    M11: 5119.7315338557,
    M12: 5140.207314407937,
    M13: 5160.683094960174,
    M14: 5181.15887551241,
    M15: 5201.634656064647,
    M16: 5222.110436616884,
    M17: 5242.58621716912,
    M18: 5263.061997721356,
    M19: 5283.537778273593,
    M20: 5304.01355882583,
    M21: 5324.489339378067,
    M22: 5344.965119930303,
    M23: 5365.44090048254,
    M24: 5385.916681034777,
    M25: 5406.392461587014,
    M26: 5426.8682421392505,
    M27: 5447.344022691486,
    M28: 5467.819803243723,
    M29: 5488.29558379596,
    M30: 5508.771364348197,
    M31: 5529.247144900433,
    M32: 5549.72292545267,
    M33: 5570.198706004907,
    M34: 5590.674486557144,
    M35: 5611.15026710938,
    M36: 5631.626047661617,
  },
  {
    DMA: "Atlanta",
    "DMA Annual City Budget": 2280000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1938000,
    "% of cLINKAllocation to Nexus": 19380,
    "Per Month": 1615,
    YEAR_1: 14958.86967,
    YEAR_2: 20792.898900000004,
    YEAR_3: 21761.74386,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 1635.18427,
    M5: 1641.91236,
    M6: 1648.64045,
    M7: 1655.36854,
    M8: 1662.09663,
    M9: 1668.82472,
    M10: 1675.55281,
    M11: 1682.2809,
    M12: 1689.00899,
    M13: 1695.73708,
    M14: 1702.46517,
    M15: 1709.19326,
    M16: 1715.92135,
    M17: 1722.64944,
    M18: 1729.37753,
    M19: 1736.10562,
    M20: 1742.83371,
    M21: 1749.5618,
    M22: 1756.28989,
    M23: 1763.01798,
    M24: 1769.7460700000001,
    M25: 1776.47416,
    M26: 1783.20225,
    M27: 1789.9303400000001,
    M28: 1796.65843,
    M29: 1803.38652,
    M30: 1810.11461,
    M31: 1816.8427000000001,
    M32: 1823.57079,
    M33: 1830.29888,
    M34: 1837.0269700000001,
    M35: 1843.75506,
    M36: 1850.48315,
  },
  {
    DMA: "Houston",
    "DMA Annual City Budget": 6243926515,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 5307337.53775,
    "% of cLINKAllocation to Nexus": 53073.3753775,
    "Per Month": 4422.781281458333,
    YEAR_1: 40965.82586269399,
    YEAR_2: 56942.68980939664,
    YEAR_3: 59595.93399126862,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 4478.057201913999,
    M5: 4496.482508732554,
    M6: 4514.90781555111,
    M7: 4533.333122369666,
    M8: 4551.758429188221,
    M9: 4570.183736006777,
    M10: 4588.609042825332,
    M11: 4607.034349643887,
    M12: 4625.459656462443,
    M13: 4643.884963280998,
    M14: 4662.310270099553,
    M15: 4680.735576918109,
    M16: 4699.160883736664,
    M17: 4717.58619055522,
    M18: 4736.0114973737755,
    M19: 4754.436804192331,
    M20: 4772.862111010886,
    M21: 4791.287417829441,
    M22: 4809.712724647997,
    M23: 4828.138031466552,
    M24: 4846.563338285107,
    M25: 4864.988645103663,
    M26: 4883.413951922218,
    M27: 4901.839258740774,
    M28: 4920.26456555933,
    M29: 4938.689872377885,
    M30: 4957.11517919644,
    M31: 4975.540486014996,
    M32: 4993.965792833551,
    M33: 5012.391099652106,
    M34: 5030.816406470662,
    M35: 5049.241713289217,
    M36: 5067.667020107772,
  },
  {
    DMA: "Washington-Hagerstown",
    "DMA Annual City Budget": 10759066000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 9145206.1,
    "% of cLINKAllocation to Nexus": 91452.061,
    "Per Month": 7621.0050833333335,
    YEAR_1: 70589.23950216151,
    YEAR_2: 98119.37350720499,
    YEAR_3: 102691.24494071699,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 7716.252404864834,
    M5: 7748.001512042,
    M6: 7779.750619219167,
    M7: 7811.499726396333,
    M8: 7843.2488335735,
    M9: 7874.997940750667,
    M10: 7906.747047927834,
    M11: 7938.496155105,
    M12: 7970.245262282167,
    M13: 8001.994369459333,
    M14: 8033.7434766365,
    M15: 8065.492583813667,
    M16: 8097.241690990833,
    M17: 8128.990798168,
    M18: 8160.739905345167,
    M19: 8192.489012522334,
    M20: 8224.238119699501,
    M21: 8255.987226876667,
    M22: 8287.736334053834,
    M23: 8319.485441231,
    M24: 8351.234548408167,
    M25: 8382.983655585333,
    M26: 8414.7327627625,
    M27: 8446.481869939667,
    M28: 8478.230977116833,
    M29: 8509.980084294,
    M30: 8541.729191471168,
    M31: 8573.478298648333,
    M32: 8605.2274058255,
    M33: 8636.976513002666,
    M34: 8668.725620179834,
    M35: 8700.474727357001,
    M36: 8732.223834534167,
  },
  {
    DMA: "Boston-Manchester",
    "DMA Annual City Budget": 9500000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 8075000,
    "% of cLINKAllocation to Nexus": 80750,
    "Per Month": 6729.166666666667,
    YEAR_1: 62328.62362499999,
    YEAR_2: 86637.07875000002,
    YEAR_3: 90673.93275,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 6813.267791666667,
    M5: 6841.3015000000005,
    M6: 6869.335208333334,
    M7: 6897.368916666667,
    M8: 6925.402625000001,
    M9: 6953.436333333334,
    M10: 6981.470041666667,
    M11: 7009.50375,
    M12: 7037.537458333334,
    M13: 7065.571166666667,
    M14: 7093.604875,
    M15: 7121.638583333333,
    M16: 7149.672291666667,
    M17: 7177.706,
    M18: 7205.7397083333335,
    M19: 7233.773416666667,
    M20: 7261.807125,
    M21: 7289.840833333334,
    M22: 7317.874541666667,
    M23: 7345.90825,
    M24: 7373.941958333334,
    M25: 7401.975666666667,
    M26: 7430.009375000001,
    M27: 7458.043083333334,
    M28: 7486.076791666667,
    M29: 7514.110500000001,
    M30: 7542.144208333334,
    M31: 7570.177916666667,
    M32: 7598.211625,
    M33: 7626.245333333334,
    M34: 7654.279041666667,
    M35: 7682.31275,
    M36: 7710.346458333333,
  },
  {
    DMA: "San Francisco-Oakland-San Jose",
    "DMA Annual City Budget": 28675495435,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 24374171.119749997,
    "% of cLINKAllocation to Nexus": 243741.71119749997,
    "Per Month": 20311.80926645833,
    YEAR_1: 188137.28023458112,
    YEAR_2: 261511.7006523537,
    YEAR_3: 273696.83627853915,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 20565.666258670528,
    M5: 20650.285256074592,
    M6: 20734.904253478657,
    M7: 20819.523250882725,
    M8: 20904.14224828679,
    M9: 20988.761245690854,
    M10: 21073.380243094918,
    M11: 21157.999240498986,
    M12: 21242.61823790305,
    M13: 21327.237235307115,
    M14: 21411.85623271118,
    M15: 21496.475230115248,
    M16: 21581.094227519312,
    M17: 21665.713224923376,
    M18: 21750.332222327444,
    M19: 21834.95121973151,
    M20: 21919.570217135573,
    M21: 22004.189214539638,
    M22: 22088.808211943706,
    M23: 22173.42720934777,
    M24: 22258.046206751835,
    M25: 22342.6652041559,
    M26: 22427.284201559967,
    M27: 22511.90319896403,
    M28: 22596.522196368096,
    M29: 22681.141193772164,
    M30: 22765.76019117623,
    M31: 22850.379188580293,
    M32: 22934.998185984357,
    M33: 23019.617183388425,
    M34: 23104.23618079249,
    M35: 23188.855178196554,
    M36: 23273.47417560062,
  },
  {
    DMA: "Phoenix-Prescott",
    "DMA Annual City Budget": 1638402000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1392641.7,
    "% of cLINKAllocation to Nexus": 13926.417,
    "Per Month": 1160.53475,
    YEAR_1: 10749.4043794155,
    YEAR_2: 14941.722431384998,
    YEAR_3: 15637.931870049,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 1175.0391133055,
    M5: 1179.873901074,
    M6: 1184.7086888425001,
    M7: 1189.543476611,
    M8: 1194.3782643795,
    M9: 1199.213052148,
    M10: 1204.0478399165,
    M11: 1208.882627685,
    M12: 1213.7174154535,
    M13: 1218.5522032220001,
    M14: 1223.3869909905,
    M15: 1228.221778759,
    M16: 1233.0565665275,
    M17: 1237.8913542960001,
    M18: 1242.7261420645,
    M19: 1247.560929833,
    M20: 1252.3957176015,
    M21: 1257.23050537,
    M22: 1262.0652931385,
    M23: 1266.900080907,
    M24: 1271.7348686755001,
    M25: 1276.569656444,
    M26: 1281.4044442125,
    M27: 1286.239231981,
    M28: 1291.0740197495002,
    M29: 1295.908807518,
    M30: 1300.7435952865,
    M31: 1305.5783830550001,
    M32: 1310.4131708235,
    M33: 1315.247958592,
    M34: 1320.0827463605,
    M35: 1324.9175341290002,
    M36: 1329.7523218975,
  },
  {
    DMA: "Seattle-Tacoma",
    "DMA Annual City Budget": 2116013047,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1798611.08995,
    "% of cLINKAllocation to Nexus": 17986.1108995,
    "Per Month": 1498.8425749583332,
    YEAR_1: 13882.966399163413,
    YEAR_2: 19297.388314628046,
    YEAR_3: 20196.549970715852,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 1517.5751094601624,
    M5: 1523.8192876274388,
    M6: 1530.0634657947153,
    M7: 1536.3076439619917,
    M8: 1542.5518221292682,
    M9: 1548.7960002965447,
    M10: 1555.040178463821,
    M11: 1561.2843566310974,
    M12: 1567.5285347983738,
    M13: 1573.7727129656503,
    M14: 1580.0168911329267,
    M15: 1586.261069300203,
    M16: 1592.5052474674794,
    M17: 1598.7494256347559,
    M18: 1604.9936038020323,
    M19: 1611.2377819693088,
    M20: 1617.481960136585,
    M21: 1623.7261383038615,
    M22: 1629.970316471138,
    M23: 1636.2144946384144,
    M24: 1642.4586728056909,
    M25: 1648.702850972967,
    M26: 1654.9470291402436,
    M27: 1661.19120730752,
    M28: 1667.4353854747965,
    M29: 1673.679563642073,
    M30: 1679.9237418093494,
    M31: 1686.1679199766256,
    M32: 1692.412098143902,
    M33: 1698.6562763111785,
    M34: 1704.900454478455,
    M35: 1711.1446326457315,
    M36: 1717.3888108130077,
  },
  {
    DMA: "Tampa-St Petersburg-Sarasota",
    "DMA Annual City Budget": 7400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 6290000,
    "% of cLINKAllocation to Nexus": 62900,
    "Per Month": 5241.666666666667,
    YEAR_1: 48550.717350000006,
    YEAR_2: 67485.7245,
    YEAR_3: 70630.2213,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 5307.1770166666665,
    M5: 5329.013800000001,
    M6: 5350.850583333334,
    M7: 5372.687366666667,
    M8: 5394.52415,
    M9: 5416.360933333333,
    M10: 5438.197716666667,
    M11: 5460.034500000001,
    M12: 5481.871283333334,
    M13: 5503.708066666667,
    M14: 5525.54485,
    M15: 5547.381633333333,
    M16: 5569.218416666667,
    M17: 5591.055200000001,
    M18: 5612.891983333334,
    M19: 5634.728766666667,
    M20: 5656.56555,
    M21: 5678.402333333333,
    M22: 5700.239116666667,
    M23: 5722.075900000001,
    M24: 5743.912683333334,
    M25: 5765.749466666667,
    M26: 5787.58625,
    M27: 5809.4230333333335,
    M28: 5831.259816666667,
    M29: 5853.096600000001,
    M30: 5874.933383333334,
    M31: 5896.770166666667,
    M32: 5918.60695,
    M33: 5940.4437333333335,
    M34: 5962.280516666667,
    M35: 5984.117300000001,
    M36: 6005.954083333334,
  },
  {
    DMA: "Detroit",
    "DMA Annual City Budget": 2453276906,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2085285.3701,
    "% of cLINKAllocation to Nexus": 20852.853701,
    "Per Month": 1737.7378084166667,
    YEAR_1: 16095.723465471421,
    YEAR_2: 22373.131000071404,
    YEAR_3: 23415.606862291796,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 1759.456055546258,
    M5: 1766.695471256122,
    M6: 1773.934886965986,
    M7: 1781.1743026758497,
    M8: 1788.4137183857135,
    M9: 1795.6531340955773,
    M10: 1802.8925498054411,
    M11: 1810.131965515305,
    M12: 1817.3713812251688,
    M13: 1824.6107969350326,
    M14: 1831.8502126448966,
    M15: 1839.0896283547604,
    M16: 1846.3290440646242,
    M17: 1853.568459774488,
    M18: 1860.8078754843518,
    M19: 1868.0472911942156,
    M20: 1875.2867069040794,
    M21: 1882.5261226139432,
    M22: 1889.765538323807,
    M23: 1897.004954033671,
    M24: 1904.244369743535,
    M25: 1911.4837854533987,
    M26: 1918.7232011632625,
    M27: 1925.9626168731263,
    M28: 1933.2020325829901,
    M29: 1940.441448292854,
    M30: 1947.680864002718,
    M31: 1954.9202797125818,
    M32: 1962.1596954224456,
    M33: 1969.3991111323094,
    M34: 1976.6385268421732,
    M35: 1983.877942552037,
    M36: 1991.1173582619008,
  },
  {
    DMA: "Minneapolis-Saint Paul",
    "DMA Annual City Budget": 830326934,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 705777.8938999999,
    "% of cLINKAllocation to Nexus": 7057.778938999999,
    "Per Month": 588.1482449166666,
    YEAR_1: 5447.698416314338,
    YEAR_2: 7572.326312547794,
    YEAR_3: 7925.158797266282,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 595.4989216816351,
    M5: 597.949147269958,
    M6: 600.3993728582808,
    M7: 602.8495984466036,
    M8: 605.2998240349265,
    M9: 607.7500496232493,
    M10: 610.2002752115721,
    M11: 612.650500799895,
    M12: 615.1007263882178,
    M13: 617.5509519765407,
    M14: 620.0011775648635,
    M15: 622.4514031531862,
    M16: 624.9016287415091,
    M17: 627.3518543298319,
    M18: 629.8020799181547,
    M19: 632.2523055064776,
    M20: 634.7025310948004,
    M21: 637.1527566831232,
    M22: 639.6029822714461,
    M23: 642.0532078597689,
    M24: 644.5034334480918,
    M25: 646.9536590364146,
    M26: 649.4038846247374,
    M27: 651.8541102130603,
    M28: 654.3043358013831,
    M29: 656.7545613897059,
    M30: 659.2047869780288,
    M31: 661.6550125663516,
    M32: 664.1052381546745,
    M33: 666.5554637429973,
    M34: 669.0056893313201,
    M35: 671.455914919643,
    M36: 673.9061405079658,
  },
  {
    DMA: "Denver",
    "DMA Annual City Budget": 1660000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1411000,
    "% of cLINKAllocation to Nexus": 14110,
    "Per Month": 1175.8333333333333,
    YEAR_1: 10891.106865,
    YEAR_2: 15138.68955,
    YEAR_3: 15844.076669999999,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 1190.5288983333332,
    M5: 1195.42742,
    M6: 1200.3259416666665,
    M7: 1205.2244633333332,
    M8: 1210.122985,
    M9: 1215.0215066666665,
    M10: 1219.9200283333332,
    M11: 1224.81855,
    M12: 1229.7170716666667,
    M13: 1234.6155933333332,
    M14: 1239.514115,
    M15: 1244.4126366666667,
    M16: 1249.3111583333332,
    M17: 1254.20968,
    M18: 1259.1082016666667,
    M19: 1264.0067233333332,
    M20: 1268.905245,
    M21: 1273.8037666666667,
    M22: 1278.7022883333332,
    M23: 1283.60081,
    M24: 1288.4993316666666,
    M25: 1293.3978533333332,
    M26: 1298.296375,
    M27: 1303.1948966666666,
    M28: 1308.0934183333334,
    M29: 1312.9919399999999,
    M30: 1317.8904616666666,
    M31: 1322.7889833333334,
    M32: 1327.6875049999999,
    M33: 1332.5860266666666,
    M34: 1337.4845483333334,
    M35: 1342.3830699999999,
    M36: 1347.2815916666666,
  },
  {
    DMA: "Orlando-Daytona Beach-Melbourne",
    "DMA Annual City Budget": 9400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 7990000,
    "% of cLINKAllocation to Nexus": 79900,
    "Per Month": 6658.333333333333,
    YEAR_1: 61672.532849999996,
    YEAR_2: 85725.10949999999,
    YEAR_3: 89719.47029999999,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 6741.549183333333,
    M5: 6769.2878,
    M6: 6797.026416666667,
    M7: 6824.765033333333,
    M8: 6852.50365,
    M9: 6880.242266666666,
    M10: 6907.980883333333,
    M11: 6935.719499999999,
    M12: 6963.458116666667,
    M13: 6991.196733333333,
    M14: 7018.93535,
    M15: 7046.673966666666,
    M16: 7074.412583333333,
    M17: 7102.151199999999,
    M18: 7129.889816666667,
    M19: 7157.628433333333,
    M20: 7185.36705,
    M21: 7213.105666666666,
    M22: 7240.844283333333,
    M23: 7268.582899999999,
    M24: 7296.321516666667,
    M25: 7324.060133333333,
    M26: 7351.79875,
    M27: 7379.5373666666665,
    M28: 7407.275983333333,
    M29: 7435.0145999999995,
    M30: 7462.753216666666,
    M31: 7490.4918333333335,
    M32: 7518.23045,
    M33: 7545.969066666667,
    M34: 7573.707683333333,
    M35: 7601.4463,
    M36: 7629.184916666666,
  },
  {
    DMA: "Miami-Fort Lauderdale",
    "DMA Annual City Budget": 6000000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 5100000,
    "% of cLINKAllocation to Nexus": 51000,
    "Per Month": 4250,
    YEAR_1: 39365.446500000005,
    YEAR_2: 54718.155,
    YEAR_3: 57267.746999999996,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 4303.1165,
    M5: 4320.822,
    M6: 4338.5275,
    M7: 4356.233,
    M8: 4373.9385,
    M9: 4391.644,
    M10: 4409.3495,
    M11: 4427.055,
    M12: 4444.7605,
    M13: 4462.466,
    M14: 4480.1715,
    M15: 4497.877,
    M16: 4515.5825,
    M17: 4533.2880000000005,
    M18: 4550.9935000000005,
    M19: 4568.699,
    M20: 4586.4045,
    M21: 4604.11,
    M22: 4621.8155,
    M23: 4639.521,
    M24: 4657.2265,
    M25: 4674.932,
    M26: 4692.6375,
    M27: 4710.343,
    M28: 4728.0485,
    M29: 4745.754,
    M30: 4763.4595,
    M31: 4781.165,
    M32: 4798.8705,
    M33: 4816.576,
    M34: 4834.2815,
    M35: 4851.987,
    M36: 4869.6925,
  },
  {
    DMA: "Cleveland-Akron-Canton",
    "DMA Annual City Budget": 837621039,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 711977.88315,
    "% of cLINKAllocation to Nexus": 7119.7788315,
    "Per Month": 593.314902625,
    YEAR_1: 5495.554366338151,
    YEAR_2: 7638.846307210507,
    YEAR_3: 7994.778290554854,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 600.7301522780072,
    M5: 603.2019021623429,
    M6: 605.6736520466787,
    M7: 608.1454019310145,
    M8: 610.6171518153502,
    M9: 613.088901699686,
    M10: 615.5606515840217,
    M11: 618.0324014683574,
    M12: 620.5041513526932,
    M13: 622.9759012370289,
    M14: 625.4476511213647,
    M15: 627.9194010057005,
    M16: 630.3911508900362,
    M17: 632.862900774372,
    M18: 635.3346506587077,
    M19: 637.8064005430434,
    M20: 640.2781504273792,
    M21: 642.7499003117149,
    M22: 645.2216501960507,
    M23: 647.6934000803865,
    M24: 650.1651499647222,
    M25: 652.636899849058,
    M26: 655.1086497333937,
    M27: 657.5803996177294,
    M28: 660.0521495020652,
    M29: 662.5238993864009,
    M30: 664.9956492707367,
    M31: 667.4673991550725,
    M32: 669.9391490394082,
    M33: 672.410898923744,
    M34: 674.8826488080797,
    M35: 677.3543986924154,
    M36: 679.8261485767512,
  },
  {
    DMA: "Sacramento-Stockton-Modesto",
    "DMA Annual City Budget": 957370858,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 813765.2293,
    "% of cLINKAllocation to Nexus": 8137.652293,
    "Per Month": 678.1376910833334,
    YEAR_1: 6281.2218818763495,
    YEAR_2: 8730.927833421167,
    YEAR_3: 9137.745346852822,
    M1: 0,
    M2: 0,
    M3: 0,
    M4: 686.6130559464929,
    M5: 689.4381775675461,
    M6: 692.2632991885993,
    M7: 695.0884208096523,
    M8: 697.9135424307055,
    M9: 700.7386640517587,
    M10: 703.5637856728118,
    M11: 706.388907293865,
    M12: 709.2140289149182,
    M13: 712.0391505359714,
    M14: 714.8642721570245,
    M15: 717.6893937780777,
    M16: 720.5145153991309,
    M17: 723.339637020184,
    M18: 726.1647586412372,
    M19: 728.9898802622904,
    M20: 731.8150018833436,
    M21: 734.6401235043967,
    M22: 737.4652451254499,
    M23: 740.2903667465031,
    M24: 743.1154883675563,
    M25: 745.9406099886094,
    M26: 748.7657316096626,
    M27: 751.5908532307158,
    M28: 754.4159748517689,
    M29: 757.241096472822,
    M30: 760.0662180938752,
    M31: 762.8913397149283,
    M32: 765.7164613359815,
    M33: 768.5415829570347,
    M34: 771.3667045780879,
    M35: 774.1918261991411,
    M36: 777.0169478201942,
  },
];








const Projection = ({selectedCities,selectedApps,count,additionalDataSetter}) => {
  let proxyAppNames ={"clink":"cLINK","gofema":"GoFEMA","amply":"AmplyFund","liveWeather":"Live Weather","iZak":"Izak","museo":"Museo","aegis":"Aegis","reee":"Reve(e)","voteiq":"Voteiq","around":"Around","impressio":"ImpressIO"}
  const [finalData,setFinalData] = useState([data])
  useEffect(()=>{
    let selectedAppsNames = selectedApps.map(e=>{return proxyAppNames[e]})
    // //console.log(selectedAppsNames,selectedCities,finalData)
    let data = aggregateDataForDMAsAndPlatforms(jsonData,selectedCities,selectedAppsNames)
    //console.log(data,"finalll")
     setFinalData([data]);
     additionalDataSetter([data["fixed"],data["metered"],data["subscription"],data["ad"]])
  },[count])

  return (
    <div className={st.container}>
      <div className={st.contentContainer}>
        <div className={st.topCont}>
          <span id={st.head}>Projection over the next 10 years</span>
          <Img src="./Images/ArrowRight.png" />
        </div>
        <div className={st.mainCont}>
          <div className={st.dataCont}><ChartComponent2 data={finalData}/></div>
          <div className={st.dataCont}><ChartComponent3 data={finalData}/></div>
          <div className={st.dataCont}><ChartComponent6 data={finalData}/></div>
          <div className={st.dataCont}><ChartComponent data={finalData}/></div>
        </div>
      </div>
    </div>
  );
};

export default Projection;
