export const dynamic4OptionData = {
  d4Cmp1: [
    {
      buttonLabel: "Best execution",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FuniqueTradingPairs.webp?alt=media&token=88c6cbaf-a180-4ce6-9f44-09ea98092ea8",
      heading: "Buy, sell & exchange always at the best available price",
      para: "Market volatility often leads to price differences across exchanges. Our Smart Engine takes advantage of this by finding the best execution route every time, meaning you get the best price from multiple exchanges.",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "Unique trading pairs",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Access 3,283 unique crypto to fiat trading pairs",
      para: "Trade any fiat or crypto pair, even if it doesn’t exist on any exchange. EUR to BORG, or CHF to PAX Gold and more! We do all the conversions for you and find the best route and price.",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "Transparent",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FuniqueTradingPairs.webp?alt=media&token=88c6cbaf-a180-4ce6-9f44-09ea98092ea8",
      heading: "Get a fully transparent exchange report",
      para: "With the Smart Exchange Report, you will be able to review and keep track of every exchange. Review all the details at each step of your exchange.",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "No hidden fees",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Enjoy zero spreads and no inflated exchange rates",
      para: "While other apps claim “zero fees” but hide them in the exchange rate or spread, we always offer the best possible price without taking a cut. Instead, we charge a small transparent fee that we reinvest into the ecosystem.",
      flexDirection: "row",
      btnDisplay: "none",
    },
  ],
  d4Cmp2: [
    {
      buttonLabel: "Balance",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2Fbalance.webp?alt=media&token=289bdb3a-313e-49f3-9c22-c9e8d511bfc6",
      heading: "View your historical balance",
      para: "Get a quick overview of your total deposits, withdrawals, fees and profit/loss. With Portfolio Visualizer, visualise how your portfolio has grown over time.",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "Profit/Loss",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Access 3,283 unique crypto to fiat trading pairs",
      para: "Trade any fiat or crypto pair, even if it doesn’t exist on any exchange. EUR to BORG, or CHF to PAX Gold and more! We do all the conversions for you and find the best route and price.",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "Fees",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2Fbalance.webp?alt=media&token=289bdb3a-313e-49f3-9c22-c9e8d511bfc6",
      heading: "View your historical balance",
      para: "Get a quick overview of your total deposits, withdrawals, fees and profit/loss. With Portfolio Visualizer, visualise how your portfolio has grown over time.",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "Allocation",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Enjoy zero spreads and no inflated exchange rates",
      para: "While other apps claim “zero fees” but hide them in the exchange rate or spread, we always offer the best possible price without taking a cut. Instead, we charge a small transparent fee that we reinvest into the ecosystem.",
      flexDirection: "row",
      btnDisplay: "none",
    },
  ],
  d4Cmp3: [
    {
      buttonLabel: "CyBorg Predictor",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FcyBorgPredictor.webp?alt=media&token=24e968cd-c80c-4385-be21-ef8442a28c13",
      heading: "The power of AI in your pocket",
      para: "The CyBorg Predictor is a machine learning algorithm that forecasts an asset's movement over the next 24 hours, combining more historical data and technical indicators than a human could ever compute!",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "SwissBorg Indicator",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Buy, sell & exchange always at the best available price",
      para: "Market volatility often leads to price differences across exchanges. Our Smart Engine takes advantage of this by finding the best execution route every time, meaning you get the best price from multiple exchanges.",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "Community Sentiment",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FcyBorgPredictor.webp?alt=media&token=24e968cd-c80c-4385-be21-ef8442a28c13",
      heading: "Get a fully transparent exchange report",
      para: "With the Smart Exchange Report, you will be able to review and keep track of every exchange. Review all the details at each step of your exchange.",
      flexDirection: "row",
      btnDisplay: "none",
    },
    {
      buttonLabel: "Support/Resistance",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Enjoy zero spreads and no inflated exchange rates",
      para: "While other apps claim “zero fees” but hide them in the exchange rate or spread, we always offer the best possible price without taking a cut. Instead, we charge a small transparent fee that we reinvest into the ecosystem.",
      flexDirection: "row",
      btnDisplay: "none",
    },
  ],
  d4Cmp4: [
    {
      buttonLabel: "Align your pace",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FalignYourPace.webp?alt=media&token=2c83aae2-9b21-40ca-90e0-5bac3bcb3e35",
      heading: "Pace yourself with best practices",
      para: "To navigate the market with confidence and resilience, experts recommend staying committed to consistent and regular investment practices rather than trying to time the market.",
      flexDirection: "row-reverse",
      btnDisplay: "flex",
    },
    {
      buttonLabel: "Earn Yield",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Access 3,283 unique crypto to fiat trading pairs",
      para: "Trade any fiat or crypto pair, even if it doesn’t exist on any exchange. EUR to BORG, or CHF to PAX Gold and more! We do all the conversions for you and find the best route and price.",
      flexDirection: "row-reverse",
      btnDisplay: "flex",
    },
    {
      buttonLabel: "Diversify portfolio",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FcyBorgPredictor.webp?alt=media&token=24e968cd-c80c-4385-be21-ef8442a28c13",
      heading: "Get a fully transparent exchange report",
      para: "With the Smart Exchange Report, you will be able to review and keep track of every exchange. Review all the details at each step of your exchange.",
      flexDirection: "row-reverse",
      btnDisplay: "flex",
    },
  ],
  d4Cmp5: [
    {
      buttonLabel: "Long-term wealth",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2Flongterm.webp?alt=media&token=81f87383-f07a-4bce-bd96-7dbe7cc0767a",
      heading: "Long term wealth building",
      para: "Auto-Invest reduces price volatility, leading to a smoother average buying price and potentially higher returns.",
      flexDirection: "row",
      btnDisplay: "flex",
    },
    {
      buttonLabel: "Invest better",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Access 3,283 unique crypto to fiat trading pairs",
      para: "Trade any fiat or crypto pair, even if it doesn’t exist on any exchange. EUR to BORG, or CHF to PAX Gold and more! We do all the conversions for you and find the best route and price.",
      flexDirection: "row",
      btnDisplay: "flex",
    },
    {
      buttonLabel: "It’s stress free!",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FcyBorgPredictor.webp?alt=media&token=24e968cd-c80c-4385-be21-ef8442a28c13",
      heading: "Get a fully transparent exchange report",
      para: "With the Smart Exchange Report, you will be able to review and keep track of every exchange. Review all the details at each step of your exchange.",
      flexDirection: "row",
      btnDisplay: "flex",
    },
    {
      buttonLabel: "You have the power",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FcyBorgPredictor.webp?alt=media&token=24e968cd-c80c-4385-be21-ef8442a28c13",
      heading: "Get a fully transparent exchange report",
      para: "With the Smart Exchange Report, you will be able to review and keep track of every exchange. Review all the details at each step of your exchange.",
      flexDirection: "row",
      btnDisplay: "flex",
    },
  ],
  d4Cmp6: [
    {
      buttonLabel: "Hero Score",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2Fimg1.webp?alt=media&token=c483637b-0722-4733-a745-03056478ce3f",
      heading: "Increased utilities",
      para: "Auto-Invest reduces price volatility, leading to a smoother average buying price and potentially higher returns.",
      flexDirection: "row",
      btnDisplay: "flex",
    },
    {
      buttonLabel: "Sustainable token economics",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FinfographicCards%2Fcore.webp?alt=media&token=7cb88bd0-25f1-4425-887a-7f5efb897afe",
      heading: "Access 3,283 unique crypto to fiat trading pairs",
      para: "Trade any fiat or crypto pair, even if it doesn’t exist on any exchange. EUR to BORG, or CHF to PAX Gold and more! We do all the conversions for you and find the best route and price.",
      flexDirection: "row",
      btnDisplay: "flex",
    },
    {
      buttonLabel: "Governance model",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FcyBorgPredictor.webp?alt=media&token=24e968cd-c80c-4385-be21-ef8442a28c13",
      heading: "Get a fully transparent exchange report",
      para: "With the Smart Exchange Report, you will be able to review and keep track of every exchange. Review all the details at each step of your exchange.",
      flexDirection: "row",
      btnDisplay: "flex",
    },
    {
      buttonLabel: "Exchange listings",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FDynamicSlider%2FcyBorgPredictor.webp?alt=media&token=24e968cd-c80c-4385-be21-ef8442a28c13",
      heading: "Get a fully transparent exchange report",
      para: "With the Smart Exchange Report, you will be able to review and keep track of every exchange. Review all the details at each step of your exchange.",
      flexDirection: "row",
      btnDisplay: "flex",
    },
  ],
};
