import "swiper/css";
// import SingleApp from "../AppPowered";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow} from "swiper";
import Img from "../Image";
import "./index.css";
import SwiperCore, { Autoplay } from 'swiper';
import { useRef,useEffect,useState} from "react";

let AndroidApps = () => {

    SwiperCore.use([Autoplay])
    const swiperRef2 = useRef(null);
    useEffect(() => {
        const swiperInstance2 = swiperRef2.current.swiper;
    
        let direction = 'ltr';
    
        const autoplayHandler = () => {
          if (swiperInstance2.isEnd && direction === 'ltr') {
            direction = 'rtl';
            swiperInstance2.params.autoplay.reverseDirection = true;
            swiperInstance2.autoplay.stop();
            swiperInstance2.autoplay.start();
          } else if (swiperInstance2.isBeginning && direction === 'rtl') {
            direction = 'ltr';
            swiperInstance2.params.autoplay.reverseDirection = false;
            swiperInstance2.autoplay.stop();
            swiperInstance2.autoplay.start();
          }
        };
    
        swiperInstance2.on('autoplay', autoplayHandler);
    
        return () => {
          swiperInstance2.off('autoplay', autoplayHandler);
        };
      }, []);
      const [slidesPerView, setSlidesPerView] = useState(6);
      useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth<480){
                setSlidesPerView(3);
              }
            else if (window.innerWidth < 980) {
                setSlidesPerView(4);
            } 
            else {
            setSlidesPerView(6);
          }
        };
    
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
        
    return (
      <div className="appswipermainlandingpage">
        <Swiper
          ref={swiperRef2}
          centeredSlides={false}
          // loop={true}
          grabCursor={true}
          autoplay={{
            delay: 2000,
          }}
          initialSlide={3}
          slidesPerView={slidesPerView}
          freeMode={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: false,
            direction: "ltr",
          }}
          modules={[EffectCoverflow]}
          className="swipermenu"
        >
          <SwiperSlide className="content1">
            <Img src="/aidtaasImages/images/images/apps/Alpaco.svg"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2F13.webp?alt=media&token=81405dde-6766-40e0-9941-cecbe80701ec"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2F14.webp?alt=media&token=4795c09d-4412-40ac-a2c6-8cc38b2d7616"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2Fappstore.webp?alt=media&token=e1e9f2f6-3b90-4219-8b63-d063ff67c6cd"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2Fdrive.webp?alt=media&token=f59a6198-5620-45ad-9424-38875a5f8b12"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2Fplaystore.webp?alt=media&token=1739e3e5-b46d-46d3-8ec7-c35dc9196c79"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2Fslack.webp?alt=media&token=fa2467e4-b540-476b-b504-40d56209f973"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2Fzoom.webp?alt=media&token=7e88c478-93dc-4749-a09b-3708c9a10705"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2Ftwillonew.webp?alt=media&token=606ecf31-c9cd-4394-b8e8-3db14833a4bc"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="/aidtaasImages/images/images/apps/Alpaco.svg"></Img>
          </SwiperSlide>
          <SwiperSlide className="content1">
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fapps%2F13.webp?alt=media&token=81405dde-6766-40e0-9941-cecbe80701ec"></Img>
          </SwiperSlide>
        </Swiper>
      </div>
    );
}

export default AndroidApps;