export const caseStudy2Data = {
  c1: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcaseStudy2%2FcaseStudyBTC.webp?alt=media&token=87777d0d-706b-412c-b13d-ec3b2203eb42",
    head: "BORG Buyback",
    headColor: "#4C4C4C",
    content:
      "To boost our ecosystem, every week we buy back BORG on the market using the fees generated by the SwissBorg Earn program. The amount we buy is based on the Community Index - the higher the Index, the more we buy!.",
    content_text_color: "",

    flexDirection: "row-reverse",
    bgColor: "#FFF",
    btn: "Tell me more",
    btn_bgColor: "#007CD7",
  },

  c2: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcaseStudy2%2FcaseStudy2Shield.webp?alt=media&token=f8f3c113-0b08-4f3b-bdb8-72187418fcd8",
    head: "BORG Protect & Choose",
    headColor: "#4C4C4C",
    content:
      "A percentage of the revenue earned from exchange fees is used to buy back BORG tokens. Then, every month, the BORG holders can decide how the tokens will be used.",
    content_text_color: "",

    flexDirection: "row-reverse",
    bgColor: "#FFF",
    btn: "Learn more",
    btn_bgColor: "#007CD7",
  },

  c3: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcaseStudy2%2FBTC_lock.webp?alt=media&token=499742e2-124d-4a8c-aa57-29f39aa5a5d0",
    head: "Locking",
    headColor: "#FFA200",
    content:
      "Users can lock their BORG tokens for a pre-defined period, reducing the available supply and increasing scarcity while enjoying Premium benefits..",
    content_text_color: "#FFA200",

    flexDirection: "row",
    bgColor: "#FFF7EA",
    btn: "More BORG metrics",
    btn_bgColor: "#FFA200",
  },

  c4: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcaseStudy2%2FfourGems.webp?alt=media&token=1687be37-523d-4796-af3b-d0ae7879b8e0",
    head: "Premium Benefits",
    headColor: "#4C4C4C",
    content:
      "By holding BORG tokens, Premium users show their confidence and trust in the BORG's long term value while benefiting from lower exchange fees on multiple cryptocurrencies",
    content_text_color: "",

    flexDirection: "row-reverse",
    bgColor: "#FFF",
    btn: "Become a premium member",
    btn_bgColor: "#FFA200",
  },

  c5: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcaseStudy2%2FcaseStudy2Search.webp?alt=media&token=bacfbfff-97b1-479c-9248-f402d575c74e",
    head: "What is Self Verification?",
    headColor: "#4C4C4C",
    content:
      "Using our simple verification  tool based on well-established Merkle tree cryptographic techniques you can verify the correctness of your account balance and proper inclusion of your assets in the company’s total liabilities.",
    content_text_color: "",

    flexDirection: "row",
    bgColor: "#FFF",
    btn: "Learn more",
    btn_bgColor: "#007CD7",
  },

  c6: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcaseStudy2%2FBTC_lock.webp?alt=media&token=499742e2-124d-4a8c-aa57-29f39aa5a5d0",
    head: "Diversify risk in one tap",
    headColor: "#4C4C4C",
    content:
      "95% of crypto projects fail. Putting all your bets on one token is highly risky. Instead broaden your horizons and invest across your favourite trends & themes, all with just one tap.",
    content_text_color: "",

    flexDirection: "row",
    bgColor: "#FFF",
    btn: "Tell me more",
    btn_bgColor: "#007CD7",
  },

  c7: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcaseStudy2%2FBTC_lock.webp?alt=media&token=499742e2-124d-4a8c-aa57-29f39aa5a5d0",
    head: "Diversify risk in one tap",
    headColor: "#4C4C4C",
    content:
      "95% of crypto projects fail. Putting all your bets on one token is highly risky. Instead broaden your horizons and invest across your favourite trends & themes, all with just one tap.",
    content_text_color: "",

    flexDirection: "row",
    bgColor: "#FFF",
    btn: "Tell me more",
    btn_bgColor: "#007CD7",
  },
};
