export const curveContainerData = {
  curveContainer1: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcurveContainer%2FcurveContainerImage.webp?alt=media&token=62c61ff7-a6c6-4636-aed1-0509511c0d08",
    heading: "Invest in the internet of the future!",
    para: "Grow your wealth with Web3 Thematic.",
    buttonText: "Learn more",
    btn_borderRad: "10px",
    btn_bgColor: "#007CD7",
    btn_txt_color: "#FFF",
    btn_border: "1px solid #007CD7",
    wrapper_border: "80px 10px",
  },
  curveContainer2: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FcurveContainer%2FcurveContainerImage2.webp?alt=media&token=c32f5d52-b749-48ec-9331-8f394e28cfb7",
    heading: "Smart Send crypto to invite your friends to the SwissBorg app!",
    para: "When they deposit EUR50 in any asset, you'll both get a free Reward Ticket!",
    buttonText: "Send now",
    btn_borderRad: "10px",
    btn_bgColor: "#007CD7",
    btn_txt_color: "#FFF",
    btn_border: "1px solid #007CD7",
    wrapper_border: "10px 0px 0px 10px",
  },
};
