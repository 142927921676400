export const articlesData = {
  card: [
    {
      imgURL:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Farticles%2Fcard1.webp?alt=media&token=e8c5b6bc-b5ac-4ff4-aab0-a03911454efe",
      date: "13 February 2023",
      heading: "From Dream to Reality: the Steps SwissBorg is…",
      para: "It’s time to look back at all that we’ve achieved and ahead to where we are…",
    },
    {
      imgURL:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Farticles%2Fcard2.webp?alt=media&token=fccf7dc1-07a5-4318-b1ab-96793cc6cbab",
      date: "1 February 2023",
      heading: "Putting People First Breaks Records",
      para: "Eleven thousand and counting have partnered up with us!",
    },
    {
      imgURL:
        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Farticles%2Fcard3.webp?alt=media&token=e270e67e-9fe4-4b25-bb32-1999279c68eb",
      date: "18 January 2023",
      heading: "SwissBorg Series A - Introducing community…",
      para: "Today, we are proud to be disrupting the status quo once more by allowing…",
    },
  ],
};
