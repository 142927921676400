export const executiveData = {
  person1: {
    img: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Fceo.webp?alt=media&token=84378a1d-145f-48df-846a-6c928c6bf2a9",
    name: "Chandra Kotaru",
    role: "CEO, Founder",
    arrow:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Farrow.webp?alt=media&token=ac0447a9-d8ae-4329-b58a-efb79d02e02b",
  },
  person2: {
    img: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Femp1.webp?alt=media&token=9d83790e-16f4-4665-9300-217b2c56694a",
    name: "Uma Soni",
    role: "Lead Engineering Manager",
    arrow:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Farrow.webp?alt=media&token=ac0447a9-d8ae-4329-b58a-efb79d02e02b",
  },
  person3: {
    img: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Femp2.webp?alt=media&token=c801a96c-42e9-4d8c-b15f-24f022db985b/Images/executiveTeam/emp2.png",
    name: "Gurpreet Gandhi",
    role: "Senior Member of Technical Staff -2",
    arrow:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Farrow.webp?alt=media&token=ac0447a9-d8ae-4329-b58a-efb79d02e02b",
  },
  person4: {
    img: "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2FKL_.webp?alt=media&token=860dacea-a700-486d-a439-46c1cfbe720a",
    name: "KL Das",
    role: "Member of Technical Staff",
    arrow:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FexecutiveTeam%2Farrow.webp?alt=media&token=ac0447a9-d8ae-4329-b58a-efb79d02e02b",
  },
};
