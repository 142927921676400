export const getStartedData = {
  getStarted: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FgetStarted%2Ficonsimage.webp?alt=media&token=2f859da9-68da-4bdf-872f-345b329cc660",
    contenttxt:
      "Boost your earnings and lower your fees with SwissBorg Premium",
    btntext: "Get started",
    bgcolor: "linear-gradient(270deg, #41aeff 5%, #2e7dcd 100%)",
    contenttxtcolor: "#FFF",
    btntextcolor: "#007CD7",
    btnbgcolor: "#FFF",
  },
  swissBorg: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FgetStarted%2Fswiss.webp?alt=media&token=f1329e7b-7efc-4f0f-a6a6-b172cb648c22",
    contenttxt: "See all the SwissBorg Fees",
    btntext: "See all the fees",
    bgcolor:
      "var(--Gradient, linear-gradient(118deg, rgba(64, 175, 255, 0.20) -8.52%, rgba(41, 177, 253, 0.20) 27.6%, rgba(255, 168, 0, 0.20) 63.71%, rgba(251, 180, 42, 0.20) 99.82%))",
    contenttxtcolor: "#191E29",
    btntextcolor: "#FFF",
    btnbgcolor: "#007CD7",
  },
  inviteFriends: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FgetStarted%2FinviteFriends.webp?alt=media&token=22703843-0a0a-4690-b894-1a0f7c6b237f",
    contenttxt: "Invite your friends and earn up to €100 in BORG",
    btntext: "Learn More",
    bgcolor: "linear-gradient(270deg, #41aeff 5%, #2e7dcd 100%)",
    contenttxtcolor: "#FFF",
    btntextcolor: "#007CD7",
    btnbgcolor: "#FFF",
  },
  readBlog: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FgetStarted%2Fblog.webp?alt=media&token=73a3725a-cea1-4938-ab75-ed0d90ff9603",
    contenttxt: "Discover the next steps of the  Rock Solid BORG roadmap",
    btntext: "Read the blog post",
    bgcolor: "linear-gradient(270deg, #41aeff 5%, #2e7dcd 100%)",
    contenttxtcolor: "#FFF",
    btntextcolor: "#007CD7",
    btnbgcolor: "#FFF",
  },
  auditList: {
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FgetStarted%2Fswiss.webp?alt=media&token=f1329e7b-7efc-4f0f-a6a6-b172cb648c22",
    contenttxt: "Complete audits list",
    btntext: "See more",
    bgcolor:
      "var(--Gradient, linear-gradient(118deg, rgba(64, 175, 255, 0.20) -8.52%, rgba(41, 177, 253, 0.20) 27.6%, rgba(255, 168, 0, 0.20) 63.71%, rgba(251, 180, 42, 0.20) 99.82%))",
    contenttxtcolor: "#191E29",
    btntextcolor: "#FFF",
    btnbgcolor: "#007CD7",
  },
};
