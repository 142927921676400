import React, { lazy, Suspense } from "react";

const Map3dComponent = lazy(() => import("../../molecules/MapComponent/index"));
const Loading = () => <h1 style={{ color: "white" }}>Loading...</h1>;

const LazyLoadMap3dComponent = () => (
  <Suspense fallback={<Loading />}>
    <Map3dComponent />
  </Suspense>
);

export default LazyLoadMap3dComponent;
