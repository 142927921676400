
const jsonData =[
  {
    "DMA": "New York",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 107008271000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 90957030.35,
    "% of cLINKAllocation to Nexus": 909570.3034999999,
    "Per Month": 75797.52529166666,
    "YEAR_1": 702071.3945180003,
    "YEAR_2": 975882.5264766676,
    "YEAR_3": 1021353.7650892396,
    "YEAR_4": 1066825.0037018114,
    "YEAR_5": 1112296.2423143834,
    "YEAR_6": 1157767.4809269556,
    "YEAR_7": 1203238.7195395275,
    "YEAR_8": 1248709.9581520995,
    "YEAR_9": 1294181.1967646715,
    "YEAR_10": 1339652.4353772434,
    "FIXED_cLINK": 11121978.7228606
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 11800000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 10030000,
    "% of cLINKAllocation to Nexus": 100300,
    "Per Month": 8358.333333333334,
    "YEAR_1": 77418.71145,
    "YEAR_2": 107612.37150000001,
    "YEAR_3": 112626.56910000002,
    "YEAR_4": 117640.76670000001,
    "YEAR_5": 122654.96430000002,
    "YEAR_6": 127669.1619,
    "YEAR_7": 132683.35950000002,
    "YEAR_8": 137697.5571,
    "YEAR_9": 142711.75470000002,
    "YEAR_10": 147725.9523,
    "FIXED_cLINK": 1226441.16855
  },
  {
    "DMA": "Chicago",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 16400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 13940000,
    "% of cLINKAllocation to Nexus": 139400,
    "Per Month": 11616.666666666666,
    "YEAR_1": 107598.88709999999,
    "YEAR_2": 149562.95700000005,
    "YEAR_3": 156531.8418,
    "YEAR_4": 163500.7266,
    "YEAR_5": 170469.61140000005,
    "YEAR_6": 177438.49619999997,
    "YEAR_7": 184407.381,
    "YEAR_8": 191376.26580000005,
    "YEAR_9": 198345.15059999994,
    "YEAR_10": 205314.03539999996,
    "FIXED_cLINK": 1704545.3528999998
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 5300000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 4505000,
    "% of cLINKAllocation to Nexus": 45050,
    "Per Month": 3754.1666666666665,
    "YEAR_1": 34772.811075,
    "YEAR_2": 48334.37024999999,
    "YEAR_3": 50586.50985,
    "YEAR_4": 52838.64945,
    "YEAR_5": 55090.78905,
    "YEAR_6": 57342.92865,
    "YEAR_7": 59595.06825,
    "YEAR_8": 61847.20784999999,
    "YEAR_9": 64099.34744999999,
    "YEAR_10": 66351.48705,
    "FIXED_cLINK": 550859.168925
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 6938786440,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 5897968.473999999,
    "% of cLINKAllocation to Nexus": 58979.68474,
    "Per Month": 4914.973728333333,
    "YEAR_1": 45524.73772979091,
    "YEAR_2": 63279.5986559697,
    "YEAR_3": 66228.11105549178,
    "YEAR_4": 69176.62345501386,
    "YEAR_5": 72125.13585453594,
    "YEAR_6": 75073.64825405802,
    "YEAR_7": 78022.16065358011,
    "YEAR_8": 80970.67305310217,
    "YEAR_9": 83919.18545262427,
    "YEAR_10": 86867.69785214633,
    "FIXED_cLINK": 721187.5720163131
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2280000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1938000,
    "% of cLINKAllocation to Nexus": 19380,
    "Per Month": 1615,
    "YEAR_1": 14958.86967,
    "YEAR_2": 20792.898900000004,
    "YEAR_3": 21761.74386,
    "YEAR_4": 22730.58882,
    "YEAR_5": 23699.433780000003,
    "YEAR_6": 24668.27874,
    "YEAR_7": 25637.1237,
    "YEAR_8": 26605.968660000006,
    "YEAR_9": 27574.81362,
    "YEAR_10": 28543.65858,
    "FIXED_cLINK": 236973.37833
  },
  {
    "DMA": "Houston",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 6243926515,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 5307337.53775,
    "% of cLINKAllocation to Nexus": 53073.3753775,
    "Per Month": 4422.781281458333,
    "YEAR_1": 40965.82586269399,
    "YEAR_2": 56942.68980939664,
    "YEAR_3": 59595.93399126862,
    "YEAR_4": 62249.17817314059,
    "YEAR_5": 64902.42235501257,
    "YEAR_6": 67555.66653688456,
    "YEAR_7": 70208.91071875654,
    "YEAR_8": 72862.1549006285,
    "YEAR_9": 75515.3990825005,
    "YEAR_10": 78168.64326437248,
    "FIXED_cLINK": 648966.824694655
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 10759066000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 9145206.1,
    "% of cLINKAllocation to Nexus": 91452.061,
    "Per Month": 7621.0050833333335,
    "YEAR_1": 70589.23950216151,
    "YEAR_2": 98119.37350720499,
    "YEAR_3": 102691.24494071699,
    "YEAR_4": 107263.116374229,
    "YEAR_5": 111834.98780774101,
    "YEAR_6": 116406.859241253,
    "YEAR_7": 120978.73067476501,
    "YEAR_8": 125550.60210827703,
    "YEAR_9": 130122.47354178902,
    "YEAR_10": 134694.344975301,
    "FIXED_cLINK": 1118250.9726734385
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 9500000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 8075000,
    "% of cLINKAllocation to Nexus": 80750,
    "Per Month": 6729.166666666667,
    "YEAR_1": 62328.62362499999,
    "YEAR_2": 86637.07875000002,
    "YEAR_3": 90673.93275,
    "YEAR_4": 94710.78675000001,
    "YEAR_5": 98747.64075,
    "YEAR_6": 102784.49475,
    "YEAR_7": 106821.34875,
    "YEAR_8": 110858.20275,
    "YEAR_9": 114895.05675000002,
    "YEAR_10": 118931.91075000001,
    "FIXED_cLINK": 987389.076375
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 28675495435,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 24374171.119749997,
    "% of cLINKAllocation to Nexus": 243741.71119749997,
    "Per Month": 20311.80926645833,
    "YEAR_1": 188137.28023458112,
    "YEAR_2": 261511.7006523537,
    "YEAR_3": 273696.83627853915,
    "YEAR_4": 285881.97190472455,
    "YEAR_5": 298067.10753091,
    "YEAR_6": 310252.2431570954,
    "YEAR_7": 322437.37878328084,
    "YEAR_8": 334622.51440946624,
    "YEAR_9": 346807.6500356517,
    "YEAR_10": 358992.7856618371,
    "FIXED_cLINK": 2980407.46864844
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1638402000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1392641.7,
    "% of cLINKAllocation to Nexus": 13926.417,
    "Per Month": 1160.53475,
    "YEAR_1": 10749.4043794155,
    "YEAR_2": 14941.722431384998,
    "YEAR_3": 15637.931870049,
    "YEAR_4": 16334.141308713002,
    "YEAR_5": 17030.350747377004,
    "YEAR_6": 17726.560186041,
    "YEAR_7": 18422.769624705,
    "YEAR_8": 19118.979063369003,
    "YEAR_9": 19815.188502033005,
    "YEAR_10": 20511.397940697003,
    "FIXED_cLINK": 170288.44605378452
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2116013047,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1798611.08995,
    "% of cLINKAllocation to Nexus": 17986.1108995,
    "Per Month": 1498.8425749583332,
    "YEAR_1": 13882.966399163413,
    "YEAR_2": 19297.388314628046,
    "YEAR_3": 20196.549970715852,
    "YEAR_4": 21095.711626803655,
    "YEAR_5": 21994.873282891458,
    "YEAR_6": 22894.03493897926,
    "YEAR_7": 23793.196595067064,
    "YEAR_8": 24692.358251154867,
    "YEAR_9": 25591.519907242673,
    "YEAR_10": 26490.681563330472,
    "FIXED_cLINK": 219929.28084997676
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 7400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 6290000,
    "% of cLINKAllocation to Nexus": 62900,
    "Per Month": 5241.666666666667,
    "YEAR_1": 48550.717350000006,
    "YEAR_2": 67485.7245,
    "YEAR_3": 70630.2213,
    "YEAR_4": 73774.71810000001,
    "YEAR_5": 76919.2149,
    "YEAR_6": 80063.71170000001,
    "YEAR_7": 83208.20850000002,
    "YEAR_8": 86352.7053,
    "YEAR_9": 89497.2021,
    "YEAR_10": 92641.6989,
    "FIXED_cLINK": 769124.12265
  },
  {
    "DMA": "Detroit",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2453276906,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2085285.3701,
    "% of cLINKAllocation to Nexus": 20852.853701,
    "Per Month": 1737.7378084166667,
    "YEAR_1": 16095.723465471421,
    "YEAR_2": 22373.131000071404,
    "YEAR_3": 23415.606862291796,
    "YEAR_4": 24458.08272451219,
    "YEAR_5": 25500.558586732575,
    "YEAR_6": 26543.034448952978,
    "YEAR_7": 27585.510311173366,
    "YEAR_8": 28627.986173393754,
    "YEAR_9": 29670.462035614153,
    "YEAR_10": 30712.93789783454,
    "FIXED_cLINK": 254983.03350604817
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 830326934,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 705777.8938999999,
    "% of cLINKAllocation to Nexus": 7057.778938999999,
    "Per Month": 588.1482449166666,
    "YEAR_1": 5447.698416314338,
    "YEAR_2": 7572.326312547794,
    "YEAR_3": 7925.158797266282,
    "YEAR_4": 8277.991281984772,
    "YEAR_5": 8630.823766703259,
    "YEAR_6": 8983.656251421746,
    "YEAR_7": 9336.488736140233,
    "YEAR_8": 9689.321220858721,
    "YEAR_9": 10042.15370557721,
    "YEAR_10": 10394.986190295698,
    "FIXED_cLINK": 86300.60467911005
  },
  {
    "DMA": "Denver",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1660000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1411000,
    "% of cLINKAllocation to Nexus": 14110,
    "Per Month": 1175.8333333333333,
    "YEAR_1": 10891.106865,
    "YEAR_2": 15138.68955,
    "YEAR_3": 15844.076669999999,
    "YEAR_4": 16549.463789999998,
    "YEAR_5": 17254.850909999997,
    "YEAR_6": 17960.23803,
    "YEAR_7": 18665.62515,
    "YEAR_8": 19371.01227,
    "YEAR_9": 20076.39939,
    "YEAR_10": 20781.786509999998,
    "FIXED_cLINK": 172533.24913500002
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 9400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 7990000,
    "% of cLINKAllocation to Nexus": 79900,
    "Per Month": 6658.333333333333,
    "YEAR_1": 61672.532849999996,
    "YEAR_2": 85725.10949999999,
    "YEAR_3": 89719.47029999999,
    "YEAR_4": 93713.8311,
    "YEAR_5": 97708.19189999999,
    "YEAR_6": 101702.5527,
    "YEAR_7": 105696.9135,
    "YEAR_8": 109691.2743,
    "YEAR_9": 113685.63510000001,
    "YEAR_10": 117679.99590000001,
    "FIXED_cLINK": 976995.50715
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 6000000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 5100000,
    "% of cLINKAllocation to Nexus": 51000,
    "Per Month": 4250,
    "YEAR_1": 39365.446500000005,
    "YEAR_2": 54718.155,
    "YEAR_3": 57267.746999999996,
    "YEAR_4": 59817.339,
    "YEAR_5": 62366.931,
    "YEAR_6": 64916.523,
    "YEAR_7": 67466.115,
    "YEAR_8": 70015.70700000001,
    "YEAR_9": 72565.29900000001,
    "YEAR_10": 75114.89099999997,
    "FIXED_cLINK": 623614.1534999999
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 837621039,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 711977.88315,
    "% of cLINKAllocation to Nexus": 7119.7788315,
    "Per Month": 593.314902625,
    "YEAR_1": 5495.554366338151,
    "YEAR_2": 7638.846307210507,
    "YEAR_3": 7994.778290554854,
    "YEAR_4": 8350.710273899203,
    "YEAR_5": 8706.64225724355,
    "YEAR_6": 9062.5742405879,
    "YEAR_7": 9418.506223932247,
    "YEAR_8": 9774.438207276595,
    "YEAR_9": 10130.370190620943,
    "YEAR_10": 10486.302173965292,
    "FIXED_cLINK": 87058.72253162923
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 957370858,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 813765.2293,
    "% of cLINKAllocation to Nexus": 8137.652293,
    "Per Month": 678.1376910833334,
    "YEAR_1": 6281.2218818763495,
    "YEAR_2": 8730.927833421167,
    "YEAR_3": 9137.745346852822,
    "YEAR_4": 9544.562860284477,
    "YEAR_5": 9951.380373716136,
    "YEAR_6": 10358.197887147791,
    "YEAR_7": 10765.015400579447,
    "YEAR_8": 11171.832914011102,
    "YEAR_9": 11578.650427442757,
    "YEAR_10": 11985.467940874414,
    "FIXED_cLINK": 99505.00286620646
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 3244906505,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2758170.5292499997,
    "% of cLINKAllocation to Nexus": 27581.7052925,
    "Per Month": 2298.4754410416667,
    "YEAR_1": 21289.532236679916,
    "YEAR_2": 29592.549516849715,
    "YEAR_3": 30971.414127832377,
    "YEAR_4": 32350.278738815032,
    "YEAR_5": 33729.1433497977,
    "YEAR_6": 35108.00796078035,
    "YEAR_7": 36486.87257176301,
    "YEAR_8": 37865.73718274568,
    "YEAR_9": 39244.60179372833,
    "YEAR_10": 40623.46640471099,
    "FIXED_cLINK": 337261.6038837031
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 925035333,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 786280.03305,
    "% of cLINKAllocation to Nexus": 7862.8003305,
    "Per Month": 655.233360875,
    "YEAR_1": 6069.071485303531,
    "YEAR_2": 8436.037788595102,
    "YEAR_3": 8829.114902717458,
    "YEAR_4": 9222.192016839816,
    "YEAR_5": 9615.26913096217,
    "YEAR_6": 10008.346245084527,
    "YEAR_7": 10401.423359206885,
    "YEAR_8": 10794.500473329239,
    "YEAR_9": 11187.577587451597,
    "YEAR_10": 11580.654701573952,
    "FIXED_cLINK": 96144.18769106427
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 964330135,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 819680.61475,
    "% of cLINKAllocation to Nexus": 8196.8061475,
    "Per Month": 683.0671789583333,
    "YEAR_1": 6326.881056280046,
    "YEAR_2": 8794.394299683487,
    "YEAR_3": 9204.169032609308,
    "YEAR_4": 9613.943765535127,
    "YEAR_5": 10023.718498460947,
    "YEAR_6": 10433.493231386768,
    "YEAR_7": 10843.267964312587,
    "YEAR_8": 11253.042697238408,
    "YEAR_9": 11662.817430164228,
    "YEAR_10": 12072.592163090047,
    "FIXED_cLINK": 100228.32013876096
  },
  {
    "DMA": "Saint Louis",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1232851506,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1047923.7801,
    "% of cLINKAllocation to Nexus": 10479.237801,
    "Per Month": 873.2698167499999,
    "YEAR_1": 8088.6250003145715,
    "YEAR_2": 11243.226632881904,
    "YEAR_3": 11767.104689029495,
    "YEAR_4": 12290.982745177087,
    "YEAR_5": 12814.86080132468,
    "YEAR_6": 13338.738857472272,
    "YEAR_7": 13862.616913619862,
    "YEAR_8": 14386.494969767456,
    "YEAR_9": 14910.373025915049,
    "YEAR_10": 15434.25108206264,
    "FIXED_cLINK": 128137.274717565
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1462055326,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1242747.0270999998,
    "% of cLINKAllocation to Nexus": 12427.470270999998,
    "Per Month": 1035.6225225833332,
    "YEAR_1": 9592.410119282174,
    "YEAR_2": 13333.494991107253,
    "YEAR_3": 13954.769084895084,
    "YEAR_4": 14576.043178682914,
    "YEAR_5": 15197.317272470747,
    "YEAR_6": 15818.59136625858,
    "YEAR_7": 16439.865460046414,
    "YEAR_8": 17061.139553834244,
    "YEAR_9": 17682.413647622077,
    "YEAR_10": 18303.68774140991,
    "FIXED_cLINK": 151959.73241560938
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 656705794,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 558199.9249,
    "% of cLINKAllocation to Nexus": 5581.9992489999995,
    "Per Month": 465.1666040833333,
    "YEAR_1": 4308.586133324504,
    "YEAR_2": 5988.954904248346,
    "YEAR_3": 6268.010210704352,
    "YEAR_4": 6547.065517160361,
    "YEAR_5": 6826.12082361637,
    "YEAR_6": 7105.176130072376,
    "YEAR_7": 7384.231436528385,
    "YEAR_8": 7663.286742984393,
    "YEAR_9": 7942.3420494404,
    "YEAR_10": 8221.397355896408,
    "FIXED_cLINK": 68255.17130397589
  },
  {
    "DMA": "Nashville",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 3218000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2735300,
    "% of cLINKAllocation to Nexus": 27353,
    "Per Month": 2279.4166666666665,
    "YEAR_1": 21113.0011395,
    "YEAR_2": 29347.170464999996,
    "YEAR_3": 30714.601640999997,
    "YEAR_4": 32082.032816999996,
    "YEAR_5": 33449.463993,
    "YEAR_6": 34816.895169,
    "YEAR_7": 36184.326344999994,
    "YEAR_8": 37551.757521,
    "YEAR_9": 38919.188697,
    "YEAR_10": 40286.619872999996,
    "FIXED_cLINK": 334465.05766049994
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 4108683946,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 3492381.3540999996,
    "% of cLINKAllocation to Nexus": 34923.813540999996,
    "Per Month": 2910.317795083333,
    "YEAR_1": 26956.696343611977,
    "YEAR_2": 37469.9341672066,
    "YEAR_3": 39215.845453748276,
    "YEAR_4": 40961.756740289944,
    "YEAR_5": 42707.668026831605,
    "YEAR_6": 44453.57931337329,
    "YEAR_7": 46199.490599914956,
    "YEAR_8": 47945.40188645663,
    "YEAR_9": 49691.3131729983,
    "YEAR_10": 51437.224459539975,
    "FIXED_cLINK": 427038.9101639716
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1589733740,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1351273.679,
    "% of cLINKAllocation to Nexus": 13512.73679,
    "Per Month": 1126.0613991666667,
    "YEAR_1": 10430.096415202486,
    "YEAR_2": 14497.88286567495,
    "YEAR_3": 15173.411603280629,
    "YEAR_4": 15848.940340886313,
    "YEAR_5": 16524.46907849199,
    "YEAR_6": 17199.99781609767,
    "YEAR_7": 17875.52655370335,
    "YEAR_8": 18551.055291309032,
    "YEAR_9": 19226.584028914713,
    "YEAR_10": 19902.11276652039,
    "FIXED_cLINK": 165230.07676008152
  },
  {
    "DMA": "San Diego",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1995000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1695750,
    "% of cLINKAllocation to Nexus": 16957.5,
    "Per Month": 1413.125,
    "YEAR_1": 13089.01096125,
    "YEAR_2": 18193.786537500004,
    "YEAR_3": 19041.525877500004,
    "YEAR_4": 19889.265217499997,
    "YEAR_5": 20737.004557499997,
    "YEAR_6": 21584.743897499997,
    "YEAR_7": 22432.483237499997,
    "YEAR_8": 23280.222577499997,
    "YEAR_9": 24127.961917499997,
    "YEAR_10": 24975.701257499997,
    "FIXED_cLINK": 207351.70603874995
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 3400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2890000,
    "% of cLINKAllocation to Nexus": 28900,
    "Per Month": 2408.3333333333335,
    "YEAR_1": 22307.08635,
    "YEAR_2": 31006.9545,
    "YEAR_3": 32451.723300000005,
    "YEAR_4": 33896.4921,
    "YEAR_5": 35341.2609,
    "YEAR_6": 36786.0297,
    "YEAR_7": 38230.798500000004,
    "YEAR_8": 39675.56730000001,
    "YEAR_9": 41120.3361,
    "YEAR_10": 42565.1049,
    "FIXED_cLINK": 353381.35365
  },
  {
    "DMA": "Columbus, OH",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2267326630,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1927227.6354999999,
    "% of cLINKAllocation to Nexus": 19272.276354999998,
    "Per Month": 1606.0230295833333,
    "YEAR_1": 14875.720858548382,
    "YEAR_2": 20677.321662661274,
    "YEAR_3": 21640.78130220043,
    "YEAR_4": 22604.240941739594,
    "YEAR_5": 23567.700581278754,
    "YEAR_6": 24531.160220817914,
    "YEAR_7": 25494.619860357074,
    "YEAR_8": 26458.07949989623,
    "YEAR_9": 27421.539139435394,
    "YEAR_10": 28384.99877897455,
    "FIXED_cLINK": 235656.1628459096
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2060000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1751000,
    "% of cLINKAllocation to Nexus": 17510,
    "Per Month": 1459.1666666666667,
    "YEAR_1": 13515.469965000002,
    "YEAR_2": 18786.56655,
    "YEAR_3": 19661.926470000002,
    "YEAR_4": 20537.28639,
    "YEAR_5": 21412.646310000004,
    "YEAR_6": 22288.00623,
    "YEAR_7": 23163.36615,
    "YEAR_8": 24038.726070000008,
    "YEAR_9": 24914.08599,
    "YEAR_10": 25789.445910000002,
    "FIXED_cLINK": 214107.52603500002
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1190303210,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1011757.7285,
    "% of cLINKAllocation to Nexus": 10117.577285,
    "Per Month": 843.1314404166666,
    "YEAR_1": 7809.469555338877,
    "YEAR_2": 10855.199256962926,
    "YEAR_3": 11360.997180594644,
    "YEAR_4": 11866.795104226363,
    "YEAR_5": 12372.593027858082,
    "YEAR_6": 12878.390951489804,
    "YEAR_7": 13384.188875121525,
    "YEAR_8": 13889.986798753243,
    "YEAR_9": 14395.784722384964,
    "YEAR_10": 14901.582646016683,
    "FIXED_cLINK": 123714.98811874713
  },
  {
    "DMA": "Austin",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 5500000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 4675000,
    "% of cLINKAllocation to Nexus": 46750,
    "Per Month": 3895.8333333333335,
    "YEAR_1": 36084.992625,
    "YEAR_2": 50158.30875,
    "YEAR_3": 52495.43475,
    "YEAR_4": 54832.56075,
    "YEAR_5": 57169.68674999999,
    "YEAR_6": 59506.81275,
    "YEAR_7": 61843.93875,
    "YEAR_8": 64181.064750000005,
    "YEAR_9": 66518.19075,
    "YEAR_10": 68855.31675,
    "FIXED_cLINK": 571646.3073750001
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 442622720,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 376229.312,
    "% of cLINKAllocation to Nexus": 3762.29312,
    "Per Month": 313.52442666666667,
    "YEAR_1": 2904.00683397408,
    "YEAR_2": 4036.5830999136,
    "YEAR_3": 4224.66765756864,
    "YEAR_4": 4412.75221522368,
    "YEAR_5": 4600.836772878721,
    "YEAR_6": 4788.92133053376,
    "YEAR_7": 4977.005888188801,
    "YEAR_8": 5165.09044584384,
    "YEAR_9": 5353.175003498881,
    "YEAR_10": 5541.25956115392,
    "FIXED_cLINK": 46004.29880877792
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 15500000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 13175000,
    "% of cLINKAllocation to Nexus": 131750,
    "Per Month": 10979.166666666666,
    "YEAR_1": 101694.070125,
    "YEAR_2": 141355.23374999998,
    "YEAR_3": 147941.67975,
    "YEAR_4": 154528.12575,
    "YEAR_5": 161114.57174999997,
    "YEAR_6": 167701.01775000003,
    "YEAR_7": 174287.46375,
    "YEAR_8": 180873.90974999996,
    "YEAR_9": 187460.35575000002,
    "YEAR_10": 194046.80174999998,
    "FIXED_cLINK": 1611003.2298749997
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1373900395,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1167815.3357499999,
    "% of cLINKAllocation to Nexus": 11678.1533575,
    "Per Month": 973.1794464583332,
    "YEAR_1": 9014.03374928356,
    "YEAR_2": 12529.549128028537,
    "YEAR_3": 13113.363370676674,
    "YEAR_4": 13697.177613324817,
    "YEAR_5": 14280.991855972956,
    "YEAR_6": 14864.806098621097,
    "YEAR_7": 15448.620341269238,
    "YEAR_8": 16032.434583917375,
    "YEAR_9": 16616.248826565516,
    "YEAR_10": 17200.063069213655,
    "FIXED_cLINK": 142797.28863687342
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 8300000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 7055000,
    "% of cLINKAllocation to Nexus": 70550,
    "Per Month": 5879.166666666667,
    "YEAR_1": 54455.534325000015,
    "YEAR_2": 75693.44775,
    "YEAR_3": 79220.38335,
    "YEAR_4": 82747.31895,
    "YEAR_5": 86274.25455,
    "YEAR_6": 89801.19015,
    "YEAR_7": 93328.12574999999,
    "YEAR_8": 96855.06135,
    "YEAR_9": 100381.99695,
    "YEAR_10": 103908.93255,
    "FIXED_cLINK": 862666.2456749999
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1323016032,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1124563.6272,
    "% of cLINKAllocation to Nexus": 11245.636272,
    "Per Month": 937.136356,
    "YEAR_1": 8680.186137723047,
    "YEAR_2": 12065.49938441016,
    "YEAR_3": 12627.691232919984,
    "YEAR_4": 13189.883081429807,
    "YEAR_5": 13752.074929939632,
    "YEAR_6": 14314.266778449455,
    "YEAR_7": 14876.458626959282,
    "YEAR_8": 15438.650475469105,
    "YEAR_9": 16000.842323978926,
    "YEAR_10": 16563.03417248875,
    "FIXED_cLINK": 137508.58714376815
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1850000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1572500,
    "% of cLINKAllocation to Nexus": 15725,
    "Per Month": 1310.4166666666667,
    "YEAR_1": 12137.679337500002,
    "YEAR_2": 16871.431125,
    "YEAR_3": 17657.555325,
    "YEAR_4": 18443.679525000003,
    "YEAR_5": 19229.803725,
    "YEAR_6": 20015.927925000004,
    "YEAR_7": 20802.052125000006,
    "YEAR_8": 21588.176325,
    "YEAR_9": 22374.300525,
    "YEAR_10": 23160.424725,
    "FIXED_cLINK": 192281.0306625
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 455000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 386750,
    "% of cLINKAllocation to Nexus": 3867.5,
    "Per Month": 322.2916666666667,
    "YEAR_1": 2985.21302625,
    "YEAR_2": 4149.460087500001,
    "YEAR_3": 4342.8041475,
    "YEAR_4": 4536.1482075,
    "YEAR_5": 4729.492267500001,
    "YEAR_6": 4922.8363275,
    "YEAR_7": 5116.1803875000005,
    "YEAR_8": 5309.5244475,
    "YEAR_9": 5502.8685075,
    "YEAR_10": 5696.212567500001,
    "FIXED_cLINK": 47290.73997375
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 4700000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 3995000,
    "% of cLINKAllocation to Nexus": 39950,
    "Per Month": 3329.1666666666665,
    "YEAR_1": 30836.266424999998,
    "YEAR_2": 42862.554749999996,
    "YEAR_3": 44859.73514999999,
    "YEAR_4": 46856.91555,
    "YEAR_5": 48854.095949999995,
    "YEAR_6": 50851.27635,
    "YEAR_7": 52848.45675,
    "YEAR_8": 54845.63715,
    "YEAR_9": 56842.81755000001,
    "YEAR_10": 58839.997950000004,
    "FIXED_cLINK": 488497.753575
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 3500000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2975000,
    "% of cLINKAllocation to Nexus": 29750,
    "Per Month": 2479.1666666666665,
    "YEAR_1": 22963.177125,
    "YEAR_2": 31918.923749999994,
    "YEAR_3": 33406.18575,
    "YEAR_4": 34893.44775,
    "YEAR_5": 36380.70975,
    "YEAR_6": 37867.97175,
    "YEAR_7": 39355.23375,
    "YEAR_8": 40842.49575,
    "YEAR_9": 42329.75775,
    "YEAR_10": 43817.01975,
    "FIXED_cLINK": 363774.92287499993
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 862000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 732700,
    "% of cLINKAllocation to Nexus": 7327,
    "Per Month": 610.5833333333334,
    "YEAR_1": 5655.502480499999,
    "YEAR_2": 7861.174935,
    "YEAR_3": 8227.466319000001,
    "YEAR_4": 8593.757703000001,
    "YEAR_5": 8960.049087,
    "YEAR_6": 9326.340471000001,
    "YEAR_7": 9692.631855000001,
    "YEAR_8": 10058.923239000002,
    "YEAR_9": 10425.214623000002,
    "YEAR_10": 10791.506007,
    "FIXED_cLINK": 89592.56671950001
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1836069415,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1560659.00275,
    "% of cLINKAllocation to Nexus": 15606.5900275,
    "Per Month": 1300.5491689583334,
    "YEAR_1": 12046.282054411466,
    "YEAR_2": 16744.38847345489,
    "YEAR_3": 17524.59312210967,
    "YEAR_4": 18304.79777076445,
    "YEAR_5": 19085.002419419226,
    "YEAR_6": 19865.207068074007,
    "YEAR_7": 20645.41171672879,
    "YEAR_8": 21425.616365383572,
    "YEAR_9": 22205.82101403835,
    "YEAR_10": 22986.02566269313,
    "FIXED_cLINK": 190833.14566707757
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 641280600,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 545088.51,
    "% of cLINKAllocation to Nexus": 5450.8851,
    "Per Month": 454.240425,
    "YEAR_1": 4207.38285846465,
    "YEAR_2": 5848.2818782155,
    "YEAR_3": 6120.782526134701,
    "YEAR_4": 6393.2831740539,
    "YEAR_5": 6665.7838219731,
    "YEAR_6": 6938.2844698923,
    "YEAR_7": 7210.785117811501,
    "YEAR_8": 7483.2857657307,
    "YEAR_9": 7755.786413649901,
    "YEAR_10": 8028.287061569101,
    "FIXED_cLINK": 66651.94308749537
  },
  {
    "DMA": "Louisville",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 924474500,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 785803.325,
    "% of cLINKAllocation to Nexus": 7858.0332499999995,
    "Per Month": 654.8361041666666,
    "YEAR_1": 6065.3919117273745,
    "YEAR_2": 8430.92316409125,
    "YEAR_3": 8823.76196232525,
    "YEAR_4": 9216.600760559251,
    "YEAR_5": 9609.439558793249,
    "YEAR_6": 10002.27835702725,
    "YEAR_7": 10395.11715526125,
    "YEAR_8": 10787.955953495248,
    "YEAR_9": 11180.794751729249,
    "YEAR_10": 11573.63354996325,
    "FIXED_cLINK": 96085.89712497263
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 21000000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 17850000,
    "% of cLINKAllocation to Nexus": 178500,
    "Per Month": 14875,
    "YEAR_1": 137779.06275,
    "YEAR_2": 191513.54249999998,
    "YEAR_3": 200437.11450000003,
    "YEAR_4": 209360.6865,
    "YEAR_5": 218284.25850000003,
    "YEAR_6": 227207.8305,
    "YEAR_7": 236131.40249999997,
    "YEAR_8": 245054.9745,
    "YEAR_9": 253978.54650000003,
    "YEAR_10": 262902.1185,
    "FIXED_cLINK": 2182649.53725
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1474153974,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1253030.8779,
    "% of cLINKAllocation to Nexus": 12530.308778999999,
    "Per Month": 1044.19239825,
    "YEAR_1": 9671.788232709898,
    "YEAR_2": 13443.830940532995,
    "YEAR_3": 14070.246137012762,
    "YEAR_4": 14696.661333492531,
    "YEAR_5": 15323.076529972299,
    "YEAR_6": 15949.491726452066,
    "YEAR_7": 16575.906922931834,
    "YEAR_8": 17202.3221194116,
    "YEAR_9": 17828.737315891372,
    "YEAR_10": 18455.15251237114,
    "FIXED_cLINK": 153217.2137707785
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1038840000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 883014,
    "% of cLINKAllocation to Nexus": 8830.14,
    "Per Month": 735.8449999999999,
    "YEAR_1": 6815.73340701,
    "YEAR_2": 9473.9013567,
    "YEAR_3": 9915.337715579999,
    "YEAR_4": 10356.774074459998,
    "YEAR_5": 10798.210433339998,
    "YEAR_6": 11239.646792219999,
    "YEAR_7": 11681.0831511,
    "YEAR_8": 12122.51950998,
    "YEAR_9": 12563.955868859997,
    "YEAR_10": 13005.392227739998,
    "FIXED_cLINK": 107972.55453698999
  },
  {
    "DMA": "Memphis",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 792000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 673200,
    "% of cLINKAllocation to Nexus": 6732,
    "Per Month": 561,
    "YEAR_1": 5196.238938,
    "YEAR_2": 7222.7964600000005,
    "YEAR_3": 7559.3426039999995,
    "YEAR_4": 7895.888747999999,
    "YEAR_5": 8232.434892000001,
    "YEAR_6": 8568.981036,
    "YEAR_7": 8905.527180000001,
    "YEAR_8": 9242.073324,
    "YEAR_9": 9578.619467999999,
    "YEAR_10": 9915.165612,
    "FIXED_cLINK": 82317.068262
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 596063300,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 506653.805,
    "% of cLINKAllocation to Nexus": 5066.53805,
    "Per Month": 422.21150416666666,
    "YEAR_1": 3910.7163244605754,
    "YEAR_2": 5435.91400653525,
    "YEAR_3": 5689.20037673085,
    "YEAR_4": 5942.48674692645,
    "YEAR_5": 6195.773117122049,
    "YEAR_6": 6449.059487317651,
    "YEAR_7": 6702.345857513251,
    "YEAR_8": 6955.63222770885,
    "YEAR_9": 7208.9185979044505,
    "YEAR_10": 7462.204968100051,
    "FIXED_cLINK": 61952.251710319426
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1773182473,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1507205.1020499999,
    "% of cLINKAllocation to Nexus": 15072.0510205,
    "Per Month": 1256.0042517083332,
    "YEAR_1": 11633.686629269863,
    "YEAR_2": 16170.878900149552,
    "YEAR_3": 16924.36087476639,
    "YEAR_4": 17677.842849383225,
    "YEAR_5": 18431.32482400006,
    "YEAR_6": 19184.806798616897,
    "YEAR_7": 19938.288773233733,
    "YEAR_8": 20691.77074785057,
    "YEAR_9": 21445.252722467405,
    "YEAR_10": 22198.73469708424,
    "FIXED_cLINK": 184296.94781682192
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1073650000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 912602.5,
    "% of cLINKAllocation to Nexus": 9126.025,
    "Per Month": 760.5020833333333,
    "YEAR_1": 7044.118605787499,
    "YEAR_2": 9791.357852625,
    "YEAR_3": 10247.586094425002,
    "YEAR_4": 10703.814336225001,
    "YEAR_5": 11160.042578025,
    "YEAR_6": 11616.270819825,
    "YEAR_7": 12072.499061625,
    "YEAR_8": 12528.727303425,
    "YEAR_9": 12984.955545225,
    "YEAR_10": 13441.183787025,
    "FIXED_cLINK": 111590.55598421251
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2442000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2075700,
    "% of cLINKAllocation to Nexus": 20757,
    "Per Month": 1729.75,
    "YEAR_1": 16021.7367255,
    "YEAR_2": 22270.289085,
    "YEAR_3": 23307.973029,
    "YEAR_4": 24345.656973,
    "YEAR_5": 25383.340916999998,
    "YEAR_6": 26421.024861,
    "YEAR_7": 27458.708805000002,
    "YEAR_8": 28496.392749000002,
    "YEAR_9": 29534.076693,
    "YEAR_10": 30571.760637,
    "FIXED_cLINK": 253810.9604745
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 171033817,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 145378.74445,
    "% of cLINKAllocation to Nexus": 1453.7874445,
    "Per Month": 121.14895370833334,
    "YEAR_1": 1122.1370954673816,
    "YEAR_2": 1559.7758181412728,
    "YEAR_3": 1632.4535600667166,
    "YEAR_4": 1705.1313019921606,
    "YEAR_5": 1777.8090439176046,
    "YEAR_6": 1850.4867858430487,
    "YEAR_7": 1923.1645277684927,
    "YEAR_8": 1995.8422696939367,
    "YEAR_9": 2068.520011619381,
    "YEAR_10": 2141.197753544825,
    "FIXED_cLINK": 17776.51816805482
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 629668295,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 535218.0507499999,
    "% of cLINKAllocation to Nexus": 5352.180507499999,
    "Per Month": 446.0150422916666,
    "YEAR_1": 4131.1955965947855,
    "YEAR_2": 5742.381227399287,
    "YEAR_3": 6009.947435330227,
    "YEAR_4": 6277.513643261166,
    "YEAR_5": 6545.079851192106,
    "YEAR_6": 6812.646059123046,
    "YEAR_7": 7080.212267053987,
    "YEAR_8": 7347.778474984927,
    "YEAR_9": 7615.344682915866,
    "YEAR_10": 7882.910890846806,
    "FIXED_cLINK": 65445.0101287022
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2752493000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2339619.05,
    "% of cLINKAllocation to Nexus": 23396.190499999997,
    "Per Month": 1949.6825416666663,
    "YEAR_1": 18058.85265552075,
    "YEAR_2": 25101.889768402496,
    "YEAR_3": 26271.512123878492,
    "YEAR_4": 27441.134479354496,
    "YEAR_5": 28610.756834830492,
    "YEAR_6": 29780.379190306496,
    "YEAR_7": 30950.001545782492,
    "YEAR_8": 32119.623901258496,
    "YEAR_9": 33289.24625673449,
    "YEAR_10": 34458.86861221049,
    "FIXED_cLINK": 286082.2653682792
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 465000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 395250,
    "% of cLINKAllocation to Nexus": 3952.5,
    "Per Month": 329.375,
    "YEAR_1": 3050.8221037499998,
    "YEAR_2": 4240.657012500001,
    "YEAR_3": 4438.2503925,
    "YEAR_4": 4635.8437725,
    "YEAR_5": 4833.437152500001,
    "YEAR_6": 5031.030532500001,
    "YEAR_7": 5228.6239125,
    "YEAR_8": 5426.217292499999,
    "YEAR_9": 5623.8106725,
    "YEAR_10": 5821.404052500001,
    "FIXED_cLINK": 48330.096896250005
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 559516800,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 475589.27999999997,
    "% of cLINKAllocation to Nexus": 4755.8928,
    "Per Month": 396.32439999999997,
    "YEAR_1": 3670.9381093752,
    "YEAR_2": 5102.621164584,
    "YEAR_3": 5340.3777574416,
    "YEAR_4": 5578.1343502992,
    "YEAR_5": 5815.8909431568,
    "YEAR_6": 6053.6475360143995,
    "YEAR_7": 6291.404128872,
    "YEAR_8": 6529.1607217296005,
    "YEAR_9": 6766.917314587199,
    "YEAR_10": 7004.6739074448,
    "FIXED_cLINK": 58153.76593350479
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 837827000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 712152.95,
    "% of cLINKAllocation to Nexus": 7121.5295,
    "Per Month": 593.4607916666666,
    "YEAR_1": 5496.9056574592505,
    "YEAR_2": 7640.724608197499,
    "YEAR_3": 7996.744110961499,
    "YEAR_4": 8352.7636137255,
    "YEAR_5": 8708.7831164895,
    "YEAR_6": 9064.8026192535,
    "YEAR_7": 9420.8221220175,
    "YEAR_8": 9776.8416247815,
    "YEAR_9": 10132.861127545497,
    "YEAR_10": 10488.880630309497,
    "FIXED_cLINK": 87080.12923074074
  },
  {
    "DMA": "Lexington",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 915000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 777750,
    "% of cLINKAllocation to Nexus": 7777.5,
    "Per Month": 648.125,
    "YEAR_1": 6003.2305912500005,
    "YEAR_2": 8344.5186375,
    "YEAR_3": 8733.3314175,
    "YEAR_4": 9122.1441975,
    "YEAR_5": 9510.9569775,
    "YEAR_6": 9899.7697575,
    "YEAR_7": 10288.5825375,
    "YEAR_8": 10677.3953175,
    "YEAR_9": 11066.208097500003,
    "YEAR_10": 11455.020877500003,
    "FIXED_cLINK": 95101.15840875
  },
  {
    "DMA": "Dayton",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 505031358,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 429276.6543,
    "% of cLINKAllocation to Nexus": 4292.766543,
    "Per Month": 357.73054525,
    "YEAR_1": 3313.4641506952244,
    "YEAR_2": 4605.730687817414,
    "YEAR_3": 4820.334672835071,
    "YEAR_4": 5034.938657852727,
    "YEAR_5": 5249.542642870382,
    "YEAR_6": 5464.146627888038,
    "YEAR_7": 5678.750612905696,
    "YEAR_8": 5893.35459792335,
    "YEAR_9": 6107.958582941006,
    "YEAR_10": 6322.562567958664,
    "FIXED_cLINK": 52490.783801687576
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 807257124,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 686168.5554,
    "% of cLINKAllocation to Nexus": 6861.685554,
    "Per Month": 571.8071295,
    "YEAR_1": 5296.33952109431,
    "YEAR_2": 7361.936739314369,
    "YEAR_3": 7704.966123529936,
    "YEAR_4": 8047.995507745506,
    "YEAR_5": 8391.024891961073,
    "YEAR_6": 8734.054276176643,
    "YEAR_7": 9077.083660392209,
    "YEAR_8": 9420.113044607779,
    "YEAR_9": 9763.142428823347,
    "YEAR_10": 10106.171813038913,
    "FIXED_cLINK": 83902.82800668408
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1750395527,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1487836.19795,
    "% of cLINKAllocation to Nexus": 14878.3619795,
    "Per Month": 1239.8634982916667,
    "YEAR_1": 11484.183578659635,
    "YEAR_2": 15963.068959615448,
    "YEAR_3": 16706.868031694612,
    "YEAR_4": 17450.667103773776,
    "YEAR_5": 18194.46617585294,
    "YEAR_6": 18938.2652479321,
    "YEAR_7": 19682.064320011268,
    "YEAR_8": 20425.863392090432,
    "YEAR_9": 21169.662464169596,
    "YEAR_10": 21913.46153624876,
    "FIXED_cLINK": 181928.57081004858
  },
  {
    "DMA": "Spokane",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1162124296,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 987805.6516,
    "% of cLINKAllocation to Nexus": 9878.056516,
    "Per Month": 823.1713763333333,
    "YEAR_1": 7624.590300089695,
    "YEAR_2": 10598.21622629898,
    "YEAR_3": 11092.040027646852,
    "YEAR_4": 11585.863828994721,
    "YEAR_5": 12079.687630342594,
    "YEAR_6": 12573.511431690473,
    "YEAR_7": 13067.335233038342,
    "YEAR_8": 13561.159034386217,
    "YEAR_9": 14054.982835734087,
    "YEAR_10": 14548.806637081958,
    "FIXED_cLINK": 120786.19318530391
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 195971150,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 166575.47749999998,
    "% of cLINKAllocation to Nexus": 1665.7547749999999,
    "Per Month": 138.81289791666666,
    "YEAR_1": 1285.7486368114126,
    "YEAR_2": 1787.1966268713747,
    "YEAR_3": 1870.4710395831748,
    "YEAR_4": 1953.745452294975,
    "YEAR_5": 2037.0198650067748,
    "YEAR_6": 2120.294277718575,
    "YEAR_7": 2203.568690430375,
    "YEAR_8": 2286.843103142175,
    "YEAR_9": 2370.1175158539745,
    "YEAR_10": 2453.3919285657753,
    "FIXED_cLINK": 20368.397136278585
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1190000,
    "% of cLINKAllocation to Nexus": 11900,
    "Per Month": 991.6666666666666,
    "YEAR_1": 9185.270849999999,
    "YEAR_2": 12767.569499999998,
    "YEAR_3": 13362.4743,
    "YEAR_4": 13957.379099999998,
    "YEAR_5": 14552.283899999999,
    "YEAR_6": 15147.1887,
    "YEAR_7": 15742.093499999999,
    "YEAR_8": 16336.9983,
    "YEAR_9": 16931.9031,
    "YEAR_10": 17526.8079,
    "FIXED_cLINK": 145509.96915000002
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 825000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 701250,
    "% of cLINKAllocation to Nexus": 7012.5,
    "Per Month": 584.375,
    "YEAR_1": 5412.74889375,
    "YEAR_2": 7523.746312500001,
    "YEAR_3": 7874.3152125,
    "YEAR_4": 8224.8841125,
    "YEAR_5": 8575.4530125,
    "YEAR_6": 8926.0219125,
    "YEAR_7": 9276.5908125,
    "YEAR_8": 9627.159712499999,
    "YEAR_9": 9977.7286125,
    "YEAR_10": 10328.2975125,
    "FIXED_cLINK": 85746.94610624999
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 363524000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 308995.39999999997,
    "% of cLINKAllocation to Nexus": 3089.9539999999997,
    "Per Month": 257.4961666666666,
    "YEAR_1": 2385.0474289109998,
    "YEAR_2": 3315.2270963699993,
    "YEAR_3": 3469.700076737999,
    "YEAR_4": 3624.1730571059998,
    "YEAR_5": 3778.6460374739995,
    "YEAR_6": 3933.1190178419993,
    "YEAR_7": 4087.5919982099995,
    "YEAR_8": 4242.064978578,
    "YEAR_9": 4396.537958946,
    "YEAR_10": 4551.010939313999,
    "FIXED_cLINK": 37783.118589489
  },
  {
    "DMA": "Madison",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 381900000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 324615,
    "% of cLINKAllocation to Nexus": 3246.15,
    "Per Month": 270.5125,
    "YEAR_1": 2505.6106697249998,
    "YEAR_2": 3482.8105657499996,
    "YEAR_3": 3645.0920965499995,
    "YEAR_4": 3807.3736273499994,
    "YEAR_5": 3969.65515815,
    "YEAR_6": 4131.936688950001,
    "YEAR_7": 4294.21821975,
    "YEAR_8": 4456.4997505500005,
    "YEAR_9": 4618.78128135,
    "YEAR_10": 4781.062812149999,
    "FIXED_cLINK": 39693.040870275
  },
  {
    "DMA": "Omaha",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1350000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1147500,
    "% of cLINKAllocation to Nexus": 11475,
    "Per Month": 956.25,
    "YEAR_1": 8857.2254625,
    "YEAR_2": 12311.584875,
    "YEAR_3": 12885.243075,
    "YEAR_4": 13458.901274999998,
    "YEAR_5": 14032.559474999998,
    "YEAR_6": 14606.217675,
    "YEAR_7": 15179.875875000002,
    "YEAR_8": 15753.534075,
    "YEAR_9": 16327.192275,
    "YEAR_10": 16900.850475,
    "FIXED_cLINK": 140313.1845375
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1079300000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 917405,
    "% of cLINKAllocation to Nexus": 9174.050000000001,
    "Per Month": 764.5041666666667,
    "YEAR_1": 7081.187734575001,
    "YEAR_2": 9842.884115249999,
    "YEAR_3": 10301.513222850002,
    "YEAR_4": 10760.14233045,
    "YEAR_5": 11218.771438049998,
    "YEAR_6": 11677.400545650002,
    "YEAR_7": 12136.029653250002,
    "YEAR_8": 12594.65876085,
    "YEAR_9": 13053.287868450001,
    "YEAR_10": 13511.916976050003,
    "FIXED_cLINK": 112177.79264542501
  },
  {
    "DMA": "Springfield, MO",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 495690888,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 421337.2548,
    "% of cLINKAllocation to Nexus": 4213.372548,
    "Per Month": 351.11437900000004,
    "YEAR_1": 3252.182188683582,
    "YEAR_2": 4520.548473611941,
    "YEAR_3": 4731.183394031556,
    "YEAR_4": 4941.818314451173,
    "YEAR_5": 5152.453234870789,
    "YEAR_6": 5363.088155290405,
    "YEAR_7": 5573.7230757100215,
    "YEAR_8": 5784.357996129636,
    "YEAR_9": 5994.992916549252,
    "YEAR_10": 6205.627836968869,
    "FIXED_cLINK": 51519.97558629721
  },
  {
    "DMA": "Columbia, SC",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 425787861,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 361919.68185,
    "% of cLINKAllocation to Nexus": 3619.1968185,
    "Per Month": 301.599734875,
    "YEAR_1": 2793.554877090823,
    "YEAR_2": 3883.0543625527425,
    "YEAR_3": 4063.9852499031945,
    "YEAR_4": 4244.916137253646,
    "YEAR_5": 4425.847024604099,
    "YEAR_6": 4606.777911954551,
    "YEAR_7": 4787.708799305004,
    "YEAR_8": 4968.639686655455,
    "YEAR_9": 5149.5705740059075,
    "YEAR_10": 5330.501461356358,
    "FIXED_cLINK": 44254.55608468178
  },
  {
    "DMA": "Rochester, NY",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 675000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 573750,
    "% of cLINKAllocation to Nexus": 5737.5,
    "Per Month": 478.125,
    "YEAR_1": 4428.61273125,
    "YEAR_2": 6155.7924375,
    "YEAR_3": 6442.6215375,
    "YEAR_4": 6729.450637499999,
    "YEAR_5": 7016.279737499999,
    "YEAR_6": 7303.1088375,
    "YEAR_7": 7589.937937500001,
    "YEAR_8": 7876.7670375,
    "YEAR_9": 8163.5961375,
    "YEAR_10": 8450.4252375,
    "FIXED_cLINK": 70156.59226875
  },
  {
    "DMA": "Portland",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1000263532,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 850224.0022,
    "% of cLINKAllocation to Nexus": 8502.240022,
    "Per Month": 708.5200018333334,
    "YEAR_1": 6562.6367591411745,
    "YEAR_2": 9122.095830803908,
    "YEAR_3": 9547.139813983735,
    "YEAR_4": 9972.183797163558,
    "YEAR_5": 10397.227780343384,
    "YEAR_6": 10822.271763523206,
    "YEAR_7": 11247.315746703034,
    "YEAR_8": 11672.359729882855,
    "YEAR_9": 12097.403713062678,
    "YEAR_10": 12522.447696242505,
    "FIXED_cLINK": 103963.08263085004
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 267721674,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 227563.42289999998,
    "% of cLINKAllocation to Nexus": 2275.634229,
    "Per Month": 189.63618574999998,
    "YEAR_1": 1756.4972057895734,
    "YEAR_2": 2441.5393424652448,
    "YEAR_3": 2555.3028488414125,
    "YEAR_4": 2669.0663552175806,
    "YEAR_5": 2782.8298615937483,
    "YEAR_6": 2896.5933679699165,
    "YEAR_7": 3010.3568743460846,
    "YEAR_8": 3124.120380722253,
    "YEAR_9": 3237.883887098421,
    "YEAR_10": 3351.6473934745886,
    "FIXED_cLINK": 27825.837517518823
  },
  {
    "DMA": "Toledo",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 995296702,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 846002.1967,
    "% of cLINKAllocation to Nexus": 8460.021967,
    "Per Month": 705.0018305833333,
    "YEAR_1": 6530.049845701241,
    "YEAR_2": 9076.799868504135,
    "YEAR_3": 9499.7332866784,
    "YEAR_4": 9922.666704852661,
    "YEAR_5": 10345.600123026927,
    "YEAR_6": 10768.533541201192,
    "YEAR_7": 11191.466959375455,
    "YEAR_8": 11614.400377549719,
    "YEAR_9": 12037.333795723984,
    "YEAR_10": 12460.267213898249,
    "FIXED_cLINK": 103446.85171651196
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 3600000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 3060000,
    "% of cLINKAllocation to Nexus": 30600,
    "Per Month": 2550,
    "YEAR_1": 23619.2679,
    "YEAR_2": 32830.893,
    "YEAR_3": 34360.6482,
    "YEAR_4": 35890.4034,
    "YEAR_5": 37420.1586,
    "YEAR_6": 38949.9138,
    "YEAR_7": 40479.669,
    "YEAR_8": 42009.424199999994,
    "YEAR_9": 43539.17939999999,
    "YEAR_10": 45068.9346,
    "FIXED_cLINK": 374168.4921
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1234690000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1049486.5,
    "% of cLINKAllocation to Nexus": 10494.865,
    "Per Month": 874.5720833333334,
    "YEAR_1": 8100.6871898475,
    "YEAR_2": 11259.993132825,
    "YEAR_3": 11784.652423905001,
    "YEAR_4": 12309.311714985,
    "YEAR_5": 12833.971006065003,
    "YEAR_6": 13358.630297145,
    "YEAR_7": 13883.289588224998,
    "YEAR_8": 14407.948879305,
    "YEAR_9": 14932.608170385003,
    "YEAR_10": 15457.267461465002,
    "FIXED_cLINK": 128328.35986415252
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2200000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1870000,
    "% of cLINKAllocation to Nexus": 18700,
    "Per Month": 1558.3333333333333,
    "YEAR_1": 14433.99705,
    "YEAR_2": 20063.3235,
    "YEAR_3": 20998.1739,
    "YEAR_4": 21933.024300000005,
    "YEAR_5": 22867.874699999997,
    "YEAR_6": 23802.7251,
    "YEAR_7": 24737.5755,
    "YEAR_8": 25672.425900000002,
    "YEAR_9": 26607.2763,
    "YEAR_10": 27542.126699999997,
    "FIXED_cLINK": 228658.52294999998
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 350000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 297500,
    "% of cLINKAllocation to Nexus": 2975,
    "Per Month": 247.91666666666666,
    "YEAR_1": 2296.3177124999997,
    "YEAR_2": 3191.8923749999994,
    "YEAR_3": 3340.618575,
    "YEAR_4": 3489.3447749999996,
    "YEAR_5": 3638.0709749999996,
    "YEAR_6": 3786.797175,
    "YEAR_7": 3935.5233749999998,
    "YEAR_8": 4084.249575,
    "YEAR_9": 4232.975775,
    "YEAR_10": 4381.701975,
    "FIXED_cLINK": 36377.492287500005
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 869081037,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 738718.88145,
    "% of cLINKAllocation to Nexus": 7387.1888145,
    "Per Month": 615.5990678750001,
    "YEAR_1": 5701.960511031338,
    "YEAR_2": 7925.751815021124,
    "YEAR_3": 8295.052158235607,
    "YEAR_4": 8664.352501450092,
    "YEAR_5": 9033.652844664575,
    "YEAR_6": 9402.95318787906,
    "YEAR_7": 9772.253531093544,
    "YEAR_8": 10141.553874308027,
    "YEAR_9": 10510.85421752251,
    "YEAR_10": 10880.154560736995,
    "FIXED_cLINK": 90328.53920194287
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2100000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1785000,
    "% of cLINKAllocation to Nexus": 17850,
    "Per Month": 1487.5,
    "YEAR_1": 13777.906275000001,
    "YEAR_2": 19151.35425,
    "YEAR_3": 20043.71145,
    "YEAR_4": 20936.06865,
    "YEAR_5": 21828.42585,
    "YEAR_6": 22720.78305,
    "YEAR_7": 23613.140250000004,
    "YEAR_8": 24505.49745,
    "YEAR_9": 25397.854650000005,
    "YEAR_10": 26290.211850000003,
    "FIXED_cLINK": 218264.953725
  },
  {
    "DMA": "Savannah",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 492629881,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 418735.39885,
    "% of cLINKAllocation to Nexus": 4187.3539885,
    "Per Month": 348.94616570833335,
    "YEAR_1": 3232.099204134478,
    "YEAR_2": 4492.6330310315925,
    "YEAR_3": 4701.967231624685,
    "YEAR_4": 4911.301432217777,
    "YEAR_5": 5120.6356328108695,
    "YEAR_6": 5329.969833403961,
    "YEAR_7": 5539.304033997054,
    "YEAR_8": 5748.638234590145,
    "YEAR_9": 5957.9724351832365,
    "YEAR_10": 6167.306635776328,
    "FIXED_cLINK": 51201.82770477013
  },
  {
    "DMA": "Charleston, SC",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 287771277,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 244605.58544999998,
    "% of cLINKAllocation to Nexus": 2446.0558545,
    "Per Month": 203.83798787499998,
    "YEAR_1": 1888.0408014966965,
    "YEAR_2": 2624.3855565723225,
    "YEAR_3": 2746.668780850486,
    "YEAR_4": 2868.95200512865,
    "YEAR_5": 2991.235229406814,
    "YEAR_6": 3113.5184536849783,
    "YEAR_7": 3235.8016779631425,
    "YEAR_8": 3358.0849022413067,
    "YEAR_9": 3480.368126519471,
    "YEAR_10": 3602.651350797635,
    "FIXED_cLINK": 29909.706884661504
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 589003826,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 500653.2521,
    "% of cLINKAllocation to Nexus": 5006.532521,
    "Per Month": 417.21104341666666,
    "YEAR_1": 3864.3997667830517,
    "YEAR_2": 5371.533774443505,
    "YEAR_3": 5621.8203482333365,
    "YEAR_4": 5872.106922023168,
    "YEAR_5": 6122.393495813002,
    "YEAR_6": 6372.680069602833,
    "YEAR_7": 6622.966643392666,
    "YEAR_8": 6873.253217182498,
    "YEAR_9": 7123.539790972329,
    "YEAR_10": 7373.826364762162,
    "FIXED_cLINK": 61218.52039320856
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 293276194,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 249284.76489999998,
    "% of cLINKAllocation to Nexus": 2492.847649,
    "Per Month": 207.73730408333333,
    "YEAR_1": 1924.1580541051037,
    "YEAR_2": 2674.588706850345,
    "YEAR_3": 2799.2111465191533,
    "YEAR_4": 2923.8335861879614,
    "YEAR_5": 3048.4560258567685,
    "YEAR_6": 3173.0784655255775,
    "YEAR_7": 3297.700905194384,
    "YEAR_8": 3422.323344863193,
    "YEAR_9": 3546.9457845319994,
    "YEAR_10": 3671.5682242008093,
    "FIXED_cLINK": 30481.864243835294
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 540518157,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 459440.43344999995,
    "% of cLINKAllocation to Nexus": 4594.4043345,
    "Per Month": 382.867027875,
    "YEAR_1": 3546.289765277017,
    "YEAR_2": 4929.359382506723,
    "YEAR_3": 5159.0428439970465,
    "YEAR_4": 5388.72630548737,
    "YEAR_5": 5618.409766977696,
    "YEAR_6": 5848.093228468018,
    "YEAR_7": 6077.776689958343,
    "YEAR_8": 6307.460151448668,
    "YEAR_9": 6537.14361293899,
    "YEAR_10": 6766.827074429315,
    "FIXED_cLINK": 56179.12882148918
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 277050000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 235492.5,
    "% of cLINKAllocation to Nexus": 2354.925,
    "Per Month": 196.24375,
    "YEAR_1": 1817.6994921374999,
    "YEAR_2": 2526.610807125,
    "YEAR_3": 2644.3382177249996,
    "YEAR_4": 2762.0656283250005,
    "YEAR_5": 2879.793038925001,
    "YEAR_6": 2997.520449525,
    "YEAR_7": 3115.2478601250004,
    "YEAR_8": 3232.975270725,
    "YEAR_9": 3350.7026813250004,
    "YEAR_10": 3468.430091925,
    "FIXED_cLINK": 28795.3835378625
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 6023145031,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 5119673.27635,
    "% of cLINKAllocation to Nexus": 51196.732763500004,
    "Per Month": 4266.394396958333,
    "YEAR_1": 39517.29891326189,
    "YEAR_2": 54929.23056562297,
    "YEAR_3": 57488.657629935864,
    "YEAR_4": 60048.084694248755,
    "YEAR_5": 62607.511758561646,
    "YEAR_6": 65166.93882287453,
    "YEAR_7": 67726.36588718744,
    "YEAR_8": 70285.79295150032,
    "YEAR_9": 72845.22001581322,
    "YEAR_10": 75404.6470801261,
    "FIXED_cLINK": 626019.7483191327
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 196000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 166600,
    "% of cLINKAllocation to Nexus": 1666,
    "Per Month": 138.83333333333334,
    "YEAR_1": 1285.937919,
    "YEAR_2": 1787.45973,
    "YEAR_3": 1870.7464020000002,
    "YEAR_4": 1954.0330740000004,
    "YEAR_5": 2037.319746,
    "YEAR_6": 2120.6064180000003,
    "YEAR_7": 2203.8930900000005,
    "YEAR_8": 2287.179762,
    "YEAR_9": 2370.4664340000004,
    "YEAR_10": 2453.7531060000006,
    "FIXED_cLINK": 20371.395681
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1900000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1615000,
    "% of cLINKAllocation to Nexus": 16150,
    "Per Month": 1345.8333333333333,
    "YEAR_1": 12465.724725,
    "YEAR_2": 17327.415749999996,
    "YEAR_3": 18134.78655,
    "YEAR_4": 18942.157350000005,
    "YEAR_5": 19749.528150000002,
    "YEAR_6": 20556.898950000003,
    "YEAR_7": 21364.269749999996,
    "YEAR_8": 22171.64055,
    "YEAR_9": 22979.011349999993,
    "YEAR_10": 23786.38215,
    "FIXED_cLINK": 197477.81527499997
  },
  {
    "DMA": "Jackson, MS",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 128806233,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 109485.29805,
    "% of cLINKAllocation to Nexus": 1094.8529805,
    "Per Month": 91.23774837500001,
    "YEAR_1": 845.0858123380058,
    "YEAR_2": 1174.6732370433524,
    "YEAR_3": 1229.4071272445087,
    "YEAR_4": 1284.1410174456646,
    "YEAR_5": 1338.8749076468207,
    "YEAR_6": 1393.6087978479766,
    "YEAR_7": 1448.3426880491327,
    "YEAR_8": 1503.0765782502888,
    "YEAR_9": 1557.8104684514446,
    "YEAR_10": 1612.5443586526007,
    "FIXED_cLINK": 13387.564992969797
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 996000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 846600,
    "% of cLINKAllocation to Nexus": 8466,
    "Per Month": 705.5,
    "YEAR_1": 6534.664119,
    "YEAR_2": 9083.21373,
    "YEAR_3": 9506.446001999999,
    "YEAR_4": 9929.678274,
    "YEAR_5": 10352.910546,
    "YEAR_6": 10776.142818,
    "YEAR_7": 11199.375090000001,
    "YEAR_8": 11622.607361999999,
    "YEAR_9": 12045.839634,
    "YEAR_10": 12469.071906000001,
    "FIXED_cLINK": 103519.949481
  },
  {
    "DMA": "Boise",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 615359516,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 523055.58859999996,
    "% of cLINKAllocation to Nexus": 5230.555886,
    "Per Month": 435.8796571666667,
    "YEAR_1": 4037.317017560649,
    "YEAR_2": 5611.8895628688315,
    "YEAR_3": 5873.375512721743,
    "YEAR_4": 6134.861462574654,
    "YEAR_5": 6396.347412427566,
    "YEAR_6": 6657.8333622804785,
    "YEAR_7": 6919.31931213339,
    "YEAR_8": 7180.805261986303,
    "YEAR_9": 7442.291211839213,
    "YEAR_10": 7703.777161692126,
    "FIXED_cLINK": 63957.81727808495
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2600000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2210000,
    "% of cLINKAllocation to Nexus": 22100,
    "Per Month": 1841.6666666666667,
    "YEAR_1": 17058.36015,
    "YEAR_2": 23711.200500000003,
    "YEAR_3": 24816.0237,
    "YEAR_4": 25920.846900000004,
    "YEAR_5": 27025.670100000003,
    "YEAR_6": 28130.493300000002,
    "YEAR_7": 29235.316500000008,
    "YEAR_8": 30340.139700000003,
    "YEAR_9": 31444.962900000002,
    "YEAR_10": 32549.78610000001,
    "FIXED_cLINK": 270232.79985000007
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 472000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 401200,
    "% of cLINKAllocation to Nexus": 4012,
    "Per Month": 334.3333333333333,
    "YEAR_1": 3096.7484579999996,
    "YEAR_2": 4304.49486,
    "YEAR_3": 4505.062763999999,
    "YEAR_4": 4705.630668,
    "YEAR_5": 4906.198571999999,
    "YEAR_6": 5106.766476,
    "YEAR_7": 5307.334379999999,
    "YEAR_8": 5507.902284,
    "YEAR_9": 5708.470187999999,
    "YEAR_10": 5909.038091999999,
    "FIXED_cLINK": 49057.646742
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 409340221,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 347939.18785,
    "% of cLINKAllocation to Nexus": 3479.3918785,
    "Per Month": 289.94932320833334,
    "YEAR_1": 2685.643428345613,
    "YEAR_2": 3733.056943402042,
    "YEAR_3": 3906.998702192014,
    "YEAR_4": 4080.9404609819867,
    "YEAR_5": 4254.882219771958,
    "YEAR_6": 4428.823978561931,
    "YEAR_7": 4602.765737351902,
    "YEAR_8": 4776.707496141875,
    "YEAR_9": 4950.649254931846,
    "YEAR_10": 5124.591013721819,
    "FIXED_cLINK": 42545.05923540298
  },
  {
    "DMA": "Reno",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 951940937,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 809149.7964499999,
    "% of cLINKAllocation to Nexus": 8091.497964499999,
    "Per Month": 674.2914970416666,
    "YEAR_1": 6245.596671105562,
    "YEAR_2": 8681.408623601874,
    "YEAR_3": 9085.918789843156,
    "YEAR_4": 9490.428956084439,
    "YEAR_5": 9894.939122325724,
    "YEAR_6": 10299.449288567008,
    "YEAR_7": 10703.959454808291,
    "YEAR_8": 11108.469621049575,
    "YEAR_9": 11512.97978729086,
    "YEAR_10": 11917.489953532144,
    "FIXED_cLINK": 98940.64026820863
  },
  {
    "DMA": "Washington",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 721449000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 613231.65,
    "% of cLINKAllocation to Nexus": 6132.316500000001,
    "Per Month": 511.0263750000001,
    "YEAR_1": 4733.360335329751,
    "YEAR_2": 6579.393034432502,
    "YEAR_3": 6885.959800900501,
    "YEAR_4": 7192.526567368501,
    "YEAR_5": 7499.093333836501,
    "YEAR_6": 7805.660100304502,
    "YEAR_7": 8112.226866772502,
    "YEAR_8": 8418.793633240502,
    "YEAR_9": 8725.360399708503,
    "YEAR_10": 9031.927166176503,
    "FIXED_cLINK": 74984.30123807027
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 560927156,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 476788.08259999997,
    "% of cLINKAllocation to Nexus": 4767.880826,
    "Per Month": 397.32340216666665,
    "YEAR_1": 3680.191324985859,
    "YEAR_2": 5115.48317761953,
    "YEAR_3": 5353.839075872923,
    "YEAR_4": 5592.194974126313,
    "YEAR_5": 5830.550872379706,
    "YEAR_6": 6068.906770633097,
    "YEAR_7": 6307.26266888649,
    "YEAR_8": 6545.6185671398825,
    "YEAR_9": 6783.974465393274,
    "YEAR_10": 7022.330363646666,
    "FIXED_cLINK": 58300.35226068374
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 200581181,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 170494.00384999998,
    "% of cLINKAllocation to Nexus": 1704.9400385,
    "Per Month": 142.07833654166666,
    "YEAR_1": 1315.9946249270524,
    "YEAR_2": 1829.2386920068418,
    "YEAR_3": 1914.4720544115348,
    "YEAR_4": 1999.705416816227,
    "YEAR_5": 2084.938779220918,
    "YEAR_6": 2170.17214162561,
    "YEAR_7": 2255.4055040303024,
    "YEAR_8": 2340.6388664349947,
    "YEAR_9": 2425.8722288396866,
    "YEAR_10": 2511.1055912443785,
    "FIXED_cLINK": 20847.543899557546
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 478100000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 406385,
    "% of cLINKAllocation to Nexus": 4063.85,
    "Per Month": 338.65416666666664,
    "YEAR_1": 3136.7699952749995,
    "YEAR_2": 4360.124984249999,
    "YEAR_3": 4563.28497345,
    "YEAR_4": 4766.44496265,
    "YEAR_5": 4969.604951849999,
    "YEAR_6": 5172.76494105,
    "YEAR_7": 5375.924930249999,
    "YEAR_8": 5579.08491945,
    "YEAR_9": 5782.24490865,
    "YEAR_10": 5985.4048978499995,
    "FIXED_cLINK": 49691.654464725
  },
  {
    "DMA": "Evansville",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 340000,
    "% of cLINKAllocation to Nexus": 3400,
    "Per Month": 283.3333333333333,
    "YEAR_1": 2624.3631,
    "YEAR_2": 3647.8769999999995,
    "YEAR_3": 3817.8498,
    "YEAR_4": 3987.8225999999995,
    "YEAR_5": 4157.7954,
    "YEAR_6": 4327.7681999999995,
    "YEAR_7": 4497.741,
    "YEAR_8": 4667.7137999999995,
    "YEAR_9": 4837.686599999999,
    "YEAR_10": 5007.6594,
    "FIXED_cLINK": 41574.27689999999
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 168227924,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 142993.7354,
    "% of cLINKAllocation to Nexus": 1429.9373540000001,
    "Per Month": 119.16144616666668,
    "YEAR_1": 1103.727890338011,
    "YEAR_2": 1534.18693679337,
    "YEAR_3": 1605.6723649945382,
    "YEAR_4": 1677.1577931957063,
    "YEAR_5": 1748.6432213968742,
    "YEAR_6": 1820.128649598042,
    "YEAR_7": 1891.61407779921,
    "YEAR_8": 1963.0995060003784,
    "YEAR_9": 2034.5849342015463,
    "YEAR_10": 2106.0703624027137,
    "FIXED_cLINK": 17484.88573672039
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 369200000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 313820,
    "% of cLINKAllocation to Nexus": 3138.2000000000003,
    "Per Month": 261.5166666666667,
    "YEAR_1": 2422.2871413000007,
    "YEAR_2": 3366.9904710000005,
    "YEAR_3": 3523.8753654,
    "YEAR_4": 3680.760259800001,
    "YEAR_5": 3837.6451542000004,
    "YEAR_6": 3994.5300486000006,
    "YEAR_7": 4151.414943000001,
    "YEAR_8": 4308.299837400001,
    "YEAR_9": 4465.184731800001,
    "YEAR_10": 4622.069626200001,
    "FIXED_cLINK": 38373.057578700005
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 179679830,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 152727.8555,
    "% of cLINKAllocation to Nexus": 1527.278555,
    "Per Month": 127.27321291666668,
    "YEAR_1": 1178.8627891656827,
    "YEAR_2": 1638.6247980522753,
    "YEAR_3": 1714.9765075738355,
    "YEAR_4": 1791.3282170953953,
    "YEAR_5": 1867.679926616955,
    "YEAR_6": 1944.031636138515,
    "YEAR_7": 2020.3833456600753,
    "YEAR_8": 2096.7350551816353,
    "YEAR_9": 2173.0867647031955,
    "YEAR_10": 2249.4384742247557,
    "FIXED_cLINK": 18675.14751441232
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2002900000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1702465,
    "% of cLINKAllocation to Nexus": 17024.65,
    "Per Month": 1418.7208333333335,
    "YEAR_1": 13140.842132474998,
    "YEAR_2": 18265.832108250004,
    "YEAR_3": 19116.928411050005,
    "YEAR_4": 19968.024713849998,
    "YEAR_5": 20819.121016650006,
    "YEAR_6": 21670.217319450006,
    "YEAR_7": 22521.313622250003,
    "YEAR_8": 23372.40992505001,
    "YEAR_9": 24223.506227850008,
    "YEAR_10": 25074.602530650005,
    "FIXED_cLINK": 208172.79800752507
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 953200000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 810220,
    "% of cLINKAllocation to Nexus": 8102.2,
    "Per Month": 675.1833333333333,
    "YEAR_1": 6253.8572673,
    "YEAR_2": 8692.890891,
    "YEAR_3": 9097.9360734,
    "YEAR_4": 9502.9812558,
    "YEAR_5": 9908.026438199999,
    "YEAR_6": 10313.0716206,
    "YEAR_7": 10718.116802999999,
    "YEAR_8": 11123.1619854,
    "YEAR_9": 11528.2071678,
    "YEAR_10": 11933.2523502,
    "FIXED_cLINK": 99071.50185269999
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 634500000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 539325,
    "% of cLINKAllocation to Nexus": 5393.25,
    "Per Month": 449.4375,
    "YEAR_1": 4162.895967375,
    "YEAR_2": 5786.44489125,
    "YEAR_3": 6056.06424525,
    "YEAR_4": 6325.683599250001,
    "YEAR_5": 6595.30295325,
    "YEAR_6": 6864.92230725,
    "YEAR_7": 7134.541661249999,
    "YEAR_8": 7404.16101525,
    "YEAR_9": 7673.78036925,
    "YEAR_10": 7943.3997232500005,
    "FIXED_cLINK": 65947.196732625
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 972000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 826200,
    "% of cLINKAllocation to Nexus": 8262,
    "Per Month": 688.5,
    "YEAR_1": 6377.202332999999,
    "YEAR_2": 8864.34111,
    "YEAR_3": 9277.375014,
    "YEAR_4": 9690.408918,
    "YEAR_5": 10103.442821999999,
    "YEAR_6": 10516.476726,
    "YEAR_7": 10929.510629999999,
    "YEAR_8": 11342.544534,
    "YEAR_9": 11755.578438,
    "YEAR_10": 12168.612342,
    "FIXED_cLINK": 101025.492867
  },
  {
    "DMA": "Lansing",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1600000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1360000,
    "% of cLINKAllocation to Nexus": 13600,
    "Per Month": 1133.3333333333333,
    "YEAR_1": 10497.4524,
    "YEAR_2": 14591.507999999998,
    "YEAR_3": 15271.3992,
    "YEAR_4": 15951.290399999998,
    "YEAR_5": 16631.1816,
    "YEAR_6": 17311.072799999998,
    "YEAR_7": 17990.964,
    "YEAR_8": 18670.855199999998,
    "YEAR_9": 19350.746399999996,
    "YEAR_10": 20030.6376,
    "FIXED_cLINK": 166297.10759999996
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 200000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 170000,
    "% of cLINKAllocation to Nexus": 1700,
    "Per Month": 141.66666666666666,
    "YEAR_1": 1312.18155,
    "YEAR_2": 1823.9384999999997,
    "YEAR_3": 1908.9249,
    "YEAR_4": 1993.9112999999998,
    "YEAR_5": 2078.8977,
    "YEAR_6": 2163.8840999999998,
    "YEAR_7": 2248.8705,
    "YEAR_8": 2333.8568999999998,
    "YEAR_9": 2418.8432999999995,
    "YEAR_10": 2503.8297,
    "FIXED_cLINK": 20787.138449999995
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2450000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2082500,
    "% of cLINKAllocation to Nexus": 20825,
    "Per Month": 1735.4166666666667,
    "YEAR_1": 16074.2239875,
    "YEAR_2": 22343.246625000003,
    "YEAR_3": 23384.330025000003,
    "YEAR_4": 24425.413425,
    "YEAR_5": 25466.496825000006,
    "YEAR_6": 26507.580224999998,
    "YEAR_7": 27548.663625,
    "YEAR_8": 28589.747025000004,
    "YEAR_9": 29630.830425,
    "YEAR_10": 30671.913825000003,
    "FIXED_cLINK": 254642.44601249998
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 45236000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 38450.6,
    "% of cLINKAllocation to Nexus": 384.506,
    "Per Month": 32.04216666666667,
    "YEAR_1": 296.789222979,
    "YEAR_2": 412.53840992999994,
    "YEAR_3": 431.76063388200004,
    "YEAR_4": 450.982857834,
    "YEAR_5": 470.20508178600005,
    "YEAR_6": 489.42730573800003,
    "YEAR_7": 508.64952969,
    "YEAR_8": 527.8717536419999,
    "YEAR_9": 547.0939775939999,
    "YEAR_10": 566.316201546,
    "FIXED_cLINK": 4701.634974621
  },
  {
    "DMA": "Eugene",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 969119288,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 823751.3948,
    "% of cLINKAllocation to Nexus": 8237.513948,
    "Per Month": 686.4594956666666,
    "YEAR_1": 6358.302247313682,
    "YEAR_2": 8838.069902378938,
    "YEAR_3": 9249.879699667355,
    "YEAR_4": 9661.68949695577,
    "YEAR_5": 10073.499294244188,
    "YEAR_6": 10485.309091532601,
    "YEAR_7": 10897.11888882102,
    "YEAR_8": 11308.928686109433,
    "YEAR_9": 11720.738483397852,
    "YEAR_10": 12132.54828068627,
    "FIXED_cLINK": 100726.08407110712
  },
  {
    "DMA": "Macon",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 557782981,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 474115.53384999995,
    "% of cLINKAllocation to Nexus": 4741.1553385,
    "Per Month": 395.09627820833333,
    "YEAR_1": 3659.5626828610025,
    "YEAR_2": 5086.809268453342,
    "YEAR_3": 5323.829106135635,
    "YEAR_4": 5560.848943817927,
    "YEAR_5": 5797.868781500218,
    "YEAR_6": 6034.88861918251,
    "YEAR_7": 6271.908456864802,
    "YEAR_8": 6508.928294547095,
    "YEAR_9": 6745.948132229386,
    "YEAR_10": 6982.967969911679,
    "FIXED_cLINK": 57973.5602555036
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 585900000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 498015,
    "% of cLINKAllocation to Nexus": 4980.150000000001,
    "Per Month": 415.01250000000005,
    "YEAR_1": 3844.0358507250007,
    "YEAR_2": 5343.227835750002,
    "YEAR_3": 5592.195494550002,
    "YEAR_4": 5841.16315335,
    "YEAR_5": 6090.130812150001,
    "YEAR_6": 6339.098470950001,
    "YEAR_7": 6588.066129750001,
    "YEAR_8": 6837.033788550001,
    "YEAR_9": 7086.001447350001,
    "YEAR_10": 7334.969106150002,
    "FIXED_cLINK": 60895.922089275016
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2500000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2125000,
    "% of cLINKAllocation to Nexus": 21250,
    "Per Month": 1770.8333333333333,
    "YEAR_1": 16402.269375000003,
    "YEAR_2": 22799.231250000004,
    "YEAR_3": 23861.561250000002,
    "YEAR_4": 24923.89125,
    "YEAR_5": 25986.22125,
    "YEAR_6": 27048.55125,
    "YEAR_7": 28110.881250000002,
    "YEAR_8": 29173.21125,
    "YEAR_9": 30235.54125,
    "YEAR_10": 31297.871250000004,
    "FIXED_cLINK": 259839.23062500003
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 695384796,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 591077.0765999999,
    "% of cLINKAllocation to Nexus": 5910.770766,
    "Per Month": 492.56423049999995,
    "YEAR_1": 4562.355497308568,
    "YEAR_2": 6341.695508695229,
    "YEAR_3": 6637.186760829101,
    "YEAR_4": 6932.678012962973,
    "YEAR_5": 7228.169265096846,
    "YEAR_6": 7523.660517230717,
    "YEAR_7": 7819.15176936459,
    "YEAR_8": 8114.643021498461,
    "YEAR_9": 8410.134273632335,
    "YEAR_10": 8705.625525766205,
    "FIXED_cLINK": 72275.30015238503
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1104400000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 938740,
    "% of cLINKAllocation to Nexus": 9387.4,
    "Per Month": 782.2833333333333,
    "YEAR_1": 7245.866519099999,
    "YEAR_2": 10071.788397,
    "YEAR_3": 10541.0832978,
    "YEAR_4": 11010.3781986,
    "YEAR_5": 11479.673099399999,
    "YEAR_6": 11948.968000199999,
    "YEAR_7": 12418.262900999998,
    "YEAR_8": 12887.557801800001,
    "YEAR_9": 13356.8527026,
    "YEAR_10": 13826.147603399997,
    "FIXED_cLINK": 114786.57852089997
  },
  {
    "DMA": "Lafayette, LA",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 110573968,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 93987.8728,
    "% of cLINKAllocation to Nexus": 939.878728,
    "Per Month": 78.32322733333334,
    "YEAR_1": 725.465603599452,
    "YEAR_2": 1008.4005866648399,
    "YEAR_3": 1055.3870040350162,
    "YEAR_4": 1102.3734214051922,
    "YEAR_5": 1149.3598387753677,
    "YEAR_6": 1196.346256145544,
    "YEAR_7": 1243.33267351572,
    "YEAR_8": 1290.319090885896,
    "YEAR_9": 1337.305508256072,
    "YEAR_10": 1384.2919256262483,
    "FIXED_cLINK": 11492.581908909347
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1162600000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 988210,
    "% of cLINKAllocation to Nexus": 9882.1,
    "Per Month": 823.5083333333333,
    "YEAR_1": 7627.71135015,
    "YEAR_2": 10602.554500499999,
    "YEAR_3": 11096.5804437,
    "YEAR_4": 11590.6063869,
    "YEAR_5": 12084.6323301,
    "YEAR_6": 12578.658273300001,
    "YEAR_7": 13072.6842165,
    "YEAR_8": 13566.710159700002,
    "YEAR_9": 14060.736102900002,
    "YEAR_10": 14554.762046099999,
    "FIXED_cLINK": 120835.63580985
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 294499830,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 250324.85549999998,
    "% of cLINKAllocation to Nexus": 2503.2485549999997,
    "Per Month": 208.60404624999998,
    "YEAR_1": 1932.1862170206823,
    "YEAR_2": 2685.747890902275,
    "YEAR_3": 2810.8902926638348,
    "YEAR_4": 2936.0326944253948,
    "YEAR_5": 3061.1750961869548,
    "YEAR_6": 3186.3174979485148,
    "YEAR_7": 3311.4598997100748,
    "YEAR_8": 3436.6023014716347,
    "YEAR_9": 3561.7447032331947,
    "YEAR_10": 3686.8871049947547,
    "FIXED_cLINK": 30609.043698557314
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2900000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2465000,
    "% of cLINKAllocation to Nexus": 24650,
    "Per Month": 2054.1666666666665,
    "YEAR_1": 19026.632475000002,
    "YEAR_2": 26447.108249999997,
    "YEAR_3": 27679.411050000002,
    "YEAR_4": 28911.71385,
    "YEAR_5": 30144.01665,
    "YEAR_6": 31376.319449999995,
    "YEAR_7": 32608.62225,
    "YEAR_8": 33840.92505,
    "YEAR_9": 35073.227849999996,
    "YEAR_10": 36305.53065,
    "FIXED_cLINK": 301413.507525
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 251158559,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 213484.77515,
    "% of cLINKAllocation to Nexus": 2134.8477515,
    "Per Month": 177.90397929166667,
    "YEAR_1": 1647.8281362219325,
    "YEAR_2": 2290.4888268231075,
    "YEAR_3": 2397.2141356160955,
    "YEAR_4": 2503.9394444090835,
    "YEAR_5": 2610.6647532020716,
    "YEAR_6": 2717.3900619950596,
    "YEAR_7": 2824.1153707880476,
    "YEAR_8": 2930.840679581035,
    "YEAR_9": 3037.5659883740236,
    "YEAR_10": 3144.291297167012,
    "FIXED_cLINK": 26104.33869417747
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1128182827,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 958955.4029499999,
    "% of cLINKAllocation to Nexus": 9589.5540295,
    "Per Month": 799.1295024583333,
    "YEAR_1": 7401.90345308121,
    "YEAR_2": 10288.680466020698,
    "YEAR_3": 10768.081451063461,
    "YEAR_4": 11247.482436106227,
    "YEAR_5": 11726.883421148987,
    "YEAR_6": 12206.284406191753,
    "YEAR_7": 12685.685391234516,
    "YEAR_8": 13165.08637627728,
    "YEAR_9": 13644.487361320043,
    "YEAR_10": 14123.888346362812,
    "FIXED_cLINK": 117258.46310880699
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 467377051,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 397270.49335,
    "% of cLINKAllocation to Nexus": 3972.7049335,
    "Per Month": 331.05874445833336,
    "YEAR_1": 3066.4177160780455,
    "YEAR_2": 4262.334986676818,
    "YEAR_3": 4460.93845171235,
    "YEAR_4": 4659.541916747882,
    "YEAR_5": 4858.145381783414,
    "YEAR_6": 5056.748846818946,
    "YEAR_7": 5255.352311854478,
    "YEAR_8": 5453.95577689001,
    "YEAR_9": 5652.559241925543,
    "YEAR_10": 5851.162706961075,
    "FIXED_cLINK": 48577.15733744856
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1600000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1360000,
    "% of cLINKAllocation to Nexus": 13600,
    "Per Month": 1133.3333333333333,
    "YEAR_1": 10497.4524,
    "YEAR_2": 14591.507999999998,
    "YEAR_3": 15271.3992,
    "YEAR_4": 15951.290399999998,
    "YEAR_5": 16631.1816,
    "YEAR_6": 17311.072799999998,
    "YEAR_7": 17990.964,
    "YEAR_8": 18670.855199999998,
    "YEAR_9": 19350.746399999996,
    "YEAR_10": 20030.6376,
    "FIXED_cLINK": 166297.10759999996
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 228000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 193800,
    "% of cLINKAllocation to Nexus": 1938,
    "Per Month": 161.5,
    "YEAR_1": 1495.8869670000001,
    "YEAR_2": 2079.28989,
    "YEAR_3": 2176.174386,
    "YEAR_4": 2273.0588820000003,
    "YEAR_5": 2369.943378,
    "YEAR_6": 2466.827874,
    "YEAR_7": 2563.71237,
    "YEAR_8": 2660.5968660000003,
    "YEAR_9": 2757.481362,
    "YEAR_10": 2854.3658580000006,
    "FIXED_cLINK": 23697.337833
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 608800000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 517480,
    "% of cLINKAllocation to Nexus": 5174.8,
    "Per Month": 431.23333333333335,
    "YEAR_1": 3994.2806382000003,
    "YEAR_2": 5552.068794000001,
    "YEAR_3": 5810.7673956,
    "YEAR_4": 6069.4659972,
    "YEAR_5": 6328.164598800001,
    "YEAR_6": 6586.863200400001,
    "YEAR_7": 6845.561802000002,
    "YEAR_8": 7104.2604036,
    "YEAR_9": 7362.9590052,
    "YEAR_10": 7621.6576068,
    "FIXED_cLINK": 63276.0494418
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 94236480,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 80101.008,
    "% of cLINKAllocation to Nexus": 801.01008,
    "Per Month": 66.75084,
    "YEAR_1": 618.2768519647199,
    "YEAR_2": 859.4077198824,
    "YEAR_3": 899.45181580176,
    "YEAR_4": 939.4959117211199,
    "YEAR_5": 979.54000764048,
    "YEAR_6": 1019.5841035598398,
    "YEAR_7": 1059.6281994792,
    "YEAR_8": 1099.67229539856,
    "YEAR_9": 1139.71639131792,
    "YEAR_10": 1179.76048723728,
    "FIXED_cLINK": 9794.533784003279
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2400000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 2040000,
    "% of cLINKAllocation to Nexus": 20400,
    "Per Month": 1700,
    "YEAR_1": 15746.1786,
    "YEAR_2": 21887.262000000002,
    "YEAR_3": 22907.098799999996,
    "YEAR_4": 23926.935600000004,
    "YEAR_5": 24946.772399999998,
    "YEAR_6": 25966.609200000003,
    "YEAR_7": 26986.446,
    "YEAR_8": 28006.2828,
    "YEAR_9": 29026.1196,
    "YEAR_10": 30045.956400000003,
    "FIXED_cLINK": 249445.66139999998
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2000000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1700000,
    "% of cLINKAllocation to Nexus": 17000,
    "Per Month": 1416.6666666666667,
    "YEAR_1": 13121.815499999999,
    "YEAR_2": 18239.385,
    "YEAR_3": 19089.249,
    "YEAR_4": 19939.113000000005,
    "YEAR_5": 20788.977000000006,
    "YEAR_6": 21638.841,
    "YEAR_7": 22488.705,
    "YEAR_8": 23338.569000000003,
    "YEAR_9": 24188.432999999997,
    "YEAR_10": 25038.297,
    "FIXED_cLINK": 207871.3845
  },
  {
    "DMA": "Rockford",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 192740892,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 163829.75819999998,
    "% of cLINKAllocation to Nexus": 1638.297582,
    "Per Month": 136.5247985,
    "YEAR_1": 1264.555212064713,
    "YEAR_2": 1757.7376672157097,
    "YEAR_3": 1839.639439935054,
    "YEAR_4": 1921.541212654398,
    "YEAR_5": 2003.4429853737422,
    "YEAR_6": 2085.344758093086,
    "YEAR_7": 2167.2465308124297,
    "YEAR_8": 2249.148303531774,
    "YEAR_9": 2331.050076251118,
    "YEAR_10": 2412.951848970462,
    "FIXED_cLINK": 20032.658034902484
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 139850000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 118872.5,
    "% of cLINKAllocation to Nexus": 1188.7250000000001,
    "Per Month": 99.06041666666668,
    "YEAR_1": 917.5429488375001,
    "YEAR_2": 1275.3889961250002,
    "YEAR_3": 1334.8157363250002,
    "YEAR_4": 1394.2424765250003,
    "YEAR_5": 1453.669216725,
    "YEAR_6": 1513.0959569250003,
    "YEAR_7": 1572.5226971250004,
    "YEAR_8": 1631.949437325,
    "YEAR_9": 1691.3761775250002,
    "YEAR_10": 1750.8029177250005,
    "FIXED_cLINK": 14535.406561162503
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1300000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1105000,
    "% of cLINKAllocation to Nexus": 11050,
    "Per Month": 920.8333333333334,
    "YEAR_1": 8529.180075,
    "YEAR_2": 11855.600250000001,
    "YEAR_3": 12408.01185,
    "YEAR_4": 12960.423450000002,
    "YEAR_5": 13512.835050000002,
    "YEAR_6": 14065.246650000001,
    "YEAR_7": 14617.658250000004,
    "YEAR_8": 15170.069850000002,
    "YEAR_9": 15722.481450000001,
    "YEAR_10": 16274.893050000004,
    "FIXED_cLINK": 135116.39992500003
  },
  {
    "DMA": "Topeka",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 98072190,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 83361.3615,
    "% of cLINKAllocation to Nexus": 833.613615,
    "Per Month": 69.46780125,
    "YEAR_1": 643.4425914304724,
    "YEAR_2": 894.3882156015746,
    "YEAR_3": 936.0622274426548,
    "YEAR_4": 977.7362392837349,
    "YEAR_5": 1019.4102511248151,
    "YEAR_6": 1061.084262965895,
    "YEAR_7": 1102.758274806975,
    "YEAR_8": 1144.4322866480552,
    "YEAR_9": 1186.106298489135,
    "YEAR_10": 1227.780310330215,
    "FIXED_cLINK": 10193.200958123525
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 694900000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 590665,
    "% of cLINKAllocation to Nexus": 5906.650000000001,
    "Per Month": 492.22083333333336,
    "YEAR_1": 4559.174795475,
    "YEAR_2": 6337.274318250001,
    "YEAR_3": 6632.55956505,
    "YEAR_4": 6927.844811850001,
    "YEAR_5": 7223.13005865,
    "YEAR_6": 7518.41530545,
    "YEAR_7": 7813.70055225,
    "YEAR_8": 8108.98579905,
    "YEAR_9": 8404.27104585,
    "YEAR_10": 8699.55629265,
    "FIXED_cLINK": 72224.91254452501
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 2100000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 1785000,
    "% of cLINKAllocation to Nexus": 17850,
    "Per Month": 1487.5,
    "YEAR_1": 13777.906275000001,
    "YEAR_2": 19151.35425,
    "YEAR_3": 20043.71145,
    "YEAR_4": 20936.06865,
    "YEAR_5": 21828.42585,
    "YEAR_6": 22720.78305,
    "YEAR_7": 23613.140250000004,
    "YEAR_8": 24505.49745,
    "YEAR_9": 25397.854650000005,
    "YEAR_10": 26290.211850000003,
    "FIXED_cLINK": 218264.953725
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 455446082,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 387129.16969999997,
    "% of cLINKAllocation to Nexus": 3871.2916969999997,
    "Per Month": 322.60764141666664,
    "YEAR_1": 2988.1397291009353,
    "YEAR_2": 4153.528218169785,
    "YEAR_3": 4347.061832686209,
    "YEAR_4": 4540.595447202632,
    "YEAR_5": 4734.129061719057,
    "YEAR_6": 4927.662676235481,
    "YEAR_7": 5121.196290751904,
    "YEAR_8": 5314.729905268328,
    "YEAR_9": 5508.263519784752,
    "YEAR_10": 5701.797134301177,
    "FIXED_cLINK": 47337.10381522025
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1143980000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 972383,
    "% of cLINKAllocation to Nexus": 9723.83,
    "Per Month": 810.3191666666667,
    "YEAR_1": 7505.547247845,
    "YEAR_2": 10432.74582615,
    "YEAR_3": 10918.859535509999,
    "YEAR_4": 11404.97324487,
    "YEAR_5": 11891.086954230002,
    "YEAR_6": 12377.20066359,
    "YEAR_7": 12863.31437295,
    "YEAR_8": 13349.428082310002,
    "YEAR_9": 13835.54179167,
    "YEAR_10": 14321.65550103,
    "FIXED_cLINK": 118900.353220155
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 573357189,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 487353.61065,
    "% of cLINKAllocation to Nexus": 4873.5361065,
    "Per Month": 406.128008875,
    "YEAR_1": 3761.7436248283148,
    "YEAR_2": 5228.841256344383,
    "YEAR_3": 5472.479073380531,
    "YEAR_4": 5716.116890416679,
    "YEAR_5": 5959.754707452827,
    "YEAR_6": 6203.392524488975,
    "YEAR_7": 6447.030341525122,
    "YEAR_8": 6690.6681585612705,
    "YEAR_9": 6934.305975597419,
    "YEAR_10": 7177.943792633567,
    "FIXED_cLINK": 59592.27634522909
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 511245405,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 434558.59424999997,
    "% of cLINKAllocation to Nexus": 4345.5859425,
    "Per Month": 362.132161875,
    "YEAR_1": 3354.233939816389,
    "YEAR_2": 4662.400885637962,
    "YEAR_3": 4879.645418075423,
    "YEAR_4": 5096.889950512883,
    "YEAR_5": 5314.134482950343,
    "YEAR_6": 5531.3790153878035,
    "YEAR_7": 5748.623547825263,
    "YEAR_8": 5965.868080262722,
    "YEAR_9": 6183.1126127001835,
    "YEAR_10": 6400.357145137643,
    "FIXED_cLINK": 53136.64507830661
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 608538224,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 517257.49039999995,
    "% of cLINKAllocation to Nexus": 5172.574904,
    "Per Month": 431.0479086666667,
    "YEAR_1": 3992.563150012836,
    "YEAR_2": 5549.681477376121,
    "YEAR_3": 5808.268841976888,
    "YEAR_4": 6066.856206577655,
    "YEAR_5": 6325.443571178424,
    "YEAR_6": 6584.030935779192,
    "YEAR_7": 6842.61830037996,
    "YEAR_8": 7101.205664980728,
    "YEAR_9": 7359.793029581497,
    "YEAR_10": 7618.380394182264,
    "FIXED_cLINK": 63248.84157202556
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 226841983,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 192815.68555,
    "% of cLINKAllocation to Nexus": 1928.1568555,
    "Per Month": 160.67973795833333,
    "YEAR_1": 1488.2893242900682,
    "YEAR_2": 2068.729131050228,
    "YEAR_3": 2165.1215485703833,
    "YEAR_4": 2261.5139660905393,
    "YEAR_5": 2357.9063836106952,
    "YEAR_6": 2454.2988011308516,
    "YEAR_7": 2550.691218651007,
    "YEAR_8": 2647.083636171163,
    "YEAR_9": 2743.4760536913195,
    "YEAR_10": 2839.8684712114755,
    "FIXED_cLINK": 23576.97853446773
  },
  {
    "DMA": "Erie",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 158291324,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 134547.6254,
    "% of cLINKAllocation to Nexus": 1345.476254,
    "Per Month": 112.12302116666666,
    "YEAR_1": 1038.534774389361,
    "YEAR_2": 1443.56820029787,
    "YEAR_3": 1510.831249187838,
    "YEAR_4": 1578.0942980778061,
    "YEAR_5": 1645.3573469677742,
    "YEAR_6": 1712.6203958577419,
    "YEAR_7": 1779.88344474771,
    "YEAR_8": 1847.1464936376783,
    "YEAR_9": 1914.409542527646,
    "YEAR_10": 1981.672591417614,
    "FIXED_cLINK": 16452.118337109037
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 1050000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 892500,
    "% of cLINKAllocation to Nexus": 8925,
    "Per Month": 743.75,
    "YEAR_1": 6888.953137500001,
    "YEAR_2": 9575.677125,
    "YEAR_3": 10021.855725,
    "YEAR_4": 10468.034325,
    "YEAR_5": 10914.212925,
    "YEAR_6": 11360.391525,
    "YEAR_7": 11806.570125000002,
    "YEAR_8": 12252.748725,
    "YEAR_9": 12698.927325000002,
    "YEAR_10": 13145.105925000002,
    "FIXED_cLINK": 109132.4768625
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 590000000,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 501500,
    "% of cLINKAllocation to Nexus": 5015,
    "Per Month": 417.9166666666667,
    "YEAR_1": 3870.9355725,
    "YEAR_2": 5380.618575,
    "YEAR_3": 5631.328455,
    "YEAR_4": 5882.038334999999,
    "YEAR_5": 6132.748215,
    "YEAR_6": 6383.458095,
    "YEAR_7": 6634.167975,
    "YEAR_8": 6884.877855000001,
    "YEAR_9": 7135.587735,
    "YEAR_10": 7386.297615,
    "FIXED_cLINK": 61322.05842750001
  },
  {
    "DMA": "Panama City",
    "PlatformName": "cLINK",
    "DMA Annual City Budget": 143663281,
    "Percentage Allocated to 311 communication": 0.00085,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.004166,
    "Holiday Months": 3,
    "Budget Allocated to cLINK Communication": 122113.78885,
    "% of cLINKAllocation to Nexus": 1221.1378885,
    "Per Month": 101.76149070833333,
    "YEAR_1": 942.5615337033278,
    "YEAR_2": 1310.1649462610922,
    "YEAR_3": 1371.2120715829842,
    "YEAR_4": 1432.2591969048763,
    "YEAR_5": 1493.3063222267683,
    "YEAR_6": 1554.3534475486604,
    "YEAR_7": 1615.4005728705524,
    "YEAR_8": 1676.4476981924445,
    "YEAR_9": 1737.4948235143365,
    "YEAR_10": 1798.5419488362284,
    "FIXED_cLINK": 14931.742561641271
  },
  {
    "DMA": "New York",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 268157718,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 6703942.95,
    "% of funds from communication to NEXUS": 67039.4295,
    "Per Month": 5586.619125,
    "YEAR_1": 50572.752310060874,
    "YEAR_2": 68016.69678353624,
    "YEAR_3": 68686.82292081826,
    "YEAR_4": 69356.94905810026,
    "YEAR_5": 70027.07519538225,
    "YEAR_6": 70697.20133266426,
    "YEAR_7": 71367.32746994625,
    "YEAR_8": 72037.45360722824,
    "YEAR_9": 72707.57974451027,
    "YEAR_10": 73377.70588179225,
    "FIXED_GoFEMA": 686847.5643040391
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 127456853,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3186421.325,
    "% of funds from communication to NEXUS": 31864.213250000004,
    "Per Month": 2655.351104166667,
    "YEAR_1": 24037.510108095565,
    "YEAR_2": 32328.71381865188,
    "YEAR_3": 32647.22849429888,
    "YEAR_4": 32965.743169945876,
    "YEAR_5": 33284.25784559288,
    "YEAR_6": 33602.77252123988,
    "YEAR_7": 33921.28719688688,
    "YEAR_8": 34239.80187253388,
    "YEAR_9": 34558.31654818088,
    "YEAR_10": 34876.83122382788,
    "FIXED_GoFEMA": 326462.4627992545
  },
  {
    "DMA": "Chicago",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 62624842,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1565621.05,
    "% of funds from communication to NEXUS": 15656.210500000001,
    "Per Month": 1304.6842083333333,
    "YEAR_1": 11810.626397569125,
    "YEAR_2": 15884.43890856375,
    "YEAR_3": 16040.938388721752,
    "YEAR_4": 16197.43786887975,
    "YEAR_5": 16353.937349037747,
    "YEAR_6": 16510.43682919575,
    "YEAR_7": 16666.93630935375,
    "YEAR_8": 16823.43578951175,
    "YEAR_9": 16979.93526966975,
    "YEAR_10": 17136.434749827746,
    "FIXED_GoFEMA": 160404.55786033088
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 56739719.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1418492.9875,
    "% of funds from communication to NEXUS": 14184.929875000002,
    "Per Month": 1182.0774895833335,
    "YEAR_1": 10700.731650825845,
    "YEAR_2": 14391.710690252814,
    "YEAR_3": 14533.503249283314,
    "YEAR_4": 14675.295808313816,
    "YEAR_5": 14817.088367344313,
    "YEAR_6": 14958.880926374813,
    "YEAR_7": 15100.673485405316,
    "YEAR_8": 15242.466044435816,
    "YEAR_9": 15384.258603466315,
    "YEAR_10": 15526.051162496815,
    "FIXED_GoFEMA": 145330.6599881992
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 338082524,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 8452063.1,
    "% of funds from communication to NEXUS": 84520.631,
    "Per Month": 7043.385916666666,
    "YEAR_1": 63760.103099520755,
    "YEAR_2": 85752.73049840248,
    "YEAR_3": 86597.59872587849,
    "YEAR_4": 87442.46695335451,
    "YEAR_5": 88287.33518083049,
    "YEAR_6": 89132.2034083065,
    "YEAR_7": 89977.07163578249,
    "YEAR_8": 90821.93986325848,
    "YEAR_9": 91666.8080907345,
    "YEAR_10": 92511.67631821049,
    "FIXED_GoFEMA": 865949.9337742792
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 12956379,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 323909.47500000003,
    "% of funds from communication to NEXUS": 3239.09475,
    "Per Month": 269.92456250000004,
    "YEAR_1": 2443.486433615438,
    "YEAR_2": 3286.3126537181256,
    "YEAR_3": 3318.6906448391255,
    "YEAR_4": 3351.0686359601254,
    "YEAR_5": 3383.4466270811254,
    "YEAR_6": 3415.8246182021253,
    "YEAR_7": 3448.202609323125,
    "YEAR_8": 3480.5806004441256,
    "YEAR_9": 3512.958591565125,
    "YEAR_10": 3545.3365826861254,
    "FIXED_GoFEMA": 33185.907997434566
  },
  {
    "DMA": "Houston",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 169041262,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4226031.55,
    "% of funds from communication to NEXUS": 42260.3155,
    "Per Month": 3521.692958333333,
    "YEAR_1": 31880.051549760377,
    "YEAR_2": 42876.36524920124,
    "YEAR_3": 43298.799362939244,
    "YEAR_4": 43721.233476677255,
    "YEAR_5": 44143.66759041524,
    "YEAR_6": 44566.10170415325,
    "YEAR_7": 44988.53581789124,
    "YEAR_8": 45410.96993162924,
    "YEAR_9": 45833.40404536725,
    "YEAR_10": 46255.838159105246,
    "FIXED_GoFEMA": 432974.9668871396
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 784004485.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 19600112.1375,
    "% of funds from communication to NEXUS": 196001.121375,
    "Per Month": 16333.426781249998,
    "YEAR_1": 147858.00293530323,
    "YEAR_2": 198858.32772184405,
    "YEAR_3": 200817.55493110855,
    "YEAR_4": 202776.78214037305,
    "YEAR_5": 204736.00934963755,
    "YEAR_6": 206695.23655890202,
    "YEAR_7": 208654.46376816652,
    "YEAR_8": 210613.69097743102,
    "YEAR_9": 212572.91818669552,
    "YEAR_10": 214532.14539596005,
    "FIXED_GoFEMA": 2008115.1319654216
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 229644405.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 5741110.1325,
    "% of funds from communication to NEXUS": 57411.101325,
    "Per Month": 4784.25844375,
    "YEAR_1": 43309.39909261955,
    "YEAR_2": 58248.01165456518,
    "YEAR_3": 58821.893023409895,
    "YEAR_4": 59395.77439225459,
    "YEAR_5": 59969.65576109928,
    "YEAR_6": 60543.53712994399,
    "YEAR_7": 61117.418498788684,
    "YEAR_8": 61691.299867633395,
    "YEAR_9": 62265.18123647809,
    "YEAR_10": 62839.06260532278,
    "FIXED_GoFEMA": 588201.2332621155
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 382370558.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 9559263.957500001,
    "% of funds from communication to NEXUS": 95592.63957500002,
    "Per Month": 7966.053297916668,
    "YEAR_1": 72112.53019227138,
    "YEAR_2": 96986.14127840457,
    "YEAR_3": 97941.68530359628,
    "YEAR_4": 98897.22932878797,
    "YEAR_5": 99852.77335397968,
    "YEAR_6": 100808.31737917138,
    "YEAR_7": 101763.86140436307,
    "YEAR_8": 102719.40542955478,
    "YEAR_9": 103674.94945474649,
    "YEAR_10": 104630.49347993819,
    "FIXED_GoFEMA": 979387.3866048139
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 35690042.33,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 892251.05825,
    "% of funds from communication to NEXUS": 8922.510582500001,
    "Per Month": 743.5425485416667,
    "YEAR_1": 6730.903306279919,
    "YEAR_2": 9052.578480516395,
    "YEAR_3": 9141.767896299065,
    "YEAR_4": 9230.957312081735,
    "YEAR_5": 9320.146727864405,
    "YEAR_6": 9409.336143647073,
    "YEAR_7": 9498.525559429743,
    "YEAR_8": 9587.714975212413,
    "YEAR_9": 9676.904390995087,
    "YEAR_10": 9766.093806777757,
    "FIXED_GoFEMA": 91414.92859910359
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 188665500.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4716637.5075,
    "% of funds from communication to NEXUS": 47166.375075,
    "Per Month": 3930.5312562500003,
    "YEAR_1": 35581.051656046744,
    "YEAR_2": 47853.94290765581,
    "YEAR_3": 48325.41799290551,
    "YEAR_4": 48796.89307815521,
    "YEAR_5": 49268.36816340492,
    "YEAR_6": 49739.843248654615,
    "YEAR_7": 50211.31833390432,
    "YEAR_8": 50682.79341915402,
    "YEAR_9": 51154.26850440371,
    "YEAR_10": 51625.743589653415,
    "FIXED_GoFEMA": 483239.64089393825
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 208637426.8,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 5215935.670000001,
    "% of funds from communication to NEXUS": 52159.35670000001,
    "Per Month": 4346.613058333334,
    "YEAR_1": 39347.623431688284,
    "YEAR_2": 52919.70972229426,
    "YEAR_3": 53441.09465186746,
    "YEAR_4": 53962.47958144066,
    "YEAR_5": 54483.86451101386,
    "YEAR_6": 55005.24944058707,
    "YEAR_7": 55526.63437016027,
    "YEAR_8": 56048.01929973346,
    "YEAR_9": 56569.40422930666,
    "YEAR_10": 57090.78915887986,
    "FIXED_GoFEMA": 534394.8683969718
  },
  {
    "DMA": "Detroit",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 37216992,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 930424.8,
    "% of funds from communication to NEXUS": 9304.248000000001,
    "Per Month": 775.3540000000002,
    "YEAR_1": 7018.875802566001,
    "YEAR_2": 9439.880675220003,
    "YEAR_3": 9532.885938228,
    "YEAR_4": 9625.891201236002,
    "YEAR_5": 9718.896464244002,
    "YEAR_6": 9811.901727252001,
    "YEAR_7": 9904.906990260002,
    "YEAR_8": 9997.912253268001,
    "YEAR_9": 10090.917516276002,
    "YEAR_10": 10183.922779284003,
    "FIXED_GoFEMA": 95325.99134783403
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 56062697,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1401567.425,
    "% of funds from communication to NEXUS": 14015.67425,
    "Per Month": 1167.9728541666666,
    "YEAR_1": 10573.049734913811,
    "YEAR_2": 14219.987741379373,
    "YEAR_3": 14360.088421182372,
    "YEAR_4": 14500.189100985373,
    "YEAR_5": 14640.289780788375,
    "YEAR_6": 14780.390460591374,
    "YEAR_7": 14920.491140394373,
    "YEAR_8": 15060.591820197373,
    "YEAR_9": 15200.692500000374,
    "YEAR_10": 15340.793179803371,
    "FIXED_GoFEMA": 143596.56388023615
  },
  {
    "DMA": "Denver",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 212329355.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 5308233.8875,
    "% of funds from communication to NEXUS": 53082.338875,
    "Per Month": 4423.528239583334,
    "YEAR_1": 40043.896494735105,
    "YEAR_2": 53856.14666995032,
    "YEAR_3": 54386.75772934482,
    "YEAR_4": 54917.36878873932,
    "YEAR_5": 55447.979848133815,
    "YEAR_6": 55978.59090752831,
    "YEAR_7": 56509.20196692282,
    "YEAR_8": 57039.813026317315,
    "YEAR_9": 57570.42408571181,
    "YEAR_10": 58101.03514510632,
    "FIXED_GoFEMA": 543851.21466249
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 208637426.8,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 5215935.670000001,
    "% of funds from communication to NEXUS": 52159.35670000001,
    "Per Month": 4346.613058333334,
    "YEAR_1": 39347.623431688284,
    "YEAR_2": 52919.70972229426,
    "YEAR_3": 53441.09465186746,
    "YEAR_4": 53962.47958144066,
    "YEAR_5": 54483.86451101386,
    "YEAR_6": 55005.24944058707,
    "YEAR_7": 55526.63437016027,
    "YEAR_8": 56048.01929973346,
    "YEAR_9": 56569.40422930666,
    "YEAR_10": 57090.78915887986,
    "FIXED_GoFEMA": 534394.8683969718
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 139091617.8,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3477290.4450000003,
    "% of funds from communication to NEXUS": 34772.90445,
    "Per Month": 2897.7420375,
    "YEAR_1": 26231.748941885966,
    "YEAR_2": 35279.806464619876,
    "YEAR_3": 35627.39641750208,
    "YEAR_4": 35974.98637038428,
    "YEAR_5": 36322.576323266476,
    "YEAR_6": 36670.16627614867,
    "YEAR_7": 37017.75622903088,
    "YEAR_8": 37365.346181913075,
    "YEAR_9": 37712.93613479527,
    "YEAR_10": 38060.52608767748,
    "FIXED_GoFEMA": 356263.245427224
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 114575299,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2864382.475,
    "% of funds from communication to NEXUS": 28643.82475,
    "Per Month": 2386.9853958333333,
    "YEAR_1": 21608.135169087942,
    "YEAR_2": 29061.38010529312,
    "YEAR_3": 29347.703777494127,
    "YEAR_4": 29634.027449695117,
    "YEAR_5": 29920.351121896125,
    "YEAR_6": 30206.674794097133,
    "YEAR_7": 30492.99846629812,
    "YEAR_8": 30779.322138499134,
    "YEAR_9": 31065.64581070012,
    "YEAR_10": 31351.969482901128,
    "FIXED_GoFEMA": 293468.20831596205
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 382370558.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 9559263.957500001,
    "% of funds from communication to NEXUS": 95592.63957500002,
    "Per Month": 7966.053297916668,
    "YEAR_1": 72112.53019227138,
    "YEAR_2": 96986.14127840457,
    "YEAR_3": 97941.68530359628,
    "YEAR_4": 98897.22932878797,
    "YEAR_5": 99852.77335397968,
    "YEAR_6": 100808.31737917138,
    "YEAR_7": 101763.86140436307,
    "YEAR_8": 102719.40542955478,
    "YEAR_9": 103674.94945474649,
    "YEAR_10": 104630.49347993819,
    "FIXED_GoFEMA": 979387.3866048139
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 46126990,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1153174.75,
    "% of funds from communication to NEXUS": 11531.7475,
    "Per Month": 960.9789583333333,
    "YEAR_1": 8699.241839754375,
    "YEAR_2": 11699.851549181249,
    "YEAR_3": 11815.12289719125,
    "YEAR_4": 11930.394245201249,
    "YEAR_5": 12045.66559321125,
    "YEAR_6": 12160.936941221249,
    "YEAR_7": 12276.208289231248,
    "YEAR_8": 12391.479637241251,
    "YEAR_9": 12506.75098525125,
    "YEAR_10": 12622.02233326125,
    "FIXED_GoFEMA": 118147.67431074561
  },
  {
    "DMA": {
      "text": "Portland, OR",
      "hyperlink": "https://ustvdb.com/markets/portland-or/"
    },
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 170990338,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4274758.45,
    "% of funds from communication to NEXUS": 42747.584500000004,
    "Per Month": 3562.2987083333337,
    "YEAR_1": 32247.634248914634,
    "YEAR_2": 43370.73741304876,
    "YEAR_3": 43798.042267710756,
    "YEAR_4": 44225.34712237276,
    "YEAR_5": 44652.65197703475,
    "YEAR_6": 45079.956831696756,
    "YEAR_7": 45507.26168635876,
    "YEAR_8": 45934.56654102076,
    "YEAR_9": 46361.871395682756,
    "YEAR_10": 46789.17625034475,
    "FIXED_GoFEMA": 437967.24573418545
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 138380970.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3459524.2575000003,
    "% of funds from communication to NEXUS": 34595.242575000004,
    "Per Month": 2882.93688125,
    "YEAR_1": 26097.72557584112,
    "YEAR_2": 35099.55472363707,
    "YEAR_3": 35445.36876841676,
    "YEAR_4": 35791.182813196465,
    "YEAR_5": 36136.996857976155,
    "YEAR_6": 36482.81090275587,
    "YEAR_7": 36828.624947535565,
    "YEAR_8": 37174.43899231527,
    "YEAR_9": 37520.25303709497,
    "YEAR_10": 37866.067081874666,
    "FIXED_GoFEMA": 354443.02370064385
  },
  {
    "DMA": "St. Louis",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 21840154.67,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 546003.86675,
    "% of funds from communication to NEXUS": 5460.038667500001,
    "Per Month": 455.00322229166676,
    "YEAR_1": 4118.9071147276445,
    "YEAR_2": 5539.632381175482,
    "YEAR_3": 5594.210927695813,
    "YEAR_4": 5648.7894742161425,
    "YEAR_5": 5703.368020736472,
    "YEAR_6": 5757.946567256802,
    "YEAR_7": 5812.525113777133,
    "YEAR_8": 5867.103660297463,
    "YEAR_9": 5921.682206817793,
    "YEAR_10": 5976.260753338122,
    "FIXED_GoFEMA": 55940.42622003887
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 9503292.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 237582.3125,
    "% of funds from communication to NEXUS": 2375.823125,
    "Per Month": 197.98526041666665,
    "YEAR_1": 1792.257412231406,
    "YEAR_2": 2410.4566866046875,
    "YEAR_3": 2434.205414562188,
    "YEAR_4": 2457.9541425196876,
    "YEAR_5": 2481.7028704771874,
    "YEAR_6": 2505.451598434687,
    "YEAR_7": 2529.200326392187,
    "YEAR_8": 2552.949054349687,
    "YEAR_9": 2576.6977823071875,
    "YEAR_10": 2600.4465102646873,
    "FIXED_GoFEMA": 24341.32179814359
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 56739719.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1418492.9875,
    "% of funds from communication to NEXUS": 14184.929875000002,
    "Per Month": 1182.0774895833335,
    "YEAR_1": 10700.731650825845,
    "YEAR_2": 14391.710690252814,
    "YEAR_3": 14533.503249283314,
    "YEAR_4": 14675.295808313816,
    "YEAR_5": 14817.088367344313,
    "YEAR_6": 14958.880926374813,
    "YEAR_7": 15100.673485405316,
    "YEAR_8": 15242.466044435816,
    "YEAR_9": 15384.258603466315,
    "YEAR_10": 15526.051162496815,
    "FIXED_GoFEMA": 145330.6599881992
  },
  {
    "DMA": "Nashville",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 14626023.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 365650.5875,
    "% of funds from communication to NEXUS": 3656.5058750000003,
    "Per Month": 304.7088229166667,
    "YEAR_1": 2758.370220567844,
    "YEAR_2": 3709.808589392813,
    "YEAR_3": 3746.359022119313,
    "YEAR_4": 3782.9094548458124,
    "YEAR_5": 3819.459887572313,
    "YEAR_6": 3856.010320298813,
    "YEAR_7": 3892.560753025313,
    "YEAR_8": 3929.111185751813,
    "YEAR_9": 3965.6616184783124,
    "YEAR_10": 4002.212051204813,
    "FIXED_GoFEMA": 37462.46310325716
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 289226811,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 7230670.275,
    "% of funds from communication to NEXUS": 72306.70275000001,
    "Per Month": 6025.558562500001,
    "YEAR_1": 54546.24235030145,
    "YEAR_2": 73360.75370933814,
    "YEAR_3": 74083.53151002714,
    "YEAR_4": 74806.30931071614,
    "YEAR_5": 75529.08711140514,
    "YEAR_6": 76251.86491209414,
    "YEAR_7": 76974.64271278314,
    "YEAR_8": 77697.42051347214,
    "YEAR_9": 78420.19831416114,
    "YEAR_10": 79142.97611485014,
    "FIXED_GoFEMA": 740813.0265591487
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 211243236.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 5281080.912500001,
    "% of funds from communication to NEXUS": 52810.80912500001,
    "Per Month": 4400.900760416667,
    "YEAR_1": 39839.06171475591,
    "YEAR_2": 53580.658695019694,
    "YEAR_3": 54108.55554303319,
    "YEAR_4": 54636.452391046696,
    "YEAR_5": 55164.34923906019,
    "YEAR_6": 55692.246087073705,
    "YEAR_7": 56220.1429350872,
    "YEAR_8": 56748.0397831007,
    "YEAR_9": 57275.93663111419,
    "YEAR_10": 57803.833479127694,
    "FIXED_GoFEMA": 541069.2764984192
  },
  {
    "DMA": "San Diego",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 127456853,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3186421.325,
    "% of funds from communication to NEXUS": 31864.213250000004,
    "Per Month": 2655.351104166667,
    "YEAR_1": 24037.510108095565,
    "YEAR_2": 32328.71381865188,
    "YEAR_3": 32647.22849429888,
    "YEAR_4": 32965.743169945876,
    "YEAR_5": 33284.25784559288,
    "YEAR_6": 33602.77252123988,
    "YEAR_7": 33921.28719688688,
    "YEAR_8": 34239.80187253388,
    "YEAR_9": 34558.31654818088,
    "YEAR_10": 34876.83122382788,
    "FIXED_GoFEMA": 326462.4627992545
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 169041262,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4226031.55,
    "% of funds from communication to NEXUS": 42260.3155,
    "Per Month": 3521.692958333333,
    "YEAR_1": 31880.051549760377,
    "YEAR_2": 42876.36524920124,
    "YEAR_3": 43298.799362939244,
    "YEAR_4": 43721.233476677255,
    "YEAR_5": 44143.66759041524,
    "YEAR_6": 44566.10170415325,
    "YEAR_7": 44988.53581789124,
    "YEAR_8": 45410.96993162924,
    "YEAR_9": 45833.40404536725,
    "YEAR_10": 46255.838159105246,
    "FIXED_GoFEMA": 432974.9668871396
  },
  {
    "DMA": "Columbus",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 38191766,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 954794.15,
    "% of funds from communication to NEXUS": 9547.9415,
    "Per Month": 795.6617916666668,
    "YEAR_1": 7202.711660164876,
    "YEAR_2": 9687.126617216252,
    "YEAR_3": 9782.56784045025,
    "YEAR_4": 9878.00906368425,
    "YEAR_5": 9973.45028691825,
    "YEAR_6": 10068.89151015225,
    "YEAR_7": 10164.33273338625,
    "YEAR_8": 10259.77395662025,
    "YEAR_9": 10355.21517985425,
    "YEAR_10": 10450.656403088251,
    "FIXED_GoFEMA": 97822.73525153514
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 50466877.33,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1261671.93325,
    "% of funds from communication to NEXUS": 12616.7193325,
    "Per Month": 1051.3932777083335,
    "YEAR_1": 9517.715567195859,
    "YEAR_2": 12800.639558569523,
    "YEAR_3": 12926.75628501719,
    "YEAR_4": 13052.873011464862,
    "YEAR_5": 13178.98973791253,
    "YEAR_6": 13305.1064643602,
    "YEAR_7": 13431.223190807872,
    "YEAR_8": 13557.33991725554,
    "YEAR_9": 13683.45664370321,
    "YEAR_10": 13809.573370150882,
    "FIXED_GoFEMA": 129263.67374643766
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 464667883.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 11616697.0875,
    "% of funds from communication to NEXUS": 116166.970875,
    "Per Month": 9680.58090625,
    "YEAR_1": 87633.2553616291,
    "YEAR_2": 117860.39489293029,
    "YEAR_3": 119021.59993379681,
    "YEAR_4": 120182.80497466329,
    "YEAR_5": 121344.0100155298,
    "YEAR_6": 122505.21505639631,
    "YEAR_7": 123666.4200972628,
    "YEAR_8": 124827.62513812931,
    "YEAR_9": 125988.8301789958,
    "YEAR_10": 127150.0352198623,
    "FIXED_GoFEMA": 1190180.1908691959
  },
  {
    "DMA": "Austin",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 169041262,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4226031.55,
    "% of funds from communication to NEXUS": 42260.3155,
    "Per Month": 3521.692958333333,
    "YEAR_1": 31880.051549760377,
    "YEAR_2": 42876.36524920124,
    "YEAR_3": 43298.799362939244,
    "YEAR_4": 43721.233476677255,
    "YEAR_5": 44143.66759041524,
    "YEAR_6": 44566.10170415325,
    "YEAR_7": 44988.53581789124,
    "YEAR_8": 45410.96993162924,
    "YEAR_9": 45833.40404536725,
    "YEAR_10": 46255.838159105246,
    "FIXED_GoFEMA": 432974.9668871396
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 38191766,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 954794.15,
    "% of funds from communication to NEXUS": 9547.9415,
    "Per Month": 795.6617916666668,
    "YEAR_1": 7202.711660164876,
    "YEAR_2": 9687.126617216252,
    "YEAR_3": 9782.56784045025,
    "YEAR_4": 9878.00906368425,
    "YEAR_5": 9973.45028691825,
    "YEAR_6": 10068.89151015225,
    "YEAR_7": 10164.33273338625,
    "YEAR_8": 10259.77395662025,
    "YEAR_9": 10355.21517985425,
    "YEAR_10": 10450.656403088251,
    "FIXED_GoFEMA": 97822.73525153514
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 113806199,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2845154.975,
    "% of funds from communication to NEXUS": 28451.549750000002,
    "Per Month": 2370.962479166667,
    "YEAR_1": 21463.08805244419,
    "YEAR_2": 28866.302216480628,
    "YEAR_3": 29150.70390778163,
    "YEAR_4": 29435.105599082628,
    "YEAR_5": 29719.507290383626,
    "YEAR_6": 30003.908981684628,
    "YEAR_7": 30288.310672985626,
    "YEAR_8": 30572.712364286635,
    "YEAR_9": 30857.114055587634,
    "YEAR_10": 31141.515746888632,
    "FIXED_GoFEMA": 291498.26888760587
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 79276138,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1981903.4500000002,
    "% of funds from communication to NEXUS": 19819.0345,
    "Per Month": 1651.5862083333334,
    "YEAR_1": 14950.949467627124,
    "YEAR_2": 20107.946475423756,
    "YEAR_3": 20306.05754428575,
    "YEAR_4": 20504.16861314775,
    "YEAR_5": 20702.27968200975,
    "YEAR_6": 20900.39075087175,
    "YEAR_7": 21098.50181973375,
    "YEAR_8": 21296.612888595748,
    "YEAR_9": 21494.72395745775,
    "YEAR_10": 21692.835026319754,
    "FIXED_GoFEMA": 203054.4662254729
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 139091617.8,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3477290.4450000003,
    "% of funds from communication to NEXUS": 34772.90445,
    "Per Month": 2897.7420375,
    "YEAR_1": 26231.748941885966,
    "YEAR_2": 35279.806464619876,
    "YEAR_3": 35627.39641750208,
    "YEAR_4": 35974.98637038428,
    "YEAR_5": 36322.576323266476,
    "YEAR_6": 36670.16627614867,
    "YEAR_7": 37017.75622903088,
    "YEAR_8": 37365.346181913075,
    "YEAR_9": 37712.93613479527,
    "YEAR_10": 38060.52608767748,
    "FIXED_GoFEMA": 356263.245427224
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 75841875,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1896046.875,
    "% of funds from communication to NEXUS": 18960.46875,
    "Per Month": 1580.0390625,
    "YEAR_1": 14303.270432460937,
    "YEAR_2": 19236.864983203122,
    "YEAR_3": 19426.39382882812,
    "YEAR_4": 19615.922674453126,
    "YEAR_5": 19805.451520078124,
    "YEAR_6": 19994.980365703126,
    "YEAR_7": 20184.509211328128,
    "YEAR_8": 20374.038056953126,
    "YEAR_9": 20563.566902578124,
    "YEAR_10": 20753.095748203123,
    "FIXED_GoFEMA": 194258.09372378903
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 69545809,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1738645.225,
    "% of funds from communication to NEXUS": 17386.452250000002,
    "Per Month": 1448.8710208333334,
    "YEAR_1": 13115.874489802314,
    "YEAR_2": 17639.903257674378,
    "YEAR_3": 17813.698234365376,
    "YEAR_4": 17987.49321105638,
    "YEAR_5": 18161.288187747377,
    "YEAR_6": 18335.083164438376,
    "YEAR_7": 18508.878141129375,
    "YEAR_8": 18682.673117820377,
    "YEAR_9": 18856.468094511376,
    "YEAR_10": 19030.263071202375,
    "FIXED_GoFEMA": 178131.6229697477
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 111650976.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2791274.4125,
    "% of funds from communication to NEXUS": 27912.744125,
    "Per Month": 2326.062010416667,
    "YEAR_1": 21056.627501994655,
    "YEAR_2": 28319.642152482193,
    "YEAR_3": 28598.657942755694,
    "YEAR_4": 28877.673733029187,
    "YEAR_5": 29156.689523302684,
    "YEAR_6": 29435.705313576193,
    "YEAR_7": 29714.721103849686,
    "YEAR_8": 29993.73689412319,
    "YEAR_9": 30272.752684396688,
    "YEAR_10": 30551.768474670193,
    "FIXED_GoFEMA": 285977.9753241804
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 226958877,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 5673971.925000001,
    "% of funds from communication to NEXUS": 56739.71925000001,
    "Per Month": 4728.309937500001,
    "YEAR_1": 42802.92641471007,
    "YEAR_2": 57566.84250736689,
    "YEAR_3": 58134.01274098988,
    "YEAR_4": 58701.182974612886,
    "YEAR_5": 59268.353208235894,
    "YEAR_6": 59835.523441858895,
    "YEAR_7": 60402.69367548189,
    "YEAR_8": 60969.86390910489,
    "YEAR_9": 61537.03414272789,
    "YEAR_10": 62104.20437635088,
    "FIXED_GoFEMA": 581322.6373914401
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 111734868.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2793371.7075,
    "% of funds from communication to NEXUS": 27933.717075,
    "Per Month": 2327.80975625,
    "YEAR_1": 21072.448934448246,
    "YEAR_2": 28340.920835660818,
    "YEAR_3": 28620.14627154252,
    "YEAR_4": 28899.37170742422,
    "YEAR_5": 29178.59714330592,
    "YEAR_6": 29457.82257918762,
    "YEAR_7": 29737.048015069322,
    "YEAR_8": 30016.273450951023,
    "YEAR_9": 30295.498886832724,
    "YEAR_10": 30574.724322714424,
    "FIXED_GoFEMA": 286192.8521471369
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 36319995,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 907999.875,
    "% of funds from communication to NEXUS": 9079.99875,
    "Per Month": 756.6665625,
    "YEAR_1": 6849.708167033437,
    "YEAR_2": 9212.362431778125,
    "YEAR_3": 9303.126099283125,
    "YEAR_4": 9393.889766788127,
    "YEAR_5": 9484.653434293126,
    "YEAR_6": 9575.417101798126,
    "YEAR_7": 9666.180769303126,
    "YEAR_8": 9756.944436808126,
    "YEAR_9": 9847.708104313126,
    "YEAR_10": 9938.471771818124,
    "FIXED_GoFEMA": 93028.46208321658
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 15841197.67,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 396029.94175,
    "% of funds from communication to NEXUS": 3960.2994175,
    "Per Month": 330.0249514583333,
    "YEAR_1": 2987.5439425525815,
    "YEAR_2": 4018.030682258605,
    "YEAR_3": 4057.6178352359366,
    "YEAR_4": 4097.204988213267,
    "YEAR_5": 4136.792141190595,
    "YEAR_6": 4176.379294167925,
    "YEAR_7": 4215.966447145256,
    "YEAR_8": 4255.553600122586,
    "YEAR_9": 4295.140753099915,
    "YEAR_10": 4334.727906077247,
    "FIXED_GoFEMA": 40574.95759006392
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 138380970.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3459524.2575000003,
    "% of funds from communication to NEXUS": 34595.242575000004,
    "Per Month": 2882.93688125,
    "YEAR_1": 26097.72557584112,
    "YEAR_2": 35099.55472363707,
    "YEAR_3": 35445.36876841676,
    "YEAR_4": 35791.182813196465,
    "YEAR_5": 36136.996857976155,
    "YEAR_6": 36482.81090275587,
    "YEAR_7": 36828.624947535565,
    "YEAR_8": 37174.43899231527,
    "YEAR_9": 37520.25303709497,
    "YEAR_10": 37866.067081874666,
    "FIXED_GoFEMA": 354443.02370064385
  },
  {
    "DMA": "Louisville",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 32452018.75,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 811300.46875,
    "% of funds from communication to NEXUS": 8113.0046875,
    "Per Month": 676.0837239583333,
    "YEAR_1": 6120.233713374609,
    "YEAR_2": 8231.27201333203,
    "YEAR_3": 8312.369608188279,
    "YEAR_4": 8393.46720304453,
    "YEAR_5": 8474.564797900779,
    "YEAR_6": 8555.66239275703,
    "YEAR_7": 8636.759987613279,
    "YEAR_8": 8717.85758246953,
    "YEAR_9": 8798.955177325779,
    "YEAR_10": 8880.05277218203,
    "FIXED_GoFEMA": 83121.19524818787
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 132120654.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3303016.3625000003,
    "% of funds from communication to NEXUS": 33030.163625,
    "Per Month": 2752.5136354166666,
    "YEAR_1": 24917.07188182303,
    "YEAR_2": 33511.660835243434,
    "YEAR_3": 33841.830350838936,
    "YEAR_4": 34171.99986643444,
    "YEAR_5": 34502.16938202994,
    "YEAR_6": 34832.338897625435,
    "YEAR_7": 35162.50841322094,
    "YEAR_8": 35492.67792881644,
    "YEAR_9": 35822.847444411935,
    "YEAR_10": 36153.01696000744,
    "FIXED_GoFEMA": 338408.121960452
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 515669245,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 12891731.125,
    "% of funds from communication to NEXUS": 128917.31125,
    "Per Month": 10743.109270833333,
    "YEAR_1": 97251.77106892405,
    "YEAR_2": 130796.60335474685,
    "YEAR_3": 132085.26079800187,
    "YEAR_4": 133373.91824125688,
    "YEAR_5": 134662.57568451186,
    "YEAR_6": 135951.23312776687,
    "YEAR_7": 137239.89057102188,
    "YEAR_8": 138528.54801427686,
    "YEAR_9": 139817.20545753185,
    "YEAR_10": 141105.86290078686,
    "FIXED_GoFEMA": 1320812.8692188258
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 703104444,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 17577611.1,
    "% of funds from communication to NEXUS": 175776.111,
    "Per Month": 14648.009250000001,
    "YEAR_1": 132600.79612743075,
    "YEAR_2": 178338.4872581025,
    "YEAR_3": 180095.54526365854,
    "YEAR_4": 181852.6032692145,
    "YEAR_5": 183609.6612747705,
    "YEAR_6": 185366.71928032654,
    "YEAR_7": 187123.7772858825,
    "YEAR_8": 188880.8352914385,
    "YEAR_9": 190637.89329699453,
    "YEAR_10": 192394.9513025505,
    "FIXED_GoFEMA": 1800901.2696503692
  },
  {
    "DMA": "Memphis",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 14626023.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 365650.5875,
    "% of funds from communication to NEXUS": 3656.5058750000003,
    "Per Month": 304.7088229166667,
    "YEAR_1": 2758.370220567844,
    "YEAR_2": 3709.808589392813,
    "YEAR_3": 3746.359022119313,
    "YEAR_4": 3782.9094548458124,
    "YEAR_5": 3819.459887572313,
    "YEAR_6": 3856.010320298813,
    "YEAR_7": 3892.560753025313,
    "YEAR_8": 3929.111185751813,
    "YEAR_9": 3965.6616184783124,
    "YEAR_10": 4002.212051204813,
    "FIXED_GoFEMA": 37462.46310325716
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 254913705.7,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 6372842.6425,
    "% of funds from communication to NEXUS": 63728.426425000005,
    "Per Month": 5310.702202083334,
    "YEAR_1": 48075.02015961314,
    "YEAR_2": 64657.42756121044,
    "YEAR_3": 65294.45691175475,
    "YEAR_4": 65931.48626229903,
    "YEAR_5": 66568.51561284336,
    "YEAR_6": 67205.54496338764,
    "YEAR_7": 67842.57431393194,
    "YEAR_8": 68479.60366447625,
    "YEAR_9": 69116.63301502053,
    "YEAR_10": 69753.66236556484,
    "FIXED_GoFEMA": 652924.9248301019
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 268157718,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 6703942.95,
    "% of funds from communication to NEXUS": 67039.4295,
    "Per Month": 5586.619125,
    "YEAR_1": 50572.752310060874,
    "YEAR_2": 68016.69678353624,
    "YEAR_3": 68686.82292081826,
    "YEAR_4": 69356.94905810026,
    "YEAR_5": 70027.07519538225,
    "YEAR_6": 70697.20133266426,
    "YEAR_7": 71367.32746994625,
    "YEAR_8": 72037.45360722824,
    "YEAR_9": 72707.57974451027,
    "YEAR_10": 73377.70588179225,
    "FIXED_GoFEMA": 686847.5643040391
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 139091617.8,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3477290.4450000003,
    "% of funds from communication to NEXUS": 34772.90445,
    "Per Month": 2897.7420375,
    "YEAR_1": 26231.748941885966,
    "YEAR_2": 35279.806464619876,
    "YEAR_3": 35627.39641750208,
    "YEAR_4": 35974.98637038428,
    "YEAR_5": 36322.576323266476,
    "YEAR_6": 36670.16627614867,
    "YEAR_7": 37017.75622903088,
    "YEAR_8": 37365.346181913075,
    "YEAR_9": 37712.93613479527,
    "YEAR_10": 38060.52608767748,
    "FIXED_GoFEMA": 356263.245427224
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 74489912,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1862247.8,
    "% of funds from communication to NEXUS": 18622.478,
    "Per Month": 1551.8731666666665,
    "YEAR_1": 14048.299251913499,
    "YEAR_2": 18893.947173045,
    "YEAR_3": 19080.097463133,
    "YEAR_4": 19266.247753220996,
    "YEAR_5": 19452.398043309,
    "YEAR_6": 19638.548333396997,
    "YEAR_7": 19824.698623484997,
    "YEAR_8": 20010.848913572998,
    "YEAR_9": 20196.999203661,
    "YEAR_10": 20383.149493749,
    "FIXED_GoFEMA": 190795.23425248647
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 113479439,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2836985.975,
    "% of funds from communication to NEXUS": 28369.859750000003,
    "Per Month": 2364.154979166667,
    "YEAR_1": 21401.46330165169,
    "YEAR_2": 28783.421380505628,
    "YEAR_3": 29067.00649856663,
    "YEAR_4": 29350.591616627633,
    "YEAR_5": 29634.176734688626,
    "YEAR_6": 29917.761852749627,
    "YEAR_7": 30201.34697081063,
    "YEAR_8": 30484.932088871632,
    "YEAR_9": 30768.51720693263,
    "YEAR_10": 31052.10232499363,
    "FIXED_GoFEMA": 290661.3199763984
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 148171616.8,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3704290.4200000004,
    "% of funds from communication to NEXUS": 37042.904200000004,
    "Per Month": 3086.9086833333336,
    "YEAR_1": 27944.176030792656,
    "YEAR_2": 37582.8971359755,
    "YEAR_3": 37953.17800635871,
    "YEAR_4": 38323.458876741905,
    "YEAR_5": 38693.7397471251,
    "YEAR_6": 39064.0206175083,
    "YEAR_7": 39434.3014878915,
    "YEAR_8": 39804.582358274696,
    "YEAR_9": 40174.863228657894,
    "YEAR_10": 40545.14409904111,
    "FIXED_GoFEMA": 379520.3615883674
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 804473154,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 20111828.85,
    "% of funds from communication to NEXUS": 201118.28850000002,
    "Per Month": 16759.857375000003,
    "YEAR_1": 151718.25693018266,
    "YEAR_2": 204050.0903506088,
    "YEAR_3": 206060.4687624548,
    "YEAR_4": 208070.8471743008,
    "YEAR_5": 210081.22558614681,
    "YEAR_6": 212091.60399799282,
    "YEAR_7": 214101.98240983882,
    "YEAR_8": 216112.36082168482,
    "YEAR_9": 218122.73923353083,
    "YEAR_10": 220133.11764537677,
    "FIXED_GoFEMA": 2060542.692912118
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 34663687.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 866592.1875,
    "% of funds from communication to NEXUS": 8665.921875,
    "Per Month": 722.16015625,
    "YEAR_1": 6537.339649089845,
    "YEAR_2": 8792.249351132812,
    "YEAR_3": 8878.873906195313,
    "YEAR_4": 8965.498461257812,
    "YEAR_5": 9052.123016320313,
    "YEAR_6": 9138.747571382812,
    "YEAR_7": 9225.372126445312,
    "YEAR_8": 9311.996681507811,
    "YEAR_9": 9398.621236570314,
    "YEAR_10": 9485.245791632813,
    "FIXED_GoFEMA": 88786.06779153516
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 14626023.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 365650.5875,
    "% of funds from communication to NEXUS": 3656.5058750000003,
    "Per Month": 304.7088229166667,
    "YEAR_1": 2758.370220567844,
    "YEAR_2": 3709.808589392813,
    "YEAR_3": 3746.359022119313,
    "YEAR_4": 3782.9094548458124,
    "YEAR_5": 3819.459887572313,
    "YEAR_6": 3856.010320298813,
    "YEAR_7": 3892.560753025313,
    "YEAR_8": 3929.111185751813,
    "YEAR_9": 3965.6616184783124,
    "YEAR_10": 4002.212051204813,
    "FIXED_GoFEMA": 37462.46310325716
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 15841197.67,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 396029.94175,
    "% of funds from communication to NEXUS": 3960.2994175,
    "Per Month": 330.0249514583333,
    "YEAR_1": 2987.5439425525815,
    "YEAR_2": 4018.030682258605,
    "YEAR_3": 4057.6178352359366,
    "YEAR_4": 4097.204988213267,
    "YEAR_5": 4136.792141190595,
    "YEAR_6": 4176.379294167925,
    "YEAR_7": 4215.966447145256,
    "YEAR_8": 4255.553600122586,
    "YEAR_9": 4295.140753099915,
    "YEAR_10": 4334.727906077247,
    "FIXED_GoFEMA": 40574.95759006392
  },
  {
    "DMA": "Lexington",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 32452018.75,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 811300.46875,
    "% of funds from communication to NEXUS": 8113.0046875,
    "Per Month": 676.0837239583333,
    "YEAR_1": 6120.233713374609,
    "YEAR_2": 8231.27201333203,
    "YEAR_3": 8312.369608188279,
    "YEAR_4": 8393.46720304453,
    "YEAR_5": 8474.564797900779,
    "YEAR_6": 8555.66239275703,
    "YEAR_7": 8636.759987613279,
    "YEAR_8": 8717.85758246953,
    "YEAR_9": 8798.955177325779,
    "YEAR_10": 8880.05277218203,
    "FIXED_GoFEMA": 83121.19524818787
  },
  {
    "DMA": "Dayton",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 38191766,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 954794.15,
    "% of funds from communication to NEXUS": 9547.9415,
    "Per Month": 795.6617916666668,
    "YEAR_1": 7202.711660164876,
    "YEAR_2": 9687.126617216252,
    "YEAR_3": 9782.56784045025,
    "YEAR_4": 9878.00906368425,
    "YEAR_5": 9973.45028691825,
    "YEAR_6": 10068.89151015225,
    "YEAR_7": 10164.33273338625,
    "YEAR_8": 10259.77395662025,
    "YEAR_9": 10355.21517985425,
    "YEAR_10": 10450.656403088251,
    "FIXED_GoFEMA": 97822.73525153514
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 35690042.33,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 892251.05825,
    "% of funds from communication to NEXUS": 8922.510582500001,
    "Per Month": 743.5425485416667,
    "YEAR_1": 6730.903306279919,
    "YEAR_2": 9052.578480516395,
    "YEAR_3": 9141.767896299065,
    "YEAR_4": 9230.957312081735,
    "YEAR_5": 9320.146727864405,
    "YEAR_6": 9409.336143647073,
    "YEAR_7": 9498.525559429743,
    "YEAR_8": 9587.714975212413,
    "YEAR_9": 9676.904390995087,
    "YEAR_10": 9766.093806777757,
    "FIXED_GoFEMA": 91414.92859910359
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 104252390.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2606309.7575000003,
    "% of funds from communication to NEXUS": 26063.097575000003,
    "Per Month": 2171.924797916667,
    "YEAR_1": 19661.303622719875,
    "YEAR_2": 26443.03237989957,
    "YEAR_3": 26703.559103259267,
    "YEAR_4": 26964.085826618968,
    "YEAR_5": 27224.612549978672,
    "YEAR_6": 27485.13927333837,
    "YEAR_7": 27745.66599669807,
    "YEAR_8": 28006.192720057767,
    "YEAR_9": 28266.719443417467,
    "YEAR_10": 28527.246166777168,
    "FIXED_GoFEMA": 267027.5570827652
  },
  {
    "DMA": "Spokane",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 94332750.25,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2358318.75625,
    "% of funds from communication to NEXUS": 23583.187562500003,
    "Per Month": 1965.2656302083335,
    "YEAR_1": 17790.525846882705,
    "YEAR_2": 23926.971479192343,
    "YEAR_3": 24162.709022067098,
    "YEAR_4": 24398.446564941845,
    "YEAR_5": 24634.184107816596,
    "YEAR_6": 24869.921650691347,
    "YEAR_7": 25105.659193566094,
    "YEAR_8": 25341.396736440845,
    "YEAR_9": 25577.134279315596,
    "YEAR_10": 25812.871822190347,
    "FIXED_GoFEMA": 241619.82070310484
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 48827216,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1220680.4000000001,
    "% of funds from communication to NEXUS": 12206.804000000002,
    "Per Month": 1017.2336666666669,
    "YEAR_1": 9208.486405593001,
    "YEAR_2": 12384.74868531,
    "YEAR_3": 12506.767898094002,
    "YEAR_4": 12628.787110878004,
    "YEAR_5": 12750.806323662002,
    "YEAR_6": 12872.825536446,
    "YEAR_7": 12994.844749230002,
    "YEAR_8": 13116.863962014004,
    "YEAR_9": 13238.883174798,
    "YEAR_10": 13360.902387582,
    "FIXED_GoFEMA": 125063.91623360701
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 158552275,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3963806.875,
    "% of funds from communication to NEXUS": 39638.06875,
    "Per Month": 3303.172395833333,
    "YEAR_1": 29901.898746660932,
    "YEAR_2": 40215.892697203126,
    "YEAR_3": 40612.114832428124,
    "YEAR_4": 41008.33696765313,
    "YEAR_5": 41404.55910287811,
    "YEAR_6": 41800.781238103125,
    "YEAR_7": 42197.003373328116,
    "YEAR_8": 42593.22550855313,
    "YEAR_9": 42989.44764377812,
    "YEAR_10": 43385.66977900313,
    "FIXED_GoFEMA": 406108.9298895891
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 85880167.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2147004.1875,
    "% of funds from communication to NEXUS": 21470.041875,
    "Per Month": 1789.17015625,
    "YEAR_1": 16196.425266879847,
    "YEAR_2": 21783.021410432808,
    "YEAR_3": 21997.635949015315,
    "YEAR_4": 22212.250487597816,
    "YEAR_5": 22426.865026180316,
    "YEAR_6": 22641.47956476281,
    "YEAR_7": 22856.094103345313,
    "YEAR_8": 23070.70864192781,
    "YEAR_9": 23285.323180510313,
    "YEAR_10": 23499.937719092817,
    "FIXED_GoFEMA": 219969.74134974516
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 74489912,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1862247.8,
    "% of funds from communication to NEXUS": 18622.478,
    "Per Month": 1551.8731666666665,
    "YEAR_1": 14048.299251913499,
    "YEAR_2": 18893.947173045,
    "YEAR_3": 19080.097463133,
    "YEAR_4": 19266.247753220996,
    "YEAR_5": 19452.398043309,
    "YEAR_6": 19638.548333396997,
    "YEAR_7": 19824.698623484997,
    "YEAR_8": 20010.848913572998,
    "YEAR_9": 20196.999203661,
    "YEAR_10": 20383.149493749,
    "FIXED_GoFEMA": 190795.23425248647
  },
  {
    "DMA": "Madison",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 79276138,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1981903.4500000002,
    "% of funds from communication to NEXUS": 19819.0345,
    "Per Month": 1651.5862083333334,
    "YEAR_1": 14950.949467627124,
    "YEAR_2": 20107.946475423756,
    "YEAR_3": 20306.05754428575,
    "YEAR_4": 20504.16861314775,
    "YEAR_5": 20702.27968200975,
    "YEAR_6": 20900.39075087175,
    "YEAR_7": 21098.50181973375,
    "YEAR_8": 21296.612888595748,
    "YEAR_9": 21494.72395745775,
    "YEAR_10": 21692.835026319754,
    "FIXED_GoFEMA": 203054.4662254729
  },
  {
    "DMA": "Omaha",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 18306801,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 457670.025,
    "% of funds from communication to NEXUS": 4576.700250000001,
    "Per Month": 381.39168750000005,
    "YEAR_1": 3452.5402418683125,
    "YEAR_2": 4643.417097894376,
    "YEAR_3": 4689.165793593375,
    "YEAR_4": 4734.914489292375,
    "YEAR_5": 4780.663184991375,
    "YEAR_6": 4826.411880690375,
    "YEAR_7": 4872.160576389375,
    "YEAR_8": 4917.909272088375,
    "YEAR_9": 4963.657967787375,
    "YEAR_10": 5009.406663486376,
    "FIXED_GoFEMA": 46890.247168081696
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 111650976.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2791274.4125,
    "% of funds from communication to NEXUS": 27912.744125,
    "Per Month": 2326.062010416667,
    "YEAR_1": 21056.627501994655,
    "YEAR_2": 28319.642152482193,
    "YEAR_3": 28598.657942755694,
    "YEAR_4": 28877.673733029187,
    "YEAR_5": 29156.689523302684,
    "YEAR_6": 29435.705313576193,
    "YEAR_7": 29714.721103849686,
    "YEAR_8": 29993.73689412319,
    "YEAR_9": 30272.752684396688,
    "YEAR_10": 30551.768474670193,
    "FIXED_GoFEMA": 285977.9753241804
  },
  {
    "DMA": "Springfield",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 21840154.67,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 546003.86675,
    "% of funds from communication to NEXUS": 5460.038667500001,
    "Per Month": 455.00322229166676,
    "YEAR_1": 4118.9071147276445,
    "YEAR_2": 5539.632381175482,
    "YEAR_3": 5594.210927695813,
    "YEAR_4": 5648.7894742161425,
    "YEAR_5": 5703.368020736472,
    "YEAR_6": 5757.946567256802,
    "YEAR_7": 5812.525113777133,
    "YEAR_8": 5867.103660297463,
    "YEAR_9": 5921.682206817793,
    "YEAR_10": 5976.260753338122,
    "FIXED_GoFEMA": 55940.42622003887
  },
  {
    "DMA": "Columbia",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 33839604.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 845990.1125,
    "% of funds from communication to NEXUS": 8459.901125,
    "Per Month": 704.9917604166667,
    "YEAR_1": 6381.923106344907,
    "YEAR_2": 8583.225333649689,
    "YEAR_3": 8667.790505295188,
    "YEAR_4": 8752.355676940688,
    "YEAR_5": 8836.920848586187,
    "YEAR_6": 8921.486020231687,
    "YEAR_7": 9006.051191877188,
    "YEAR_8": 9090.616363522688,
    "YEAR_9": 9175.181535168189,
    "YEAR_10": 9259.746706813687,
    "FIXED_GoFEMA": 86675.2972884301
  },
  {
    "DMA": "Rochester",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 268157718,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 6703942.95,
    "% of funds from communication to NEXUS": 67039.4295,
    "Per Month": 5586.619125,
    "YEAR_1": 50572.752310060874,
    "YEAR_2": 68016.69678353624,
    "YEAR_3": 68686.82292081826,
    "YEAR_4": 69356.94905810026,
    "YEAR_5": 70027.07519538225,
    "YEAR_6": 70697.20133266426,
    "YEAR_7": 71367.32746994625,
    "YEAR_8": 72037.45360722824,
    "YEAR_9": 72707.57974451027,
    "YEAR_10": 73377.70588179225,
    "FIXED_GoFEMA": 686847.5643040391
  },
  {
    "DMA": "Portland",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 52051312.67,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1301282.8167500002,
    "% of funds from communication to NEXUS": 13012.828167500003,
    "Per Month": 1084.402347291667,
    "YEAR_1": 9816.529476408523,
    "YEAR_2": 13202.522670111735,
    "YEAR_3": 13332.598900474066,
    "YEAR_4": 13462.675130836395,
    "YEAR_5": 13592.751361198725,
    "YEAR_6": 13722.827591561054,
    "YEAR_7": 13852.903821923384,
    "YEAR_8": 13982.980052285713,
    "YEAR_9": 14113.056282648045,
    "YEAR_10": 14243.132513010376,
    "FIXED_GoFEMA": 133321.977800458
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 15059836.67,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 376495.91675000003,
    "% of funds from communication to NEXUS": 3764.9591675000006,
    "Per Month": 313.7465972916667,
    "YEAR_1": 2840.18448330427,
    "YEAR_2": 3819.842859764233,
    "YEAR_3": 3857.477391602561,
    "YEAR_4": 3895.111923440892,
    "YEAR_5": 3932.7464552792226,
    "YEAR_6": 3970.3809871175513,
    "YEAR_7": 4008.015518955882,
    "YEAR_8": 4045.650050794212,
    "YEAR_9": 4083.2845826325415,
    "YEAR_10": 4120.919114470872,
    "FIXED_GoFEMA": 38573.61336736224
  },
  {
    "DMA": "Toledo",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 38191766,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 954794.15,
    "% of funds from communication to NEXUS": 9547.9415,
    "Per Month": 795.6617916666668,
    "YEAR_1": 7202.711660164876,
    "YEAR_2": 9687.126617216252,
    "YEAR_3": 9782.56784045025,
    "YEAR_4": 9878.00906368425,
    "YEAR_5": 9973.45028691825,
    "YEAR_6": 10068.89151015225,
    "YEAR_7": 10164.33273338625,
    "YEAR_8": 10259.77395662025,
    "YEAR_9": 10355.21517985425,
    "YEAR_10": 10450.656403088251,
    "FIXED_GoFEMA": 97822.73525153514
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 36319995,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 907999.875,
    "% of funds from communication to NEXUS": 9079.99875,
    "Per Month": 756.6665625,
    "YEAR_1": 6849.708167033437,
    "YEAR_2": 9212.362431778125,
    "YEAR_3": 9303.126099283125,
    "YEAR_4": 9393.889766788127,
    "YEAR_5": 9484.653434293126,
    "YEAR_6": 9575.417101798126,
    "YEAR_7": 9666.180769303126,
    "YEAR_8": 9756.944436808126,
    "YEAR_9": 9847.708104313126,
    "YEAR_10": 9938.471771818124,
    "FIXED_GoFEMA": 93028.46208321658
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 507123786,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 12678094.65,
    "% of funds from communication to NEXUS": 126780.9465,
    "Per Month": 10565.078875000001,
    "YEAR_1": 95640.15464928113,
    "YEAR_2": 128629.09574760377,
    "YEAR_3": 129896.39808881778,
    "YEAR_4": 131163.70043003175,
    "YEAR_5": 132431.00277124578,
    "YEAR_6": 133698.30511245978,
    "YEAR_7": 134965.60745367376,
    "YEAR_8": 136232.90979488776,
    "YEAR_9": 137500.21213610173,
    "YEAR_10": 138767.51447731577,
    "FIXED_GoFEMA": 1298924.9006614191
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 676165047.7,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 16904126.192500003,
    "% of funds from communication to NEXUS": 169041.26192500003,
    "Per Month": 14086.771827083336,
    "YEAR_1": 127520.20614246352,
    "YEAR_2": 171505.4609207117,
    "YEAR_3": 173195.19737491402,
    "YEAR_4": 174884.9338291163,
    "YEAR_5": 176574.6702833186,
    "YEAR_6": 178264.40673752094,
    "YEAR_7": 179954.1431917232,
    "YEAR_8": 181643.8796459255,
    "YEAR_9": 183333.61610012778,
    "YEAR_10": 185023.35255433014,
    "FIXED_GoFEMA": 1731899.8667801516
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 14626023.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 365650.5875,
    "% of funds from communication to NEXUS": 3656.5058750000003,
    "Per Month": 304.7088229166667,
    "YEAR_1": 2758.370220567844,
    "YEAR_2": 3709.808589392813,
    "YEAR_3": 3746.359022119313,
    "YEAR_4": 3782.9094548458124,
    "YEAR_5": 3819.459887572313,
    "YEAR_6": 3856.010320298813,
    "YEAR_7": 3892.560753025313,
    "YEAR_8": 3929.111185751813,
    "YEAR_9": 3965.6616184783124,
    "YEAR_10": 4002.212051204813,
    "FIXED_GoFEMA": 37462.46310325716
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 268157718,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 6703942.95,
    "% of funds from communication to NEXUS": 67039.4295,
    "Per Month": 5586.619125,
    "YEAR_1": 50572.752310060874,
    "YEAR_2": 68016.69678353624,
    "YEAR_3": 68686.82292081826,
    "YEAR_4": 69356.94905810026,
    "YEAR_5": 70027.07519538225,
    "YEAR_6": 70697.20133266426,
    "YEAR_7": 71367.32746994625,
    "YEAR_8": 72037.45360722824,
    "YEAR_9": 72707.57974451027,
    "YEAR_10": 73377.70588179225,
    "FIXED_GoFEMA": 686847.5643040391
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 424658711.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 10616467.787500001,
    "% of funds from communication to NEXUS": 106164.67787500002,
    "Per Month": 8847.056489583336,
    "YEAR_1": 80087.79308376687,
    "YEAR_2": 107712.29346672284,
    "YEAR_3": 108773.51558676135,
    "YEAR_4": 109834.73770679985,
    "YEAR_5": 110895.95982683834,
    "YEAR_6": 111957.18194687684,
    "YEAR_7": 113018.40406691535,
    "YEAR_8": 114079.62618695384,
    "YEAR_9": 115140.84830699235,
    "YEAR_10": 116202.07042703085,
    "FIXED_GoFEMA": 1087702.4306056586
  },
  {
    "DMA": "Savannah",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 12956379,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 323909.47500000003,
    "% of funds from communication to NEXUS": 3239.09475,
    "Per Month": 269.92456250000004,
    "YEAR_1": 2443.486433615438,
    "YEAR_2": 3286.3126537181256,
    "YEAR_3": 3318.6906448391255,
    "YEAR_4": 3351.0686359601254,
    "YEAR_5": 3383.4466270811254,
    "YEAR_6": 3415.8246182021253,
    "YEAR_7": 3448.202609323125,
    "YEAR_8": 3480.5806004441256,
    "YEAR_9": 3512.958591565125,
    "YEAR_10": 3545.3365826861254,
    "FIXED_GoFEMA": 33185.907997434566
  },
  {
    "DMA": "Charleston",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 33839604.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 845990.1125,
    "% of funds from communication to NEXUS": 8459.901125,
    "Per Month": 704.9917604166667,
    "YEAR_1": 6381.923106344907,
    "YEAR_2": 8583.225333649689,
    "YEAR_3": 8667.790505295188,
    "YEAR_4": 8752.355676940688,
    "YEAR_5": 8836.920848586187,
    "YEAR_6": 8921.486020231687,
    "YEAR_7": 9006.051191877188,
    "YEAR_8": 9090.616363522688,
    "YEAR_9": 9175.181535168189,
    "YEAR_10": 9259.746706813687,
    "FIXED_GoFEMA": 86675.2972884301
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 515669245,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 12891731.125,
    "% of funds from communication to NEXUS": 128917.31125,
    "Per Month": 10743.109270833333,
    "YEAR_1": 97251.77106892405,
    "YEAR_2": 130796.60335474685,
    "YEAR_3": 132085.26079800187,
    "YEAR_4": 133373.91824125688,
    "YEAR_5": 134662.57568451186,
    "YEAR_6": 135951.23312776687,
    "YEAR_7": 137239.89057102188,
    "YEAR_8": 138528.54801427686,
    "YEAR_9": 139817.20545753185,
    "YEAR_10": 141105.86290078686,
    "FIXED_GoFEMA": 1320812.8692188258
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 187874526,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4696863.15,
    "% of funds from communication to NEXUS": 46968.6315,
    "Per Month": 3914.0526250000003,
    "YEAR_1": 35431.879192707376,
    "YEAR_2": 47653.31672569126,
    "YEAR_3": 48122.81516616526,
    "YEAR_4": 48592.313606639254,
    "YEAR_5": 49061.812047113264,
    "YEAR_6": 49531.31048758725,
    "YEAR_7": 50000.80892806126,
    "YEAR_8": 50470.30736853525,
    "YEAR_9": 50939.80580900926,
    "YEAR_10": 51409.304249483255,
    "FIXED_GoFEMA": 481213.6735809927
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 159811110,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3995277.75,
    "% of funds from communication to NEXUS": 39952.777500000004,
    "Per Month": 3329.398125,
    "YEAR_1": 30139.306609201878,
    "YEAR_2": 40535.18911400625,
    "YEAR_3": 40934.55707789624,
    "YEAR_4": 41333.92504178626,
    "YEAR_5": 41733.29300567625,
    "YEAR_6": 42132.66096956626,
    "YEAR_7": 42532.028933456255,
    "YEAR_8": 42931.39689734624,
    "YEAR_9": 43330.76486123625,
    "YEAR_10": 43730.132825126246,
    "FIXED_GoFEMA": 409333.25533529813
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 80144556,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2003613.9000000001,
    "% of funds from communication to NEXUS": 20036.139000000003,
    "Per Month": 1669.6782500000002,
    "YEAR_1": 15114.727294881752,
    "YEAR_2": 20328.215816272503,
    "YEAR_3": 20528.4970617165,
    "YEAR_4": 20728.7783071605,
    "YEAR_5": 20929.0595526045,
    "YEAR_6": 21129.3407980485,
    "YEAR_7": 21329.6220434925,
    "YEAR_8": 21529.9032889365,
    "YEAR_9": 21730.1845343805,
    "YEAR_10": 21930.4657798245,
    "FIXED_GoFEMA": 205278.79447731827
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 97654432,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2441360.8000000003,
    "% of funds from communication to NEXUS": 24413.608000000004,
    "Per Month": 2034.4673333333337,
    "YEAR_1": 18416.972811186002,
    "YEAR_2": 24769.49737062,
    "YEAR_3": 25013.535796188004,
    "YEAR_4": 25257.574221756007,
    "YEAR_5": 25501.612647324004,
    "YEAR_6": 25745.651072892,
    "YEAR_7": 25989.689498460004,
    "YEAR_8": 26233.727924028008,
    "YEAR_9": 26477.766349596,
    "YEAR_10": 26721.804775164,
    "FIXED_GoFEMA": 250127.83246721403
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 407112903.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 10177822.582500001,
    "% of funds from communication to NEXUS": 101778.22582500002,
    "Per Month": 8481.51881875,
    "YEAR_1": 76778.77099483919,
    "YEAR_2": 103261.89791196396,
    "YEAR_3": 104279.27305731065,
    "YEAR_4": 105296.64820265734,
    "YEAR_5": 106314.02334800406,
    "YEAR_6": 107331.39849335075,
    "YEAR_7": 108348.77363869746,
    "YEAR_8": 109366.14878404413,
    "YEAR_9": 110383.52392939084,
    "YEAR_10": 111400.89907473755,
    "FIXED_GoFEMA": 1042761.3574349958
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 515669245,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 12891731.125,
    "% of funds from communication to NEXUS": 128917.31125,
    "Per Month": 10743.109270833333,
    "YEAR_1": 97251.77106892405,
    "YEAR_2": 130796.60335474685,
    "YEAR_3": 132085.26079800187,
    "YEAR_4": 133373.91824125688,
    "YEAR_5": 134662.57568451186,
    "YEAR_6": 135951.23312776687,
    "YEAR_7": 137239.89057102188,
    "YEAR_8": 138528.54801427686,
    "YEAR_9": 139817.20545753185,
    "YEAR_10": 141105.86290078686,
    "FIXED_GoFEMA": 1320812.8692188258
  },
  {
    "DMA": "Jackson",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 13134751.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 328368.78750000003,
    "% of funds from communication to NEXUS": 3283.6878750000005,
    "Per Month": 273.64065625000006,
    "YEAR_1": 2477.1262942493445,
    "YEAR_2": 3331.555834997813,
    "YEAR_3": 3364.3795789963133,
    "YEAR_4": 3397.203322994813,
    "YEAR_5": 3430.027066993313,
    "YEAR_6": 3462.8508109918134,
    "YEAR_7": 3495.674554990313,
    "YEAR_8": 3528.498298988813,
    "YEAR_9": 3561.322042987313,
    "YEAR_10": 3594.145786985813,
    "FIXED_GoFEMA": 33642.78359317566
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 69327375.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1733184.3875000002,
    "% of funds from communication to NEXUS": 17331.843875000002,
    "Per Month": 1444.3203229166668,
    "YEAR_1": 13074.679392476346,
    "YEAR_2": 17584.498829087814,
    "YEAR_3": 17757.747940462315,
    "YEAR_4": 17930.997051836814,
    "YEAR_5": 18104.24616321131,
    "YEAR_6": 18277.495274585814,
    "YEAR_7": 18450.744385960315,
    "YEAR_8": 18623.99349733481,
    "YEAR_9": 18797.24260870931,
    "YEAR_10": 18970.491720083814,
    "FIXED_GoFEMA": 177572.13686374866
  },
  {
    "DMA": "Boise",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 30168716.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 754217.9125000001,
    "% of funds from communication to NEXUS": 7542.1791250000015,
    "Per Month": 628.5149270833334,
    "YEAR_1": 5689.618178608408,
    "YEAR_2": 7652.125241194688,
    "YEAR_3": 7727.516863728189,
    "YEAR_4": 7802.908486261689,
    "YEAR_5": 7878.300108795189,
    "YEAR_6": 7953.691731328689,
    "YEAR_7": 8029.083353862188,
    "YEAR_8": 8104.474976395689,
    "YEAR_9": 8179.866598929188,
    "YEAR_10": 8255.25822146269,
    "FIXED_GoFEMA": 77272.8437605666
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 19006585,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 475164.625,
    "% of funds from communication to NEXUS": 4751.64625,
    "Per Month": 395.9705208333333,
    "YEAR_1": 3584.514824462812,
    "YEAR_2": 4820.913373209375,
    "YEAR_3": 4868.410829124376,
    "YEAR_4": 4915.908285039375,
    "YEAR_5": 4963.405740954375,
    "YEAR_6": 5010.903196869374,
    "YEAR_7": 5058.400652784374,
    "YEAR_8": 5105.898108699374,
    "YEAR_9": 5153.395564614375,
    "YEAR_10": 5200.893020529375,
    "FIXED_GoFEMA": 48682.64359628718
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 67679209,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1691980.225,
    "% of funds from communication to NEXUS": 16919.80225,
    "Per Month": 1409.9835208333334,
    "YEAR_1": 12763.846212689814,
    "YEAR_2": 17166.450667299378,
    "YEAR_3": 17335.581010590377,
    "YEAR_4": 17504.711353881376,
    "YEAR_5": 17673.841697172375,
    "YEAR_6": 17842.972040463374,
    "YEAR_7": 18012.102383754376,
    "YEAR_8": 18181.232727045375,
    "YEAR_9": 18350.363070336378,
    "YEAR_10": 18519.493413627373,
    "FIXED_GoFEMA": 173350.5945768602
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 43878070.25,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1096951.75625,
    "% of funds from communication to NEXUS": 10969.517562500001,
    "Per Month": 914.1264635416668,
    "YEAR_1": 8275.110614555204,
    "YEAR_2": 11129.425704767344,
    "YEAR_3": 11239.077002322096,
    "YEAR_4": 11348.728299876846,
    "YEAR_5": 11458.379597431596,
    "YEAR_6": 11568.030894986345,
    "YEAR_7": 11677.682192541097,
    "YEAR_8": 11787.333490095847,
    "YEAR_9": 11896.984787650594,
    "YEAR_10": 12006.636085205346,
    "FIXED_GoFEMA": 112387.38866943232
  },
  {
    "DMA": "Reno",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 75841875,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1896046.875,
    "% of funds from communication to NEXUS": 18960.46875,
    "Per Month": 1580.0390625,
    "YEAR_1": 14303.270432460937,
    "YEAR_2": 19236.864983203122,
    "YEAR_3": 19426.39382882812,
    "YEAR_4": 19615.922674453126,
    "YEAR_5": 19805.451520078124,
    "YEAR_6": 19994.980365703126,
    "YEAR_7": 20184.509211328128,
    "YEAR_8": 20374.038056953126,
    "YEAR_9": 20563.566902578124,
    "YEAR_10": 20753.095748203123,
    "FIXED_GoFEMA": 194258.09372378903
  },
  {
    "DMA": "Washington",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 138380970.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3459524.2575000003,
    "% of funds from communication to NEXUS": 34595.242575000004,
    "Per Month": 2882.93688125,
    "YEAR_1": 26097.72557584112,
    "YEAR_2": 35099.55472363707,
    "YEAR_3": 35445.36876841676,
    "YEAR_4": 35791.182813196465,
    "YEAR_5": 36136.996857976155,
    "YEAR_6": 36482.81090275587,
    "YEAR_7": 36828.624947535565,
    "YEAR_8": 37174.43899231527,
    "YEAR_9": 37520.25303709497,
    "YEAR_10": 37866.067081874666,
    "FIXED_GoFEMA": 354443.02370064385
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 99775528.33,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 2494388.20825,
    "% of funds from communication to NEXUS": 24943.8820825,
    "Per Month": 2078.6568402083335,
    "YEAR_1": 18816.997394192294,
    "YEAR_2": 25307.501523557647,
    "YEAR_3": 25556.840568854317,
    "YEAR_4": 25806.179614150988,
    "YEAR_5": 26055.518659447658,
    "YEAR_6": 26304.857704744325,
    "YEAR_7": 26554.196750040996,
    "YEAR_8": 26803.535795337666,
    "YEAR_9": 27052.874840634337,
    "YEAR_10": 27302.213885931007,
    "FIXED_GoFEMA": 255560.7167368912
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 79263093,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1981577.3250000002,
    "% of funds from communication to NEXUS": 19815.773250000002,
    "Per Month": 1651.3144375000002,
    "YEAR_1": 14948.489267865563,
    "YEAR_2": 20104.637684551875,
    "YEAR_3": 20302.716153958874,
    "YEAR_4": 20500.794623365873,
    "YEAR_5": 20698.873092772876,
    "YEAR_6": 20896.95156217988,
    "YEAR_7": 21095.030031586877,
    "YEAR_8": 21293.10850099388,
    "YEAR_9": 21491.18697040088,
    "YEAR_10": 21689.265439807878,
    "FIXED_GoFEMA": 203021.05332748443
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 54920403.67,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1373010.0917500001,
    "% of funds from communication to NEXUS": 13730.100917500002,
    "Per Month": 1144.1750764583335,
    "YEAR_1": 10357.620851962456,
    "YEAR_2": 13930.251463624856,
    "YEAR_3": 14067.497552396188,
    "YEAR_4": 14204.743641167519,
    "YEAR_5": 14341.989729938847,
    "YEAR_6": 14479.235818710178,
    "YEAR_7": 14616.481907481508,
    "YEAR_8": 14753.72799625284,
    "YEAR_9": 14890.974085024169,
    "YEAR_10": 15028.220173795498,
    "FIXED_GoFEMA": 140670.74322035405
  },
  {
    "DMA": "Evansville",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 9503292.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 237582.3125,
    "% of funds from communication to NEXUS": 2375.823125,
    "Per Month": 197.98526041666665,
    "YEAR_1": 1792.257412231406,
    "YEAR_2": 2410.4566866046875,
    "YEAR_3": 2434.205414562188,
    "YEAR_4": 2457.9541425196876,
    "YEAR_5": 2481.7028704771874,
    "YEAR_6": 2505.451598434687,
    "YEAR_7": 2529.200326392187,
    "YEAR_8": 2552.949054349687,
    "YEAR_9": 2576.6977823071875,
    "YEAR_10": 2600.4465102646873,
    "FIXED_GoFEMA": 24341.32179814359
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 9503292.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 237582.3125,
    "% of funds from communication to NEXUS": 2375.823125,
    "Per Month": 197.98526041666665,
    "YEAR_1": 1792.257412231406,
    "YEAR_2": 2410.4566866046875,
    "YEAR_3": 2434.205414562188,
    "YEAR_4": 2457.9541425196876,
    "YEAR_5": 2481.7028704771874,
    "YEAR_6": 2505.451598434687,
    "YEAR_7": 2529.200326392187,
    "YEAR_8": 2552.949054349687,
    "YEAR_9": 2576.6977823071875,
    "YEAR_10": 2600.4465102646873,
    "FIXED_GoFEMA": 24341.32179814359
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 170219158,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4255478.95,
    "% of funds from communication to NEXUS": 42554.789500000006,
    "Per Month": 3546.232458333334,
    "YEAR_1": 32102.19485818088,
    "YEAR_2": 43175.131943936256,
    "YEAR_3": 43600.509619778255,
    "YEAR_4": 44025.887295620254,
    "YEAR_5": 44451.26497146226,
    "YEAR_6": 44876.64264730426,
    "YEAR_7": 45302.02032314625,
    "YEAR_8": 45727.39799898826,
    "YEAR_9": 46152.775674830256,
    "YEAR_10": 46578.15335067226,
    "FIXED_GoFEMA": 435991.9786839192
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 26637086.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 665927.1625000001,
    "% of funds from communication to NEXUS": 6659.271625000001,
    "Per Month": 554.9393020833335,
    "YEAR_1": 5023.576378384031,
    "YEAR_2": 6756.347157113439,
    "YEAR_3": 6822.91323627694,
    "YEAR_4": 6889.479315440441,
    "YEAR_5": 6956.04539460394,
    "YEAR_6": 7022.611473767438,
    "YEAR_7": 7089.177552930938,
    "YEAR_8": 7155.743632094439,
    "YEAR_9": 7222.30971125794,
    "YEAR_10": 7288.875790421441,
    "FIXED_GoFEMA": 68227.07964229099
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 676165047.7,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 16904126.192500003,
    "% of funds from communication to NEXUS": 169041.26192500003,
    "Per Month": 14086.771827083336,
    "YEAR_1": 127520.20614246352,
    "YEAR_2": 171505.4609207117,
    "YEAR_3": 173195.19737491402,
    "YEAR_4": 174884.9338291163,
    "YEAR_5": 176574.6702833186,
    "YEAR_6": 178264.40673752094,
    "YEAR_7": 179954.1431917232,
    "YEAR_8": 181643.8796459255,
    "YEAR_9": 183333.61610012778,
    "YEAR_10": 185023.35255433014,
    "FIXED_GoFEMA": 1731899.8667801516
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 8209990.667,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 205249.76667500002,
    "% of funds from communication to NEXUS": 2052.4976667500005,
    "Per Month": 171.04147222916671,
    "YEAR_1": 1548.3493354836148,
    "YEAR_2": 2082.417951487049,
    "YEAR_3": 2102.934718163882,
    "YEAR_4": 2123.451484840715,
    "YEAR_5": 2143.9682515175477,
    "YEAR_6": 2164.4850181943807,
    "YEAR_7": 2185.0017848712137,
    "YEAR_8": 2205.5185515480466,
    "YEAR_9": 2226.0353182248796,
    "YEAR_10": 2246.552084901713,
    "FIXED_GoFEMA": 21028.714499233043
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 26607184.75,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 665179.61875,
    "% of funds from communication to NEXUS": 6651.7961875,
    "Per Month": 554.3163489583334,
    "YEAR_1": 5017.937108301984,
    "YEAR_2": 6748.762746423282,
    "YEAR_3": 6815.254101113532,
    "YEAR_4": 6881.7454558037825,
    "YEAR_5": 6948.236810494032,
    "YEAR_6": 7014.728165184281,
    "YEAR_7": 7081.219519874532,
    "YEAR_8": 7147.710874564781,
    "YEAR_9": 7214.202229255032,
    "YEAR_10": 7280.693583945282,
    "FIXED_GoFEMA": 68150.49059496052
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 631971128.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 15799278.2125,
    "% of funds from communication to NEXUS": 157992.782125,
    "Per Month": 13166.065177083334,
    "YEAR_1": 119185.52852817814,
    "YEAR_2": 160295.92190642722,
    "YEAR_3": 161875.21775654866,
    "YEAR_4": 163454.51360667017,
    "YEAR_5": 165033.80945679167,
    "YEAR_6": 166613.1053069132,
    "YEAR_7": 168192.4011570347,
    "YEAR_8": 169771.6970071562,
    "YEAR_9": 171350.99285727768,
    "YEAR_10": 172930.28870739916,
    "FIXED_GoFEMA": 1618703.4762903969
  },
  {
    "DMA": "Lansing",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 37216992,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 930424.8,
    "% of funds from communication to NEXUS": 9304.248000000001,
    "Per Month": 775.3540000000002,
    "YEAR_1": 7018.875802566001,
    "YEAR_2": 9439.880675220003,
    "YEAR_3": 9532.885938228,
    "YEAR_4": 9625.891201236002,
    "YEAR_5": 9718.896464244002,
    "YEAR_6": 9811.901727252001,
    "YEAR_7": 9904.906990260002,
    "YEAR_8": 9997.912253268001,
    "YEAR_9": 10090.917516276002,
    "YEAR_10": 10183.922779284003,
    "FIXED_GoFEMA": 95325.99134783403
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 38191766,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 954794.15,
    "% of funds from communication to NEXUS": 9547.9415,
    "Per Month": 795.6617916666668,
    "YEAR_1": 7202.711660164876,
    "YEAR_2": 9687.126617216252,
    "YEAR_3": 9782.56784045025,
    "YEAR_4": 9878.00906368425,
    "YEAR_5": 9973.45028691825,
    "YEAR_6": 10068.89151015225,
    "YEAR_7": 10164.33273338625,
    "YEAR_8": 10259.77395662025,
    "YEAR_9": 10355.21517985425,
    "YEAR_10": 10450.656403088251,
    "FIXED_GoFEMA": 97822.73525153514
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 377331000.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 9433275.012500001,
    "% of funds from communication to NEXUS": 94332.750125,
    "Per Month": 7861.0625104166675,
    "YEAR_1": 71162.10329323416,
    "YEAR_2": 95707.8857899472,
    "YEAR_3": 96650.8359601967,
    "YEAR_4": 97593.7861304462,
    "YEAR_5": 98536.7363006957,
    "YEAR_6": 99479.68647094519,
    "YEAR_7": 100422.6366411947,
    "YEAR_8": 101365.5868114442,
    "YEAR_9": 102308.5369816937,
    "YEAR_10": 103251.4871519432,
    "FIXED_GoFEMA": 966479.2815317409
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 74433984.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1860849.6125,
    "% of funds from communication to NEXUS": 18608.496125,
    "Per Month": 1550.7080104166669,
    "YEAR_1": 14037.751699428658,
    "YEAR_2": 18879.76147726219,
    "YEAR_3": 19065.77200452769,
    "YEAR_4": 19251.78253179319,
    "YEAR_5": 19437.79305905869,
    "YEAR_6": 19623.80358632419,
    "YEAR_7": 19809.81411358969,
    "YEAR_8": 19995.82464085519,
    "YEAR_9": 20181.835168120688,
    "YEAR_10": 20367.845695386193,
    "FIXED_GoFEMA": 190651.98397634635
  },
  {
    "DMA": "Eugene",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 170990338,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4274758.45,
    "% of funds from communication to NEXUS": 42747.584500000004,
    "Per Month": 3562.2987083333337,
    "YEAR_1": 32247.634248914634,
    "YEAR_2": 43370.73741304876,
    "YEAR_3": 43798.042267710756,
    "YEAR_4": 44225.34712237276,
    "YEAR_5": 44652.65197703475,
    "YEAR_6": 45079.956831696756,
    "YEAR_7": 45507.26168635876,
    "YEAR_8": 45934.56654102076,
    "YEAR_9": 46361.871395682756,
    "YEAR_10": 46789.17625034475,
    "FIXED_GoFEMA": 437967.24573418545
  },
  {
    "DMA": "Macon",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 12956379,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 323909.47500000003,
    "% of funds from communication to NEXUS": 3239.09475,
    "Per Month": 269.92456250000004,
    "YEAR_1": 2443.486433615438,
    "YEAR_2": 3286.3126537181256,
    "YEAR_3": 3318.6906448391255,
    "YEAR_4": 3351.0686359601254,
    "YEAR_5": 3383.4466270811254,
    "YEAR_6": 3415.8246182021253,
    "YEAR_7": 3448.202609323125,
    "YEAR_8": 3480.5806004441256,
    "YEAR_9": 3512.958591565125,
    "YEAR_10": 3545.3365826861254,
    "FIXED_GoFEMA": 33185.907997434566
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 24213330.33,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 605333.25825,
    "% of funds from communication to NEXUS": 6053.332582499999,
    "Per Month": 504.4443818749999,
    "YEAR_1": 4566.472173591418,
    "YEAR_2": 6141.575038221393,
    "YEAR_3": 6202.084150716064,
    "YEAR_4": 6262.593263210732,
    "YEAR_5": 6323.102375705404,
    "YEAR_6": 6383.611488200073,
    "YEAR_7": 6444.120600694743,
    "YEAR_8": 6504.6297131894125,
    "YEAR_9": 6565.138825684083,
    "YEAR_10": 6625.647938178754,
    "FIXED_GoFEMA": 62018.97556739207
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 125249684.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3131242.1125000003,
    "% of funds from communication to NEXUS": 31312.421125000004,
    "Per Month": 2609.3684270833337,
    "YEAR_1": 23621.25288943491,
    "YEAR_2": 31768.87794394969,
    "YEAR_3": 32081.876905515193,
    "YEAR_4": 32394.875867080693,
    "YEAR_5": 32707.874828646185,
    "YEAR_6": 33020.87379021169,
    "YEAR_7": 33333.8727517772,
    "YEAR_8": 33646.87171334269,
    "YEAR_9": 33959.8706749082,
    "YEAR_10": 34272.8696364737,
    "FIXED_GoFEMA": 320809.1170013402
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 127456853,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3186421.325,
    "% of funds from communication to NEXUS": 31864.213250000004,
    "Per Month": 2655.351104166667,
    "YEAR_1": 24037.510108095565,
    "YEAR_2": 32328.71381865188,
    "YEAR_3": 32647.22849429888,
    "YEAR_4": 32965.743169945876,
    "YEAR_5": 33284.25784559288,
    "YEAR_6": 33602.77252123988,
    "YEAR_7": 33921.28719688688,
    "YEAR_8": 34239.80187253388,
    "YEAR_9": 34558.31654818088,
    "YEAR_10": 34876.83122382788,
    "FIXED_GoFEMA": 326462.4627992545
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 382370558.3,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 9559263.957500001,
    "% of funds from communication to NEXUS": 95592.63957500002,
    "Per Month": 7966.053297916668,
    "YEAR_1": 72112.53019227138,
    "YEAR_2": 96986.14127840457,
    "YEAR_3": 97941.68530359628,
    "YEAR_4": 98897.22932878797,
    "YEAR_5": 99852.77335397968,
    "YEAR_6": 100808.31737917138,
    "YEAR_7": 101763.86140436307,
    "YEAR_8": 102719.40542955478,
    "YEAR_9": 103674.94945474649,
    "YEAR_10": 104630.49347993819,
    "FIXED_GoFEMA": 979387.3866048139
  },
  {
    "DMA": "Lafayette",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 515669245,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 12891731.125,
    "% of funds from communication to NEXUS": 128917.31125,
    "Per Month": 10743.109270833333,
    "YEAR_1": 97251.77106892405,
    "YEAR_2": 130796.60335474685,
    "YEAR_3": 132085.26079800187,
    "YEAR_4": 133373.91824125688,
    "YEAR_5": 134662.57568451186,
    "YEAR_6": 135951.23312776687,
    "YEAR_7": 137239.89057102188,
    "YEAR_8": 138528.54801427686,
    "YEAR_9": 139817.20545753185,
    "YEAR_10": 141105.86290078686,
    "FIXED_GoFEMA": 1320812.8692188258
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 25063044,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 626576.1,
    "% of funds from communication to NEXUS": 6265.7609999999995,
    "Per Month": 522.14675,
    "YEAR_1": 4726.72248929325,
    "YEAR_2": 6357.1001309775,
    "YEAR_3": 6419.732677933501,
    "YEAR_4": 6482.3652248895,
    "YEAR_5": 6544.997771845501,
    "YEAR_6": 6607.630318801501,
    "YEAR_7": 6670.262865757499,
    "YEAR_8": 6732.895412713501,
    "YEAR_9": 6795.5279596695,
    "YEAR_10": 6858.160506625499,
    "FIXED_GoFEMA": 64195.39535850675
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 254913705.7,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 6372842.6425,
    "% of funds from communication to NEXUS": 63728.426425000005,
    "Per Month": 5310.702202083334,
    "YEAR_1": 48075.02015961314,
    "YEAR_2": 64657.42756121044,
    "YEAR_3": 65294.45691175475,
    "YEAR_4": 65931.48626229903,
    "YEAR_5": 66568.51561284336,
    "YEAR_6": 67205.54496338764,
    "YEAR_7": 67842.57431393194,
    "YEAR_8": 68479.60366447625,
    "YEAR_9": 69116.63301502053,
    "YEAR_10": 69753.66236556484,
    "FIXED_GoFEMA": 652924.9248301019
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 158552275,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3963806.875,
    "% of funds from communication to NEXUS": 39638.06875,
    "Per Month": 3303.172395833333,
    "YEAR_1": 29901.898746660932,
    "YEAR_2": 40215.892697203126,
    "YEAR_3": 40612.114832428124,
    "YEAR_4": 41008.33696765313,
    "YEAR_5": 41404.55910287811,
    "YEAR_6": 41800.781238103125,
    "YEAR_7": 42197.003373328116,
    "YEAR_8": 42593.22550855313,
    "YEAR_9": 42989.44764377812,
    "YEAR_10": 43385.66977900313,
    "FIXED_GoFEMA": 406108.9298895891
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 46126990,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1153174.75,
    "% of funds from communication to NEXUS": 11531.7475,
    "Per Month": 960.9789583333333,
    "YEAR_1": 8699.241839754375,
    "YEAR_2": 11699.851549181249,
    "YEAR_3": 11815.12289719125,
    "YEAR_4": 11930.394245201249,
    "YEAR_5": 12045.66559321125,
    "YEAR_6": 12160.936941221249,
    "YEAR_7": 12276.208289231248,
    "YEAR_8": 12391.479637241251,
    "YEAR_9": 12506.75098525125,
    "YEAR_10": 12622.02233326125,
    "FIXED_GoFEMA": 118147.67431074561
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 169041262,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4226031.55,
    "% of funds from communication to NEXUS": 42260.3155,
    "Per Month": 3521.692958333333,
    "YEAR_1": 31880.051549760377,
    "YEAR_2": 42876.36524920124,
    "YEAR_3": 43298.799362939244,
    "YEAR_4": 43721.233476677255,
    "YEAR_5": 44143.66759041524,
    "YEAR_6": 44566.10170415325,
    "YEAR_7": 44988.53581789124,
    "YEAR_8": 45410.96993162924,
    "YEAR_9": 45833.40404536725,
    "YEAR_10": 46255.838159105246,
    "FIXED_GoFEMA": 432974.9668871396
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 169041262,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4226031.55,
    "% of funds from communication to NEXUS": 42260.3155,
    "Per Month": 3521.692958333333,
    "YEAR_1": 31880.051549760377,
    "YEAR_2": 42876.36524920124,
    "YEAR_3": 43298.799362939244,
    "YEAR_4": 43721.233476677255,
    "YEAR_5": 44143.66759041524,
    "YEAR_6": 44566.10170415325,
    "YEAR_7": 44988.53581789124,
    "YEAR_8": 45410.96993162924,
    "YEAR_9": 45833.40404536725,
    "YEAR_10": 46255.838159105246,
    "FIXED_GoFEMA": 432974.9668871396
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 158552275,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3963806.875,
    "% of funds from communication to NEXUS": 39638.06875,
    "Per Month": 3303.172395833333,
    "YEAR_1": 29901.898746660932,
    "YEAR_2": 40215.892697203126,
    "YEAR_3": 40612.114832428124,
    "YEAR_4": 41008.33696765313,
    "YEAR_5": 41404.55910287811,
    "YEAR_6": 41800.781238103125,
    "YEAR_7": 42197.003373328116,
    "YEAR_8": 42593.22550855313,
    "YEAR_9": 42989.44764377812,
    "YEAR_10": 43385.66977900313,
    "FIXED_GoFEMA": 406108.9298895891
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 254913705.7,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 6372842.6425,
    "% of funds from communication to NEXUS": 63728.426425000005,
    "Per Month": 5310.702202083334,
    "YEAR_1": 48075.02015961314,
    "YEAR_2": 64657.42756121044,
    "YEAR_3": 65294.45691175475,
    "YEAR_4": 65931.48626229903,
    "YEAR_5": 66568.51561284336,
    "YEAR_6": 67205.54496338764,
    "YEAR_7": 67842.57431393194,
    "YEAR_8": 68479.60366447625,
    "YEAR_9": 69116.63301502053,
    "YEAR_10": 69753.66236556484,
    "FIXED_GoFEMA": 652924.9248301019
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 52539006.25,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1313475.15625,
    "% of funds from communication to NEXUS": 13134.7515625,
    "Per Month": 1094.5626302083333,
    "YEAR_1": 9908.505224145703,
    "YEAR_2": 13326.223403402342,
    "YEAR_3": 13457.518380021093,
    "YEAR_4": 13588.813356639843,
    "YEAR_5": 13720.108333258595,
    "YEAR_6": 13851.403309877343,
    "YEAR_7": 13982.698286496094,
    "YEAR_8": 14113.993263114842,
    "YEAR_9": 14245.288239733592,
    "YEAR_10": 14376.583216352343,
    "FIXED_GoFEMA": 134571.13501304178
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 289226811,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 7230670.275,
    "% of funds from communication to NEXUS": 72306.70275000001,
    "Per Month": 6025.558562500001,
    "YEAR_1": 54546.24235030145,
    "YEAR_2": 73360.75370933814,
    "YEAR_3": 74083.53151002714,
    "YEAR_4": 74806.30931071614,
    "YEAR_5": 75529.08711140514,
    "YEAR_6": 76251.86491209414,
    "YEAR_7": 76974.64271278314,
    "YEAR_8": 77697.42051347214,
    "YEAR_9": 78420.19831416114,
    "YEAR_10": 79142.97611485014,
    "FIXED_GoFEMA": 740813.0265591487
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 341980676.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 8549516.9125,
    "% of funds from communication to NEXUS": 85495.169125,
    "Per Month": 7124.597427083333,
    "YEAR_1": 64495.268592125896,
    "YEAR_2": 86741.47495291969,
    "YEAR_3": 87596.0846634932,
    "YEAR_4": 88450.69437406668,
    "YEAR_5": 89305.30408464019,
    "YEAR_6": 90159.91379521368,
    "YEAR_7": 91014.52350578718,
    "YEAR_8": 91869.1332163607,
    "YEAR_9": 92723.74292693418,
    "YEAR_10": 93578.35263750768,
    "FIXED_GoFEMA": 875934.492749049
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 43680310,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1092007.75,
    "% of funds from communication to NEXUS": 10920.0775,
    "Per Month": 910.0064583333333,
    "YEAR_1": 8237.814353926875,
    "YEAR_2": 11079.264929756251,
    "YEAR_3": 11188.42202444625,
    "YEAR_4": 11297.57911913625,
    "YEAR_5": 11406.736213826249,
    "YEAR_6": 11515.89330851625,
    "YEAR_7": 11625.05040320625,
    "YEAR_8": 11734.20749789625,
    "YEAR_9": 11843.36459258625,
    "YEAR_10": 11952.521687276248,
    "FIXED_GoFEMA": 111880.85413057312
  },
  {
    "DMA": "Rockford",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 62624842,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1565621.05,
    "% of funds from communication to NEXUS": 15656.210500000001,
    "Per Month": 1304.6842083333333,
    "YEAR_1": 11810.626397569125,
    "YEAR_2": 15884.43890856375,
    "YEAR_3": 16040.938388721752,
    "YEAR_4": 16197.43786887975,
    "YEAR_5": 16353.937349037747,
    "YEAR_6": 16510.43682919575,
    "YEAR_7": 16666.93630935375,
    "YEAR_8": 16823.43578951175,
    "YEAR_9": 16979.93526966975,
    "YEAR_10": 17136.434749827746,
    "FIXED_GoFEMA": 160404.55786033088
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 54456728,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1361418.2000000002,
    "% of funds from communication to NEXUS": 13614.182000000003,
    "Per Month": 1134.5151666666668,
    "YEAR_1": 10270.174721431502,
    "YEAR_2": 13812.642738105003,
    "YEAR_3": 13948.730101377001,
    "YEAR_4": 14084.817464649,
    "YEAR_5": 14220.904827921,
    "YEAR_6": 14356.992191193003,
    "YEAR_7": 14493.079554465001,
    "YEAR_8": 14629.166917737002,
    "YEAR_9": 14765.254281009005,
    "YEAR_10": 14901.341644281001,
    "FIXED_GoFEMA": 139483.10444216852
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 355334059,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 8883351.475,
    "% of funds from communication to NEXUS": 88833.51475,
    "Per Month": 7402.792895833333,
    "YEAR_1": 67013.62723088043,
    "YEAR_2": 90128.48531126813,
    "YEAR_3": 91016.46512470911,
    "YEAR_4": 91904.44493815012,
    "YEAR_5": 92792.42475159113,
    "YEAR_6": 93680.40456503213,
    "YEAR_7": 94568.38437847313,
    "YEAR_8": 95456.36419191414,
    "YEAR_9": 96344.34400535513,
    "YEAR_10": 97232.32381879611,
    "FIXED_GoFEMA": 910137.2683161695
  },
  {
    "DMA": "Topeka",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 42940084,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1073502.1,
    "% of funds from communication to NEXUS": 10735.021,
    "Per Month": 894.5850833333334,
    "YEAR_1": 8098.21268058825,
    "YEAR_2": 10891.510768627502,
    "YEAR_3": 10998.8180385435,
    "YEAR_4": 11106.125308459501,
    "YEAR_5": 11213.4325783755,
    "YEAR_6": 11320.739848291501,
    "YEAR_7": 11428.0471182075,
    "YEAR_8": 11535.354388123502,
    "YEAR_9": 11642.6616580395,
    "YEAR_10": 11749.9689279555,
    "FIXED_GoFEMA": 109984.87131521176
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 169041262,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 4226031.55,
    "% of funds from communication to NEXUS": 42260.3155,
    "Per Month": 3521.692958333333,
    "YEAR_1": 31880.051549760377,
    "YEAR_2": 42876.36524920124,
    "YEAR_3": 43298.799362939244,
    "YEAR_4": 43721.233476677255,
    "YEAR_5": 44143.66759041524,
    "YEAR_6": 44566.10170415325,
    "YEAR_7": 44988.53581789124,
    "YEAR_8": 45410.96993162924,
    "YEAR_9": 45833.40404536725,
    "YEAR_10": 46255.838159105246,
    "FIXED_GoFEMA": 432974.9668871396
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 338082524,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 8452063.1,
    "% of funds from communication to NEXUS": 84520.631,
    "Per Month": 7043.385916666666,
    "YEAR_1": 63760.103099520755,
    "YEAR_2": 85752.73049840248,
    "YEAR_3": 86597.59872587849,
    "YEAR_4": 87442.46695335451,
    "YEAR_5": 88287.33518083049,
    "YEAR_6": 89132.2034083065,
    "YEAR_7": 89977.07163578249,
    "YEAR_8": 90821.93986325848,
    "YEAR_9": 91666.8080907345,
    "YEAR_10": 92511.67631821049,
    "FIXED_GoFEMA": 865949.9337742792
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 127456853,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 3186421.325,
    "% of funds from communication to NEXUS": 31864.213250000004,
    "Per Month": 2655.351104166667,
    "YEAR_1": 24037.510108095565,
    "YEAR_2": 32328.71381865188,
    "YEAR_3": 32647.22849429888,
    "YEAR_4": 32965.743169945876,
    "YEAR_5": 33284.25784559288,
    "YEAR_6": 33602.77252123988,
    "YEAR_7": 33921.28719688688,
    "YEAR_8": 34239.80187253388,
    "YEAR_9": 34558.31654818088,
    "YEAR_10": 34876.83122382788,
    "FIXED_GoFEMA": 326462.4627992545
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 53214369.25,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1330359.2312500002,
    "% of funds from communication to NEXUS": 13303.592312500003,
    "Per Month": 1108.6326927083335,
    "YEAR_1": 10035.874169455641,
    "YEAR_2": 13497.525429435469,
    "YEAR_3": 13630.508138191219,
    "YEAR_4": 13763.490846946974,
    "YEAR_5": 13896.473555702723,
    "YEAR_6": 14029.456264458473,
    "YEAR_7": 14162.438973214223,
    "YEAR_8": 14295.421681969972,
    "YEAR_9": 14428.404390725722,
    "YEAR_10": 14561.387099481472,
    "FIXED_GoFEMA": 136300.9805495819
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 56597184.67,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1414929.61675,
    "% of funds from communication to NEXUS": 14149.2961675,
    "Per Month": 1179.1080139583335,
    "YEAR_1": 10673.85053508952,
    "YEAR_2": 14355.557532381736,
    "YEAR_3": 14496.993896872063,
    "YEAR_4": 14638.430261362393,
    "YEAR_5": 14779.866625852721,
    "YEAR_6": 14921.302990343054,
    "YEAR_7": 15062.739354833384,
    "YEAR_8": 15204.175719323712,
    "YEAR_9": 15345.612083814041,
    "YEAR_10": 15487.048448304373,
    "FIXED_GoFEMA": 144965.577448177
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 338082524,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 8452063.1,
    "% of funds from communication to NEXUS": 84520.631,
    "Per Month": 7043.385916666666,
    "YEAR_1": 63760.103099520755,
    "YEAR_2": 85752.73049840248,
    "YEAR_3": 86597.59872587849,
    "YEAR_4": 87442.46695335451,
    "YEAR_5": 88287.33518083049,
    "YEAR_6": 89132.2034083065,
    "YEAR_7": 89977.07163578249,
    "YEAR_8": 90821.93986325848,
    "YEAR_9": 91666.8080907345,
    "YEAR_10": 92511.67631821049,
    "FIXED_GoFEMA": 865949.9337742792
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 31682396.33,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 792059.90825,
    "% of funds from communication to NEXUS": 7920.5990825,
    "Per Month": 660.0499235416667,
    "YEAR_1": 5975.088071812543,
    "YEAR_2": 8036.0616156251435,
    "YEAR_3": 8115.235924053813,
    "YEAR_4": 8194.410232482483,
    "YEAR_5": 8273.584540911153,
    "YEAR_6": 8352.758849339823,
    "YEAR_7": 8431.933157768492,
    "YEAR_8": 8511.107466197163,
    "YEAR_9": 8590.281774625833,
    "YEAR_10": 8669.456083054503,
    "FIXED_GoFEMA": 81149.91771587096
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 24413608.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 610340.2125,
    "% of funds from communication to NEXUS": 6103.4021250000005,
    "Per Month": 508.61684375000004,
    "YEAR_1": 4604.243297093157,
    "YEAR_2": 6192.374469477188,
    "YEAR_3": 6253.384077118689,
    "YEAR_4": 6314.393684760187,
    "YEAR_5": 6375.403292401687,
    "YEAR_6": 6436.412900043188,
    "YEAR_7": 6497.422507684687,
    "YEAR_8": 6558.432115326188,
    "YEAR_9": 6619.441722967687,
    "YEAR_10": 6680.4513306091885,
    "FIXED_GoFEMA": 62531.95939748185
  },
  {
    "DMA": "Erie",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 56739719.5,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1418492.9875,
    "% of funds from communication to NEXUS": 14184.929875000002,
    "Per Month": 1182.0774895833335,
    "YEAR_1": 10700.731650825845,
    "YEAR_2": 14391.710690252814,
    "YEAR_3": 14533.503249283314,
    "YEAR_4": 14675.295808313816,
    "YEAR_5": 14817.088367344313,
    "YEAR_6": 14958.880926374813,
    "YEAR_7": 15100.673485405316,
    "YEAR_8": 15242.466044435816,
    "YEAR_9": 15384.258603466315,
    "YEAR_10": 15526.051162496815,
    "FIXED_GoFEMA": 145330.6599881992
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 72338436,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1808460.9000000001,
    "% of funds from communication to NEXUS": 18084.609,
    "Per Month": 1507.05075,
    "YEAR_1": 13642.545266309253,
    "YEAR_2": 18348.237387697503,
    "YEAR_3": 18529.0111392615,
    "YEAR_4": 18709.7848908255,
    "YEAR_5": 18890.5586423895,
    "YEAR_6": 19071.332393953504,
    "YEAR_7": 19252.1061455175,
    "YEAR_8": 19432.879897081504,
    "YEAR_9": 19613.653648645504,
    "YEAR_10": 19794.427400209497,
    "FIXED_GoFEMA": 185284.53681189075
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 50466877.33,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1261671.93325,
    "% of funds from communication to NEXUS": 12616.7193325,
    "Per Month": 1051.3932777083335,
    "YEAR_1": 9517.715567195859,
    "YEAR_2": 12800.639558569523,
    "YEAR_3": 12926.75628501719,
    "YEAR_4": 13052.873011464862,
    "YEAR_5": 13178.98973791253,
    "YEAR_6": 13305.1064643602,
    "YEAR_7": 13431.223190807872,
    "YEAR_8": 13557.33991725554,
    "YEAR_9": 13683.45664370321,
    "YEAR_10": 13809.573370150882,
    "FIXED_GoFEMA": 129263.67374643766
  },
  {
    "DMA": "Panama City",
    "PlatformName": "GoFEMA",
    "DMA Annual City Budget": 69545809,
    "% of funds used for communication": 0.025,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Communication from FEMA": 1738645.225,
    "% of funds from communication to NEXUS": 17386.452250000002,
    "Per Month": 1448.8710208333334,
    "YEAR_1": 13115.874489802314,
    "YEAR_2": 17639.903257674378,
    "YEAR_3": 17813.698234365376,
    "YEAR_4": 17987.49321105638,
    "YEAR_5": 18161.288187747377,
    "YEAR_6": 18335.083164438376,
    "YEAR_7": 18508.878141129375,
    "YEAR_8": 18682.673117820377,
    "YEAR_9": 18856.468094511376,
    "YEAR_10": 19030.263071202375,
    "FIXED_GoFEMA": 178131.6229697477
  },
   {
    "DMA": "New York",
    "PlatformName": "AmplyFund",
    "No of Households": 7726580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 3476961,
    "HH's Considered": 3476.9610000000002,
    "contribution per HH": 17384.805,
    "Assuming Revenue": 1738.4805000000001,
    "Revenue Per month": 52154.415,
    "YEAR_1": 339525.24165000004,
    "YEAR_2": 735377.2515,
    "YEAR_3": 810479.6091,
    "YEAR_4": 885581.9667000001,
    "YEAR_5": 960684.3243,
    "YEAR_6": 1035786.6819,
    "YEAR_7": 1110889.0395,
    "YEAR_8": 1185991.3971,
    "YEAR_9": 1261093.7547,
    "YEAR_10": 1336196.1123000002,
    "FIXED_AmplyFund": 9661605.37875
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "AmplyFund",
    "No of Households": 5838090,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 2627140.5,
    "HH's Considered": 2627.1405,
    "contribution per HH": 13135.7025,
    "Assuming Revenue": 1313.57025,
    "Revenue Per month": 39407.1075,
    "YEAR_1": 256540.26982499997,
    "YEAR_2": 555640.21575,
    "YEAR_3": 612386.45055,
    "YEAR_4": 669132.6853499999,
    "YEAR_5": 725878.92015,
    "YEAR_6": 782625.15495,
    "YEAR_7": 839371.3897500001,
    "YEAR_8": 896117.6245499998,
    "YEAR_9": 952863.85935,
    "YEAR_10": 1009610.0941500001,
    "FIXED_AmplyFund": 7300166.664375001
  },
  {
    "DMA": "Chicago",
    "PlatformName": "AmplyFund",
    "No of Households": 3624820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 1631169,
    "HH's Considered": 1631.169,
    "contribution per HH": 8155.845,
    "Assuming Revenue": 815.5845,
    "Revenue Per month": 24467.535,
    "YEAR_1": 159283.65285,
    "YEAR_2": 344992.24350000004,
    "YEAR_3": 380225.49390000006,
    "YEAR_4": 415458.7443,
    "YEAR_5": 450691.9947,
    "YEAR_6": 485925.24510000006,
    "YEAR_7": 521158.4955,
    "YEAR_8": 556391.7459,
    "YEAR_9": 591624.9963,
    "YEAR_10": 626858.2467000001,
    "FIXED_AmplyFund": 4532610.858750001
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "AmplyFund",
    "No of Households": 3108960,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 1399032,
    "HH's Considered": 1399.032,
    "contribution per HH": 6995.16,
    "Assuming Revenue": 699.5160000000001,
    "Revenue Per month": 20985.480000000003,
    "YEAR_1": 136615.47480000003,
    "YEAR_2": 295895.26800000004,
    "YEAR_3": 326114.35920000006,
    "YEAR_4": 356333.45040000003,
    "YEAR_5": 386552.54160000006,
    "YEAR_6": 416771.6328000001,
    "YEAR_7": 446990.72400000005,
    "YEAR_8": 477209.81520000007,
    "YEAR_9": 507428.9064000001,
    "YEAR_10": 537647.9976,
    "FIXED_AmplyFund": 3887560.1700000004
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "AmplyFund",
    "No of Households": 3041540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 1368693,
    "HH's Considered": 1368.693,
    "contribution per HH": 6843.465,
    "Assuming Revenue": 684.3465000000001,
    "Revenue Per month": 20530.395000000004,
    "YEAR_1": 133652.87145000004,
    "YEAR_2": 289478.5695000001,
    "YEAR_3": 319042.33830000006,
    "YEAR_4": 348606.1071000001,
    "YEAR_5": 378169.87590000004,
    "YEAR_6": 407733.6447000001,
    "YEAR_7": 437297.4135,
    "YEAR_8": 466861.1823000001,
    "YEAR_9": 496424.9511000001,
    "YEAR_10": 525988.7199000001,
    "FIXED_AmplyFund": 3803255.673750001
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "AmplyFund",
    "No of Households": 2679850,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 1205932.5,
    "HH's Considered": 1205.9325000000001,
    "contribution per HH": 6029.6625,
    "Assuming Revenue": 602.9662500000001,
    "Revenue Per month": 18088.987500000003,
    "YEAR_1": 117759.30862500003,
    "YEAR_2": 255054.72375000006,
    "YEAR_3": 281102.86575000006,
    "YEAR_4": 307151.0077500001,
    "YEAR_5": 333199.1497500001,
    "YEAR_6": 359247.29175000003,
    "YEAR_7": 385295.43375,
    "YEAR_8": 411343.57575000013,
    "YEAR_9": 437391.71775000007,
    "YEAR_10": 463439.8597500001,
    "FIXED_AmplyFund": 3350984.9343750007
  },
  {
    "DMA": "Houston",
    "PlatformName": "AmplyFund",
    "No of Households": 2666330,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 1199848.5,
    "HH's Considered": 1199.8485,
    "contribution per HH": 5999.2425,
    "Assuming Revenue": 599.92425,
    "Revenue Per month": 17997.7275,
    "YEAR_1": 117165.20602499999,
    "YEAR_2": 253767.95774999997,
    "YEAR_3": 279684.68535,
    "YEAR_4": 305601.41295,
    "YEAR_5": 331518.14055,
    "YEAR_6": 357434.86815000005,
    "YEAR_7": 383351.59575000004,
    "YEAR_8": 409268.3233500001,
    "YEAR_9": 435185.05095000006,
    "YEAR_10": 461101.77855,
    "FIXED_AmplyFund": 3334079.019375
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "AmplyFund",
    "No of Households": 2617350,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 1177807.5,
    "HH's Considered": 1177.8075000000001,
    "contribution per HH": 5889.0375,
    "Assuming Revenue": 588.9037500000001,
    "Revenue Per month": 17667.112500000003,
    "YEAR_1": 115012.90237500003,
    "YEAR_2": 249106.28625000006,
    "YEAR_3": 274546.92825000006,
    "YEAR_4": 299987.5702500001,
    "YEAR_5": 325428.2122500001,
    "YEAR_6": 350868.8542500001,
    "YEAR_7": 376309.49625,
    "YEAR_8": 401750.13825000013,
    "YEAR_9": 427190.78025000007,
    "YEAR_10": 452631.4222500001,
    "FIXED_AmplyFund": 3272832.5906250007
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "AmplyFund",
    "No of Households": 2596190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 1168285.5,
    "HH's Considered": 1168.2855,
    "contribution per HH": 5841.4275,
    "Assuming Revenue": 584.14275,
    "Revenue Per month": 17524.2825,
    "YEAR_1": 114083.07907500002,
    "YEAR_2": 247092.38325,
    "YEAR_3": 272327.35005,
    "YEAR_4": 297562.31685,
    "YEAR_5": 322797.28365,
    "YEAR_6": 348032.25045000005,
    "YEAR_7": 373267.21725000005,
    "YEAR_8": 398502.18405000004,
    "YEAR_9": 423737.1508500001,
    "YEAR_10": 448972.11765000003,
    "FIXED_AmplyFund": 3246373.3331250004
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "AmplyFund",
    "No of Households": 2593210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 1166944.5,
    "HH's Considered": 1166.9445,
    "contribution per HH": 5834.7225,
    "Assuming Revenue": 583.47225,
    "Revenue Per month": 17504.1675,
    "YEAR_1": 113952.130425,
    "YEAR_2": 246808.76175,
    "YEAR_3": 272014.76295,
    "YEAR_4": 297220.76414999994,
    "YEAR_5": 322426.76535,
    "YEAR_6": 347632.76655,
    "YEAR_7": 372838.76775,
    "YEAR_8": 398044.76894999994,
    "YEAR_9": 423250.77015,
    "YEAR_10": 448456.77135,
    "FIXED_AmplyFund": 3242647.029375
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "AmplyFund",
    "No of Households": 2138870,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 962491.5,
    "HH's Considered": 962.4915,
    "contribution per HH": 4812.4574999999995,
    "Assuming Revenue": 481.24575,
    "Revenue Per month": 14437.3725,
    "YEAR_1": 93987.294975,
    "YEAR_2": 203566.95225,
    "YEAR_3": 224356.76864999998,
    "YEAR_4": 245146.58505,
    "YEAR_5": 265936.40145,
    "YEAR_6": 286726.21785,
    "YEAR_7": 307516.03425,
    "YEAR_8": 328305.85065,
    "YEAR_9": 349095.66705000005,
    "YEAR_10": 369885.48345,
    "FIXED_AmplyFund": 2674523.255625
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "AmplyFund",
    "No of Households": 2116440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 952398,
    "HH's Considered": 952.398,
    "contribution per HH": 4761.99,
    "Assuming Revenue": 476.199,
    "Revenue Per month": 14285.970000000001,
    "YEAR_1": 93001.66470000001,
    "YEAR_2": 201432.17699999997,
    "YEAR_3": 222003.9738,
    "YEAR_4": 242575.77060000005,
    "YEAR_5": 263147.5674,
    "YEAR_6": 283719.3642,
    "YEAR_7": 304291.1610000001,
    "YEAR_8": 324862.95780000003,
    "YEAR_9": 345434.7546,
    "YEAR_10": 366006.5514,
    "FIXED_AmplyFund": 2646475.9425
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "AmplyFund",
    "No of Households": 2068720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 930924,
    "HH's Considered": 930.924,
    "contribution per HH": 4654.62,
    "Assuming Revenue": 465.462,
    "Revenue Per month": 13963.86,
    "YEAR_1": 90904.72860000002,
    "YEAR_2": 196890.42599999998,
    "YEAR_3": 216998.3844,
    "YEAR_4": 237106.3428,
    "YEAR_5": 257214.30120000002,
    "YEAR_6": 277322.25960000005,
    "YEAR_7": 297430.218,
    "YEAR_8": 317538.1764,
    "YEAR_9": 337646.1348,
    "YEAR_10": 357754.09320000006,
    "FIXED_AmplyFund": 2586805.065
  },
  {
    "DMA": "Detroit",
    "PlatformName": "AmplyFund",
    "No of Households": 1937250,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 871762.5,
    "HH's Considered": 871.7625,
    "contribution per HH": 4358.8125,
    "Assuming Revenue": 435.88125,
    "Revenue Per month": 13076.4375,
    "YEAR_1": 85127.608125,
    "YEAR_2": 184377.76875000002,
    "YEAR_3": 203207.83874999994,
    "YEAR_4": 222037.90875000003,
    "YEAR_5": 240867.97874999995,
    "YEAR_6": 259698.04875000005,
    "YEAR_7": 278528.11874999997,
    "YEAR_8": 297358.18875000003,
    "YEAR_9": 316188.25875000004,
    "YEAR_10": 335018.32875,
    "FIXED_AmplyFund": 2422410.046875
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "AmplyFund",
    "No of Households": 1839480,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 827766,
    "HH's Considered": 827.766,
    "contribution per HH": 4138.83,
    "Assuming Revenue": 413.88300000000004,
    "Revenue Per month": 12416.490000000002,
    "YEAR_1": 80831.3499,
    "YEAR_2": 175072.50900000002,
    "YEAR_3": 192952.25460000001,
    "YEAR_4": 210832.0002,
    "YEAR_5": 228711.74580000003,
    "YEAR_6": 246591.4914,
    "YEAR_7": 264471.237,
    "YEAR_8": 282350.98260000005,
    "YEAR_9": 300230.7282,
    "YEAR_10": 318110.47380000004,
    "FIXED_AmplyFund": 2300154.7725
  },
  {
    "DMA": "Denver",
    "PlatformName": "AmplyFund",
    "No of Households": 1792540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 806643,
    "HH's Considered": 806.643,
    "contribution per HH": 4033.215,
    "Assuming Revenue": 403.3215,
    "Revenue Per month": 12099.645,
    "YEAR_1": 78768.68895,
    "YEAR_2": 170604.99450000003,
    "YEAR_3": 188028.4833,
    "YEAR_4": 205451.9721,
    "YEAR_5": 222875.4609,
    "YEAR_6": 240298.9497,
    "YEAR_7": 257722.43850000002,
    "YEAR_8": 275145.92730000004,
    "YEAR_9": 292569.41610000003,
    "YEAR_10": 309992.9049,
    "FIXED_AmplyFund": 2241459.23625
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "AmplyFund",
    "No of Households": 1775140,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 798813,
    "HH's Considered": 798.813,
    "contribution per HH": 3994.065,
    "Assuming Revenue": 399.40650000000005,
    "Revenue Per month": 11982.195000000002,
    "YEAR_1": 78004.08945,
    "YEAR_2": 168948.9495,
    "YEAR_3": 186203.31030000004,
    "YEAR_4": 203457.6711,
    "YEAR_5": 220712.03190000003,
    "YEAR_6": 237966.39270000003,
    "YEAR_7": 255220.75350000005,
    "YEAR_8": 272475.1143000001,
    "YEAR_9": 289729.47510000004,
    "YEAR_10": 306983.83590000006,
    "FIXED_AmplyFund": 2219701.6237500003
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "AmplyFund",
    "No of Households": 1720970,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 774436.5,
    "HH's Considered": 774.4365,
    "contribution per HH": 3872.1825,
    "Assuming Revenue": 387.21825,
    "Revenue Per month": 11616.5475,
    "YEAR_1": 75623.724225,
    "YEAR_2": 163793.31975000002,
    "YEAR_3": 180521.14815000002,
    "YEAR_4": 197248.97655000002,
    "YEAR_5": 213976.80495,
    "YEAR_6": 230704.63335000002,
    "YEAR_7": 247432.46175000002,
    "YEAR_8": 264160.29014999996,
    "YEAR_9": 280888.11855,
    "YEAR_10": 297615.94695,
    "FIXED_AmplyFund": 2151965.4243750004
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "AmplyFund",
    "No of Households": 1552420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 698589,
    "HH's Considered": 698.589,
    "contribution per HH": 3492.945,
    "Assuming Revenue": 349.2945,
    "Revenue Per month": 10478.835000000001,
    "YEAR_1": 68217.21585000001,
    "YEAR_2": 147751.57350000003,
    "YEAR_3": 162841.09590000001,
    "YEAR_4": 177930.6183,
    "YEAR_5": 193020.14070000002,
    "YEAR_6": 208109.6631,
    "YEAR_7": 223199.18550000002,
    "YEAR_8": 238288.70789999998,
    "YEAR_9": 253378.23030000005,
    "YEAR_10": 268467.7527,
    "FIXED_AmplyFund": 1941204.1837500003
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "AmplyFund",
    "No of Households": 1502080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 675936,
    "HH's Considered": 675.936,
    "contribution per HH": 3379.6800000000003,
    "Assuming Revenue": 337.9680000000001,
    "Revenue Per month": 10139.040000000003,
    "YEAR_1": 66005.15040000001,
    "YEAR_2": 142960.46400000004,
    "YEAR_3": 157560.68160000004,
    "YEAR_4": 172160.89920000007,
    "YEAR_5": 186761.11680000002,
    "YEAR_6": 201361.33440000005,
    "YEAR_7": 215961.55200000003,
    "YEAR_8": 230561.76960000003,
    "YEAR_9": 245161.98720000006,
    "YEAR_10": 259762.20480000004,
    "FIXED_AmplyFund": 1878257.1600000001
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "AmplyFund",
    "No of Households": 1323400,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 595530,
    "HH's Considered": 595.53,
    "contribution per HH": 2977.6499999999996,
    "Assuming Revenue": 297.765,
    "Revenue Per month": 8932.949999999999,
    "YEAR_1": 58153.504499999995,
    "YEAR_2": 125954.59499999997,
    "YEAR_3": 138818.04299999995,
    "YEAR_4": 151681.491,
    "YEAR_5": 164544.93899999998,
    "YEAR_6": 177408.387,
    "YEAR_7": 190271.835,
    "YEAR_8": 203135.28299999997,
    "YEAR_9": 215998.73099999997,
    "YEAR_10": 228862.179,
    "FIXED_AmplyFund": 1654828.9874999998
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "AmplyFund",
    "No of Households": 1293400,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 582030,
    "HH's Considered": 582.03,
    "contribution per HH": 2910.1499999999996,
    "Assuming Revenue": 291.015,
    "Revenue Per month": 8730.449999999999,
    "YEAR_1": 56835.229499999994,
    "YEAR_2": 123099.345,
    "YEAR_3": 135671.19299999997,
    "YEAR_4": 148243.041,
    "YEAR_5": 160814.889,
    "YEAR_6": 173386.73699999996,
    "YEAR_7": 185958.58499999996,
    "YEAR_8": 198530.433,
    "YEAR_9": 211102.28099999996,
    "YEAR_10": 223674.12899999996,
    "FIXED_AmplyFund": 1617315.8624999998
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "AmplyFund",
    "No of Households": 1289510,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 580279.5,
    "HH's Considered": 580.2795,
    "contribution per HH": 2901.3975,
    "Assuming Revenue": 290.13975,
    "Revenue Per month": 8704.1925,
    "YEAR_1": 56664.29317499999,
    "YEAR_2": 122729.11425,
    "YEAR_3": 135263.15145,
    "YEAR_4": 147797.18864999997,
    "YEAR_5": 160331.22584999996,
    "YEAR_6": 172865.26304999995,
    "YEAR_7": 185399.30024999997,
    "YEAR_8": 197933.33744999996,
    "YEAR_9": 210467.37464999998,
    "YEAR_10": 223001.41184999997,
    "FIXED_AmplyFund": 1612451.6606249998
  },
  {
    "DMA": "Saint Louis",
    "PlatformName": "AmplyFund",
    "No of Households": 1255160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 564822,
    "HH's Considered": 564.822,
    "contribution per HH": 2824.11,
    "Assuming Revenue": 282.411,
    "Revenue Per month": 8472.33,
    "YEAR_1": 55154.8683,
    "YEAR_2": 119459.853,
    "YEAR_3": 131660.0082,
    "YEAR_4": 143860.16339999996,
    "YEAR_5": 156060.31859999997,
    "YEAR_6": 168260.4738,
    "YEAR_7": 180460.62899999996,
    "YEAR_8": 192660.78419999997,
    "YEAR_9": 204860.93939999997,
    "YEAR_10": 217061.0946,
    "FIXED_AmplyFund": 1569499.1324999998
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "AmplyFund",
    "No of Households": 1207280,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 543276,
    "HH's Considered": 543.2760000000001,
    "contribution per HH": 2716.38,
    "Assuming Revenue": 271.63800000000003,
    "Revenue Per month": 8149.140000000001,
    "YEAR_1": 53050.90140000001,
    "YEAR_2": 114902.87400000003,
    "YEAR_3": 126637.63560000001,
    "YEAR_4": 138372.3972,
    "YEAR_5": 150107.15880000003,
    "YEAR_6": 161841.92040000003,
    "YEAR_7": 173576.68200000003,
    "YEAR_8": 185311.44360000006,
    "YEAR_9": 197046.20519999997,
    "YEAR_10": 208780.96680000005,
    "FIXED_AmplyFund": 1509628.1850000003
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "AmplyFund",
    "No of Households": 1174940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 528723,
    "HH's Considered": 528.723,
    "contribution per HH": 2643.615,
    "Assuming Revenue": 264.3615,
    "Revenue Per month": 7930.844999999999,
    "YEAR_1": 51629.80094999999,
    "YEAR_2": 111824.9145,
    "YEAR_3": 123245.33129999999,
    "YEAR_4": 134665.7481,
    "YEAR_5": 146086.16489999997,
    "YEAR_6": 157506.58169999998,
    "YEAR_7": 168926.9985,
    "YEAR_8": 180347.41530000002,
    "YEAR_9": 191767.83209999997,
    "YEAR_10": 203188.2489,
    "FIXED_AmplyFund": 1469189.03625
  },
  {
    "DMA": "Nashville",
    "PlatformName": "AmplyFund",
    "No of Households": 1168540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 525843,
    "HH's Considered": 525.843,
    "contribution per HH": 2629.2149999999997,
    "Assuming Revenue": 262.9215,
    "Revenue Per month": 7887.6449999999995,
    "YEAR_1": 51348.56894999999,
    "YEAR_2": 111215.79449999999,
    "YEAR_3": 122574.0033,
    "YEAR_4": 133932.2121,
    "YEAR_5": 145290.4209,
    "YEAR_6": 156648.62969999996,
    "YEAR_7": 168006.83849999998,
    "YEAR_8": 179365.04729999998,
    "YEAR_9": 190723.25609999997,
    "YEAR_10": 202081.4649,
    "FIXED_AmplyFund": 1461186.2362499996
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "AmplyFund",
    "No of Households": 1149480,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 517266,
    "HH's Considered": 517.266,
    "contribution per HH": 2586.33,
    "Assuming Revenue": 258.633,
    "Revenue Per month": 7758.99,
    "YEAR_1": 50511.02489999999,
    "YEAR_2": 109401.759,
    "YEAR_3": 120574.7046,
    "YEAR_4": 131747.65019999997,
    "YEAR_5": 142920.59579999998,
    "YEAR_6": 154093.5414,
    "YEAR_7": 165266.487,
    "YEAR_8": 176439.4326,
    "YEAR_9": 187612.37819999998,
    "YEAR_10": 198785.32379999998,
    "FIXED_AmplyFund": 1437352.8974999997
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "AmplyFund",
    "No of Households": 1148120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 516654,
    "HH's Considered": 516.654,
    "contribution per HH": 2583.27,
    "Assuming Revenue": 258.327,
    "Revenue Per month": 7749.8099999999995,
    "YEAR_1": 50451.263100000004,
    "YEAR_2": 109272.32099999998,
    "YEAR_3": 120432.0474,
    "YEAR_4": 131591.7738,
    "YEAR_5": 142751.50019999998,
    "YEAR_6": 153911.2266,
    "YEAR_7": 165070.95299999998,
    "YEAR_8": 176230.6794,
    "YEAR_9": 187390.40580000004,
    "YEAR_10": 198550.1322,
    "FIXED_AmplyFund": 1435652.3025000002
  },
  {
    "DMA": "San Diego",
    "PlatformName": "AmplyFund",
    "No of Households": 1107010,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 498154.5,
    "HH's Considered": 498.1545,
    "contribution per HH": 2490.7725,
    "Assuming Revenue": 249.07725000000002,
    "Revenue Per month": 7472.317500000001,
    "YEAR_1": 48644.78692500001,
    "YEAR_2": 105359.67675000003,
    "YEAR_3": 116119.81395000003,
    "YEAR_4": 126879.95115000001,
    "YEAR_5": 137640.08835,
    "YEAR_6": 148400.22555000003,
    "YEAR_7": 159160.36275000003,
    "YEAR_8": 169920.49995,
    "YEAR_9": 180680.63715000002,
    "YEAR_10": 191440.77435000002,
    "FIXED_AmplyFund": 1384246.8168750003
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "AmplyFund",
    "No of Households": 1059540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 476793,
    "HH's Considered": 476.793,
    "contribution per HH": 2383.965,
    "Assuming Revenue": 238.39650000000003,
    "Revenue Per month": 7151.895000000001,
    "YEAR_1": 46558.83645000001,
    "YEAR_2": 100841.71950000002,
    "YEAR_3": 111140.44830000002,
    "YEAR_4": 121439.17710000003,
    "YEAR_5": 131737.90590000004,
    "YEAR_6": 142036.63470000002,
    "YEAR_7": 152335.36350000004,
    "YEAR_8": 162634.09230000002,
    "YEAR_9": 172932.82110000003,
    "YEAR_10": 183231.54990000004,
    "FIXED_AmplyFund": 1324888.54875
  },
  {
    "DMA": "Columbus, OH",
    "PlatformName": "AmplyFund",
    "No of Households": 1023600,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 460620,
    "HH's Considered": 460.62,
    "contribution per HH": 2303.1,
    "Assuming Revenue": 230.31,
    "Revenue Per month": 6909.3,
    "YEAR_1": 44979.543000000005,
    "YEAR_2": 97421.13,
    "YEAR_3": 107370.522,
    "YEAR_4": 117319.91400000002,
    "YEAR_5": 127269.30600000001,
    "YEAR_6": 137218.698,
    "YEAR_7": 147168.09,
    "YEAR_8": 157117.48200000002,
    "YEAR_9": 167066.87399999998,
    "YEAR_10": 177016.266,
    "FIXED_AmplyFund": 1279947.8250000002
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "AmplyFund",
    "No of Households": 1019550,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 458797.5,
    "HH's Considered": 458.7975,
    "contribution per HH": 2293.9875,
    "Assuming Revenue": 229.39875000000004,
    "Revenue Per month": 6881.9625000000015,
    "YEAR_1": 44801.57587500001,
    "YEAR_2": 97035.67125000001,
    "YEAR_3": 106945.69725000003,
    "YEAR_4": 116855.72325000004,
    "YEAR_5": 126765.74925000005,
    "YEAR_6": 136675.77525000006,
    "YEAR_7": 146585.80125000005,
    "YEAR_8": 156495.82725000003,
    "YEAR_9": 166405.85325000004,
    "YEAR_10": 176315.87925000003,
    "FIXED_AmplyFund": 1274883.5531250003
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "AmplyFund",
    "No of Households": 1014160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 456372,
    "HH's Considered": 456.372,
    "contribution per HH": 2281.86,
    "Assuming Revenue": 228.18600000000004,
    "Revenue Per month": 6845.580000000001,
    "YEAR_1": 44564.72580000001,
    "YEAR_2": 96522.67800000001,
    "YEAR_3": 106380.3132,
    "YEAR_4": 116237.94840000001,
    "YEAR_5": 126095.5836,
    "YEAR_6": 135953.2188,
    "YEAR_7": 145810.85400000002,
    "YEAR_8": 155668.4892,
    "YEAR_9": 165526.12440000003,
    "YEAR_10": 175383.7596,
    "FIXED_AmplyFund": 1268143.6950000003
  },
  {
    "DMA": "Austin",
    "PlatformName": "AmplyFund",
    "No of Households": 978520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 440334,
    "HH's Considered": 440.334,
    "contribution per HH": 2201.67,
    "Assuming Revenue": 220.16700000000003,
    "Revenue Per month": 6605.010000000001,
    "YEAR_1": 42998.6151,
    "YEAR_2": 93130.641,
    "YEAR_3": 102641.85540000003,
    "YEAR_4": 112153.06980000003,
    "YEAR_5": 121664.2842,
    "YEAR_6": 131175.49860000002,
    "YEAR_7": 140686.713,
    "YEAR_8": 150197.92740000002,
    "YEAR_9": 159709.14180000004,
    "YEAR_10": 169220.35620000004,
    "FIXED_AmplyFund": 1223578.1025000003
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "AmplyFund",
    "No of Households": 953940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 429273,
    "HH's Considered": 429.273,
    "contribution per HH": 2146.3650000000002,
    "Assuming Revenue": 214.63650000000004,
    "Revenue Per month": 6439.095000000001,
    "YEAR_1": 41918.50845000001,
    "YEAR_2": 90791.23950000004,
    "YEAR_3": 100063.53630000002,
    "YEAR_4": 109335.8331,
    "YEAR_5": 118608.12990000003,
    "YEAR_6": 127880.42670000004,
    "YEAR_7": 137152.72350000002,
    "YEAR_8": 146425.02030000003,
    "YEAR_9": 155697.31710000004,
    "YEAR_10": 164969.6139,
    "FIXED_AmplyFund": 1192842.3487500004
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "AmplyFund",
    "No of Households": 947350,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 426307.5,
    "HH's Considered": 426.3075,
    "contribution per HH": 2131.5375,
    "Assuming Revenue": 213.15375,
    "Revenue Per month": 6394.6125,
    "YEAR_1": 41628.927375,
    "YEAR_2": 90164.03625,
    "YEAR_3": 99372.27825,
    "YEAR_4": 108580.52025,
    "YEAR_5": 117788.76225,
    "YEAR_6": 126997.00425,
    "YEAR_7": 136205.24625000003,
    "YEAR_8": 145413.48825000002,
    "YEAR_9": 154621.73025,
    "YEAR_10": 163829.97225,
    "FIXED_AmplyFund": 1184601.9656250002
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "AmplyFund",
    "No of Households": 900200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 405090,
    "HH's Considered": 405.09000000000003,
    "contribution per HH": 2025.4500000000003,
    "Assuming Revenue": 202.54500000000004,
    "Revenue Per month": 6076.350000000001,
    "YEAR_1": 39557.03850000001,
    "YEAR_2": 85676.535,
    "YEAR_3": 94426.47900000005,
    "YEAR_4": 103176.42300000002,
    "YEAR_5": 111926.36700000001,
    "YEAR_6": 120676.31100000002,
    "YEAR_7": 129426.25500000003,
    "YEAR_8": 138176.19900000002,
    "YEAR_9": 146926.14300000004,
    "YEAR_10": 155676.087,
    "FIXED_AmplyFund": 1125643.8375000001
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "AmplyFund",
    "No of Households": 888210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 399694.5,
    "HH's Considered": 399.6945,
    "contribution per HH": 1998.4725,
    "Assuming Revenue": 199.84725000000003,
    "Revenue Per month": 5995.417500000001,
    "YEAR_1": 39030.16792500001,
    "YEAR_2": 84535.38675000002,
    "YEAR_3": 93168.78795000003,
    "YEAR_4": 101802.18915000002,
    "YEAR_5": 110435.59035000004,
    "YEAR_6": 119068.99155,
    "YEAR_7": 127702.39275000004,
    "YEAR_8": 136335.79395000002,
    "YEAR_9": 144969.19515000004,
    "YEAR_10": 153602.59635000007,
    "FIXED_AmplyFund": 1110651.0918750002
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "AmplyFund",
    "No of Households": 870240,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 391608,
    "HH's Considered": 391.608,
    "contribution per HH": 1958.04,
    "Assuming Revenue": 195.804,
    "Revenue Per month": 5874.12,
    "YEAR_1": 38240.521199999996,
    "YEAR_2": 82825.09199999999,
    "YEAR_3": 91283.8248,
    "YEAR_4": 99742.5576,
    "YEAR_5": 108201.29040000001,
    "YEAR_6": 116660.02320000001,
    "YEAR_7": 125118.75600000001,
    "YEAR_8": 133577.4888,
    "YEAR_9": 142036.2216,
    "YEAR_10": 150494.9544,
    "FIXED_AmplyFund": 1088180.73
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "AmplyFund",
    "No of Households": 790580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 355761,
    "HH's Considered": 355.761,
    "contribution per HH": 1778.805,
    "Assuming Revenue": 177.8805,
    "Revenue Per month": 5336.415,
    "YEAR_1": 34740.06165,
    "YEAR_2": 75243.45150000001,
    "YEAR_3": 82927.88910000001,
    "YEAR_4": 90612.3267,
    "YEAR_5": 98296.7643,
    "YEAR_6": 105981.20190000001,
    "YEAR_7": 113665.6395,
    "YEAR_8": 121350.07710000001,
    "YEAR_9": 129034.5147,
    "YEAR_10": 136718.9523,
    "FIXED_AmplyFund": 988570.8787500001
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "AmplyFund",
    "No of Households": 781030,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 351463.5,
    "HH's Considered": 351.4635,
    "contribution per HH": 1757.3175,
    "Assuming Revenue": 175.73175000000003,
    "Revenue Per month": 5271.952500000001,
    "YEAR_1": 34320.410775000004,
    "YEAR_2": 74334.53025000001,
    "YEAR_3": 81926.14185000003,
    "YEAR_4": 89517.75345000002,
    "YEAR_5": 97109.36505000001,
    "YEAR_6": 104700.97665000003,
    "YEAR_7": 112292.58825000003,
    "YEAR_8": 119884.19985,
    "YEAR_9": 127475.81145000004,
    "YEAR_10": 135067.42305000004,
    "FIXED_AmplyFund": 976629.200625
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "AmplyFund",
    "No of Households": 772320,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 347544,
    "HH's Considered": 347.544,
    "contribution per HH": 1737.7199999999998,
    "Assuming Revenue": 173.772,
    "Revenue Per month": 5213.16,
    "YEAR_1": 33937.6716,
    "YEAR_2": 73505.556,
    "YEAR_3": 81012.5064,
    "YEAR_4": 88519.45679999999,
    "YEAR_5": 96026.4072,
    "YEAR_6": 103533.35759999999,
    "YEAR_7": 111040.30799999999,
    "YEAR_8": 118547.25839999999,
    "YEAR_9": 126054.20880000001,
    "YEAR_10": 133561.1592,
    "FIXED_AmplyFund": 965737.8899999999
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "AmplyFund",
    "No of Households": 772190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 347485.5,
    "HH's Considered": 347.4855,
    "contribution per HH": 1737.4275,
    "Assuming Revenue": 173.74275,
    "Revenue Per month": 5212.2825,
    "YEAR_1": 33931.959075000006,
    "YEAR_2": 73493.18325,
    "YEAR_3": 80998.87005,
    "YEAR_4": 88504.55685000001,
    "YEAR_5": 96010.24365000002,
    "YEAR_6": 103515.93045000001,
    "YEAR_7": 111021.61725000001,
    "YEAR_8": 118527.30404999999,
    "YEAR_9": 126032.99085000002,
    "YEAR_10": 133538.67765000003,
    "FIXED_AmplyFund": 965575.3331250001
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "AmplyFund",
    "No of Households": 766220,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 344799,
    "HH's Considered": 344.79900000000004,
    "contribution per HH": 1723.9950000000001,
    "Assuming Revenue": 172.39950000000002,
    "Revenue Per month": 5171.985000000001,
    "YEAR_1": 33669.622350000005,
    "YEAR_2": 72924.9885,
    "YEAR_3": 80372.64689999999,
    "YEAR_4": 87820.3053,
    "YEAR_5": 95267.9637,
    "YEAR_6": 102715.62210000001,
    "YEAR_7": 110163.28050000002,
    "YEAR_8": 117610.93890000001,
    "YEAR_9": 125058.59730000001,
    "YEAR_10": 132506.2557,
    "FIXED_AmplyFund": 958110.2212500001
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "AmplyFund",
    "No of Households": 743340,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 334503,
    "HH's Considered": 334.503,
    "contribution per HH": 1672.5149999999999,
    "Assuming Revenue": 167.2515,
    "Revenue Per month": 5017.545,
    "YEAR_1": 32664.217950000002,
    "YEAR_2": 70747.3845,
    "YEAR_3": 77972.64929999999,
    "YEAR_4": 85197.9141,
    "YEAR_5": 92423.1789,
    "YEAR_6": 99648.4437,
    "YEAR_7": 106873.70850000001,
    "YEAR_8": 114098.9733,
    "YEAR_9": 121324.2381,
    "YEAR_10": 128549.50289999999,
    "FIXED_AmplyFund": 929500.2112499999
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "AmplyFund",
    "No of Households": 739970,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 332986.5,
    "HH's Considered": 332.98650000000004,
    "contribution per HH": 1664.9325000000001,
    "Assuming Revenue": 166.49325000000002,
    "Revenue Per month": 4994.797500000001,
    "YEAR_1": 32516.131725000007,
    "YEAR_2": 70426.64475,
    "YEAR_3": 77619.15315000001,
    "YEAR_4": 84811.66155,
    "YEAR_5": 92004.16995000001,
    "YEAR_6": 99196.67835,
    "YEAR_7": 106389.18675000002,
    "YEAR_8": 113581.69515,
    "YEAR_9": 120774.20355000003,
    "YEAR_10": 127966.71195000001,
    "FIXED_AmplyFund": 925286.2368750003
  },
  {
    "DMA": "Louisville",
    "PlatformName": "AmplyFund",
    "No of Households": 721070,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 324481.5,
    "HH's Considered": 324.4815,
    "contribution per HH": 1622.4074999999998,
    "Assuming Revenue": 162.24075,
    "Revenue Per month": 4867.2225,
    "YEAR_1": 31685.618475,
    "YEAR_2": 68627.83725,
    "YEAR_3": 75636.63765,
    "YEAR_4": 82645.43805,
    "YEAR_5": 89654.23845,
    "YEAR_6": 96663.03885000001,
    "YEAR_7": 103671.83924999999,
    "YEAR_8": 110680.63965000001,
    "YEAR_9": 117689.44005,
    "YEAR_10": 124698.24044999998,
    "FIXED_AmplyFund": 901652.9681249999
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "AmplyFund",
    "No of Households": 720750,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 324337.5,
    "HH's Considered": 324.33750000000003,
    "contribution per HH": 1621.6875000000002,
    "Assuming Revenue": 162.16875000000005,
    "Revenue Per month": 4865.062500000002,
    "YEAR_1": 31671.556875000017,
    "YEAR_2": 68597.38125000002,
    "YEAR_3": 75603.07125000004,
    "YEAR_4": 82608.76125000004,
    "YEAR_5": 89614.45125000003,
    "YEAR_6": 96620.14125000004,
    "YEAR_7": 103625.83125000005,
    "YEAR_8": 110631.52125000005,
    "YEAR_9": 117637.21125000005,
    "YEAR_10": 124642.90125000005,
    "FIXED_AmplyFund": 901252.8281250002
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "AmplyFund",
    "No of Households": 687110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 309199.5,
    "HH's Considered": 309.1995,
    "contribution per HH": 1545.9975,
    "Assuming Revenue": 154.59975,
    "Revenue Per month": 4637.9925,
    "YEAR_1": 30193.331175000003,
    "YEAR_2": 65395.69425,
    "YEAR_3": 72074.40345,
    "YEAR_4": 78753.11265,
    "YEAR_5": 85431.82185000001,
    "YEAR_6": 92110.53104999999,
    "YEAR_7": 98789.24025000002,
    "YEAR_8": 105467.94945000001,
    "YEAR_9": 112146.65865000003,
    "YEAR_10": 118825.36785000002,
    "FIXED_AmplyFund": 859188.1106250001
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "AmplyFund",
    "No of Households": 663900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 298755,
    "HH's Considered": 298.755,
    "contribution per HH": 1493.775,
    "Assuming Revenue": 149.37750000000003,
    "Revenue Per month": 4481.325000000001,
    "YEAR_1": 29173.425750000006,
    "YEAR_2": 63186.68250000001,
    "YEAR_3": 69639.79050000002,
    "YEAR_4": 76092.89850000001,
    "YEAR_5": 82546.0065,
    "YEAR_6": 88999.1145,
    "YEAR_7": 95452.22250000003,
    "YEAR_8": 101905.33050000003,
    "YEAR_9": 108358.43850000002,
    "YEAR_10": 114811.54650000003,
    "FIXED_AmplyFund": 830165.4562500003
  },
  {
    "DMA": "Memphis",
    "PlatformName": "AmplyFund",
    "No of Households": 644360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 289962,
    "HH's Considered": 289.962,
    "contribution per HH": 1449.81,
    "Assuming Revenue": 144.981,
    "Revenue Per month": 4349.43,
    "YEAR_1": 28314.789300000004,
    "YEAR_2": 61326.963,
    "YEAR_3": 67590.1422,
    "YEAR_4": 73853.3214,
    "YEAR_5": 80116.5006,
    "YEAR_6": 86379.67980000001,
    "YEAR_7": 92642.85900000001,
    "YEAR_8": 98906.03820000001,
    "YEAR_9": 105169.21740000002,
    "YEAR_10": 111432.39660000001,
    "FIXED_AmplyFund": 805731.9075000001
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "AmplyFund",
    "No of Households": 634120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 285354,
    "HH's Considered": 285.354,
    "contribution per HH": 1426.77,
    "Assuming Revenue": 142.677,
    "Revenue Per month": 4280.3099999999995,
    "YEAR_1": 27864.818099999997,
    "YEAR_2": 60352.37099999999,
    "YEAR_3": 66516.0174,
    "YEAR_4": 72679.66379999998,
    "YEAR_5": 78843.31019999999,
    "YEAR_6": 85006.9566,
    "YEAR_7": 91170.60299999999,
    "YEAR_8": 97334.24939999999,
    "YEAR_9": 103497.89579999998,
    "YEAR_10": 109661.54219999998,
    "FIXED_AmplyFund": 792927.4274999999
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "AmplyFund",
    "No of Households": 632110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 284449.5,
    "HH's Considered": 284.4495,
    "contribution per HH": 1422.2475,
    "Assuming Revenue": 142.22475,
    "Revenue Per month": 4266.7425,
    "YEAR_1": 27776.493675,
    "YEAR_2": 60161.06925000001,
    "YEAR_3": 66305.17845000002,
    "YEAR_4": 72449.28765,
    "YEAR_5": 78593.39685000002,
    "YEAR_6": 84737.50605,
    "YEAR_7": 90881.61525,
    "YEAR_8": 97025.72445,
    "YEAR_9": 103169.83365000002,
    "YEAR_10": 109313.94285,
    "FIXED_AmplyFund": 790414.0481250001
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "AmplyFund",
    "No of Households": 618120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 278154,
    "HH's Considered": 278.154,
    "contribution per HH": 1390.77,
    "Assuming Revenue": 139.077,
    "Revenue Per month": 4172.3099999999995,
    "YEAR_1": 27161.738099999995,
    "YEAR_2": 58829.57099999999,
    "YEAR_3": 64837.69739999999,
    "YEAR_4": 70845.8238,
    "YEAR_5": 76853.95019999999,
    "YEAR_6": 82862.07659999999,
    "YEAR_7": 88870.203,
    "YEAR_8": 94878.32939999999,
    "YEAR_9": 100886.45579999998,
    "YEAR_10": 106894.5822,
    "FIXED_AmplyFund": 772920.4275
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "AmplyFund",
    "No of Households": 608190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 273685.5,
    "HH's Considered": 273.6855,
    "contribution per HH": 1368.4275,
    "Assuming Revenue": 136.84275,
    "Revenue Per month": 4105.2825,
    "YEAR_1": 26725.389075,
    "YEAR_2": 57884.483250000005,
    "YEAR_3": 63796.09005000001,
    "YEAR_4": 69707.69685000001,
    "YEAR_5": 75619.30365,
    "YEAR_6": 81530.91045000001,
    "YEAR_7": 87442.51725,
    "YEAR_8": 93354.12405,
    "YEAR_9": 99265.73085,
    "YEAR_10": 105177.33765,
    "FIXED_AmplyFund": 760503.5831250001
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "AmplyFund",
    "No of Households": 590390,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 265675.5,
    "HH's Considered": 265.6755,
    "contribution per HH": 1328.3775,
    "Assuming Revenue": 132.83775,
    "Revenue Per month": 3985.1325,
    "YEAR_1": 25943.212575,
    "YEAR_2": 56190.36825000001,
    "YEAR_3": 61928.959050000005,
    "YEAR_4": 67667.54985000001,
    "YEAR_5": 73406.14065000002,
    "YEAR_6": 79144.73144999999,
    "YEAR_7": 84883.32225,
    "YEAR_8": 90621.91305,
    "YEAR_9": 96360.50385000001,
    "YEAR_10": 102099.09465000001,
    "FIXED_AmplyFund": 738245.7956249999
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "AmplyFund",
    "No of Households": 588650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 264892.5,
    "HH's Considered": 264.8925,
    "contribution per HH": 1324.4624999999999,
    "Assuming Revenue": 132.44625,
    "Revenue Per month": 3973.3875,
    "YEAR_1": 25866.752625,
    "YEAR_2": 56024.76375,
    "YEAR_3": 61746.44174999999,
    "YEAR_4": 67468.11975,
    "YEAR_5": 73189.79775,
    "YEAR_6": 78911.47574999998,
    "YEAR_7": 84633.15375,
    "YEAR_8": 90354.83174999998,
    "YEAR_9": 96076.50975,
    "YEAR_10": 101798.18775,
    "FIXED_AmplyFund": 736070.0343749999
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "AmplyFund",
    "No of Households": 578760,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 260442,
    "HH's Considered": 260.442,
    "contribution per HH": 1302.21,
    "Assuming Revenue": 130.221,
    "Revenue Per month": 3906.63,
    "YEAR_1": 25432.1613,
    "YEAR_2": 55083.483,
    "YEAR_3": 60709.0302,
    "YEAR_4": 66334.57740000001,
    "YEAR_5": 71960.1246,
    "YEAR_6": 77585.6718,
    "YEAR_7": 83211.21900000001,
    "YEAR_8": 88836.7662,
    "YEAR_9": 94462.3134,
    "YEAR_10": 100087.86060000001,
    "FIXED_AmplyFund": 723703.2075
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "AmplyFund",
    "No of Households": 577130,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 259708.5,
    "HH's Considered": 259.7085,
    "contribution per HH": 1298.5425,
    "Assuming Revenue": 129.85425,
    "Revenue Per month": 3895.6275,
    "YEAR_1": 25360.535024999997,
    "YEAR_2": 54928.34774999999,
    "YEAR_3": 60538.051349999994,
    "YEAR_4": 66147.75495,
    "YEAR_5": 71757.45855,
    "YEAR_6": 77367.16214999999,
    "YEAR_7": 82976.86575,
    "YEAR_8": 88586.56935,
    "YEAR_9": 94196.27295,
    "YEAR_10": 99805.97655,
    "FIXED_AmplyFund": 721664.994375
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "AmplyFund",
    "No of Households": 559650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 251842.5,
    "HH's Considered": 251.8425,
    "contribution per HH": 1259.2125,
    "Assuming Revenue": 125.92125000000001,
    "Revenue Per month": 3777.6375000000003,
    "YEAR_1": 24592.420125,
    "YEAR_2": 53264.68875,
    "YEAR_3": 58704.48675000001,
    "YEAR_4": 64144.284750000006,
    "YEAR_5": 69584.08275,
    "YEAR_6": 75023.88075000001,
    "YEAR_7": 80463.67875,
    "YEAR_8": 85903.47675,
    "YEAR_9": 91343.27475000001,
    "YEAR_10": 96783.07275,
    "FIXED_AmplyFund": 699807.346875
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "AmplyFund",
    "No of Households": 543710,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 244669.5,
    "HH's Considered": 244.6695,
    "contribution per HH": 1223.3475,
    "Assuming Revenue": 122.33475000000001,
    "Revenue Per month": 3670.0425000000005,
    "YEAR_1": 23891.976675,
    "YEAR_2": 51747.59925,
    "YEAR_3": 57032.46045,
    "YEAR_4": 62317.32165,
    "YEAR_5": 67602.18285000001,
    "YEAR_6": 72887.04405,
    "YEAR_7": 78171.90525000001,
    "YEAR_8": 83456.76645,
    "YEAR_9": 88741.62765000001,
    "YEAR_10": 94026.48885,
    "FIXED_AmplyFund": 679875.3731249999
  },
  {
    "DMA": "Lexington",
    "PlatformName": "AmplyFund",
    "No of Households": 510900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 229905,
    "HH's Considered": 229.905,
    "contribution per HH": 1149.525,
    "Assuming Revenue": 114.95250000000001,
    "Revenue Per month": 3448.5750000000003,
    "YEAR_1": 22450.22325,
    "YEAR_2": 48624.90750000001,
    "YEAR_3": 53590.85549999999,
    "YEAR_4": 58556.803500000016,
    "YEAR_5": 63522.75150000002,
    "YEAR_6": 68488.6995,
    "YEAR_7": 73454.64749999999,
    "YEAR_8": 78420.59550000001,
    "YEAR_9": 83386.5435,
    "YEAR_10": 88352.4915,
    "FIXED_AmplyFund": 638848.51875
  },
  {
    "DMA": "Dayton",
    "PlatformName": "AmplyFund",
    "No of Households": 491820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 221319,
    "HH's Considered": 221.31900000000002,
    "contribution per HH": 1106.595,
    "Assuming Revenue": 110.65950000000001,
    "Revenue Per month": 3319.7850000000003,
    "YEAR_1": 21611.80035,
    "YEAR_2": 46808.96850000001,
    "YEAR_3": 51589.458900000005,
    "YEAR_4": 56369.94930000001,
    "YEAR_5": 61150.4397,
    "YEAR_6": 65930.93010000001,
    "YEAR_7": 70711.42050000001,
    "YEAR_8": 75491.91090000002,
    "YEAR_9": 80272.4013,
    "YEAR_10": 85052.89170000001,
    "FIXED_AmplyFund": 614990.1712500001
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "AmplyFund",
    "No of Households": 490560,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 220752,
    "HH's Considered": 220.752,
    "contribution per HH": 1103.76,
    "Assuming Revenue": 110.376,
    "Revenue Per month": 3311.28,
    "YEAR_1": 21556.432800000002,
    "YEAR_2": 46689.048,
    "YEAR_3": 51457.2912,
    "YEAR_4": 56225.5344,
    "YEAR_5": 60993.7776,
    "YEAR_6": 65762.02080000001,
    "YEAR_7": 70530.264,
    "YEAR_8": 75298.5072,
    "YEAR_9": 80066.7504,
    "YEAR_10": 84834.99360000002,
    "FIXED_AmplyFund": 613414.6200000001
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "AmplyFund",
    "No of Households": 484300,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 217935,
    "HH's Considered": 217.935,
    "contribution per HH": 1089.675,
    "Assuming Revenue": 108.9675,
    "Revenue Per month": 3269.025,
    "YEAR_1": 21281.352750000002,
    "YEAR_2": 46093.2525,
    "YEAR_3": 50800.64849999998,
    "YEAR_4": 55508.0445,
    "YEAR_5": 60215.44050000001,
    "YEAR_6": 64922.8365,
    "YEAR_7": 69630.2325,
    "YEAR_8": 74337.6285,
    "YEAR_9": 79045.0245,
    "YEAR_10": 83752.42049999998,
    "FIXED_AmplyFund": 605586.88125
  },
  {
    "DMA": "Spokane",
    "PlatformName": "AmplyFund",
    "No of Households": 481910,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 216859.5,
    "HH's Considered": 216.8595,
    "contribution per HH": 1084.2975,
    "Assuming Revenue": 108.42975,
    "Revenue Per month": 3252.8925,
    "YEAR_1": 21176.330175,
    "YEAR_2": 45865.784250000004,
    "YEAR_3": 50549.94945,
    "YEAR_4": 55234.11465,
    "YEAR_5": 59918.27984999999,
    "YEAR_6": 64602.44505,
    "YEAR_7": 69286.61025,
    "YEAR_8": 73970.77545,
    "YEAR_9": 78654.94065,
    "YEAR_10": 83339.10584999999,
    "FIXED_AmplyFund": 602598.335625
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "AmplyFund",
    "No of Households": 472310,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 212539.5,
    "HH's Considered": 212.5395,
    "contribution per HH": 1062.6975,
    "Assuming Revenue": 106.26975,
    "Revenue Per month": 3188.0925,
    "YEAR_1": 20754.482174999997,
    "YEAR_2": 44952.104250000004,
    "YEAR_3": 49542.95745,
    "YEAR_4": 54133.81065,
    "YEAR_5": 58724.663850000004,
    "YEAR_6": 63315.51705,
    "YEAR_7": 67906.37025,
    "YEAR_8": 72497.22345,
    "YEAR_9": 77088.07665,
    "YEAR_10": 81678.92985,
    "FIXED_AmplyFund": 590594.135625
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "AmplyFund",
    "No of Households": 471190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 212035.5,
    "HH's Considered": 212.0355,
    "contribution per HH": 1060.1775,
    "Assuming Revenue": 106.01775,
    "Revenue Per month": 3180.5325000000003,
    "YEAR_1": 20705.266575,
    "YEAR_2": 44845.508250000006,
    "YEAR_3": 49425.47505,
    "YEAR_4": 54005.44185000001,
    "YEAR_5": 58585.408650000005,
    "YEAR_6": 63165.37545000001,
    "YEAR_7": 67745.34225,
    "YEAR_8": 72325.30905,
    "YEAR_9": 76905.27585,
    "YEAR_10": 81485.24265000001,
    "FIXED_AmplyFund": 589193.645625
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "AmplyFund",
    "No of Households": 457620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 205929,
    "HH's Considered": 205.929,
    "contribution per HH": 1029.645,
    "Assuming Revenue": 102.9645,
    "Revenue Per month": 3088.935,
    "YEAR_1": 20108.96685,
    "YEAR_2": 43553.983499999995,
    "YEAR_3": 48002.0499,
    "YEAR_4": 52450.11630000001,
    "YEAR_5": 56898.18269999999,
    "YEAR_6": 61346.2491,
    "YEAR_7": 65794.3155,
    "YEAR_8": 70242.38190000001,
    "YEAR_9": 74690.44829999999,
    "YEAR_10": 79138.51469999999,
    "FIXED_AmplyFund": 572225.20875
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "AmplyFund",
    "No of Households": 456390,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 205375.5,
    "HH's Considered": 205.37550000000002,
    "contribution per HH": 1026.8775,
    "Assuming Revenue": 102.68775000000001,
    "Revenue Per month": 3080.6325,
    "YEAR_1": 20054.917575,
    "YEAR_2": 43436.91825,
    "YEAR_3": 47873.029050000005,
    "YEAR_4": 52309.13985,
    "YEAR_5": 56745.25065000001,
    "YEAR_6": 61181.36145,
    "YEAR_7": 65617.47224999999,
    "YEAR_8": 70053.58305,
    "YEAR_9": 74489.69385,
    "YEAR_10": 78925.80464999999,
    "FIXED_AmplyFund": 570687.170625
  },
  {
    "DMA": "Madison",
    "PlatformName": "AmplyFund",
    "No of Households": 454700,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 204615,
    "HH's Considered": 204.615,
    "contribution per HH": 1023.075,
    "Assuming Revenue": 102.3075,
    "Revenue Per month": 3069.2250000000004,
    "YEAR_1": 19980.65475,
    "YEAR_2": 43276.0725,
    "YEAR_3": 47695.75650000001,
    "YEAR_4": 52115.44050000001,
    "YEAR_5": 56535.12450000001,
    "YEAR_6": 60954.80850000002,
    "YEAR_7": 65374.49250000003,
    "YEAR_8": 69794.17650000002,
    "YEAR_9": 74213.86050000001,
    "YEAR_10": 78633.54450000002,
    "FIXED_AmplyFund": 568573.9312500001
  },
  {
    "DMA": "Omaha",
    "PlatformName": "AmplyFund",
    "No of Households": 451790,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 203305.5,
    "HH's Considered": 203.3055,
    "contribution per HH": 1016.5274999999999,
    "Assuming Revenue": 101.65275,
    "Revenue Per month": 3049.5825,
    "YEAR_1": 19852.782075000003,
    "YEAR_2": 42999.113249999995,
    "YEAR_3": 47390.512050000005,
    "YEAR_4": 51781.91085,
    "YEAR_5": 56173.309649999996,
    "YEAR_6": 60564.70844999999,
    "YEAR_7": 64956.10724999999,
    "YEAR_8": 69347.50604999998,
    "YEAR_9": 73738.90485,
    "YEAR_10": 78130.30365,
    "FIXED_AmplyFund": 564935.158125
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "AmplyFund",
    "No of Households": 442050,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 198922.5,
    "HH's Considered": 198.9225,
    "contribution per HH": 994.6125000000001,
    "Assuming Revenue": 99.46125,
    "Revenue Per month": 2983.8375,
    "YEAR_1": 19424.782125,
    "YEAR_2": 42072.10875000001,
    "YEAR_3": 46368.83475000001,
    "YEAR_4": 50665.56075000001,
    "YEAR_5": 54962.28675000001,
    "YEAR_6": 59259.01275000001,
    "YEAR_7": 63555.738750000004,
    "YEAR_8": 67852.46475000001,
    "YEAR_9": 72149.19075000001,
    "YEAR_10": 76445.91675,
    "FIXED_AmplyFund": 552755.8968750001
  },
  {
    "DMA": "Springfield, MO",
    "PlatformName": "AmplyFund",
    "No of Households": 436340,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 196353,
    "HH's Considered": 196.353,
    "contribution per HH": 981.7650000000001,
    "Assuming Revenue": 98.17650000000002,
    "Revenue Per month": 2945.2950000000005,
    "YEAR_1": 19173.870450000002,
    "YEAR_2": 41528.6595,
    "YEAR_3": 45769.884300000005,
    "YEAR_4": 50011.109100000016,
    "YEAR_5": 54252.333900000005,
    "YEAR_6": 58493.55870000001,
    "YEAR_7": 62734.78350000001,
    "YEAR_8": 66976.00830000002,
    "YEAR_9": 71217.2331,
    "YEAR_10": 75458.45790000002,
    "FIXED_AmplyFund": 545615.89875
  },
  {
    "DMA": "Columbia, SC",
    "PlatformName": "AmplyFund",
    "No of Households": 435570,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 196006.5,
    "HH's Considered": 196.00650000000002,
    "contribution per HH": 980.0325,
    "Assuming Revenue": 98.00325000000001,
    "Revenue Per month": 2940.0975000000003,
    "YEAR_1": 19140.034725,
    "YEAR_2": 41455.37475000001,
    "YEAR_3": 45689.115150000005,
    "YEAR_4": 49922.85555000001,
    "YEAR_5": 54156.59595,
    "YEAR_6": 58390.33635000001,
    "YEAR_7": 62624.07675000001,
    "YEAR_8": 66857.81715000002,
    "YEAR_9": 71091.55755,
    "YEAR_10": 75325.29795000001,
    "FIXED_AmplyFund": 544653.0618750001
  },
  {
    "DMA": "Rochester, NY",
    "PlatformName": "AmplyFund",
    "No of Households": 434190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 195385.5,
    "HH's Considered": 195.3855,
    "contribution per HH": 976.9275,
    "Assuming Revenue": 97.69275,
    "Revenue Per month": 2930.7825000000003,
    "YEAR_1": 19079.394075,
    "YEAR_2": 41324.03325000001,
    "YEAR_3": 45544.36005,
    "YEAR_4": 49764.686850000006,
    "YEAR_5": 53985.01365000001,
    "YEAR_6": 58205.34045000001,
    "YEAR_7": 62425.667250000006,
    "YEAR_8": 66645.99405000001,
    "YEAR_9": 70866.32085,
    "YEAR_10": 75086.64765000001,
    "FIXED_AmplyFund": 542927.4581250001
  },
  {
    "DMA": "Portland",
    "PlatformName": "AmplyFund",
    "No of Households": 429130,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 193108.5,
    "HH's Considered": 193.1085,
    "contribution per HH": 965.5425,
    "Assuming Revenue": 96.55425000000001,
    "Revenue Per month": 2896.6275000000005,
    "YEAR_1": 18857.045025000003,
    "YEAR_2": 40842.44775000001,
    "YEAR_3": 45013.59135,
    "YEAR_4": 49184.73495000001,
    "YEAR_5": 53355.87855000001,
    "YEAR_6": 57527.02215000001,
    "YEAR_7": 61698.165750000015,
    "YEAR_8": 65869.30935,
    "YEAR_9": 70040.45295,
    "YEAR_10": 74211.59655000002,
    "FIXED_AmplyFund": 536600.244375
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "AmplyFund",
    "No of Households": 427650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 192442.5,
    "HH's Considered": 192.4425,
    "contribution per HH": 962.2125,
    "Assuming Revenue": 96.22125,
    "Revenue Per month": 2886.6375,
    "YEAR_1": 18792.010125,
    "YEAR_2": 40701.588749999995,
    "YEAR_3": 44858.34675,
    "YEAR_4": 49015.10475,
    "YEAR_5": 53171.86274999999,
    "YEAR_6": 57328.620749999995,
    "YEAR_7": 61485.37874999999,
    "YEAR_8": 65642.13674999999,
    "YEAR_9": 69798.89474999999,
    "YEAR_10": 73955.65275,
    "FIXED_AmplyFund": 534749.5968749999
  },
  {
    "DMA": "Toledo",
    "PlatformName": "AmplyFund",
    "No of Households": 424380,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 190971,
    "HH's Considered": 190.971,
    "contribution per HH": 954.855,
    "Assuming Revenue": 95.4855,
    "Revenue Per month": 2864.565,
    "YEAR_1": 18648.318150000003,
    "YEAR_2": 40390.3665,
    "YEAR_3": 44515.3401,
    "YEAR_4": 48640.313700000006,
    "YEAR_5": 52765.287299999996,
    "YEAR_6": 56890.2609,
    "YEAR_7": 61015.234500000006,
    "YEAR_8": 65140.208099999996,
    "YEAR_9": 69265.1817,
    "YEAR_10": 73390.1553,
    "FIXED_AmplyFund": 530660.66625
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "AmplyFund",
    "No of Households": 412370,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 185566.5,
    "HH's Considered": 185.5665,
    "contribution per HH": 927.8325,
    "Assuming Revenue": 92.78325000000001,
    "Revenue Per month": 2783.4975000000004,
    "YEAR_1": 18120.568725000005,
    "YEAR_2": 39247.314750000005,
    "YEAR_3": 43255.55115000001,
    "YEAR_4": 47263.78755,
    "YEAR_5": 51272.02395000001,
    "YEAR_6": 55280.26035,
    "YEAR_7": 59288.496750000006,
    "YEAR_8": 63296.73315000001,
    "YEAR_9": 67304.96955000001,
    "YEAR_10": 71313.20595000002,
    "FIXED_AmplyFund": 515642.91187500005
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "AmplyFund",
    "No of Households": 407620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 183429,
    "HH's Considered": 183.429,
    "contribution per HH": 917.145,
    "Assuming Revenue": 91.7145,
    "Revenue Per month": 2751.435,
    "YEAR_1": 17911.841849999997,
    "YEAR_2": 38795.233499999995,
    "YEAR_3": 42757.2999,
    "YEAR_4": 46719.36630000001,
    "YEAR_5": 50681.43269999999,
    "YEAR_6": 54643.49909999999,
    "YEAR_7": 58605.5655,
    "YEAR_8": 62567.6319,
    "YEAR_9": 66529.69829999999,
    "YEAR_10": 70491.76469999999,
    "FIXED_AmplyFund": 509703.33374999993
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "AmplyFund",
    "No of Households": 403470,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 181561.5,
    "HH's Considered": 181.5615,
    "contribution per HH": 907.8075,
    "Assuming Revenue": 90.78075000000001,
    "Revenue Per month": 2723.4225000000006,
    "YEAR_1": 17729.480475000004,
    "YEAR_2": 38400.25725000001,
    "YEAR_3": 42321.98565000001,
    "YEAR_4": 46243.71405,
    "YEAR_5": 50165.44245000002,
    "YEAR_6": 54087.17085000003,
    "YEAR_7": 58008.89925,
    "YEAR_8": 61930.62765,
    "YEAR_9": 65852.35605000002,
    "YEAR_10": 69774.08445000002,
    "FIXED_AmplyFund": 504514.01812500006
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "AmplyFund",
    "No of Households": 401510,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 180679.5,
    "HH's Considered": 180.6795,
    "contribution per HH": 903.3974999999999,
    "Assuming Revenue": 90.33975,
    "Revenue Per month": 2710.1924999999997,
    "YEAR_1": 17643.353174999997,
    "YEAR_2": 38213.71425,
    "YEAR_3": 42116.391449999996,
    "YEAR_4": 46019.068649999994,
    "YEAR_5": 49921.74585,
    "YEAR_6": 53824.42304999999,
    "YEAR_7": 57727.100249999996,
    "YEAR_8": 61629.777449999994,
    "YEAR_9": 65532.45464999999,
    "YEAR_10": 69435.13184999999,
    "FIXED_AmplyFund": 502063.16062499996
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "AmplyFund",
    "No of Households": 388650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 174892.5,
    "HH's Considered": 174.8925,
    "contribution per HH": 874.4625000000001,
    "Assuming Revenue": 87.44625000000002,
    "Revenue Per month": 2623.3875000000007,
    "YEAR_1": 17078.252625000005,
    "YEAR_2": 36989.76375000001,
    "YEAR_3": 40767.441750000005,
    "YEAR_4": 44545.11975000001,
    "YEAR_5": 48322.79775000002,
    "YEAR_6": 52100.475750000005,
    "YEAR_7": 55878.15375000001,
    "YEAR_8": 59655.83175000002,
    "YEAR_9": 63433.50975000002,
    "YEAR_10": 67211.18775000001,
    "FIXED_AmplyFund": 485982.53437500016
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "AmplyFund",
    "No of Households": 385730,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 173578.5,
    "HH's Considered": 173.5785,
    "contribution per HH": 867.8924999999999,
    "Assuming Revenue": 86.78925,
    "Revenue Per month": 2603.6775,
    "YEAR_1": 16949.940524999998,
    "YEAR_2": 36711.85275,
    "YEAR_3": 40461.148349999996,
    "YEAR_4": 44210.44394999999,
    "YEAR_5": 47959.73954999999,
    "YEAR_6": 51709.035149999996,
    "YEAR_7": 55458.33075,
    "YEAR_8": 59207.62634999999,
    "YEAR_9": 62956.92194999999,
    "YEAR_10": 66706.21755,
    "FIXED_AmplyFund": 482331.25687499996
  },
  {
    "DMA": "Savannah",
    "PlatformName": "AmplyFund",
    "No of Households": 378040,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 170118,
    "HH's Considered": 170.118,
    "contribution per HH": 850.5899999999999,
    "Assuming Revenue": 85.059,
    "Revenue Per month": 2551.77,
    "YEAR_1": 16612.022699999998,
    "YEAR_2": 35979.956999999995,
    "YEAR_3": 39654.5058,
    "YEAR_4": 43329.054599999996,
    "YEAR_5": 47003.60339999999,
    "YEAR_6": 50678.1522,
    "YEAR_7": 54352.701,
    "YEAR_8": 58027.249800000005,
    "YEAR_9": 61701.7986,
    "YEAR_10": 65376.3474,
    "FIXED_AmplyFund": 472715.39249999996
  },
  {
    "DMA": "Charleston, SC",
    "PlatformName": "AmplyFund",
    "No of Households": 374290,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 168430.5,
    "HH's Considered": 168.4305,
    "contribution per HH": 842.1524999999999,
    "Assuming Revenue": 84.21525,
    "Revenue Per month": 2526.4575,
    "YEAR_1": 16447.238325000002,
    "YEAR_2": 35623.05075,
    "YEAR_3": 39261.14955,
    "YEAR_4": 42899.24835,
    "YEAR_5": 46537.34715,
    "YEAR_6": 50175.44595,
    "YEAR_7": 53813.54475,
    "YEAR_8": 57451.64355,
    "YEAR_9": 61089.74235,
    "YEAR_10": 64727.84115,
    "FIXED_AmplyFund": 468026.25187499996
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "AmplyFund",
    "No of Households": 373520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 168084,
    "HH's Considered": 168.084,
    "contribution per HH": 840.4200000000001,
    "Assuming Revenue": 84.04200000000002,
    "Revenue Per month": 2521.2600000000007,
    "YEAR_1": 16413.4026,
    "YEAR_2": 35549.76600000001,
    "YEAR_3": 39180.38040000002,
    "YEAR_4": 42810.994800000015,
    "YEAR_5": 46441.60920000001,
    "YEAR_6": 50072.22360000001,
    "YEAR_7": 53702.83800000002,
    "YEAR_8": 57333.45240000002,
    "YEAR_9": 60964.06680000002,
    "YEAR_10": 64594.68120000002,
    "FIXED_AmplyFund": 467063.41500000015
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "AmplyFund",
    "No of Households": 373080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 167886,
    "HH's Considered": 167.886,
    "contribution per HH": 839.43,
    "Assuming Revenue": 83.943,
    "Revenue Per month": 2518.29,
    "YEAR_1": 16394.067900000002,
    "YEAR_2": 35507.888999999996,
    "YEAR_3": 39134.22660000001,
    "YEAR_4": 42760.56419999999,
    "YEAR_5": 46386.9018,
    "YEAR_6": 50013.239400000006,
    "YEAR_7": 53639.57699999999,
    "YEAR_8": 57265.91460000002,
    "YEAR_9": 60892.25220000001,
    "YEAR_10": 64518.58979999999,
    "FIXED_AmplyFund": 466513.22250000003
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "AmplyFund",
    "No of Households": 371730,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 167278.5,
    "HH's Considered": 167.2785,
    "contribution per HH": 836.3925,
    "Assuming Revenue": 83.63925,
    "Revenue Per month": 2509.1775000000002,
    "YEAR_1": 16334.745525000002,
    "YEAR_2": 35379.40275,
    "YEAR_3": 38992.61835000001,
    "YEAR_4": 42605.83395,
    "YEAR_5": 46219.04955,
    "YEAR_6": 49832.26515000001,
    "YEAR_7": 53445.48075000002,
    "YEAR_8": 57058.696350000006,
    "YEAR_9": 60671.91195,
    "YEAR_10": 64285.127550000005,
    "FIXED_AmplyFund": 464825.13187499996
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "AmplyFund",
    "No of Households": 369270,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 166171.5,
    "HH's Considered": 166.1715,
    "contribution per HH": 830.8575000000001,
    "Assuming Revenue": 83.08575000000002,
    "Revenue Per month": 2492.5725000000007,
    "YEAR_1": 16226.646975000003,
    "YEAR_2": 35145.27225000001,
    "YEAR_3": 38734.57665000001,
    "YEAR_4": 42323.88105000002,
    "YEAR_5": 45913.185450000004,
    "YEAR_6": 49502.48985000001,
    "YEAR_7": 53091.794250000006,
    "YEAR_8": 56681.09865000001,
    "YEAR_9": 60270.403050000015,
    "YEAR_10": 63859.70745000001,
    "FIXED_AmplyFund": 461749.05562500004
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "AmplyFund",
    "No of Households": 360880,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 162396,
    "HH's Considered": 162.39600000000002,
    "contribution per HH": 811.98,
    "Assuming Revenue": 81.19800000000001,
    "Revenue Per month": 2435.94,
    "YEAR_1": 15857.969400000002,
    "YEAR_2": 34346.75399999999,
    "YEAR_3": 37854.507600000004,
    "YEAR_4": 41362.2612,
    "YEAR_5": 44870.014800000004,
    "YEAR_6": 48377.7684,
    "YEAR_7": 51885.522,
    "YEAR_8": 55393.27559999999,
    "YEAR_9": 58901.029200000004,
    "YEAR_10": 62408.7828,
    "FIXED_AmplyFund": 451257.885
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "AmplyFund",
    "No of Households": 360200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 162090,
    "HH's Considered": 162.09,
    "contribution per HH": 810.45,
    "Assuming Revenue": 81.04500000000002,
    "Revenue Per month": 2431.3500000000004,
    "YEAR_1": 15828.088500000002,
    "YEAR_2": 34282.034999999996,
    "YEAR_3": 37783.17900000001,
    "YEAR_4": 41284.323000000004,
    "YEAR_5": 44785.46700000001,
    "YEAR_6": 48286.61100000001,
    "YEAR_7": 51787.755000000005,
    "YEAR_8": 55288.89900000001,
    "YEAR_9": 58790.043000000005,
    "YEAR_10": 62291.18700000002,
    "FIXED_AmplyFund": 450407.58750000014
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "AmplyFund",
    "No of Households": 353500,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 159075,
    "HH's Considered": 159.07500000000002,
    "contribution per HH": 795.3750000000001,
    "Assuming Revenue": 79.53750000000002,
    "Revenue Per month": 2386.125000000001,
    "YEAR_1": 15533.673750000005,
    "YEAR_2": 33644.36250000002,
    "YEAR_3": 37080.38250000002,
    "YEAR_4": 40516.40250000001,
    "YEAR_5": 43952.422500000015,
    "YEAR_6": 47388.44250000002,
    "YEAR_7": 50824.46250000002,
    "YEAR_8": 54260.48250000003,
    "YEAR_9": 57696.50250000003,
    "YEAR_10": 61132.52250000002,
    "FIXED_AmplyFund": 442029.6562500002
  },
  {
    "DMA": "Jackson, MS",
    "PlatformName": "AmplyFund",
    "No of Households": 340720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 153324,
    "HH's Considered": 153.324,
    "contribution per HH": 766.6200000000001,
    "Assuming Revenue": 76.66200000000002,
    "Revenue Per month": 2299.8600000000006,
    "YEAR_1": 14972.088600000003,
    "YEAR_2": 32428.026000000005,
    "YEAR_3": 35739.82440000001,
    "YEAR_4": 39051.62280000001,
    "YEAR_5": 42363.42120000001,
    "YEAR_6": 45675.21960000002,
    "YEAR_7": 48987.01800000002,
    "YEAR_8": 52298.81640000001,
    "YEAR_9": 55610.61480000002,
    "YEAR_10": 58922.41320000001,
    "FIXED_AmplyFund": 426049.0650000001
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "AmplyFund",
    "No of Households": 338310,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 152239.5,
    "HH's Considered": 152.2395,
    "contribution per HH": 761.1975,
    "Assuming Revenue": 76.11975,
    "Revenue Per month": 2283.5924999999997,
    "YEAR_1": 14866.187175,
    "YEAR_2": 32198.65425,
    "YEAR_3": 35487.027449999994,
    "YEAR_4": 38775.400649999996,
    "YEAR_5": 42063.77385,
    "YEAR_6": 45352.14705,
    "YEAR_7": 48640.52025,
    "YEAR_8": 51928.89345,
    "YEAR_9": 55217.266650000005,
    "YEAR_10": 58505.63984999999,
    "FIXED_AmplyFund": 423035.51062499994
  },
  {
    "DMA": "Boise",
    "PlatformName": "AmplyFund",
    "No of Households": 330040,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 148518,
    "HH's Considered": 148.518,
    "contribution per HH": 742.59,
    "Assuming Revenue": 74.259,
    "Revenue Per month": 2227.77,
    "YEAR_1": 14502.7827,
    "YEAR_2": 31411.556999999997,
    "YEAR_3": 34619.54579999999,
    "YEAR_4": 37827.534600000006,
    "YEAR_5": 41035.523400000005,
    "YEAR_6": 44243.512200000005,
    "YEAR_7": 47451.501000000004,
    "YEAR_8": 50659.489799999996,
    "YEAR_9": 53867.4786,
    "YEAR_10": 57075.467399999994,
    "FIXED_AmplyFund": 412694.39249999996
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "AmplyFund",
    "No of Households": 329080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 148086,
    "HH's Considered": 148.086,
    "contribution per HH": 740.4300000000001,
    "Assuming Revenue": 74.043,
    "Revenue Per month": 2221.29,
    "YEAR_1": 14460.5979,
    "YEAR_2": 31320.189,
    "YEAR_3": 34518.846600000004,
    "YEAR_4": 37717.5042,
    "YEAR_5": 40916.1618,
    "YEAR_6": 44114.8194,
    "YEAR_7": 47313.477,
    "YEAR_8": 50512.134600000005,
    "YEAR_9": 53710.7922,
    "YEAR_10": 56909.44980000001,
    "FIXED_AmplyFund": 411493.97250000003
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "AmplyFund",
    "No of Households": 322100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 144945,
    "HH's Considered": 144.945,
    "contribution per HH": 724.7249999999999,
    "Assuming Revenue": 72.4725,
    "Revenue Per month": 2174.1749999999997,
    "YEAR_1": 14153.879249999998,
    "YEAR_2": 30655.8675,
    "YEAR_3": 33786.6795,
    "YEAR_4": 36917.4915,
    "YEAR_5": 40048.30349999999,
    "YEAR_6": 43179.11549999999,
    "YEAR_7": 46309.9275,
    "YEAR_8": 49440.739499999996,
    "YEAR_9": 52571.55149999999,
    "YEAR_10": 55702.36349999999,
    "FIXED_AmplyFund": 402765.9187499999
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "AmplyFund",
    "No of Households": 320820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 144369,
    "HH's Considered": 144.369,
    "contribution per HH": 721.845,
    "Assuming Revenue": 72.1845,
    "Revenue Per month": 2165.535,
    "YEAR_1": 14097.632849999998,
    "YEAR_2": 30534.043499999996,
    "YEAR_3": 33652.4139,
    "YEAR_4": 36770.7843,
    "YEAR_5": 39889.15469999999,
    "YEAR_6": 43007.5251,
    "YEAR_7": 46125.89549999999,
    "YEAR_8": 49244.2659,
    "YEAR_9": 52362.6363,
    "YEAR_10": 55481.006700000005,
    "FIXED_AmplyFund": 401165.35875
  },
  {
    "DMA": "Reno",
    "PlatformName": "AmplyFund",
    "No of Households": 306940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 138123,
    "HH's Considered": 138.123,
    "contribution per HH": 690.615,
    "Assuming Revenue": 69.06150000000001,
    "Revenue Per month": 2071.8450000000003,
    "YEAR_1": 13487.710950000002,
    "YEAR_2": 29213.014500000005,
    "YEAR_3": 32196.471300000005,
    "YEAR_4": 35179.9281,
    "YEAR_5": 38163.384900000005,
    "YEAR_6": 41146.841700000004,
    "YEAR_7": 44130.298500000004,
    "YEAR_8": 47113.755300000004,
    "YEAR_9": 50097.21210000002,
    "YEAR_10": 53080.668900000004,
    "FIXED_AmplyFund": 383809.28625
  },
  {
    "DMA": "Washington",
    "PlatformName": "AmplyFund",
    "No of Households": 305320,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 137394,
    "HH's Considered": 137.394,
    "contribution per HH": 686.97,
    "Assuming Revenue": 68.697,
    "Revenue Per month": 2060.91,
    "YEAR_1": 13416.524099999999,
    "YEAR_2": 29058.831,
    "YEAR_3": 32026.541400000002,
    "YEAR_4": 34994.2518,
    "YEAR_5": 37961.962199999994,
    "YEAR_6": 40929.6726,
    "YEAR_7": 43897.383,
    "YEAR_8": 46865.09339999999,
    "YEAR_9": 49832.803799999994,
    "YEAR_10": 52800.51419999999,
    "FIXED_AmplyFund": 381783.57749999996
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "AmplyFund",
    "No of Households": 302600,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 136170,
    "HH's Considered": 136.17000000000002,
    "contribution per HH": 680.8500000000001,
    "Assuming Revenue": 68.08500000000002,
    "Revenue Per month": 2042.5500000000006,
    "YEAR_1": 13297.000500000006,
    "YEAR_2": 28799.955000000005,
    "YEAR_3": 31741.22700000001,
    "YEAR_4": 34682.49900000001,
    "YEAR_5": 37623.771000000015,
    "YEAR_6": 40565.04300000001,
    "YEAR_7": 43506.31500000001,
    "YEAR_8": 46447.587000000014,
    "YEAR_9": 49388.85900000001,
    "YEAR_10": 52330.13100000002,
    "FIXED_AmplyFund": 378382.38750000007
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "AmplyFund",
    "No of Households": 299530,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 134788.5,
    "HH's Considered": 134.7885,
    "contribution per HH": 673.9425,
    "Assuming Revenue": 67.39425,
    "Revenue Per month": 2021.8274999999999,
    "YEAR_1": 13162.097025000001,
    "YEAR_2": 28507.767749999995,
    "YEAR_3": 31419.19935,
    "YEAR_4": 34330.63095,
    "YEAR_5": 37242.062549999995,
    "YEAR_6": 40153.49414999999,
    "YEAR_7": 43064.925749999995,
    "YEAR_8": 45976.35735,
    "YEAR_9": 48887.78895000001,
    "YEAR_10": 51799.22055,
    "FIXED_AmplyFund": 374543.54437499994
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "AmplyFund",
    "No of Households": 291650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 131242.5,
    "HH's Considered": 131.2425,
    "contribution per HH": 656.2125000000001,
    "Assuming Revenue": 65.62125000000002,
    "Revenue Per month": 1968.6375000000005,
    "YEAR_1": 12815.830125000004,
    "YEAR_2": 27757.788750000007,
    "YEAR_3": 30592.626750000007,
    "YEAR_4": 33427.464750000014,
    "YEAR_5": 36262.30275,
    "YEAR_6": 39097.14075000001,
    "YEAR_7": 41931.97875000001,
    "YEAR_8": 44766.81675000002,
    "YEAR_9": 47601.654750000016,
    "YEAR_10": 50436.49275000002,
    "FIXED_AmplyFund": 364690.0968750001
  },
  {
    "DMA": "Evansville",
    "PlatformName": "AmplyFund",
    "No of Households": 290990,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 130945.5,
    "HH's Considered": 130.9455,
    "contribution per HH": 654.7275000000001,
    "Assuming Revenue": 65.47275,
    "Revenue Per month": 1964.1825000000001,
    "YEAR_1": 12786.828075,
    "YEAR_2": 27694.973250000003,
    "YEAR_3": 30523.396050000003,
    "YEAR_4": 33351.81885,
    "YEAR_5": 36180.241649999996,
    "YEAR_6": 39008.66445,
    "YEAR_7": 41837.08725,
    "YEAR_8": 44665.51005,
    "YEAR_9": 47493.93285,
    "YEAR_10": 50322.35565,
    "FIXED_AmplyFund": 363864.8081249999
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "AmplyFund",
    "No of Households": 285360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 128412,
    "HH's Considered": 128.412,
    "contribution per HH": 642.0600000000001,
    "Assuming Revenue": 64.206,
    "Revenue Per month": 1926.18,
    "YEAR_1": 12539.431800000002,
    "YEAR_2": 27159.138000000006,
    "YEAR_3": 29932.8372,
    "YEAR_4": 32706.536399999997,
    "YEAR_5": 35480.2356,
    "YEAR_6": 38253.9348,
    "YEAR_7": 41027.634000000005,
    "YEAR_8": 43801.333199999994,
    "YEAR_9": 46575.03240000001,
    "YEAR_10": 49348.73159999999,
    "FIXED_AmplyFund": 356824.84500000003
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "AmplyFund",
    "No of Households": 284300,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 127935,
    "HH's Considered": 127.935,
    "contribution per HH": 639.675,
    "Assuming Revenue": 63.9675,
    "Revenue Per month": 1919.025,
    "YEAR_1": 12492.852750000002,
    "YEAR_2": 27058.252500000002,
    "YEAR_3": 29821.648500000007,
    "YEAR_4": 32585.044500000004,
    "YEAR_5": 35348.440500000004,
    "YEAR_6": 38111.8365,
    "YEAR_7": 40875.232500000006,
    "YEAR_8": 43638.62849999999,
    "YEAR_9": 46402.02450000001,
    "YEAR_10": 49165.42049999999,
    "FIXED_AmplyFund": 355499.38125000003
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "AmplyFund",
    "No of Households": 283100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 127395,
    "HH's Considered": 127.395,
    "contribution per HH": 636.975,
    "Assuming Revenue": 63.697500000000005,
    "Revenue Per month": 1910.9250000000002,
    "YEAR_1": 12440.121750000002,
    "YEAR_2": 26944.042500000003,
    "YEAR_3": 29695.7745,
    "YEAR_4": 32447.506500000003,
    "YEAR_5": 35199.23850000001,
    "YEAR_6": 37950.9705,
    "YEAR_7": 40702.70250000001,
    "YEAR_8": 43454.4345,
    "YEAR_9": 46206.16650000001,
    "YEAR_10": 48957.89850000001,
    "FIXED_AmplyFund": 353998.85625000007
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "AmplyFund",
    "No of Households": 282090,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 126940.5,
    "HH's Considered": 126.9405,
    "contribution per HH": 634.7025,
    "Assuming Revenue": 63.47025,
    "Revenue Per month": 1904.1075,
    "YEAR_1": 12395.739825,
    "YEAR_2": 26847.91575,
    "YEAR_3": 29589.830550000006,
    "YEAR_4": 32331.745350000005,
    "YEAR_5": 35073.66015,
    "YEAR_6": 37815.57495000001,
    "YEAR_7": 40557.48975,
    "YEAR_8": 43299.40455,
    "YEAR_9": 46041.31935000001,
    "YEAR_10": 48783.234150000004,
    "FIXED_AmplyFund": 352735.91437500005
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "AmplyFund",
    "No of Households": 276580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 124461,
    "HH's Considered": 124.461,
    "contribution per HH": 622.305,
    "Assuming Revenue": 62.2305,
    "Revenue Per month": 1866.915,
    "YEAR_1": 12153.61665,
    "YEAR_2": 26323.5015,
    "YEAR_3": 29011.859099999998,
    "YEAR_4": 31700.216699999997,
    "YEAR_5": 34388.5743,
    "YEAR_6": 37076.9319,
    "YEAR_7": 39765.28949999999,
    "YEAR_8": 42453.6471,
    "YEAR_9": 45142.004700000005,
    "YEAR_10": 47830.3623,
    "FIXED_AmplyFund": 345846.00375
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "AmplyFund",
    "No of Households": 265790,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 119605.5,
    "HH's Considered": 119.6055,
    "contribution per HH": 598.0275,
    "Assuming Revenue": 59.80275,
    "Revenue Per month": 1794.0825,
    "YEAR_1": 11679.477075,
    "YEAR_2": 25296.56325,
    "YEAR_3": 27880.042050000004,
    "YEAR_4": 30463.52085,
    "YEAR_5": 33046.99965,
    "YEAR_6": 35630.478449999995,
    "YEAR_7": 38213.95725,
    "YEAR_8": 40797.43605,
    "YEAR_9": 43380.91485,
    "YEAR_10": 45964.39365,
    "FIXED_AmplyFund": 332353.78312499996
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "AmplyFund",
    "No of Households": 264420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 118989,
    "HH's Considered": 118.989,
    "contribution per HH": 594.945,
    "Assuming Revenue": 59.49450000000001,
    "Revenue Per month": 1784.8350000000003,
    "YEAR_1": 11619.275850000002,
    "YEAR_2": 25166.1735,
    "YEAR_3": 27736.3359,
    "YEAR_4": 30306.498300000007,
    "YEAR_5": 32876.66070000001,
    "YEAR_6": 35446.8231,
    "YEAR_7": 38016.98550000001,
    "YEAR_8": 40587.147899999996,
    "YEAR_9": 43157.3103,
    "YEAR_10": 45727.47270000001,
    "FIXED_AmplyFund": 330640.6837500001
  },
  {
    "DMA": "Lansing",
    "PlatformName": "AmplyFund",
    "No of Households": 260780,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 117351,
    "HH's Considered": 117.351,
    "contribution per HH": 586.755,
    "Assuming Revenue": 58.6755,
    "Revenue Per month": 1760.2649999999999,
    "YEAR_1": 11459.325149999999,
    "YEAR_2": 24819.7365,
    "YEAR_3": 27354.518099999998,
    "YEAR_4": 29889.2997,
    "YEAR_5": 32424.081299999998,
    "YEAR_6": 34958.8629,
    "YEAR_7": 37493.6445,
    "YEAR_8": 40028.426100000004,
    "YEAR_9": 42563.20770000001,
    "YEAR_10": 45097.989299999994,
    "FIXED_AmplyFund": 326089.09125000006
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "AmplyFund",
    "No of Households": 260220,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 117099,
    "HH's Considered": 117.099,
    "contribution per HH": 585.495,
    "Assuming Revenue": 58.5495,
    "Revenue Per month": 1756.4850000000001,
    "YEAR_1": 11434.717350000003,
    "YEAR_2": 24766.438500000004,
    "YEAR_3": 27295.7769,
    "YEAR_4": 29825.115300000005,
    "YEAR_5": 32354.453700000002,
    "YEAR_6": 34883.7921,
    "YEAR_7": 37413.13050000001,
    "YEAR_8": 39942.46890000001,
    "YEAR_9": 42471.807300000015,
    "YEAR_10": 45001.1457,
    "FIXED_AmplyFund": 325388.84625000006
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "AmplyFund",
    "No of Households": 258360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 116262,
    "HH's Considered": 116.262,
    "contribution per HH": 581.31,
    "Assuming Revenue": 58.131,
    "Revenue Per month": 1743.93,
    "YEAR_1": 11352.9843,
    "YEAR_2": 24589.412999999997,
    "YEAR_3": 27100.672199999997,
    "YEAR_4": 29611.931399999998,
    "YEAR_5": 32123.190599999998,
    "YEAR_6": 34634.4498,
    "YEAR_7": 37145.709,
    "YEAR_8": 39656.9682,
    "YEAR_9": 42168.227399999996,
    "YEAR_10": 44679.4866,
    "FIXED_AmplyFund": 323063.03250000003
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "AmplyFund",
    "No of Households": 254120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 114354,
    "HH's Considered": 114.354,
    "contribution per HH": 571.77,
    "Assuming Revenue": 57.177,
    "Revenue Per month": 1715.31,
    "YEAR_1": 11166.668099999999,
    "YEAR_2": 24185.871,
    "YEAR_3": 26655.917400000002,
    "YEAR_4": 29125.963799999998,
    "YEAR_5": 31596.010199999997,
    "YEAR_6": 34066.056599999996,
    "YEAR_7": 36536.103,
    "YEAR_8": 39006.149399999995,
    "YEAR_9": 41476.1958,
    "YEAR_10": 43946.24219999999,
    "FIXED_AmplyFund": 317761.17749999993
  },
  {
    "DMA": "Eugene",
    "PlatformName": "AmplyFund",
    "No of Households": 250840,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 112878,
    "HH's Considered": 112.878,
    "contribution per HH": 564.39,
    "Assuming Revenue": 56.439,
    "Revenue Per month": 1693.17,
    "YEAR_1": 11022.5367,
    "YEAR_2": 23873.697,
    "YEAR_3": 26311.861800000002,
    "YEAR_4": 28750.026600000005,
    "YEAR_5": 31188.191400000003,
    "YEAR_6": 33626.3562,
    "YEAR_7": 36064.521,
    "YEAR_8": 38502.6858,
    "YEAR_9": 40940.850600000005,
    "YEAR_10": 43379.0154,
    "FIXED_AmplyFund": 313659.7425
  },
  {
    "DMA": "Macon",
    "PlatformName": "AmplyFund",
    "No of Households": 250620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 112779,
    "HH's Considered": 112.779,
    "contribution per HH": 563.895,
    "Assuming Revenue": 56.3895,
    "Revenue Per month": 1691.685,
    "YEAR_1": 11012.869349999999,
    "YEAR_2": 23852.7585,
    "YEAR_3": 26288.7849,
    "YEAR_4": 28724.8113,
    "YEAR_5": 31160.837699999996,
    "YEAR_6": 33596.8641,
    "YEAR_7": 36032.890499999994,
    "YEAR_8": 38468.916900000004,
    "YEAR_9": 40904.9433,
    "YEAR_10": 43340.969699999994,
    "FIXED_AmplyFund": 313384.64625
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "AmplyFund",
    "No of Households": 249140,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 112113,
    "HH's Considered": 112.113,
    "contribution per HH": 560.565,
    "Assuming Revenue": 56.05650000000001,
    "Revenue Per month": 1681.6950000000002,
    "YEAR_1": 10947.83445,
    "YEAR_2": 23711.899500000003,
    "YEAR_3": 26133.540300000004,
    "YEAR_4": 28555.18110000001,
    "YEAR_5": 30976.821900000003,
    "YEAR_6": 33398.46270000001,
    "YEAR_7": 35820.103500000005,
    "YEAR_8": 38241.7443,
    "YEAR_9": 40663.38510000001,
    "YEAR_10": 43085.0259,
    "FIXED_AmplyFund": 311533.99875
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "AmplyFund",
    "No of Households": 248110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 111649.5,
    "HH's Considered": 111.6495,
    "contribution per HH": 558.2475000000001,
    "Assuming Revenue": 55.82475000000001,
    "Revenue Per month": 1674.7425000000003,
    "YEAR_1": 10902.573675000001,
    "YEAR_2": 23613.869250000003,
    "YEAR_3": 26025.498450000006,
    "YEAR_4": 28437.127650000002,
    "YEAR_5": 30848.756850000005,
    "YEAR_6": 33260.38605,
    "YEAR_7": 35672.015250000004,
    "YEAR_8": 38083.64445000001,
    "YEAR_9": 40495.27365000001,
    "YEAR_10": 42906.90285000001,
    "FIXED_AmplyFund": 310246.04812500003
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "AmplyFund",
    "No of Households": 247180,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 111231,
    "HH's Considered": 111.23100000000001,
    "contribution per HH": 556.1550000000001,
    "Assuming Revenue": 55.61550000000001,
    "Revenue Per month": 1668.4650000000004,
    "YEAR_1": 10861.707150000002,
    "YEAR_2": 23525.356500000005,
    "YEAR_3": 25927.94610000001,
    "YEAR_4": 28330.535700000004,
    "YEAR_5": 30733.125300000007,
    "YEAR_6": 33135.71490000001,
    "YEAR_7": 35538.30450000001,
    "YEAR_8": 37940.894100000005,
    "YEAR_9": 40343.48370000001,
    "YEAR_10": 42746.07330000001,
    "FIXED_AmplyFund": 309083.14125000004
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "AmplyFund",
    "No of Households": 241200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 108540,
    "HH's Considered": 108.54,
    "contribution per HH": 542.7,
    "Assuming Revenue": 54.27000000000001,
    "Revenue Per month": 1628.1000000000004,
    "YEAR_1": 10598.931000000002,
    "YEAR_2": 22956.210000000003,
    "YEAR_3": 25300.674000000003,
    "YEAR_4": 27645.138000000003,
    "YEAR_5": 29989.602000000006,
    "YEAR_6": 32334.066000000003,
    "YEAR_7": 34678.530000000006,
    "YEAR_8": 37022.994,
    "YEAR_9": 39367.458000000006,
    "YEAR_10": 41711.922000000006,
    "FIXED_AmplyFund": 301605.525
  },
  {
    "DMA": "Lafayette, LA",
    "PlatformName": "AmplyFund",
    "No of Households": 240150,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 108067.5,
    "HH's Considered": 108.0675,
    "contribution per HH": 540.3375,
    "Assuming Revenue": 54.03375,
    "Revenue Per month": 1621.0124999999998,
    "YEAR_1": 10552.791374999999,
    "YEAR_2": 22856.27625,
    "YEAR_3": 25190.534249999997,
    "YEAR_4": 27524.79225,
    "YEAR_5": 29859.050249999997,
    "YEAR_6": 32193.308249999995,
    "YEAR_7": 34527.566249999996,
    "YEAR_8": 36861.82425,
    "YEAR_9": 39196.08225,
    "YEAR_10": 41530.340249999994,
    "FIXED_AmplyFund": 300292.565625
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "AmplyFund",
    "No of Households": 235290,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 105880.5,
    "HH's Considered": 105.8805,
    "contribution per HH": 529.4025,
    "Assuming Revenue": 52.940250000000006,
    "Revenue Per month": 1588.2075000000002,
    "YEAR_1": 10339.230825000002,
    "YEAR_2": 22393.725750000005,
    "YEAR_3": 24680.744550000007,
    "YEAR_4": 26967.76335,
    "YEAR_5": 29254.782150000003,
    "YEAR_6": 31541.800950000008,
    "YEAR_7": 33828.81975,
    "YEAR_8": 36115.83855,
    "YEAR_9": 38402.857350000006,
    "YEAR_10": 40689.87615000001,
    "FIXED_AmplyFund": 294215.4393750001
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "AmplyFund",
    "No of Households": 232880,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 104796,
    "HH's Considered": 104.796,
    "contribution per HH": 523.98,
    "Assuming Revenue": 52.398,
    "Revenue Per month": 1571.94,
    "YEAR_1": 10233.3294,
    "YEAR_2": 22164.354,
    "YEAR_3": 24427.9476,
    "YEAR_4": 26691.541200000007,
    "YEAR_5": 28955.134800000003,
    "YEAR_6": 31218.7284,
    "YEAR_7": 33482.322,
    "YEAR_8": 35745.91560000001,
    "YEAR_9": 38009.5092,
    "YEAR_10": 40273.10280000001,
    "FIXED_AmplyFund": 291201.885
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "AmplyFund",
    "No of Households": 226710,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 102019.5,
    "HH's Considered": 102.01950000000001,
    "contribution per HH": 510.0975,
    "Assuming Revenue": 51.009750000000004,
    "Revenue Per month": 1530.2925,
    "YEAR_1": 9962.204174999999,
    "YEAR_2": 21577.12425,
    "YEAR_3": 23780.745450000002,
    "YEAR_4": 25984.366650000004,
    "YEAR_5": 28187.98785000001,
    "YEAR_6": 30391.609050000003,
    "YEAR_7": 32595.23025,
    "YEAR_8": 34798.851449999995,
    "YEAR_9": 37002.472649999996,
    "YEAR_10": 39206.09385,
    "FIXED_AmplyFund": 283486.685625
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "AmplyFund",
    "No of Households": 219540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 98793,
    "HH's Considered": 98.793,
    "contribution per HH": 493.96500000000003,
    "Assuming Revenue": 49.3965,
    "Revenue Per month": 1481.895,
    "YEAR_1": 9647.13645,
    "YEAR_2": 20894.7195,
    "YEAR_3": 23028.6483,
    "YEAR_4": 25162.5771,
    "YEAR_5": 27296.505900000004,
    "YEAR_6": 29430.434699999998,
    "YEAR_7": 31564.363500000003,
    "YEAR_8": 33698.2923,
    "YEAR_9": 35832.221099999995,
    "YEAR_10": 37966.149900000004,
    "FIXED_AmplyFund": 274521.04875
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "AmplyFund",
    "No of Households": 208490,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 93820.5,
    "HH's Considered": 93.8205,
    "contribution per HH": 469.10249999999996,
    "Assuming Revenue": 46.91025,
    "Revenue Per month": 1407.3075,
    "YEAR_1": 9161.571824999999,
    "YEAR_2": 19843.03575,
    "YEAR_3": 21869.558549999998,
    "YEAR_4": 23896.081349999997,
    "YEAR_5": 25922.604149999996,
    "YEAR_6": 27949.126949999998,
    "YEAR_7": 29975.64975,
    "YEAR_8": 32002.172549999996,
    "YEAR_9": 34028.695349999995,
    "YEAR_10": 36055.21815000001,
    "FIXED_AmplyFund": 260703.71437499998
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "AmplyFund",
    "No of Households": 197080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 88686,
    "HH's Considered": 88.686,
    "contribution per HH": 443.43000000000006,
    "Assuming Revenue": 44.34300000000001,
    "Revenue Per month": 1330.2900000000004,
    "YEAR_1": 8660.187900000003,
    "YEAR_2": 18757.089000000007,
    "YEAR_3": 20672.706600000005,
    "YEAR_4": 22588.324200000006,
    "YEAR_5": 24503.941800000004,
    "YEAR_6": 26419.559400000013,
    "YEAR_7": 28335.17700000001,
    "YEAR_8": 30250.79460000001,
    "YEAR_9": 32166.41220000001,
    "YEAR_10": 34082.02980000002,
    "FIXED_AmplyFund": 246436.2225000001
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "AmplyFund",
    "No of Households": 188980,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 85041,
    "HH's Considered": 85.041,
    "contribution per HH": 425.205,
    "Assuming Revenue": 42.5205,
    "Revenue Per month": 1275.615,
    "YEAR_1": 8304.25365,
    "YEAR_2": 17986.1715,
    "YEAR_3": 19823.057099999998,
    "YEAR_4": 21659.9427,
    "YEAR_5": 23496.828299999997,
    "YEAR_6": 25333.713900000002,
    "YEAR_7": 27170.599500000004,
    "YEAR_8": 29007.485099999998,
    "YEAR_9": 30844.3707,
    "YEAR_10": 32681.2563,
    "FIXED_AmplyFund": 236307.67875
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "AmplyFund",
    "No of Households": 188900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 85005,
    "HH's Considered": 85.005,
    "contribution per HH": 425.025,
    "Assuming Revenue": 42.5025,
    "Revenue Per month": 1275.0749999999998,
    "YEAR_1": 8300.738249999999,
    "YEAR_2": 17978.557499999995,
    "YEAR_3": 19814.6655,
    "YEAR_4": 21650.773499999996,
    "YEAR_5": 23486.881499999996,
    "YEAR_6": 25322.989499999996,
    "YEAR_7": 27159.097499999996,
    "YEAR_8": 28995.205499999996,
    "YEAR_9": 30831.313499999997,
    "YEAR_10": 32667.421499999997,
    "FIXED_AmplyFund": 236207.64375
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "AmplyFund",
    "No of Households": 188520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 84834,
    "HH's Considered": 84.834,
    "contribution per HH": 424.17,
    "Assuming Revenue": 42.417,
    "Revenue Per month": 1272.51,
    "YEAR_1": 8284.0401,
    "YEAR_2": 17942.391,
    "YEAR_3": 19774.8054,
    "YEAR_4": 21607.219800000003,
    "YEAR_5": 23439.6342,
    "YEAR_6": 25272.0486,
    "YEAR_7": 27104.463,
    "YEAR_8": 28936.877399999998,
    "YEAR_9": 30769.29179999999,
    "YEAR_10": 32601.706199999993,
    "FIXED_AmplyFund": 235732.47749999995
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "AmplyFund",
    "No of Households": 185470,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 83461.5,
    "HH's Considered": 83.4615,
    "contribution per HH": 417.3075,
    "Assuming Revenue": 41.73075,
    "Revenue Per month": 1251.9225000000001,
    "YEAR_1": 8150.015475000002,
    "YEAR_2": 17652.107250000005,
    "YEAR_3": 19454.875650000005,
    "YEAR_4": 21257.644050000006,
    "YEAR_5": 23060.412450000007,
    "YEAR_6": 24863.180850000008,
    "YEAR_7": 26665.94925000001,
    "YEAR_8": 28468.71765000001,
    "YEAR_9": 30271.48605000001,
    "YEAR_10": 32074.25445000001,
    "FIXED_AmplyFund": 231918.64312500006
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "AmplyFund",
    "No of Households": 183100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 82395,
    "HH's Considered": 82.395,
    "contribution per HH": 411.97499999999997,
    "Assuming Revenue": 41.1975,
    "Revenue Per month": 1235.925,
    "YEAR_1": 8045.87175,
    "YEAR_2": 17426.5425,
    "YEAR_3": 19206.274499999996,
    "YEAR_4": 20986.0065,
    "YEAR_5": 22765.7385,
    "YEAR_6": 24545.4705,
    "YEAR_7": 26325.202500000003,
    "YEAR_8": 28104.934499999996,
    "YEAR_9": 29884.6665,
    "YEAR_10": 31664.3985,
    "FIXED_AmplyFund": 228955.10625
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "AmplyFund",
    "No of Households": 181720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 81774,
    "HH's Considered": 81.774,
    "contribution per HH": 408.87,
    "Assuming Revenue": 40.887,
    "Revenue Per month": 1226.6100000000001,
    "YEAR_1": 7985.231100000002,
    "YEAR_2": 17295.201,
    "YEAR_3": 19061.519400000005,
    "YEAR_4": 20827.8378,
    "YEAR_5": 22594.1562,
    "YEAR_6": 24360.474600000005,
    "YEAR_7": 26126.793,
    "YEAR_8": 27893.1114,
    "YEAR_9": 29659.429800000005,
    "YEAR_10": 31425.7482,
    "FIXED_AmplyFund": 227229.50250000003
  },
  {
    "DMA": "Rockford",
    "PlatformName": "AmplyFund",
    "No of Households": 181440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 81648,
    "HH's Considered": 81.648,
    "contribution per HH": 408.24,
    "Assuming Revenue": 40.824000000000005,
    "Revenue Per month": 1224.7200000000003,
    "YEAR_1": 7972.927200000002,
    "YEAR_2": 17268.552000000007,
    "YEAR_3": 19032.148800000003,
    "YEAR_4": 20795.745600000002,
    "YEAR_5": 22559.342400000005,
    "YEAR_6": 24322.939200000004,
    "YEAR_7": 26086.536000000007,
    "YEAR_8": 27850.132800000007,
    "YEAR_9": 29613.729600000006,
    "YEAR_10": 31377.326400000005,
    "FIXED_AmplyFund": 226879.38000000006
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "AmplyFund",
    "No of Households": 174760,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 78642,
    "HH's Considered": 78.642,
    "contribution per HH": 393.21,
    "Assuming Revenue": 39.321,
    "Revenue Per month": 1179.6299999999999,
    "YEAR_1": 7679.391299999999,
    "YEAR_2": 16632.783,
    "YEAR_3": 18331.4502,
    "YEAR_4": 20030.117399999996,
    "YEAR_5": 21728.7846,
    "YEAR_6": 23427.4518,
    "YEAR_7": 25126.119,
    "YEAR_8": 26824.786199999995,
    "YEAR_9": 28523.4534,
    "YEAR_10": 30222.120600000002,
    "FIXED_AmplyFund": 218526.4575
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "AmplyFund",
    "No of Households": 173940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 78273,
    "HH's Considered": 78.273,
    "contribution per HH": 391.365,
    "Assuming Revenue": 39.136500000000005,
    "Revenue Per month": 1174.0950000000003,
    "YEAR_1": 7643.3584500000015,
    "YEAR_2": 16554.739500000003,
    "YEAR_3": 18245.436300000005,
    "YEAR_4": 19936.133100000003,
    "YEAR_5": 21626.829900000004,
    "YEAR_6": 23317.526700000006,
    "YEAR_7": 25008.223500000007,
    "YEAR_8": 26698.9203,
    "YEAR_9": 28389.617100000007,
    "YEAR_10": 30080.31390000001,
    "FIXED_AmplyFund": 217501.09875000003
  },
  {
    "DMA": "Topeka",
    "PlatformName": "AmplyFund",
    "No of Households": 173370,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 78016.5,
    "HH's Considered": 78.01650000000001,
    "contribution per HH": 390.08250000000004,
    "Assuming Revenue": 39.008250000000004,
    "Revenue Per month": 1170.2475000000002,
    "YEAR_1": 7618.311225,
    "YEAR_2": 16500.489750000004,
    "YEAR_3": 18185.646150000004,
    "YEAR_4": 19870.802550000004,
    "YEAR_5": 21555.95895,
    "YEAR_6": 23241.115350000004,
    "YEAR_7": 24926.271750000007,
    "YEAR_8": 26611.428150000003,
    "YEAR_9": 28296.584550000007,
    "YEAR_10": 29981.740950000007,
    "FIXED_AmplyFund": 216788.34937500002
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "AmplyFund",
    "No of Households": 171450,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 77152.5,
    "HH's Considered": 77.1525,
    "contribution per HH": 385.76250000000005,
    "Assuming Revenue": 38.57625000000001,
    "Revenue Per month": 1157.2875000000004,
    "YEAR_1": 7533.941625000002,
    "YEAR_2": 16317.753750000007,
    "YEAR_3": 17984.24775000001,
    "YEAR_4": 19650.74175000001,
    "YEAR_5": 21317.235750000007,
    "YEAR_6": 22983.72975000001,
    "YEAR_7": 24650.22375000001,
    "YEAR_8": 26316.71775000001,
    "YEAR_9": 27983.21175000001,
    "YEAR_10": 29649.70575000001,
    "FIXED_AmplyFund": 214387.50937500008
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "AmplyFund",
    "No of Households": 168960,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 76032,
    "HH's Considered": 76.032,
    "contribution per HH": 380.15999999999997,
    "Assuming Revenue": 38.016,
    "Revenue Per month": 1140.48,
    "YEAR_1": 7424.524799999999,
    "YEAR_2": 16080.768000000002,
    "YEAR_3": 17723.059200000003,
    "YEAR_4": 19365.3504,
    "YEAR_5": 21007.6416,
    "YEAR_6": 22649.932799999995,
    "YEAR_7": 24292.224000000002,
    "YEAR_8": 25934.5152,
    "YEAR_9": 27576.8064,
    "YEAR_10": 29219.0976,
    "FIXED_AmplyFund": 211273.92
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "AmplyFund",
    "No of Households": 168210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 75694.5,
    "HH's Considered": 75.6945,
    "contribution per HH": 378.4725,
    "Assuming Revenue": 37.84725,
    "Revenue Per month": 1135.4175,
    "YEAR_1": 7391.567925,
    "YEAR_2": 16009.38675,
    "YEAR_3": 17644.38795,
    "YEAR_4": 19279.38915,
    "YEAR_5": 20914.39035,
    "YEAR_6": 22549.391550000004,
    "YEAR_7": 24184.392749999995,
    "YEAR_8": 25819.39395,
    "YEAR_9": 27454.395150000004,
    "YEAR_10": 29089.396349999995,
    "FIXED_AmplyFund": 210336.091875
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "AmplyFund",
    "No of Households": 166190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 74785.5,
    "HH's Considered": 74.7855,
    "contribution per HH": 373.9275,
    "Assuming Revenue": 37.39275,
    "Revenue Per month": 1121.7825,
    "YEAR_1": 7302.804075,
    "YEAR_2": 15817.13325,
    "YEAR_3": 17432.500050000002,
    "YEAR_4": 19047.86685,
    "YEAR_5": 20663.23365,
    "YEAR_6": 22278.60045,
    "YEAR_7": 23893.96725,
    "YEAR_8": 25509.33405,
    "YEAR_9": 27124.700850000005,
    "YEAR_10": 28740.067650000005,
    "FIXED_AmplyFund": 207810.208125
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "AmplyFund",
    "No of Households": 161210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 72544.5,
    "HH's Considered": 72.5445,
    "contribution per HH": 362.72249999999997,
    "Assuming Revenue": 36.27225,
    "Revenue Per month": 1088.1675,
    "YEAR_1": 7083.9704249999995,
    "YEAR_2": 15343.161750000001,
    "YEAR_3": 16910.12295,
    "YEAR_4": 18477.08415,
    "YEAR_5": 20044.045350000004,
    "YEAR_6": 21611.006550000002,
    "YEAR_7": 23177.96775,
    "YEAR_8": 24744.928949999998,
    "YEAR_9": 26311.890150000003,
    "YEAR_10": 27878.851349999997,
    "FIXED_AmplyFund": 201583.02937499998
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "AmplyFund",
    "No of Households": 160200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 72090,
    "HH's Considered": 72.09,
    "contribution per HH": 360.45000000000005,
    "Assuming Revenue": 36.04500000000001,
    "Revenue Per month": 1081.3500000000004,
    "YEAR_1": 7039.588500000002,
    "YEAR_2": 15247.035000000007,
    "YEAR_3": 16804.179000000007,
    "YEAR_4": 18361.323000000008,
    "YEAR_5": 19918.467000000004,
    "YEAR_6": 21475.61100000001,
    "YEAR_7": 23032.75500000001,
    "YEAR_8": 24589.899000000005,
    "YEAR_9": 26147.043000000012,
    "YEAR_10": 27704.187000000005,
    "FIXED_AmplyFund": 200320.08750000005
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "AmplyFund",
    "No of Households": 155590,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 70015.5,
    "HH's Considered": 70.0155,
    "contribution per HH": 350.0775,
    "Assuming Revenue": 35.00775,
    "Revenue Per month": 1050.2325,
    "YEAR_1": 6837.013575000001,
    "YEAR_2": 14808.278250000001,
    "YEAR_3": 16320.613050000002,
    "YEAR_4": 17832.947849999997,
    "YEAR_5": 19345.282650000005,
    "YEAR_6": 20857.61745,
    "YEAR_7": 22369.952250000002,
    "YEAR_8": 23882.28705,
    "YEAR_9": 25394.621849999996,
    "YEAR_10": 26906.95665,
    "FIXED_AmplyFund": 194555.570625
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "AmplyFund",
    "No of Households": 155440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 69948,
    "HH's Considered": 69.94800000000001,
    "contribution per HH": 349.74,
    "Assuming Revenue": 34.974000000000004,
    "Revenue Per month": 1049.22,
    "YEAR_1": 6830.422200000001,
    "YEAR_2": 14794.002,
    "YEAR_3": 16304.8788,
    "YEAR_4": 17815.7556,
    "YEAR_5": 19326.632400000002,
    "YEAR_6": 20837.5092,
    "YEAR_7": 22348.386,
    "YEAR_8": 23859.262799999997,
    "YEAR_9": 25370.139600000002,
    "YEAR_10": 26881.0164,
    "FIXED_AmplyFund": 194368.00499999998
  },
  {
    "DMA": "Erie",
    "PlatformName": "AmplyFund",
    "No of Households": 152420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 68589,
    "HH's Considered": 68.589,
    "contribution per HH": 342.945,
    "Assuming Revenue": 34.2945,
    "Revenue Per month": 1028.835,
    "YEAR_1": 6697.7158500000005,
    "YEAR_2": 14506.5735,
    "YEAR_3": 15988.095900000002,
    "YEAR_4": 17469.6183,
    "YEAR_5": 18951.1407,
    "YEAR_6": 20432.6631,
    "YEAR_7": 21914.1855,
    "YEAR_8": 23395.7079,
    "YEAR_9": 24877.230300000007,
    "YEAR_10": 26358.7527,
    "FIXED_AmplyFund": 190591.68375000003
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "AmplyFund",
    "No of Households": 152190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 68485.5,
    "HH's Considered": 68.4855,
    "contribution per HH": 342.4275,
    "Assuming Revenue": 34.24275,
    "Revenue Per month": 1027.2825,
    "YEAR_1": 6687.609075,
    "YEAR_2": 14484.683249999996,
    "YEAR_3": 15963.970049999998,
    "YEAR_4": 17443.25685,
    "YEAR_5": 18922.54365,
    "YEAR_6": 20401.83045,
    "YEAR_7": 21881.11725,
    "YEAR_8": 23360.404049999997,
    "YEAR_9": 24839.690849999995,
    "YEAR_10": 26318.977649999997,
    "FIXED_AmplyFund": 190304.08312499998
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "AmplyFund",
    "No of Households": 151160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 68022,
    "HH's Considered": 68.022,
    "contribution per HH": 340.11,
    "Assuming Revenue": 34.011,
    "Revenue Per month": 1020.33,
    "YEAR_1": 6642.3483,
    "YEAR_2": 14386.653000000002,
    "YEAR_3": 15855.9282,
    "YEAR_4": 17325.203400000002,
    "YEAR_5": 18794.478600000002,
    "YEAR_6": 20263.7538,
    "YEAR_7": 21733.029000000002,
    "YEAR_8": 23202.3042,
    "YEAR_9": 24671.579400000002,
    "YEAR_10": 26140.8546,
    "FIXED_AmplyFund": 189016.13249999998
  },
  {
    "DMA": "Panama City",
    "PlatformName": "AmplyFund",
    "No of Households": 150700,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 5,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.01,
    "Holiday Months": 6,
    "No of Smart Households": 67815,
    "HH's Considered": 67.815,
    "contribution per HH": 339.075,
    "Assuming Revenue": 33.9075,
    "Revenue Per month": 1017.2249999999999,
    "YEAR_1": 6622.134749999999,
    "YEAR_2": 14342.872499999998,
    "YEAR_3": 15807.6765,
    "YEAR_4": 17272.480499999998,
    "YEAR_5": 18737.2845,
    "YEAR_6": 20202.0885,
    "YEAR_7": 21666.892499999998,
    "YEAR_8": 23131.6965,
    "YEAR_9": 24596.500499999995,
    "YEAR_10": 26061.304500000002,
    "FIXED_AmplyFund": 188440.93125
  },
  {
    "DMA": "New York",
    "PlatformName": "Live Weather",
    "Average Fund": 6703942.95,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 67039.4295,
    "Per Month": 5586.619125,
    "YEAR_1": 50572.752310060874,
    "YEAR_2": 68016.69678353624,
    "YEAR_3": 68686.82292081826,
    "YEAR_4": 69356.94905810026,
    "YEAR_5": 70027.07519538225,
    "YEAR_6": 70697.20133266426,
    "YEAR_7": 71367.32746994625,
    "YEAR_8": 72037.45360722824,
    "YEAR_9": 72707.57974451027,
    "YEAR_10": 73377.70588179225,
    "FIXED_LiveWeather": 686847.5643040391
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Live Weather",
    "Average Fund": 3186421.325,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 31864.213250000004,
    "Per Month": 2655.351104166667,
    "YEAR_1": 24037.510108095565,
    "YEAR_2": 32328.71381865188,
    "YEAR_3": 32647.22849429888,
    "YEAR_4": 32965.743169945876,
    "YEAR_5": 33284.25784559288,
    "YEAR_6": 33602.77252123988,
    "YEAR_7": 33921.28719688688,
    "YEAR_8": 34239.80187253388,
    "YEAR_9": 34558.31654818088,
    "YEAR_10": 34876.83122382788,
    "FIXED_LiveWeather": 326462.4627992545
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Live Weather",
    "Average Fund": 1565621.05,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 15656.210500000001,
    "Per Month": 1304.6842083333333,
    "YEAR_1": 11810.626397569125,
    "YEAR_2": 15884.43890856375,
    "YEAR_3": 16040.938388721752,
    "YEAR_4": 16197.43786887975,
    "YEAR_5": 16353.937349037747,
    "YEAR_6": 16510.43682919575,
    "YEAR_7": 16666.93630935375,
    "YEAR_8": 16823.43578951175,
    "YEAR_9": 16979.93526966975,
    "YEAR_10": 17136.434749827746,
    "FIXED_LiveWeather": 160404.55786033088
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Live Weather",
    "Average Fund": 1418492.988,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 14184.92988,
    "Per Month": 1182.07749,
    "YEAR_1": 10700.73165459771,
    "YEAR_2": 14391.710695325699,
    "YEAR_3": 14533.503254406176,
    "YEAR_4": 14675.295813486657,
    "YEAR_5": 14817.088372567137,
    "YEAR_6": 14958.880931647618,
    "YEAR_7": 15100.673490728097,
    "YEAR_8": 15242.466049808578,
    "YEAR_9": 15384.258608889058,
    "YEAR_10": 15526.051167969537,
    "FIXED_LiveWeather": 145330.66003942624
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Live Weather",
    "Average Fund": 8452063.1,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 84520.631,
    "Per Month": 7043.385916666666,
    "YEAR_1": 63760.103099520755,
    "YEAR_2": 85752.73049840248,
    "YEAR_3": 86597.59872587849,
    "YEAR_4": 87442.46695335451,
    "YEAR_5": 88287.33518083049,
    "YEAR_6": 89132.2034083065,
    "YEAR_7": 89977.07163578249,
    "YEAR_8": 90821.93986325848,
    "YEAR_9": 91666.8080907345,
    "YEAR_10": 92511.67631821049,
    "FIXED_LiveWeather": 865949.9337742792
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Live Weather",
    "Average Fund": 323909.475,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 3239.0947499999997,
    "Per Month": 269.9245625,
    "YEAR_1": 2443.4864336154374,
    "YEAR_2": 3286.3126537181242,
    "YEAR_3": 3318.690644839125,
    "YEAR_4": 3351.068635960125,
    "YEAR_5": 3383.4466270811245,
    "YEAR_6": 3415.824618202125,
    "YEAR_7": 3448.202609323125,
    "YEAR_8": 3480.5806004441247,
    "YEAR_9": 3512.9585915651246,
    "YEAR_10": 3545.336582686125,
    "FIXED_LiveWeather": 33185.907997434566
  },
  {
    "DMA": "Houston",
    "PlatformName": "Live Weather",
    "Average Fund": 4226031.55,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 42260.3155,
    "Per Month": 3521.692958333333,
    "YEAR_1": 31880.051549760377,
    "YEAR_2": 42876.36524920124,
    "YEAR_3": 43298.799362939244,
    "YEAR_4": 43721.233476677255,
    "YEAR_5": 44143.66759041524,
    "YEAR_6": 44566.10170415325,
    "YEAR_7": 44988.53581789124,
    "YEAR_8": 45410.96993162924,
    "YEAR_9": 45833.40404536725,
    "YEAR_10": 46255.838159105246,
    "FIXED_LiveWeather": 432974.9668871396
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Live Weather",
    "Average Fund": 19600112.14,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 196001.1214,
    "Per Month": 16333.426783333334,
    "YEAR_1": 147858.00295416254,
    "YEAR_2": 198858.3277472085,
    "YEAR_3": 200817.55495672292,
    "YEAR_4": 202776.7821662373,
    "YEAR_5": 204736.00937575172,
    "YEAR_6": 206695.2365852661,
    "YEAR_7": 208654.46379478052,
    "YEAR_8": 210613.6910042949,
    "YEAR_9": 212572.91821380935,
    "YEAR_10": 214532.1454233237,
    "FIXED_LiveWeather": 2008115.1322215574
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Live Weather",
    "Average Fund": 5741110.131,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 57411.10131,
    "Per Month": 4784.2584425,
    "YEAR_1": 43309.39908130395,
    "YEAR_2": 58248.01163934652,
    "YEAR_3": 58821.89300804128,
    "YEAR_4": 59395.77437673604,
    "YEAR_5": 59969.655745430806,
    "YEAR_6": 60543.53711412556,
    "YEAR_7": 61117.418482820314,
    "YEAR_8": 61691.29985151508,
    "YEAR_9": 62265.18122020984,
    "YEAR_10": 62839.062588904606,
    "FIXED_LiveWeather": 588201.233108434
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Live Weather",
    "Average Fund": 9559263.958,
    "% of funds used": 0.01,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 0.000833,
    "Holiday Months": 3,
    "Funds for Nexus": 95592.63958,
    "Per Month": 7966.053298333333,
    "YEAR_1": 72112.53019604323,
    "YEAR_2": 96986.14128347745,
    "YEAR_3": 97941.68530871913,
    "YEAR_4": 98897.2293339608,
    "YEAR_5": 99852.7733592025,
    "YEAR_6": 100808.31738444416,
    "YEAR_7": 101763.86140968584,
    "YEAR_8": 102719.40543492752,
    "YEAR_9": 103674.94946016921,
    "YEAR_10": 104630.4934854109,
    "FIXED_LiveWeather": 979387.3866560407
  },
    {
    "DMA": "New York",
    "PlatformName": "ImpressIO",
    "Grand Total": 83472,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 834.72,
    "Metered Revenue": 41736,
    "YEAR_1": 259291.99511999998,
    "YEAR_2": 537380.2152,
    "YEAR_3": 562441.8484799999,
    "YEAR_4": 587503.4817599999,
    "YEAR_5": 612565.11504,
    "YEAR_6": 637626.7483199999,
    "YEAR_7": 662688.3816000001,
    "YEAR_8": 687750.0148799999,
    "YEAR_9": 712811.64816,
    "YEAR_10": 737873.2814399998,
    "METERED_ImpressIO": 5997932.7299999995
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "ImpressIO",
    "Grand Total": 67568,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 675.6800000000001,
    "Metered Revenue": 33784,
    "YEAR_1": 209888.84328,
    "YEAR_2": 434992.64879999997,
    "YEAR_3": 455279.26512,
    "YEAR_4": 475565.88144,
    "YEAR_5": 495852.49776,
    "YEAR_6": 516139.11407999997,
    "YEAR_7": 536425.7304,
    "YEAR_8": 556712.34672,
    "YEAR_9": 576998.96304,
    "YEAR_10": 597285.5793599999,
    "METERED_ImpressIO": 4855140.869999999
  },
  {
    "DMA": "Chicago",
    "PlatformName": "ImpressIO",
    "Grand Total": 41582,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 415.82,
    "Metered Revenue": 20791,
    "YEAR_1": 129167.62197,
    "YEAR_2": 267698.6787,
    "YEAR_3": 280183.25838,
    "YEAR_4": 292667.83806000004,
    "YEAR_5": 305152.41774,
    "YEAR_6": 317636.9974199999,
    "YEAR_7": 330121.57710000005,
    "YEAR_8": 342606.15678,
    "YEAR_9": 355090.73646000004,
    "YEAR_10": 367575.31614,
    "METERED_ImpressIO": 2987900.59875
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "ImpressIO",
    "Grand Total": 35037,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 350.37,
    "Metered Revenue": 17518.5,
    "YEAR_1": 108836.659395,
    "YEAR_2": 225562.95045,
    "YEAR_3": 236082.45933,
    "YEAR_4": 246601.96820999996,
    "YEAR_5": 257121.47708999997,
    "YEAR_6": 267640.98597,
    "YEAR_7": 278160.49485,
    "YEAR_8": 288680.00373,
    "YEAR_9": 299199.51261,
    "YEAR_10": 309719.02148999996,
    "METERED_ImpressIO": 2517605.533125
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "ImpressIO",
    "Grand Total": 44114,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 441.14,
    "Metered Revenue": 22057,
    "YEAR_1": 137032.86218999999,
    "YEAR_2": 283999.3149,
    "YEAR_3": 297244.10225999996,
    "YEAR_4": 310488.88962,
    "YEAR_5": 323733.67698,
    "YEAR_6": 336978.46434,
    "YEAR_7": 350223.2517,
    "YEAR_8": 363468.03906,
    "YEAR_9": 376712.82641999994,
    "YEAR_10": 389957.61378,
    "METERED_ImpressIO": 3169839.04125
  },
  {
    "DMA": "Houston",
    "PlatformName": "ImpressIO",
    "Grand Total": 34921,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 349.21,
    "Metered Revenue": 17460.5,
    "YEAR_1": 108476.32453499999,
    "YEAR_2": 224816.15985,
    "YEAR_3": 235300.84089000002,
    "YEAR_4": 245785.52193,
    "YEAR_5": 256270.20296999998,
    "YEAR_6": 266754.88401000004,
    "YEAR_7": 277239.56505,
    "YEAR_8": 287724.24609,
    "YEAR_9": 298208.92713,
    "YEAR_10": 308693.60817,
    "METERED_ImpressIO": 2509270.280625
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "ImpressIO",
    "Grand Total": 31611,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 316.11,
    "Metered Revenue": 15805.5,
    "YEAR_1": 98194.35568499999,
    "YEAR_2": 203506.87634999998,
    "YEAR_3": 212997.76299000002,
    "YEAR_4": 222488.64963,
    "YEAR_5": 231979.53627000004,
    "YEAR_6": 241470.42291,
    "YEAR_7": 250961.30955,
    "YEAR_8": 260452.19619,
    "YEAR_9": 269943.08282999997,
    "YEAR_10": 279433.96947,
    "METERED_ImpressIO": 2271428.161875
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "ImpressIO",
    "Grand Total": 30886,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 308.86,
    "Metered Revenue": 15443,
    "YEAR_1": 95942.26281,
    "YEAR_2": 198839.43510000003,
    "YEAR_3": 208112.64774000007,
    "YEAR_4": 217385.86037999997,
    "YEAR_5": 226659.07302,
    "YEAR_6": 235932.28565999996,
    "YEAR_7": 245205.49829999995,
    "YEAR_8": 254478.71094,
    "YEAR_9": 263751.92358,
    "YEAR_10": 273025.13622000004,
    "METERED_ImpressIO": 2219332.8337499998
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "ImpressIO",
    "Grand Total": 37269,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "Minimum Charge for Digital Signage Ads": 50,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 372.69,
    "Metered Revenue": 18634.5,
    "YEAR_1": 115769.999115,
    "YEAR_2": 239932.23165000003,
    "YEAR_3": 251121.87621000002,
    "YEAR_4": 262311.52077,
    "YEAR_5": 273501.16533,
    "YEAR_6": 284690.80989000003,
    "YEAR_7": 295880.45444999996,
    "YEAR_8": 307070.09901000006,
    "YEAR_9": 318259.74357,
    "YEAR_10": 329449.38813000004,
    "METERED_ImpressIO": 2677987.288125
  },
  {
    "DMA": "New York",
    "PlatformName": "ImpressIO",
    "Grand Total": 83472,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 834.72,
    "Ad Revenue": 21285.360000000004,
    "YEAR_1": 132238.9175112,
    "YEAR_2": 274063.9097520001,
    "YEAR_3": 286845.34272480005,
    "YEAR_4": 299626.77569760004,
    "YEAR_5": 312408.2086704001,
    "YEAR_6": 325189.64164320007,
    "YEAR_7": 337971.07461600006,
    "YEAR_8": 350752.50758880004,
    "YEAR_9": 363533.94056160003,
    "YEAR_10": 376315.37353440013,
    "AD_ImpressIO": 3058945.6923
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "ImpressIO",
    "Grand Total": 67568,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 675.6800000000001,
    "Ad Revenue": 17229.840000000004,
    "YEAR_1": 107043.31007280003,
    "YEAR_2": 221846.25088800007,
    "YEAR_3": 232192.42521120008,
    "YEAR_4": 242538.59953440004,
    "YEAR_5": 252884.77385760003,
    "YEAR_6": 263230.9481808001,
    "YEAR_7": 273577.1225040001,
    "YEAR_8": 283923.2968272001,
    "YEAR_9": 294269.47115040006,
    "YEAR_10": 304615.6454736001,
    "AD_ImpressIO": 2476121.8437000006
  },
  {
    "DMA": "Chicago",
    "PlatformName": "ImpressIO",
    "Grand Total": 41582,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 415.82,
    "Ad Revenue": 10603.41,
    "YEAR_1": 65875.4872047,
    "YEAR_2": 136526.326137,
    "YEAR_3": 142893.4617738,
    "YEAR_4": 149260.59741059996,
    "YEAR_5": 155627.73304740002,
    "YEAR_6": 161994.8686842,
    "YEAR_7": 168362.00432100001,
    "YEAR_8": 174729.1399578,
    "YEAR_9": 181096.2755946,
    "YEAR_10": 187463.41123140004,
    "AD_ImpressIO": 1523829.3053625
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "ImpressIO",
    "Grand Total": 35037,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 350.37,
    "Ad Revenue": 8934.435,
    "YEAR_1": 55506.696291449996,
    "YEAR_2": 115037.10472949999,
    "YEAR_3": 120402.05425829999,
    "YEAR_4": 125767.00378709997,
    "YEAR_5": 131131.95331589997,
    "YEAR_6": 136496.9028447,
    "YEAR_7": 141861.8523735,
    "YEAR_8": 147226.80190229998,
    "YEAR_9": 152591.75143109998,
    "YEAR_10": 157956.7009599,
    "AD_ImpressIO": 1283978.8218937498
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "ImpressIO",
    "Grand Total": 44114,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 441.14,
    "Ad Revenue": 11249.07,
    "YEAR_1": 69886.75971690001,
    "YEAR_2": 144839.65059900002,
    "YEAR_3": 151594.4921526,
    "YEAR_4": 158349.3337062,
    "YEAR_5": 165104.1752598,
    "YEAR_6": 171859.0168134,
    "YEAR_7": 178613.85836699998,
    "YEAR_8": 185368.69992060002,
    "YEAR_9": 192123.54147419997,
    "YEAR_10": 198878.3830278,
    "AD_ImpressIO": 1616617.9110375
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "ImpressIO",
    "Grand Total": 34921,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 349.21,
    "Ad Revenue": 8904.855,
    "YEAR_1": 55322.92551284999,
    "YEAR_2": 114656.2415235,
    "YEAR_3": 120003.42885389998,
    "YEAR_4": 125350.61618430002,
    "YEAR_5": 130697.8035147,
    "YEAR_6": 136044.9908451,
    "YEAR_7": 141392.1781755,
    "YEAR_8": 146739.3655059,
    "YEAR_9": 152086.5528363,
    "YEAR_10": 157433.74016669998,
    "AD_ImpressIO": 1279727.84311875
  },
  {
    "DMA": "Houston",
    "PlatformName": "ImpressIO",
    "Grand Total": 39085,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 390.85,
    "Ad Revenue": 9966.675000000001,
    "YEAR_1": 61919.66277225001,
    "YEAR_2": 128327.9172975,
    "YEAR_3": 134312.7063015,
    "YEAR_4": 140297.49530550002,
    "YEAR_5": 146282.2843095,
    "YEAR_6": 152267.07331350003,
    "YEAR_7": 158251.86231750002,
    "YEAR_8": 164236.65132150002,
    "YEAR_9": 170221.4403255,
    "YEAR_10": 176206.2293295,
    "AD_ImpressIO": 1432323.32259375
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "ImpressIO",
    "Grand Total": 31611,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 316.11,
    "Ad Revenue": 8060.805000000002,
    "YEAR_1": 50079.12139935001,
    "YEAR_2": 103788.50693850002,
    "YEAR_3": 108628.85912490003,
    "YEAR_4": 113469.21131130002,
    "YEAR_5": 118309.56349770003,
    "YEAR_6": 123149.91568410004,
    "YEAR_7": 127990.26787050004,
    "YEAR_8": 132830.62005690005,
    "YEAR_9": 137670.97224330003,
    "YEAR_10": 142511.32442970003,
    "AD_ImpressIO": 1158428.3625562503
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "ImpressIO",
    "Grand Total": 30886,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 308.86,
    "Ad Revenue": 7875.93,
    "YEAR_1": 48930.5540331,
    "YEAR_2": 101408.11190100001,
    "YEAR_3": 106137.4503474,
    "YEAR_4": 110866.78879380002,
    "YEAR_5": 115596.12724019997,
    "YEAR_6": 120325.46568660002,
    "YEAR_7": 125054.80413300001,
    "YEAR_8": 129784.1425794,
    "YEAR_9": 134513.48102580002,
    "YEAR_10": 139242.81947219998,
    "AD_ImpressIO": 1131859.7452125
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "ImpressIO",
    "Grand Total": 37269,
    "% Capture for Nexus": 0.01,
    "DMA Consdered": "Y",
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 displays": 10,
    "Min number of people who watched the ad": 100,
    "No of Ads per Display": 1,
    "Monthly Adoption Growth Rate": 0.00417,
    "Holiday Months": 6,
    "TAM": 372.69,
    "Ad Revenue": 9503.595,
    "YEAR_1": 59042.699548649995,
    "YEAR_2": 122365.4381415,
    "YEAR_3": 128072.1568671,
    "YEAR_4": 133778.87559269997,
    "YEAR_5": 139485.5943183,
    "YEAR_6": 145192.31304389998,
    "YEAR_7": 150899.0317695,
    "YEAR_8": 156605.7504951,
    "YEAR_9": 162312.46922069998,
    "YEAR_10": 168019.18794629996,
    "AD_ImpressIO": 1365773.51694375
  },
  {
    "DMA": "New York",
    "PlatformName": "Museo",
    "No of Households": 7726580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 3476961,
    "HH's Considered": 3476.9610000000002,
    "contribution per HH": 69504.45039,
    "Assuming Revenue": 6950.445039,
    "Revenue Per month": 208513.35117,
    "YEAR_1": 1388261.0407547432,
    "YEAR_2": 3067022.88235953,
    "YEAR_3": 3454357.2834929223,
    "YEAR_4": 3841691.684626314,
    "YEAR_5": 4229026.085759706,
    "YEAR_6": 4616360.486893099,
    "YEAR_7": 5003694.88802649,
    "YEAR_8": 5391029.289159882,
    "YEAR_9": 5778363.690293275,
    "YEAR_10": 6165698.091426667,
    "SUBSCRIPTION_Museo": 42935505.42279263
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Museo",
    "No of Households": 5838090,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 2627140.5,
    "HH's Considered": 2627.1405,
    "contribution per HH": 52516.538595,
    "Assuming Revenue": 5251.6538595,
    "Revenue Per month": 157549.615785,
    "YEAR_1": 1048949.5869349516,
    "YEAR_2": 2317397.298581565,
    "YEAR_3": 2610061.464863781,
    "YEAR_4": 2902725.631145997,
    "YEAR_5": 3195389.7974282126,
    "YEAR_6": 3488053.963710429,
    "YEAR_7": 3780718.1299926457,
    "YEAR_8": 4073382.2962748604,
    "YEAR_9": 4366046.462557077,
    "YEAR_10": 4658710.628839293,
    "SUBSCRIPTION_Museo": 32441435.26032881
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Museo",
    "No of Households": 3624820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 1631169,
    "HH's Considered": 1631.169,
    "contribution per HH": 32607.06831,
    "Assuming Revenue": 3260.706831,
    "Revenue Per month": 97821.20492999999,
    "YEAR_1": 651283.8003034471,
    "YEAR_2": 1438852.10331537,
    "YEAR_3": 1620564.773593338,
    "YEAR_4": 1802277.443871306,
    "YEAR_5": 1983990.114149274,
    "YEAR_6": 2165702.7844272424,
    "YEAR_7": 2347415.4547052104,
    "YEAR_8": 2529128.1249831785,
    "YEAR_9": 2710840.7952611456,
    "YEAR_10": 2892553.4655391145,
    "SUBSCRIPTION_Museo": 20142608.860148624
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Museo",
    "No of Households": 3108960,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 1399032,
    "HH's Considered": 1399.032,
    "contribution per HH": 27966.649679999995,
    "Assuming Revenue": 2796.6649679999996,
    "Revenue Per month": 83899.94903999999,
    "YEAR_1": 558597.470713416,
    "YEAR_2": 1234084.3504293598,
    "YEAR_3": 1389936.895766064,
    "YEAR_4": 1545789.4411027678,
    "YEAR_5": 1701641.9864394716,
    "YEAR_6": 1857494.5317761756,
    "YEAR_7": 2013347.07711288,
    "YEAR_8": 2169199.6224495843,
    "YEAR_9": 2325052.1677862876,
    "YEAR_10": 2480904.713122992,
    "SUBSCRIPTION_Museo": 17276048.256699
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Museo",
    "No of Households": 3041540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 1368693,
    "HH's Considered": 1368.693,
    "contribution per HH": 27360.173069999997,
    "Assuming Revenue": 2736.017307,
    "Revenue Per month": 82080.51921,
    "YEAR_1": 546483.888848259,
    "YEAR_2": 1207322.35705989,
    "YEAR_3": 1359795.1295443862,
    "YEAR_4": 1512267.9020288817,
    "YEAR_5": 1664740.6745133782,
    "YEAR_6": 1817213.446997874,
    "YEAR_7": 1969686.2194823697,
    "YEAR_8": 2122158.991966866,
    "YEAR_9": 2274631.764451362,
    "YEAR_10": 2427104.5369358575,
    "SUBSCRIPTION_Museo": 16901404.911829125
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Museo",
    "No of Households": 2679850,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 1205932.5,
    "HH's Considered": 1205.9325000000001,
    "contribution per HH": 24106.590675,
    "Assuming Revenue": 2410.6590675,
    "Revenue Per month": 72319.77202500001,
    "YEAR_1": 481497.81016524753,
    "YEAR_2": 1063751.5267157252,
    "YEAR_3": 1198092.7352293653,
    "YEAR_4": 1332433.9437430054,
    "YEAR_5": 1466775.1522566453,
    "YEAR_6": 1601116.3607702851,
    "YEAR_7": 1735457.5692839252,
    "YEAR_8": 1869798.777797565,
    "YEAR_9": 2004139.986311205,
    "YEAR_10": 2138481.194824845,
    "SUBSCRIPTION_Museo": 14891545.057097813
  },
  {
    "DMA": "Houston",
    "PlatformName": "Museo",
    "No of Households": 2666330,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 1199848.5,
    "HH's Considered": 1199.8485,
    "contribution per HH": 23984.971515,
    "Assuming Revenue": 2398.4971515,
    "Revenue Per month": 71954.914545,
    "YEAR_1": 479068.6255491556,
    "YEAR_2": 1058384.838042405,
    "YEAR_3": 1192048.2873011972,
    "YEAR_4": 1325711.736559989,
    "YEAR_5": 1459375.1858187811,
    "YEAR_6": 1593038.6350775731,
    "YEAR_7": 1726702.0843363653,
    "YEAR_8": 1860365.5335951573,
    "YEAR_9": 1994028.9828539493,
    "YEAR_10": 2127692.4321127413,
    "SUBSCRIPTION_Museo": 14816416.341247313
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Museo",
    "No of Households": 2617350,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 1177807.5,
    "HH's Considered": 1177.8075000000001,
    "contribution per HH": 23544.371925,
    "Assuming Revenue": 2354.4371925,
    "Revenue Per month": 70633.115775,
    "YEAR_1": 470268.2215183725,
    "YEAR_2": 1038942.499934475,
    "YEAR_3": 1170150.575798115,
    "YEAR_4": 1301358.651661755,
    "YEAR_5": 1432566.727525395,
    "YEAR_6": 1563774.8033890352,
    "YEAR_7": 1694982.879252675,
    "YEAR_8": 1826190.9551163148,
    "YEAR_9": 1957399.030979955,
    "YEAR_10": 2088607.106843595,
    "SUBSCRIPTION_Museo": 14544241.452019688
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Museo",
    "No of Households": 2596190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 1168285.5,
    "HH's Considered": 1168.2855,
    "contribution per HH": 23354.027144999996,
    "Assuming Revenue": 2335.4027144999995,
    "Revenue Per month": 70062.08143499999,
    "YEAR_1": 466466.3319860864,
    "YEAR_2": 1030543.1558274147,
    "YEAR_3": 1160690.4783010709,
    "YEAR_4": 1290837.8007747268,
    "YEAR_5": 1420985.123248383,
    "YEAR_6": 1551132.4457220386,
    "YEAR_7": 1681279.7681956948,
    "YEAR_8": 1811427.0906693505,
    "YEAR_9": 1941574.4131430066,
    "YEAR_10": 2071721.7356166625,
    "SUBSCRIPTION_Museo": 14426658.343484435
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Museo",
    "No of Households": 2593210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 1166944.5,
    "HH's Considered": 1166.9445,
    "contribution per HH": 23327.220555,
    "Assuming Revenue": 2332.7220555,
    "Revenue Per month": 69981.66166499999,
    "YEAR_1": 465930.90519940347,
    "YEAR_2": 1029360.2614304848,
    "YEAR_3": 1159358.1961393892,
    "YEAR_4": 1289356.130848293,
    "YEAR_5": 1419354.0655571967,
    "YEAR_6": 1549352.000266101,
    "YEAR_7": 1679349.934975005,
    "YEAR_8": 1809347.8696839088,
    "YEAR_9": 1939345.8043928128,
    "YEAR_10": 2069343.7391017168,
    "SUBSCRIPTION_Museo": 14410098.907594312
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Museo",
    "No of Households": 2138870,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 962491.5,
    "HH's Considered": 962.4915,
    "contribution per HH": 19240.205084999998,
    "Assuming Revenue": 1924.0205084999998,
    "Revenue Per month": 57720.615255,
    "YEAR_1": 384298.08430626447,
    "YEAR_2": 849012.529785795,
    "YEAR_3": 956234.344683483,
    "YEAR_4": 1063456.1595811709,
    "YEAR_5": 1170677.974478859,
    "YEAR_6": 1277899.789376547,
    "YEAR_7": 1385121.6042742347,
    "YEAR_8": 1492343.419171923,
    "YEAR_9": 1599565.234069611,
    "YEAR_10": 1706787.048967299,
    "SUBSCRIPTION_Museo": 11885396.188695185
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Museo",
    "No of Households": 2116440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 952398,
    "HH's Considered": 952.398,
    "contribution per HH": 19038.436019999997,
    "Assuming Revenue": 1903.843602,
    "Revenue Per month": 57115.308059999996,
    "YEAR_1": 380268.009532674,
    "YEAR_2": 840109.06625454,
    "YEAR_3": 946206.4625067959,
    "YEAR_4": 1052303.8587590521,
    "YEAR_5": 1158401.2550113078,
    "YEAR_6": 1264498.6512635641,
    "YEAR_7": 1370596.0475158198,
    "YEAR_8": 1476693.443768076,
    "YEAR_9": 1582790.8400203318,
    "YEAR_10": 1688888.2362725877,
    "SUBSCRIPTION_Museo": 11760755.870904751
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Museo",
    "No of Households": 2068720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 930924,
    "HH's Considered": 930.924,
    "contribution per HH": 18609.170759999997,
    "Assuming Revenue": 1860.9170759999997,
    "Revenue Per month": 55827.512279999995,
    "YEAR_1": 371693.99400901195,
    "YEAR_2": 821166.8781265197,
    "YEAR_3": 924872.0649378479,
    "YEAR_4": 1028577.2517491758,
    "YEAR_5": 1132282.438560504,
    "YEAR_6": 1235987.625371832,
    "YEAR_7": 1339692.8121831599,
    "YEAR_8": 1443397.998994488,
    "YEAR_9": 1547103.185805816,
    "YEAR_10": 1650808.3726171434,
    "SUBSCRIPTION_Museo": 11495582.6223555
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Museo",
    "No of Households": 1937250,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 871762.5,
    "HH's Considered": 871.7625,
    "contribution per HH": 17426.532375,
    "Assuming Revenue": 1742.6532375,
    "Revenue Per month": 52279.597125,
    "YEAR_1": 348072.32969853753,
    "YEAR_2": 768980.5941116251,
    "YEAR_3": 866095.173731025,
    "YEAR_4": 963209.7533504249,
    "YEAR_5": 1060324.332969825,
    "YEAR_6": 1157438.912589225,
    "YEAR_7": 1254553.492208625,
    "YEAR_8": 1351668.071828025,
    "YEAR_9": 1448782.6514474254,
    "YEAR_10": 1545897.2310668249,
    "SUBSCRIPTION_Museo": 10765022.543001562
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Museo",
    "No of Households": 1839480,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 827766,
    "HH's Considered": 827.766,
    "contribution per HH": 16547.042339999996,
    "Assuming Revenue": 1654.7042339999998,
    "Revenue Per month": 49641.12701999999,
    "YEAR_1": 330505.6595864579,
    "YEAR_2": 730171.3373371802,
    "YEAR_3": 822384.694889532,
    "YEAR_4": 914598.0524418836,
    "YEAR_5": 1006811.4099942361,
    "YEAR_6": 1099024.7675465879,
    "YEAR_7": 1191238.1250989398,
    "YEAR_8": 1283451.482651292,
    "YEAR_9": 1375664.8402036438,
    "YEAR_10": 1467878.1977559957,
    "SUBSCRIPTION_Museo": 10221728.56750575
  },
  {
    "DMA": "Denver",
    "PlatformName": "Museo",
    "No of Households": 1792540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 806643,
    "HH's Considered": 806.643,
    "contribution per HH": 16124.79357,
    "Assuming Revenue": 1612.4793570000002,
    "Revenue Per month": 48374.380710000005,
    "YEAR_1": 322071.7893291091,
    "YEAR_2": 711538.7658633902,
    "YEAR_3": 801399.0154702861,
    "YEAR_4": 891259.2650771822,
    "YEAR_5": 981119.5146840783,
    "YEAR_6": 1070979.7642909742,
    "YEAR_7": 1160840.0138978702,
    "YEAR_8": 1250700.2635047662,
    "YEAR_9": 1340560.5131116621,
    "YEAR_10": 1430420.762718558,
    "SUBSCRIPTION_Museo": 9960889.667947877
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Museo",
    "No of Households": 1775140,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 798813,
    "HH's Considered": 798.813,
    "contribution per HH": 15968.271869999999,
    "Assuming Revenue": 1596.8271869999999,
    "Revenue Per month": 47904.81561,
    "YEAR_1": 318945.471849819,
    "YEAR_2": 704631.93280749,
    "YEAR_3": 793619.918284626,
    "YEAR_4": 882607.9037617621,
    "YEAR_5": 971595.889238898,
    "YEAR_6": 1060583.8747160342,
    "YEAR_7": 1149571.86019317,
    "YEAR_8": 1238559.8456703061,
    "YEAR_9": 1327547.8311474419,
    "YEAR_10": 1416535.8166245779,
    "SUBSCRIPTION_Museo": 9864200.344294125
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Museo",
    "No of Households": 1720970,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 774436.5,
    "HH's Considered": 774.4365,
    "contribution per HH": 15480.985635,
    "Assuming Revenue": 1548.0985635,
    "Revenue Per month": 46442.956905,
    "YEAR_1": 309212.5627777995,
    "YEAR_2": 683129.4531156449,
    "YEAR_3": 769401.889862373,
    "YEAR_4": 855674.3266091011,
    "YEAR_5": 941946.7633558292,
    "YEAR_6": 1028219.200102557,
    "YEAR_7": 1114491.6368492849,
    "YEAR_8": 1200764.0735960128,
    "YEAR_9": 1287036.510342741,
    "YEAR_10": 1373308.9470894693,
    "SUBSCRIPTION_Museo": 9563185.363700813
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Museo",
    "No of Households": 1552420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 698589,
    "HH's Considered": 698.589,
    "contribution per HH": 13964.79411,
    "Assuming Revenue": 1396.4794110000003,
    "Revenue Per month": 41894.38233000001,
    "YEAR_1": 278928.60811490705,
    "YEAR_2": 616224.4696919702,
    "YEAR_3": 694047.4743081781,
    "YEAR_4": 771870.4789243862,
    "YEAR_5": 849693.4835405942,
    "YEAR_6": 927516.4881568023,
    "YEAR_7": 1005339.4927730102,
    "YEAR_8": 1083162.4973892183,
    "YEAR_9": 1160985.5020054262,
    "YEAR_10": 1238808.5066216344,
    "SUBSCRIPTION_Museo": 8626577.001526127
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Museo",
    "No of Households": 1502080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 675936,
    "HH's Considered": 675.936,
    "contribution per HH": 13511.96064,
    "Assuming Revenue": 1351.196064,
    "Revenue Per month": 40535.88192,
    "YEAR_1": 269883.848235168,
    "YEAR_2": 596242.28716128,
    "YEAR_3": 671541.741415872,
    "YEAR_4": 746841.1956704641,
    "YEAR_5": 822140.649925056,
    "YEAR_6": 897440.104179648,
    "YEAR_7": 972739.55843424,
    "YEAR_8": 1048039.0126888321,
    "YEAR_9": 1123338.466943424,
    "YEAR_10": 1198637.921198016,
    "SUBSCRIPTION_Museo": 8346844.785852
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Museo",
    "No of Households": 1323400,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 595530,
    "HH's Considered": 595.53,
    "contribution per HH": 11904.644699999999,
    "Assuming Revenue": 1190.46447,
    "Revenue Per month": 35713.9341,
    "YEAR_1": 237779.80184439002,
    "YEAR_2": 525316.2566769,
    "YEAR_3": 591658.4606610601,
    "YEAR_4": 658000.6646452199,
    "YEAR_5": 724342.86862938,
    "YEAR_6": 790685.07261354,
    "YEAR_7": 857027.2765976998,
    "YEAR_8": 923369.4805818599,
    "YEAR_9": 989711.6845660198,
    "YEAR_10": 1056053.88855018,
    "SUBSCRIPTION_Museo": 7353945.45536625
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Museo",
    "No of Households": 1293400,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 582030,
    "HH's Considered": 582.03,
    "contribution per HH": 11634.7797,
    "Assuming Revenue": 1163.47797,
    "Revenue Per month": 34904.3391,
    "YEAR_1": 232389.59929388997,
    "YEAR_2": 513407.9238219,
    "YEAR_3": 578246.2241340599,
    "YEAR_4": 643084.52444622,
    "YEAR_5": 707922.8247583798,
    "YEAR_6": 772761.1250705399,
    "YEAR_7": 837599.4253826998,
    "YEAR_8": 902437.7256948596,
    "YEAR_9": 967276.0260070197,
    "YEAR_10": 1032114.3263191797,
    "SUBSCRIPTION_Museo": 7187239.724928748
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Museo",
    "No of Households": 1289510,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 580279.5,
    "HH's Considered": 580.2795,
    "contribution per HH": 11599.787204999999,
    "Assuming Revenue": 1159.9787205,
    "Revenue Per month": 34799.361615,
    "YEAR_1": 231690.6696965085,
    "YEAR_2": 511863.809995035,
    "YEAR_3": 576507.1041310589,
    "YEAR_4": 641150.3982670831,
    "YEAR_5": 705793.692403107,
    "YEAR_6": 770436.986539131,
    "YEAR_7": 835080.2806751551,
    "YEAR_8": 899723.574811179,
    "YEAR_9": 964366.8689472032,
    "YEAR_10": 1029010.1630832269,
    "SUBSCRIPTION_Museo": 7165623.548548687
  },
  {
    "DMA": "Saint Louis",
    "PlatformName": "Museo",
    "No of Households": 1255160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 564822,
    "HH's Considered": 564.822,
    "contribution per HH": 11290.79178,
    "Assuming Revenue": 1129.079178,
    "Revenue Per month": 33872.37534,
    "YEAR_1": 225518.887776186,
    "YEAR_2": 498228.76887606,
    "YEAR_3": 561150.0933076439,
    "YEAR_4": 624071.4177392279,
    "YEAR_5": 686992.742170812,
    "YEAR_6": 749914.066602396,
    "YEAR_7": 812835.3910339798,
    "YEAR_8": 875756.7154655639,
    "YEAR_9": 938678.0398971479,
    "YEAR_10": 1001599.364328732,
    "SUBSCRIPTION_Museo": 6974745.487197749
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Museo",
    "No of Households": 1207280,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 543276,
    "HH's Considered": 543.2760000000001,
    "contribution per HH": 10860.08724,
    "Assuming Revenue": 1086.008724,
    "Revenue Per month": 32580.261720000002,
    "YEAR_1": 216916.124505588,
    "YEAR_2": 479223.06963948,
    "YEAR_3": 539744.163810552,
    "YEAR_4": 600265.257981624,
    "YEAR_5": 660786.3521526961,
    "YEAR_6": 721307.4463237681,
    "YEAR_7": 781828.5404948399,
    "YEAR_8": 842349.6346659123,
    "YEAR_9": 902870.728836984,
    "YEAR_10": 963391.823008056,
    "SUBSCRIPTION_Museo": 6708683.1414195
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Museo",
    "No of Households": 1174940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 528723,
    "HH's Considered": 528.723,
    "contribution per HH": 10569.172769999997,
    "Assuming Revenue": 1056.9172769999998,
    "Revenue Per month": 31707.518309999992,
    "YEAR_1": 211105.48615614895,
    "YEAR_2": 466385.88682178984,
    "YEAR_3": 525285.7728344458,
    "YEAR_4": 584185.6588471018,
    "YEAR_5": 643085.5448597579,
    "YEAR_6": 701985.4308724138,
    "YEAR_7": 760885.3168850698,
    "YEAR_8": 819785.2028977259,
    "YEAR_9": 878685.0889103818,
    "YEAR_10": 937584.9749230377,
    "SUBSCRIPTION_Museo": 6528974.364007874
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Museo",
    "No of Households": 1168540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 525843,
    "HH's Considered": 525.843,
    "contribution per HH": 10511.601569999999,
    "Assuming Revenue": 1051.160157,
    "Revenue Per month": 31534.80471,
    "YEAR_1": 209955.576278709,
    "YEAR_2": 463845.44247939,
    "YEAR_3": 522424.49570868607,
    "YEAR_4": 581003.5489379821,
    "YEAR_5": 639582.6021672781,
    "YEAR_6": 698161.6553965741,
    "YEAR_7": 756740.7086258701,
    "YEAR_8": 815319.7618551662,
    "YEAR_9": 873898.815084462,
    "YEAR_10": 932477.8683137579,
    "SUBSCRIPTION_Museo": 6493410.474847876
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Museo",
    "No of Households": 1149480,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 517266,
    "HH's Considered": 517.266,
    "contribution per HH": 10340.147339999998,
    "Assuming Revenue": 1034.0147339999999,
    "Revenue Per month": 31020.442019999995,
    "YEAR_1": 206531.00092495797,
    "YEAR_2": 456279.6816721799,
    "YEAR_3": 513903.2547685319,
    "YEAR_4": 571526.8278648839,
    "YEAR_5": 629150.4009612359,
    "YEAR_6": 686773.9740575879,
    "YEAR_7": 744397.54715394,
    "YEAR_8": 802021.1202502918,
    "YEAR_9": 859644.693346644,
    "YEAR_10": 917268.2664429958,
    "SUBSCRIPTION_Museo": 6387496.767443248
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Museo",
    "No of Households": 1148120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 516654,
    "HH's Considered": 516.654,
    "contribution per HH": 10327.91346,
    "Assuming Revenue": 1032.791346,
    "Revenue Per month": 30983.74038,
    "YEAR_1": 206286.645076002,
    "YEAR_2": 455739.83724941994,
    "YEAR_3": 513295.23337930796,
    "YEAR_4": 570850.6295091959,
    "YEAR_5": 628406.0256390839,
    "YEAR_6": 685961.421768972,
    "YEAR_7": 743516.81789886,
    "YEAR_8": 801072.214028748,
    "YEAR_9": 858627.610158636,
    "YEAR_10": 916183.006288524,
    "SUBSCRIPTION_Museo": 6379939.440996749
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Museo",
    "No of Households": 1107010,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 498154.5,
    "HH's Considered": 498.1545,
    "contribution per HH": 9958.108455,
    "Assuming Revenue": 995.8108455,
    "Revenue Per month": 29874.325365,
    "YEAR_1": 198900.27084763348,
    "YEAR_2": 439421.45179378503,
    "YEAR_3": 494915.9985918089,
    "YEAR_4": 550410.5453898332,
    "YEAR_5": 605905.0921878571,
    "YEAR_6": 661399.6389858811,
    "YEAR_7": 716894.185783905,
    "YEAR_8": 772388.7325819291,
    "YEAR_9": 827883.279379953,
    "YEAR_10": 883377.8261779771,
    "SUBSCRIPTION_Museo": 6151497.021720562
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Museo",
    "No of Households": 1059540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 476793,
    "HH's Considered": 476.793,
    "contribution per HH": 9531.092069999999,
    "Assuming Revenue": 953.109207,
    "Revenue Per month": 28593.27621,
    "YEAR_1": 190371.17367855902,
    "YEAR_2": 420578.49977289,
    "YEAR_3": 473693.36966058594,
    "YEAR_4": 526808.239548282,
    "YEAR_5": 579923.109435978,
    "YEAR_6": 633037.979323674,
    "YEAR_7": 686152.8492113701,
    "YEAR_8": 739267.719099066,
    "YEAR_9": 792382.5889867621,
    "YEAR_10": 845497.4588744581,
    "SUBSCRIPTION_Museo": 5887712.987591625
  },
  {
    "DMA": "Columbus, OH",
    "PlatformName": "Museo",
    "No of Households": 1023600,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 460620,
    "HH's Considered": 460.62,
    "contribution per HH": 9207.7938,
    "Assuming Revenue": 920.77938,
    "Revenue Per month": 27623.3814,
    "YEAR_1": 183913.71102306,
    "YEAR_2": 406312.31701259996,
    "YEAR_3": 457625.5103012399,
    "YEAR_4": 508938.70358988,
    "YEAR_5": 560251.89687852,
    "YEAR_6": 611565.0901671599,
    "YEAR_7": 662878.2834558,
    "YEAR_8": 714191.47674444,
    "YEAR_9": 765504.6700330799,
    "YEAR_10": 816817.8633217199,
    "SUBSCRIPTION_Museo": 5687999.5225275
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Museo",
    "No of Households": 1019550,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 458797.5,
    "HH's Considered": 458.7975,
    "contribution per HH": 9171.362025,
    "Assuming Revenue": 917.1362025000001,
    "Revenue Per month": 27514.086075000003,
    "YEAR_1": 183186.03367874253,
    "YEAR_2": 404704.69207717513,
    "YEAR_3": 455814.8583700951,
    "YEAR_4": 506925.0246630149,
    "YEAR_5": 558035.1909559352,
    "YEAR_6": 609145.357248855,
    "YEAR_7": 660255.5235417751,
    "YEAR_8": 711365.6898346951,
    "YEAR_9": 762475.8561276151,
    "YEAR_10": 813586.0224205351,
    "SUBSCRIPTION_Museo": 5665494.248918437
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Museo",
    "No of Households": 1014160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 456372,
    "HH's Considered": 456.372,
    "contribution per HH": 9122.87628,
    "Assuming Revenue": 912.287628,
    "Revenue Per month": 27368.62884,
    "YEAR_1": 182217.593953836,
    "YEAR_2": 402565.16160756,
    "YEAR_3": 453405.12654074404,
    "YEAR_4": 504245.09147392807,
    "YEAR_5": 555085.056407112,
    "YEAR_6": 605925.0213402961,
    "YEAR_7": 656764.98627348,
    "YEAR_8": 707604.9512066641,
    "YEAR_9": 758444.9161398482,
    "YEAR_10": 809284.8810730322,
    "SUBSCRIPTION_Museo": 5635542.7860165015
  },
  {
    "DMA": "Austin",
    "PlatformName": "Museo",
    "No of Households": 978520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 440334,
    "HH's Considered": 440.334,
    "contribution per HH": 8802.27666,
    "Assuming Revenue": 880.227666,
    "Revenue Per month": 26406.82998,
    "YEAR_1": 175814.03332384198,
    "YEAR_2": 388418.06217582,
    "YEAR_3": 437471.38954666804,
    "YEAR_4": 486524.7169175159,
    "YEAR_5": 535578.044288364,
    "YEAR_6": 584631.371659212,
    "YEAR_7": 633684.69903006,
    "YEAR_8": 682738.026400908,
    "YEAR_9": 731791.353771756,
    "YEAR_10": 780844.681142604,
    "SUBSCRIPTION_Museo": 5437496.378256749
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Museo",
    "No of Households": 953940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 429273,
    "HH's Considered": 429.273,
    "contribution per HH": 8581.16727,
    "Assuming Revenue": 858.1167270000001,
    "Revenue Per month": 25743.50181,
    "YEAR_1": 171397.66070079902,
    "YEAR_2": 378661.16812329006,
    "YEAR_3": 426482.2970855461,
    "YEAR_4": 474303.426047802,
    "YEAR_5": 522124.55501005804,
    "YEAR_6": 569945.6839723141,
    "YEAR_7": 617766.81293457,
    "YEAR_8": 665587.941896826,
    "YEAR_9": 713409.070859082,
    "YEAR_10": 761230.199821338,
    "SUBSCRIPTION_Museo": 5300908.816451626
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Museo",
    "No of Households": 947350,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 426307.5,
    "HH's Considered": 426.3075,
    "contribution per HH": 8521.886924999999,
    "Assuming Revenue": 852.1886924999999,
    "Revenue Per month": 25565.660774999997,
    "YEAR_1": 170213.61287387248,
    "YEAR_2": 376045.30433947494,
    "YEAR_3": 423536.07579511486,
    "YEAR_4": 471026.8472507549,
    "YEAR_5": 518517.6187063949,
    "YEAR_6": 566008.3901620349,
    "YEAR_7": 613499.161617675,
    "YEAR_8": 660989.9330733148,
    "YEAR_9": 708480.7045289548,
    "YEAR_10": 755971.4759845949,
    "SUBSCRIPTION_Museo": 5264289.124332187
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Museo",
    "No of Households": 900200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 405090,
    "HH's Considered": 405.09000000000003,
    "contribution per HH": 8097.7491,
    "Assuming Revenue": 809.7749100000001,
    "Revenue Per month": 24293.247300000003,
    "YEAR_1": 161742.01119867002,
    "YEAR_2": 357329.3745357001,
    "YEAR_3": 402456.51072018006,
    "YEAR_4": 447583.64690466004,
    "YEAR_5": 492710.7830891401,
    "YEAR_6": 537837.9192736201,
    "YEAR_7": 582965.0554581,
    "YEAR_8": 628092.1916425801,
    "YEAR_9": 673219.3278270601,
    "YEAR_10": 718346.4640115402,
    "SUBSCRIPTION_Museo": 5002283.28466125
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Museo",
    "No of Households": 888210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 399694.5,
    "HH's Considered": 399.6945,
    "contribution per HH": 7989.893055,
    "Assuming Revenue": 798.9893055,
    "Revenue Per month": 23969.679165,
    "YEAR_1": 159587.7269126535,
    "YEAR_2": 352570.010837985,
    "YEAR_3": 397096.08685488906,
    "YEAR_4": 441622.1628717931,
    "YEAR_5": 486148.238888697,
    "YEAR_6": 530674.314905601,
    "YEAR_7": 575200.3909225052,
    "YEAR_8": 619726.4669394089,
    "YEAR_9": 664252.5429563131,
    "YEAR_10": 708778.6189732171,
    "SUBSCRIPTION_Museo": 4935656.561063063
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Museo",
    "No of Households": 870240,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 391608,
    "HH's Considered": 391.608,
    "contribution per HH": 7828.24392,
    "Assuming Revenue": 782.824392,
    "Revenue Per month": 23484.73176,
    "YEAR_1": 156358.99558490398,
    "YEAR_2": 345436.91945784,
    "YEAR_3": 389062.157175216,
    "YEAR_4": 432687.394892592,
    "YEAR_5": 476312.63260996796,
    "YEAR_6": 519937.870327344,
    "YEAR_7": 563563.1080447199,
    "YEAR_8": 607188.345762096,
    "YEAR_9": 650813.583479472,
    "YEAR_10": 694438.821196848,
    "SUBSCRIPTION_Museo": 4835799.828531
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Museo",
    "No of Households": 790580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 355761,
    "HH's Considered": 355.761,
    "contribution per HH": 7111.6623899999995,
    "Assuming Revenue": 711.166239,
    "Revenue Per month": 21334.98717,
    "YEAR_1": 142046.21107914302,
    "YEAR_2": 313816.32628353,
    "YEAR_3": 353448.198450522,
    "YEAR_4": 393080.0706175141,
    "YEAR_5": 432711.942784506,
    "YEAR_6": 472343.81495149806,
    "YEAR_7": 511975.68711849005,
    "YEAR_8": 551607.559285482,
    "YEAR_9": 591239.431452474,
    "YEAR_10": 630871.303619466,
    "SUBSCRIPTION_Museo": 4393140.545642626
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Museo",
    "No of Households": 781030,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 351463.5,
    "HH's Considered": 351.4635,
    "contribution per HH": 7025.755365,
    "Assuming Revenue": 702.5755365,
    "Revenue Per month": 21077.266095,
    "YEAR_1": 140330.3299339005,
    "YEAR_2": 310025.506991355,
    "YEAR_3": 349178.63648942695,
    "YEAR_4": 388331.76598749903,
    "YEAR_5": 427484.89548557095,
    "YEAR_6": 466638.024983643,
    "YEAR_7": 505791.15448171494,
    "YEAR_8": 544944.283979787,
    "YEAR_9": 584097.4134778589,
    "YEAR_10": 623250.5429759311,
    "SUBSCRIPTION_Museo": 4340072.554786688
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Museo",
    "No of Households": 772320,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 347544,
    "HH's Considered": 347.544,
    "contribution per HH": 6947.404559999999,
    "Assuming Revenue": 694.740456,
    "Revenue Per month": 20842.21368,
    "YEAR_1": 138765.37446007202,
    "YEAR_2": 306568.12101912,
    "YEAR_3": 345284.617151088,
    "YEAR_4": 384001.113283056,
    "YEAR_5": 422717.60941502394,
    "YEAR_6": 461434.10554699204,
    "YEAR_7": 500150.60167895997,
    "YEAR_8": 538867.097810928,
    "YEAR_9": 577583.5939428961,
    "YEAR_10": 616300.090074864,
    "SUBSCRIPTION_Museo": 4291672.324383
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Museo",
    "No of Households": 772190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 347485.5,
    "HH's Considered": 347.4855,
    "contribution per HH": 6946.235145,
    "Assuming Revenue": 694.6235145,
    "Revenue Per month": 20838.705435000003,
    "YEAR_1": 138742.01691568652,
    "YEAR_2": 306516.51824341505,
    "YEAR_3": 345226.49745947105,
    "YEAR_4": 383936.476675527,
    "YEAR_5": 422646.45589158294,
    "YEAR_6": 461356.43510763906,
    "YEAR_7": 500066.414323695,
    "YEAR_8": 538776.3935397512,
    "YEAR_9": 577486.3727558071,
    "YEAR_10": 616196.3519718632,
    "SUBSCRIPTION_Museo": 4290949.932884437
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Museo",
    "No of Households": 766220,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 344799,
    "HH's Considered": 344.79900000000004,
    "contribution per HH": 6892.53201,
    "Assuming Revenue": 689.253201,
    "Revenue Per month": 20677.59603,
    "YEAR_1": 137669.366608137,
    "YEAR_2": 304146.76000526996,
    "YEAR_3": 342557.462390598,
    "YEAR_4": 380968.164775926,
    "YEAR_5": 419378.86716125405,
    "YEAR_6": 457789.569546582,
    "YEAR_7": 496200.27193190996,
    "YEAR_8": 534610.9743172381,
    "YEAR_9": 573021.6767025661,
    "YEAR_10": 611432.379087894,
    "SUBSCRIPTION_Museo": 4257775.492527375
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Museo",
    "No of Households": 743340,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 334503,
    "HH's Considered": 334.503,
    "contribution per HH": 6686.714969999999,
    "Assuming Revenue": 668.6714969999999,
    "Revenue Per month": 20060.14491,
    "YEAR_1": 133558.438796289,
    "YEAR_2": 295064.67148118996,
    "YEAR_3": 332328.396666006,
    "YEAR_4": 369592.12185082206,
    "YEAR_5": 406855.847035638,
    "YEAR_6": 444119.57222045393,
    "YEAR_7": 481383.29740527,
    "YEAR_8": 518647.02259008604,
    "YEAR_9": 555910.7477749019,
    "YEAR_10": 593174.4729597181,
    "SUBSCRIPTION_Museo": 4130634.588780375
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Museo",
    "No of Households": 739970,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 332986.5,
    "HH's Considered": 332.98650000000004,
    "contribution per HH": 6656.400135,
    "Assuming Revenue": 665.6400135,
    "Revenue Per month": 19969.200405,
    "YEAR_1": 132952.9393764495,
    "YEAR_2": 293726.968757145,
    "YEAR_3": 330821.755429473,
    "YEAR_4": 367916.5421018011,
    "YEAR_5": 405011.328774129,
    "YEAR_6": 442106.11544645706,
    "YEAR_7": 479200.902118785,
    "YEAR_8": 516295.6887911131,
    "YEAR_9": 553390.4754634411,
    "YEAR_10": 590485.2621357689,
    "SUBSCRIPTION_Museo": 4111907.9783945624
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Museo",
    "No of Households": 721070,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 324481.5,
    "HH's Considered": 324.4815,
    "contribution per HH": 6486.385184999999,
    "Assuming Revenue": 648.6385184999999,
    "Revenue Per month": 19459.155554999998,
    "YEAR_1": 129557.11176963449,
    "YEAR_2": 286224.71905849496,
    "YEAR_3": 322372.04641746293,
    "YEAR_4": 358519.37377643096,
    "YEAR_5": 394666.70113539894,
    "YEAR_6": 430814.0284943669,
    "YEAR_7": 466961.355853335,
    "YEAR_8": 503108.6832123029,
    "YEAR_9": 539256.010571271,
    "YEAR_10": 575403.3379302389,
    "SUBSCRIPTION_Museo": 4006883.368218937
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Museo",
    "No of Households": 720750,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 324337.5,
    "HH's Considered": 324.33750000000003,
    "contribution per HH": 6483.506625,
    "Assuming Revenue": 648.3506625,
    "Revenue Per month": 19450.519874999998,
    "YEAR_1": 129499.6162757625,
    "YEAR_2": 286097.69684137503,
    "YEAR_3": 322228.98256117495,
    "YEAR_4": 358360.268280975,
    "YEAR_5": 394491.554000775,
    "YEAR_6": 430622.83972057496,
    "YEAR_7": 466754.12544037495,
    "YEAR_8": 502885.41116017493,
    "YEAR_9": 539016.696879975,
    "YEAR_10": 575147.982599775,
    "SUBSCRIPTION_Museo": 4005105.1737609366
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Museo",
    "No of Households": 687110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 309199.5,
    "HH's Considered": 309.1995,
    "contribution per HH": 6180.898004999999,
    "Assuming Revenue": 618.0898004999999,
    "Revenue Per month": 18542.694014999997,
    "YEAR_1": 123455.40248246849,
    "YEAR_2": 272744.48626663495,
    "YEAR_3": 307189.3946688989,
    "YEAR_4": 341634.3030711629,
    "YEAR_5": 376079.211473427,
    "YEAR_6": 410524.1198756909,
    "YEAR_7": 444969.02827795496,
    "YEAR_8": 479413.9366802189,
    "YEAR_9": 513858.8450824829,
    "YEAR_10": 548303.7534847469,
    "SUBSCRIPTION_Museo": 3818172.4813636867
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Museo",
    "No of Households": 663900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 298755,
    "HH's Considered": 298.755,
    "contribution per HH": 5972.11245,
    "Assuming Revenue": 597.211245,
    "Revenue Per month": 17916.337349999998,
    "YEAR_1": 119285.182442565,
    "YEAR_2": 263531.40608115,
    "YEAR_3": 296812.79434250994,
    "YEAR_4": 330094.18260386994,
    "YEAR_5": 363375.57086523,
    "YEAR_6": 396656.95912658994,
    "YEAR_7": 429938.34738794994,
    "YEAR_8": 463219.73564930994,
    "YEAR_9": 496501.12391066994,
    "YEAR_10": 529782.5121720299,
    "SUBSCRIPTION_Museo": 3689197.8145818743
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Museo",
    "No of Households": 644360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 289962,
    "HH's Considered": 289.962,
    "contribution per HH": 5796.34038,
    "Assuming Revenue": 579.634038,
    "Revenue Per month": 17389.02114,
    "YEAR_1": 115774.363848006,
    "YEAR_2": 255775.11194826005,
    "YEAR_3": 288076.957617924,
    "YEAR_4": 320378.803287588,
    "YEAR_5": 352680.64895725204,
    "YEAR_6": 384982.494626916,
    "YEAR_7": 417284.34029658005,
    "YEAR_8": 449586.185966244,
    "YEAR_9": 481888.031635908,
    "YEAR_10": 514189.877305572,
    "SUBSCRIPTION_Museo": 3580616.81549025
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Museo",
    "No of Households": 634120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 285354,
    "HH's Considered": 285.354,
    "contribution per HH": 5704.226459999999,
    "Assuming Revenue": 570.4226459999999,
    "Revenue Per month": 17112.679379999998,
    "YEAR_1": 113934.508044102,
    "YEAR_2": 251710.40100041995,
    "YEAR_3": 283498.9142167079,
    "YEAR_4": 315287.42743299593,
    "YEAR_5": 347075.940649284,
    "YEAR_6": 378864.4538655719,
    "YEAR_7": 410652.96708186,
    "YEAR_8": 442441.48029814794,
    "YEAR_9": 474229.99351443595,
    "YEAR_10": 506018.5067307239,
    "SUBSCRIPTION_Museo": 3523714.592834249
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Museo",
    "No of Households": 632110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 284449.5,
    "HH's Considered": 284.4495,
    "contribution per HH": 5686.1455049999995,
    "Assuming Revenue": 568.6145505,
    "Revenue Per month": 17058.436514999998,
    "YEAR_1": 113573.36447321848,
    "YEAR_2": 250912.54269913497,
    "YEAR_3": 282600.29436939897,
    "YEAR_4": 314288.04603966296,
    "YEAR_5": 345975.79770992696,
    "YEAR_6": 377663.54938019096,
    "YEAR_7": 409351.3010504549,
    "YEAR_8": 441039.05272071896,
    "YEAR_9": 472726.804390983,
    "YEAR_10": 504414.5560612469,
    "SUBSCRIPTION_Museo": 3512545.308894937
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Museo",
    "No of Households": 618120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 278154,
    "HH's Considered": 278.154,
    "contribution per HH": 5560.298459999999,
    "Assuming Revenue": 556.0298459999999,
    "Revenue Per month": 16680.895379999998,
    "YEAR_1": 111059.73335050199,
    "YEAR_2": 245359.29014441997,
    "YEAR_3": 276345.721402308,
    "YEAR_4": 307332.1526601959,
    "YEAR_5": 338318.583918084,
    "YEAR_6": 369305.01517597196,
    "YEAR_7": 400291.4464338599,
    "YEAR_8": 431277.877691748,
    "YEAR_9": 462264.30894963595,
    "YEAR_10": 493250.7402075239,
    "SUBSCRIPTION_Museo": 3434804.869934249
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Museo",
    "No of Households": 608190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 273685.5,
    "HH's Considered": 273.6855,
    "contribution per HH": 5470.973144999999,
    "Assuming Revenue": 547.0973144999999,
    "Revenue Per month": 16412.919434999996,
    "YEAR_1": 109275.57630628647,
    "YEAR_2": 241417.63196941494,
    "YEAR_3": 271906.27111187094,
    "YEAR_4": 302394.91025432694,
    "YEAR_5": 332883.54939678294,
    "YEAR_6": 363372.18853923894,
    "YEAR_7": 393860.82768169494,
    "YEAR_8": 424349.46682415094,
    "YEAR_9": 454838.10596660693,
    "YEAR_10": 485326.7451090628,
    "SUBSCRIPTION_Museo": 3379625.2731594364
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Museo",
    "No of Households": 590390,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 265675.5,
    "HH's Considered": 265.6755,
    "contribution per HH": 5310.853244999999,
    "Assuming Revenue": 531.0853245,
    "Revenue Per month": 15932.559734999999,
    "YEAR_1": 106077.38945965651,
    "YEAR_2": 234352.0211421149,
    "YEAR_3": 263948.34410585096,
    "YEAR_4": 293544.667069587,
    "YEAR_5": 323140.99003332295,
    "YEAR_6": 352737.31299705897,
    "YEAR_7": 382333.635960795,
    "YEAR_8": 411929.958924531,
    "YEAR_9": 441526.281888267,
    "YEAR_10": 471122.6048520029,
    "SUBSCRIPTION_Museo": 3280713.2064331872
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Museo",
    "No of Households": 588650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 264892.5,
    "HH's Considered": 264.8925,
    "contribution per HH": 5295.201074999999,
    "Assuming Revenue": 529.5201074999999,
    "Revenue Per month": 15885.603224999997,
    "YEAR_1": 105764.75771172749,
    "YEAR_2": 233661.33783652497,
    "YEAR_3": 263170.434387285,
    "YEAR_4": 292679.5309380449,
    "YEAR_5": 322188.627488805,
    "YEAR_6": 351697.72403956496,
    "YEAR_7": 381206.820590325,
    "YEAR_8": 410715.91714108497,
    "YEAR_9": 440225.013691845,
    "YEAR_10": 469734.11024260486,
    "SUBSCRIPTION_Museo": 3271044.2740678117
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Museo",
    "No of Households": 578760,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 260442,
    "HH's Considered": 260.442,
    "contribution per HH": 5206.23558,
    "Assuming Revenue": 520.623558,
    "Revenue Per month": 15618.70674,
    "YEAR_1": 103987.787604246,
    "YEAR_2": 229735.55743866,
    "YEAR_3": 258748.867078884,
    "YEAR_4": 287762.17671910796,
    "YEAR_5": 316775.486359332,
    "YEAR_6": 345788.79599955596,
    "YEAR_7": 374802.10563977994,
    "YEAR_8": 403815.415280004,
    "YEAR_9": 432828.724920228,
    "YEAR_10": 461842.034560452,
    "SUBSCRIPTION_Museo": 3216086.95160025
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Museo",
    "No of Households": 577130,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 259708.5,
    "HH's Considered": 259.7085,
    "contribution per HH": 5191.572915,
    "Assuming Revenue": 519.1572915,
    "Revenue Per month": 15574.718745000002,
    "YEAR_1": 103694.9199323355,
    "YEAR_2": 229088.53802020507,
    "YEAR_3": 258020.135560917,
    "YEAR_4": 286951.73310162895,
    "YEAR_5": 315883.33064234105,
    "YEAR_6": 344814.92818305304,
    "YEAR_7": 373746.525723765,
    "YEAR_8": 402678.123264477,
    "YEAR_9": 431609.720805189,
    "YEAR_10": 460541.31834590103,
    "SUBSCRIPTION_Museo": 3207029.273579813
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Museo",
    "No of Households": 559650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 251842.5,
    "HH's Considered": 251.8425,
    "contribution per HH": 5034.331574999999,
    "Assuming Revenue": 503.43315749999994,
    "Revenue Per month": 15102.994724999999,
    "YEAR_1": 100554.2285795775,
    "YEAR_2": 222149.949410025,
    "YEAR_3": 250205.27241118494,
    "YEAR_4": 278260.59541234496,
    "YEAR_5": 306315.91841350496,
    "YEAR_6": 334371.24141466495,
    "YEAR_7": 362426.56441582495,
    "YEAR_8": 390481.88741698494,
    "YEAR_9": 418537.210418145,
    "YEAR_10": 446592.53341930493,
    "SUBSCRIPTION_Museo": 3109895.4013115624
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Museo",
    "No of Households": 543710,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 244669.5,
    "HH's Considered": 244.6695,
    "contribution per HH": 4890.943305,
    "Assuming Revenue": 489.0943305,
    "Revenue Per month": 14672.829915,
    "YEAR_1": 97690.2342910785,
    "YEAR_2": 215822.655219735,
    "YEAR_3": 243078.90406983902,
    "YEAR_4": 270335.152919943,
    "YEAR_5": 297591.401770047,
    "YEAR_6": 324847.650620151,
    "YEAR_7": 352103.899470255,
    "YEAR_8": 379360.148320359,
    "YEAR_9": 406616.397170463,
    "YEAR_10": 433872.64602056704,
    "SUBSCRIPTION_Museo": 3021319.089872438
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Museo",
    "No of Households": 510900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 229905,
    "HH's Considered": 229.905,
    "contribution per HH": 4595.80095,
    "Assuming Revenue": 459.58009500000003,
    "Revenue Per month": 13787.40285,
    "YEAR_1": 91795.149435015,
    "YEAR_2": 202798.90852064997,
    "YEAR_3": 228410.38805481,
    "YEAR_4": 254021.86758897,
    "YEAR_5": 279633.34712313005,
    "YEAR_6": 305244.82665729005,
    "YEAR_7": 330856.30619145,
    "YEAR_8": 356467.78572561,
    "YEAR_9": 382079.26525977,
    "YEAR_10": 407690.74479393003,
    "SUBSCRIPTION_Museo": 2838998.589350625
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Museo",
    "No of Households": 491820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 221319,
    "HH's Considered": 221.31900000000002,
    "contribution per HH": 4424.16681,
    "Assuming Revenue": 442.416681,
    "Revenue Per month": 13272.50043,
    "YEAR_1": 88366.98061289701,
    "YEAR_2": 195225.20882486997,
    "YEAR_3": 219880.20562363803,
    "YEAR_4": 244535.202422406,
    "YEAR_5": 269190.199221174,
    "YEAR_6": 293845.19601994206,
    "YEAR_7": 318500.19281871,
    "YEAR_8": 343155.189617478,
    "YEAR_9": 367810.186416246,
    "YEAR_10": 392465.18321501405,
    "SUBSCRIPTION_Museo": 2732973.7447923752
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Museo",
    "No of Households": 490560,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 220752,
    "HH's Considered": 220.752,
    "contribution per HH": 4412.83248,
    "Assuming Revenue": 441.283248,
    "Revenue Per month": 13238.497440000001,
    "YEAR_1": 88140.59210577601,
    "YEAR_2": 194725.05884496006,
    "YEAR_3": 219316.89168950403,
    "YEAR_4": 243908.72453404803,
    "YEAR_5": 268500.557378592,
    "YEAR_6": 293092.390223136,
    "YEAR_7": 317684.22306768,
    "YEAR_8": 342276.0559122241,
    "YEAR_9": 366867.8887567681,
    "YEAR_10": 391459.72160131205,
    "SUBSCRIPTION_Museo": 2725972.1041140007
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Museo",
    "No of Households": 484300,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 217935,
    "HH's Considered": 217.935,
    "contribution per HH": 4356.5206499999995,
    "Assuming Revenue": 435.652065,
    "Revenue Per month": 13069.56195,
    "YEAR_1": 87015.836506905,
    "YEAR_2": 192240.18672255,
    "YEAR_3": 216518.20500086996,
    "YEAR_4": 240796.22327919,
    "YEAR_5": 265074.24155751,
    "YEAR_6": 289352.25983583,
    "YEAR_7": 313630.27811415,
    "YEAR_8": 337908.29639247,
    "YEAR_9": 362186.31467079005,
    "YEAR_10": 386464.33294911,
    "SUBSCRIPTION_Museo": 2691186.1750293747
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Museo",
    "No of Households": 481910,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 216859.5,
    "HH's Considered": 216.8595,
    "contribution per HH": 4335.0214049999995,
    "Assuming Revenue": 433.5021405,
    "Revenue Per month": 13005.064215,
    "YEAR_1": 86586.41703704851,
    "YEAR_2": 191291.489538435,
    "YEAR_3": 215449.696824219,
    "YEAR_4": 239607.90411000297,
    "YEAR_5": 263766.111395787,
    "YEAR_6": 287924.31868157105,
    "YEAR_7": 312082.52596735506,
    "YEAR_8": 336240.73325313895,
    "YEAR_9": 360398.940538923,
    "YEAR_10": 384557.14782470703,
    "SUBSCRIPTION_Museo": 2677905.285171187
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Museo",
    "No of Households": 472310,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 212539.5,
    "HH's Considered": 212.5395,
    "contribution per HH": 4248.664605,
    "Assuming Revenue": 424.8664605,
    "Revenue Per month": 12745.993815,
    "YEAR_1": 84861.5522208885,
    "YEAR_2": 187480.823024835,
    "YEAR_3": 211157.78113557902,
    "YEAR_4": 234834.739246323,
    "YEAR_5": 258511.697357067,
    "YEAR_6": 282188.65546781104,
    "YEAR_7": 305865.61357855506,
    "YEAR_8": 329542.571689299,
    "YEAR_9": 353219.529800043,
    "YEAR_10": 376896.48791078693,
    "SUBSCRIPTION_Museo": 2624559.4514311883
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Museo",
    "No of Households": 471190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 212035.5,
    "HH's Considered": 212.0355,
    "contribution per HH": 4238.589645,
    "Assuming Revenue": 423.8589645,
    "Revenue Per month": 12715.768935,
    "YEAR_1": 84660.3179923365,
    "YEAR_2": 187036.245264915,
    "YEAR_3": 210657.05763857102,
    "YEAR_4": 234277.87001222698,
    "YEAR_5": 257898.682385883,
    "YEAR_6": 281519.494759539,
    "YEAR_7": 305140.307133195,
    "YEAR_8": 328761.119506851,
    "YEAR_9": 352381.931880507,
    "YEAR_10": 376002.744254163,
    "SUBSCRIPTION_Museo": 2618335.7708281875
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Museo",
    "No of Households": 457620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 205929,
    "HH's Considered": 205.929,
    "contribution per HH": 4116.52071,
    "Assuming Revenue": 411.652071,
    "Revenue Per month": 12349.562129999998,
    "YEAR_1": 82222.149705327,
    "YEAR_2": 181649.70937017,
    "YEAR_3": 204590.25598285795,
    "YEAR_4": 227530.802595546,
    "YEAR_5": 250471.349208234,
    "YEAR_6": 273411.895820922,
    "YEAR_7": 296352.44243361,
    "YEAR_8": 319292.9890462979,
    "YEAR_9": 342233.535658986,
    "YEAR_10": 365174.08227167395,
    "SUBSCRIPTION_Museo": 2542929.212093625
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Museo",
    "No of Households": 456390,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 205375.5,
    "HH's Considered": 205.37550000000002,
    "contribution per HH": 4105.456245,
    "Assuming Revenue": 410.54562450000003,
    "Revenue Per month": 12316.368735,
    "YEAR_1": 82001.1514007565,
    "YEAR_2": 181161.467723115,
    "YEAR_3": 204040.354285251,
    "YEAR_4": 226919.240847387,
    "YEAR_5": 249798.127409523,
    "YEAR_6": 272677.013971659,
    "YEAR_7": 295555.900533795,
    "YEAR_8": 318434.787095931,
    "YEAR_9": 341313.673658067,
    "YEAR_10": 364192.56022020295,
    "SUBSCRIPTION_Museo": 2536094.277145687
  },
  {
    "DMA": "Madison",
    "PlatformName": "Museo",
    "No of Households": 454700,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 204615,
    "HH's Considered": 204.615,
    "contribution per HH": 4090.25385,
    "Assuming Revenue": 409.025385,
    "Revenue Per month": 12270.761550000001,
    "YEAR_1": 81697.50332374501,
    "YEAR_2": 180490.63163895003,
    "YEAR_3": 203284.79829423004,
    "YEAR_4": 226078.96494951,
    "YEAR_5": 248873.13160479,
    "YEAR_6": 271667.29826007003,
    "YEAR_7": 294461.46491535,
    "YEAR_8": 317255.63157063007,
    "YEAR_9": 340049.79822591005,
    "YEAR_10": 362843.96488119,
    "SUBSCRIPTION_Museo": 2526703.1876643756
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Museo",
    "No of Households": 451790,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 203305.5,
    "HH's Considered": 203.3055,
    "contribution per HH": 4064.076945,
    "Assuming Revenue": 406.4076945,
    "Revenue Per month": 12192.230835,
    "YEAR_1": 81174.65367634651,
    "YEAR_2": 179335.523352015,
    "YEAR_3": 201983.81135111104,
    "YEAR_4": 224632.09935020702,
    "YEAR_5": 247280.38734930306,
    "YEAR_6": 269928.67534839903,
    "YEAR_7": 292576.963347495,
    "YEAR_8": 315225.2513465909,
    "YEAR_9": 337873.53934568696,
    "YEAR_10": 360521.827344783,
    "SUBSCRIPTION_Museo": 2510532.731811938
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Museo",
    "No of Households": 442050,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 198922.5,
    "HH's Considered": 198.9225,
    "contribution per HH": 3976.460775,
    "Assuming Revenue": 397.64607750000005,
    "Revenue Per month": 11929.382325000002,
    "YEAR_1": 79424.63458161752,
    "YEAR_2": 175469.284618425,
    "YEAR_3": 197629.30522534502,
    "YEAR_4": 219789.32583226502,
    "YEAR_5": 241949.34643918503,
    "YEAR_6": 264109.36704610504,
    "YEAR_7": 286269.38765302504,
    "YEAR_8": 308429.40825994505,
    "YEAR_9": 330589.42886686506,
    "YEAR_10": 352749.44947378506,
    "SUBSCRIPTION_Museo": 2456408.937996563
  },
  {
    "DMA": "Springfield, MO",
    "PlatformName": "Museo",
    "No of Households": 436340,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 196353,
    "HH's Considered": 196.353,
    "contribution per HH": 3925.09647,
    "Assuming Revenue": 392.50964700000003,
    "Revenue Per month": 11775.289410000001,
    "YEAR_1": 78398.699362839,
    "YEAR_2": 173202.73193169004,
    "YEAR_3": 195076.50953970602,
    "YEAR_4": 216950.28714772203,
    "YEAR_5": 238824.064755738,
    "YEAR_6": 260697.84236375403,
    "YEAR_7": 282571.61997177004,
    "YEAR_8": 304445.397579786,
    "YEAR_9": 326319.17518780206,
    "YEAR_10": 348192.95279581804,
    "SUBSCRIPTION_Museo": 2424679.2806366254
  },
  {
    "DMA": "Columbia, SC",
    "PlatformName": "Museo",
    "No of Households": 435570,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 196006.5,
    "HH's Considered": 196.00650000000002,
    "contribution per HH": 3918.169935,
    "Assuming Revenue": 391.8169935,
    "Revenue Per month": 11754.509805000002,
    "YEAR_1": 78260.35083070952,
    "YEAR_2": 172897.08472174502,
    "YEAR_3": 194732.262135513,
    "YEAR_4": 216567.43954928103,
    "YEAR_5": 238402.61696304905,
    "YEAR_6": 260237.794376817,
    "YEAR_7": 282072.971790585,
    "YEAR_8": 303908.149204353,
    "YEAR_9": 325743.326618121,
    "YEAR_10": 347578.50403188914,
    "SUBSCRIPTION_Museo": 2420400.5002220627
  },
  {
    "DMA": "Rochester, NY",
    "PlatformName": "Museo",
    "No of Households": 434190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 195385.5,
    "HH's Considered": 195.3855,
    "contribution per HH": 3905.756145,
    "Assuming Revenue": 390.57561450000003,
    "Revenue Per month": 11717.268435000002,
    "YEAR_1": 78012.40151338652,
    "YEAR_2": 172349.30141041504,
    "YEAR_3": 194115.299255271,
    "YEAR_4": 215881.297100127,
    "YEAR_5": 237647.2949449831,
    "YEAR_6": 259413.2927898391,
    "YEAR_7": 281179.2906346951,
    "YEAR_8": 302945.2884795511,
    "YEAR_9": 324711.2863244071,
    "YEAR_10": 346477.2841692631,
    "SUBSCRIPTION_Museo": 2412732.036621938
  },
  {
    "DMA": "Portland",
    "PlatformName": "Museo",
    "No of Households": 429130,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 193108.5,
    "HH's Considered": 193.1085,
    "contribution per HH": 3860.2389149999995,
    "Assuming Revenue": 386.0238915,
    "Revenue Per month": 11580.716745,
    "YEAR_1": 77103.2540165355,
    "YEAR_2": 170340.762602205,
    "YEAR_3": 191853.10202771702,
    "YEAR_4": 213365.44145322902,
    "YEAR_5": 234877.78087874094,
    "YEAR_6": 256390.120304253,
    "YEAR_7": 277902.45972976496,
    "YEAR_8": 299414.79915527697,
    "YEAR_9": 320927.138580789,
    "YEAR_10": 342439.478006301,
    "SUBSCRIPTION_Museo": 2384614.3367548124
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Museo",
    "No of Households": 427650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 192442.5,
    "HH's Considered": 192.4425,
    "contribution per HH": 3846.9255749999998,
    "Assuming Revenue": 384.6925575,
    "Revenue Per month": 11540.776725,
    "YEAR_1": 76837.3373573775,
    "YEAR_2": 169753.28484802498,
    "YEAR_3": 191191.43169238497,
    "YEAR_4": 212629.578536745,
    "YEAR_5": 234067.725381105,
    "YEAR_6": 255505.87222546502,
    "YEAR_7": 276944.019069825,
    "YEAR_8": 298382.165914185,
    "YEAR_9": 319820.312758545,
    "YEAR_10": 341258.4596029049,
    "SUBSCRIPTION_Museo": 2376390.1873865626
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Museo",
    "No of Households": 424380,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 190971,
    "HH's Considered": 190.971,
    "contribution per HH": 3817.5102899999997,
    "Assuming Revenue": 381.751029,
    "Revenue Per month": 11452.53087,
    "YEAR_1": 76249.805279373,
    "YEAR_2": 168455.27656683,
    "YEAR_3": 189729.497910942,
    "YEAR_4": 211003.71925505402,
    "YEAR_5": 232277.94059916603,
    "YEAR_6": 253552.16194327798,
    "YEAR_7": 274826.38328739005,
    "YEAR_8": 296100.604631502,
    "YEAR_9": 317374.82597561396,
    "YEAR_10": 338649.047319726,
    "SUBSCRIPTION_Museo": 2358219.262768875
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Museo",
    "No of Households": 412370,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 185566.5,
    "HH's Considered": 185.5665,
    "contribution per HH": 3709.4743349999994,
    "Assuming Revenue": 370.9474335,
    "Revenue Per month": 11128.423005,
    "YEAR_1": 74091.92752498951,
    "YEAR_2": 163687.973980545,
    "YEAR_3": 184360.132554633,
    "YEAR_4": 205032.29112872094,
    "YEAR_5": 225704.44970280895,
    "YEAR_6": 246376.60827689696,
    "YEAR_7": 267048.766850985,
    "YEAR_8": 287720.925425073,
    "YEAR_9": 308393.083999161,
    "YEAR_10": 329065.24257324904,
    "SUBSCRIPTION_Museo": 2291481.402017063
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Museo",
    "No of Households": 407620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 183429,
    "HH's Considered": 183.429,
    "contribution per HH": 3666.7457099999997,
    "Assuming Revenue": 366.674571,
    "Revenue Per month": 11000.237130000001,
    "YEAR_1": 73238.47878782701,
    "YEAR_2": 161802.48794517003,
    "YEAR_3": 182236.52843785804,
    "YEAR_4": 202670.56893054603,
    "YEAR_5": 223104.60942323404,
    "YEAR_6": 243538.64991592203,
    "YEAR_7": 263972.69040861004,
    "YEAR_8": 284406.730901298,
    "YEAR_9": 304840.77139398607,
    "YEAR_10": 325274.8118866741,
    "SUBSCRIPTION_Museo": 2265086.3280311255
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Museo",
    "No of Households": 403470,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 181561.5,
    "HH's Considered": 181.5615,
    "contribution per HH": 3629.4143849999996,
    "Assuming Revenue": 362.9414385,
    "Revenue Per month": 10888.243155,
    "YEAR_1": 72492.8341016745,
    "YEAR_2": 160155.168566895,
    "YEAR_3": 180381.169051623,
    "YEAR_4": 200607.169536351,
    "YEAR_5": 220833.170021079,
    "YEAR_6": 241059.17050580698,
    "YEAR_7": 261285.170990535,
    "YEAR_8": 281511.171475263,
    "YEAR_9": 301737.171959991,
    "YEAR_10": 321963.172444719,
    "SUBSCRIPTION_Museo": 2242025.3686539377
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Museo",
    "No of Households": 401510,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 180679.5,
    "HH's Considered": 180.6795,
    "contribution per HH": 3611.7832049999997,
    "Assuming Revenue": 361.1783205,
    "Revenue Per month": 10835.349615,
    "YEAR_1": 72140.67420170849,
    "YEAR_2": 159377.157487035,
    "YEAR_3": 179504.90293185902,
    "YEAR_4": 199632.648376683,
    "YEAR_5": 219760.39382150702,
    "YEAR_6": 239888.13926633107,
    "YEAR_7": 260015.88471115506,
    "YEAR_8": 280143.630155979,
    "YEAR_9": 300271.375600803,
    "YEAR_10": 320399.121045627,
    "SUBSCRIPTION_Museo": 2231133.9275986874
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Museo",
    "No of Households": 388650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 174892.5,
    "HH's Considered": 174.8925,
    "contribution per HH": 3496.101075,
    "Assuming Revenue": 349.6101075,
    "Revenue Per month": 10488.303225000001,
    "YEAR_1": 69830.07404172751,
    "YEAR_2": 154272.45213652504,
    "YEAR_3": 173755.52420728502,
    "YEAR_4": 193238.59627804506,
    "YEAR_5": 212721.66834880502,
    "YEAR_6": 232204.740419565,
    "YEAR_7": 251687.812490325,
    "YEAR_8": 271170.8845610851,
    "YEAR_9": 290653.95663184504,
    "YEAR_10": 310137.02870260505,
    "SUBSCRIPTION_Museo": 2159672.7378178127
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Museo",
    "No of Households": 385730,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 173578.5,
    "HH's Considered": 173.5785,
    "contribution per HH": 3469.8342149999994,
    "Assuming Revenue": 346.98342149999996,
    "Revenue Per month": 10409.502644999999,
    "YEAR_1": 69305.42766014549,
    "YEAR_2": 153113.37440530502,
    "YEAR_3": 172450.06651865697,
    "YEAR_4": 191786.75863200898,
    "YEAR_5": 211123.45074536098,
    "YEAR_6": 230460.142858713,
    "YEAR_7": 249796.834972065,
    "YEAR_8": 269133.52708541695,
    "YEAR_9": 288470.21919876896,
    "YEAR_10": 307806.91131212097,
    "SUBSCRIPTION_Museo": 2143446.713388562
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Museo",
    "No of Households": 378040,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 170118,
    "HH's Considered": 170.118,
    "contribution per HH": 3400.6588199999997,
    "Assuming Revenue": 340.065882,
    "Revenue Per month": 10201.97646,
    "YEAR_1": 67923.739073034,
    "YEAR_2": 150060.87175013998,
    "YEAR_3": 169012.06322223603,
    "YEAR_4": 187963.25469433202,
    "YEAR_5": 206914.44616642798,
    "YEAR_6": 225865.637638524,
    "YEAR_7": 244816.82911062005,
    "YEAR_8": 263768.020582716,
    "YEAR_9": 282719.21205481194,
    "YEAR_10": 301670.403526908,
    "SUBSCRIPTION_Museo": 2100714.47781975
  },
  {
    "DMA": "Charleston, SC",
    "PlatformName": "Museo",
    "No of Households": 374290,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 168430.5,
    "HH's Considered": 168.4305,
    "contribution per HH": 3366.9256949999995,
    "Assuming Revenue": 336.6925695,
    "Revenue Per month": 10100.777085,
    "YEAR_1": 67249.9637542215,
    "YEAR_2": 148572.330143265,
    "YEAR_3": 167335.533656361,
    "YEAR_4": 186098.73716945702,
    "YEAR_5": 204861.940682553,
    "YEAR_6": 223625.14419564902,
    "YEAR_7": 242388.34770874504,
    "YEAR_8": 261151.55122184101,
    "YEAR_9": 279914.754734937,
    "YEAR_10": 298677.958248033,
    "SUBSCRIPTION_Museo": 2079876.2615150625
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Museo",
    "No of Households": 373520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 168084,
    "HH's Considered": 168.084,
    "contribution per HH": 3359.99916,
    "Assuming Revenue": 335.999916,
    "Revenue Per month": 10079.99748,
    "YEAR_1": 67111.615222092,
    "YEAR_2": 148266.68293332,
    "YEAR_3": 166991.286252168,
    "YEAR_4": 185715.88957101598,
    "YEAR_5": 204440.49288986402,
    "YEAR_6": 223165.096208712,
    "YEAR_7": 241889.69952756,
    "YEAR_8": 260614.30284640798,
    "YEAR_9": 279338.90616525605,
    "YEAR_10": 298063.509484104,
    "SUBSCRIPTION_Museo": 2075597.4811005
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Museo",
    "No of Households": 373080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 167886,
    "HH's Considered": 167.886,
    "contribution per HH": 3356.04114,
    "Assuming Revenue": 335.604114,
    "Revenue Per month": 10068.12342,
    "YEAR_1": 67032.55891801801,
    "YEAR_2": 148092.02738478,
    "YEAR_3": 166794.573449772,
    "YEAR_4": 185497.119514764,
    "YEAR_5": 204199.66557975602,
    "YEAR_6": 222902.21164474805,
    "YEAR_7": 241604.75770974,
    "YEAR_8": 260307.303774732,
    "YEAR_9": 279009.849839724,
    "YEAR_10": 297712.395904716,
    "SUBSCRIPTION_Museo": 2073152.46372075
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Museo",
    "No of Households": 371730,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 167278.5,
    "HH's Considered": 167.2785,
    "contribution per HH": 3343.897215,
    "Assuming Revenue": 334.3897215,
    "Revenue Per month": 10031.691645,
    "YEAR_1": 66789.9998032455,
    "YEAR_2": 147556.15240630502,
    "YEAR_3": 166191.022806057,
    "YEAR_4": 184825.89320580903,
    "YEAR_5": 203460.763605561,
    "YEAR_6": 222095.634005313,
    "YEAR_7": 240730.50440506503,
    "YEAR_8": 259365.37480481702,
    "YEAR_9": 278000.245204569,
    "YEAR_10": 296635.115604321,
    "SUBSCRIPTION_Museo": 2065650.705851063
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Museo",
    "No of Households": 369270,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 166171.5,
    "HH's Considered": 166.1715,
    "contribution per HH": 3321.768285,
    "Assuming Revenue": 332.17682850000006,
    "Revenue Per month": 9965.304855000002,
    "YEAR_1": 66348.00319410452,
    "YEAR_2": 146579.66911219503,
    "YEAR_3": 165091.21941084307,
    "YEAR_4": 183602.76970949103,
    "YEAR_5": 202114.32000813907,
    "YEAR_6": 220625.87030678702,
    "YEAR_7": 239137.42060543507,
    "YEAR_8": 257648.97090408308,
    "YEAR_9": 276160.52120273106,
    "YEAR_10": 294672.0715013791,
    "SUBSCRIPTION_Museo": 2051980.835955188
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Museo",
    "No of Households": 360880,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 162396,
    "HH's Considered": 162.39600000000002,
    "contribution per HH": 3246.29604,
    "Assuming Revenue": 324.62960400000003,
    "Revenue Per month": 9738.888120000001,
    "YEAR_1": 64840.543214148005,
    "YEAR_2": 143249.30535708,
    "YEAR_3": 161340.26392879203,
    "YEAR_4": 179431.22250050405,
    "YEAR_5": 197522.18107221602,
    "YEAR_6": 215613.13964392804,
    "YEAR_7": 233704.09821564003,
    "YEAR_8": 251795.05678735202,
    "YEAR_9": 269886.01535906404,
    "YEAR_10": 287976.97393077606,
    "SUBSCRIPTION_Museo": 2005358.8000095005
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Museo",
    "No of Households": 360200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 162090,
    "HH's Considered": 162.09,
    "contribution per HH": 3240.1791,
    "Assuming Revenue": 324.01791000000003,
    "Revenue Per month": 9720.5373,
    "YEAR_1": 64718.36528967001,
    "YEAR_2": 142979.3831457,
    "YEAR_3": 161036.25323417998,
    "YEAR_4": 179093.12332266002,
    "YEAR_5": 197149.99341114002,
    "YEAR_6": 215206.86349962,
    "YEAR_7": 233263.7335881,
    "YEAR_8": 251320.60367657998,
    "YEAR_9": 269377.47376506,
    "YEAR_10": 287434.34385354,
    "SUBSCRIPTION_Museo": 2001580.13678625
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Museo",
    "No of Households": 353500,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 159075,
    "HH's Considered": 159.07500000000002,
    "contribution per HH": 3179.90925,
    "Assuming Revenue": 317.99092500000006,
    "Revenue Per month": 9539.727750000002,
    "YEAR_1": 63514.55338672502,
    "YEAR_2": 140319.85547475002,
    "YEAR_3": 158040.85374315002,
    "YEAR_4": 175761.85201155004,
    "YEAR_5": 193482.85027995004,
    "YEAR_6": 211203.84854835004,
    "YEAR_7": 228924.84681675007,
    "YEAR_8": 246645.84508515004,
    "YEAR_9": 264366.84335355007,
    "YEAR_10": 282087.84162195004,
    "SUBSCRIPTION_Museo": 1964349.1903218755
  },
  {
    "DMA": "Jackson, MS",
    "PlatformName": "Museo",
    "No of Households": 340720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 153324,
    "HH's Considered": 153.324,
    "contribution per HH": 3064.94676,
    "Assuming Revenue": 306.49467599999997,
    "Revenue Per month": 9194.840279999999,
    "YEAR_1": 61218.32710021199,
    "YEAR_2": 135246.90567851998,
    "YEAR_3": 152327.24098264799,
    "YEAR_4": 169407.576286776,
    "YEAR_5": 186487.91159090394,
    "YEAR_6": 203568.24689503197,
    "YEAR_7": 220648.58219916,
    "YEAR_8": 237728.91750328796,
    "YEAR_9": 254809.25280741596,
    "YEAR_10": 271889.58811154397,
    "SUBSCRIPTION_Museo": 1893332.5491555
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Museo",
    "No of Households": 338310,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 152239.5,
    "HH's Considered": 152.2395,
    "contribution per HH": 3043.2676049999995,
    "Assuming Revenue": 304.3267605,
    "Revenue Per month": 9129.802815,
    "YEAR_1": 60785.31416198849,
    "YEAR_2": 134290.26960583497,
    "YEAR_3": 151249.791314979,
    "YEAR_4": 168209.313024123,
    "YEAR_5": 185168.834733267,
    "YEAR_6": 202128.35644241096,
    "YEAR_7": 219087.87815155496,
    "YEAR_8": 236047.39986069896,
    "YEAR_9": 253006.92156984296,
    "YEAR_10": 269966.44327898696,
    "SUBSCRIPTION_Museo": 1879940.5221436871
  },
  {
    "DMA": "Boise",
    "PlatformName": "Museo",
    "No of Households": 330040,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 148518,
    "HH's Considered": 148.518,
    "contribution per HH": 2968.87482,
    "Assuming Revenue": 296.88748200000003,
    "Revenue Per month": 8906.62446,
    "YEAR_1": 59299.414992234,
    "YEAR_2": 131007.53918214,
    "YEAR_3": 147552.48477903602,
    "YEAR_4": 164097.43037593205,
    "YEAR_5": 180642.37597282804,
    "YEAR_6": 197187.32156972404,
    "YEAR_7": 213732.26716662,
    "YEAR_8": 230277.21276351603,
    "YEAR_9": 246822.15836041202,
    "YEAR_10": 263367.103957308,
    "SUBSCRIPTION_Museo": 1833985.3091197503
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Museo",
    "No of Households": 329080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 148086,
    "HH's Considered": 148.086,
    "contribution per HH": 2960.23914,
    "Assuming Revenue": 296.02391400000005,
    "Revenue Per month": 8880.71742,
    "YEAR_1": 59126.928510618,
    "YEAR_2": 130626.47253078001,
    "YEAR_3": 147123.293210172,
    "YEAR_4": 163620.113889564,
    "YEAR_5": 180116.93456895603,
    "YEAR_6": 196613.75524834802,
    "YEAR_7": 213110.57592774002,
    "YEAR_8": 229607.39660713202,
    "YEAR_9": 246104.21728652404,
    "YEAR_10": 262601.03796591604,
    "SUBSCRIPTION_Museo": 1828650.7257457501
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Museo",
    "No of Households": 322100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 144945,
    "HH's Considered": 144.945,
    "contribution per HH": 2897.4505499999996,
    "Assuming Revenue": 289.745055,
    "Revenue Per month": 8692.351649999999,
    "YEAR_1": 57872.80805053499,
    "YEAR_2": 127855.80041984998,
    "YEAR_3": 144002.71284489,
    "YEAR_4": 160149.62526992997,
    "YEAR_5": 176296.53769496997,
    "YEAR_6": 192443.45012001,
    "YEAR_7": 208590.36254504998,
    "YEAR_8": 224737.27497008996,
    "YEAR_9": 240884.18739512996,
    "YEAR_10": 257031.09982016994,
    "SUBSCRIPTION_Museo": 1789863.8591306247
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Museo",
    "No of Households": 320820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 144369,
    "HH's Considered": 144.369,
    "contribution per HH": 2885.9363099999996,
    "Assuming Revenue": 288.59363099999996,
    "Revenue Per month": 8657.80893,
    "YEAR_1": 57642.82607504699,
    "YEAR_2": 127347.71155136998,
    "YEAR_3": 143430.45741973797,
    "YEAR_4": 159513.20328810601,
    "YEAR_5": 175595.94915647397,
    "YEAR_6": 191678.695024842,
    "YEAR_7": 207761.44089320998,
    "YEAR_8": 223844.186761578,
    "YEAR_9": 239926.93262994598,
    "YEAR_10": 256009.67849831397,
    "SUBSCRIPTION_Museo": 1782751.0812986249
  },
  {
    "DMA": "Reno",
    "PlatformName": "Museo",
    "No of Households": 306940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 138123,
    "HH's Considered": 138.123,
    "contribution per HH": 2761.0787699999996,
    "Assuming Revenue": 276.107877,
    "Revenue Per month": 8283.236309999998,
    "YEAR_1": 55148.95902834899,
    "YEAR_2": 121838.12288378997,
    "YEAR_3": 137225.062653246,
    "YEAR_4": 152612.00242270197,
    "YEAR_5": 167998.94219215796,
    "YEAR_6": 183385.88196161395,
    "YEAR_7": 198772.82173106994,
    "YEAR_8": 214159.76150052596,
    "YEAR_9": 229546.70126998198,
    "YEAR_10": 244933.64103943796,
    "SUBSCRIPTION_Museo": 1705621.8966828743
  },
  {
    "DMA": "Washington",
    "PlatformName": "Museo",
    "No of Households": 305320,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 137394,
    "HH's Considered": 137.394,
    "contribution per HH": 2746.5060599999997,
    "Assuming Revenue": 274.650606,
    "Revenue Per month": 8239.51818,
    "YEAR_1": 54857.88809062199,
    "YEAR_2": 121195.07290962001,
    "YEAR_3": 136500.801880788,
    "YEAR_4": 151806.53085195596,
    "YEAR_5": 167112.25982312398,
    "YEAR_6": 182417.98879429203,
    "YEAR_7": 197723.71776546002,
    "YEAR_8": 213029.44673662802,
    "YEAR_9": 228335.17570779595,
    "YEAR_10": 243640.9046789639,
    "SUBSCRIPTION_Museo": 1696619.7872392498
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Museo",
    "No of Households": 302600,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 136170,
    "HH's Considered": 136.17000000000002,
    "contribution per HH": 2722.0383,
    "Assuming Revenue": 272.20383000000004,
    "Revenue Per month": 8166.114900000001,
    "YEAR_1": 54369.17639271001,
    "YEAR_2": 120115.38406410003,
    "YEAR_3": 135284.75910234003,
    "YEAR_4": 150454.13414058005,
    "YEAR_5": 165623.50917882004,
    "YEAR_6": 180792.88421706005,
    "YEAR_7": 195962.2592553,
    "YEAR_8": 211131.63429354003,
    "YEAR_9": 226301.00933178002,
    "YEAR_10": 241470.38437002,
    "SUBSCRIPTION_Museo": 1681505.1343462504
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Museo",
    "No of Households": 299530,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 134788.5,
    "HH's Considered": 134.7885,
    "contribution per HH": 2694.422115,
    "Assuming Revenue": 269.4422115,
    "Revenue Per month": 8083.266345,
    "YEAR_1": 53817.578998375495,
    "YEAR_2": 118896.764668605,
    "YEAR_3": 133912.24023107698,
    "YEAR_4": 148927.71579354902,
    "YEAR_5": 163943.191356021,
    "YEAR_6": 178958.66691849296,
    "YEAR_7": 193974.142480965,
    "YEAR_8": 208989.61804343702,
    "YEAR_9": 224005.09360590897,
    "YEAR_10": 239020.569168381,
    "SUBSCRIPTION_Museo": 1664445.5812648125
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Museo",
    "No of Households": 291650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 131242.5,
    "HH's Considered": 131.2425,
    "contribution per HH": 2623.537575,
    "Assuming Revenue": 262.3537575,
    "Revenue Per month": 7870.612724999999,
    "YEAR_1": 52401.7524617775,
    "YEAR_2": 115768.842572025,
    "YEAR_3": 130389.29276998498,
    "YEAR_4": 145009.742967945,
    "YEAR_5": 159630.193165905,
    "YEAR_6": 174250.64336386495,
    "YEAR_7": 188871.093561825,
    "YEAR_8": 203491.543759785,
    "YEAR_9": 218111.993957745,
    "YEAR_10": 232732.444155705,
    "SUBSCRIPTION_Museo": 1620657.5427365622
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Museo",
    "No of Households": 290990,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 130945.5,
    "HH's Considered": 130.9455,
    "contribution per HH": 2617.600545,
    "Assuming Revenue": 261.76005449999997,
    "Revenue Per month": 7852.801634999999,
    "YEAR_1": 52283.16800566649,
    "YEAR_2": 115506.85924921499,
    "YEAR_3": 130094.22356639098,
    "YEAR_4": 144681.58788356697,
    "YEAR_5": 159268.952200743,
    "YEAR_6": 173856.31651791898,
    "YEAR_7": 188443.680835095,
    "YEAR_8": 203031.04515227096,
    "YEAR_9": 217618.40946944698,
    "YEAR_10": 232205.77378662297,
    "SUBSCRIPTION_Museo": 1616990.0166669376
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Museo",
    "No of Households": 285360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 128412,
    "HH's Considered": 128.412,
    "contribution per HH": 2566.95588,
    "Assuming Revenue": 256.695588,
    "Revenue Per month": 7700.8676399999995,
    "YEAR_1": 51271.606660355996,
    "YEAR_2": 113272.06211676,
    "YEAR_3": 127577.19384482398,
    "YEAR_4": 141882.325572888,
    "YEAR_5": 156187.457300952,
    "YEAR_6": 170492.589029016,
    "YEAR_7": 184797.72075708,
    "YEAR_8": 199102.852485144,
    "YEAR_9": 213407.98421320796,
    "YEAR_10": 227713.11594127197,
    "SUBSCRIPTION_Museo": 1585704.9079214998
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Museo",
    "No of Households": 284300,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 127935,
    "HH's Considered": 127.935,
    "contribution per HH": 2557.42065,
    "Assuming Revenue": 255.74206500000003,
    "Revenue Per month": 7672.261950000001,
    "YEAR_1": 51081.15283690501,
    "YEAR_2": 112851.30102255,
    "YEAR_3": 127103.29482087003,
    "YEAR_4": 141355.28861919002,
    "YEAR_5": 155607.28241751002,
    "YEAR_6": 169859.27621583003,
    "YEAR_7": 184111.27001415,
    "YEAR_8": 198363.26381247,
    "YEAR_9": 212615.25761079,
    "YEAR_10": 226867.25140911,
    "SUBSCRIPTION_Museo": 1579814.638779375
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Museo",
    "No of Households": 283100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 127395,
    "HH's Considered": 127.395,
    "contribution per HH": 2546.62605,
    "Assuming Revenue": 254.66260499999999,
    "Revenue Per month": 7639.87815,
    "YEAR_1": 50865.544734885,
    "YEAR_2": 112374.96770835,
    "YEAR_3": 126566.80535979,
    "YEAR_4": 140758.64301123,
    "YEAR_5": 154950.48066267,
    "YEAR_6": 169142.31831410996,
    "YEAR_7": 183334.15596555,
    "YEAR_8": 197525.99361699,
    "YEAR_9": 211717.83126842996,
    "YEAR_10": 225909.66891986996,
    "SUBSCRIPTION_Museo": 1573146.4095618748
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Museo",
    "No of Households": 282090,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 126940.5,
    "HH's Considered": 126.9405,
    "contribution per HH": 2537.540595,
    "Assuming Revenue": 253.7540595,
    "Revenue Per month": 7612.621785,
    "YEAR_1": 50684.0745823515,
    "YEAR_2": 111974.05383556499,
    "YEAR_3": 126115.26006338102,
    "YEAR_4": 140256.466291197,
    "YEAR_5": 154397.672519013,
    "YEAR_6": 168538.87874682902,
    "YEAR_7": 182680.08497464503,
    "YEAR_8": 196821.29120246106,
    "YEAR_9": 210962.49743027697,
    "YEAR_10": 225103.70365809297,
    "SUBSCRIPTION_Museo": 1567533.9833038126
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Museo",
    "No of Households": 276580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 124461,
    "HH's Considered": 124.461,
    "contribution per HH": 2487.9753899999996,
    "Assuming Revenue": 248.79753899999997,
    "Revenue Per month": 7463.926169999999,
    "YEAR_1": 49694.07404724299,
    "YEAR_2": 109786.89003452998,
    "YEAR_3": 123651.87928792198,
    "YEAR_4": 137516.868541314,
    "YEAR_5": 151381.85779470595,
    "YEAR_6": 165246.847048098,
    "YEAR_7": 179111.83630149,
    "YEAR_8": 192976.82555488194,
    "YEAR_9": 206841.81480827395,
    "YEAR_10": 220706.80406166596,
    "SUBSCRIPTION_Museo": 1536915.6974801247
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Museo",
    "No of Households": 265790,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 119605.5,
    "HH's Considered": 119.6055,
    "contribution per HH": 2390.913945,
    "Assuming Revenue": 239.09139449999998,
    "Revenue Per month": 7172.741834999999,
    "YEAR_1": 47755.39786324649,
    "YEAR_2": 105503.85965101497,
    "YEAR_3": 118827.94488371098,
    "YEAR_4": 132152.03011640697,
    "YEAR_5": 145476.11534910297,
    "YEAR_6": 158800.20058179897,
    "YEAR_7": 172124.285814495,
    "YEAR_8": 185448.37104719097,
    "YEAR_9": 198772.45627988697,
    "YEAR_10": 212096.54151258297,
    "SUBSCRIPTION_Museo": 1476957.203099437
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Museo",
    "No of Households": 264420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 118989,
    "HH's Considered": 118.989,
    "contribution per HH": 2378.59011,
    "Assuming Revenue": 237.859011,
    "Revenue Per month": 7135.77033,
    "YEAR_1": 47509.245280107,
    "YEAR_2": 104960.04578397,
    "YEAR_3": 118215.45274897802,
    "YEAR_4": 131470.859713986,
    "YEAR_5": 144726.266678994,
    "YEAR_6": 157981.673644002,
    "YEAR_7": 171237.08060901,
    "YEAR_8": 184492.48757401804,
    "YEAR_9": 197747.894539026,
    "YEAR_10": 211003.30150403403,
    "SUBSCRIPTION_Museo": 1469344.3080761253
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Museo",
    "No of Households": 260780,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 117351,
    "HH's Considered": 117.351,
    "contribution per HH": 2345.84649,
    "Assuming Revenue": 234.584649,
    "Revenue Per month": 7037.539470000001,
    "YEAR_1": 46855.23403731301,
    "YEAR_2": 103515.16806423,
    "YEAR_3": 116588.101383702,
    "YEAR_4": 129661.03470317401,
    "YEAR_5": 142733.968022646,
    "YEAR_6": 155806.90134211804,
    "YEAR_7": 168879.83466159,
    "YEAR_8": 181952.76798106197,
    "YEAR_9": 195025.701300534,
    "YEAR_10": 208098.634620006,
    "SUBSCRIPTION_Museo": 1449117.346116375
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Museo",
    "No of Households": 260220,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 117099,
    "HH's Considered": 117.099,
    "contribution per HH": 2340.80901,
    "Assuming Revenue": 234.080901,
    "Revenue Per month": 7022.427030000001,
    "YEAR_1": 46754.61692303701,
    "YEAR_2": 103292.87918427,
    "YEAR_3": 116337.73963519801,
    "YEAR_4": 129382.60008612603,
    "YEAR_5": 142427.46053705402,
    "YEAR_6": 155472.320987982,
    "YEAR_7": 168517.18143891002,
    "YEAR_8": 181562.041889838,
    "YEAR_9": 194606.90234076598,
    "YEAR_10": 207651.762791694,
    "SUBSCRIPTION_Museo": 1446005.505814875
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Museo",
    "No of Households": 258360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 116262,
    "HH's Considered": 116.262,
    "contribution per HH": 2324.0773799999997,
    "Assuming Revenue": 232.407738,
    "Revenue Per month": 6972.23214,
    "YEAR_1": 46420.424364906,
    "YEAR_2": 102554.56254726002,
    "YEAR_3": 115506.180970524,
    "YEAR_4": 128457.79939378801,
    "YEAR_5": 141409.41781705202,
    "YEAR_6": 154361.036240316,
    "YEAR_7": 167312.65466358,
    "YEAR_8": 180264.27308684398,
    "YEAR_9": 193215.89151010802,
    "YEAR_10": 206167.509933372,
    "SUBSCRIPTION_Museo": 1435669.7505277502
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Museo",
    "No of Households": 254120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 114354,
    "HH's Considered": 114.354,
    "contribution per HH": 2285.93646,
    "Assuming Revenue": 228.593646,
    "Revenue Per month": 6857.809380000001,
    "YEAR_1": 45658.609071102,
    "YEAR_2": 100871.51817042001,
    "YEAR_3": 113610.58487470803,
    "YEAR_4": 126349.65157899601,
    "YEAR_5": 139088.71828328402,
    "YEAR_6": 151827.784987572,
    "YEAR_7": 164566.85169186,
    "YEAR_8": 177305.91839614802,
    "YEAR_9": 190044.985100436,
    "YEAR_10": 202784.05180472403,
    "SUBSCRIPTION_Museo": 1412108.67395925
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Museo",
    "No of Households": 250840,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 112878,
    "HH's Considered": 112.878,
    "contribution per HH": 2256.43122,
    "Assuming Revenue": 225.643122,
    "Revenue Per month": 6769.29366,
    "YEAR_1": 45069.280258914005,
    "YEAR_2": 99569.54044494001,
    "YEAR_3": 112144.180347756,
    "YEAR_4": 124718.82025057201,
    "YEAR_5": 137293.460153388,
    "YEAR_6": 149868.10005620404,
    "YEAR_7": 162442.73995902,
    "YEAR_8": 175017.37986183603,
    "YEAR_9": 187592.019764652,
    "YEAR_10": 200166.659667468,
    "SUBSCRIPTION_Museo": 1393882.18076475
  },
  {
    "DMA": "Macon",
    "PlatformName": "Museo",
    "No of Households": 250620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 112779,
    "HH's Considered": 112.779,
    "contribution per HH": 2254.45221,
    "Assuming Revenue": 225.445221,
    "Revenue Per month": 6763.35663,
    "YEAR_1": 45029.752106877,
    "YEAR_2": 99482.21267067001,
    "YEAR_3": 112045.82394655804,
    "YEAR_4": 124609.43522244597,
    "YEAR_5": 137173.04649833398,
    "YEAR_6": 149736.657774222,
    "YEAR_7": 162300.26905011002,
    "YEAR_8": 174863.88032599798,
    "YEAR_9": 187427.49160188602,
    "YEAR_10": 199991.10287777404,
    "SUBSCRIPTION_Museo": 1392659.672074875
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Museo",
    "No of Households": 249140,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 112113,
    "HH's Considered": 112.113,
    "contribution per HH": 2241.1388699999998,
    "Assuming Revenue": 224.11388699999998,
    "Revenue Per month": 6723.416609999999,
    "YEAR_1": 44763.83544771899,
    "YEAR_2": 98894.73491649,
    "YEAR_3": 111384.153611226,
    "YEAR_4": 123873.57230596198,
    "YEAR_5": 136362.99100069798,
    "YEAR_6": 148852.40969543398,
    "YEAR_7": 161341.82839017,
    "YEAR_8": 173831.24708490598,
    "YEAR_9": 186320.665779642,
    "YEAR_10": 198810.08447437797,
    "SUBSCRIPTION_Museo": 1384435.522706625
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Museo",
    "No of Households": 248110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 111649.5,
    "HH's Considered": 111.6495,
    "contribution per HH": 2231.873505,
    "Assuming Revenue": 223.1873505,
    "Revenue Per month": 6695.6205150000005,
    "YEAR_1": 44578.771826818505,
    "YEAR_2": 98485.88215513501,
    "YEAR_3": 110923.66682379902,
    "YEAR_4": 123361.45149246302,
    "YEAR_5": 135799.236161127,
    "YEAR_6": 148237.02082979103,
    "YEAR_7": 160674.80549845504,
    "YEAR_8": 173112.590167119,
    "YEAR_9": 185550.374835783,
    "YEAR_10": 197988.15950444702,
    "SUBSCRIPTION_Museo": 1378711.9592949376
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Museo",
    "No of Households": 247180,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 111231,
    "HH's Considered": 111.23100000000001,
    "contribution per HH": 2223.50769,
    "Assuming Revenue": 222.350769,
    "Revenue Per month": 6670.52307,
    "YEAR_1": 44411.675547752995,
    "YEAR_2": 98116.72383663,
    "YEAR_3": 110507.88749146197,
    "YEAR_4": 122899.05114629403,
    "YEAR_5": 135290.214801126,
    "YEAR_6": 147681.37845595798,
    "YEAR_7": 160072.54211079,
    "YEAR_8": 172463.70576562203,
    "YEAR_9": 184854.869420454,
    "YEAR_10": 197246.03307528602,
    "SUBSCRIPTION_Museo": 1373544.081651375
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Museo",
    "No of Households": 241200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 108540,
    "HH's Considered": 108.54,
    "contribution per HH": 2169.7146,
    "Assuming Revenue": 216.97145999999998,
    "Revenue Per month": 6509.1438,
    "YEAR_1": 43337.22850602,
    "YEAR_2": 95742.9961542,
    "YEAR_3": 107834.38167708,
    "YEAR_4": 119925.76719996,
    "YEAR_5": 132017.15272284,
    "YEAR_6": 144108.53824572,
    "YEAR_7": 156199.92376859998,
    "YEAR_8": 168291.30929148,
    "YEAR_9": 180382.69481436,
    "YEAR_10": 192474.08033724,
    "SUBSCRIPTION_Museo": 1340314.0727175
  },
  {
    "DMA": "Lafayette, LA",
    "PlatformName": "Museo",
    "No of Households": 240150,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 108067.5,
    "HH's Considered": 108.0675,
    "contribution per HH": 2160.2693249999998,
    "Assuming Revenue": 216.0269325,
    "Revenue Per month": 6480.807975,
    "YEAR_1": 43148.5714167525,
    "YEAR_2": 95326.204504275,
    "YEAR_3": 107364.953398635,
    "YEAR_4": 119403.702292995,
    "YEAR_5": 131442.45118735498,
    "YEAR_6": 143481.20008171498,
    "YEAR_7": 155519.948976075,
    "YEAR_8": 167558.697870435,
    "YEAR_9": 179597.44676479496,
    "YEAR_10": 191636.19565915497,
    "SUBSCRIPTION_Museo": 1334479.3721521874
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Museo",
    "No of Households": 235290,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 105880.5,
    "HH's Considered": 105.8805,
    "contribution per HH": 2116.551195,
    "Assuming Revenue": 211.6551195,
    "Revenue Per month": 6349.653585,
    "YEAR_1": 42275.358603571505,
    "YEAR_2": 93397.054581765,
    "YEAR_3": 105192.17108126098,
    "YEAR_4": 116987.287580757,
    "YEAR_5": 128782.40408025299,
    "YEAR_6": 140577.520579749,
    "YEAR_7": 152372.63707924497,
    "YEAR_8": 164167.753578741,
    "YEAR_9": 175962.870078237,
    "YEAR_10": 187757.98657773298,
    "SUBSCRIPTION_Museo": 1307473.0438213123
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Museo",
    "No of Households": 232880,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 104796,
    "HH's Considered": 104.796,
    "contribution per HH": 2094.8720399999997,
    "Assuming Revenue": 209.487204,
    "Revenue Per month": 6284.61612,
    "YEAR_1": 41842.345665348,
    "YEAR_2": 92440.41850908002,
    "YEAR_3": 104114.72141359201,
    "YEAR_4": 115789.02431810401,
    "YEAR_5": 127463.327222616,
    "YEAR_6": 139137.630127128,
    "YEAR_7": 150811.93303164,
    "YEAR_8": 162486.23593615202,
    "YEAR_9": 174160.53884066397,
    "YEAR_10": 185834.841745176,
    "SUBSCRIPTION_Museo": 1294081.0168094998
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Museo",
    "No of Households": 226710,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 102019.5,
    "HH's Considered": 102.01950000000001,
    "contribution per HH": 2039.369805,
    "Assuming Revenue": 203.9369805,
    "Revenue Per month": 6118.109415,
    "YEAR_1": 40733.7606741285,
    "YEAR_2": 89991.271385235,
    "YEAR_3": 101356.27143453903,
    "YEAR_4": 112721.27148384298,
    "YEAR_5": 124086.271533147,
    "YEAR_6": 135451.27158245098,
    "YEAR_7": 146816.271631755,
    "YEAR_8": 158181.27168105904,
    "YEAR_9": 169546.271730363,
    "YEAR_10": 180911.27177966695,
    "SUBSCRIPTION_Museo": 1259795.2049161873
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Museo",
    "No of Households": 219540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 98793,
    "HH's Considered": 98.793,
    "contribution per HH": 1974.87207,
    "Assuming Revenue": 197.487207,
    "Revenue Per month": 5924.61621,
    "YEAR_1": 39445.502264559,
    "YEAR_2": 87145.17983288999,
    "YEAR_3": 98150.74690458599,
    "YEAR_4": 109156.313976282,
    "YEAR_5": 120161.88104797801,
    "YEAR_6": 131167.448119674,
    "YEAR_7": 142173.01519136998,
    "YEAR_8": 153178.582263066,
    "YEAR_9": 164184.14933476198,
    "YEAR_10": 175189.716406458,
    "SUBSCRIPTION_Museo": 1219952.535341625
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Museo",
    "No of Households": 208490,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 93820.5,
    "HH's Considered": 93.8205,
    "contribution per HH": 1875.4717949999997,
    "Assuming Revenue": 187.54717949999997,
    "Revenue Per month": 5626.415384999999,
    "YEAR_1": 37460.1109917915,
    "YEAR_2": 82758.94389796497,
    "YEAR_3": 93210.573117141,
    "YEAR_4": 103662.20233631699,
    "YEAR_5": 114113.83155549297,
    "YEAR_6": 124565.46077466899,
    "YEAR_7": 135017.08999384497,
    "YEAR_8": 145468.71921302096,
    "YEAR_9": 155920.348432197,
    "YEAR_10": 166371.97765137296,
    "SUBSCRIPTION_Museo": 1158549.2579638122
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Museo",
    "No of Households": 197080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 88686,
    "HH's Considered": 88.686,
    "contribution per HH": 1772.83314,
    "Assuming Revenue": 177.28331400000002,
    "Revenue Per month": 5318.49942,
    "YEAR_1": 35410.037288418,
    "YEAR_2": 78229.80796878,
    "YEAR_3": 88109.45249137201,
    "YEAR_4": 97989.097013964,
    "YEAR_5": 107868.74153655599,
    "YEAR_6": 117748.38605914802,
    "YEAR_7": 127628.03058174,
    "YEAR_8": 137507.67510433198,
    "YEAR_9": 147387.319626924,
    "YEAR_10": 157266.964149516,
    "SUBSCRIPTION_Museo": 1095145.5118207498
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Museo",
    "No of Households": 188980,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 85041,
    "HH's Considered": 85.041,
    "contribution per HH": 1699.9695899999997,
    "Assuming Revenue": 169.99695899999998,
    "Revenue Per month": 5099.908769999999,
    "YEAR_1": 33954.682599782995,
    "YEAR_2": 75014.55809793,
    "YEAR_3": 84488.14862908199,
    "YEAR_4": 93961.739160234,
    "YEAR_5": 103435.329691386,
    "YEAR_6": 112908.92022253797,
    "YEAR_7": 122382.51075368996,
    "YEAR_8": 131856.10128484198,
    "YEAR_9": 141329.69181599398,
    "YEAR_10": 150803.28234714599,
    "SUBSCRIPTION_Museo": 1050134.9646026248
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Museo",
    "No of Households": 188900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 85005,
    "HH's Considered": 85.005,
    "contribution per HH": 1699.2499499999997,
    "Assuming Revenue": 169.92499499999997,
    "Revenue Per month": 5097.749849999999,
    "YEAR_1": 33940.308726314994,
    "YEAR_2": 74982.80254364997,
    "YEAR_3": 84452.38266500998,
    "YEAR_4": 93921.96278636999,
    "YEAR_5": 103391.54290773,
    "YEAR_6": 112861.12302909001,
    "YEAR_7": 122330.70315044996,
    "YEAR_8": 131800.28327180998,
    "YEAR_9": 141269.86339316997,
    "YEAR_10": 150739.44351452996,
    "SUBSCRIPTION_Museo": 1049690.415988125
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Museo",
    "No of Households": 188520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 84834,
    "HH's Considered": 84.834,
    "contribution per HH": 1695.8316599999998,
    "Assuming Revenue": 169.583166,
    "Revenue Per month": 5087.49498,
    "YEAR_1": 33872.032827342,
    "YEAR_2": 74831.96366082001,
    "YEAR_3": 84282.49433566802,
    "YEAR_4": 93733.02501051601,
    "YEAR_5": 103183.55568536399,
    "YEAR_6": 112634.08636021202,
    "YEAR_7": 122084.61703506003,
    "YEAR_8": 131535.147709908,
    "YEAR_9": 140985.678384756,
    "YEAR_10": 150436.20905960404,
    "SUBSCRIPTION_Museo": 1047578.81006925
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Museo",
    "No of Households": 185470,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 83461.5,
    "HH's Considered": 83.4615,
    "contribution per HH": 1668.3953849999998,
    "Assuming Revenue": 166.8395385,
    "Revenue Per month": 5005.186155,
    "YEAR_1": 33324.028901374506,
    "YEAR_2": 73621.283153895,
    "YEAR_3": 82918.91695542299,
    "YEAR_4": 92216.55075695098,
    "YEAR_5": 101514.184558479,
    "YEAR_6": 110811.81836000699,
    "YEAR_7": 120109.452161535,
    "YEAR_8": 129407.08596306301,
    "YEAR_9": 138704.71976459102,
    "YEAR_10": 148002.353566119,
    "SUBSCRIPTION_Museo": 1030630.3941414375
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Museo",
    "No of Households": 183100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 82395,
    "HH's Considered": 82.395,
    "contribution per HH": 1647.07605,
    "Assuming Revenue": 164.707605,
    "Revenue Per month": 4941.22815,
    "YEAR_1": 32898.202899885,
    "YEAR_2": 72680.52485834999,
    "YEAR_3": 81859.35026979001,
    "YEAR_4": 91038.17568123,
    "YEAR_5": 100217.00109266999,
    "YEAR_6": 109395.82650411,
    "YEAR_7": 118574.65191554998,
    "YEAR_8": 127753.47732699,
    "YEAR_9": 136932.30273843,
    "YEAR_10": 146111.12814987,
    "SUBSCRIPTION_Museo": 1017460.641436875
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Museo",
    "No of Households": 181720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 81774,
    "HH's Considered": 81.774,
    "contribution per HH": 1634.6622599999998,
    "Assuming Revenue": 163.466226,
    "Revenue Per month": 4903.98678,
    "YEAR_1": 32650.253582562003,
    "YEAR_2": 72132.74154702,
    "YEAR_3": 81242.387389548,
    "YEAR_4": 90352.03323207599,
    "YEAR_5": 99461.67907460398,
    "YEAR_6": 108571.32491713198,
    "YEAR_7": 117680.97075966002,
    "YEAR_8": 126790.61660218802,
    "YEAR_9": 135900.262444716,
    "YEAR_10": 145009.908287244,
    "SUBSCRIPTION_Museo": 1009792.17783675
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Museo",
    "No of Households": 181440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 81648,
    "HH's Considered": 81.648,
    "contribution per HH": 1632.1435199999999,
    "Assuming Revenue": 163.214352,
    "Revenue Per month": 4896.43056,
    "YEAR_1": 32599.945025424,
    "YEAR_2": 72021.59710704,
    "YEAR_3": 81117.20651529598,
    "YEAR_4": 90212.815923552,
    "YEAR_5": 99308.425331808,
    "YEAR_6": 108404.03474006399,
    "YEAR_7": 117499.64414831996,
    "YEAR_8": 126595.25355657603,
    "YEAR_9": 135690.862964832,
    "YEAR_10": 144786.47237308798,
    "SUBSCRIPTION_Museo": 1008236.257686
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Museo",
    "No of Households": 174760,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 78642,
    "HH's Considered": 78.642,
    "contribution per HH": 1572.0535799999998,
    "Assuming Revenue": 157.205358,
    "Revenue Per month": 4716.160739999999,
    "YEAR_1": 31399.726590845996,
    "YEAR_2": 69370.00832466,
    "YEAR_3": 78130.748515284,
    "YEAR_4": 86891.48870590798,
    "YEAR_5": 95652.22889653197,
    "YEAR_6": 104412.96908715597,
    "YEAR_7": 113173.70927777998,
    "YEAR_8": 121934.449468404,
    "YEAR_9": 130695.189659028,
    "YEAR_10": 139455.929849652,
    "SUBSCRIPTION_Museo": 971116.44837525
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Museo",
    "No of Households": 173940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 78273,
    "HH's Considered": 78.273,
    "contribution per HH": 1564.67727,
    "Assuming Revenue": 156.467727,
    "Revenue Per month": 4694.0318099999995,
    "YEAR_1": 31252.394387798995,
    "YEAR_2": 69044.51389328999,
    "YEAR_3": 77764.14738354599,
    "YEAR_4": 86483.780873802,
    "YEAR_5": 95203.414364058,
    "YEAR_6": 103923.04785431398,
    "YEAR_7": 112642.68134456997,
    "YEAR_8": 121362.31483482599,
    "YEAR_9": 130081.94832508198,
    "YEAR_10": 138801.58181533797,
    "SUBSCRIPTION_Museo": 966559.8250766248
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Museo",
    "No of Households": 173370,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 78016.5,
    "HH's Considered": 78.01650000000001,
    "contribution per HH": 1559.549835,
    "Assuming Revenue": 155.95498350000003,
    "Revenue Per month": 4678.649505000001,
    "YEAR_1": 31149.980539339507,
    "YEAR_2": 68818.25556904502,
    "YEAR_3": 77509.31488953301,
    "YEAR_4": 86200.37421002102,
    "YEAR_5": 94891.43353050902,
    "YEAR_6": 103582.49285099702,
    "YEAR_7": 112273.55217148503,
    "YEAR_8": 120964.61149197305,
    "YEAR_9": 129655.67081246104,
    "YEAR_10": 138346.73013294904,
    "SUBSCRIPTION_Museo": 963392.4161983128
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Museo",
    "No of Households": 171450,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 77152.5,
    "HH's Considered": 77.1525,
    "contribution per HH": 1542.2784749999998,
    "Assuming Revenue": 154.2278475,
    "Revenue Per month": 4626.835425,
    "YEAR_1": 30805.007576107502,
    "YEAR_2": 68056.12226632501,
    "YEAR_3": 76650.93175180501,
    "YEAR_4": 85245.74123728502,
    "YEAR_5": 93840.550722765,
    "YEAR_6": 102435.36020824501,
    "YEAR_7": 111030.16969372498,
    "YEAR_8": 119624.97917920502,
    "YEAR_9": 128219.78866468501,
    "YEAR_10": 136814.598150165,
    "SUBSCRIPTION_Museo": 952723.2494503125
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Museo",
    "No of Households": 168960,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 76032,
    "HH's Considered": 76.032,
    "contribution per HH": 1519.8796799999998,
    "Assuming Revenue": 151.987968,
    "Revenue Per month": 4559.63904,
    "YEAR_1": 30357.620764416002,
    "YEAR_2": 67067.73063936,
    "YEAR_3": 75537.716120064,
    "YEAR_4": 84007.701600768,
    "YEAR_5": 92477.687081472,
    "YEAR_6": 100947.672562176,
    "YEAR_7": 109417.65804288,
    "YEAR_8": 117887.64352358399,
    "YEAR_9": 126357.62900428798,
    "YEAR_10": 134827.61448499202,
    "SUBSCRIPTION_Museo": 938886.673824
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Museo",
    "No of Households": 168210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 75694.5,
    "HH's Considered": 75.6945,
    "contribution per HH": 1513.133055,
    "Assuming Revenue": 151.3133055,
    "Revenue Per month": 4539.399165000001,
    "YEAR_1": 30222.8657006535,
    "YEAR_2": 66770.02231798501,
    "YEAR_3": 75202.410206889,
    "YEAR_4": 83634.79809579301,
    "YEAR_5": 92067.18598469702,
    "YEAR_6": 100499.57387360102,
    "YEAR_7": 108931.96176250503,
    "YEAR_8": 117364.34965140904,
    "YEAR_9": 125796.73754031301,
    "YEAR_10": 134229.12542921703,
    "SUBSCRIPTION_Museo": 934719.0305630628
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Museo",
    "No of Households": 166190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 74785.5,
    "HH's Considered": 74.7855,
    "contribution per HH": 1494.962145,
    "Assuming Revenue": 149.4962145,
    "Revenue Per month": 4484.886435,
    "YEAR_1": 29859.9253955865,
    "YEAR_2": 65968.19457241501,
    "YEAR_3": 74299.319614071,
    "YEAR_4": 82630.44465572701,
    "YEAR_5": 90961.569697383,
    "YEAR_6": 99292.694739039,
    "YEAR_7": 107623.81978069499,
    "YEAR_8": 115954.94482235101,
    "YEAR_9": 124286.069864007,
    "YEAR_10": 132617.19490566303,
    "SUBSCRIPTION_Museo": 923494.1780469374
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Museo",
    "No of Households": 161210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 72544.5,
    "HH's Considered": 72.5445,
    "contribution per HH": 1450.1645549999998,
    "Assuming Revenue": 145.01645549999998,
    "Revenue Per month": 4350.493664999999,
    "YEAR_1": 28965.15177220349,
    "YEAR_2": 63991.411318484985,
    "YEAR_3": 72072.88835058898,
    "YEAR_4": 80154.365382693,
    "YEAR_5": 88235.84241479698,
    "YEAR_6": 96317.31944690098,
    "YEAR_7": 104398.79647900497,
    "YEAR_8": 112480.27351110896,
    "YEAR_9": 120561.75054321297,
    "YEAR_10": 128643.22757531695,
    "SUBSCRIPTION_Museo": 895821.0267943123
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Museo",
    "No of Households": 160200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 72090,
    "HH's Considered": 72.09,
    "contribution per HH": 1441.0791,
    "Assuming Revenue": 144.10791,
    "Revenue Per month": 4323.2373,
    "YEAR_1": 28783.681619670002,
    "YEAR_2": 63590.4974457,
    "YEAR_3": 71621.34305417999,
    "YEAR_4": 79652.18866266002,
    "YEAR_5": 87683.03427114,
    "YEAR_6": 95713.87987962,
    "YEAR_7": 103744.72548810001,
    "YEAR_8": 111775.57109658,
    "YEAR_9": 119806.41670506,
    "YEAR_10": 127837.26231353999,
    "SUBSCRIPTION_Museo": 890208.6005362499
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Museo",
    "No of Households": 155590,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 70015.5,
    "HH's Considered": 70.0155,
    "contribution per HH": 1399.609845,
    "Assuming Revenue": 139.9609845,
    "Revenue Per month": 4198.829535,
    "YEAR_1": 27955.387161076495,
    "YEAR_2": 61760.58363031499,
    "YEAR_3": 69560.329374531,
    "YEAR_4": 77360.07511874699,
    "YEAR_5": 85159.82086296301,
    "YEAR_6": 92959.56660717899,
    "YEAR_7": 100759.312351395,
    "YEAR_8": 108559.058095611,
    "YEAR_9": 116358.80383982699,
    "YEAR_10": 124158.54958404298,
    "SUBSCRIPTION_Museo": 864591.4866256875
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Museo",
    "No of Households": 155440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 69948,
    "HH's Considered": 69.94800000000001,
    "contribution per HH": 1398.26052,
    "Assuming Revenue": 139.826052,
    "Revenue Per month": 4194.78156,
    "YEAR_1": 27928.436148324,
    "YEAR_2": 61701.04196604001,
    "YEAR_3": 69493.268191896,
    "YEAR_4": 77285.494417752,
    "YEAR_5": 85077.72064360802,
    "YEAR_6": 92869.946869464,
    "YEAR_7": 100662.17309532,
    "YEAR_8": 108454.39932117601,
    "YEAR_9": 116246.62554703202,
    "YEAR_10": 124038.85177288801,
    "SUBSCRIPTION_Museo": 863757.9579735
  },
  {
    "DMA": "Erie",
    "PlatformName": "Museo",
    "No of Households": 152420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 68589,
    "HH's Considered": 68.589,
    "contribution per HH": 1371.0941099999998,
    "Assuming Revenue": 137.109411,
    "Revenue Per month": 4113.28233,
    "YEAR_1": 27385.822424907,
    "YEAR_2": 60502.269791970015,
    "YEAR_3": 68143.10304817799,
    "YEAR_4": 75783.936304386,
    "YEAR_5": 83424.769560594,
    "YEAR_6": 91065.60281680198,
    "YEAR_7": 98706.43607301,
    "YEAR_8": 106347.26932921799,
    "YEAR_9": 113988.10258542601,
    "YEAR_10": 121628.935841634,
    "SUBSCRIPTION_Museo": 846976.2477761249
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Museo",
    "No of Households": 152190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 68485.5,
    "HH's Considered": 68.4855,
    "contribution per HH": 1369.0251449999998,
    "Assuming Revenue": 136.9025145,
    "Revenue Per month": 4107.075435,
    "YEAR_1": 27344.497538686497,
    "YEAR_2": 60410.972573415,
    "YEAR_3": 68040.275901471,
    "YEAR_4": 75669.579229527,
    "YEAR_5": 83298.882557583,
    "YEAR_6": 90928.185885639,
    "YEAR_7": 98557.489213695,
    "YEAR_8": 106186.79254175098,
    "YEAR_9": 113816.095869807,
    "YEAR_10": 121445.39919786298,
    "SUBSCRIPTION_Museo": 845698.1705094373
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Museo",
    "No of Households": 151160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 68022,
    "HH's Considered": 68.022,
    "contribution per HH": 1359.75978,
    "Assuming Revenue": 135.97597800000003,
    "Revenue Per month": 4079.279340000001,
    "YEAR_1": 27159.43391778601,
    "YEAR_2": 60002.11981206001,
    "YEAR_3": 67579.78911404402,
    "YEAR_4": 75157.458416028,
    "YEAR_5": 82735.12771801202,
    "YEAR_6": 90312.79701999601,
    "YEAR_7": 97890.46632198003,
    "YEAR_8": 105468.13562396402,
    "YEAR_9": 113045.80492594802,
    "YEAR_10": 120623.47422793202,
    "SUBSCRIPTION_Museo": 839974.6070977502
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Museo",
    "No of Households": 150700,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.001,
    "Contribution": 19.99,
    "Assuming": 0.1,
    "DMA Consdered": "Y",
    "Increse in Households every month": 0.0129,
    "Holiday Months": 6,
    "No of Smart Households": 67815,
    "HH's Considered": 67.815,
    "contribution per HH": 1355.6218499999998,
    "Assuming Revenue": 135.56218499999997,
    "Revenue Per month": 4066.865549999999,
    "YEAR_1": 27076.78414534499,
    "YEAR_2": 59819.52537495,
    "YEAR_3": 67374.13482062997,
    "YEAR_4": 74928.74426630998,
    "YEAR_5": 82483.35371198998,
    "YEAR_6": 90037.96315766998,
    "YEAR_7": 97592.57260334998,
    "YEAR_8": 105147.18204902994,
    "YEAR_9": 112701.79149470998,
    "YEAR_10": 120256.40094038995,
    "SUBSCRIPTION_Museo": 837418.4525643748
  },
  {
    "DMA": "New York",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 5830635000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 291531750,
    "Annual Police Budget for Communication": 2915317.5,
    "Per Month": 242943.125,
    "YEAR_1": 2250770.875875,
    "YEAR_2": 3129593.33625,
    "YEAR_3": 3276525.33825,
    "YEAR_4": 3423457.3402500004,
    "YEAR_5": 3570389.34225,
    "YEAR_6": 3717321.34425,
    "YEAR_7": 3864253.3462500004,
    "YEAR_8": 4011185.34825,
    "YEAR_9": 4158117.35025,
    "YEAR_10": 4305049.35225,
    "FIXED_Aegis": 35706662.974125005
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 3200000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 160000000,
    "Annual Police Budget for Communication": 1600000,
    "Per Month": 133333.33333333334,
    "YEAR_1": 1235280,
    "YEAR_2": 1717599.9999999998,
    "YEAR_3": 1798239.9999999998,
    "YEAR_4": 1878879.9999999998,
    "YEAR_5": 1959519.9999999998,
    "YEAR_6": 2040159.9999999998,
    "YEAR_7": 2120799.9999999995,
    "YEAR_8": 2201439.9999999995,
    "YEAR_9": 2282079.9999999995,
    "YEAR_10": 2362720,
    "FIXED_Aegis": 19596720
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1940000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 97000000,
    "Annual Police Budget for Communication": 970000,
    "Per Month": 80833.33333333333,
    "YEAR_1": 748888.5,
    "YEAR_2": 1041295.0000000001,
    "YEAR_3": 1090183.0000000002,
    "YEAR_4": 1139071.0000000002,
    "YEAR_5": 1187959,
    "YEAR_6": 1236847,
    "YEAR_7": 1285735,
    "YEAR_8": 1334623,
    "YEAR_9": 1383510.9999999993,
    "YEAR_10": 1432398.9999999993,
    "FIXED_Aegis": 11880511.5
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1300000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 65000000,
    "Annual Police Budget for Communication": 650000,
    "Per Month": 54166.666666666664,
    "YEAR_1": 501832.50000000006,
    "YEAR_2": 697775,
    "YEAR_3": 730534.9999999999,
    "YEAR_4": 763294.9999999999,
    "YEAR_5": 796054.9999999998,
    "YEAR_6": 828814.9999999997,
    "YEAR_7": 861574.9999999997,
    "YEAR_8": 894334.9999999997,
    "YEAR_9": 927094.9999999997,
    "YEAR_10": 959854.9999999997,
    "FIXED_Aegis": 7961167.5
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1072261852,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 53613092.6,
    "Annual Police Budget for Communication": 536130.926,
    "Per Month": 44677.57716666666,
    "YEAR_1": 413919.8814183,
    "YEAR_2": 575536.5490609999,
    "YEAR_3": 602557.5477314,
    "YEAR_4": 629578.5464017999,
    "YEAR_5": 656599.5450722,
    "YEAR_6": 683620.5437425999,
    "YEAR_7": 710641.5424129999,
    "YEAR_8": 737662.5410833998,
    "YEAR_9": 764683.5397538,
    "YEAR_10": 791704.5384241999,
    "FIXED_Aegis": 6566504.775101699
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 273600000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 13680000,
    "Annual Police Budget for Communication": 136800,
    "Per Month": 11400,
    "YEAR_1": 105616.44,
    "YEAR_2": 146854.8,
    "YEAR_3": 153749.52,
    "YEAR_4": 160644.24,
    "YEAR_5": 167538.96000000002,
    "YEAR_6": 174433.68,
    "YEAR_7": 181328.40000000002,
    "YEAR_8": 188223.12,
    "YEAR_9": 195117.84,
    "YEAR_10": 202012.56000000003,
    "FIXED_Aegis": 1675519.5600000003
  },
  {
    "DMA": "Houston",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1018736302,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 50936815.1,
    "Annual Police Budget for Communication": 509368.151,
    "Per Month": 42447.345916666665,
    "YEAR_1": 393257.68097955,
    "YEAR_2": 546806.7100985,
    "YEAR_3": 572478.8649089,
    "YEAR_4": 598151.0197193,
    "YEAR_5": 623823.1745297,
    "YEAR_6": 649495.3293401,
    "YEAR_7": 675167.4841504999,
    "YEAR_8": 700839.6389609001,
    "YEAR_9": 726511.7937712999,
    "YEAR_10": 752183.9485817,
    "FIXED_Aegis": 6238715.64504045
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 512735177,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 25636758.85,
    "Annual Police Budget for Communication": 256367.5885,
    "Per Month": 21363.965708333333,
    "YEAR_1": 197928.596701425,
    "YEAR_2": 275210.60625475,
    "YEAR_3": 288131.53271515,
    "YEAR_4": 301052.4591755501,
    "YEAR_5": 313973.38563594996,
    "YEAR_6": 326894.31209634995,
    "YEAR_7": 339815.23855675,
    "YEAR_8": 352736.16501715,
    "YEAR_9": 365657.09147755,
    "YEAR_10": 378578.01793795,
    "FIXED_Aegis": 3139977.4055685746
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1300000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 65000000,
    "Annual Police Budget for Communication": 650000,
    "Per Month": 54166.666666666664,
    "YEAR_1": 501832.50000000006,
    "YEAR_2": 697775,
    "YEAR_3": 730534.9999999999,
    "YEAR_4": 763294.9999999999,
    "YEAR_5": 796054.9999999998,
    "YEAR_6": 828814.9999999997,
    "YEAR_7": 861574.9999999997,
    "YEAR_8": 894334.9999999997,
    "YEAR_9": 927094.9999999997,
    "YEAR_10": 959854.9999999997,
    "FIXED_Aegis": 7961167.5
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1985500529,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 99275026.45,
    "Annual Police Budget for Communication": 992750.2645,
    "Per Month": 82729.18870833334,
    "YEAR_1": 766452.8417072251,
    "YEAR_2": 1065717.40894075,
    "YEAR_3": 1115752.02227155,
    "YEAR_4": 1165786.63560235,
    "YEAR_5": 1215821.2489331502,
    "YEAR_6": 1265855.86226395,
    "YEAR_7": 1315890.4755947501,
    "YEAR_8": 1365925.0889255502,
    "YEAR_9": 1415959.70225635,
    "YEAR_10": 1465994.3155871502,
    "FIXED_Aegis": 12159155.602082776
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 631238667,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 31561933.35,
    "Annual Police Budget for Communication": 315619.3335,
    "Per Month": 26301.611125,
    "YEAR_1": 243673.906428675,
    "YEAR_2": 338817.35451225,
    "YEAR_3": 354724.56892065,
    "YEAR_4": 370631.78332905,
    "YEAR_5": 386538.99773745,
    "YEAR_6": 402446.21214584995,
    "YEAR_7": 418353.42655425,
    "YEAR_8": 434260.64096265,
    "YEAR_9": 450167.85537104995,
    "YEAR_10": 466075.06977945,
    "FIXED_Aegis": 3865689.8157413253
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 533175917,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 26658795.85,
    "Annual Police Budget for Communication": 266587.9585,
    "Per Month": 22215.663208333335,
    "YEAR_1": 205819.23335992504,
    "YEAR_2": 286182.17344975,
    "YEAR_3": 299618.20655815006,
    "YEAR_4": 313054.23966655,
    "YEAR_5": 326490.2727749501,
    "YEAR_6": 339926.30588335,
    "YEAR_7": 353362.33899175003,
    "YEAR_8": 366798.37210015004,
    "YEAR_9": 380234.40520855,
    "YEAR_10": 393670.43831695005,
    "FIXED_Aegis": 3265155.9863100755
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 957000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 47850000,
    "Annual Police Budget for Communication": 478500,
    "Per Month": 39875,
    "YEAR_1": 369425.925,
    "YEAR_2": 513669.75,
    "YEAR_3": 537786.15,
    "YEAR_4": 561902.5499999999,
    "YEAR_5": 586018.9500000001,
    "YEAR_6": 610135.35,
    "YEAR_7": 634251.75,
    "YEAR_8": 658368.15,
    "YEAR_9": 682484.5499999999,
    "YEAR_10": 706600.95,
    "FIXED_Aegis": 5860644.075
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 366000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 18300000,
    "Annual Police Budget for Communication": 183000,
    "Per Month": 15250,
    "YEAR_1": 141285.15,
    "YEAR_2": 196450.5,
    "YEAR_3": 205673.7,
    "YEAR_4": 214896.9,
    "YEAR_5": 224120.09999999998,
    "YEAR_6": 233343.3,
    "YEAR_7": 242566.5,
    "YEAR_8": 251789.7,
    "YEAR_9": 261012.9,
    "YEAR_10": 270236.1,
    "FIXED_Aegis": 2241374.85
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 291761377,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 14588068.850000001,
    "Annual Police Budget for Communication": 145880.68850000002,
    "Per Month": 12156.724041666668,
    "YEAR_1": 112627.185556425,
    "YEAR_2": 156602.91910475,
    "YEAR_3": 163955.30580515,
    "YEAR_4": 171307.69250555002,
    "YEAR_5": 178660.07920595005,
    "YEAR_6": 186012.46590635003,
    "YEAR_7": 193364.85260675,
    "YEAR_8": 200717.23930715,
    "YEAR_9": 208069.62600755002,
    "YEAR_10": 215422.01270795002,
    "FIXED_Aegis": 1786739.3787135752
  },
  {
    "DMA": "Denver",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 611000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 30550000,
    "Annual Police Budget for Communication": 305500,
    "Per Month": 25458.333333333332,
    "YEAR_1": 235861.275,
    "YEAR_2": 327954.25,
    "YEAR_3": 343351.44999999995,
    "YEAR_4": 358748.65,
    "YEAR_5": 374145.85000000003,
    "YEAR_6": 389543.04999999993,
    "YEAR_7": 404940.24999999994,
    "YEAR_8": 420337.44999999995,
    "YEAR_9": 435734.6499999999,
    "YEAR_10": 451131.85000000003,
    "FIXED_Aegis": 3741748.7249999996
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1180000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 59000000,
    "Annual Police Budget for Communication": 590000,
    "Per Month": 49166.666666666664,
    "YEAR_1": 455509.50000000006,
    "YEAR_2": 633365,
    "YEAR_3": 663101,
    "YEAR_4": 692837,
    "YEAR_5": 722572.9999999999,
    "YEAR_6": 752308.9999999999,
    "YEAR_7": 782044.9999999999,
    "YEAR_8": 811780.9999999997,
    "YEAR_9": 841516.9999999997,
    "YEAR_10": 871252.9999999997,
    "FIXED_Aegis": 7226290.5
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1400000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 70000000,
    "Annual Police Budget for Communication": 700000,
    "Per Month": 58333.333333333336,
    "YEAR_1": 540435,
    "YEAR_2": 751450.0000000001,
    "YEAR_3": 786730.0000000002,
    "YEAR_4": 822010.0000000003,
    "YEAR_5": 857290.0000000003,
    "YEAR_6": 892570.0000000003,
    "YEAR_7": 927850.0000000003,
    "YEAR_8": 963130.0000000003,
    "YEAR_9": 998410.0000000003,
    "YEAR_10": 1033690.0000000003,
    "FIXED_Aegis": 8573565.000000002
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 275850281,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 13792514.05,
    "Annual Police Budget for Communication": 137925.1405,
    "Per Month": 11493.761708333333,
    "YEAR_1": 106485.10472302501,
    "YEAR_2": 148062.63832675002,
    "YEAR_3": 155014.06540795,
    "YEAR_4": 161965.49248915,
    "YEAR_5": 168916.91957035,
    "YEAR_6": 175868.34665155,
    "YEAR_7": 182819.77373274998,
    "YEAR_8": 189771.20081394998,
    "YEAR_9": 196722.62789515,
    "YEAR_10": 203674.05497635002,
    "FIXED_Aegis": 1689300.224586975
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 426481382,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 21324069.1,
    "Annual Police Budget for Communication": 213240.69100000002,
    "Per Month": 17770.057583333335,
    "YEAR_1": 164632.47548655,
    "YEAR_2": 228913.88178849997,
    "YEAR_3": 239661.21261490005,
    "YEAR_4": 250408.5434413,
    "YEAR_5": 261155.87426770004,
    "YEAR_6": 271903.2050941,
    "YEAR_7": 282650.53592050006,
    "YEAR_8": 293397.8667469001,
    "YEAR_9": 304145.1975733001,
    "YEAR_10": 314892.52839970007,
    "FIXED_Aegis": 2611761.3213334503
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 317600000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 15880000,
    "Annual Police Budget for Communication": 158800,
    "Per Month": 13233.333333333334,
    "YEAR_1": 122601.54,
    "YEAR_2": 170471.8,
    "YEAR_3": 178475.32,
    "YEAR_4": 186478.84,
    "YEAR_5": 194482.36000000002,
    "YEAR_6": 202485.88000000003,
    "YEAR_7": 210489.4,
    "YEAR_8": 218492.92000000004,
    "YEAR_9": 226496.44,
    "YEAR_10": 234499.96000000002,
    "FIXED_Aegis": 1944974.46
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 255647467,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 12782373.350000001,
    "Annual Police Budget for Communication": 127823.73350000002,
    "Per Month": 10651.977791666668,
    "YEAR_1": 98686.31344867502,
    "YEAR_2": 137218.77791225002,
    "YEAR_3": 143661.09408065001,
    "YEAR_4": 150103.41024905,
    "YEAR_5": 156545.72641745003,
    "YEAR_6": 162988.04258585005,
    "YEAR_7": 169430.35875425005,
    "YEAR_8": 175872.67492265,
    "YEAR_9": 182314.99109105003,
    "YEAR_10": 188757.30725945003,
    "FIXED_Aegis": 1565578.696721325
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 240610298,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 12030514.9,
    "Annual Police Budget for Communication": 120305.149,
    "Per Month": 10025.429083333334,
    "YEAR_1": 92881.59028545002,
    "YEAR_2": 129147.57745150001,
    "YEAR_3": 135210.9569611,
    "YEAR_4": 141274.33647070001,
    "YEAR_5": 147337.7159803,
    "YEAR_6": 153401.0954899,
    "YEAR_7": 159464.4749995,
    "YEAR_8": 165527.85450910003,
    "YEAR_9": 171591.2340187,
    "YEAR_10": 177654.61352830002,
    "FIXED_Aegis": 1473491.4496945501
  },
  {
    "DMA": "Saint Louis",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 181079000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 9053950,
    "Annual Police Budget for Communication": 90539.5,
    "Per Month": 7544.958333333333,
    "YEAR_1": 69901.02097499999,
    "YEAR_2": 97194.15324999999,
    "YEAR_3": 101757.34405,
    "YEAR_4": 106320.53485,
    "YEAR_5": 110883.72565,
    "YEAR_6": 115446.91645,
    "YEAR_7": 120010.10725,
    "YEAR_8": 124573.29804999998,
    "YEAR_9": 129136.48885,
    "YEAR_10": 133699.67965,
    "FIXED_Aegis": 1108923.2690249998
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 284230093,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 14211504.65,
    "Annual Police Budget for Communication": 142115.0465,
    "Per Month": 11842.920541666666,
    "YEAR_1": 109719.92165032499,
    "YEAR_2": 152560.50241775002,
    "YEAR_3": 159723.10076135,
    "YEAR_4": 166885.69910495,
    "YEAR_5": 174048.29744854997,
    "YEAR_6": 181210.89579215,
    "YEAR_7": 188373.49413574996,
    "YEAR_8": 195536.09247935,
    "YEAR_9": 202698.69082294998,
    "YEAR_10": 209861.28916654998,
    "FIXED_Aegis": 1740617.983779675
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 118585446,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 5929272.300000001,
    "Annual Police Budget for Communication": 59292.723000000005,
    "Per Month": 4941.06025,
    "YEAR_1": 45776.94679215,
    "YEAR_2": 63650.738140500005,
    "YEAR_3": 66639.09137970001,
    "YEAR_4": 69627.44461890002,
    "YEAR_5": 72615.79785809999,
    "YEAR_6": 75604.1510973,
    "YEAR_7": 78592.5043365,
    "YEAR_8": 81580.8575757,
    "YEAR_9": 84569.21081490001,
    "YEAR_10": 87557.5640541,
    "FIXED_Aegis": 726214.30666785
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 734000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 36700000,
    "Annual Police Budget for Communication": 367000,
    "Per Month": 30583.333333333332,
    "YEAR_1": 283342.35,
    "YEAR_2": 393974.5,
    "YEAR_3": 412471.3,
    "YEAR_4": 430968.1,
    "YEAR_5": 449464.9,
    "YEAR_6": 467961.7,
    "YEAR_7": 486458.5,
    "YEAR_8": 504955.3,
    "YEAR_9": 523452.1,
    "YEAR_10": 541948.8999999999,
    "FIXED_Aegis": 4494997.65
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 525100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 26255000,
    "Annual Police Budget for Communication": 262550,
    "Per Month": 21879.166666666668,
    "YEAR_1": 202701.7275,
    "YEAR_2": 281847.425,
    "YEAR_3": 295079.94500000007,
    "YEAR_4": 308312.46499999997,
    "YEAR_5": 321544.985,
    "YEAR_6": 334777.505,
    "YEAR_7": 348010.025,
    "YEAR_8": 361242.54500000004,
    "YEAR_9": 374475.065,
    "YEAR_10": 387707.585,
    "FIXED_Aegis": 3215699.2724999995
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 103977042,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 5198852.100000001,
    "Annual Police Budget for Communication": 51988.52100000001,
    "Per Month": 4332.37675,
    "YEAR_1": 40137.737638050006,
    "YEAR_2": 55809.6772935,
    "YEAR_3": 58429.8987519,
    "YEAR_4": 61050.12021030001,
    "YEAR_5": 63670.34166870002,
    "YEAR_6": 66290.5631271,
    "YEAR_7": 68910.7845855,
    "YEAR_8": 71531.00604390001,
    "YEAR_9": 74151.22750230001,
    "YEAR_10": 76771.44896070001,
    "FIXED_Aegis": 636752.8057819502
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 593292386,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 29664619.3,
    "Annual Police Budget for Communication": 296646.193,
    "Per Month": 24720.516083333336,
    "YEAR_1": 229025.69330565003,
    "YEAR_2": 318449.68818550004,
    "YEAR_3": 333400.65631270007,
    "YEAR_4": 348351.62443990004,
    "YEAR_5": 363302.5925671,
    "YEAR_6": 378253.5606943,
    "YEAR_7": 393204.5288215,
    "YEAR_8": 408155.49694870005,
    "YEAR_9": 423106.4650759001,
    "YEAR_10": 438057.4332031,
    "FIXED_Aegis": 3633307.7395543503
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 501294814,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 25064740.700000003,
    "Annual Police Budget for Communication": 250647.40700000004,
    "Per Month": 20887.28391666667,
    "YEAR_1": 193512.33057435005,
    "YEAR_2": 269069.99141450005,
    "YEAR_3": 281702.62072730006,
    "YEAR_4": 294335.2500401,
    "YEAR_5": 306967.8793529,
    "YEAR_6": 319600.5086657,
    "YEAR_7": 332233.1379785,
    "YEAR_8": 344865.7672913,
    "YEAR_9": 357498.3966041,
    "YEAR_10": 370131.0259169,
    "FIXED_Aegis": 3069916.90856565
  },
  {
    "DMA": "Columbus, OH",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 478000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 23900000,
    "Annual Police Budget for Communication": 239000,
    "Per Month": 19916.666666666668,
    "YEAR_1": 184519.95,
    "YEAR_2": 256566.50000000003,
    "YEAR_3": 268612.10000000003,
    "YEAR_4": 280657.7,
    "YEAR_5": 292703.3,
    "YEAR_6": 304748.9,
    "YEAR_7": 316794.5,
    "YEAR_8": 328840.10000000003,
    "YEAR_9": 340885.7,
    "YEAR_10": 352931.3,
    "FIXED_Aegis": 2927260.0500000003
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 284500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 14225000,
    "Annual Police Budget for Communication": 142250,
    "Per Month": 11854.166666666666,
    "YEAR_1": 109824.11249999999,
    "YEAR_2": 152705.375,
    "YEAR_3": 159874.775,
    "YEAR_4": 167044.175,
    "YEAR_5": 174213.575,
    "YEAR_6": 181382.975,
    "YEAR_7": 188552.375,
    "YEAR_8": 195721.775,
    "YEAR_9": 202891.175,
    "YEAR_10": 210060.575,
    "FIXED_Aegis": 1742270.8874999997
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 93050846,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4652542.3,
    "Annual Police Budget for Communication": 46525.423,
    "Per Month": 3877.1185833333334,
    "YEAR_1": 35919.95282715,
    "YEAR_2": 49945.0415905,
    "YEAR_3": 52289.922909700006,
    "YEAR_4": 54634.80422890001,
    "YEAR_5": 56979.685548099995,
    "YEAR_6": 59324.5668673,
    "YEAR_7": 61669.4481865,
    "YEAR_8": 64014.3295057,
    "YEAR_9": 66359.2108249,
    "YEAR_10": 68704.0921441,
    "FIXED_Aegis": 569841.05463285
  },
  {
    "DMA": "Austin",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 476508288,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 23825414.400000002,
    "Annual Police Budget for Communication": 238254.14400000003,
    "Per Month": 19854.512000000002,
    "YEAR_1": 183944.11187520003,
    "YEAR_2": 255765.823584,
    "YEAR_3": 267773.83244160004,
    "YEAR_4": 279781.8412992,
    "YEAR_5": 291789.85015680006,
    "YEAR_6": 303797.8590144,
    "YEAR_7": 315805.86787200003,
    "YEAR_8": 327813.87672959996,
    "YEAR_9": 339821.8855872,
    "YEAR_10": 351829.89444480004,
    "FIXED_Aegis": 2918124.8430048004
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 168190360,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 8409518,
    "Annual Police Budget for Communication": 84095.18000000001,
    "Per Month": 7007.931666666667,
    "YEAR_1": 64925.68371900001,
    "YEAR_2": 90276.17573000002,
    "YEAR_3": 94514.57280200001,
    "YEAR_4": 98752.96987400003,
    "YEAR_5": 102991.366946,
    "YEAR_6": 107229.76401799999,
    "YEAR_7": 111468.16109000001,
    "YEAR_8": 115706.558162,
    "YEAR_9": 119944.95523400001,
    "YEAR_10": 124183.35230600002,
    "FIXED_Aegis": 1029993.5598810001
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1100000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 55000000,
    "Annual Police Budget for Communication": 550000,
    "Per Month": 45833.333333333336,
    "YEAR_1": 424627.49999999994,
    "YEAR_2": 590425,
    "YEAR_3": 618145,
    "YEAR_4": 645865,
    "YEAR_5": 673585,
    "YEAR_6": 701305,
    "YEAR_7": 729025.0000000001,
    "YEAR_8": 756745.0000000001,
    "YEAR_9": 784465.0000000001,
    "YEAR_10": 812185.0000000003,
    "FIXED_Aegis": 6736372.5
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 270400000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 13520000,
    "Annual Police Budget for Communication": 135200,
    "Per Month": 11266.666666666666,
    "YEAR_1": 104381.15999999999,
    "YEAR_2": 145137.2,
    "YEAR_3": 151951.28,
    "YEAR_4": 158765.36,
    "YEAR_5": 165579.43999999997,
    "YEAR_6": 172393.51999999996,
    "YEAR_7": 179207.6,
    "YEAR_8": 186021.68,
    "YEAR_9": 192835.76,
    "YEAR_10": 199649.84,
    "FIXED_Aegis": 1655922.84
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 953000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 47650000,
    "Annual Police Budget for Communication": 476500,
    "Per Month": 39708.333333333336,
    "YEAR_1": 367881.825,
    "YEAR_2": 511522.75000000006,
    "YEAR_3": 535538.35,
    "YEAR_4": 559553.9500000001,
    "YEAR_5": 583569.55,
    "YEAR_6": 607585.1500000001,
    "YEAR_7": 631600.75,
    "YEAR_8": 655616.3500000001,
    "YEAR_9": 679631.95,
    "YEAR_10": 703647.5499999999,
    "FIXED_Aegis": 5836148.175000001
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 172878488,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 8643924.4,
    "Annual Police Budget for Communication": 86439.244,
    "Per Month": 7203.270333333334,
    "YEAR_1": 66735.4183302,
    "YEAR_2": 92792.52843399999,
    "YEAR_3": 97149.06633160001,
    "YEAR_4": 101505.6042292,
    "YEAR_5": 105862.1421268,
    "YEAR_6": 110218.68002439999,
    "YEAR_7": 114575.21792200001,
    "YEAR_8": 118931.75581960002,
    "YEAR_9": 123288.2937172,
    "YEAR_10": 127644.8316148,
    "FIXED_Aegis": 1058703.5385498
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 665000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 33250000,
    "Annual Police Budget for Communication": 332500,
    "Per Month": 27708.333333333332,
    "YEAR_1": 256706.62500000003,
    "YEAR_2": 356938.74999999994,
    "YEAR_3": 373696.74999999994,
    "YEAR_4": 390454.74999999994,
    "YEAR_5": 407212.7499999998,
    "YEAR_6": 423970.7499999998,
    "YEAR_7": 440728.7499999998,
    "YEAR_8": 457486.7499999998,
    "YEAR_9": 474244.7499999998,
    "YEAR_10": 491002.7499999998,
    "FIXED_Aegis": 4072443.3749999995
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 185955117,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 9297755.85,
    "Annual Police Budget for Communication": 92977.5585,
    "Per Month": 7748.129875,
    "YEAR_1": 71783.32403992499,
    "YEAR_2": 99811.40904975,
    "YEAR_3": 104497.47799814999,
    "YEAR_4": 109183.54694654999,
    "YEAR_5": 113869.61589495,
    "YEAR_6": 118555.68484334998,
    "YEAR_7": 123241.75379175,
    "YEAR_8": 127927.82274014999,
    "YEAR_9": 132613.89168855,
    "YEAR_10": 137299.96063694998,
    "FIXED_Aegis": 1138784.4876300748
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1700000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 85000000,
    "Annual Police Budget for Communication": 850000,
    "Per Month": 70833.33333333333,
    "YEAR_1": 656242.5,
    "YEAR_2": 912475.0000000001,
    "YEAR_3": 955315.0000000001,
    "YEAR_4": 998155.0000000001,
    "YEAR_5": 1040995.0000000001,
    "YEAR_6": 1083835.0000000002,
    "YEAR_7": 1126675.0000000002,
    "YEAR_8": 1169515,
    "YEAR_9": 1212354.9999999995,
    "YEAR_10": 1255194.9999999995,
    "FIXED_Aegis": 10410757.5
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 800000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 40000000,
    "Annual Police Budget for Communication": 400000,
    "Per Month": 33333.333333333336,
    "YEAR_1": 308820,
    "YEAR_2": 429399.99999999994,
    "YEAR_3": 449559.99999999994,
    "YEAR_4": 469719.99999999994,
    "YEAR_5": 489879.99999999994,
    "YEAR_6": 510039.99999999994,
    "YEAR_7": 530199.9999999999,
    "YEAR_8": 550359.9999999999,
    "YEAR_9": 570519.9999999999,
    "YEAR_10": 590680,
    "FIXED_Aegis": 4899180
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 293000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 14650000,
    "Annual Police Budget for Communication": 146500,
    "Per Month": 12208.333333333334,
    "YEAR_1": 113105.32500000001,
    "YEAR_2": 157267.75,
    "YEAR_3": 164651.35,
    "YEAR_4": 172034.95,
    "YEAR_5": 179418.55,
    "YEAR_6": 186802.15,
    "YEAR_7": 194185.75,
    "YEAR_8": 201569.35,
    "YEAR_9": 208952.95,
    "YEAR_10": 216336.55,
    "FIXED_Aegis": 1794324.6750000003
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 163911659,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 8195582.95,
    "Annual Police Budget for Communication": 81955.8295,
    "Per Month": 6829.652458333334,
    "YEAR_1": 63273.998165475,
    "YEAR_2": 87979.58296825002,
    "YEAR_3": 92110.15677505001,
    "YEAR_4": 96240.73058185002,
    "YEAR_5": 100371.30438865,
    "YEAR_6": 104501.87819545002,
    "YEAR_7": 108632.45200225001,
    "YEAR_8": 112763.02580905001,
    "YEAR_9": 116893.59961585002,
    "YEAR_10": 121024.17342265,
    "FIXED_Aegis": 1003790.9019245252
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 196116407,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 9805820.35,
    "Annual Police Budget for Communication": 98058.2035,
    "Per Month": 8171.516958333334,
    "YEAR_1": 75705.836012175,
    "YEAR_2": 105265.48145725,
    "YEAR_3": 110207.61491365,
    "YEAR_4": 115149.74837005,
    "YEAR_5": 120091.88182645002,
    "YEAR_6": 125034.01528284998,
    "YEAR_7": 129976.14873925,
    "YEAR_8": 134918.28219565,
    "YEAR_9": 139860.41565205,
    "YEAR_10": 144802.54910844998,
    "FIXED_Aegis": 1201011.9735578252
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 220496500,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 11024825,
    "Annual Police Budget for Communication": 110248.25,
    "Per Month": 9187.354166666666,
    "YEAR_1": 85117.16141249999,
    "YEAR_2": 118351.496375,
    "YEAR_3": 123908.008175,
    "YEAR_4": 129464.51997499999,
    "YEAR_5": 135021.03177499998,
    "YEAR_6": 140577.543575,
    "YEAR_7": 146134.055375,
    "YEAR_8": 151690.56717499997,
    "YEAR_9": 157247.07897499995,
    "YEAR_10": 162803.590775,
    "FIXED_Aegis": 1350315.0535875
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 780000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 39000000,
    "Annual Police Budget for Communication": 390000,
    "Per Month": 32500,
    "YEAR_1": 301099.5,
    "YEAR_2": 418665,
    "YEAR_3": 438321,
    "YEAR_4": 457977,
    "YEAR_5": 477633,
    "YEAR_6": 497289,
    "YEAR_7": 516945,
    "YEAR_8": 536601,
    "YEAR_9": 556257,
    "YEAR_10": 575913,
    "FIXED_Aegis": 4776700.5
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 208956813,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 10447840.65,
    "Annual Police Budget for Communication": 104478.40650000001,
    "Per Month": 8706.533875000001,
    "YEAR_1": 80662.553738325,
    "YEAR_2": 112157.56937775001,
    "YEAR_3": 117423.28106535002,
    "YEAR_4": 122688.99275295001,
    "YEAR_5": 127954.70444055002,
    "YEAR_6": 133220.41612815,
    "YEAR_7": 138486.12781575002,
    "YEAR_8": 143751.83950335,
    "YEAR_9": 149017.55119095,
    "YEAR_10": 154283.26287855,
    "FIXED_Aegis": 1279646.298891675
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 225280000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 11264000,
    "Annual Police Budget for Communication": 112640,
    "Per Month": 9386.666666666666,
    "YEAR_1": 86963.71199999998,
    "YEAR_2": 120919.04,
    "YEAR_3": 126596.096,
    "YEAR_4": 132273.15199999997,
    "YEAR_5": 137950.20799999998,
    "YEAR_6": 143627.26400000002,
    "YEAR_7": 149304.32,
    "YEAR_8": 154981.37599999993,
    "YEAR_9": 160658.432,
    "YEAR_10": 166335.48799999998,
    "FIXED_Aegis": 1379609.088
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 281479497,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 14073974.850000001,
    "Annual Police Budget for Communication": 140739.74850000002,
    "Per Month": 11728.312375000001,
    "YEAR_1": 108658.122829425,
    "YEAR_2": 151084.12001475005,
    "YEAR_3": 158177.40333915,
    "YEAR_4": 165270.68666355003,
    "YEAR_5": 172363.96998795005,
    "YEAR_6": 179457.25331235002,
    "YEAR_7": 186550.53663675,
    "YEAR_8": 193643.81996115003,
    "YEAR_9": 200737.10328555,
    "YEAR_10": 207830.38660995004,
    "FIXED_Aegis": 1723773.402640575
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 240187600,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 12009380,
    "Annual Police Budget for Communication": 120093.8,
    "Per Month": 10007.816666666668,
    "YEAR_1": 92718.41829000002,
    "YEAR_2": 128920.6943,
    "YEAR_3": 134973.42182000002,
    "YEAR_4": 141026.14934,
    "YEAR_5": 147078.87686,
    "YEAR_6": 153131.60438000003,
    "YEAR_7": 159184.3319,
    "YEAR_8": 165237.05942,
    "YEAR_9": 171289.78694,
    "YEAR_10": 177342.51446000003,
    "FIXED_Aegis": 1470902.8577100001
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 316191931,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 15809596.55,
    "Annual Police Budget for Communication": 158095.96550000002,
    "Per Month": 13174.663791666668,
    "YEAR_1": 122057.99016427502,
    "YEAR_2": 169716.01896425002,
    "YEAR_3": 177684.05562545,
    "YEAR_4": 185652.09228665,
    "YEAR_5": 193620.12894785,
    "YEAR_6": 201588.16560905002,
    "YEAR_7": 209556.20227025004,
    "YEAR_8": 217524.23893145003,
    "YEAR_9": 225492.27559265002,
    "YEAR_10": 233460.31225385002,
    "FIXED_Aegis": 1936351.4806457253
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 223500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 11175000,
    "Annual Police Budget for Communication": 111750,
    "Per Month": 9312.5,
    "YEAR_1": 86276.5875,
    "YEAR_2": 119963.625,
    "YEAR_3": 125595.825,
    "YEAR_4": 131228.025,
    "YEAR_5": 136860.225,
    "YEAR_6": 142492.425,
    "YEAR_7": 148124.625,
    "YEAR_8": 153756.825,
    "YEAR_9": 159389.025,
    "YEAR_10": 165021.225,
    "FIXED_Aegis": 1368708.4124999999
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 380000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 19000000,
    "Annual Police Budget for Communication": 190000,
    "Per Month": 15833.333333333334,
    "YEAR_1": 146689.50000000003,
    "YEAR_2": 203965.0000000001,
    "YEAR_3": 213541.0000000001,
    "YEAR_4": 223117.0000000001,
    "YEAR_5": 232693.0000000001,
    "YEAR_6": 242269.0000000001,
    "YEAR_7": 251845.0000000001,
    "YEAR_8": 261421.0000000001,
    "YEAR_9": 270997.00000000006,
    "YEAR_10": 280573.00000000006,
    "FIXED_Aegis": 2327110.5000000005
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 42098849,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2104942.45,
    "Annual Police Budget for Communication": 21049.4245,
    "Per Month": 1754.1187083333334,
    "YEAR_1": 16251.208185225,
    "YEAR_2": 22596.55720075,
    "YEAR_3": 23657.44819555,
    "YEAR_4": 24718.33919035,
    "YEAR_5": 25779.23018515,
    "YEAR_6": 26840.121179950005,
    "YEAR_7": 27901.01217475,
    "YEAR_8": 28961.90316955,
    "YEAR_9": 30022.79416435,
    "YEAR_10": 31083.68515915,
    "FIXED_Aegis": 257812.298804775
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 176500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 8825000,
    "Annual Police Budget for Communication": 88250,
    "Per Month": 7354.166666666667,
    "YEAR_1": 68133.4125,
    "YEAR_2": 94736.375,
    "YEAR_3": 99184.175,
    "YEAR_4": 103631.975,
    "YEAR_5": 108079.775,
    "YEAR_6": 112527.575,
    "YEAR_7": 116975.375,
    "YEAR_8": 121423.175,
    "YEAR_9": 125870.975,
    "YEAR_10": 130318.77500000002,
    "FIXED_Aegis": 1080881.5875
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 470000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 23500000,
    "Annual Police Budget for Communication": 235000,
    "Per Month": 19583.333333333332,
    "YEAR_1": 181431.75,
    "YEAR_2": 252272.50000000003,
    "YEAR_3": 264116.5,
    "YEAR_4": 275960.50000000006,
    "YEAR_5": 287804.5,
    "YEAR_6": 299648.5,
    "YEAR_7": 311492.5,
    "YEAR_8": 323336.5,
    "YEAR_9": 335180.4999999998,
    "YEAR_10": 347024.4999999998,
    "FIXED_Aegis": 2878268.25
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 165000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 8250000,
    "Annual Police Budget for Communication": 82500,
    "Per Month": 6875,
    "YEAR_1": 63694.125,
    "YEAR_2": 88563.75,
    "YEAR_3": 92721.75,
    "YEAR_4": 96879.75,
    "YEAR_5": 101037.75,
    "YEAR_6": 105195.75,
    "YEAR_7": 109353.75,
    "YEAR_8": 113511.75,
    "YEAR_9": 117669.75,
    "YEAR_10": 121827.75,
    "FIXED_Aegis": 1010455.875
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 77285380,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3864269,
    "Annual Police Budget for Communication": 38642.69,
    "Per Month": 3220.224166666667,
    "YEAR_1": 29834.0888145,
    "YEAR_2": 41482.927715,
    "YEAR_3": 43430.519291,
    "YEAR_4": 45378.110867,
    "YEAR_5": 47325.702442999995,
    "YEAR_6": 49273.29401900001,
    "YEAR_7": 51220.885595000014,
    "YEAR_8": 53168.477171000006,
    "YEAR_9": 55116.06874699999,
    "YEAR_10": 57063.660323000004,
    "FIXED_Aegis": 473293.7349855
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 144899000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 7244950,
    "Annual Police Budget for Communication": 72449.5,
    "Per Month": 6037.458333333333,
    "YEAR_1": 55934.63647500001,
    "YEAR_2": 77774.53825,
    "YEAR_3": 81425.99305000002,
    "YEAR_4": 85077.44784999998,
    "YEAR_5": 88728.90264999999,
    "YEAR_6": 92380.35745,
    "YEAR_7": 96031.81225,
    "YEAR_8": 99683.26704999998,
    "YEAR_9": 103334.72184999999,
    "YEAR_10": 106986.17664999998,
    "FIXED_Aegis": 887357.8535249999
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 103000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 5150000,
    "Annual Police Budget for Communication": 51500,
    "Per Month": 4291.666666666667,
    "YEAR_1": 39760.575000000004,
    "YEAR_2": 55285.25000000001,
    "YEAR_3": 57880.85,
    "YEAR_4": 60476.45,
    "YEAR_5": 63072.04999999999,
    "YEAR_6": 65667.65000000001,
    "YEAR_7": 68263.25,
    "YEAR_8": 70858.85,
    "YEAR_9": 73454.45000000001,
    "YEAR_10": 76050.04999999999,
    "FIXED_Aegis": 630769.425
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 80197258,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4009862.9000000004,
    "Annual Police Budget for Communication": 40098.62900000001,
    "Per Month": 3341.5524166666673,
    "YEAR_1": 30958.146519450005,
    "YEAR_2": 43045.878231500006,
    "YEAR_3": 45066.84913310001,
    "YEAR_4": 47087.82003470001,
    "YEAR_5": 49108.79093630001,
    "YEAR_6": 51129.76183790001,
    "YEAR_7": 53150.73273950001,
    "YEAR_8": 55171.703641100015,
    "YEAR_9": 57192.67454270001,
    "YEAR_10": 59213.645444300004,
    "FIXED_Aegis": 491126.00306055014
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 213832312,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 10691615.600000001,
    "Annual Police Budget for Communication": 106916.15600000002,
    "Per Month": 8909.679666666669,
    "YEAR_1": 82544.61823980002,
    "YEAR_2": 114774.49346600001,
    "YEAR_3": 120163.06772840004,
    "YEAR_4": 125551.64199080002,
    "YEAR_5": 130940.21625320002,
    "YEAR_6": 136328.79051560003,
    "YEAR_7": 141717.36477800002,
    "YEAR_8": 147105.93904040003,
    "YEAR_9": 152494.51330280004,
    "YEAR_10": 157883.0875652,
    "FIXED_Aegis": 1309503.7328802003
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 312266304,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 15613315.200000001,
    "Annual Police Budget for Communication": 156133.152,
    "Per Month": 13011.096,
    "YEAR_1": 120542.6000016,
    "YEAR_2": 167608.93867199996,
    "YEAR_3": 175478.04953279998,
    "YEAR_4": 183347.16039359997,
    "YEAR_5": 191216.27125439997,
    "YEAR_6": 199085.38211519996,
    "YEAR_7": 206954.49297599998,
    "YEAR_8": 214823.6038368,
    "YEAR_9": 222692.7146976,
    "YEAR_10": 230561.82555839999,
    "FIXED_Aegis": 1912311.0390384
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 72940720,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3647036,
    "Annual Police Budget for Communication": 36470.36,
    "Per Month": 3039.1966666666667,
    "YEAR_1": 28156.941438000005,
    "YEAR_2": 39150.93146,
    "YEAR_3": 40989.037604,
    "YEAR_4": 42827.143748,
    "YEAR_5": 44665.249892,
    "YEAR_6": 46503.356036000005,
    "YEAR_7": 48341.46218,
    "YEAR_8": 50179.56832400001,
    "YEAR_9": 52017.674468,
    "YEAR_10": 53855.780612,
    "FIXED_Aegis": 446687.145762
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 74216221,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3710811.0500000003,
    "Annual Police Budget for Communication": 37108.1105,
    "Per Month": 3092.342541666667,
    "YEAR_1": 28649.316711525003,
    "YEAR_2": 39835.55662175,
    "YEAR_3": 41705.80539095,
    "YEAR_4": 43576.05416015,
    "YEAR_5": 45446.30292935,
    "YEAR_6": 47316.55169855,
    "YEAR_7": 49186.80046775,
    "YEAR_8": 51057.04923695,
    "YEAR_9": 52927.29800615,
    "YEAR_10": 54797.54677535,
    "FIXED_Aegis": 454498.281998475
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 220000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 11000000,
    "Annual Police Budget for Communication": 110000,
    "Per Month": 9166.666666666666,
    "YEAR_1": 84925.5,
    "YEAR_2": 118085.00000000001,
    "YEAR_3": 123629.00000000001,
    "YEAR_4": 129173.00000000001,
    "YEAR_5": 134717.00000000003,
    "YEAR_6": 140261.00000000003,
    "YEAR_7": 145805,
    "YEAR_8": 151349,
    "YEAR_9": 156892.99999999994,
    "YEAR_10": 162436.9999999999,
    "FIXED_Aegis": 1347274.5
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 125700000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 6285000,
    "Annual Police Budget for Communication": 62850,
    "Per Month": 5237.5,
    "YEAR_1": 48523.3425,
    "YEAR_2": 67469.475,
    "YEAR_3": 70637.115,
    "YEAR_4": 73804.755,
    "YEAR_5": 76972.39499999999,
    "YEAR_6": 80140.03499999999,
    "YEAR_7": 83307.67499999999,
    "YEAR_8": 86475.315,
    "YEAR_9": 89642.955,
    "YEAR_10": 92810.595,
    "FIXED_Aegis": 769783.6574999999
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 24735000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1236750,
    "Annual Police Budget for Communication": 12367.5,
    "Per Month": 1030.625,
    "YEAR_1": 9548.328375,
    "YEAR_2": 13276.511250000001,
    "YEAR_3": 13899.833249999998,
    "YEAR_4": 14523.155250000002,
    "YEAR_5": 15146.477250000002,
    "YEAR_6": 15769.799249999998,
    "YEAR_7": 16393.121250000004,
    "YEAR_8": 17016.44325,
    "YEAR_9": 17639.765249999997,
    "YEAR_10": 18263.087250000004,
    "FIXED_Aegis": 151476.52162500002
  },
  {
    "DMA": "Madison",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 85100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4255000,
    "Annual Police Budget for Communication": 42550,
    "Per Month": 3545.8333333333335,
    "YEAR_1": 32850.7275,
    "YEAR_2": 45677.424999999996,
    "YEAR_3": 47821.94500000001,
    "YEAR_4": 49966.465,
    "YEAR_5": 52110.985,
    "YEAR_6": 54255.505000000005,
    "YEAR_7": 56400.025,
    "YEAR_8": 58544.545000000006,
    "YEAR_9": 60689.065,
    "YEAR_10": 62833.585,
    "FIXED_Aegis": 521150.2725
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 178400000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 8920000,
    "Annual Police Budget for Communication": 89200,
    "Per Month": 7433.333333333333,
    "YEAR_1": 68866.85999999999,
    "YEAR_2": 95756.20000000001,
    "YEAR_3": 100251.88,
    "YEAR_4": 104747.56,
    "YEAR_5": 109243.24,
    "YEAR_6": 113738.92000000001,
    "YEAR_7": 118234.6,
    "YEAR_8": 122730.28,
    "YEAR_9": 127225.95999999999,
    "YEAR_10": 131721.63999999998,
    "FIXED_Aegis": 1092517.14
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 346000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 17300000,
    "Annual Police Budget for Communication": 173000,
    "Per Month": 14416.666666666666,
    "YEAR_1": 133564.65,
    "YEAR_2": 185715.5,
    "YEAR_3": 194434.7,
    "YEAR_4": 203153.90000000002,
    "YEAR_5": 211873.1,
    "YEAR_6": 220592.3,
    "YEAR_7": 229311.5,
    "YEAR_8": 238030.7,
    "YEAR_9": 246749.90000000002,
    "YEAR_10": 255469.1,
    "FIXED_Aegis": 2118895.35
  },
  {
    "DMA": "Springfield, MO",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 52269039,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2613451.95,
    "Annual Police Budget for Communication": 26134.519500000002,
    "Per Month": 2177.8766250000003,
    "YEAR_1": 20177.155779975,
    "YEAR_2": 28055.40668325001,
    "YEAR_3": 29372.58646605001,
    "YEAR_4": 30689.766248850006,
    "YEAR_5": 32006.946031650008,
    "YEAR_6": 33324.12581445,
    "YEAR_7": 34641.30559725001,
    "YEAR_8": 35958.485380050006,
    "YEAR_9": 37275.665162850004,
    "YEAR_10": 38592.84494565,
    "FIXED_Aegis": 320094.28811002505
  },
  {
    "DMA": "Columbia, SC",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 51422302,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2571115.1,
    "Annual Police Budget for Communication": 25711.151,
    "Per Month": 2142.5959166666667,
    "YEAR_1": 19850.294129550002,
    "YEAR_2": 27600.9205985,
    "YEAR_3": 28896.762608899997,
    "YEAR_4": 30192.604619299997,
    "YEAR_5": 31488.446629699996,
    "YEAR_6": 32784.2886401,
    "YEAR_7": 34080.130650499996,
    "YEAR_8": 35375.9726609,
    "YEAR_9": 36671.814671299995,
    "YEAR_10": 37967.6566817,
    "FIXED_Aegis": 314908.89189045003
  },
  {
    "DMA": "Rochester, NY",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 74229700,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3711485,
    "Annual Police Budget for Communication": 37114.85,
    "Per Month": 3092.9041666666667,
    "YEAR_1": 28654.5199425,
    "YEAR_2": 39842.79147499999,
    "YEAR_3": 41713.379915,
    "YEAR_4": 43583.968355000005,
    "YEAR_5": 45454.556795,
    "YEAR_6": 47325.145235,
    "YEAR_7": 49195.733674999996,
    "YEAR_8": 51066.322114999995,
    "YEAR_9": 52936.910554999995,
    "YEAR_10": 54807.498995,
    "FIXED_Aegis": 454580.8270574999
  },
  {
    "DMA": "Portland",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 206418650,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 10320932.5,
    "Annual Police Budget for Communication": 103209.325,
    "Per Month": 8600.777083333332,
    "YEAR_1": 79682.75936624999,
    "YEAR_2": 110795.21038749999,
    "YEAR_3": 115996.96036749998,
    "YEAR_4": 121198.71034749999,
    "YEAR_5": 126400.46032749998,
    "YEAR_6": 131602.21030749998,
    "YEAR_7": 136803.96028749997,
    "YEAR_8": 142005.71026749996,
    "YEAR_9": 147207.46024749998,
    "YEAR_10": 152409.21022749998,
    "FIXED_Aegis": 1264102.6521337496
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 71296636,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3564831.8000000003,
    "Annual Police Budget for Communication": 35648.31800000001,
    "Per Month": 2970.6931666666674,
    "YEAR_1": 27522.283911900002,
    "YEAR_2": 38268.469373000014,
    "YEAR_3": 40065.1446002,
    "YEAR_4": 41861.8198274,
    "YEAR_5": 43658.49505460002,
    "YEAR_6": 45455.17028180001,
    "YEAR_7": 47251.845509000006,
    "YEAR_8": 49048.52073620002,
    "YEAR_9": 50845.1959634,
    "YEAR_10": 52641.8711906,
    "FIXED_Aegis": 436618.8164481001
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 84048006,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4202400.3,
    "Annual Police Budget for Communication": 42024.003,
    "Per Month": 3502.0002499999996,
    "YEAR_1": 32444.631516149995,
    "YEAR_2": 45112.76722049999,
    "YEAR_3": 47230.776971700005,
    "YEAR_4": 49348.7867229,
    "YEAR_5": 51466.79647409999,
    "YEAR_6": 53584.80622529998,
    "YEAR_7": 55702.815976499995,
    "YEAR_8": 57820.825727699994,
    "YEAR_9": 59938.83547889999,
    "YEAR_10": 62056.84523009999,
    "FIXED_Aegis": 514707.8875438499
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 780000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 39000000,
    "Annual Police Budget for Communication": 390000,
    "Per Month": 32500,
    "YEAR_1": 301099.5,
    "YEAR_2": 418665,
    "YEAR_3": 438321,
    "YEAR_4": 457977,
    "YEAR_5": 477633,
    "YEAR_6": 497289,
    "YEAR_7": 516945,
    "YEAR_8": 536601,
    "YEAR_9": 556257,
    "YEAR_10": 575913,
    "FIXED_Aegis": 4776700.5
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 564800000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 28240000,
    "Annual Police Budget for Communication": 282400,
    "Per Month": 23533.333333333332,
    "YEAR_1": 218026.92,
    "YEAR_2": 303156.39999999997,
    "YEAR_3": 317389.36,
    "YEAR_4": 331622.32,
    "YEAR_5": 345855.27999999997,
    "YEAR_6": 360088.23999999993,
    "YEAR_7": 374321.2,
    "YEAR_8": 388554.1599999999,
    "YEAR_9": 402787.12,
    "YEAR_10": 417020.07999999996,
    "FIXED_Aegis": 3458821.08
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 337000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 16850000,
    "Annual Police Budget for Communication": 168500,
    "Per Month": 14041.666666666666,
    "YEAR_1": 130090.42499999999,
    "YEAR_2": 180884.75,
    "YEAR_3": 189377.15,
    "YEAR_4": 197869.55,
    "YEAR_5": 206361.95,
    "YEAR_6": 214854.35,
    "YEAR_7": 223346.75,
    "YEAR_8": 231839.15,
    "YEAR_9": 240331.55,
    "YEAR_10": 248823.94999999995,
    "FIXED_Aegis": 2063779.575
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 85435603,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4271780.15,
    "Annual Police Budget for Communication": 42717.8015,
    "Per Month": 3559.8167916666666,
    "YEAR_1": 32980.278648075,
    "YEAR_2": 45857.55991025,
    "YEAR_3": 48010.537105849995,
    "YEAR_4": 50163.51430144999,
    "YEAR_5": 52316.491497049996,
    "YEAR_6": 54469.46869265,
    "YEAR_7": 56622.44588825,
    "YEAR_8": 58775.42308385,
    "YEAR_9": 60928.400279450005,
    "YEAR_10": 63081.37747505,
    "FIXED_Aegis": 523205.49688192504
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 57261600,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2863080,
    "Annual Police Budget for Communication": 28630.8,
    "Per Month": 2385.9,
    "YEAR_1": 22104.409140000003,
    "YEAR_2": 30735.163799999995,
    "YEAR_3": 32178.156120000003,
    "YEAR_4": 33621.14844,
    "YEAR_5": 35064.14076,
    "YEAR_6": 36507.13307999999,
    "YEAR_7": 37950.125400000004,
    "YEAR_8": 39393.11772,
    "YEAR_9": 40836.11004,
    "YEAR_10": 42279.10236,
    "FIXED_Aegis": 350668.60686
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 570000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 28500000,
    "Annual Police Budget for Communication": 285000,
    "Per Month": 23750,
    "YEAR_1": 220034.25,
    "YEAR_2": 305947.5,
    "YEAR_3": 320311.5,
    "YEAR_4": 334675.5,
    "YEAR_5": 349039.5,
    "YEAR_6": 363403.5,
    "YEAR_7": 377767.5,
    "YEAR_8": 392131.5,
    "YEAR_9": 406495.5,
    "YEAR_10": 420859.5,
    "FIXED_Aegis": 3490665.75
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 70209286,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3510464.3000000003,
    "Annual Police Budget for Communication": 35104.643000000004,
    "Per Month": 2925.386916666667,
    "YEAR_1": 27102.53962815,
    "YEAR_2": 37684.8342605,
    "YEAR_3": 39454.1082677,
    "YEAR_4": 41223.3822749,
    "YEAR_5": 42992.6562821,
    "YEAR_6": 44761.93028930001,
    "YEAR_7": 46531.2042965,
    "YEAR_8": 48300.47830370001,
    "YEAR_9": 50069.7523109,
    "YEAR_10": 51839.0263181,
    "FIXED_Aegis": 429959.91223185
  },
  {
    "DMA": "Charleston, SC",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 60782338,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3039116.9000000004,
    "Annual Police Budget for Communication": 30391.169000000005,
    "Per Month": 2532.597416666667,
    "YEAR_1": 23463.50202645,
    "YEAR_2": 32624.919921500008,
    "YEAR_3": 34156.6348391,
    "YEAR_4": 35688.34975670001,
    "YEAR_5": 37220.064674299996,
    "YEAR_6": 38751.779591900005,
    "YEAR_7": 40283.49450950001,
    "YEAR_8": 41815.2094271,
    "YEAR_9": 43346.92434470001,
    "YEAR_10": 44878.63926230001,
    "FIXED_Aegis": 372229.51835355005
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 79026100,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3951305,
    "Annual Police Budget for Communication": 39513.05,
    "Per Month": 3292.754166666667,
    "YEAR_1": 30506.050252500005,
    "YEAR_2": 42417.25917500001,
    "YEAR_3": 44408.716895000005,
    "YEAR_4": 46400.174615,
    "YEAR_5": 48391.63233500001,
    "YEAR_6": 50383.090055,
    "YEAR_7": 52374.547775000014,
    "YEAR_8": 54366.00549500001,
    "YEAR_9": 56357.46321500001,
    "YEAR_10": 58348.920935,
    "FIXED_Aegis": 483953.8607475
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 84594727,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4229736.350000001,
    "Annual Police Budget for Communication": 42297.36350000001,
    "Per Month": 3524.7802916666674,
    "YEAR_1": 32655.679490175007,
    "YEAR_2": 45406.21971725001,
    "YEAR_3": 47538.006837650006,
    "YEAR_4": 49669.79395805002,
    "YEAR_5": 51801.58107845001,
    "YEAR_6": 53933.36819885001,
    "YEAR_7": 56065.155319250014,
    "YEAR_8": 58196.94243965001,
    "YEAR_9": 60328.72956005001,
    "YEAR_10": 62460.51668045002,
    "FIXED_Aegis": 518055.9932798251
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 188972651,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 9448632.55,
    "Annual Police Budget for Communication": 94486.3255,
    "Per Month": 7873.860458333334,
    "YEAR_1": 72948.167602275,
    "YEAR_2": 101431.07042424999,
    "YEAR_3": 106193.18122945,
    "YEAR_4": 110955.29203464999,
    "YEAR_5": 115717.40283985001,
    "YEAR_6": 120479.51364505,
    "YEAR_7": 125241.62445025,
    "YEAR_8": 130003.73525545001,
    "YEAR_9": 134765.84606065,
    "YEAR_10": 139527.95686585,
    "FIXED_Aegis": 1157263.790407725
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 133950000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 6697500,
    "Annual Police Budget for Communication": 66975,
    "Per Month": 5581.25,
    "YEAR_1": 51708.04875,
    "YEAR_2": 71897.6625,
    "YEAR_3": 75273.20250000001,
    "YEAR_4": 78648.7425,
    "YEAR_5": 82024.2825,
    "YEAR_6": 85399.8225,
    "YEAR_7": 88775.3625,
    "YEAR_8": 92150.9025,
    "YEAR_9": 95526.4425,
    "YEAR_10": 98901.98250000001,
    "FIXED_Aegis": 820306.45125
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 200226764,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 10011338.200000001,
    "Annual Police Budget for Communication": 100113.38200000001,
    "Per Month": 8342.781833333334,
    "YEAR_1": 77292.5365731,
    "YEAR_2": 107471.715577,
    "YEAR_3": 112517.43002980002,
    "YEAR_4": 117563.1444826,
    "YEAR_5": 122608.85893540006,
    "YEAR_6": 127654.57338820002,
    "YEAR_7": 132700.28784099998,
    "YEAR_8": 137746.00229380003,
    "YEAR_9": 142791.7167466,
    "YEAR_10": 147837.43119940005,
    "FIXED_Aegis": 1226183.6970669
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 29000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1450000,
    "Annual Police Budget for Communication": 14500,
    "Per Month": 1208.3333333333333,
    "YEAR_1": 11194.725,
    "YEAR_2": 15565.749999999998,
    "YEAR_3": 16296.55,
    "YEAR_4": 17027.35,
    "YEAR_5": 17758.149999999998,
    "YEAR_6": 18488.949999999997,
    "YEAR_7": 19219.75,
    "YEAR_8": 19950.55,
    "YEAR_9": 20681.35,
    "YEAR_10": 21412.15,
    "FIXED_Aegis": 177595.275
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 300000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 15000000,
    "Annual Police Budget for Communication": 150000,
    "Per Month": 12500,
    "YEAR_1": 115807.5,
    "YEAR_2": 161025,
    "YEAR_3": 168585,
    "YEAR_4": 176145,
    "YEAR_5": 183705,
    "YEAR_6": 191265,
    "YEAR_7": 198825,
    "YEAR_8": 206385,
    "YEAR_9": 213945,
    "YEAR_10": 221505,
    "FIXED_Aegis": 1837192.5
  },
  {
    "DMA": "Jackson, MS",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 36746853,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1837342.6500000001,
    "Annual Police Budget for Communication": 18373.4265,
    "Per Month": 1531.1188750000001,
    "YEAR_1": 14185.203929324998,
    "YEAR_2": 19723.873347750003,
    "YEAR_3": 20649.894043349996,
    "YEAR_4": 21575.914738950003,
    "YEAR_5": 22501.935434550003,
    "YEAR_6": 23427.956130150003,
    "YEAR_7": 24353.97682575,
    "YEAR_8": 25279.99752135,
    "YEAR_9": 26206.01821695,
    "YEAR_10": 27132.03891255,
    "FIXED_Aegis": 225036.80910067502
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 290000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 14500000,
    "Annual Police Budget for Communication": 145000,
    "Per Month": 12083.333333333334,
    "YEAR_1": 111947.24999999999,
    "YEAR_2": 155657.5,
    "YEAR_3": 162965.5,
    "YEAR_4": 170273.5,
    "YEAR_5": 177581.50000000003,
    "YEAR_6": 184889.50000000003,
    "YEAR_7": 192197.50000000003,
    "YEAR_8": 199505.5000000001,
    "YEAR_9": 206813.5000000001,
    "YEAR_10": 214121.5000000001,
    "FIXED_Aegis": 1775952.75
  },
  {
    "DMA": "Boise",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 84487908,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4224395.4,
    "Annual Police Budget for Communication": 42243.954000000005,
    "Per Month": 3520.3295000000003,
    "YEAR_1": 32614.444685700004,
    "YEAR_2": 45348.884619000004,
    "YEAR_3": 47477.97990060001,
    "YEAR_4": 49607.0751822,
    "YEAR_5": 51736.1704638,
    "YEAR_6": 53865.2657454,
    "YEAR_7": 55994.361027000006,
    "YEAR_8": 58123.4563086,
    "YEAR_9": 60252.551590200004,
    "YEAR_10": 62381.64687180001,
    "FIXED_Aegis": 517401.8363943
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 550000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 27500000,
    "Annual Police Budget for Communication": 275000,
    "Per Month": 22916.666666666668,
    "YEAR_1": 212313.74999999997,
    "YEAR_2": 295212.5,
    "YEAR_3": 309072.5,
    "YEAR_4": 322932.5,
    "YEAR_5": 336792.5,
    "YEAR_6": 350652.5,
    "YEAR_7": 364512.50000000006,
    "YEAR_8": 378372.50000000006,
    "YEAR_9": 392232.50000000006,
    "YEAR_10": 406092.5000000002,
    "FIXED_Aegis": 3368186.25
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 137000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 6850000,
    "Annual Police Budget for Communication": 68500,
    "Per Month": 5708.333333333333,
    "YEAR_1": 52885.424999999996,
    "YEAR_2": 73534.75,
    "YEAR_3": 76987.15,
    "YEAR_4": 80439.54999999999,
    "YEAR_5": 83891.95,
    "YEAR_6": 87344.34999999999,
    "YEAR_7": 90796.75,
    "YEAR_8": 94249.15,
    "YEAR_9": 97701.54999999999,
    "YEAR_10": 101153.94999999998,
    "FIXED_Aegis": 838984.575
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 28113083,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1405654.1500000001,
    "Annual Police Budget for Communication": 14056.541500000001,
    "Per Month": 1171.3784583333334,
    "YEAR_1": 10852.352865075001,
    "YEAR_2": 15089.69730025,
    "YEAR_3": 15798.146991849997,
    "YEAR_4": 16506.59668345,
    "YEAR_5": 17215.04637505,
    "YEAR_6": 17923.496066649994,
    "YEAR_7": 18631.945758250004,
    "YEAR_8": 19340.39544985,
    "YEAR_9": 20048.845141450005,
    "YEAR_10": 20757.29483305,
    "FIXED_Aegis": 172163.817464925
  },
  {
    "DMA": "Reno",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 150129366,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 7506468.300000001,
    "Annual Police Budget for Communication": 75064.683,
    "Per Month": 6255.39025,
    "YEAR_1": 57953.688510149994,
    "YEAR_2": 80581.93720050002,
    "YEAR_3": 84365.1972237,
    "YEAR_4": 88148.4572469,
    "YEAR_5": 91931.7172701,
    "YEAR_6": 95714.97729329999,
    "YEAR_7": 99498.2373165,
    "YEAR_8": 103281.49733970001,
    "YEAR_9": 107064.7573629,
    "YEAR_10": 110848.0173861,
    "FIXED_Aegis": 919388.48414985
  },
  {
    "DMA": "Washington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 313900000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 15695000,
    "Annual Police Budget for Communication": 156950,
    "Per Month": 13079.166666666666,
    "YEAR_1": 121173.2475,
    "YEAR_2": 168485.825,
    "YEAR_3": 176396.105,
    "YEAR_4": 184306.385,
    "YEAR_5": 192216.66499999998,
    "YEAR_6": 200126.94499999998,
    "YEAR_7": 208037.225,
    "YEAR_8": 215947.505,
    "YEAR_9": 223857.78499999997,
    "YEAR_10": 231768.065,
    "FIXED_Aegis": 1922315.7524999997
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 171100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 8555000,
    "Annual Police Budget for Communication": 85550,
    "Per Month": 7129.166666666667,
    "YEAR_1": 66048.8775,
    "YEAR_2": 91837.925,
    "YEAR_3": 96149.64499999999,
    "YEAR_4": 100461.36500000002,
    "YEAR_5": 104773.08500000002,
    "YEAR_6": 109084.80500000001,
    "YEAR_7": 113396.525,
    "YEAR_8": 117708.24500000001,
    "YEAR_9": 122019.96500000003,
    "YEAR_10": 126331.68500000001,
    "FIXED_Aegis": 1047812.1225
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 72843752,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3642187.6,
    "Annual Police Budget for Communication": 36421.876000000004,
    "Per Month": 3035.156333333334,
    "YEAR_1": 28119.509365800004,
    "YEAR_2": 39098.88388600001,
    "YEAR_3": 40934.5464364,
    "YEAR_4": 42770.20898680001,
    "YEAR_5": 44605.8715372,
    "YEAR_6": 46441.53408760001,
    "YEAR_7": 48277.196638,
    "YEAR_8": 50112.859188400005,
    "YEAR_9": 51948.52173880001,
    "YEAR_10": 53784.18428920001,
    "FIXED_Aegis": 446093.3161542
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 118200000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 5910000,
    "Annual Police Budget for Communication": 59100,
    "Per Month": 4925,
    "YEAR_1": 45628.155,
    "YEAR_2": 63443.84999999999,
    "YEAR_3": 66422.49,
    "YEAR_4": 69401.13,
    "YEAR_5": 72379.76999999999,
    "YEAR_6": 75358.40999999999,
    "YEAR_7": 78337.05,
    "YEAR_8": 81315.69,
    "YEAR_9": 84294.32999999999,
    "YEAR_10": 87272.97,
    "FIXED_Aegis": 723853.8449999999
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 40483972,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2024198.6,
    "Annual Police Budget for Communication": 20241.986,
    "Per Month": 1686.8321666666668,
    "YEAR_1": 15627.8252913,
    "YEAR_2": 21729.771971000002,
    "YEAR_3": 22749.9680654,
    "YEAR_4": 23770.1641598,
    "YEAR_5": 24790.3602542,
    "YEAR_6": 25810.556348600003,
    "YEAR_7": 26830.752442999998,
    "YEAR_8": 27850.9485374,
    "YEAR_9": 28871.1446318,
    "YEAR_10": 29891.3407262,
    "FIXED_Aegis": 247922.8324287
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 76088613,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3804430.6500000004,
    "Annual Police Budget for Communication": 38044.306500000006,
    "Per Month": 3170.3588750000004,
    "YEAR_1": 29372.106833325,
    "YEAR_2": 40840.56302775,
    "YEAR_3": 42757.996075350005,
    "YEAR_4": 44675.42912295,
    "YEAR_5": 46592.86217055001,
    "YEAR_6": 48510.29521815,
    "YEAR_7": 50427.728265749996,
    "YEAR_8": 52345.16131335001,
    "YEAR_9": 54262.59436095,
    "YEAR_10": 56180.027408550006,
    "FIXED_Aegis": 465964.7637966751
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 76400000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3820000,
    "Annual Police Budget for Communication": 38200,
    "Per Month": 3183.3333333333335,
    "YEAR_1": 29492.310000000005,
    "YEAR_2": 41007.7,
    "YEAR_3": 42932.979999999996,
    "YEAR_4": 44858.26,
    "YEAR_5": 46783.54,
    "YEAR_6": 48708.82000000001,
    "YEAR_7": 50634.1,
    "YEAR_8": 52559.37999999999,
    "YEAR_9": 54484.659999999996,
    "YEAR_10": 56409.939999999995,
    "FIXED_Aegis": 467871.69
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 61588930,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3079446.5,
    "Annual Police Budget for Communication": 30794.465,
    "Per Month": 2566.2054166666667,
    "YEAR_1": 23774.86670325,
    "YEAR_2": 33057.858177500006,
    "YEAR_3": 34609.8992135,
    "YEAR_4": 36161.9402495,
    "YEAR_5": 37713.981285500005,
    "YEAR_6": 39266.0223215,
    "YEAR_7": 40818.0633575,
    "YEAR_8": 42370.1043935,
    "YEAR_9": 43922.1454295,
    "YEAR_10": 45474.186465499995,
    "FIXED_Aegis": 377169.06759675004
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 125290000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 6264500,
    "Annual Police Budget for Communication": 62645,
    "Per Month": 5220.416666666667,
    "YEAR_1": 48365.07225,
    "YEAR_2": 67249.4075,
    "YEAR_3": 70406.71549999999,
    "YEAR_4": 73564.0235,
    "YEAR_5": 76721.3315,
    "YEAR_6": 79878.6395,
    "YEAR_7": 83035.94750000001,
    "YEAR_8": 86193.2555,
    "YEAR_9": 89350.5635,
    "YEAR_10": 92507.87150000001,
    "FIXED_Aegis": 767272.82775
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 183000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 9150000,
    "Annual Police Budget for Communication": 91500,
    "Per Month": 7625,
    "YEAR_1": 70642.575,
    "YEAR_2": 98225.25,
    "YEAR_3": 102836.85,
    "YEAR_4": 107448.45,
    "YEAR_5": 112060.04999999999,
    "YEAR_6": 116671.65,
    "YEAR_7": 121283.25,
    "YEAR_8": 125894.85,
    "YEAR_9": 130506.45,
    "YEAR_10": 135118.05,
    "FIXED_Aegis": 1120687.425
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 126800000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 6340000,
    "Annual Police Budget for Communication": 63400,
    "Per Month": 5283.333333333333,
    "YEAR_1": 48947.969999999994,
    "YEAR_2": 68059.90000000001,
    "YEAR_3": 71255.26,
    "YEAR_4": 74450.62,
    "YEAR_5": 77645.98,
    "YEAR_6": 80841.34,
    "YEAR_7": 84036.7,
    "YEAR_8": 87232.06,
    "YEAR_9": 90427.41999999998,
    "YEAR_10": 93622.78,
    "FIXED_Aegis": 776520.03
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 222200000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 11110000,
    "Annual Police Budget for Communication": 111100,
    "Per Month": 9258.333333333334,
    "YEAR_1": 85774.755,
    "YEAR_2": 119265.85,
    "YEAR_3": 124865.29000000001,
    "YEAR_4": 130464.73,
    "YEAR_5": 136064.16999999998,
    "YEAR_6": 141663.61000000002,
    "YEAR_7": 147263.05,
    "YEAR_8": 152862.49000000002,
    "YEAR_9": 158461.93000000002,
    "YEAR_10": 164061.37000000002,
    "FIXED_Aegis": 1360747.2449999999
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 320000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 16000000,
    "Annual Police Budget for Communication": 160000,
    "Per Month": 13333.333333333334,
    "YEAR_1": 123527.99999999999,
    "YEAR_2": 171760,
    "YEAR_3": 179824.00000000003,
    "YEAR_4": 187888.00000000003,
    "YEAR_5": 195952.00000000003,
    "YEAR_6": 204016.0000000001,
    "YEAR_7": 212080.0000000001,
    "YEAR_8": 220144.0000000001,
    "YEAR_9": 228208.0000000001,
    "YEAR_10": 236272.0000000001,
    "FIXED_Aegis": 1959672
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 90000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4500000,
    "Annual Police Budget for Communication": 45000,
    "Per Month": 3750,
    "YEAR_1": 34742.25,
    "YEAR_2": 48307.5,
    "YEAR_3": 50575.5,
    "YEAR_4": 52843.5,
    "YEAR_5": 55111.5,
    "YEAR_6": 57379.5,
    "YEAR_7": 59647.5,
    "YEAR_8": 61915.5,
    "YEAR_9": 64183.5,
    "YEAR_10": 66451.5,
    "FIXED_Aegis": 551157.75
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 438000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 21900000,
    "Annual Police Budget for Communication": 219000,
    "Per Month": 18250,
    "YEAR_1": 169078.95,
    "YEAR_2": 235096.5,
    "YEAR_3": 246134.1,
    "YEAR_4": 257171.69999999998,
    "YEAR_5": 268209.3,
    "YEAR_6": 279246.9,
    "YEAR_7": 290284.5,
    "YEAR_8": 301322.1,
    "YEAR_9": 312359.69999999995,
    "YEAR_10": 323397.29999999993,
    "FIXED_Aegis": 2682301.05
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 21100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1055000,
    "Annual Police Budget for Communication": 10550,
    "Per Month": 879.1666666666666,
    "YEAR_1": 8145.1275,
    "YEAR_2": 11325.425000000001,
    "YEAR_3": 11857.145,
    "YEAR_4": 12388.865,
    "YEAR_5": 12920.584999999997,
    "YEAR_6": 13452.305,
    "YEAR_7": 13984.024999999998,
    "YEAR_8": 14515.744999999999,
    "YEAR_9": 15047.464999999998,
    "YEAR_10": 15579.185,
    "FIXED_Aegis": 129215.87249999998
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 63937242,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3196862.1,
    "Annual Police Budget for Communication": 31968.621000000003,
    "Per Month": 2664.05175,
    "YEAR_1": 24681.37384305,
    "YEAR_2": 34318.3146435,
    "YEAR_3": 35929.5331419,
    "YEAR_4": 37540.75164030001,
    "YEAR_5": 39151.9701387,
    "YEAR_6": 40763.18863710001,
    "YEAR_7": 42374.40713549999,
    "YEAR_8": 43985.6256339,
    "YEAR_9": 45596.844132300015,
    "YEAR_10": 47208.0626307,
    "FIXED_Aegis": 391550.07157695
  },
  {
    "DMA": "Macon",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 54184445,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2709222.25,
    "Annual Police Budget for Communication": 27092.2225,
    "Per Month": 2257.6852083333333,
    "YEAR_1": 20916.550381125002,
    "YEAR_2": 29083.500853750003,
    "YEAR_3": 30448.94886775,
    "YEAR_4": 31814.39688175,
    "YEAR_5": 33179.84489575,
    "YEAR_6": 34545.29290975,
    "YEAR_7": 35910.74092375,
    "YEAR_8": 37276.18893775,
    "YEAR_9": 38641.63695175,
    "YEAR_10": 40007.08496575,
    "FIXED_Aegis": 331824.18656887504
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 137000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 6850000,
    "Annual Police Budget for Communication": 68500,
    "Per Month": 5708.333333333333,
    "YEAR_1": 52885.424999999996,
    "YEAR_2": 73534.75,
    "YEAR_3": 76987.15,
    "YEAR_4": 80439.54999999999,
    "YEAR_5": 83891.95,
    "YEAR_6": 87344.34999999999,
    "YEAR_7": 90796.75,
    "YEAR_8": 94249.15,
    "YEAR_9": 97701.54999999999,
    "YEAR_10": 101153.94999999998,
    "FIXED_Aegis": 838984.575
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 400000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 20000000,
    "Annual Police Budget for Communication": 200000,
    "Per Month": 16666.666666666668,
    "YEAR_1": 154410,
    "YEAR_2": 214699.99999999997,
    "YEAR_3": 224779.99999999997,
    "YEAR_4": 234859.99999999997,
    "YEAR_5": 244939.99999999997,
    "YEAR_6": 255019.99999999997,
    "YEAR_7": 265099.99999999994,
    "YEAR_8": 275179.99999999994,
    "YEAR_9": 285259.99999999994,
    "YEAR_10": 295340,
    "FIXED_Aegis": 2449590
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 168632368,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 8431618.4,
    "Annual Police Budget for Communication": 84316.18400000001,
    "Per Month": 7026.348666666668,
    "YEAR_1": 65096.30985720001,
    "YEAR_2": 90513.423524,
    "YEAR_3": 94762.95919760002,
    "YEAR_4": 99012.49487120002,
    "YEAR_5": 103262.0305448,
    "YEAR_6": 107511.56621840002,
    "YEAR_7": 111761.10189200002,
    "YEAR_8": 116010.63756560002,
    "YEAR_9": 120260.17323920003,
    "YEAR_10": 124509.70891280002,
    "FIXED_Aegis": 1032700.4058228001
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 288900000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 14445000,
    "Annual Police Budget for Communication": 144450,
    "Per Month": 12037.5,
    "YEAR_1": 111522.6225,
    "YEAR_2": 155067.075,
    "YEAR_3": 162347.355,
    "YEAR_4": 169627.635,
    "YEAR_5": 176907.91499999998,
    "YEAR_6": 184188.19499999998,
    "YEAR_7": 191468.47499999998,
    "YEAR_8": 198748.755,
    "YEAR_9": 206029.03500000003,
    "YEAR_10": 213309.31499999997,
    "FIXED_Aegis": 1769216.3775
  },
  {
    "DMA": "Lafayette, LA",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 46471778,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2323588.9,
    "Annual Police Budget for Communication": 23235.889,
    "Per Month": 1936.3240833333332,
    "YEAR_1": 17939.268102449998,
    "YEAR_2": 24943.726841499996,
    "YEAR_3": 26114.815647099997,
    "YEAR_4": 27285.904452699997,
    "YEAR_5": 28456.993258299997,
    "YEAR_6": 29628.082063899994,
    "YEAR_7": 30799.170869499998,
    "YEAR_8": 31970.259675099995,
    "YEAR_9": 33141.3484807,
    "YEAR_10": 34312.437286299995,
    "FIXED_Aegis": 284592.00667755
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 417000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 20850000,
    "Annual Police Budget for Communication": 208500,
    "Per Month": 17375,
    "YEAR_1": 160972.425,
    "YEAR_2": 223824.75,
    "YEAR_3": 234333.15,
    "YEAR_4": 244841.55000000002,
    "YEAR_5": 255349.94999999998,
    "YEAR_6": 265858.35,
    "YEAR_7": 276366.75,
    "YEAR_8": 286875.15,
    "YEAR_9": 297383.55,
    "YEAR_10": 307891.95,
    "FIXED_Aegis": 2553697.5749999997
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 77000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3850000,
    "Annual Police Budget for Communication": 38500,
    "Per Month": 3208.3333333333335,
    "YEAR_1": 29723.925000000003,
    "YEAR_2": 41329.75,
    "YEAR_3": 43270.15,
    "YEAR_4": 45210.55,
    "YEAR_5": 47150.95,
    "YEAR_6": 49091.35,
    "YEAR_7": 51031.75,
    "YEAR_8": 52972.15,
    "YEAR_9": 54912.55,
    "YEAR_10": 56852.95000000001,
    "FIXED_Aegis": 471546.07500000007
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 580000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 29000000,
    "Annual Police Budget for Communication": 290000,
    "Per Month": 24166.666666666668,
    "YEAR_1": 223894.49999999997,
    "YEAR_2": 311315,
    "YEAR_3": 325931,
    "YEAR_4": 340547,
    "YEAR_5": 355163.00000000006,
    "YEAR_6": 369779.00000000006,
    "YEAR_7": 384395.00000000006,
    "YEAR_8": 399011.0000000002,
    "YEAR_9": 413627.0000000002,
    "YEAR_10": 428243.0000000002,
    "FIXED_Aegis": 3551905.5
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 38717704,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1935885.2000000002,
    "Annual Police Budget for Communication": 19358.852000000003,
    "Per Month": 1613.2376666666669,
    "YEAR_1": 14946.0016866,
    "YEAR_2": 20781.727622000002,
    "YEAR_3": 21757.413762800006,
    "YEAR_4": 22733.0999036,
    "YEAR_5": 23708.7860444,
    "YEAR_6": 24684.472185200008,
    "YEAR_7": 25660.158326000008,
    "YEAR_8": 26635.8444668,
    "YEAR_9": 27611.5306076,
    "YEAR_10": 28587.216748400002,
    "FIXED_Aegis": 237106.2513534
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 83558819,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4177940.95,
    "Annual Police Budget for Communication": 41779.4095,
    "Per Month": 3481.6174583333336,
    "YEAR_1": 32255.793104475,
    "YEAR_2": 44850.19609825,
    "YEAR_3": 46955.87833705,
    "YEAR_4": 49061.56057585001,
    "YEAR_5": 51167.24281465,
    "YEAR_6": 53272.92505345001,
    "YEAR_7": 55378.60729225,
    "YEAR_8": 57484.28953105,
    "YEAR_9": 59589.97176985,
    "YEAR_10": 61695.654008649995,
    "FIXED_Aegis": 511712.1185855251
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 51524314,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2576215.7,
    "Annual Police Budget for Communication": 25762.157000000003,
    "Per Month": 2146.8464166666668,
    "YEAR_1": 19889.673311849998,
    "YEAR_2": 27655.675539500004,
    "YEAR_3": 28954.088252299996,
    "YEAR_4": 30252.500965100007,
    "YEAR_5": 31550.9136779,
    "YEAR_6": 32849.32639070001,
    "YEAR_7": 34147.7391035,
    "YEAR_8": 35446.15181629999,
    "YEAR_9": 36744.564529100004,
    "YEAR_10": 38042.97724189999,
    "FIXED_Aegis": 315533.61082815006
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 310000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 15500000,
    "Annual Police Budget for Communication": 155000,
    "Per Month": 12916.666666666666,
    "YEAR_1": 119667.75000000001,
    "YEAR_2": 166392.5,
    "YEAR_3": 174204.5,
    "YEAR_4": 182016.49999999997,
    "YEAR_5": 189828.49999999997,
    "YEAR_6": 197640.49999999994,
    "YEAR_7": 205452.4999999999,
    "YEAR_8": 213264.4999999999,
    "YEAR_9": 221076.4999999999,
    "YEAR_10": 228888.4999999999,
    "FIXED_Aegis": 1898432.25
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 59500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2975000,
    "Annual Police Budget for Communication": 29750,
    "Per Month": 2479.1666666666665,
    "YEAR_1": 22968.4875,
    "YEAR_2": 31936.624999999996,
    "YEAR_3": 33436.024999999994,
    "YEAR_4": 34935.424999999996,
    "YEAR_5": 36434.825,
    "YEAR_6": 37934.22499999999,
    "YEAR_7": 39433.62499999999,
    "YEAR_8": 40933.024999999994,
    "YEAR_9": 42432.42499999999,
    "YEAR_10": 43931.825000000004,
    "FIXED_Aegis": 364376.51249999995
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 119500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 5975000,
    "Annual Police Budget for Communication": 59750,
    "Per Month": 4979.166666666667,
    "YEAR_1": 46129.9875,
    "YEAR_2": 64141.62500000001,
    "YEAR_3": 67153.02500000001,
    "YEAR_4": 70164.425,
    "YEAR_5": 73175.825,
    "YEAR_6": 76187.225,
    "YEAR_7": 79198.625,
    "YEAR_8": 82210.02500000001,
    "YEAR_9": 85221.425,
    "YEAR_10": 88232.825,
    "FIXED_Aegis": 731815.0125000001
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 19680811,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 984040.55,
    "Annual Police Budget for Communication": 9840.4055,
    "Per Month": 820.0337916666667,
    "YEAR_1": 7597.285066275001,
    "YEAR_2": 10563.67530425,
    "YEAR_3": 11059.63174145,
    "YEAR_4": 11555.58817865,
    "YEAR_5": 12051.54461585,
    "YEAR_6": 12547.50105305,
    "YEAR_7": 13043.457490250003,
    "YEAR_8": 13539.413927450001,
    "YEAR_9": 14035.37036465,
    "YEAR_10": 14531.326801850002,
    "FIXED_Aegis": 120524.794543725
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 480000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 24000000,
    "Annual Police Budget for Communication": 240000,
    "Per Month": 20000,
    "YEAR_1": 185292,
    "YEAR_2": 257640,
    "YEAR_3": 269736,
    "YEAR_4": 281832,
    "YEAR_5": 293928,
    "YEAR_6": 306024,
    "YEAR_7": 318120,
    "YEAR_8": 330216,
    "YEAR_9": 342312,
    "YEAR_10": 354408,
    "FIXED_Aegis": 2939508
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 100000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 5000000,
    "Annual Police Budget for Communication": 50000,
    "Per Month": 4166.666666666667,
    "YEAR_1": 38602.5,
    "YEAR_2": 53674.99999999999,
    "YEAR_3": 56194.99999999999,
    "YEAR_4": 58714.99999999999,
    "YEAR_5": 61234.99999999999,
    "YEAR_6": 63754.99999999999,
    "YEAR_7": 66274.99999999999,
    "YEAR_8": 68794.99999999999,
    "YEAR_9": 71314.99999999999,
    "YEAR_10": 73835,
    "FIXED_Aegis": 612397.5
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 76632780,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 3831639,
    "Annual Police Budget for Communication": 38316.39,
    "Per Month": 3193.0325,
    "YEAR_1": 29582.168899499997,
    "YEAR_2": 41132.644665,
    "YEAR_3": 43063.790721,
    "YEAR_4": 44994.936776999995,
    "YEAR_5": 46926.08283299999,
    "YEAR_6": 48857.228889,
    "YEAR_7": 50788.374945,
    "YEAR_8": 52719.521000999994,
    "YEAR_9": 54650.667057,
    "YEAR_10": 56581.81311299999,
    "FIXED_Aegis": 469297.2289005
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 33300000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1665000,
    "Annual Police Budget for Communication": 16650,
    "Per Month": 1387.5,
    "YEAR_1": 12854.6325,
    "YEAR_2": 17873.775,
    "YEAR_3": 18712.935,
    "YEAR_4": 19552.095,
    "YEAR_5": 20391.254999999997,
    "YEAR_6": 21230.414999999997,
    "YEAR_7": 22069.574999999997,
    "YEAR_8": 22908.735,
    "YEAR_9": 23747.895,
    "YEAR_10": 24587.055,
    "FIXED_Aegis": 203928.36749999996
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 250000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 12500000,
    "Annual Police Budget for Communication": 125000,
    "Per Month": 10416.666666666666,
    "YEAR_1": 96506.25,
    "YEAR_2": 134187.50000000003,
    "YEAR_3": 140487.50000000003,
    "YEAR_4": 146787.5,
    "YEAR_5": 153087.5,
    "YEAR_6": 159387.5,
    "YEAR_7": 165687.5,
    "YEAR_8": 171987.5,
    "YEAR_9": 178287.4999999999,
    "YEAR_10": 184587.4999999999,
    "FIXED_Aegis": 1530993.75
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 42959740,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2147987,
    "Annual Police Budget for Communication": 21479.87,
    "Per Month": 1789.9891666666665,
    "YEAR_1": 16583.5336335,
    "YEAR_2": 23058.640444999997,
    "YEAR_3": 24141.225893000003,
    "YEAR_4": 25223.811340999997,
    "YEAR_5": 26306.396789,
    "YEAR_6": 27388.982236999997,
    "YEAR_7": 28471.567684999998,
    "YEAR_8": 29554.153133,
    "YEAR_9": 30636.738580999998,
    "YEAR_10": 31719.324028999996,
    "FIXED_Aegis": 263084.3737665
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 90100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4505000,
    "Annual Police Budget for Communication": 45050,
    "Per Month": 3754.1666666666665,
    "YEAR_1": 34780.8525,
    "YEAR_2": 48361.175,
    "YEAR_3": 50631.69499999999,
    "YEAR_4": 52902.215,
    "YEAR_5": 55172.73499999999,
    "YEAR_6": 57443.255000000005,
    "YEAR_7": 59713.77499999999,
    "YEAR_8": 61984.295,
    "YEAR_9": 64254.815,
    "YEAR_10": 66525.33499999999,
    "FIXED_Aegis": 551770.1475
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 250000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 12500000,
    "Annual Police Budget for Communication": 125000,
    "Per Month": 10416.666666666666,
    "YEAR_1": 96506.25,
    "YEAR_2": 134187.50000000003,
    "YEAR_3": 140487.50000000003,
    "YEAR_4": 146787.5,
    "YEAR_5": 153087.5,
    "YEAR_6": 159387.5,
    "YEAR_7": 165687.5,
    "YEAR_8": 171987.5,
    "YEAR_9": 178287.4999999999,
    "YEAR_10": 184587.4999999999,
    "FIXED_Aegis": 1530993.75
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 44126983,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 2206349.15,
    "Annual Police Budget for Communication": 22063.4915,
    "Per Month": 1838.6242916666667,
    "YEAR_1": 17034.118612575,
    "YEAR_2": 23685.158125250004,
    "YEAR_3": 24797.15809685,
    "YEAR_4": 25909.158068450004,
    "YEAR_5": 27021.158040050002,
    "YEAR_6": 28133.158011649997,
    "YEAR_7": 29245.15798325,
    "YEAR_8": 30357.157954850005,
    "YEAR_9": 31469.15792645,
    "YEAR_10": 32581.157898049998,
    "FIXED_Aegis": 270232.540717425
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 83000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 4150000,
    "Annual Police Budget for Communication": 41500,
    "Per Month": 3458.3333333333335,
    "YEAR_1": 32040.075,
    "YEAR_2": 44550.25000000001,
    "YEAR_3": 46641.850000000006,
    "YEAR_4": 48733.45,
    "YEAR_5": 50825.049999999996,
    "YEAR_6": 52916.65000000001,
    "YEAR_7": 55008.25000000001,
    "YEAR_8": 57099.850000000006,
    "YEAR_9": 59191.45000000001,
    "YEAR_10": 61283.049999999996,
    "FIXED_Aegis": 508289.92500000005
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 146309082,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 7315454.100000001,
    "Annual Police Budget for Communication": 73154.54100000001,
    "Per Month": 6096.211750000001,
    "YEAR_1": 56478.963379050016,
    "YEAR_2": 78531.39976350001,
    "YEAR_3": 82218.38862990003,
    "YEAR_4": 85905.3774963,
    "YEAR_5": 89592.36636270004,
    "YEAR_6": 93279.35522910001,
    "YEAR_7": 96966.34409550001,
    "YEAR_8": 100653.33296190001,
    "YEAR_9": 104340.32182830002,
    "YEAR_10": 108027.31069470002,
    "FIXED_Aegis": 895993.1604409501
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 219846716,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 10992335.8,
    "Annual Police Budget for Communication": 109923.35800000001,
    "Per Month": 9160.279833333334,
    "YEAR_1": 84866.3285439,
    "YEAR_2": 118002.72481300001,
    "YEAR_3": 123542.86205620001,
    "YEAR_4": 129082.9992994,
    "YEAR_5": 134623.1365426,
    "YEAR_6": 140163.2737858,
    "YEAR_7": 145703.411029,
    "YEAR_8": 151243.54827220002,
    "YEAR_9": 156783.6855154,
    "YEAR_10": 162323.8227586,
    "FIXED_Aegis": 1346335.7926161
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 117783349,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 5889167.45,
    "Annual Police Budget for Communication": 58891.6745,
    "Per Month": 4907.639541666666,
    "YEAR_1": 45467.31729772499,
    "YEAR_2": 63220.21257574999,
    "YEAR_3": 66188.35297055,
    "YEAR_4": 69156.49336535,
    "YEAR_5": 72124.63376014998,
    "YEAR_6": 75092.77415495,
    "YEAR_7": 78060.91454975,
    "YEAR_8": 81029.05494455,
    "YEAR_9": 83997.19533935,
    "YEAR_10": 86965.33573415,
    "FIXED_Aegis": 721302.284692275
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 24478411,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1223920.55,
    "Annual Police Budget for Communication": 12239.2055,
    "Per Month": 1019.9337916666667,
    "YEAR_1": 9449.278606275,
    "YEAR_2": 13138.787104250001,
    "YEAR_3": 13755.64306145,
    "YEAR_4": 14372.49901865,
    "YEAR_5": 14989.354975850001,
    "YEAR_6": 15606.21093305,
    "YEAR_7": 16223.06689025,
    "YEAR_8": 16839.922847449998,
    "YEAR_9": 17456.77880465,
    "YEAR_10": 18073.63476185,
    "FIXED_Aegis": 149905.177003725
  },
  {
    "DMA": "Erie",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 39381583,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 1969079.1500000001,
    "Annual Police Budget for Communication": 19690.791500000003,
    "Per Month": 1640.8992916666668,
    "YEAR_1": 15202.275577575001,
    "YEAR_2": 21138.064675250003,
    "YEAR_3": 22130.48056685,
    "YEAR_4": 23122.896458450003,
    "YEAR_5": 24115.31235005,
    "YEAR_6": 25107.728241650002,
    "YEAR_7": 26100.144133250004,
    "YEAR_8": 27092.560024850005,
    "YEAR_9": 28084.975916450006,
    "YEAR_10": 29077.391808050008,
    "FIXED_Aegis": 241171.82975242505
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 215000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 10750000,
    "Annual Police Budget for Communication": 107500,
    "Per Month": 8958.333333333334,
    "YEAR_1": 82995.375,
    "YEAR_2": 115401.24999999999,
    "YEAR_3": 120819.24999999999,
    "YEAR_4": 126237.24999999999,
    "YEAR_5": 131655.25,
    "YEAR_6": 137073.24999999997,
    "YEAR_7": 142491.24999999997,
    "YEAR_8": 147909.25,
    "YEAR_9": 153327.25,
    "YEAR_10": 158745.25,
    "FIXED_Aegis": 1316654.625
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 187000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 9350000,
    "Annual Police Budget for Communication": 93500,
    "Per Month": 7791.666666666667,
    "YEAR_1": 72186.675,
    "YEAR_2": 100372.25,
    "YEAR_3": 105084.65,
    "YEAR_4": 109797.04999999999,
    "YEAR_5": 114509.45,
    "YEAR_6": 119221.85,
    "YEAR_7": 123934.25,
    "YEAR_8": 128646.65,
    "YEAR_9": 133359.05,
    "YEAR_10": 138071.45,
    "FIXED_Aegis": 1145183.325
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 16563000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "Holiday Months": 3,
    "TAM": 828150,
    "Annual Police Budget for Communication": 8281.5,
    "Per Month": 690.125,
    "YEAR_1": 6393.732075,
    "YEAR_2": 8890.19025,
    "YEAR_3": 9307.57785,
    "YEAR_4": 9724.96545,
    "YEAR_5": 10142.35305,
    "YEAR_6": 10559.74065,
    "YEAR_7": 10977.128250000002,
    "YEAR_8": 11394.51585,
    "YEAR_9": 11811.903449999998,
    "YEAR_10": 12229.29105,
    "FIXED_Aegis": 101431.39792499998
  },
  {
    "DMA": "New York",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 5830635000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 291531750,
    "Annual Police Budget for Communication": 2915317.5,
    "Per Month": 242943.125,
    "Metered Monthly Budget": 36441.46875,
    "YEAR_1": 337615.63138125,
    "YEAR_2": 469439.00043749996,
    "YEAR_3": 491478.8007375,
    "YEAR_4": 513518.6010375001,
    "YEAR_5": 535558.4013375,
    "YEAR_6": 557598.2016375,
    "YEAR_7": 579638.0019375001,
    "YEAR_8": 601677.8022375,
    "YEAR_9": 623717.6025375001,
    "YEAR_10": 645757.4028375,
    "METERED_Aegis": 5355999.446118751
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 3200000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 160000000,
    "Annual Police Budget for Communication": 1600000,
    "Per Month": 133333.33333333334,
    "Metered Monthly Budget": 20000,
    "YEAR_1": 185292,
    "YEAR_2": 257640,
    "YEAR_3": 269736,
    "YEAR_4": 281832,
    "YEAR_5": 293928,
    "YEAR_6": 306024,
    "YEAR_7": 318120,
    "YEAR_8": 330216,
    "YEAR_9": 342312,
    "YEAR_10": 354408,
    "METERED_Aegis": 2939508
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1940000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 97000000,
    "Annual Police Budget for Communication": 970000,
    "Per Month": 80833.33333333333,
    "Metered Monthly Budget": 12124.999999999998,
    "YEAR_1": 112333.275,
    "YEAR_2": 156194.24999999997,
    "YEAR_3": 163527.44999999995,
    "YEAR_4": 170860.64999999997,
    "YEAR_5": 178193.84999999998,
    "YEAR_6": 185527.05,
    "YEAR_7": 192860.24999999997,
    "YEAR_8": 200193.44999999995,
    "YEAR_9": 207526.65,
    "YEAR_10": 214859.84999999998,
    "METERED_Aegis": 1782076.7249999996
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1300000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 65000000,
    "Annual Police Budget for Communication": 650000,
    "Per Month": 54166.666666666664,
    "Metered Monthly Budget": 8124.999999999999,
    "YEAR_1": 75274.87499999999,
    "YEAR_2": 104666.24999999999,
    "YEAR_3": 109580.24999999999,
    "YEAR_4": 114494.24999999999,
    "YEAR_5": 119408.24999999999,
    "YEAR_6": 124322.24999999999,
    "YEAR_7": 129236.24999999999,
    "YEAR_8": 134150.24999999997,
    "YEAR_9": 139064.24999999997,
    "YEAR_10": 143978.24999999997,
    "METERED_Aegis": 1194175.1249999998
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1072261852,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 53613092.6,
    "Annual Police Budget for Communication": 536130.926,
    "Per Month": 44677.57716666666,
    "Metered Monthly Budget": 6701.6365749999995,
    "YEAR_1": 62087.982212744995,
    "YEAR_2": 86330.48235914999,
    "YEAR_3": 90383.63215970999,
    "YEAR_4": 94436.78196027,
    "YEAR_5": 98489.93176082999,
    "YEAR_6": 102543.08156139,
    "YEAR_7": 106596.23136194999,
    "YEAR_8": 110649.38116251,
    "YEAR_9": 114702.53096306999,
    "YEAR_10": 118755.68076363,
    "METERED_Aegis": 984975.7162652549
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 273600000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 13680000,
    "Annual Police Budget for Communication": 136800,
    "Per Month": 11400,
    "Metered Monthly Budget": 1710,
    "YEAR_1": 15842.466,
    "YEAR_2": 22028.22,
    "YEAR_3": 23062.428000000004,
    "YEAR_4": 24096.636,
    "YEAR_5": 25130.844,
    "YEAR_6": 26165.052000000003,
    "YEAR_7": 27199.260000000002,
    "YEAR_8": 28233.468,
    "YEAR_9": 29267.676,
    "YEAR_10": 30301.884,
    "METERED_Aegis": 251327.934
  },
  {
    "DMA": "Houston",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1018736302,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 50936815.1,
    "Annual Police Budget for Communication": 509368.151,
    "Per Month": 42447.345916666665,
    "Metered Monthly Budget": 6367.1018875,
    "YEAR_1": 58988.6521469325,
    "YEAR_2": 82021.006514775,
    "YEAR_3": 85871.829736335,
    "YEAR_4": 89722.65295789501,
    "YEAR_5": 93573.476179455,
    "YEAR_6": 97424.29940101499,
    "YEAR_7": 101275.12262257499,
    "YEAR_8": 105125.94584413497,
    "YEAR_9": 108976.769065695,
    "YEAR_10": 112827.59228725499,
    "METERED_Aegis": 935807.3467560675
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 512735177,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 25636758.85,
    "Annual Police Budget for Communication": 256367.5885,
    "Per Month": 21363.965708333333,
    "Metered Monthly Budget": 3204.59485625,
    "YEAR_1": 29689.289505213746,
    "YEAR_2": 41281.590938212496,
    "YEAR_3": 43219.72990727249,
    "YEAR_4": 45157.868876332504,
    "YEAR_5": 47096.00784539249,
    "YEAR_6": 49034.14681445249,
    "YEAR_7": 50972.28578351252,
    "YEAR_8": 52910.42475257249,
    "YEAR_9": 54848.5637216325,
    "YEAR_10": 56786.70269069247,
    "METERED_Aegis": 470996.6108352862
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1300000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 65000000,
    "Annual Police Budget for Communication": 650000,
    "Per Month": 54166.666666666664,
    "Metered Monthly Budget": 8124.999999999999,
    "YEAR_1": 75274.87499999999,
    "YEAR_2": 104666.24999999999,
    "YEAR_3": 109580.24999999999,
    "YEAR_4": 114494.24999999999,
    "YEAR_5": 119408.24999999999,
    "YEAR_6": 124322.24999999999,
    "YEAR_7": 129236.24999999999,
    "YEAR_8": 134150.24999999997,
    "YEAR_9": 139064.24999999997,
    "YEAR_10": 143978.24999999997,
    "METERED_Aegis": 1194175.1249999998
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1985500529,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 99275026.45,
    "Annual Police Budget for Communication": 992750.2645,
    "Per Month": 82729.18870833334,
    "Metered Monthly Budget": 12409.37830625,
    "YEAR_1": 114967.92625608377,
    "YEAR_2": 159857.61134111253,
    "YEAR_3": 167362.8033407325,
    "YEAR_4": 174867.9953403525,
    "YEAR_5": 182373.1873399725,
    "YEAR_6": 189878.3793395925,
    "YEAR_7": 197383.57133921247,
    "YEAR_8": 204888.76333883253,
    "YEAR_9": 212393.95533845248,
    "YEAR_10": 219899.1473380725,
    "METERED_Aegis": 1823873.3403124164
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 631238667,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 31561933.35,
    "Annual Police Budget for Communication": 315619.3335,
    "Per Month": 26301.611125,
    "Metered Monthly Budget": 3945.24166875,
    "YEAR_1": 36551.085964301245,
    "YEAR_2": 50822.603176837496,
    "YEAR_3": 53208.68533809751,
    "YEAR_4": 55594.767499357506,
    "YEAR_5": 57980.849660617496,
    "YEAR_6": 60366.931821877486,
    "YEAR_7": 62753.013983137505,
    "YEAR_8": 65139.096144397496,
    "YEAR_9": 67525.1783056575,
    "YEAR_10": 69911.26046691749,
    "METERED_Aegis": 579853.4723611989
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 533175917,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 26658795.85,
    "Annual Police Budget for Communication": 266587.9585,
    "Per Month": 22215.663208333335,
    "Metered Monthly Budget": 3332.3494812500003,
    "YEAR_1": 30872.88500398875,
    "YEAR_2": 42927.32601746251,
    "YEAR_3": 44942.73098372251,
    "YEAR_4": 46958.135949982505,
    "YEAR_5": 48973.540916242506,
    "YEAR_6": 50988.945882502514,
    "YEAR_7": 53004.35084876249,
    "YEAR_8": 55019.75581502251,
    "YEAR_9": 57035.160781282495,
    "YEAR_10": 59050.56574754251,
    "METERED_Aegis": 489773.3979465114
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 957000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 47850000,
    "Annual Police Budget for Communication": 478500,
    "Per Month": 39875,
    "Metered Monthly Budget": 5981.25,
    "YEAR_1": 55413.88875,
    "YEAR_2": 77050.4625,
    "YEAR_3": 80667.92249999999,
    "YEAR_4": 84285.38249999999,
    "YEAR_5": 87902.8425,
    "YEAR_6": 91520.3025,
    "YEAR_7": 95137.7625,
    "YEAR_8": 98755.22249999999,
    "YEAR_9": 102372.6825,
    "YEAR_10": 105990.14249999999,
    "METERED_Aegis": 879096.61125
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 366000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 18300000,
    "Annual Police Budget for Communication": 183000,
    "Per Month": 15250,
    "Metered Monthly Budget": 2287.5,
    "YEAR_1": 21192.772500000003,
    "YEAR_2": 29467.575,
    "YEAR_3": 30851.055,
    "YEAR_4": 32234.535000000003,
    "YEAR_5": 33618.015,
    "YEAR_6": 35001.495,
    "YEAR_7": 36384.975,
    "YEAR_8": 37768.455,
    "YEAR_9": 39151.935,
    "YEAR_10": 40535.415,
    "METERED_Aegis": 336206.2275
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 291761377,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 14588068.850000001,
    "Annual Police Budget for Communication": 145880.68850000002,
    "Per Month": 12156.724041666668,
    "Metered Monthly Budget": 1823.5086062500002,
    "YEAR_1": 16894.07783346375,
    "YEAR_2": 23490.437865712498,
    "YEAR_3": 24593.295870772505,
    "YEAR_4": 25696.153875832504,
    "YEAR_5": 26799.011880892504,
    "YEAR_6": 27901.8698859525,
    "YEAR_7": 29004.727891012502,
    "YEAR_8": 30107.5858960725,
    "YEAR_9": 31210.443901132505,
    "YEAR_10": 32313.3019061925,
    "METERED_Aegis": 268010.90680703626
  },
  {
    "DMA": "Denver",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 611000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 30550000,
    "Annual Police Budget for Communication": 305500,
    "Per Month": 25458.333333333332,
    "Metered Monthly Budget": 3818.7499999999995,
    "YEAR_1": 35379.191249999996,
    "YEAR_2": 49193.1375,
    "YEAR_3": 51502.717499999984,
    "YEAR_4": 53812.2975,
    "YEAR_5": 56121.87749999999,
    "YEAR_6": 58431.457500000004,
    "YEAR_7": 60741.03749999999,
    "YEAR_8": 63050.61750000001,
    "YEAR_9": 65360.19749999999,
    "YEAR_10": 67669.7775,
    "METERED_Aegis": 561262.30875
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1180000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 59000000,
    "Annual Police Budget for Communication": 590000,
    "Per Month": 49166.666666666664,
    "Metered Monthly Budget": 7374.999999999999,
    "YEAR_1": 68326.42499999999,
    "YEAR_2": 95004.75,
    "YEAR_3": 99465.15,
    "YEAR_4": 103925.54999999997,
    "YEAR_5": 108385.94999999998,
    "YEAR_6": 112846.34999999998,
    "YEAR_7": 117306.74999999997,
    "YEAR_8": 121767.14999999997,
    "YEAR_9": 126227.54999999997,
    "YEAR_10": 130687.94999999998,
    "METERED_Aegis": 1083943.575
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1400000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 70000000,
    "Annual Police Budget for Communication": 700000,
    "Per Month": 58333.333333333336,
    "Metered Monthly Budget": 8750,
    "YEAR_1": 81065.25,
    "YEAR_2": 112717.5,
    "YEAR_3": 118009.5,
    "YEAR_4": 123301.5,
    "YEAR_5": 128593.5,
    "YEAR_6": 133885.5,
    "YEAR_7": 139177.5,
    "YEAR_8": 144469.5,
    "YEAR_9": 149761.5,
    "YEAR_10": 155053.5,
    "METERED_Aegis": 1286034.75
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 275850281,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 13792514.05,
    "Annual Police Budget for Communication": 137925.1405,
    "Per Month": 11493.761708333333,
    "Metered Monthly Budget": 1724.06425625,
    "YEAR_1": 15972.76570845375,
    "YEAR_2": 22209.395749012503,
    "YEAR_3": 23252.109811192502,
    "YEAR_4": 24294.8238733725,
    "YEAR_5": 25337.537935552504,
    "YEAR_6": 26380.2519977325,
    "YEAR_7": 27422.966059912498,
    "YEAR_8": 28465.680122092497,
    "YEAR_9": 29508.394184272496,
    "YEAR_10": 30551.108246452495,
    "METERED_Aegis": 253395.03368804624
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 426481382,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 21324069.1,
    "Annual Police Budget for Communication": 213240.69100000002,
    "Per Month": 17770.057583333335,
    "Metered Monthly Budget": 2665.5086375,
    "YEAR_1": 24694.871322982504,
    "YEAR_2": 34337.082268275,
    "YEAR_3": 35949.181892235,
    "YEAR_4": 37561.281516195006,
    "YEAR_5": 39173.381140155005,
    "YEAR_6": 40785.480764115,
    "YEAR_7": 42397.580388074995,
    "YEAR_8": 44009.68001203501,
    "YEAR_9": 45621.77963599501,
    "YEAR_10": 47233.87925995499,
    "METERED_Aegis": 391764.19820001745
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 317600000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 15880000,
    "Annual Police Budget for Communication": 158800,
    "Per Month": 13233.333333333334,
    "Metered Monthly Budget": 1985,
    "YEAR_1": 18390.230999999996,
    "YEAR_2": 25570.77,
    "YEAR_3": 26771.298000000003,
    "YEAR_4": 27971.826000000005,
    "YEAR_5": 29172.354,
    "YEAR_6": 30372.881999999998,
    "YEAR_7": 31573.41,
    "YEAR_8": 32773.938,
    "YEAR_9": 33974.466,
    "YEAR_10": 35174.994,
    "METERED_Aegis": 291746.169
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 255647467,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 12782373.350000001,
    "Annual Police Budget for Communication": 127823.73350000002,
    "Per Month": 10651.977791666668,
    "Metered Monthly Budget": 1597.7966687500002,
    "YEAR_1": 14802.947017301252,
    "YEAR_2": 20582.81668683751,
    "YEAR_3": 21549.164112097507,
    "YEAR_4": 22515.511537357506,
    "YEAR_5": 23481.858962617505,
    "YEAR_6": 24448.206387877504,
    "YEAR_7": 25414.553813137503,
    "YEAR_8": 26380.901238397506,
    "YEAR_9": 27347.248663657505,
    "YEAR_10": 28313.596088917504,
    "METERED_Aegis": 234836.80450819887
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 240610298,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 12030514.9,
    "Annual Police Budget for Communication": 120305.149,
    "Per Month": 10025.429083333334,
    "Metered Monthly Budget": 1503.8143625,
    "YEAR_1": 13932.238542817502,
    "YEAR_2": 19372.136617725002,
    "YEAR_3": 20281.643544165003,
    "YEAR_4": 21191.150470604996,
    "YEAR_5": 22100.657397045,
    "YEAR_6": 23010.164323484998,
    "YEAR_7": 23919.671249925002,
    "YEAR_8": 24829.178176365003,
    "YEAR_9": 25738.685102805,
    "YEAR_10": 26648.192029245,
    "METERED_Aegis": 221023.7174541825
  },
  {
    "DMA": "Saint Louis",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 181079000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 9053950,
    "Annual Police Budget for Communication": 90539.5,
    "Per Month": 7544.958333333333,
    "Metered Monthly Budget": 1131.7437499999999,
    "YEAR_1": 10485.153146249999,
    "YEAR_2": 14579.122987499999,
    "YEAR_3": 15263.601607499997,
    "YEAR_4": 15948.080227499999,
    "YEAR_5": 16632.558847499997,
    "YEAR_6": 17317.0374675,
    "YEAR_7": 18001.516087499997,
    "YEAR_8": 18685.9947075,
    "YEAR_9": 19370.473327499996,
    "YEAR_10": 20054.951947499998,
    "METERED_Aegis": 166338.49035374998
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 284230093,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 14211504.65,
    "Annual Police Budget for Communication": 142115.0465,
    "Per Month": 11842.920541666666,
    "Metered Monthly Budget": 1776.4380812499999,
    "YEAR_1": 16457.98824754875,
    "YEAR_2": 22884.075362662497,
    "YEAR_3": 23958.4651142025,
    "YEAR_4": 25032.854865742498,
    "YEAR_5": 26107.2446172825,
    "YEAR_6": 27181.6343688225,
    "YEAR_7": 28256.024120362497,
    "YEAR_8": 29330.4138719025,
    "YEAR_9": 30404.803623442494,
    "YEAR_10": 31479.193374982497,
    "METERED_Aegis": 261092.69756695128
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 118585446,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 5929272.300000001,
    "Annual Police Budget for Communication": 59292.723000000005,
    "Per Month": 4941.06025,
    "Metered Monthly Budget": 741.1590375000001,
    "YEAR_1": 6866.542018822501,
    "YEAR_2": 9547.610721075001,
    "YEAR_3": 9995.863706955,
    "YEAR_4": 10444.116692835,
    "YEAR_5": 10892.369678715,
    "YEAR_6": 11340.622664595003,
    "YEAR_7": 11788.875650475,
    "YEAR_8": 12237.128636355,
    "YEAR_9": 12685.381622235001,
    "YEAR_10": 13133.634608115002,
    "METERED_Aegis": 108932.1460001775
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 734000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 36700000,
    "Annual Police Budget for Communication": 367000,
    "Per Month": 30583.333333333332,
    "Metered Monthly Budget": 4587.5,
    "YEAR_1": 42501.3525,
    "YEAR_2": 59096.175,
    "YEAR_3": 61870.69500000001,
    "YEAR_4": 64645.215000000004,
    "YEAR_5": 67419.735,
    "YEAR_6": 70194.255,
    "YEAR_7": 72968.775,
    "YEAR_8": 75743.29500000001,
    "YEAR_9": 78517.815,
    "YEAR_10": 81292.33499999999,
    "METERED_Aegis": 674249.6475
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 525100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 26255000,
    "Annual Police Budget for Communication": 262550,
    "Per Month": 21879.166666666668,
    "Metered Monthly Budget": 3281.875,
    "YEAR_1": 30405.259124999997,
    "YEAR_2": 42277.11375,
    "YEAR_3": 44261.991749999994,
    "YEAR_4": 46246.869750000005,
    "YEAR_5": 48231.747749999995,
    "YEAR_6": 50216.62575,
    "YEAR_7": 52201.50375,
    "YEAR_8": 54186.38174999999,
    "YEAR_9": 56171.25975,
    "YEAR_10": 58156.137749999994,
    "METERED_Aegis": 482354.89087500004
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 103977042,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 5198852.100000001,
    "Annual Police Budget for Communication": 51988.52100000001,
    "Per Month": 4332.37675,
    "Metered Monthly Budget": 649.8565125,
    "YEAR_1": 6020.6606457075,
    "YEAR_2": 8371.451594025,
    "YEAR_3": 8764.484812785,
    "YEAR_4": 9157.518031545002,
    "YEAR_5": 9550.551250305,
    "YEAR_6": 9943.584469065,
    "YEAR_7": 10336.617687825,
    "YEAR_8": 10729.650906585,
    "YEAR_9": 11122.684125345,
    "YEAR_10": 11515.717344105,
    "METERED_Aegis": 95512.92086729249
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 593292386,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 29664619.3,
    "Annual Police Budget for Communication": 296646.193,
    "Per Month": 24720.516083333336,
    "Metered Monthly Budget": 3708.0774125000003,
    "YEAR_1": 34353.85399584751,
    "YEAR_2": 47767.453227825,
    "YEAR_3": 50010.098446905,
    "YEAR_4": 52252.743665985,
    "YEAR_5": 54495.388885065,
    "YEAR_6": 56738.034104145,
    "YEAR_7": 58980.679323225006,
    "YEAR_8": 61223.324542305,
    "YEAR_9": 63465.969761385,
    "YEAR_10": 65708.614980465,
    "METERED_Aegis": 544996.1609331525
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 501294814,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 25064740.700000003,
    "Annual Police Budget for Communication": 250647.40700000004,
    "Per Month": 20887.28391666667,
    "Metered Monthly Budget": 3133.0925875000007,
    "YEAR_1": 29026.849586152508,
    "YEAR_2": 40360.498712175,
    "YEAR_3": 42255.39310909501,
    "YEAR_4": 44150.28750601501,
    "YEAR_5": 46045.181902935015,
    "YEAR_6": 47940.07629985502,
    "YEAR_7": 49834.97069677502,
    "YEAR_8": 51729.865093695,
    "YEAR_9": 53624.75949061501,
    "YEAR_10": 55519.653887535016,
    "METERED_Aegis": 460487.5362848476
  },
  {
    "DMA": "Columbus, OH",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 478000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 23900000,
    "Annual Police Budget for Communication": 239000,
    "Per Month": 19916.666666666668,
    "Metered Monthly Budget": 2987.5,
    "YEAR_1": 27677.9925,
    "YEAR_2": 38484.975,
    "YEAR_3": 40291.814999999995,
    "YEAR_4": 42098.655,
    "YEAR_5": 43905.494999999995,
    "YEAR_6": 45712.33499999999,
    "YEAR_7": 47519.17499999999,
    "YEAR_8": 49326.015,
    "YEAR_9": 51132.85500000001,
    "YEAR_10": 52939.69500000001,
    "METERED_Aegis": 439089.0075
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 284500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 14225000,
    "Annual Police Budget for Communication": 142250,
    "Per Month": 11854.166666666666,
    "Metered Monthly Budget": 1778.1249999999998,
    "YEAR_1": 16473.616874999996,
    "YEAR_2": 22905.806249999998,
    "YEAR_3": 23981.216249999998,
    "YEAR_4": 25056.626249999994,
    "YEAR_5": 26132.036249999997,
    "YEAR_6": 27207.446249999994,
    "YEAR_7": 28282.856249999997,
    "YEAR_8": 29358.26625,
    "YEAR_9": 30433.676249999993,
    "YEAR_10": 31509.086249999997,
    "METERED_Aegis": 261340.63312499996
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 93050846,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4652542.3,
    "Annual Police Budget for Communication": 46525.423,
    "Per Month": 3877.1185833333334,
    "Metered Monthly Budget": 581.5677875,
    "YEAR_1": 5387.9929240725005,
    "YEAR_2": 7491.756238575001,
    "YEAR_3": 7843.488436455001,
    "YEAR_4": 8195.220634335,
    "YEAR_5": 8546.952832215002,
    "YEAR_6": 8898.685030095001,
    "YEAR_7": 9250.417227975002,
    "YEAR_8": 9602.149425855,
    "YEAR_9": 9953.881623735002,
    "YEAR_10": 10305.613821615,
    "METERED_Aegis": 85476.15819492751
  },
  {
    "DMA": "Austin",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 476508288,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 23825414.400000002,
    "Annual Police Budget for Communication": 238254.14400000003,
    "Per Month": 19854.512000000002,
    "Metered Monthly Budget": 2978.1768,
    "YEAR_1": 27591.616781280005,
    "YEAR_2": 38364.8735376,
    "YEAR_3": 40166.07486624001,
    "YEAR_4": 41967.27619488,
    "YEAR_5": 43768.47752352001,
    "YEAR_6": 45569.67885216,
    "YEAR_7": 47370.880180800006,
    "YEAR_8": 49172.08150944001,
    "YEAR_9": 50973.282838080006,
    "YEAR_10": 52774.48416672001,
    "METERED_Aegis": 437718.72645072005
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 168190360,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 8409518,
    "Annual Police Budget for Communication": 84095.18000000001,
    "Per Month": 7007.931666666667,
    "Metered Monthly Budget": 1051.18975,
    "YEAR_1": 9738.85255785,
    "YEAR_2": 13541.426359500001,
    "YEAR_3": 14177.185920299997,
    "YEAR_4": 14812.945481100001,
    "YEAR_5": 15448.705041899999,
    "YEAR_6": 16084.4646027,
    "YEAR_7": 16720.2241635,
    "YEAR_8": 17355.983724299997,
    "YEAR_9": 17991.7432851,
    "YEAR_10": 18627.502845900002,
    "METERED_Aegis": 154499.03398214997
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1100000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 55000000,
    "Annual Police Budget for Communication": 550000,
    "Per Month": 45833.333333333336,
    "Metered Monthly Budget": 6875,
    "YEAR_1": 63694.125,
    "YEAR_2": 88563.75,
    "YEAR_3": 92721.75,
    "YEAR_4": 96879.75,
    "YEAR_5": 101037.75,
    "YEAR_6": 105195.75,
    "YEAR_7": 109353.75,
    "YEAR_8": 113511.75,
    "YEAR_9": 117669.75,
    "YEAR_10": 121827.75,
    "METERED_Aegis": 1010455.875
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 270400000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 13520000,
    "Annual Police Budget for Communication": 135200,
    "Per Month": 11266.666666666666,
    "Metered Monthly Budget": 1689.9999999999998,
    "YEAR_1": 15657.173999999997,
    "YEAR_2": 21770.579999999994,
    "YEAR_3": 22792.692,
    "YEAR_4": 23814.803999999996,
    "YEAR_5": 24836.915999999997,
    "YEAR_6": 25859.027999999995,
    "YEAR_7": 26881.139999999996,
    "YEAR_8": 27903.251999999993,
    "YEAR_9": 28925.363999999998,
    "YEAR_10": 29947.475999999995,
    "METERED_Aegis": 248388.42599999995
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 953000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 47650000,
    "Annual Police Budget for Communication": 476500,
    "Per Month": 39708.333333333336,
    "Metered Monthly Budget": 5956.25,
    "YEAR_1": 55182.27374999999,
    "YEAR_2": 76728.41249999999,
    "YEAR_3": 80330.75250000003,
    "YEAR_4": 83933.0925,
    "YEAR_5": 87535.43250000002,
    "YEAR_6": 91137.77249999999,
    "YEAR_7": 94740.11250000002,
    "YEAR_8": 98342.4525,
    "YEAR_9": 101944.79250000001,
    "YEAR_10": 105547.13249999998,
    "METERED_Aegis": 875422.22625
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 172878488,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 8643924.4,
    "Annual Police Budget for Communication": 86439.244,
    "Per Month": 7203.270333333334,
    "Metered Monthly Budget": 1080.49055,
    "YEAR_1": 10010.312749529998,
    "YEAR_2": 13918.879265099999,
    "YEAR_3": 14572.35994974,
    "YEAR_4": 15225.84063438,
    "YEAR_5": 15879.321319020002,
    "YEAR_6": 16532.802003660003,
    "YEAR_7": 17186.282688299998,
    "YEAR_8": 17839.76337294,
    "YEAR_9": 18493.244057579996,
    "YEAR_10": 19146.72474222,
    "METERED_Aegis": 158805.53078247
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 665000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 33250000,
    "Annual Police Budget for Communication": 332500,
    "Per Month": 27708.333333333332,
    "Metered Monthly Budget": 4156.25,
    "YEAR_1": 38505.99375000001,
    "YEAR_2": 53540.8125,
    "YEAR_3": 56054.5125,
    "YEAR_4": 58568.2125,
    "YEAR_5": 61081.9125,
    "YEAR_6": 63595.6125,
    "YEAR_7": 66109.3125,
    "YEAR_8": 68623.0125,
    "YEAR_9": 71136.7125,
    "YEAR_10": 73650.41249999999,
    "METERED_Aegis": 610866.50625
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 185955117,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 9297755.85,
    "Annual Police Budget for Communication": 92977.5585,
    "Per Month": 7748.129875,
    "Metered Monthly Budget": 1162.21948125,
    "YEAR_1": 10767.498605988749,
    "YEAR_2": 14971.7113574625,
    "YEAR_3": 15674.621699722498,
    "YEAR_4": 16377.532041982498,
    "YEAR_5": 17080.4423842425,
    "YEAR_6": 17783.3527265025,
    "YEAR_7": 18486.2630687625,
    "YEAR_8": 19189.173411022497,
    "YEAR_9": 19892.083753282503,
    "YEAR_10": 20594.9940955425,
    "METERED_Aegis": 170817.67314451124
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 1700000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 85000000,
    "Annual Police Budget for Communication": 850000,
    "Per Month": 70833.33333333333,
    "Metered Monthly Budget": 10624.999999999998,
    "YEAR_1": 98436.37499999999,
    "YEAR_2": 136871.24999999997,
    "YEAR_3": 143297.24999999997,
    "YEAR_4": 149723.24999999997,
    "YEAR_5": 156149.24999999997,
    "YEAR_6": 162575.24999999997,
    "YEAR_7": 169001.24999999997,
    "YEAR_8": 175427.24999999997,
    "YEAR_9": 181853.24999999997,
    "YEAR_10": 188279.24999999997,
    "METERED_Aegis": 1561613.6249999998
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 800000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 40000000,
    "Annual Police Budget for Communication": 400000,
    "Per Month": 33333.333333333336,
    "Metered Monthly Budget": 5000,
    "YEAR_1": 46323,
    "YEAR_2": 64410,
    "YEAR_3": 67434,
    "YEAR_4": 70458,
    "YEAR_5": 73482,
    "YEAR_6": 76506,
    "YEAR_7": 79530,
    "YEAR_8": 82554,
    "YEAR_9": 85578,
    "YEAR_10": 88602,
    "METERED_Aegis": 734877
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 293000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 14650000,
    "Annual Police Budget for Communication": 146500,
    "Per Month": 12208.333333333334,
    "Metered Monthly Budget": 1831.25,
    "YEAR_1": 16965.79875,
    "YEAR_2": 23590.162500000002,
    "YEAR_3": 24697.702499999992,
    "YEAR_4": 25805.2425,
    "YEAR_5": 26912.782499999994,
    "YEAR_6": 28020.322500000002,
    "YEAR_7": 29127.862499999996,
    "YEAR_8": 30235.402500000007,
    "YEAR_9": 31342.942499999994,
    "YEAR_10": 32450.482500000006,
    "METERED_Aegis": 269148.70125
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 163911659,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 8195582.95,
    "Annual Police Budget for Communication": 81955.8295,
    "Per Month": 6829.652458333334,
    "Metered Monthly Budget": 1024.44786875,
    "YEAR_1": 9491.099724821252,
    "YEAR_2": 13196.937445237503,
    "YEAR_3": 13816.523516257497,
    "YEAR_4": 14436.1095872775,
    "YEAR_5": 15055.695658297502,
    "YEAR_6": 15675.281729317496,
    "YEAR_7": 16294.867800337503,
    "YEAR_8": 16914.453871357502,
    "YEAR_9": 17534.039942377494,
    "YEAR_10": 18153.6260133975,
    "METERED_Aegis": 150568.63528867875
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 196116407,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 9805820.35,
    "Annual Police Budget for Communication": 98058.2035,
    "Per Month": 8171.516958333334,
    "Metered Monthly Budget": 1225.72754375,
    "YEAR_1": 11355.87540182625,
    "YEAR_2": 15789.822218587502,
    "YEAR_3": 16531.1422370475,
    "YEAR_4": 17272.4622555075,
    "YEAR_5": 18013.782273967503,
    "YEAR_6": 18755.102292427502,
    "YEAR_7": 19496.422310887498,
    "YEAR_8": 20237.7423293475,
    "YEAR_9": 20979.0623478075,
    "YEAR_10": 21720.3823662675,
    "METERED_Aegis": 180151.79603367375
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 220496500,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 11024825,
    "Annual Police Budget for Communication": 110248.25,
    "Per Month": 9187.354166666666,
    "Metered Monthly Budget": 1378.1031249999999,
    "YEAR_1": 12767.574211874999,
    "YEAR_2": 17752.724456250002,
    "YEAR_3": 18586.201226249996,
    "YEAR_4": 19419.677996249997,
    "YEAR_5": 20253.154766249994,
    "YEAR_6": 21086.631536249995,
    "YEAR_7": 21920.108306249997,
    "YEAR_8": 22753.585076249998,
    "YEAR_9": 23587.061846249995,
    "YEAR_10": 24420.53861625,
    "METERED_Aegis": 202547.25803812497
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 780000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 39000000,
    "Annual Police Budget for Communication": 390000,
    "Per Month": 32500,
    "Metered Monthly Budget": 4875,
    "YEAR_1": 45164.925,
    "YEAR_2": 62799.75,
    "YEAR_3": 65748.15,
    "YEAR_4": 68696.55,
    "YEAR_5": 71644.95,
    "YEAR_6": 74593.35,
    "YEAR_7": 77541.75,
    "YEAR_8": 80490.15,
    "YEAR_9": 83438.55,
    "YEAR_10": 86386.94999999998,
    "METERED_Aegis": 716505.0750000001
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 208956813,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 10447840.65,
    "Annual Police Budget for Communication": 104478.40650000001,
    "Per Month": 8706.533875000001,
    "Metered Monthly Budget": 1305.98008125,
    "YEAR_1": 12099.38306074875,
    "YEAR_2": 16823.635406662503,
    "YEAR_3": 17613.4921598025,
    "YEAR_4": 18403.3489129425,
    "YEAR_5": 19193.2056660825,
    "YEAR_6": 19983.062419222497,
    "YEAR_7": 20772.9191723625,
    "YEAR_8": 21562.775925502494,
    "YEAR_9": 22352.6326786425,
    "YEAR_10": 23142.489431782495,
    "METERED_Aegis": 191946.94483375124
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 225280000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 11264000,
    "Annual Police Budget for Communication": 112640,
    "Per Month": 9386.666666666666,
    "Metered Monthly Budget": 1407.9999999999998,
    "YEAR_1": 13044.556799999998,
    "YEAR_2": 18137.855999999996,
    "YEAR_3": 18989.414399999994,
    "YEAR_4": 19840.9728,
    "YEAR_5": 20692.531199999998,
    "YEAR_6": 21544.0896,
    "YEAR_7": 22395.647999999997,
    "YEAR_8": 23247.206400000003,
    "YEAR_9": 24098.764799999997,
    "YEAR_10": 24950.32319999999,
    "METERED_Aegis": 206941.36319999996
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 281479497,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 14073974.850000001,
    "Annual Police Budget for Communication": 140739.74850000002,
    "Per Month": 11728.312375000001,
    "Metered Monthly Budget": 1759.24685625,
    "YEAR_1": 16298.718424413748,
    "YEAR_2": 22662.618002212494,
    "YEAR_3": 23726.6105008725,
    "YEAR_4": 24790.602999532493,
    "YEAR_5": 25854.595498192502,
    "YEAR_6": 26918.587996852508,
    "YEAR_7": 27982.580495512502,
    "YEAR_8": 29046.5729941725,
    "YEAR_9": 30110.565492832495,
    "YEAR_10": 31174.557991492493,
    "METERED_Aegis": 258566.01039608626
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 240187600,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 12009380,
    "Annual Police Budget for Communication": 120093.8,
    "Per Month": 10007.816666666668,
    "Metered Monthly Budget": 1501.1725000000001,
    "YEAR_1": 13907.762743500003,
    "YEAR_2": 19338.104145000005,
    "YEAR_3": 20246.013273,
    "YEAR_4": 21153.922400999996,
    "YEAR_5": 22061.831529000006,
    "YEAR_6": 22969.740657000002,
    "YEAR_7": 23877.649785,
    "YEAR_8": 24785.558913000004,
    "YEAR_9": 25693.468041,
    "YEAR_10": 26601.377169000014,
    "METERED_Aegis": 220635.42865650004
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 316191931,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 15809596.55,
    "Annual Police Budget for Communication": 158095.96550000002,
    "Per Month": 13174.663791666668,
    "Metered Monthly Budget": 1976.19956875,
    "YEAR_1": 18308.69852464125,
    "YEAR_2": 25457.402844637498,
    "YEAR_3": 26652.608343817497,
    "YEAR_4": 27847.813842997497,
    "YEAR_5": 29043.0193421775,
    "YEAR_6": 30238.2248413575,
    "YEAR_7": 31433.430340537503,
    "YEAR_8": 32628.6358397175,
    "YEAR_9": 33823.8413388975,
    "YEAR_10": 35019.0468380775,
    "METERED_Aegis": 290452.7220968587
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 223500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 11175000,
    "Annual Police Budget for Communication": 111750,
    "Per Month": 9312.5,
    "Metered Monthly Budget": 1396.875,
    "YEAR_1": 12941.488125,
    "YEAR_2": 17994.54375,
    "YEAR_3": 18839.37375,
    "YEAR_4": 19684.20375,
    "YEAR_5": 20529.033750000002,
    "YEAR_6": 21373.863750000004,
    "YEAR_7": 22218.693750000006,
    "YEAR_8": 23063.52375,
    "YEAR_9": 23908.353749999995,
    "YEAR_10": 24753.183749999997,
    "METERED_Aegis": 205306.261875
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 380000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 19000000,
    "Annual Police Budget for Communication": 190000,
    "Per Month": 15833.333333333334,
    "Metered Monthly Budget": 2375,
    "YEAR_1": 22003.425,
    "YEAR_2": 30594.75,
    "YEAR_3": 32031.15,
    "YEAR_4": 33467.549999999996,
    "YEAR_5": 34903.95,
    "YEAR_6": 36340.35,
    "YEAR_7": 37776.75,
    "YEAR_8": 39213.15,
    "YEAR_9": 40649.549999999996,
    "YEAR_10": 42085.95,
    "METERED_Aegis": 349066.575
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 42098849,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2104942.45,
    "Annual Police Budget for Communication": 21049.4245,
    "Per Month": 1754.1187083333334,
    "Metered Monthly Budget": 263.11780625,
    "YEAR_1": 2437.68122778375,
    "YEAR_2": 3389.4835801125005,
    "YEAR_3": 3548.6172293325003,
    "YEAR_4": 3707.7508785524997,
    "YEAR_5": 3866.884527772499,
    "YEAR_6": 4026.0181769925002,
    "YEAR_7": 4185.1518262125,
    "YEAR_8": 4344.2854754325,
    "YEAR_9": 4503.4191246525,
    "YEAR_10": 4662.5527738724995,
    "METERED_Aegis": 38671.844820716244
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 176500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 8825000,
    "Annual Police Budget for Communication": 88250,
    "Per Month": 7354.166666666667,
    "Metered Monthly Budget": 1103.125,
    "YEAR_1": 10220.011875,
    "YEAR_2": 14210.45625,
    "YEAR_3": 14877.626250000001,
    "YEAR_4": 15544.79625,
    "YEAR_5": 16211.966250000001,
    "YEAR_6": 16879.13625,
    "YEAR_7": 17546.306249999998,
    "YEAR_8": 18213.47625,
    "YEAR_9": 18880.646249999994,
    "YEAR_10": 19547.816250000003,
    "METERED_Aegis": 162132.23812499997
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 470000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 23500000,
    "Annual Police Budget for Communication": 235000,
    "Per Month": 19583.333333333332,
    "Metered Monthly Budget": 2937.4999999999995,
    "YEAR_1": 27214.762499999997,
    "YEAR_2": 37840.874999999985,
    "YEAR_3": 39617.47499999999,
    "YEAR_4": 41394.075,
    "YEAR_5": 43170.674999999996,
    "YEAR_6": 44947.274999999994,
    "YEAR_7": 46723.874999999985,
    "YEAR_8": 48500.474999999984,
    "YEAR_9": 50277.075,
    "YEAR_10": 52053.674999999996,
    "METERED_Aegis": 431740.23749999993
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 165000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 8250000,
    "Annual Police Budget for Communication": 82500,
    "Per Month": 6875,
    "Metered Monthly Budget": 1031.25,
    "YEAR_1": 9554.118749999998,
    "YEAR_2": 13284.5625,
    "YEAR_3": 13908.2625,
    "YEAR_4": 14531.9625,
    "YEAR_5": 15155.6625,
    "YEAR_6": 15779.3625,
    "YEAR_7": 16403.0625,
    "YEAR_8": 17026.7625,
    "YEAR_9": 17650.462499999998,
    "YEAR_10": 18274.162500000002,
    "METERED_Aegis": 151568.38125
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 77285380,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3864269,
    "Annual Police Budget for Communication": 38642.69,
    "Per Month": 3220.224166666667,
    "Metered Monthly Budget": 483.03362500000003,
    "YEAR_1": 4475.113322175,
    "YEAR_2": 6222.439157250001,
    "YEAR_3": 6514.577893650002,
    "YEAR_4": 6806.716630049999,
    "YEAR_5": 7098.855366450001,
    "YEAR_6": 7390.994102850002,
    "YEAR_7": 7683.13283925,
    "YEAR_8": 7975.271575649998,
    "YEAR_9": 8267.41031205,
    "YEAR_10": 8559.54904845,
    "METERED_Aegis": 70994.06024782501
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 144899000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 7244950,
    "Annual Police Budget for Communication": 72449.5,
    "Per Month": 6037.458333333333,
    "Metered Monthly Budget": 905.61875,
    "YEAR_1": 8390.195471250001,
    "YEAR_2": 11666.180737499999,
    "YEAR_3": 12213.8989575,
    "YEAR_4": 12761.6171775,
    "YEAR_5": 13309.335397499997,
    "YEAR_6": 13857.0536175,
    "YEAR_7": 14404.771837499997,
    "YEAR_8": 14952.4900575,
    "YEAR_9": 15500.208277499998,
    "YEAR_10": 16047.926497499999,
    "METERED_Aegis": 133103.67802875
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 103000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 5150000,
    "Annual Police Budget for Communication": 51500,
    "Per Month": 4291.666666666667,
    "Metered Monthly Budget": 643.75,
    "YEAR_1": 5964.08625,
    "YEAR_2": 8292.7875,
    "YEAR_3": 8682.127499999999,
    "YEAR_4": 9071.4675,
    "YEAR_5": 9460.807499999999,
    "YEAR_6": 9850.147500000001,
    "YEAR_7": 10239.487500000001,
    "YEAR_8": 10628.827500000001,
    "YEAR_9": 11018.1675,
    "YEAR_10": 11407.5075,
    "METERED_Aegis": 94615.41375
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 80197258,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4009862.9000000004,
    "Annual Police Budget for Communication": 40098.62900000001,
    "Per Month": 3341.5524166666673,
    "Metered Monthly Budget": 501.23286250000007,
    "YEAR_1": 4643.7219779175,
    "YEAR_2": 6456.881734725001,
    "YEAR_3": 6760.027369965001,
    "YEAR_4": 7063.173005205001,
    "YEAR_5": 7366.318640445002,
    "YEAR_6": 7669.464275685002,
    "YEAR_7": 7972.609910925001,
    "YEAR_8": 8275.755546165,
    "YEAR_9": 8578.901181405,
    "YEAR_10": 8882.046816645001,
    "METERED_Aegis": 73668.9004590825
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 213832312,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 10691615.600000001,
    "Annual Police Budget for Communication": 106916.15600000002,
    "Per Month": 8909.679666666669,
    "Metered Monthly Budget": 1336.4519500000004,
    "YEAR_1": 12381.692735970002,
    "YEAR_2": 17216.174019900005,
    "YEAR_3": 18024.460159260005,
    "YEAR_4": 18832.746298620004,
    "YEAR_5": 19641.032437980004,
    "YEAR_6": 20449.318577340004,
    "YEAR_7": 21257.604716700007,
    "YEAR_8": 22065.890856060003,
    "YEAR_9": 22874.176995420003,
    "YEAR_10": 23682.46313478001,
    "METERED_Aegis": 196425.55993203004
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 312266304,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 15613315.200000001,
    "Annual Police Budget for Communication": 156133.152,
    "Per Month": 13011.096,
    "Metered Monthly Budget": 1951.6644,
    "YEAR_1": 18081.390000239997,
    "YEAR_2": 25141.340800799997,
    "YEAR_3": 26321.707429919996,
    "YEAR_4": 27502.07405904,
    "YEAR_5": 28682.440688159997,
    "YEAR_6": 29862.807317279992,
    "YEAR_7": 31043.1739464,
    "YEAR_8": 32223.540575519997,
    "YEAR_9": 33403.907204639996,
    "YEAR_10": 34584.273833759995,
    "METERED_Aegis": 286846.65585576
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 72940720,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3647036,
    "Annual Police Budget for Communication": 36470.36,
    "Per Month": 3039.1966666666667,
    "Metered Monthly Budget": 455.8795,
    "YEAR_1": 4223.541215700001,
    "YEAR_2": 5872.639719000001,
    "YEAR_3": 6148.3556406,
    "YEAR_4": 6424.071562200001,
    "YEAR_5": 6699.787483800001,
    "YEAR_6": 6975.5034054,
    "YEAR_7": 7251.219327000001,
    "YEAR_8": 7526.935248600001,
    "YEAR_9": 7802.6511702,
    "YEAR_10": 8078.367091800001,
    "METERED_Aegis": 67003.0718643
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 74216221,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3710811.0500000003,
    "Annual Police Budget for Communication": 37108.1105,
    "Per Month": 3092.342541666667,
    "Metered Monthly Budget": 463.85138125000003,
    "YEAR_1": 4297.39750672875,
    "YEAR_2": 5975.3334932625,
    "YEAR_3": 6255.8708086424995,
    "YEAR_4": 6536.4081240225005,
    "YEAR_5": 6816.945439402501,
    "YEAR_6": 7097.482754782501,
    "YEAR_7": 7378.0200701625,
    "YEAR_8": 7658.557385542501,
    "YEAR_9": 7939.0947009225,
    "YEAR_10": 8219.632016302501,
    "METERED_Aegis": 68174.74229977126
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 220000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 11000000,
    "Annual Police Budget for Communication": 110000,
    "Per Month": 9166.666666666666,
    "Metered Monthly Budget": 1374.9999999999998,
    "YEAR_1": 12738.824999999997,
    "YEAR_2": 17712.75,
    "YEAR_3": 18544.349999999995,
    "YEAR_4": 19375.949999999997,
    "YEAR_5": 20207.549999999996,
    "YEAR_6": 21039.149999999994,
    "YEAR_7": 21870.749999999996,
    "YEAR_8": 22702.35,
    "YEAR_9": 23533.949999999993,
    "YEAR_10": 24365.549999999996,
    "METERED_Aegis": 202091.17499999996
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 125700000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 6285000,
    "Annual Police Budget for Communication": 62850,
    "Per Month": 5237.5,
    "Metered Monthly Budget": 785.625,
    "YEAR_1": 7278.501375,
    "YEAR_2": 10120.42125,
    "YEAR_3": 10595.567249999998,
    "YEAR_4": 11070.71325,
    "YEAR_5": 11545.85925,
    "YEAR_6": 12021.005249999998,
    "YEAR_7": 12496.151249999999,
    "YEAR_8": 12971.29725,
    "YEAR_9": 13446.44325,
    "YEAR_10": 13921.58925,
    "METERED_Aegis": 115467.548625
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 24735000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1236750,
    "Annual Police Budget for Communication": 12367.5,
    "Per Month": 1030.625,
    "Metered Monthly Budget": 154.59375,
    "YEAR_1": 1432.2492562499997,
    "YEAR_2": 1991.4766875,
    "YEAR_3": 2084.9749874999998,
    "YEAR_4": 2178.4732875000004,
    "YEAR_5": 2271.9715874999997,
    "YEAR_6": 2365.4698875,
    "YEAR_7": 2458.9681875,
    "YEAR_8": 2552.4664875,
    "YEAR_9": 2645.9647875000005,
    "YEAR_10": 2739.4630875,
    "METERED_Aegis": 22721.478243750003
  },
  {
    "DMA": "Madison",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 85100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4255000,
    "Annual Police Budget for Communication": 42550,
    "Per Month": 3545.8333333333335,
    "Metered Monthly Budget": 531.875,
    "YEAR_1": 4927.609125,
    "YEAR_2": 6851.6137499999995,
    "YEAR_3": 7173.29175,
    "YEAR_4": 7494.969749999999,
    "YEAR_5": 7816.647749999999,
    "YEAR_6": 8138.32575,
    "YEAR_7": 8460.00375,
    "YEAR_8": 8781.681749999998,
    "YEAR_9": 9103.35975,
    "YEAR_10": 9425.037750000001,
    "METERED_Aegis": 78172.54087499999
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 178400000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 8920000,
    "Annual Police Budget for Communication": 89200,
    "Per Month": 7433.333333333333,
    "Metered Monthly Budget": 1115,
    "YEAR_1": 10330.028999999999,
    "YEAR_2": 14363.430000000002,
    "YEAR_3": 15037.782000000001,
    "YEAR_4": 15712.134,
    "YEAR_5": 16386.486,
    "YEAR_6": 17060.838,
    "YEAR_7": 17735.19,
    "YEAR_8": 18409.541999999998,
    "YEAR_9": 19083.893999999997,
    "YEAR_10": 19758.246000000006,
    "METERED_Aegis": 163877.57100000003
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 346000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 17300000,
    "Annual Police Budget for Communication": 173000,
    "Per Month": 14416.666666666666,
    "Metered Monthly Budget": 2162.5,
    "YEAR_1": 20034.697500000002,
    "YEAR_2": 27857.325,
    "YEAR_3": 29165.204999999998,
    "YEAR_4": 30473.085000000003,
    "YEAR_5": 31780.964999999997,
    "YEAR_6": 33088.845,
    "YEAR_7": 34396.725,
    "YEAR_8": 35704.604999999996,
    "YEAR_9": 37012.485,
    "YEAR_10": 38320.365,
    "METERED_Aegis": 317834.3025
  },
  {
    "DMA": "Springfield, MO",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 52269039,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2613451.95,
    "Annual Police Budget for Communication": 26134.519500000002,
    "Per Month": 2177.8766250000003,
    "Metered Monthly Budget": 326.68149375,
    "YEAR_1": 3026.57336699625,
    "YEAR_2": 4208.3110024874995,
    "YEAR_3": 4405.8879699075,
    "YEAR_4": 4603.4649373275,
    "YEAR_5": 4801.0419047475,
    "YEAR_6": 4998.618872167501,
    "YEAR_7": 5196.195839587501,
    "YEAR_8": 5393.7728070075,
    "YEAR_9": 5591.349774427499,
    "YEAR_10": 5788.926741847499,
    "METERED_Aegis": 48014.14321650375
  },
  {
    "DMA": "Columbia, SC",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 51422302,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2571115.1,
    "Annual Police Budget for Communication": 25711.151,
    "Per Month": 2142.5959166666667,
    "Metered Monthly Budget": 321.3893875,
    "YEAR_1": 2977.5441194324994,
    "YEAR_2": 4140.1380897750005,
    "YEAR_3": 4334.514391334999,
    "YEAR_4": 4528.890692895,
    "YEAR_5": 4723.266994455,
    "YEAR_6": 4917.643296015,
    "YEAR_7": 5112.019597574999,
    "YEAR_8": 5306.395899134999,
    "YEAR_9": 5500.772200695,
    "YEAR_10": 5695.148502255,
    "METERED_Aegis": 47236.33378356749
  },
  {
    "DMA": "Rochester, NY",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 74229700,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3711485,
    "Annual Police Budget for Communication": 37114.85,
    "Per Month": 3092.9041666666667,
    "Metered Monthly Budget": 463.93562499999996,
    "YEAR_1": 4298.177991375,
    "YEAR_2": 5976.41872125,
    "YEAR_3": 6257.00698725,
    "YEAR_4": 6537.59525325,
    "YEAR_5": 6818.183519249998,
    "YEAR_6": 7098.77178525,
    "YEAR_7": 7379.360051249999,
    "YEAR_8": 7659.9483172499995,
    "YEAR_9": 7940.536583249999,
    "YEAR_10": 8221.124849249998,
    "METERED_Aegis": 68187.12405862499
  },
  {
    "DMA": "Portland",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 206418650,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 10320932.5,
    "Annual Police Budget for Communication": 103209.325,
    "Per Month": 8600.777083333332,
    "Metered Monthly Budget": 1290.1165624999999,
    "YEAR_1": 11952.413904937497,
    "YEAR_2": 16619.281558125,
    "YEAR_3": 17399.544055125,
    "YEAR_4": 18179.806552125,
    "YEAR_5": 18960.069049125,
    "YEAR_6": 19740.331546125,
    "YEAR_7": 20520.594043125,
    "YEAR_8": 21300.856540125,
    "YEAR_9": 22081.119037124998,
    "YEAR_10": 22861.381534124997,
    "METERED_Aegis": 189615.3978200625
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 71296636,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3564831.8000000003,
    "Annual Police Budget for Communication": 35648.31800000001,
    "Per Month": 2970.6931666666674,
    "Metered Monthly Budget": 445.6039750000001,
    "YEAR_1": 4128.342586785002,
    "YEAR_2": 5740.27040595,
    "YEAR_3": 6009.771690030001,
    "YEAR_4": 6279.272974110001,
    "YEAR_5": 6548.774258190001,
    "YEAR_6": 6818.275542270003,
    "YEAR_7": 7087.776826349999,
    "YEAR_8": 7357.278110430002,
    "YEAR_9": 7626.77939451,
    "YEAR_10": 7896.28067859,
    "METERED_Aegis": 65492.82246721501
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 84048006,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4202400.3,
    "Annual Police Budget for Communication": 42024.003,
    "Per Month": 3502.0002499999996,
    "Metered Monthly Budget": 525.3000374999999,
    "YEAR_1": 4866.6947274224985,
    "YEAR_2": 6766.915083074999,
    "YEAR_3": 7084.616545754999,
    "YEAR_4": 7402.318008434999,
    "YEAR_5": 7720.019471115,
    "YEAR_6": 8037.720933794999,
    "YEAR_7": 8355.422396474998,
    "YEAR_8": 8673.123859154999,
    "YEAR_9": 8990.825321834998,
    "YEAR_10": 9308.526784515,
    "METERED_Aegis": 77206.18313157748
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 780000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 39000000,
    "Annual Police Budget for Communication": 390000,
    "Per Month": 32500,
    "Metered Monthly Budget": 4875,
    "YEAR_1": 45164.925,
    "YEAR_2": 62799.75,
    "YEAR_3": 65748.15,
    "YEAR_4": 68696.55,
    "YEAR_5": 71644.95,
    "YEAR_6": 74593.35,
    "YEAR_7": 77541.75,
    "YEAR_8": 80490.15,
    "YEAR_9": 83438.55,
    "YEAR_10": 86386.94999999998,
    "METERED_Aegis": 716505.0750000001
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 564800000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 28240000,
    "Annual Police Budget for Communication": 282400,
    "Per Month": 23533.333333333332,
    "Metered Monthly Budget": 3529.9999999999995,
    "YEAR_1": 32704.037999999993,
    "YEAR_2": 45473.459999999985,
    "YEAR_3": 47608.403999999995,
    "YEAR_4": 49743.34799999998,
    "YEAR_5": 51878.292,
    "YEAR_6": 54013.23599999999,
    "YEAR_7": 56148.17999999999,
    "YEAR_8": 58283.12399999999,
    "YEAR_9": 60418.068,
    "YEAR_10": 62553.011999999995,
    "METERED_Aegis": 518823.1619999999
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 337000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 16850000,
    "Annual Police Budget for Communication": 168500,
    "Per Month": 14041.666666666666,
    "Metered Monthly Budget": 2106.25,
    "YEAR_1": 19513.56375,
    "YEAR_2": 27132.712499999998,
    "YEAR_3": 28406.5725,
    "YEAR_4": 29680.432500000003,
    "YEAR_5": 30954.2925,
    "YEAR_6": 32228.152500000004,
    "YEAR_7": 33502.0125,
    "YEAR_8": 34775.872500000005,
    "YEAR_9": 36049.732500000006,
    "YEAR_10": 37323.5925,
    "METERED_Aegis": 309566.93625
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 85435603,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4271780.15,
    "Annual Police Budget for Communication": 42717.8015,
    "Per Month": 3559.8167916666666,
    "Metered Monthly Budget": 533.97251875,
    "YEAR_1": 4947.04179721125,
    "YEAR_2": 6878.6339865375,
    "YEAR_3": 7201.5805658775,
    "YEAR_4": 7524.5271452175,
    "YEAR_5": 7847.4737245575,
    "YEAR_6": 8170.420303897499,
    "YEAR_7": 8493.3668832375,
    "YEAR_8": 8816.313462577498,
    "YEAR_9": 9139.260041917498,
    "YEAR_10": 9462.206621257497,
    "METERED_Aegis": 78480.82453228874
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 57261600,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2863080,
    "Annual Police Budget for Communication": 28630.8,
    "Per Month": 2385.9,
    "Metered Monthly Budget": 357.885,
    "YEAR_1": 3315.661371,
    "YEAR_2": 4610.2745700000005,
    "YEAR_3": 4826.723418,
    "YEAR_4": 5043.172265999999,
    "YEAR_5": 5259.6211140000005,
    "YEAR_6": 5476.069962,
    "YEAR_7": 5692.518809999999,
    "YEAR_8": 5908.9676580000005,
    "YEAR_9": 6125.416506,
    "YEAR_10": 6341.8653540000005,
    "METERED_Aegis": 52600.29102900001
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 570000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 28500000,
    "Annual Police Budget for Communication": 285000,
    "Per Month": 23750,
    "Metered Monthly Budget": 3562.5,
    "YEAR_1": 33005.1375,
    "YEAR_2": 45892.125,
    "YEAR_3": 48046.725,
    "YEAR_4": 50201.325000000004,
    "YEAR_5": 52355.924999999996,
    "YEAR_6": 54510.525,
    "YEAR_7": 56665.125,
    "YEAR_8": 58819.725,
    "YEAR_9": 60974.325000000004,
    "YEAR_10": 63128.924999999996,
    "METERED_Aegis": 523599.8625
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 70209286,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3510464.3000000003,
    "Annual Police Budget for Communication": 35104.643000000004,
    "Per Month": 2925.386916666667,
    "Metered Monthly Budget": 438.8080375,
    "YEAR_1": 4065.3809442224997,
    "YEAR_2": 5652.725139074999,
    "YEAR_3": 5918.116240154999,
    "YEAR_4": 6183.507341234999,
    "YEAR_5": 6448.898442315,
    "YEAR_6": 6714.289543395,
    "YEAR_7": 6979.680644475,
    "YEAR_8": 7245.071745554999,
    "YEAR_9": 7510.462846635,
    "YEAR_10": 7775.853947715,
    "METERED_Aegis": 64493.98683477749
  },
  {
    "DMA": "Charleston, SC",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 60782338,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3039116.9000000004,
    "Annual Police Budget for Communication": 30391.169000000005,
    "Per Month": 2532.597416666667,
    "Metered Monthly Budget": 379.88961250000006,
    "YEAR_1": 3519.525303967501,
    "YEAR_2": 4893.737988225001,
    "YEAR_3": 5123.495225865001,
    "YEAR_4": 5353.252463505001,
    "YEAR_5": 5583.009701145001,
    "YEAR_6": 5812.766938785002,
    "YEAR_7": 6042.524176425001,
    "YEAR_8": 6272.281414065001,
    "YEAR_9": 6502.038651705001,
    "YEAR_10": 6731.795889345,
    "METERED_Aegis": 55834.427753032505
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 79026100,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3951305,
    "Annual Police Budget for Communication": 39513.05,
    "Per Month": 3292.754166666667,
    "Metered Monthly Budget": 493.91312500000004,
    "YEAR_1": 4575.907537875,
    "YEAR_2": 6362.588876250002,
    "YEAR_3": 6661.307534250001,
    "YEAR_4": 6960.026192250001,
    "YEAR_5": 7258.74485025,
    "YEAR_6": 7557.463508250001,
    "YEAR_7": 7856.18216625,
    "YEAR_8": 8154.90082425,
    "YEAR_9": 8453.61948225,
    "YEAR_10": 8752.33814025,
    "METERED_Aegis": 72593.079112125
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 84594727,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4229736.350000001,
    "Annual Police Budget for Communication": 42297.36350000001,
    "Per Month": 3524.7802916666674,
    "Metered Monthly Budget": 528.7170437500001,
    "YEAR_1": 4898.351923526252,
    "YEAR_2": 6810.932957587501,
    "YEAR_3": 7130.701025647501,
    "YEAR_4": 7450.469093707502,
    "YEAR_5": 7770.237161767502,
    "YEAR_6": 8090.005229827501,
    "YEAR_7": 8409.773297887501,
    "YEAR_8": 8729.541365947502,
    "YEAR_9": 9049.3094340075,
    "YEAR_10": 9369.077502067501,
    "METERED_Aegis": 77708.39899197377
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 188972651,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 9448632.55,
    "Annual Police Budget for Communication": 94486.3255,
    "Per Month": 7873.860458333334,
    "Metered Monthly Budget": 1181.07906875,
    "YEAR_1": 10942.22514034125,
    "YEAR_2": 15214.6605636375,
    "YEAR_3": 15928.977184417501,
    "YEAR_4": 16643.2938051975,
    "YEAR_5": 17357.6104259775,
    "YEAR_6": 18071.9270467575,
    "YEAR_7": 18786.243667537503,
    "YEAR_8": 19500.5602883175,
    "YEAR_9": 20214.8769090975,
    "YEAR_10": 20929.1935298775,
    "METERED_Aegis": 173589.56856115875
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 133950000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 6697500,
    "Annual Police Budget for Communication": 66975,
    "Per Month": 5581.25,
    "Metered Monthly Budget": 837.1875,
    "YEAR_1": 7756.2073125,
    "YEAR_2": 10784.649375,
    "YEAR_3": 11290.980375000001,
    "YEAR_4": 11797.311375,
    "YEAR_5": 12303.642375,
    "YEAR_6": 12809.973375,
    "YEAR_7": 13316.304374999998,
    "YEAR_8": 13822.635375,
    "YEAR_9": 14328.966374999998,
    "YEAR_10": 14835.297375,
    "METERED_Aegis": 123045.96768749999
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 200226764,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 10011338.200000001,
    "Annual Police Budget for Communication": 100113.38200000001,
    "Per Month": 8342.781833333334,
    "Metered Monthly Budget": 1251.417275,
    "YEAR_1": 11593.880485965,
    "YEAR_2": 16120.75733655,
    "YEAR_3": 16877.614504470002,
    "YEAR_4": 17634.47167239,
    "YEAR_5": 18391.32884031,
    "YEAR_6": 19148.186008229997,
    "YEAR_7": 19905.04317615,
    "YEAR_8": 20661.90034407,
    "YEAR_9": 21418.75751199,
    "YEAR_10": 22175.61467991,
    "METERED_Aegis": 183927.554560035
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 29000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1450000,
    "Annual Police Budget for Communication": 14500,
    "Per Month": 1208.3333333333333,
    "Metered Monthly Budget": 181.24999999999997,
    "YEAR_1": 1679.2087499999996,
    "YEAR_2": 2334.8624999999993,
    "YEAR_3": 2444.482499999999,
    "YEAR_4": 2554.1024999999995,
    "YEAR_5": 2663.7225000000003,
    "YEAR_6": 2773.3424999999993,
    "YEAR_7": 2882.9625000000005,
    "YEAR_8": 2992.5824999999995,
    "YEAR_9": 3102.2025000000003,
    "YEAR_10": 3211.8224999999993,
    "METERED_Aegis": 26639.291249999995
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 300000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 15000000,
    "Annual Police Budget for Communication": 150000,
    "Per Month": 12500,
    "Metered Monthly Budget": 1875,
    "YEAR_1": 17371.125,
    "YEAR_2": 24153.75,
    "YEAR_3": 25287.75,
    "YEAR_4": 26421.75,
    "YEAR_5": 27555.75,
    "YEAR_6": 28689.75,
    "YEAR_7": 29823.75,
    "YEAR_8": 30957.75,
    "YEAR_9": 32091.75,
    "YEAR_10": 33225.75,
    "METERED_Aegis": 275578.875
  },
  {
    "DMA": "Jackson, MS",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 36746853,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1837342.6500000001,
    "Annual Police Budget for Communication": 18373.4265,
    "Per Month": 1531.1188750000001,
    "Metered Monthly Budget": 229.66783125,
    "YEAR_1": 2127.78058939875,
    "YEAR_2": 2958.5810021624993,
    "YEAR_3": 3097.4841065025003,
    "YEAR_4": 3236.3872108424994,
    "YEAR_5": 3375.2903151825,
    "YEAR_6": 3514.1934195224994,
    "YEAR_7": 3653.0965238625,
    "YEAR_8": 3791.9996282024986,
    "YEAR_9": 3930.9027325425,
    "YEAR_10": 4069.8058368825004,
    "METERED_Aegis": 33755.521365101245
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 290000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 14500000,
    "Annual Police Budget for Communication": 145000,
    "Per Month": 12083.333333333334,
    "Metered Monthly Budget": 1812.5,
    "YEAR_1": 16792.0875,
    "YEAR_2": 23348.625,
    "YEAR_3": 24444.825,
    "YEAR_4": 25541.024999999998,
    "YEAR_5": 26637.225000000002,
    "YEAR_6": 27733.425,
    "YEAR_7": 28829.625,
    "YEAR_8": 29925.825,
    "YEAR_9": 31022.024999999998,
    "YEAR_10": 32118.225000000002,
    "METERED_Aegis": 266392.9125
  },
  {
    "DMA": "Boise",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 84487908,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4224395.4,
    "Annual Police Budget for Communication": 42243.954000000005,
    "Per Month": 3520.3295000000003,
    "Metered Monthly Budget": 528.049425,
    "YEAR_1": 4892.166702855,
    "YEAR_2": 6802.33269285,
    "YEAR_3": 7121.696985090001,
    "YEAR_4": 7441.061277329999,
    "YEAR_5": 7760.42556957,
    "YEAR_6": 8079.789861810001,
    "YEAR_7": 8399.15415405,
    "YEAR_8": 8718.51844629,
    "YEAR_9": 9037.88273853,
    "YEAR_10": 9357.247030769999,
    "METERED_Aegis": 77610.275459145
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 550000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 27500000,
    "Annual Police Budget for Communication": 275000,
    "Per Month": 22916.666666666668,
    "Metered Monthly Budget": 3437.5,
    "YEAR_1": 31847.0625,
    "YEAR_2": 44281.875,
    "YEAR_3": 46360.875,
    "YEAR_4": 48439.875,
    "YEAR_5": 50518.875,
    "YEAR_6": 52597.875,
    "YEAR_7": 54676.875,
    "YEAR_8": 56755.875,
    "YEAR_9": 58834.875,
    "YEAR_10": 60913.875,
    "METERED_Aegis": 505227.9375
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 137000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 6850000,
    "Annual Police Budget for Communication": 68500,
    "Per Month": 5708.333333333333,
    "Metered Monthly Budget": 856.2499999999999,
    "YEAR_1": 7932.813749999999,
    "YEAR_2": 11030.212499999998,
    "YEAR_3": 11548.072499999998,
    "YEAR_4": 12065.932499999999,
    "YEAR_5": 12583.792499999998,
    "YEAR_6": 13101.6525,
    "YEAR_7": 13619.512499999999,
    "YEAR_8": 14137.3725,
    "YEAR_9": 14655.232499999996,
    "YEAR_10": 15173.0925,
    "METERED_Aegis": 125847.68624999998
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 28113083,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1405654.1500000001,
    "Annual Police Budget for Communication": 14056.541500000001,
    "Per Month": 1171.3784583333334,
    "Metered Monthly Budget": 175.70676875,
    "YEAR_1": 1627.85292976125,
    "YEAR_2": 2263.4545950375004,
    "YEAR_3": 2369.7220487775003,
    "YEAR_4": 2475.9895025175,
    "YEAR_5": 2582.2569562575,
    "YEAR_6": 2688.5244099975,
    "YEAR_7": 2794.7918637375,
    "YEAR_8": 2901.0593174774995,
    "YEAR_9": 3007.3267712174998,
    "YEAR_10": 3113.5942249575,
    "METERED_Aegis": 25824.57261973875
  },
  {
    "DMA": "Reno",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 150129366,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 7506468.300000001,
    "Annual Police Budget for Communication": 75064.683,
    "Per Month": 6255.39025,
    "Metered Monthly Budget": 938.3085375,
    "YEAR_1": 8693.0532765225,
    "YEAR_2": 12087.290580075001,
    "YEAR_3": 12654.779583555,
    "YEAR_4": 13222.268587035,
    "YEAR_5": 13789.757590515,
    "YEAR_6": 14357.246593995002,
    "YEAR_7": 14924.735597475003,
    "YEAR_8": 15492.224600955002,
    "YEAR_9": 16059.713604435,
    "YEAR_10": 16627.202607915002,
    "METERED_Aegis": 137908.27262247753
  },
  {
    "DMA": "Washington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 313900000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 15695000,
    "Annual Police Budget for Communication": 156950,
    "Per Month": 13079.166666666666,
    "Metered Monthly Budget": 1961.8749999999998,
    "YEAR_1": 18175.987124999996,
    "YEAR_2": 25272.873749999995,
    "YEAR_3": 26459.415749999996,
    "YEAR_4": 27645.957749999994,
    "YEAR_5": 28832.499749999995,
    "YEAR_6": 30019.041749999997,
    "YEAR_7": 31205.583749999998,
    "YEAR_8": 32392.125749999992,
    "YEAR_9": 33578.66774999999,
    "YEAR_10": 34765.209749999995,
    "METERED_Aegis": 288347.36287499993
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 171100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 8555000,
    "Annual Police Budget for Communication": 85550,
    "Per Month": 7129.166666666667,
    "Metered Monthly Budget": 1069.375,
    "YEAR_1": 9907.331624999999,
    "YEAR_2": 13775.68875,
    "YEAR_3": 14422.44675,
    "YEAR_4": 15069.20475,
    "YEAR_5": 15715.962749999999,
    "YEAR_6": 16362.72075,
    "YEAR_7": 17009.47875,
    "YEAR_8": 17656.23675,
    "YEAR_9": 18302.994749999998,
    "YEAR_10": 18949.75275,
    "METERED_Aegis": 157171.81837499997
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 72843752,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3642187.6,
    "Annual Police Budget for Communication": 36421.876000000004,
    "Per Month": 3035.156333333334,
    "Metered Monthly Budget": 455.2734500000001,
    "YEAR_1": 4217.926404870001,
    "YEAR_2": 5864.8325829000005,
    "YEAR_3": 6140.181965460001,
    "YEAR_4": 6415.53134802,
    "YEAR_5": 6690.88073058,
    "YEAR_6": 6966.230113140001,
    "YEAR_7": 7241.579495700003,
    "YEAR_8": 7516.9288782600015,
    "YEAR_9": 7792.278260820002,
    "YEAR_10": 8067.627643380001,
    "METERED_Aegis": 66913.99742313001
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 118200000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 5910000,
    "Annual Police Budget for Communication": 59100,
    "Per Month": 4925,
    "Metered Monthly Budget": 738.75,
    "YEAR_1": 6844.223250000001,
    "YEAR_2": 9516.577500000001,
    "YEAR_3": 9963.373500000003,
    "YEAR_4": 10410.1695,
    "YEAR_5": 10856.965499999998,
    "YEAR_6": 11303.761499999997,
    "YEAR_7": 11750.557499999999,
    "YEAR_8": 12197.353499999997,
    "YEAR_9": 12644.149499999998,
    "YEAR_10": 13090.945499999998,
    "METERED_Aegis": 108578.07675
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 40483972,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2024198.6,
    "Annual Police Budget for Communication": 20241.986,
    "Per Month": 1686.8321666666668,
    "Metered Monthly Budget": 253.02482500000002,
    "YEAR_1": 2344.173793695,
    "YEAR_2": 3259.46579565,
    "YEAR_3": 3412.4952098100002,
    "YEAR_4": 3565.5246239700004,
    "YEAR_5": 3718.5540381299998,
    "YEAR_6": 3871.58345229,
    "YEAR_7": 4024.6128664500006,
    "YEAR_8": 4177.642280610001,
    "YEAR_9": 4330.671694770001,
    "YEAR_10": 4483.70110893,
    "METERED_Aegis": 37188.424864305
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 76088613,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3804430.6500000004,
    "Annual Police Budget for Communication": 38044.306500000006,
    "Per Month": 3170.3588750000004,
    "Metered Monthly Budget": 475.55383125000003,
    "YEAR_1": 4405.81602499875,
    "YEAR_2": 6126.0844541625,
    "YEAR_3": 6413.699411302501,
    "YEAR_4": 6701.314368442501,
    "YEAR_5": 6988.9293255825005,
    "YEAR_6": 7276.544282722501,
    "YEAR_7": 7564.1592398625,
    "YEAR_8": 7851.7741970025,
    "YEAR_9": 8139.389154142499,
    "YEAR_10": 8427.004111282502,
    "METERED_Aegis": 69894.71456950126
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 76400000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3820000,
    "Annual Police Budget for Communication": 38200,
    "Per Month": 3183.3333333333335,
    "Metered Monthly Budget": 477.5,
    "YEAR_1": 4423.8465,
    "YEAR_2": 6151.155000000001,
    "YEAR_3": 6439.947,
    "YEAR_4": 6728.7390000000005,
    "YEAR_5": 7017.531,
    "YEAR_6": 7306.323,
    "YEAR_7": 7595.115000000001,
    "YEAR_8": 7883.907,
    "YEAR_9": 8172.699,
    "YEAR_10": 8461.491,
    "METERED_Aegis": 70180.75349999999
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 61588930,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3079446.5,
    "Annual Police Budget for Communication": 30794.465,
    "Per Month": 2566.2054166666667,
    "Metered Monthly Budget": 384.9308125,
    "YEAR_1": 3566.2300054875,
    "YEAR_2": 4958.678726624999,
    "YEAR_3": 5191.484882025001,
    "YEAR_4": 5424.291037425,
    "YEAR_5": 5657.097192825,
    "YEAR_6": 5889.903348224999,
    "YEAR_7": 6122.709503624999,
    "YEAR_8": 6355.515659024999,
    "YEAR_9": 6588.321814424999,
    "YEAR_10": 6821.127969825,
    "METERED_Aegis": 56575.36013951249
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 125290000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 6264500,
    "Annual Police Budget for Communication": 62645,
    "Per Month": 5220.416666666667,
    "Metered Monthly Budget": 783.0625,
    "YEAR_1": 7254.760837499999,
    "YEAR_2": 10087.411124999999,
    "YEAR_3": 10561.007325,
    "YEAR_4": 11034.603524999999,
    "YEAR_5": 11508.199724999999,
    "YEAR_6": 11981.795925,
    "YEAR_7": 12455.392124999998,
    "YEAR_8": 12928.988325,
    "YEAR_9": 13402.584525,
    "YEAR_10": 13876.180724999998,
    "METERED_Aegis": 115090.92416249998
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 183000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 9150000,
    "Annual Police Budget for Communication": 91500,
    "Per Month": 7625,
    "Metered Monthly Budget": 1143.75,
    "YEAR_1": 10596.386250000001,
    "YEAR_2": 14733.7875,
    "YEAR_3": 15425.5275,
    "YEAR_4": 16117.267500000002,
    "YEAR_5": 16809.0075,
    "YEAR_6": 17500.7475,
    "YEAR_7": 18192.4875,
    "YEAR_8": 18884.2275,
    "YEAR_9": 19575.9675,
    "YEAR_10": 20267.7075,
    "METERED_Aegis": 168103.11375
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 126800000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 6340000,
    "Annual Police Budget for Communication": 63400,
    "Per Month": 5283.333333333333,
    "Metered Monthly Budget": 792.4999999999999,
    "YEAR_1": 7342.1955,
    "YEAR_2": 10208.984999999999,
    "YEAR_3": 10688.288999999997,
    "YEAR_4": 11167.592999999997,
    "YEAR_5": 11646.896999999999,
    "YEAR_6": 12126.201,
    "YEAR_7": 12605.505,
    "YEAR_8": 13084.808999999997,
    "YEAR_9": 13564.112999999998,
    "YEAR_10": 14043.417,
    "METERED_Aegis": 116478.00449999998
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 222200000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 11110000,
    "Annual Police Budget for Communication": 111100,
    "Per Month": 9258.333333333334,
    "Metered Monthly Budget": 1388.75,
    "YEAR_1": 12866.213249999997,
    "YEAR_2": 17889.8775,
    "YEAR_3": 18729.7935,
    "YEAR_4": 19569.709499999997,
    "YEAR_5": 20409.625500000002,
    "YEAR_6": 21249.5415,
    "YEAR_7": 22089.457499999997,
    "YEAR_8": 22929.3735,
    "YEAR_9": 23769.289500000003,
    "YEAR_10": 24609.205500000004,
    "METERED_Aegis": 204112.08675
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 320000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 16000000,
    "Annual Police Budget for Communication": 160000,
    "Per Month": 13333.333333333334,
    "Metered Monthly Budget": 2000,
    "YEAR_1": 18529.200000000004,
    "YEAR_2": 25764,
    "YEAR_3": 26973.6,
    "YEAR_4": 28183.2,
    "YEAR_5": 29392.8,
    "YEAR_6": 30602.4,
    "YEAR_7": 31812,
    "YEAR_8": 33021.6,
    "YEAR_9": 34231.200000000004,
    "YEAR_10": 35440.8,
    "METERED_Aegis": 293950.80000000005
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 90000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4500000,
    "Annual Police Budget for Communication": 45000,
    "Per Month": 3750,
    "Metered Monthly Budget": 562.5,
    "YEAR_1": 5211.3375,
    "YEAR_2": 7246.125,
    "YEAR_3": 7586.325,
    "YEAR_4": 7926.525000000001,
    "YEAR_5": 8266.725,
    "YEAR_6": 8606.925,
    "YEAR_7": 8947.125,
    "YEAR_8": 9287.325,
    "YEAR_9": 9627.525,
    "YEAR_10": 9967.725,
    "METERED_Aegis": 82673.66249999999
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 438000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 21900000,
    "Annual Police Budget for Communication": 219000,
    "Per Month": 18250,
    "Metered Monthly Budget": 2737.5,
    "YEAR_1": 25361.842500000002,
    "YEAR_2": 35264.475,
    "YEAR_3": 36920.115,
    "YEAR_4": 38575.755,
    "YEAR_5": 40231.395000000004,
    "YEAR_6": 41887.034999999996,
    "YEAR_7": 43542.674999999996,
    "YEAR_8": 45198.314999999995,
    "YEAR_9": 46853.955,
    "YEAR_10": 48509.594999999994,
    "METERED_Aegis": 402345.1575
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 21100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1055000,
    "Annual Police Budget for Communication": 10550,
    "Per Month": 879.1666666666666,
    "Metered Monthly Budget": 131.875,
    "YEAR_1": 1221.769125,
    "YEAR_2": 1698.8137499999998,
    "YEAR_3": 1778.57175,
    "YEAR_4": 1858.32975,
    "YEAR_5": 1938.0877500000001,
    "YEAR_6": 2017.8457500000002,
    "YEAR_7": 2097.60375,
    "YEAR_8": 2177.36175,
    "YEAR_9": 2257.1197500000003,
    "YEAR_10": 2336.87775,
    "METERED_Aegis": 19382.380875000003
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 63937242,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3196862.1,
    "Annual Police Budget for Communication": 31968.621000000003,
    "Per Month": 2664.05175,
    "Metered Monthly Budget": 399.6077625,
    "YEAR_1": 3702.2060764575003,
    "YEAR_2": 5147.747196525,
    "YEAR_3": 5389.429971285,
    "YEAR_4": 5631.112746045001,
    "YEAR_5": 5872.795520804999,
    "YEAR_6": 6114.478295565,
    "YEAR_7": 6356.161070325,
    "YEAR_8": 6597.843845084999,
    "YEAR_9": 6839.526619845,
    "YEAR_10": 7081.209394605,
    "METERED_Aegis": 58732.5107365425
  },
  {
    "DMA": "Macon",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 54184445,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2709222.25,
    "Annual Police Budget for Communication": 27092.2225,
    "Per Month": 2257.6852083333333,
    "Metered Monthly Budget": 338.65278125,
    "YEAR_1": 3137.4825571687497,
    "YEAR_2": 4362.5251280625,
    "YEAR_3": 4567.342330162499,
    "YEAR_4": 4772.159532262501,
    "YEAR_5": 4976.9767343625,
    "YEAR_6": 5181.7939364625,
    "YEAR_7": 5386.6111385625,
    "YEAR_8": 5591.4283406625,
    "YEAR_9": 5796.245542762499,
    "YEAR_10": 6001.062744862499,
    "METERED_Aegis": 49773.627985331244
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 137000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 6850000,
    "Annual Police Budget for Communication": 68500,
    "Per Month": 5708.333333333333,
    "Metered Monthly Budget": 856.2499999999999,
    "YEAR_1": 7932.813749999999,
    "YEAR_2": 11030.212499999998,
    "YEAR_3": 11548.072499999998,
    "YEAR_4": 12065.932499999999,
    "YEAR_5": 12583.792499999998,
    "YEAR_6": 13101.6525,
    "YEAR_7": 13619.512499999999,
    "YEAR_8": 14137.3725,
    "YEAR_9": 14655.232499999996,
    "YEAR_10": 15173.0925,
    "METERED_Aegis": 125847.68624999998
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 400000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 20000000,
    "Annual Police Budget for Communication": 200000,
    "Per Month": 16666.666666666668,
    "Metered Monthly Budget": 2500,
    "YEAR_1": 23161.5,
    "YEAR_2": 32205,
    "YEAR_3": 33717,
    "YEAR_4": 35229,
    "YEAR_5": 36741,
    "YEAR_6": 38253,
    "YEAR_7": 39765,
    "YEAR_8": 41277,
    "YEAR_9": 42789,
    "YEAR_10": 44301,
    "METERED_Aegis": 367438.5
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 168632368,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 8431618.4,
    "Annual Police Budget for Communication": 84316.18400000001,
    "Per Month": 7026.348666666668,
    "Metered Monthly Budget": 1053.9523000000002,
    "YEAR_1": 9764.446478580003,
    "YEAR_2": 13577.013528600004,
    "YEAR_3": 14214.44387964,
    "YEAR_4": 14851.874230680001,
    "YEAR_5": 15489.304581720002,
    "YEAR_6": 16126.734932760002,
    "YEAR_7": 16764.1652838,
    "YEAR_8": 17401.595634840003,
    "YEAR_9": 18039.025985880002,
    "YEAR_10": 18676.45633692,
    "METERED_Aegis": 154905.06087342004
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 288900000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 14445000,
    "Annual Police Budget for Communication": 144450,
    "Per Month": 12037.5,
    "Metered Monthly Budget": 1805.625,
    "YEAR_1": 16728.393375,
    "YEAR_2": 23260.06125,
    "YEAR_3": 24352.103249999996,
    "YEAR_4": 25444.145250000005,
    "YEAR_5": 26536.18725,
    "YEAR_6": 27628.229249999997,
    "YEAR_7": 28720.271249999998,
    "YEAR_8": 29812.31325,
    "YEAR_9": 30904.35525,
    "YEAR_10": 31996.39725,
    "METERED_Aegis": 265382.456625
  },
  {
    "DMA": "Lafayette, LA",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 46471778,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2323588.9,
    "Annual Police Budget for Communication": 23235.889,
    "Per Month": 1936.3240833333332,
    "Metered Monthly Budget": 290.44861249999997,
    "YEAR_1": 2690.8902153675,
    "YEAR_2": 3741.5590262249993,
    "YEAR_3": 3917.2223470649997,
    "YEAR_4": 4092.885667905,
    "YEAR_5": 4268.548988745,
    "YEAR_6": 4444.212309585,
    "YEAR_7": 4619.875630424999,
    "YEAR_8": 4795.538951265,
    "YEAR_9": 4971.202272105,
    "YEAR_10": 5146.865592945,
    "METERED_Aegis": 42688.8010016325
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 417000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 20850000,
    "Annual Police Budget for Communication": 208500,
    "Per Month": 17375,
    "Metered Monthly Budget": 2606.25,
    "YEAR_1": 24145.86375,
    "YEAR_2": 33573.7125,
    "YEAR_3": 35149.9725,
    "YEAR_4": 36726.2325,
    "YEAR_5": 38302.4925,
    "YEAR_6": 39878.7525,
    "YEAR_7": 41455.0125,
    "YEAR_8": 43031.27250000001,
    "YEAR_9": 44607.532499999994,
    "YEAR_10": 46183.7925,
    "METERED_Aegis": 383054.63625
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 77000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3850000,
    "Annual Police Budget for Communication": 38500,
    "Per Month": 3208.3333333333335,
    "Metered Monthly Budget": 481.25,
    "YEAR_1": 4458.58875,
    "YEAR_2": 6199.4625,
    "YEAR_3": 6490.522499999999,
    "YEAR_4": 6781.5824999999995,
    "YEAR_5": 7072.6425,
    "YEAR_6": 7363.702499999999,
    "YEAR_7": 7654.7625,
    "YEAR_8": 7945.822499999999,
    "YEAR_9": 8236.8825,
    "YEAR_10": 8527.9425,
    "METERED_Aegis": 70731.91125
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 580000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 29000000,
    "Annual Police Budget for Communication": 290000,
    "Per Month": 24166.666666666668,
    "Metered Monthly Budget": 3625,
    "YEAR_1": 33584.175,
    "YEAR_2": 46697.25,
    "YEAR_3": 48889.65,
    "YEAR_4": 51082.049999999996,
    "YEAR_5": 53274.450000000004,
    "YEAR_6": 55466.85,
    "YEAR_7": 57659.25,
    "YEAR_8": 59851.65,
    "YEAR_9": 62044.049999999996,
    "YEAR_10": 64236.450000000004,
    "METERED_Aegis": 532785.825
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 38717704,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1935885.2000000002,
    "Annual Police Budget for Communication": 19358.852000000003,
    "Per Month": 1613.2376666666669,
    "Metered Monthly Budget": 241.98565000000002,
    "YEAR_1": 2241.9002529900004,
    "YEAR_2": 3117.2591433000002,
    "YEAR_3": 3263.61206442,
    "YEAR_4": 3409.9649855400003,
    "YEAR_5": 3556.3179066600005,
    "YEAR_6": 3702.6708277800003,
    "YEAR_7": 3849.0237489,
    "YEAR_8": 3995.376670020001,
    "YEAR_9": 4141.729591140001,
    "YEAR_10": 4288.082512260001,
    "METERED_Aegis": 35565.93770301
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 83558819,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4177940.95,
    "Annual Police Budget for Communication": 41779.4095,
    "Per Month": 3481.6174583333336,
    "Metered Monthly Budget": 522.24261875,
    "YEAR_1": 4838.36896567125,
    "YEAR_2": 6727.529414737501,
    "YEAR_3": 7043.381750557501,
    "YEAR_4": 7359.2340863775,
    "YEAR_5": 7675.086422197501,
    "YEAR_6": 7990.9387580175,
    "YEAR_7": 8306.791093837499,
    "YEAR_8": 8622.6434296575,
    "YEAR_9": 8938.4957654775,
    "YEAR_10": 9254.348101297499,
    "METERED_Aegis": 76756.81778782875
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 51524314,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2576215.7,
    "Annual Police Budget for Communication": 25762.157000000003,
    "Per Month": 2146.8464166666668,
    "Metered Monthly Budget": 322.0269625,
    "YEAR_1": 2983.4509967775,
    "YEAR_2": 4148.351330925001,
    "YEAR_3": 4343.113237845,
    "YEAR_4": 4537.875144765,
    "YEAR_5": 4732.637051685,
    "YEAR_6": 4927.398958605001,
    "YEAR_7": 5122.160865525,
    "YEAR_8": 5316.922772445,
    "YEAR_9": 5511.684679365,
    "YEAR_10": 5706.446586285001,
    "METERED_Aegis": 47330.041624222504
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 310000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 15500000,
    "Annual Police Budget for Communication": 155000,
    "Per Month": 12916.666666666666,
    "Metered Monthly Budget": 1937.4999999999998,
    "YEAR_1": 17950.1625,
    "YEAR_2": 24958.875,
    "YEAR_3": 26130.674999999992,
    "YEAR_4": 27302.475,
    "YEAR_5": 28474.274999999998,
    "YEAR_6": 29646.074999999997,
    "YEAR_7": 30817.875,
    "YEAR_8": 31989.674999999992,
    "YEAR_9": 33161.475,
    "YEAR_10": 34333.275,
    "METERED_Aegis": 284764.83749999997
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 59500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2975000,
    "Annual Police Budget for Communication": 29750,
    "Per Month": 2479.1666666666665,
    "Metered Monthly Budget": 371.87499999999994,
    "YEAR_1": 3445.2731249999997,
    "YEAR_2": 4790.493750000001,
    "YEAR_3": 5015.4037499999995,
    "YEAR_4": 5240.313749999999,
    "YEAR_5": 5465.223749999999,
    "YEAR_6": 5690.133749999999,
    "YEAR_7": 5915.043749999999,
    "YEAR_8": 6139.95375,
    "YEAR_9": 6364.8637499999995,
    "YEAR_10": 6589.7737499999985,
    "METERED_Aegis": 54656.47687499999
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 119500000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 5975000,
    "Annual Police Budget for Communication": 59750,
    "Per Month": 4979.166666666667,
    "Metered Monthly Budget": 746.875,
    "YEAR_1": 6919.498125,
    "YEAR_2": 9621.24375,
    "YEAR_3": 10072.953749999999,
    "YEAR_4": 10524.66375,
    "YEAR_5": 10976.373749999999,
    "YEAR_6": 11428.083749999998,
    "YEAR_7": 11879.793749999997,
    "YEAR_8": 12331.50375,
    "YEAR_9": 12783.213750000003,
    "YEAR_10": 13234.923750000002,
    "METERED_Aegis": 109772.251875
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 19680811,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 984040.55,
    "Annual Police Budget for Communication": 9840.4055,
    "Per Month": 820.0337916666667,
    "Metered Monthly Budget": 123.00506875,
    "YEAR_1": 1139.59275994125,
    "YEAR_2": 1584.5512956375,
    "YEAR_3": 1658.9447612175004,
    "YEAR_4": 1733.3382267975005,
    "YEAR_5": 1807.7316923775004,
    "YEAR_6": 1882.1251579575,
    "YEAR_7": 1956.5186235374997,
    "YEAR_8": 2030.9120891174996,
    "YEAR_9": 2105.3055546975,
    "YEAR_10": 2179.6990202775,
    "METERED_Aegis": 18078.719181558754
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 480000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 24000000,
    "Annual Police Budget for Communication": 240000,
    "Per Month": 20000,
    "Metered Monthly Budget": 3000,
    "YEAR_1": 27793.8,
    "YEAR_2": 38646,
    "YEAR_3": 40460.4,
    "YEAR_4": 42274.799999999996,
    "YEAR_5": 44089.200000000004,
    "YEAR_6": 45903.6,
    "YEAR_7": 47718,
    "YEAR_8": 49532.4,
    "YEAR_9": 51346.799999999996,
    "YEAR_10": 53161.200000000004,
    "METERED_Aegis": 440926.20000000007
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 100000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 5000000,
    "Annual Police Budget for Communication": 50000,
    "Per Month": 4166.666666666667,
    "Metered Monthly Budget": 625,
    "YEAR_1": 5790.375,
    "YEAR_2": 8051.25,
    "YEAR_3": 8429.25,
    "YEAR_4": 8807.25,
    "YEAR_5": 9185.25,
    "YEAR_6": 9563.25,
    "YEAR_7": 9941.25,
    "YEAR_8": 10319.25,
    "YEAR_9": 10697.25,
    "YEAR_10": 11075.25,
    "METERED_Aegis": 91859.625
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 76632780,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 3831639,
    "Annual Police Budget for Communication": 38316.39,
    "Per Month": 3193.0325,
    "Metered Monthly Budget": 478.95487499999996,
    "YEAR_1": 4437.325334925,
    "YEAR_2": 6169.896699749999,
    "YEAR_3": 6459.56860815,
    "YEAR_4": 6749.2405165499995,
    "YEAR_5": 7038.91242495,
    "YEAR_6": 7328.58433335,
    "YEAR_7": 7618.256241749999,
    "YEAR_8": 7907.928150149999,
    "YEAR_9": 8197.600058549999,
    "YEAR_10": 8487.27196695,
    "METERED_Aegis": 70394.584335075
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 33300000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1665000,
    "Annual Police Budget for Communication": 16650,
    "Per Month": 1387.5,
    "Metered Monthly Budget": 208.125,
    "YEAR_1": 1928.1948750000001,
    "YEAR_2": 2681.06625,
    "YEAR_3": 2806.94025,
    "YEAR_4": 2932.81425,
    "YEAR_5": 3058.68825,
    "YEAR_6": 3184.56225,
    "YEAR_7": 3310.4362499999997,
    "YEAR_8": 3436.31025,
    "YEAR_9": 3562.18425,
    "YEAR_10": 3688.0582500000005,
    "METERED_Aegis": 30589.255124999996
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 250000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 12500000,
    "Annual Police Budget for Communication": 125000,
    "Per Month": 10416.666666666666,
    "Metered Monthly Budget": 1562.4999999999998,
    "YEAR_1": 14475.937499999998,
    "YEAR_2": 20128.124999999996,
    "YEAR_3": 21073.124999999996,
    "YEAR_4": 22018.124999999996,
    "YEAR_5": 22963.124999999996,
    "YEAR_6": 23908.124999999996,
    "YEAR_7": 24853.124999999996,
    "YEAR_8": 25798.124999999996,
    "YEAR_9": 26743.124999999996,
    "YEAR_10": 27688.124999999996,
    "METERED_Aegis": 229649.06249999997
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 42959740,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2147987,
    "Annual Police Budget for Communication": 21479.87,
    "Per Month": 1789.9891666666665,
    "Metered Monthly Budget": 268.49837499999995,
    "YEAR_1": 2487.5300450249993,
    "YEAR_2": 3458.796066749999,
    "YEAR_3": 3621.1838839499987,
    "YEAR_4": 3783.571701149999,
    "YEAR_5": 3945.959518349999,
    "YEAR_6": 4108.347335549998,
    "YEAR_7": 4270.735152749999,
    "YEAR_8": 4433.122969949999,
    "YEAR_9": 4595.510787149999,
    "YEAR_10": 4757.898604349999,
    "METERED_Aegis": 39462.65606497499
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 90100000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4505000,
    "Annual Police Budget for Communication": 45050,
    "Per Month": 3754.1666666666665,
    "Metered Monthly Budget": 563.125,
    "YEAR_1": 5217.127875000001,
    "YEAR_2": 7254.1762499999995,
    "YEAR_3": 7594.75425,
    "YEAR_4": 7935.332250000001,
    "YEAR_5": 8275.91025,
    "YEAR_6": 8616.48825,
    "YEAR_7": 8957.06625,
    "YEAR_8": 9297.64425,
    "YEAR_9": 9638.22225,
    "YEAR_10": 9978.80025,
    "METERED_Aegis": 82765.522125
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 250000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 12500000,
    "Annual Police Budget for Communication": 125000,
    "Per Month": 10416.666666666666,
    "Metered Monthly Budget": 1562.4999999999998,
    "YEAR_1": 14475.937499999998,
    "YEAR_2": 20128.124999999996,
    "YEAR_3": 21073.124999999996,
    "YEAR_4": 22018.124999999996,
    "YEAR_5": 22963.124999999996,
    "YEAR_6": 23908.124999999996,
    "YEAR_7": 24853.124999999996,
    "YEAR_8": 25798.124999999996,
    "YEAR_9": 26743.124999999996,
    "YEAR_10": 27688.124999999996,
    "METERED_Aegis": 229649.06249999997
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 44126983,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 2206349.15,
    "Annual Police Budget for Communication": 22063.4915,
    "Per Month": 1838.6242916666667,
    "Metered Monthly Budget": 275.79364375,
    "YEAR_1": 2555.1177918862495,
    "YEAR_2": 3552.7737187875,
    "YEAR_3": 3719.5737145274998,
    "YEAR_4": 3886.3737102674995,
    "YEAR_5": 4053.1737060074993,
    "YEAR_6": 4219.9737017475,
    "YEAR_7": 4386.7736974875,
    "YEAR_8": 4553.5736932275,
    "YEAR_9": 4720.373688967499,
    "YEAR_10": 4887.1736847075,
    "METERED_Aegis": 40534.88110761375
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 83000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 4150000,
    "Annual Police Budget for Communication": 41500,
    "Per Month": 3458.3333333333335,
    "Metered Monthly Budget": 518.75,
    "YEAR_1": 4806.01125,
    "YEAR_2": 6682.537499999999,
    "YEAR_3": 6996.277500000002,
    "YEAR_4": 7310.017499999999,
    "YEAR_5": 7623.7575000000015,
    "YEAR_6": 7937.497499999999,
    "YEAR_7": 8251.237500000001,
    "YEAR_8": 8564.977499999999,
    "YEAR_9": 8878.7175,
    "YEAR_10": 9192.457499999999,
    "METERED_Aegis": 76243.48875
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 146309082,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 7315454.100000001,
    "Annual Police Budget for Communication": 73154.54100000001,
    "Per Month": 6096.211750000001,
    "Metered Monthly Budget": 914.4317625000002,
    "YEAR_1": 8471.844506857502,
    "YEAR_2": 11779.709964525002,
    "YEAR_3": 12332.758294485004,
    "YEAR_4": 12885.806624445004,
    "YEAR_5": 13438.854954405004,
    "YEAR_6": 13991.903284365002,
    "YEAR_7": 14544.951614325004,
    "YEAR_8": 15097.999944285002,
    "YEAR_9": 15651.048274245002,
    "YEAR_10": 16204.096604205004,
    "METERED_Aegis": 134398.97406614252
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 219846716,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 10992335.8,
    "Annual Police Budget for Communication": 109923.35800000001,
    "Per Month": 9160.279833333334,
    "Metered Monthly Budget": 1374.041975,
    "YEAR_1": 12729.949281585003,
    "YEAR_2": 17700.40872195,
    "YEAR_3": 18531.429308429997,
    "YEAR_4": 19362.44989491,
    "YEAR_5": 20193.470481389995,
    "YEAR_6": 21024.49106787,
    "YEAR_7": 21855.511654349997,
    "YEAR_8": 22686.53224083,
    "YEAR_9": 23517.55282731,
    "YEAR_10": 24348.573413790004,
    "METERED_Aegis": 201950.368892415
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 117783349,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 5889167.45,
    "Annual Police Budget for Communication": 58891.6745,
    "Per Month": 4907.639541666666,
    "Metered Monthly Budget": 736.14593125,
    "YEAR_1": 6820.0975946587505,
    "YEAR_2": 9483.0318863625,
    "YEAR_3": 9928.2529455825,
    "YEAR_4": 10373.4740048025,
    "YEAR_5": 10818.6950640225,
    "YEAR_6": 11263.9161232425,
    "YEAR_7": 11709.1371824625,
    "YEAR_8": 12154.358241682501,
    "YEAR_9": 12599.579300902496,
    "YEAR_10": 13044.8003601225,
    "METERED_Aegis": 108195.34270384122
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 24478411,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1223920.55,
    "Annual Police Budget for Communication": 12239.2055,
    "Per Month": 1019.9337916666667,
    "Metered Monthly Budget": 152.99006875,
    "YEAR_1": 1417.3917909412503,
    "YEAR_2": 1970.8180656374998,
    "YEAR_3": 2063.3464592174996,
    "YEAR_4": 2155.8748527975,
    "YEAR_5": 2248.4032463775,
    "YEAR_6": 2340.9316399575005,
    "YEAR_7": 2433.4600335375,
    "YEAR_8": 2525.9884271174997,
    "YEAR_9": 2618.5168206975004,
    "YEAR_10": 2711.0452142775,
    "METERED_Aegis": 22485.77655055875
  },
  {
    "DMA": "Erie",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 39381583,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 1969079.1500000001,
    "Annual Police Budget for Communication": 19690.791500000003,
    "Per Month": 1640.8992916666668,
    "Metered Monthly Budget": 246.13489375,
    "YEAR_1": 2280.34133663625,
    "YEAR_2": 3170.7097012875006,
    "YEAR_3": 3319.5720850275,
    "YEAR_4": 3468.4344687675,
    "YEAR_5": 3617.2968525075,
    "YEAR_6": 3766.1592362474994,
    "YEAR_7": 3915.0216199875,
    "YEAR_8": 4063.8840037275004,
    "YEAR_9": 4212.7463874674995,
    "YEAR_10": 4361.6087712075005,
    "METERED_Aegis": 36175.77446286375
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 215000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 10750000,
    "Annual Police Budget for Communication": 107500,
    "Per Month": 8958.333333333334,
    "Metered Monthly Budget": 1343.75,
    "YEAR_1": 12449.30625,
    "YEAR_2": 17310.1875,
    "YEAR_3": 18122.8875,
    "YEAR_4": 18935.587499999998,
    "YEAR_5": 19748.287500000002,
    "YEAR_6": 20560.9875,
    "YEAR_7": 21373.6875,
    "YEAR_8": 22186.3875,
    "YEAR_9": 22999.087499999998,
    "YEAR_10": 23811.7875,
    "METERED_Aegis": 197498.19375
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 187000000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 9350000,
    "Annual Police Budget for Communication": 93500,
    "Per Month": 7791.666666666667,
    "Metered Monthly Budget": 1168.75,
    "YEAR_1": 10828.00125,
    "YEAR_2": 15055.837500000001,
    "YEAR_3": 15762.6975,
    "YEAR_4": 16469.5575,
    "YEAR_5": 17176.417500000003,
    "YEAR_6": 17883.2775,
    "YEAR_7": 18590.1375,
    "YEAR_8": 19296.997499999998,
    "YEAR_9": 20003.8575,
    "YEAR_10": 20710.7175,
    "METERED_Aegis": 171777.49875
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Aegis",
    "DMA Annual City Budget": 16563000,
    "% DMA Annual Police Budget for communication": 0.05,
    "DMA Consdered": "Y",
    "% of Annual Police Budget for Communication": 0.01,
    "Monthly Adoption Growth Rate": 0.0042,
    "% for Metered": 0.15,
    "Holiday Months": 3,
    "TAM": 828150,
    "Annual Police Budget for Communication": 8281.5,
    "Per Month": 690.125,
    "Metered Monthly Budget": 103.51875,
    "YEAR_1": 959.05981125,
    "YEAR_2": 1333.5285374999999,
    "YEAR_3": 1396.1366775000001,
    "YEAR_4": 1458.7448175,
    "YEAR_5": 1521.3529574999998,
    "YEAR_6": 1583.9610975,
    "YEAR_7": 1646.5692374999999,
    "YEAR_8": 1709.1773775,
    "YEAR_9": 1771.7855175,
    "YEAR_10": 1834.3936574999998,
    "METERED_Aegis": 15214.709688750001
  },
  
  {
    "DMA": "New York",
    "PlatformName": "Around",
    "No of Households": 7726580,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 3476961,
    "No of HHs for ad": 139078.44,
    "AD Revenue Per month": 35465.0022,
    "YEAR_1": 0,
    "YEAR_2": 426857.8197897654,
    "YEAR_3": 427734.0209713187,
    "YEAR_4": 428610.2221528721,
    "YEAR_5": 429486.42333442543,
    "YEAR_6": 430362.6245159789,
    "YEAR_7": 431238.8256975323,
    "YEAR_8": 432115.02687908564,
    "YEAR_9": 432991.228060639,
    "YEAR_10": 433867.42924219236,
    "AD_Around": 3873263.62064381
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Around",
    "No of Households": 5838090,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 2627140.5,
    "No of HHs for ad": 105085.62,
    "AD Revenue Per month": 26796.833099999996,
    "YEAR_1": 0,
    "YEAR_2": 322527.479057543,
    "YEAR_3": 323189.5237598583,
    "YEAR_4": 323851.5684621735,
    "YEAR_5": 324513.61316448875,
    "YEAR_6": 325175.657866804,
    "YEAR_7": 325837.7025691193,
    "YEAR_8": 326499.7472714345,
    "YEAR_9": 327161.79197374976,
    "YEAR_10": 327823.836676065,
    "AD_Around": 2926580.9208012354
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Around",
    "No of Households": 3624820,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 1631169,
    "No of HHs for ad": 65246.76,
    "AD Revenue Per month": 16637.9238,
    "YEAR_1": 0,
    "YEAR_2": 200254.5450031368,
    "YEAR_3": 200665.6028795736,
    "YEAR_4": 201076.66075601027,
    "YEAR_5": 201487.71863244698,
    "YEAR_6": 201898.77650888372,
    "YEAR_7": 202309.83438532037,
    "YEAR_8": 202720.8922617571,
    "YEAR_9": 203131.95013819373,
    "YEAR_10": 203543.0080146305,
    "AD_Around": 1817088.988579953
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Around",
    "No of Households": 3108960,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 1399032,
    "No of HHs for ad": 55961.28,
    "AD Revenue Per month": 14270.1264,
    "YEAR_1": 0,
    "YEAR_2": 171755.66517315406,
    "YEAR_3": 172108.22405760258,
    "YEAR_4": 172460.78294205107,
    "YEAR_5": 172813.34182649964,
    "YEAR_6": 173165.90071094813,
    "YEAR_7": 173518.45959539662,
    "YEAR_8": 173871.01847984514,
    "YEAR_9": 174223.57736429363,
    "YEAR_10": 174576.1362487422,
    "AD_Around": 1558493.1063985333
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Around",
    "No of Households": 3041540,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 1368693,
    "No of HHs for ad": 54747.72,
    "AD Revenue Per month": 13960.668599999999,
    "YEAR_1": 0,
    "YEAR_2": 168031.0219014574,
    "YEAR_3": 168375.9352967425,
    "YEAR_4": 168720.8486920276,
    "YEAR_5": 169065.76208731267,
    "YEAR_6": 169410.67548259776,
    "YEAR_7": 169755.58887788287,
    "YEAR_8": 170100.50227316795,
    "YEAR_9": 170445.41566845303,
    "YEAR_10": 170790.3290637381,
    "AD_Around": 1524696.07934338
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Around",
    "No of Households": 2679850,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 1205932.5,
    "No of HHs for ad": 48237.3,
    "AD Revenue Per month": 12300.511500000002,
    "YEAR_1": 0,
    "YEAR_2": 148049.3217391916,
    "YEAR_3": 148353.21916035152,
    "YEAR_4": 148657.11658151145,
    "YEAR_5": 148961.01400267135,
    "YEAR_6": 149264.91142383125,
    "YEAR_7": 149568.80884499117,
    "YEAR_8": 149872.7062661511,
    "YEAR_9": 150176.603687311,
    "YEAR_10": 150480.50110847093,
    "AD_Around": 1343384.2028144812
  },
  {
    "DMA": "Houston",
    "PlatformName": "Around",
    "No of Households": 2666330,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 1199848.5,
    "No of HHs for ad": 47993.94,
    "AD Revenue Per month": 12238.454699999998,
    "YEAR_1": 0,
    "YEAR_2": 147302.40425130457,
    "YEAR_3": 147604.76849219913,
    "YEAR_4": 147907.13273309372,
    "YEAR_5": 148209.49697398834,
    "YEAR_6": 148511.86121488287,
    "YEAR_7": 148814.22545577746,
    "YEAR_8": 149116.58969667205,
    "YEAR_9": 149418.9539375666,
    "YEAR_10": 149721.31817846117,
    "AD_Around": 1336606.7509339459
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Around",
    "No of Households": 2617350,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 1177807.5,
    "No of HHs for ad": 47112.3,
    "AD Revenue Per month": 12013.636500000002,
    "YEAR_1": 0,
    "YEAR_2": 144596.48571900412,
    "YEAR_3": 144893.29558346397,
    "YEAR_4": 145190.10544792388,
    "YEAR_5": 145486.91531238385,
    "YEAR_6": 145783.72517684376,
    "YEAR_7": 146080.5350413037,
    "YEAR_8": 146377.34490576357,
    "YEAR_9": 146674.15477022354,
    "YEAR_10": 146970.96463468342,
    "AD_Around": 1312053.5265915939
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Around",
    "No of Households": 2596190,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 1168285.5,
    "No of HHs for ad": 46731.42,
    "AD Revenue Per month": 11916.512099999998,
    "YEAR_1": 0,
    "YEAR_2": 143427.49355600934,
    "YEAR_3": 143721.9038572729,
    "YEAR_4": 144016.31415853646,
    "YEAR_5": 144310.72445980005,
    "YEAR_6": 144605.13476106361,
    "YEAR_7": 144899.54506232718,
    "YEAR_8": 145193.95536359074,
    "YEAR_9": 145488.36566485433,
    "YEAR_10": 145782.7759661179,
    "AD_Around": 1301446.2128495725
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Around",
    "No of Households": 2593210,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 1166944.5,
    "No of HHs for ad": 46677.78,
    "AD Revenue Per month": 11902.8339,
    "YEAR_1": 0,
    "YEAR_2": 143262.86233456683,
    "YEAR_3": 143556.93470112694,
    "YEAR_4": 143851.00706768705,
    "YEAR_5": 144145.07943424716,
    "YEAR_6": 144439.15180080727,
    "YEAR_7": 144733.22416736739,
    "YEAR_8": 145027.2965339275,
    "YEAR_9": 145321.3689004876,
    "YEAR_10": 145615.44126704772,
    "AD_Around": 1299952.3662072658
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Around",
    "No of Households": 2138870,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 962491.5,
    "No of HHs for ad": 38499.66,
    "AD Revenue Per month": 9817.4133,
    "YEAR_1": 0,
    "YEAR_2": 118162.67805597502,
    "YEAR_3": 118405.22785435787,
    "YEAR_4": 118647.77765274074,
    "YEAR_5": 118890.32745112362,
    "YEAR_6": 119132.87724950648,
    "YEAR_7": 119375.42704788933,
    "YEAR_8": 119617.97684627221,
    "YEAR_9": 119860.52664465505,
    "YEAR_10": 120103.07644303792,
    "AD_Around": 1072195.8952455583
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Around",
    "No of Households": 2116440,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 952398,
    "No of HHs for ad": 38095.92,
    "AD Revenue Per month": 9714.4596,
    "YEAR_1": 0,
    "YEAR_2": 116923.52426505013,
    "YEAR_3": 117163.5304810845,
    "YEAR_4": 117403.53669711886,
    "YEAR_5": 117643.54291315321,
    "YEAR_6": 117883.5491291876,
    "YEAR_7": 118123.55534522198,
    "YEAR_8": 118363.56156125633,
    "YEAR_9": 118603.56777729071,
    "YEAR_10": 118843.57399332506,
    "AD_Around": 1060951.9421626884
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Around",
    "No of Households": 2068720,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 930924,
    "No of HHs for ad": 37236.96,
    "AD Revenue Per month": 9495.424799999999,
    "YEAR_1": 0,
    "YEAR_2": 114287.21490691655,
    "YEAR_3": 114521.80963165933,
    "YEAR_4": 114756.40435640211,
    "YEAR_5": 114990.9990811449,
    "YEAR_6": 115225.59380588768,
    "YEAR_7": 115460.18853063045,
    "YEAR_8": 115694.78325537324,
    "YEAR_9": 115929.37798011603,
    "YEAR_10": 116163.97270485881,
    "AD_Around": 1037030.3442529892
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Around",
    "No of Households": 1937250,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 871762.5,
    "No of HHs for ad": 34870.5,
    "AD Revenue Per month": 8891.9775,
    "YEAR_1": 0,
    "YEAR_2": 107024.10528173178,
    "YEAR_3": 107243.79118920495,
    "YEAR_4": 107463.47709667815,
    "YEAR_5": 107683.16300415136,
    "YEAR_6": 107902.84891162456,
    "YEAR_7": 108122.53481909775,
    "YEAR_8": 108342.22072657096,
    "YEAR_9": 108561.90663404416,
    "YEAR_10": 108781.59254151737,
    "AD_Around": 971125.6402046209
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Around",
    "No of Households": 1839480,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 827766,
    "No of HHs for ad": 33110.64,
    "AD Revenue Per month": 8443.2132,
    "YEAR_1": 0,
    "YEAR_2": 101622.76483863204,
    "YEAR_3": 101831.3635394083,
    "YEAR_4": 102039.96224018457,
    "YEAR_5": 102248.56094096082,
    "YEAR_6": 102457.15964173706,
    "YEAR_7": 102665.75834251332,
    "YEAR_8": 102874.35704328958,
    "YEAR_9": 103082.95574406584,
    "YEAR_10": 103291.55444484207,
    "AD_Around": 922114.4367756336
  },
  {
    "DMA": "Denver",
    "PlatformName": "Around",
    "No of Households": 1792540,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 806643,
    "No of HHs for ad": 32265.72,
    "AD Revenue Per month": 8227.7586,
    "YEAR_1": 0,
    "YEAR_2": 99029.5468740304,
    "YEAR_3": 99232.8225362227,
    "YEAR_4": 99436.09819841498,
    "YEAR_5": 99639.37386060727,
    "YEAR_6": 99842.64952279956,
    "YEAR_7": 100045.92518499185,
    "YEAR_8": 100249.20084718414,
    "YEAR_9": 100452.47650937643,
    "YEAR_10": 100655.7521715687,
    "AD_Around": 898583.845705196
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Around",
    "No of Households": 1775140,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 798813,
    "No of HHs for ad": 31952.52,
    "AD Revenue Per month": 8147.892599999999,
    "YEAR_1": 0,
    "YEAR_2": 98068.2773260102,
    "YEAR_3": 98269.57981241721,
    "YEAR_4": 98470.88229882423,
    "YEAR_5": 98672.18478523125,
    "YEAR_6": 98873.48727163824,
    "YEAR_7": 99074.78975804526,
    "YEAR_8": 99276.09224445226,
    "YEAR_9": 99477.39473085929,
    "YEAR_10": 99678.69721726628,
    "AD_Around": 889861.3854447443
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Around",
    "No of Households": 1720970,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 774436.5,
    "No of HHs for ad": 30977.46,
    "AD Revenue Per month": 7899.252300000001,
    "YEAR_1": 0,
    "YEAR_2": 95075.63529059332,
    "YEAR_3": 95270.79484985731,
    "YEAR_4": 95465.95440912129,
    "YEAR_5": 95661.11396838527,
    "YEAR_6": 95856.27352764926,
    "YEAR_7": 96051.43308691325,
    "YEAR_8": 96246.59264617723,
    "YEAR_9": 96441.75220544121,
    "YEAR_10": 96636.9117647052,
    "AD_Around": 862706.4617488433
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Around",
    "No of Households": 1552420,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 698589,
    "No of HHs for ad": 27943.56,
    "AD Revenue Per month": 7125.607800000001,
    "YEAR_1": 0,
    "YEAR_2": 85764.02711135166,
    "YEAR_3": 85940.0729477071,
    "YEAR_4": 86116.11878406252,
    "YEAR_5": 86292.16462041794,
    "YEAR_6": 86468.21045677336,
    "YEAR_7": 86644.25629312878,
    "YEAR_8": 86820.30212948422,
    "YEAR_9": 86996.34796583962,
    "YEAR_10": 87172.39380219506,
    "AD_Around": 778213.8941109603
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Around",
    "No of Households": 1502080,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 675936,
    "No of HHs for ad": 27037.440000000002,
    "AD Revenue Per month": 6894.547199999999,
    "YEAR_1": 0,
    "YEAR_2": 82982.97486725183,
    "YEAR_3": 83153.3121019388,
    "YEAR_4": 83323.6493366258,
    "YEAR_5": 83493.98657131275,
    "YEAR_6": 83664.32380599972,
    "YEAR_7": 83834.66104068671,
    "YEAR_8": 84004.99827537368,
    "YEAR_9": 84175.33551006067,
    "YEAR_10": 84345.67274474764,
    "AD_Around": 752978.9142539975
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Around",
    "No of Households": 1323400,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 595530,
    "No of HHs for ad": 23821.2,
    "AD Revenue Per month": 6074.406,
    "YEAR_1": 0,
    "YEAR_2": 73111.7310258582,
    "YEAR_3": 73261.80578644668,
    "YEAR_4": 73411.88054703517,
    "YEAR_5": 73561.95530762363,
    "YEAR_6": 73712.03006821212,
    "YEAR_7": 73862.1048288006,
    "YEAR_8": 74012.17958938908,
    "YEAR_9": 74162.25434997756,
    "YEAR_10": 74312.32911056605,
    "AD_Around": 663408.2706139091
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Around",
    "No of Households": 1293400,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 582030,
    "No of HHs for ad": 23281.2,
    "AD Revenue Per month": 5936.706,
    "YEAR_1": 0,
    "YEAR_2": 71454.3697361682,
    "YEAR_3": 71601.04246954068,
    "YEAR_4": 71747.71520291317,
    "YEAR_5": 71894.38793628564,
    "YEAR_6": 72041.06066965811,
    "YEAR_7": 72187.7334030306,
    "YEAR_8": 72334.40613640308,
    "YEAR_9": 72481.07886977556,
    "YEAR_10": 72627.75160314803,
    "AD_Around": 648369.5460269231
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Around",
    "No of Households": 1289510,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 580279.5,
    "No of HHs for ad": 23211.18,
    "AD Revenue Per month": 5918.8509,
    "YEAR_1": 0,
    "YEAR_2": 71239.46522227174,
    "YEAR_3": 71385.6968261152,
    "YEAR_4": 71531.92842995869,
    "YEAR_5": 71678.16003380215,
    "YEAR_6": 71824.39163764562,
    "YEAR_7": 71970.6232414891,
    "YEAR_8": 72116.85484533256,
    "YEAR_9": 72263.08644917604,
    "YEAR_10": 72409.31805301951,
    "AD_Around": 646419.5247388106
  },
  {
    "DMA": "Saint Louis",
    "PlatformName": "Around",
    "No of Households": 1255160,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 564822,
    "No of HHs for ad": 22592.88,
    "AD Revenue Per month": 5761.1844,
    "YEAR_1": 0,
    "YEAR_2": 69341.78654557667,
    "YEAR_3": 69484.12282825784,
    "YEAR_4": 69626.459110939,
    "YEAR_5": 69768.79539362012,
    "YEAR_6": 69911.13167630129,
    "YEAR_7": 70053.46795898245,
    "YEAR_8": 70195.8042416636,
    "YEAR_9": 70338.14052434474,
    "YEAR_10": 70480.4768070259,
    "AD_Around": 629200.1850867115
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Around",
    "No of Households": 1207280,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 543276,
    "No of HHs for ad": 21731.04,
    "AD Revenue Per month": 5541.415200000001,
    "YEAR_1": 0,
    "YEAR_2": 66696.63792723145,
    "YEAR_3": 66833.54457447586,
    "YEAR_4": 66970.4512217203,
    "YEAR_5": 67107.3578689647,
    "YEAR_6": 67244.26451620912,
    "YEAR_7": 67381.17116345353,
    "YEAR_8": 67518.07781069794,
    "YEAR_9": 67654.98445794238,
    "YEAR_10": 67791.89110518678,
    "AD_Around": 605198.3806458821
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Around",
    "No of Households": 1174940,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 528723,
    "No of HHs for ad": 21148.920000000002,
    "AD Revenue Per month": 5392.9746000000005,
    "YEAR_1": 0,
    "YEAR_2": 64910.00245694563,
    "YEAR_3": 65043.2417188512,
    "YEAR_4": 65176.480980756765,
    "YEAR_5": 65309.72024266234,
    "YEAR_6": 65442.9595045679,
    "YEAR_7": 65576.19876647346,
    "YEAR_8": 65709.43802837904,
    "YEAR_9": 65842.6772902846,
    "YEAR_10": 65975.91655219017,
    "AD_Around": 588986.6355411111
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Around",
    "No of Households": 1168540,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 525843,
    "No of HHs for ad": 21033.72,
    "AD Revenue Per month": 5363.598599999999,
    "YEAR_1": 0,
    "YEAR_2": 64556.43204847842,
    "YEAR_3": 64688.945544577895,
    "YEAR_4": 64821.459040677386,
    "YEAR_5": 64953.97253677688,
    "YEAR_6": 65086.48603287637,
    "YEAR_7": 65218.99952897586,
    "YEAR_8": 65351.51302507534,
    "YEAR_9": 65484.02652117484,
    "YEAR_10": 65616.54001727431,
    "AD_Around": 585778.3742958873
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Around",
    "No of Households": 1149480,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 517266,
    "No of HHs for ad": 20690.64,
    "AD Revenue Per month": 5276.113199999999,
    "YEAR_1": 0,
    "YEAR_2": 63503.45517576202,
    "YEAR_3": 63633.80725057027,
    "YEAR_4": 63764.15932537854,
    "YEAR_5": 63894.511400186784,
    "YEAR_6": 64024.86347499504,
    "YEAR_7": 64155.21554980331,
    "YEAR_8": 64285.56762461157,
    "YEAR_9": 64415.91969941982,
    "YEAR_10": 64546.27177422807,
    "AD_Around": 576223.7712749554
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Around",
    "No of Households": 1148120,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 516654,
    "No of HHs for ad": 20666.16,
    "AD Revenue Per month": 5269.870800000001,
    "YEAR_1": 0,
    "YEAR_2": 63428.32146396278,
    "YEAR_3": 63558.51931353724,
    "YEAR_4": 63688.717163111694,
    "YEAR_5": 63818.91501268615,
    "YEAR_6": 63949.11286226063,
    "YEAR_7": 64079.31071183509,
    "YEAR_8": 64209.508561409544,
    "YEAR_9": 64339.706410984014,
    "YEAR_10": 64469.90426055848,
    "AD_Around": 575542.0157603455
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Around",
    "No of Households": 1107010,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 498154.5,
    "No of HHs for ad": 19926.18,
    "AD Revenue Per month": 5081.1759,
    "YEAR_1": 0,
    "YEAR_2": 61157.18404332423,
    "YEAR_3": 61282.71998160371,
    "YEAR_4": 61408.25591988317,
    "YEAR_5": 61533.79185816264,
    "YEAR_6": 61659.32779644212,
    "YEAR_7": 61784.86373472159,
    "YEAR_8": 61910.39967300107,
    "YEAR_9": 62035.93561128054,
    "YEAR_10": 62161.47154956,
    "AD_Around": 554933.9501679791
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Around",
    "No of Households": 1059540,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 476793,
    "No of HHs for ad": 19071.72,
    "AD Revenue Per month": 4863.288600000001,
    "YEAR_1": 0,
    "YEAR_2": 58534.686029271434,
    "YEAR_3": 58654.83882648612,
    "YEAR_4": 58774.9916237008,
    "YEAR_5": 58895.144420915494,
    "YEAR_6": 59015.29721813018,
    "YEAR_7": 59135.45001534486,
    "YEAR_8": 59255.60281255956,
    "YEAR_9": 59375.755609774256,
    "YEAR_10": 59495.908406988936,
    "AD_Around": 531137.6749631717
  },
  {
    "DMA": "Columbus, OH",
    "PlatformName": "Around",
    "No of Households": 1023600,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 460620,
    "No of HHs for ad": 18424.8,
    "AD Revenue Per month": 4698.324,
    "YEAR_1": 0,
    "YEAR_2": 56549.167204222795,
    "YEAR_3": 56665.24437283271,
    "YEAR_4": 56781.32154144263,
    "YEAR_5": 56897.39871005255,
    "YEAR_6": 57013.47587866248,
    "YEAR_7": 57129.55304727239,
    "YEAR_8": 57245.63021588232,
    "YEAR_9": 57361.707384492234,
    "YEAR_10": 57477.78455310216,
    "AD_Around": 513121.2829079622
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Around",
    "No of Households": 1019550,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 458797.5,
    "No of HHs for ad": 18351.9,
    "AD Revenue Per month": 4679.7345000000005,
    "YEAR_1": 0,
    "YEAR_2": 56325.42343011466,
    "YEAR_3": 56441.04132505042,
    "YEAR_4": 56556.65921998618,
    "YEAR_5": 56672.27711492193,
    "YEAR_6": 56787.895009857704,
    "YEAR_7": 56903.51290479345,
    "YEAR_8": 57019.13079972922,
    "YEAR_9": 57134.74869466499,
    "YEAR_10": 57250.36658960074,
    "AD_Around": 511091.05508871924
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Around",
    "No of Households": 1014160,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 456372,
    "No of HHs for ad": 18254.88,
    "AD Revenue Per month": 4654.9944000000005,
    "YEAR_1": 0,
    "YEAR_2": 56027.65085173369,
    "YEAR_3": 56142.65751577964,
    "YEAR_4": 56257.664179825595,
    "YEAR_5": 56372.67084387154,
    "YEAR_6": 56487.6775079175,
    "YEAR_7": 56602.68417196345,
    "YEAR_8": 56717.690836009395,
    "YEAR_9": 56832.69750005534,
    "YEAR_10": 56947.704164101306,
    "AD_Around": 508389.0975712575
  },
  {
    "DMA": "Austin",
    "PlatformName": "Around",
    "No of Households": 978520,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 440334,
    "No of HHs for ad": 17613.36,
    "AD Revenue Per month": 4491.4068,
    "YEAR_1": 0,
    "YEAR_2": 54058.70563958196,
    "YEAR_3": 54169.6706952953,
    "YEAR_4": 54280.635751008645,
    "YEAR_5": 54391.600806721995,
    "YEAR_6": 54502.56586243534,
    "YEAR_7": 54613.53091814868,
    "YEAR_8": 54724.495973862024,
    "YEAR_9": 54835.46102957537,
    "YEAR_10": 54946.42608528871,
    "AD_Around": 490523.09276191803
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Around",
    "No of Households": 953940,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 429273,
    "No of HHs for ad": 17170.920000000002,
    "AD Revenue Per month": 4378.5846,
    "YEAR_1": 0,
    "YEAR_2": 52700.774289562614,
    "YEAR_3": 52808.95195097699,
    "YEAR_4": 52917.129612391356,
    "YEAR_5": 53025.30727380573,
    "YEAR_6": 53133.4849352201,
    "YEAR_7": 53241.662596634465,
    "YEAR_8": 53349.840258048825,
    "YEAR_9": 53458.01791946319,
    "YEAR_10": 53566.19558087757,
    "AD_Around": 478201.3644169809
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Around",
    "No of Households": 947350,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 426307.5,
    "No of HHs for ad": 17052.3,
    "AD Revenue Per month": 4348.3365,
    "YEAR_1": 0,
    "YEAR_2": 52336.70725959405,
    "YEAR_3": 52444.137609029975,
    "YEAR_4": 52551.567958465894,
    "YEAR_5": 52658.99830790181,
    "YEAR_6": 52766.42865733773,
    "YEAR_7": 52873.85900677366,
    "YEAR_8": 52981.28935620956,
    "YEAR_9": 53088.7197056455,
    "YEAR_10": 53196.15005508141,
    "AD_Around": 474897.85791603965
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Around",
    "No of Households": 900200,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 405090,
    "No of HHs for ad": 16203.6,
    "AD Revenue Per month": 4131.918,
    "YEAR_1": 0,
    "YEAR_2": 49731.887765964595,
    "YEAR_3": 49833.97126262603,
    "YEAR_4": 49936.05475928747,
    "YEAR_5": 50038.138255948914,
    "YEAR_6": 50140.221752610356,
    "YEAR_7": 50242.30524927178,
    "YEAR_8": 50344.38874593323,
    "YEAR_9": 50446.472242594675,
    "YEAR_10": 50548.55573925612,
    "AD_Around": 451261.9957734932
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Around",
    "No of Households": 888210,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 399694.5,
    "No of HHs for ad": 15987.78,
    "AD Revenue Per month": 4076.8839000000003,
    "YEAR_1": 0,
    "YEAR_2": 49069.49570385183,
    "YEAR_3": 49170.21952363594,
    "YEAR_4": 49270.94334342006,
    "YEAR_5": 49371.66716320417,
    "YEAR_6": 49472.390982988276,
    "YEAR_7": 49573.11480277239,
    "YEAR_8": 49673.838622556505,
    "YEAR_9": 49774.56244234062,
    "YEAR_10": 49875.28626212473,
    "AD_Around": 445251.5188468945
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Around",
    "No of Households": 870240,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 391608,
    "No of HHs for ad": 15664.32,
    "AD Revenue Per month": 3994.4015999999992,
    "YEAR_1": 0,
    "YEAR_2": 48076.736291327514,
    "YEAR_3": 48175.42229680924,
    "YEAR_4": 48274.108302290966,
    "YEAR_5": 48372.79430777269,
    "YEAR_6": 48471.48031325442,
    "YEAR_7": 48570.16631873614,
    "YEAR_8": 48668.85232421788,
    "YEAR_9": 48767.53832969961,
    "YEAR_10": 48866.22433518133,
    "AD_Around": 436243.32281928975
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Around",
    "No of Households": 790580,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 355761,
    "No of HHs for ad": 14230.44,
    "AD Revenue Per month": 3628.7621999999997,
    "YEAR_1": 0,
    "YEAR_2": 43675.889613437335,
    "YEAR_3": 43765.54210265151,
    "YEAR_4": 43855.194591865686,
    "YEAR_5": 43944.84708107986,
    "YEAR_6": 44034.499570294036,
    "YEAR_7": 44124.15205950821,
    "YEAR_8": 44213.80454872239,
    "YEAR_9": 44303.45703793657,
    "YEAR_10": 44393.109527150744,
    "AD_Around": 396310.49613264634
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Around",
    "No of Households": 781030,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 351463.5,
    "No of HHs for ad": 14058.54,
    "AD Revenue Per month": 3584.9276999999997,
    "YEAR_1": 0,
    "YEAR_2": 43148.296269552695,
    "YEAR_3": 43236.86578010311,
    "YEAR_4": 43325.43529065352,
    "YEAR_5": 43414.00480120393,
    "YEAR_6": 43502.57431175435,
    "YEAR_7": 43591.143822304766,
    "YEAR_8": 43679.71333285518,
    "YEAR_9": 43768.2828434056,
    "YEAR_10": 43856.85235395602,
    "AD_Around": 391523.1688057892
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Around",
    "No of Households": 772320,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 347544,
    "No of HHs for ad": 13901.76,
    "AD Revenue Per month": 3544.9487999999997,
    "YEAR_1": 0,
    "YEAR_2": 42667.10904177935,
    "YEAR_3": 42754.69083042806,
    "YEAR_4": 42842.272619076764,
    "YEAR_5": 42929.85440772547,
    "YEAR_6": 43017.436196374176,
    "YEAR_7": 43105.01798502288,
    "YEAR_8": 43192.59977367159,
    "YEAR_9": 43280.181562320286,
    "YEAR_10": 43367.763350968984,
    "AD_Around": 387156.9257673676
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Around",
    "No of Households": 772190,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 347485.5,
    "No of HHs for ad": 13899.42,
    "AD Revenue Per month": 3544.3520999999996,
    "YEAR_1": 0,
    "YEAR_2": 42659.92714285737,
    "YEAR_3": 42747.49418938813,
    "YEAR_4": 42835.061235918896,
    "YEAR_5": 42922.62828244967,
    "YEAR_6": 43010.19532898043,
    "YEAR_7": 43097.7623755112,
    "YEAR_8": 43185.32942204198,
    "YEAR_9": 43272.89646857274,
    "YEAR_10": 43360.46351510351,
    "AD_Around": 387091.7579608239
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Around",
    "No of Households": 766220,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 344799,
    "No of HHs for ad": 13791.960000000001,
    "AD Revenue Per month": 3516.9498000000003,
    "YEAR_1": 0,
    "YEAR_2": 42330.11224620907,
    "YEAR_3": 42417.00228932385,
    "YEAR_4": 42503.89233243863,
    "YEAR_5": 42590.78237555341,
    "YEAR_6": 42677.6724186682,
    "YEAR_7": 42764.56246178298,
    "YEAR_8": 42851.45250489777,
    "YEAR_9": 42938.34254801255,
    "YEAR_10": 43025.23259112734,
    "AD_Around": 384099.0517680138
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Around",
    "No of Households": 743340,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 334503,
    "No of HHs for ad": 13380.12,
    "AD Revenue Per month": 3411.9306,
    "YEAR_1": 0,
    "YEAR_2": 41066.09803593883,
    "YEAR_3": 41150.39346629687,
    "YEAR_4": 41234.688896654916,
    "YEAR_5": 41318.98432701297,
    "YEAR_6": 41403.27975737101,
    "YEAR_7": 41487.57518772906,
    "YEAR_8": 41571.870618087116,
    "YEAR_9": 41656.16604844516,
    "YEAR_10": 41740.461478803205,
    "AD_Around": 372629.5178163391
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Around",
    "No of Households": 739970,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 332986.5,
    "No of HHs for ad": 13319.460000000001,
    "AD Revenue Per month": 3396.4623,
    "YEAR_1": 0,
    "YEAR_2": 40879.92111773031,
    "YEAR_3": 40963.83438703111,
    "YEAR_4": 41047.74765633188,
    "YEAR_5": 41131.66092563268,
    "YEAR_6": 41215.57419493345,
    "YEAR_7": 41299.48746423424,
    "YEAR_8": 41383.40073353502,
    "YEAR_9": 41467.31400283581,
    "YEAR_10": 41551.22727213658,
    "AD_Around": 370940.1677544011
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Around",
    "No of Households": 721070,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 324481.5,
    "No of HHs for ad": 12979.26,
    "AD Revenue Per month": 3309.7113,
    "YEAR_1": 0,
    "YEAR_2": 39835.78350522561,
    "YEAR_3": 39917.55349738031,
    "YEAR_4": 39999.32348953502,
    "YEAR_5": 40081.09348168972,
    "YEAR_6": 40162.86347384442,
    "YEAR_7": 40244.63346599913,
    "YEAR_8": 40326.403458153836,
    "YEAR_9": 40408.17345030853,
    "YEAR_10": 40489.94344246324,
    "AD_Around": 361465.7712645998
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Around",
    "No of Households": 720750,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 324337.5,
    "No of HHs for ad": 12973.5,
    "AD Revenue Per month": 3308.2425,
    "YEAR_1": 0,
    "YEAR_2": 39818.104984802245,
    "YEAR_3": 39899.838688666634,
    "YEAR_4": 39981.572392531045,
    "YEAR_5": 40063.306096395456,
    "YEAR_6": 40145.03980025985,
    "YEAR_7": 40226.773504124256,
    "YEAR_8": 40308.50720798865,
    "YEAR_9": 40390.24091185305,
    "YEAR_10": 40471.974615717445,
    "AD_Around": 361305.35820233857
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Around",
    "No of Households": 687110,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 309199.5,
    "No of HHs for ad": 12367.98,
    "AD Revenue Per month": 3153.8348999999994,
    "YEAR_1": 0,
    "YEAR_2": 37959.65052529652,
    "YEAR_3": 38037.569422642715,
    "YEAR_4": 38115.48831998891,
    "YEAR_5": 38193.4072173351,
    "YEAR_6": 38271.326114681295,
    "YEAR_7": 38349.24501202748,
    "YEAR_8": 38427.163909373674,
    "YEAR_9": 38505.08280671987,
    "YEAR_10": 38583.00170406606,
    "AD_Around": 344441.9350321316
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Around",
    "No of Households": 663900,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 298755,
    "No of HHs for ad": 11950.2,
    "AD Revenue Per month": 3047.301,
    "YEAR_1": 0,
    "YEAR_2": 36677.405340839694,
    "YEAR_3": 36752.69220312978,
    "YEAR_4": 36827.97906541986,
    "YEAR_5": 36903.26592770993,
    "YEAR_6": 36978.55279000003,
    "YEAR_7": 37053.839652290095,
    "YEAR_8": 37129.12651458018,
    "YEAR_9": 37204.41337687026,
    "YEAR_10": 37279.70023916033,
    "AD_Around": 332806.9751100002
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Around",
    "No of Households": 644360,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 289962,
    "No of HHs for ad": 11598.48,
    "AD Revenue Per month": 2957.6123999999995,
    "YEAR_1": 0,
    "YEAR_2": 35597.91068748827,
    "YEAR_3": 35670.98169605167,
    "YEAR_4": 35744.05270461507,
    "YEAR_5": 35817.12371317846,
    "YEAR_6": 35890.19472174185,
    "YEAR_7": 35963.26573030524,
    "YEAR_8": 36036.33673886863,
    "YEAR_9": 36109.40774743202,
    "YEAR_10": 36182.47875599542,
    "AD_Around": 323011.75249567657
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Around",
    "No of Households": 634120,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 285354,
    "No of HHs for ad": 11414.16,
    "AD Revenue Per month": 2910.6108,
    "YEAR_1": 0,
    "YEAR_2": 35032.19803394076,
    "YEAR_3": 35104.10781721442,
    "YEAR_4": 35176.017600488085,
    "YEAR_5": 35247.92738376175,
    "YEAR_6": 35319.83716703542,
    "YEAR_7": 35391.74695030908,
    "YEAR_8": 35463.65673358274,
    "YEAR_9": 35535.5665168564,
    "YEAR_10": 35607.476300130074,
    "AD_Around": 317878.5345033188
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Around",
    "No of Households": 632110,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 284449.5,
    "No of HHs for ad": 11377.98,
    "AD Revenue Per month": 2901.3848999999996,
    "YEAR_1": 0,
    "YEAR_2": 34921.15482753152,
    "YEAR_3": 34992.836674981714,
    "YEAR_4": 35064.51852243191,
    "YEAR_5": 35136.2003698821,
    "YEAR_6": 35207.88221733229,
    "YEAR_7": 35279.564064782484,
    "YEAR_8": 35351.245912232676,
    "YEAR_9": 35422.92775968287,
    "YEAR_10": 35494.60960713306,
    "AD_Around": 316870.93995599065
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Around",
    "No of Households": 618120,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 278154,
    "No of HHs for ad": 11126.16,
    "AD Revenue Per month": 2837.1708,
    "YEAR_1": 0,
    "YEAR_2": 34148.27201277276,
    "YEAR_3": 34218.36738153122,
    "YEAR_4": 34288.462750289684,
    "YEAR_5": 34358.558119048146,
    "YEAR_6": 34428.653487806616,
    "YEAR_7": 34498.74885656508,
    "YEAR_8": 34568.84422532354,
    "YEAR_9": 34638.939594082,
    "YEAR_10": 34709.03496284047,
    "AD_Around": 309857.88139025954
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Around",
    "No of Households": 608190,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 273685.5,
    "No of HHs for ad": 10947.42,
    "AD Revenue Per month": 2791.5921000000003,
    "YEAR_1": 0,
    "YEAR_2": 33599.68542588537,
    "YEAR_3": 33668.65472363534,
    "YEAR_4": 33737.624021385316,
    "YEAR_5": 33806.593319135274,
    "YEAR_6": 33875.56261688525,
    "YEAR_7": 33944.53191463522,
    "YEAR_8": 34013.501212385185,
    "YEAR_9": 34082.47051013515,
    "YEAR_10": 34151.439807885115,
    "AD_Around": 304880.0635519672
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Around",
    "No of Households": 590390,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 265675.5,
    "No of HHs for ad": 10627.02,
    "AD Revenue Per month": 2709.8901000000005,
    "YEAR_1": 0,
    "YEAR_2": 32616.317727335972,
    "YEAR_3": 32683.268488937785,
    "YEAR_4": 32750.21925053959,
    "YEAR_5": 32817.170012141396,
    "YEAR_6": 32884.12077374321,
    "YEAR_7": 32951.071535345014,
    "YEAR_8": 33018.02229694682,
    "YEAR_9": 33084.97305854863,
    "YEAR_10": 33151.92382015044,
    "AD_Around": 295957.0869636889
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Around",
    "No of Households": 588650,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 264892.5,
    "No of HHs for ad": 10595.7,
    "AD Revenue Per month": 2701.9035000000003,
    "YEAR_1": 0,
    "YEAR_2": 32520.190772533955,
    "YEAR_3": 32586.944216557233,
    "YEAR_4": 32653.697660580518,
    "YEAR_5": 32720.451104603795,
    "YEAR_6": 32787.20454862708,
    "YEAR_7": 32853.95799265036,
    "YEAR_8": 32920.711436673635,
    "YEAR_9": 32987.46488069691,
    "YEAR_10": 33054.21832472019,
    "AD_Around": 295084.84093764366
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Around",
    "No of Households": 578760,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 260442,
    "No of HHs for ad": 10417.68,
    "AD Revenue Per month": 2656.5083999999997,
    "YEAR_1": 0,
    "YEAR_2": 31973.81400069948,
    "YEAR_3": 32039.445909750546,
    "YEAR_4": 32105.077818801616,
    "YEAR_5": 32170.709727852693,
    "YEAR_6": 32236.341636903766,
    "YEAR_7": 32301.97354595484,
    "YEAR_8": 32367.605455005905,
    "YEAR_9": 32433.237364056975,
    "YEAR_10": 32498.869273108052,
    "AD_Around": 290127.0747321339
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Around",
    "No of Households": 577130,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 259708.5,
    "No of HHs for ad": 10388.34,
    "AD Revenue Per month": 2649.0267000000003,
    "YEAR_1": 0,
    "YEAR_2": 31883.76403729299,
    "YEAR_3": 31949.211102865334,
    "YEAR_4": 32014.65816843767,
    "YEAR_5": 32080.105234010003,
    "YEAR_6": 32145.552299582345,
    "YEAR_7": 32210.999365154672,
    "YEAR_8": 32276.446430727017,
    "YEAR_9": 32341.89349629934,
    "YEAR_10": 32407.340561871686,
    "AD_Around": 289309.9706962411
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Around",
    "No of Households": 559650,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 251842.5,
    "No of HHs for ad": 10073.7,
    "AD Revenue Per month": 2568.7935000000007,
    "YEAR_1": 0,
    "YEAR_2": 30918.074859166958,
    "YEAR_3": 30981.539676881435,
    "YEAR_4": 31045.00449459592,
    "YEAR_5": 31108.4693123104,
    "YEAR_6": 31171.934130024874,
    "YEAR_7": 31235.398947739362,
    "YEAR_8": 31298.863765453836,
    "YEAR_9": 31362.328583168317,
    "YEAR_10": 31425.793400882794,
    "AD_Around": 280547.4071702239
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Around",
    "No of Households": 543710,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 244669.5,
    "No of HHs for ad": 9786.78,
    "AD Revenue Per month": 2495.6289,
    "YEAR_1": 0,
    "YEAR_2": 30037.463560578333,
    "YEAR_3": 30099.120767832042,
    "YEAR_4": 30160.77797508576,
    "YEAR_5": 30222.435182339468,
    "YEAR_6": 30284.09238959318,
    "YEAR_7": 30345.74959684689,
    "YEAR_8": 30407.406804100603,
    "YEAR_9": 30469.064011354312,
    "YEAR_10": 30530.72121860803,
    "AD_Around": 272556.83150633867
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Around",
    "No of Households": 510900,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 229905,
    "No of HHs for ad": 9196.2,
    "AD Revenue Per month": 2345.0310000000004,
    "YEAR_1": 0,
    "YEAR_2": 28224.862763420708,
    "YEAR_3": 28282.799286909183,
    "YEAR_4": 28340.735810397666,
    "YEAR_5": 28398.67233388614,
    "YEAR_6": 28456.608857374624,
    "YEAR_7": 28514.545380863103,
    "YEAR_8": 28572.481904351585,
    "YEAR_9": 28630.418427840068,
    "YEAR_10": 28688.354951328547,
    "AD_Around": 256109.47971637166
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Around",
    "No of Households": 491820,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 221319,
    "No of HHs for ad": 8852.76,
    "AD Revenue Per month": 2257.4538,
    "YEAR_1": 0,
    "YEAR_2": 27170.780983177858,
    "YEAR_3": 27226.553817356966,
    "YEAR_4": 27282.32665153607,
    "YEAR_5": 27338.099485715178,
    "YEAR_6": 27393.87231989428,
    "YEAR_7": 27449.64515407337,
    "YEAR_8": 27505.417988252477,
    "YEAR_9": 27561.19082243158,
    "YEAR_10": 27616.963656610682,
    "AD_Around": 246544.8508790485
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Around",
    "No of Households": 490560,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 220752,
    "No of HHs for ad": 8830.08,
    "AD Revenue Per month": 2251.6704,
    "YEAR_1": 0,
    "YEAR_2": 27101.171809010877,
    "YEAR_3": 27156.80175804691,
    "YEAR_4": 27212.431707082942,
    "YEAR_5": 27268.061656118978,
    "YEAR_6": 27323.69160515501,
    "YEAR_7": 27379.32155419104,
    "YEAR_8": 27434.951503227072,
    "YEAR_9": 27490.581452263104,
    "YEAR_10": 27546.211401299137,
    "AD_Around": 245913.2244463951
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Around",
    "No of Households": 484300,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 217935,
    "No of HHs for ad": 8717.4,
    "AD Revenue Per month": 2222.937,
    "YEAR_1": 0,
    "YEAR_2": 26755.335753228905,
    "YEAR_3": 26810.255812585856,
    "YEAR_4": 26865.175871942818,
    "YEAR_5": 26920.095931299777,
    "YEAR_6": 26975.015990656735,
    "YEAR_7": 27029.936050013697,
    "YEAR_8": 27084.85610937066,
    "YEAR_9": 27139.77616872762,
    "YEAR_10": 27194.696228084584,
    "AD_Around": 242775.14391591065
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Around",
    "No of Households": 481910,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 216859.5,
    "No of HHs for ad": 8674.380000000001,
    "AD Revenue Per month": 2211.9669000000004,
    "YEAR_1": 0,
    "YEAR_2": 26623.299303816937,
    "YEAR_3": 26677.94833500569,
    "YEAR_4": 26732.59736619444,
    "YEAR_5": 26787.246397383195,
    "YEAR_6": 26841.895428571945,
    "YEAR_7": 26896.54445976069,
    "YEAR_8": 26951.19349094944,
    "YEAR_9": 27005.84252213819,
    "YEAR_10": 27060.491553326952,
    "AD_Around": 241577.05885714747
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Around",
    "No of Households": 472310,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 212539.5,
    "No of HHs for ad": 8501.58,
    "AD Revenue Per month": 2167.9029,
    "YEAR_1": 0,
    "YEAR_2": 26092.943691116136,
    "YEAR_3": 26146.50407359576,
    "YEAR_4": 26200.064456075394,
    "YEAR_5": 26253.624838555028,
    "YEAR_6": 26307.185221034662,
    "YEAR_7": 26360.74560351429,
    "YEAR_8": 26414.305985993917,
    "YEAR_9": 26467.866368473555,
    "YEAR_10": 26521.42675095319,
    "AD_Around": 236764.66698931193
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Around",
    "No of Households": 471190,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 212035.5,
    "No of HHs for ad": 8481.42,
    "AD Revenue Per month": 2162.7620999999995,
    "YEAR_1": 0,
    "YEAR_2": 26031.068869634364,
    "YEAR_3": 26084.50224309793,
    "YEAR_4": 26137.935616561503,
    "YEAR_5": 26191.36899002507,
    "YEAR_6": 26244.802363488638,
    "YEAR_7": 26298.235736952207,
    "YEAR_8": 26351.66911041577,
    "YEAR_9": 26405.10248387934,
    "YEAR_10": 26458.535857342908,
    "AD_Around": 236203.22127139772
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Around",
    "No of Households": 457620,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 205929,
    "No of HHs for ad": 8237.16,
    "AD Revenue Per month": 2100.4757999999997,
    "YEAR_1": 0,
    "YEAR_2": 25281.389112931254,
    "YEAR_3": 25333.283636084125,
    "YEAR_4": 25385.17815923698,
    "YEAR_5": 25437.07268238986,
    "YEAR_6": 25488.967205542718,
    "YEAR_7": 25540.86172869557,
    "YEAR_8": 25592.756251848445,
    "YEAR_9": 25644.650775001308,
    "YEAR_10": 25696.54529815416,
    "AD_Around": 229400.70484988444
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Around",
    "No of Households": 456390,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 205375.5,
    "No of HHs for ad": 8215.02,
    "AD Revenue Per month": 2094.8300999999997,
    "YEAR_1": 0,
    "YEAR_2": 25213.43730005397,
    "YEAR_3": 25265.192340090973,
    "YEAR_4": 25316.947380127986,
    "YEAR_5": 25368.70242016499,
    "YEAR_6": 25420.457460202,
    "YEAR_7": 25472.212500239006,
    "YEAR_8": 25523.96754027602,
    "YEAR_9": 25575.72258031302,
    "YEAR_10": 25627.47762035003,
    "AD_Around": 228784.11714181796
  },
  {
    "DMA": "Madison",
    "PlatformName": "Around",
    "No of Households": 454700,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 204615,
    "No of HHs for ad": 8184.6,
    "AD Revenue Per month": 2087.0730000000003,
    "YEAR_1": 0,
    "YEAR_2": 25120.0726140681,
    "YEAR_3": 25171.63600657195,
    "YEAR_4": 25223.199399075784,
    "YEAR_5": 25274.762791579626,
    "YEAR_6": 25326.326184083464,
    "YEAR_7": 25377.889576587306,
    "YEAR_8": 25429.452969091144,
    "YEAR_9": 25481.01636159498,
    "YEAR_10": 25532.579754098828,
    "AD_Around": 227936.93565675116
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Around",
    "No of Households": 451790,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 203305.5,
    "No of HHs for ad": 8132.22,
    "AD Revenue Per month": 2073.7160999999996,
    "YEAR_1": 0,
    "YEAR_2": 24959.30856896817,
    "YEAR_3": 25010.541964832053,
    "YEAR_4": 25061.775360695938,
    "YEAR_5": 25113.00875655983,
    "YEAR_6": 25164.24215242372,
    "YEAR_7": 25215.475548287606,
    "YEAR_8": 25266.70894415149,
    "YEAR_9": 25317.942340015383,
    "YEAR_10": 25369.175735879267,
    "AD_Around": 226478.17937181343
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Around",
    "No of Households": 442050,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 198922.5,
    "No of HHs for ad": 7956.900000000001,
    "AD Revenue Per month": 2029.0095000000003,
    "YEAR_1": 0,
    "YEAR_2": 24421.218603582154,
    "YEAR_3": 24471.347474609913,
    "YEAR_4": 24521.476345637675,
    "YEAR_5": 24571.605216665434,
    "YEAR_6": 24621.734087693192,
    "YEAR_7": 24671.86295872095,
    "YEAR_8": 24721.991829748713,
    "YEAR_9": 24772.12070077648,
    "YEAR_10": 24822.249571804234,
    "AD_Around": 221595.60678923875
  },
  {
    "DMA": "Springfield, MO",
    "PlatformName": "Around",
    "No of Households": 436340,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 196353,
    "No of HHs for ad": 7854.12,
    "AD Revenue Per month": 2002.8005999999996,
    "YEAR_1": 0,
    "YEAR_2": 24105.767504777814,
    "YEAR_3": 24155.24885662546,
    "YEAR_4": 24204.730208473113,
    "YEAR_5": 24254.21156032076,
    "YEAR_6": 24303.692912168404,
    "YEAR_7": 24353.174264016052,
    "YEAR_8": 24402.655615863703,
    "YEAR_9": 24452.13696771135,
    "YEAR_10": 24501.618319559,
    "AD_Around": 218733.2362095157
  },
  {
    "DMA": "Columbia, SC",
    "PlatformName": "Around",
    "No of Households": 435570,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 196006.5,
    "No of HHs for ad": 7840.26,
    "AD Revenue Per month": 1999.2663000000002,
    "YEAR_1": 0,
    "YEAR_2": 24063.228565009118,
    "YEAR_3": 24112.622598158214,
    "YEAR_4": 24162.01663130732,
    "YEAR_5": 24211.41066445643,
    "YEAR_6": 24260.804697605523,
    "YEAR_7": 24310.19873075463,
    "YEAR_8": 24359.59276390374,
    "YEAR_9": 24408.986797052836,
    "YEAR_10": 24458.380830201942,
    "AD_Around": 218347.24227844973
  },
  {
    "DMA": "Rochester, NY",
    "PlatformName": "Around",
    "No of Households": 434190,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 195385.5,
    "No of HHs for ad": 7815.42,
    "AD Revenue Per month": 1992.9321,
    "YEAR_1": 0,
    "YEAR_2": 23986.989945683366,
    "YEAR_3": 24036.227485580537,
    "YEAR_4": 24085.465025477708,
    "YEAR_5": 24134.702565374875,
    "YEAR_6": 24183.940105272042,
    "YEAR_7": 24233.177645169206,
    "YEAR_8": 24282.415185066377,
    "YEAR_9": 24331.652724963547,
    "YEAR_10": 24380.890264860715,
    "AD_Around": 217655.46094744836
  },
  {
    "DMA": "Portland",
    "PlatformName": "Around",
    "No of Households": 429130,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 193108.5,
    "No of HHs for ad": 7724.34,
    "AD Revenue Per month": 1969.7067,
    "YEAR_1": 0,
    "YEAR_2": 23707.44834148899,
    "YEAR_3": 23756.112072795724,
    "YEAR_4": 23804.775804102464,
    "YEAR_5": 23853.439535409194,
    "YEAR_6": 23902.103266715934,
    "YEAR_7": 23950.766998022667,
    "YEAR_8": 23999.430729329408,
    "YEAR_9": 24048.09446063614,
    "YEAR_10": 24096.758191942878,
    "AD_Around": 215118.92940044342
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Around",
    "No of Households": 427650,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 192442.5,
    "No of HHs for ad": 7697.7,
    "AD Revenue Per month": 1962.9135,
    "YEAR_1": 0,
    "YEAR_2": 23625.685184530957,
    "YEAR_3": 23674.181082495026,
    "YEAR_4": 23722.676980459113,
    "YEAR_5": 23771.172878423196,
    "YEAR_6": 23819.66877638727,
    "YEAR_7": 23868.164674351352,
    "YEAR_8": 23916.660572315435,
    "YEAR_9": 23965.156470279508,
    "YEAR_10": 24013.65236824359,
    "AD_Around": 214377.0189874854
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Around",
    "No of Households": 424380,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 190971,
    "No of HHs for ad": 7638.84,
    "AD Revenue Per month": 1947.9042,
    "YEAR_1": 0,
    "YEAR_2": 23445.03280395474,
    "YEAR_3": 23493.157880952276,
    "YEAR_4": 23541.282957949814,
    "YEAR_5": 23589.408034947348,
    "YEAR_6": 23637.533111944882,
    "YEAR_7": 23685.658188942416,
    "YEAR_8": 23733.783265939954,
    "YEAR_9": 23781.908342937495,
    "YEAR_10": 23830.033419935033,
    "AD_Around": 212737.79800750397
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Around",
    "No of Households": 412370,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 185566.5,
    "No of HHs for ad": 7422.66,
    "AD Revenue Per month": 1892.7782999999997,
    "YEAR_1": 0,
    "YEAR_2": 22781.535834315502,
    "YEAR_3": 22828.298966417573,
    "YEAR_4": 22875.062098519633,
    "YEAR_5": 22921.825230621704,
    "YEAR_6": 22968.588362723764,
    "YEAR_7": 23015.35149482583,
    "YEAR_8": 23062.11462692789,
    "YEAR_9": 23108.87775902995,
    "YEAR_10": 23155.64089113202,
    "AD_Around": 206717.29526451387
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Around",
    "No of Households": 407620,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 183429,
    "No of HHs for ad": 7337.16,
    "AD Revenue Per month": 1870.9757999999997,
    "YEAR_1": 0,
    "YEAR_2": 22519.120296781253,
    "YEAR_3": 22565.34477457412,
    "YEAR_4": 22611.569252366982,
    "YEAR_5": 22657.793730159847,
    "YEAR_6": 22704.018207952715,
    "YEAR_7": 22750.24268574558,
    "YEAR_8": 22796.46716353844,
    "YEAR_9": 22842.69164133131,
    "YEAR_10": 22888.916119124166,
    "AD_Around": 204336.1638715744
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Around",
    "No of Households": 403470,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 181561.5,
    "No of HHs for ad": 7262.46,
    "AD Revenue Per month": 1851.9272999999998,
    "YEAR_1": 0,
    "YEAR_2": 22289.85198504081,
    "YEAR_3": 22335.60584906879,
    "YEAR_4": 22381.35971309678,
    "YEAR_5": 22427.11357712476,
    "YEAR_6": 22472.867441152743,
    "YEAR_7": 22518.621305180724,
    "YEAR_8": 22564.375169208713,
    "YEAR_9": 22610.1290332367,
    "YEAR_10": 22655.88289726468,
    "AD_Around": 202255.8069703747
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Around",
    "No of Households": 401510,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 180679.5,
    "No of HHs for ad": 7227.18,
    "AD Revenue Per month": 1842.9308999999998,
    "YEAR_1": 0,
    "YEAR_2": 22181.571047447727,
    "YEAR_3": 22227.1026456976,
    "YEAR_4": 22272.634243947472,
    "YEAR_5": 22318.165842197344,
    "YEAR_6": 22363.697440447217,
    "YEAR_7": 22409.22903869709,
    "YEAR_8": 22454.76063694696,
    "YEAR_9": 22500.292235196834,
    "YEAR_10": 22545.823833446706,
    "AD_Around": 201273.27696402496
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Around",
    "No of Households": 388650,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 174892.5,
    "No of HHs for ad": 6995.7,
    "AD Revenue Per month": 1783.9035,
    "YEAR_1": 0,
    "YEAR_2": 21471.115507933948,
    "YEAR_3": 21515.18877051723,
    "YEAR_4": 21559.262033100513,
    "YEAR_5": 21603.33529568379,
    "YEAR_6": 21647.40855826707,
    "YEAR_7": 21691.48182085035,
    "YEAR_8": 21735.555083433625,
    "YEAR_9": 21779.628346016907,
    "YEAR_10": 21823.70160860019,
    "AD_Around": 194826.67702440362
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Around",
    "No of Households": 385730,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 173578.5,
    "No of HHs for ad": 6943.14,
    "AD Revenue Per month": 1770.5007000000003,
    "YEAR_1": 0,
    "YEAR_2": 21309.799009070794,
    "YEAR_3": 21353.541141005047,
    "YEAR_4": 21397.28327293931,
    "YEAR_5": 21441.025404873562,
    "YEAR_6": 21484.76753680782,
    "YEAR_7": 21528.509668742074,
    "YEAR_8": 21572.251800676328,
    "YEAR_9": 21615.99393261059,
    "YEAR_10": 21659.736064544843,
    "AD_Around": 193362.90783127036
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Around",
    "No of Households": 378040,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 170118,
    "No of HHs for ad": 6804.72,
    "AD Revenue Per month": 1735.2035999999998,
    "YEAR_1": 0,
    "YEAR_2": 20884.96206514692,
    "YEAR_3": 20927.832144104807,
    "YEAR_4": 20970.702223062694,
    "YEAR_5": 21013.57230202058,
    "YEAR_6": 21056.44238097847,
    "YEAR_7": 21099.312459936355,
    "YEAR_8": 21142.18253889424,
    "YEAR_9": 21185.052617852136,
    "YEAR_10": 21227.92269681002,
    "AD_Around": 189507.98142880623
  },
  {
    "DMA": "Charleston, SC",
    "PlatformName": "Around",
    "No of Households": 374290,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 168430.5,
    "No of HHs for ad": 6737.22,
    "AD Revenue Per month": 1717.9911,
    "YEAR_1": 0,
    "YEAR_2": 20677.79190393567,
    "YEAR_3": 20720.236729491553,
    "YEAR_4": 20762.681555047446,
    "YEAR_5": 20805.12638060333,
    "YEAR_6": 20847.571206159224,
    "YEAR_7": 20890.01603171511,
    "YEAR_8": 20932.460857271,
    "YEAR_9": 20974.905682826884,
    "YEAR_10": 21017.350508382773,
    "AD_Around": 187628.140855433
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Around",
    "No of Households": 373520,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 168084,
    "No of HHs for ad": 6723.360000000001,
    "AD Revenue Per month": 1714.4568000000002,
    "YEAR_1": 0,
    "YEAR_2": 20635.25296416696,
    "YEAR_3": 20677.6104710243,
    "YEAR_4": 20719.967977881653,
    "YEAR_5": 20762.325484738994,
    "YEAR_6": 20804.682991596343,
    "YEAR_7": 20847.04049845368,
    "YEAR_8": 20889.39800531102,
    "YEAR_9": 20931.755512168373,
    "YEAR_10": 20974.113019025714,
    "AD_Around": 187242.14692436703
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Around",
    "No of Households": 373080,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 167886,
    "No of HHs for ad": 6715.4400000000005,
    "AD Revenue Per month": 1712.4372,
    "YEAR_1": 0,
    "YEAR_2": 20610.94499858484,
    "YEAR_3": 20653.25260904302,
    "YEAR_4": 20695.560219501192,
    "YEAR_5": 20737.867829959367,
    "YEAR_6": 20780.175440417544,
    "YEAR_7": 20822.48305087572,
    "YEAR_8": 20864.7906613339,
    "YEAR_9": 20907.098271792074,
    "YEAR_10": 20949.40588225025,
    "AD_Around": 187021.5789637579
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Around",
    "No of Households": 371730,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 167278.5,
    "No of HHs for ad": 6691.14,
    "AD Revenue Per month": 1706.2407000000003,
    "YEAR_1": 0,
    "YEAR_2": 20536.363740548797,
    "YEAR_3": 20578.518259782253,
    "YEAR_4": 20620.672779015706,
    "YEAR_5": 20662.82729824916,
    "YEAR_6": 20704.981817482614,
    "YEAR_7": 20747.136336716074,
    "YEAR_8": 20789.29085594953,
    "YEAR_9": 20831.445375182986,
    "YEAR_10": 20873.59989441644,
    "AD_Around": 186344.83635734356
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Around",
    "No of Households": 369270,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 166171.5,
    "No of HHs for ad": 6646.860000000001,
    "AD Revenue Per month": 1694.9492999999998,
    "YEAR_1": 0,
    "YEAR_2": 20400.46011479421,
    "YEAR_3": 20442.33566779595,
    "YEAR_4": 20484.211220797697,
    "YEAR_5": 20526.086773799438,
    "YEAR_6": 20567.962326801186,
    "YEAR_7": 20609.837879802926,
    "YEAR_8": 20651.713432804674,
    "YEAR_9": 20693.588985806415,
    "YEAR_10": 20735.464538808163,
    "AD_Around": 185111.66094121063
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Around",
    "No of Households": 360880,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 162396,
    "No of HHs for ad": 6495.84,
    "AD Revenue Per month": 1656.4392,
    "YEAR_1": 0,
    "YEAR_2": 19936.951407444238,
    "YEAR_3": 19977.875526834574,
    "YEAR_4": 20018.799646224914,
    "YEAR_5": 20059.72376561525,
    "YEAR_6": 20100.647885005586,
    "YEAR_7": 20141.57200439592,
    "YEAR_8": 20182.496123786255,
    "YEAR_9": 20223.42024317659,
    "YEAR_10": 20264.34436256693,
    "AD_Around": 180905.83096505026
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Around",
    "No of Households": 360200,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 162090,
    "No of HHs for ad": 6483.6,
    "AD Revenue Per month": 1653.3180000000002,
    "YEAR_1": 0,
    "YEAR_2": 19899.384551544605,
    "YEAR_3": 19940.231558318046,
    "YEAR_4": 19981.078565091484,
    "YEAR_5": 20021.925571864922,
    "YEAR_6": 20062.772578638363,
    "YEAR_7": 20103.619585411805,
    "YEAR_8": 20144.466592185247,
    "YEAR_9": 20185.31359895868,
    "YEAR_10": 20226.160605732122,
    "AD_Around": 180564.95320774527
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Around",
    "No of Households": 353500,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 159075,
    "No of HHs for ad": 6363,
    "AD Revenue Per month": 1622.565,
    "YEAR_1": 0,
    "YEAR_2": 19529.2405301805,
    "YEAR_3": 19569.327750875706,
    "YEAR_4": 19609.4149715709,
    "YEAR_5": 19649.5021922661,
    "YEAR_6": 19689.589412961297,
    "YEAR_7": 19729.6766336565,
    "YEAR_8": 19769.763854351702,
    "YEAR_9": 19809.851075046903,
    "YEAR_10": 19849.9382957421,
    "AD_Around": 177206.3047166517
  },
  {
    "DMA": "Jackson, MS",
    "PlatformName": "Around",
    "No of Households": 340720,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 153324,
    "No of HHs for ad": 6132.96,
    "AD Revenue Per month": 1563.9047999999998,
    "YEAR_1": 0,
    "YEAR_2": 18823.204620772558,
    "YEAR_3": 18861.842577873744,
    "YEAR_4": 18900.480534974922,
    "YEAR_5": 18939.118492076108,
    "YEAR_6": 18977.756449177294,
    "YEAR_7": 19016.394406278476,
    "YEAR_8": 19055.03236337966,
    "YEAR_9": 19093.670320480844,
    "YEAR_10": 19132.30827758203,
    "AD_Around": 170799.8080425956
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Around",
    "No of Households": 338310,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 152239.5,
    "No of HHs for ad": 6089.58,
    "AD Revenue Per month": 1552.8428999999999,
    "YEAR_1": 0,
    "YEAR_2": 18690.06326383413,
    "YEAR_3": 18728.42792474896,
    "YEAR_4": 18766.792585663792,
    "YEAR_5": 18805.157246578623,
    "YEAR_6": 18843.521907493458,
    "YEAR_7": 18881.88656840829,
    "YEAR_8": 18920.251229323123,
    "YEAR_9": 18958.615890237954,
    "YEAR_10": 18996.980551152785,
    "AD_Around": 169591.69716744107
  },
  {
    "DMA": "Boise",
    "PlatformName": "Around",
    "No of Households": 330040,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 148518,
    "No of HHs for ad": 5940.72,
    "AD Revenue Per month": 1514.8836000000001,
    "YEAR_1": 0,
    "YEAR_2": 18233.184001642923,
    "YEAR_3": 18270.610837055207,
    "YEAR_4": 18308.037672467497,
    "YEAR_5": 18345.464507879784,
    "YEAR_6": 18382.891343292074,
    "YEAR_7": 18420.31817870436,
    "YEAR_8": 18457.745014116652,
    "YEAR_9": 18495.17184952894,
    "YEAR_10": 18532.59868494123,
    "AD_Around": 165446.02208962868
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Around",
    "No of Households": 329080,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 148086,
    "No of HHs for ad": 5923.4400000000005,
    "AD Revenue Per month": 1510.4772,
    "YEAR_1": 0,
    "YEAR_2": 18180.14844037284,
    "YEAR_3": 18217.466410914214,
    "YEAR_4": 18254.784381455596,
    "YEAR_5": 18292.102351996968,
    "YEAR_6": 18329.420322538346,
    "YEAR_7": 18366.738293079718,
    "YEAR_8": 18404.056263621093,
    "YEAR_9": 18441.374234162475,
    "YEAR_10": 18478.69220470385,
    "AD_Around": 164964.7829028451
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Around",
    "No of Households": 322100,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 144945,
    "No of HHs for ad": 5797.8,
    "AD Revenue Per month": 1478.439,
    "YEAR_1": 0,
    "YEAR_2": 17794.5357136383,
    "YEAR_3": 17831.06214584742,
    "YEAR_4": 17867.58857805654,
    "YEAR_5": 17904.11501026566,
    "YEAR_6": 17940.64144247478,
    "YEAR_7": 17977.167874683906,
    "YEAR_8": 18013.69430689302,
    "YEAR_9": 18050.22073910214,
    "YEAR_10": 18086.747171311257,
    "AD_Around": 161465.77298227302
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Around",
    "No of Households": 320820,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 144369,
    "No of HHs for ad": 5774.76,
    "AD Revenue Per month": 1472.5638000000004,
    "YEAR_1": 0,
    "YEAR_2": 17723.821631944866,
    "YEAR_3": 17760.202910992768,
    "YEAR_4": 17796.58419004067,
    "YEAR_5": 17832.965469088576,
    "YEAR_6": 17869.346748136482,
    "YEAR_7": 17905.728027184385,
    "YEAR_8": 17942.109306232287,
    "YEAR_9": 17978.49058528019,
    "YEAR_10": 18014.871864328095,
    "AD_Around": 160824.12073322834
  },
  {
    "DMA": "Reno",
    "PlatformName": "Around",
    "No of Households": 306940,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 138123,
    "No of HHs for ad": 5524.92,
    "AD Revenue Per month": 1408.8546000000001,
    "YEAR_1": 0,
    "YEAR_2": 16957.015808581622,
    "YEAR_3": 16991.82308303759,
    "YEAR_4": 17026.63035749356,
    "YEAR_5": 17061.437631949524,
    "YEAR_6": 17096.244906405493,
    "YEAR_7": 17131.05218086146,
    "YEAR_8": 17165.85945531743,
    "YEAR_9": 17200.6667297734,
    "YEAR_10": 17235.474004229367,
    "AD_Around": 153866.20415764945
  },
  {
    "DMA": "Washington",
    "PlatformName": "Around",
    "No of Households": 305320,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 137394,
    "No of HHs for ad": 5495.76,
    "AD Revenue Per month": 1401.4188000000001,
    "YEAR_1": 0,
    "YEAR_2": 16867.51829893836,
    "YEAR_3": 16902.141863924666,
    "YEAR_4": 16936.76542891097,
    "YEAR_5": 16971.38899389727,
    "YEAR_6": 17006.012558883576,
    "YEAR_7": 17040.63612386988,
    "YEAR_8": 17075.259688856186,
    "YEAR_9": 17109.88325384249,
    "YEAR_10": 17144.506818828795,
    "AD_Around": 153054.1130299522
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Around",
    "No of Households": 302600,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 136170,
    "No of HHs for ad": 5446.8,
    "AD Revenue Per month": 1388.9339999999997,
    "YEAR_1": 0,
    "YEAR_2": 16717.250875339796,
    "YEAR_3": 16751.565989858518,
    "YEAR_4": 16785.88110437724,
    "YEAR_5": 16820.196218895955,
    "YEAR_6": 16854.511333414677,
    "YEAR_7": 16888.826447933396,
    "YEAR_8": 16923.141562452114,
    "YEAR_9": 16957.45667697084,
    "YEAR_10": 16991.77179148956,
    "AD_Around": 151690.6020007321
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Around",
    "No of Households": 299530,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 134788.5,
    "No of HHs for ad": 5391.54,
    "AD Revenue Per month": 1374.8427000000001,
    "YEAR_1": 0,
    "YEAR_2": 16547.64757002819,
    "YEAR_3": 16581.614543761807,
    "YEAR_4": 16615.581517495422,
    "YEAR_5": 16649.548491229038,
    "YEAR_6": 16683.515464962653,
    "YEAR_7": 16717.482438696272,
    "YEAR_8": 16751.449412429887,
    "YEAR_9": 16785.416386163502,
    "YEAR_10": 16819.383359897118,
    "AD_Around": 150151.6391846639
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Around",
    "No of Households": 291650,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 131242.5,
    "No of HHs for ad": 5249.7,
    "AD Revenue Per month": 1338.6735,
    "YEAR_1": 0,
    "YEAR_2": 16112.314004602951,
    "YEAR_3": 16145.387379187829,
    "YEAR_4": 16178.46075377271,
    "YEAR_5": 16211.534128357593,
    "YEAR_6": 16244.607502942472,
    "YEAR_7": 16277.680877527353,
    "YEAR_8": 16310.754252112232,
    "YEAR_9": 16343.827626697112,
    "YEAR_10": 16376.90100128199,
    "AD_Around": 146201.46752648224
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Around",
    "No of Households": 290990,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 130945.5,
    "No of HHs for ad": 5237.82,
    "AD Revenue Per month": 1335.6441,
    "YEAR_1": 0,
    "YEAR_2": 16075.85205622977,
    "YEAR_3": 16108.850586215898,
    "YEAR_4": 16141.849116202025,
    "YEAR_5": 16174.847646188155,
    "YEAR_6": 16207.846176174282,
    "YEAR_7": 16240.84470616041,
    "YEAR_8": 16273.843236146538,
    "YEAR_9": 16306.841766132668,
    "YEAR_10": 16339.840296118793,
    "AD_Around": 145870.61558556854
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Around",
    "No of Households": 285360,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 128412,
    "No of HHs for ad": 5136.4800000000005,
    "AD Revenue Per month": 1309.8024000000003,
    "YEAR_1": 0,
    "YEAR_2": 15764.820587531285,
    "YEAR_3": 15797.180670409876,
    "YEAR_4": 15829.540753288467,
    "YEAR_5": 15861.90083616706,
    "YEAR_6": 15894.26091904565,
    "YEAR_7": 15926.621001924243,
    "YEAR_8": 15958.981084802836,
    "YEAR_9": 15991.341167681427,
    "YEAR_10": 16023.70125056002,
    "AD_Around": 143048.34827141085
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Around",
    "No of Households": 284300,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 127935,
    "No of HHs for ad": 5117.400000000001,
    "AD Revenue Per month": 1304.937,
    "YEAR_1": 0,
    "YEAR_2": 15706.260488628897,
    "YEAR_3": 15738.500366545859,
    "YEAR_4": 15770.74024446282,
    "YEAR_5": 15802.98012237978,
    "YEAR_6": 15835.220000296738,
    "YEAR_7": 15867.4598782137,
    "YEAR_8": 15899.69975613066,
    "YEAR_9": 15931.939634047618,
    "YEAR_10": 15964.17951196458,
    "AD_Around": 142516.98000267067
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Around",
    "No of Households": 283100,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 127395,
    "No of HHs for ad": 5095.8,
    "AD Revenue Per month": 1299.429,
    "YEAR_1": 0,
    "YEAR_2": 15639.966037041302,
    "YEAR_3": 15672.06983386962,
    "YEAR_4": 15704.173630697944,
    "YEAR_5": 15736.277427526262,
    "YEAR_6": 15768.38122435458,
    "YEAR_7": 15800.485021182903,
    "YEAR_8": 15832.588818011222,
    "YEAR_9": 15864.69261483954,
    "YEAR_10": 15896.796411667863,
    "AD_Around": 141915.43101919122
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Around",
    "No of Households": 282090,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 126940.5,
    "No of HHs for ad": 5077.62,
    "AD Revenue Per month": 1294.7930999999999,
    "YEAR_1": 0,
    "YEAR_2": 15584.168206955068,
    "YEAR_3": 15616.157468867117,
    "YEAR_4": 15648.146730779164,
    "YEAR_5": 15680.135992691212,
    "YEAR_6": 15712.12525460326,
    "YEAR_7": 15744.11451651531,
    "YEAR_8": 15776.103778427358,
    "YEAR_9": 15808.093040339407,
    "YEAR_10": 15840.08230225145,
    "AD_Around": 141409.12729142932
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Around",
    "No of Households": 276580,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 124461,
    "No of HHs for ad": 4978.4400000000005,
    "AD Revenue Per month": 1269.5022,
    "YEAR_1": 0,
    "YEAR_2": 15279.766183415339,
    "YEAR_3": 15311.130606328714,
    "YEAR_4": 15342.49502924209,
    "YEAR_5": 15373.859452155468,
    "YEAR_6": 15405.223875068843,
    "YEAR_7": 15436.588297982218,
    "YEAR_8": 15467.952720895593,
    "YEAR_9": 15499.31714380897,
    "YEAR_10": 15530.681566722347,
    "AD_Around": 138647.01487561958
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Around",
    "No of Households": 265790,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 119605.5,
    "No of HHs for ad": 4784.22,
    "AD Revenue Per month": 1219.9761,
    "YEAR_1": 0,
    "YEAR_2": 14683.668572890174,
    "YEAR_3": 14713.80940001486,
    "YEAR_4": 14743.950227139547,
    "YEAR_5": 14774.09105426423,
    "YEAR_6": 14804.231881388927,
    "YEAR_7": 14834.372708513612,
    "YEAR_8": 14864.5135356383,
    "YEAR_9": 14894.654362762987,
    "YEAR_10": 14924.79518988767,
    "AD_Around": 133238.0869325003
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Around",
    "No of Households": 264420,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 118989,
    "No of HHs for ad": 4759.56,
    "AD Revenue Per month": 1213.6878000000002,
    "YEAR_1": 0,
    "YEAR_2": 14607.982407327661,
    "YEAR_3": 14637.967875209486,
    "YEAR_4": 14667.95334309131,
    "YEAR_5": 14697.938810973135,
    "YEAR_6": 14727.924278854956,
    "YEAR_7": 14757.909746736781,
    "YEAR_8": 14787.895214618607,
    "YEAR_9": 14817.880682500429,
    "YEAR_10": 14847.866150382253,
    "AD_Around": 132551.31850969463
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Around",
    "No of Households": 260780,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 117351,
    "No of HHs for ad": 4694.04,
    "AD Revenue Per month": 1196.9802,
    "YEAR_1": 0,
    "YEAR_2": 14406.88923751194,
    "YEAR_3": 14436.461926091557,
    "YEAR_4": 14466.03461467117,
    "YEAR_5": 14495.607303250788,
    "YEAR_6": 14525.179991830404,
    "YEAR_7": 14554.75268041002,
    "YEAR_8": 14584.325368989636,
    "YEAR_9": 14613.898057569251,
    "YEAR_10": 14643.470746148869,
    "AD_Around": 130726.61992647364
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Around",
    "No of Households": 260220,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 117099,
    "No of HHs for ad": 4683.96,
    "AD Revenue Per month": 1194.4098000000001,
    "YEAR_1": 0,
    "YEAR_2": 14375.95182677106,
    "YEAR_3": 14405.461010842646,
    "YEAR_4": 14434.97019491423,
    "YEAR_5": 14464.479378985814,
    "YEAR_6": 14493.988563057397,
    "YEAR_7": 14523.497747128982,
    "YEAR_8": 14553.006931200567,
    "YEAR_9": 14582.516115272148,
    "YEAR_10": 14612.025299343735,
    "AD_Around": 130445.89706751659
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Around",
    "No of Households": 258360,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 116262,
    "No of HHs for ad": 4650.4800000000005,
    "AD Revenue Per month": 1185.8724000000002,
    "YEAR_1": 0,
    "YEAR_2": 14273.19542681028,
    "YEAR_3": 14302.493685194475,
    "YEAR_4": 14331.791943578668,
    "YEAR_5": 14361.090201962857,
    "YEAR_6": 14390.388460347052,
    "YEAR_7": 14419.686718731242,
    "YEAR_8": 14448.984977115437,
    "YEAR_9": 14478.283235499624,
    "YEAR_10": 14507.58149388382,
    "AD_Around": 129513.49614312346
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Around",
    "No of Households": 254120,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 114354,
    "No of HHs for ad": 4574.16,
    "AD Revenue Per month": 1166.4107999999999,
    "YEAR_1": 0,
    "YEAR_2": 14038.95503120076,
    "YEAR_3": 14067.772469738422,
    "YEAR_4": 14096.589908276086,
    "YEAR_5": 14125.407346813752,
    "YEAR_6": 14154.224785351415,
    "YEAR_7": 14183.042223889079,
    "YEAR_8": 14211.859662426743,
    "YEAR_9": 14240.677100964407,
    "YEAR_10": 14269.49453950207,
    "AD_Around": 127388.02306816273
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Around",
    "No of Households": 250840,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 112878,
    "No of HHs for ad": 4515.12,
    "AD Revenue Per month": 1151.3555999999999,
    "YEAR_1": 0,
    "YEAR_2": 13857.750196861318,
    "YEAR_3": 13886.195680423367,
    "YEAR_4": 13914.641163985414,
    "YEAR_5": 13943.086647547463,
    "YEAR_6": 13971.53213110951,
    "YEAR_7": 13999.977614671558,
    "YEAR_8": 14028.423098233607,
    "YEAR_9": 14056.868581795654,
    "YEAR_10": 14085.314065357701,
    "AD_Around": 125743.78917998559
  },
  {
    "DMA": "Macon",
    "PlatformName": "Around",
    "No of Households": 250620,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 112779,
    "No of HHs for ad": 4511.16,
    "AD Revenue Per month": 1150.3458,
    "YEAR_1": 0,
    "YEAR_2": 13845.596214070258,
    "YEAR_3": 13874.016749432722,
    "YEAR_4": 13902.437284795189,
    "YEAR_5": 13930.857820157653,
    "YEAR_6": 13959.278355520117,
    "YEAR_7": 13987.69889088258,
    "YEAR_8": 14016.119426245044,
    "YEAR_9": 14044.539961607508,
    "YEAR_10": 14072.960496969972,
    "AD_Around": 125633.50519968105
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Around",
    "No of Households": 249140,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 112113,
    "No of HHs for ad": 4484.52,
    "AD Revenue Per month": 1143.5525999999998,
    "YEAR_1": 0,
    "YEAR_2": 13763.833057112217,
    "YEAR_3": 13792.085759132025,
    "YEAR_4": 13820.338461151836,
    "YEAR_5": 13848.591163171643,
    "YEAR_6": 13876.84386519145,
    "YEAR_7": 13905.096567211258,
    "YEAR_8": 13933.349269231063,
    "YEAR_9": 13961.601971250871,
    "YEAR_10": 13989.85467327068,
    "AD_Around": 124891.59478672306
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Around",
    "No of Households": 248110,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 111649.5,
    "No of HHs for ad": 4465.9800000000005,
    "AD Revenue Per month": 1138.8249,
    "YEAR_1": 0,
    "YEAR_2": 13706.93031949953,
    "YEAR_3": 13735.066218584923,
    "YEAR_4": 13763.202117670317,
    "YEAR_5": 13791.338016755708,
    "YEAR_6": 13819.4739158411,
    "YEAR_7": 13847.60981492649,
    "YEAR_8": 13875.745714011882,
    "YEAR_9": 13903.881613097274,
    "YEAR_10": 13932.017512182667,
    "AD_Around": 124375.26524256986
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Around",
    "No of Households": 247180,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 111231,
    "No of HHs for ad": 4449.24,
    "AD Revenue Per month": 1134.5562,
    "YEAR_1": 0,
    "YEAR_2": 13655.552119519141,
    "YEAR_3": 13683.582555760835,
    "YEAR_4": 13711.61299200253,
    "YEAR_5": 13739.643428244228,
    "YEAR_6": 13767.673864485922,
    "YEAR_7": 13795.704300727622,
    "YEAR_8": 13823.734736969314,
    "YEAR_9": 13851.765173211017,
    "YEAR_10": 13879.795609452707,
    "AD_Around": 123909.0647803733
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Around",
    "No of Households": 241200,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 108540,
    "No of HHs for ad": 4341.6,
    "AD Revenue Per month": 1107.108,
    "YEAR_1": 0,
    "YEAR_2": 13325.1847691076,
    "YEAR_3": 13352.53706792424,
    "YEAR_4": 13379.88936674088,
    "YEAR_5": 13407.241665557518,
    "YEAR_6": 13434.59396437416,
    "YEAR_7": 13461.9462631908,
    "YEAR_8": 13489.29856200744,
    "YEAR_9": 13516.65086082408,
    "YEAR_10": 13544.003159640717,
    "AD_Around": 120911.34567936743
  },
  {
    "DMA": "Lafayette, LA",
    "PlatformName": "Around",
    "No of Households": 240150,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 108067.5,
    "No of HHs for ad": 4322.7,
    "AD Revenue Per month": 1102.2885,
    "YEAR_1": 0,
    "YEAR_2": 13267.177123968451,
    "YEAR_3": 13294.410351832534,
    "YEAR_4": 13321.643579696613,
    "YEAR_5": 13348.87680756069,
    "YEAR_6": 13376.110035424772,
    "YEAR_7": 13403.34326328885,
    "YEAR_8": 13430.576491152931,
    "YEAR_9": 13457.809719017012,
    "YEAR_10": 13485.04294688109,
    "AD_Around": 120384.99031882294
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Around",
    "No of Households": 235290,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 105880.5,
    "No of HHs for ad": 4235.22,
    "AD Revenue Per month": 1079.9811,
    "YEAR_1": 0,
    "YEAR_2": 12998.68459503867,
    "YEAR_3": 13025.36669449376,
    "YEAR_4": 13052.048793948845,
    "YEAR_5": 13078.730893403932,
    "YEAR_6": 13105.412992859023,
    "YEAR_7": 13132.09509231411,
    "YEAR_8": 13158.777191769197,
    "YEAR_9": 13185.459291224284,
    "YEAR_10": 13212.141390679375,
    "AD_Around": 117948.7169357312
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Around",
    "No of Households": 232880,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 104796,
    "No of HHs for ad": 4191.84,
    "AD Revenue Per month": 1068.9192,
    "YEAR_1": 0,
    "YEAR_2": 12865.543238100241,
    "YEAR_3": 12891.952041368977,
    "YEAR_4": 12918.360844637711,
    "YEAR_5": 12944.769647906449,
    "YEAR_6": 12971.178451175185,
    "YEAR_7": 12997.587254443919,
    "YEAR_8": 13023.996057712657,
    "YEAR_9": 13050.404860981393,
    "YEAR_10": 13076.813664250129,
    "AD_Around": 116740.60606057667
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Around",
    "No of Households": 226710,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 102019.5,
    "No of HHs for ad": 4080.78,
    "AD Revenue Per month": 1040.5989,
    "YEAR_1": 0,
    "YEAR_2": 12524.679266187328,
    "YEAR_3": 12550.388385858641,
    "YEAR_4": 12576.097505529953,
    "YEAR_5": 12601.806625201265,
    "YEAR_6": 12627.515744872575,
    "YEAR_7": 12653.224864543889,
    "YEAR_8": 12678.9339842152,
    "YEAR_9": 12704.643103886516,
    "YEAR_10": 12730.352223557826,
    "AD_Around": 113647.64170385318
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Around",
    "No of Households": 219540,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 98793,
    "No of HHs for ad": 3951.7200000000003,
    "AD Revenue Per month": 1007.6886000000001,
    "YEAR_1": 0,
    "YEAR_2": 12128.569917951421,
    "YEAR_3": 12153.465953118108,
    "YEAR_4": 12178.361988284798,
    "YEAR_5": 12203.258023451484,
    "YEAR_6": 12228.154058618173,
    "YEAR_7": 12253.050093784863,
    "YEAR_8": 12277.946128951548,
    "YEAR_9": 12302.842164118236,
    "YEAR_10": 12327.738199284924,
    "AD_Around": 110053.38652756355
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Around",
    "No of Households": 208490,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 93820.5,
    "No of HHs for ad": 3752.82,
    "AD Revenue Per month": 956.9691000000001,
    "YEAR_1": 0,
    "YEAR_2": 11518.108509582271,
    "YEAR_3": 11541.7514647244,
    "YEAR_4": 11565.394419866529,
    "YEAR_5": 11589.037375008655,
    "YEAR_6": 11612.680330150783,
    "YEAR_7": 11636.323285292912,
    "YEAR_8": 11659.96624043504,
    "YEAR_9": 11683.609195577168,
    "YEAR_10": 11707.252150719296,
    "AD_Around": 104514.12297135706
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Around",
    "No of Households": 197080,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 88686,
    "No of HHs for ad": 3547.44,
    "AD Revenue Per month": 904.5972,
    "YEAR_1": 0,
    "YEAR_2": 10887.758765736839,
    "YEAR_3": 10910.107816527818,
    "YEAR_4": 10932.456867318791,
    "YEAR_5": 10954.805918109767,
    "YEAR_6": 10977.154968900746,
    "YEAR_7": 10999.504019691722,
    "YEAR_8": 11021.853070482695,
    "YEAR_9": 11044.202121273673,
    "YEAR_10": 11066.55117206465,
    "AD_Around": 98794.39472010669
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Around",
    "No of Households": 188980,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 85041,
    "No of HHs for ad": 3401.64,
    "AD Revenue Per month": 867.4182,
    "YEAR_1": 0,
    "YEAR_2": 10440.271217520542,
    "YEAR_3": 10461.701720963196,
    "YEAR_4": 10483.132224405852,
    "YEAR_5": 10504.562727848506,
    "YEAR_6": 10525.993231291164,
    "YEAR_7": 10547.423734733817,
    "YEAR_8": 10568.854238176476,
    "YEAR_9": 10590.284741619133,
    "YEAR_10": 10611.715245061787,
    "AD_Around": 94733.93908162047
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Around",
    "No of Households": 188900,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 85005,
    "No of HHs for ad": 3400.2000000000003,
    "AD Revenue Per month": 867.051,
    "YEAR_1": 0,
    "YEAR_2": 10435.851587414701,
    "YEAR_3": 10457.273018784781,
    "YEAR_4": 10478.694450154862,
    "YEAR_5": 10500.11588152494,
    "YEAR_6": 10521.53731289502,
    "YEAR_7": 10542.958744265103,
    "YEAR_8": 10564.38017563518,
    "YEAR_9": 10585.80160700526,
    "YEAR_10": 10607.223038375343,
    "AD_Around": 94693.83581605519
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Around",
    "No of Households": 188520,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 84834,
    "No of HHs for ad": 3393.36,
    "AD Revenue Per month": 865.3068000000001,
    "YEAR_1": 0,
    "YEAR_2": 10414.85834441196,
    "YEAR_3": 10436.236683437304,
    "YEAR_4": 10457.615022462649,
    "YEAR_5": 10478.993361487994,
    "YEAR_6": 10500.371700513337,
    "YEAR_7": 10521.75003953868,
    "YEAR_8": 10543.128378564026,
    "YEAR_9": 10564.50671758937,
    "YEAR_10": 10585.885056614712,
    "AD_Around": 94503.34530462003
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Around",
    "No of Households": 185470,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 83461.5,
    "No of HHs for ad": 3338.46,
    "AD Revenue Per month": 851.3072999999999,
    "YEAR_1": 0,
    "YEAR_2": 10246.359946626808,
    "YEAR_3": 10267.392412885194,
    "YEAR_4": 10288.424879143578,
    "YEAR_5": 10309.457345401963,
    "YEAR_6": 10330.489811660344,
    "YEAR_7": 10351.52227791873,
    "YEAR_8": 10372.554744177114,
    "YEAR_9": 10393.587210435497,
    "YEAR_10": 10414.619676693881,
    "AD_Around": 92974.4083049431
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Around",
    "No of Households": 183100,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 82395,
    "No of HHs for ad": 3295.8,
    "AD Revenue Per month": 840.4290000000001,
    "YEAR_1": 0,
    "YEAR_2": 10115.4284047413,
    "YEAR_3": 10136.192110849619,
    "YEAR_4": 10156.955816957943,
    "YEAR_5": 10177.71952306626,
    "YEAR_6": 10198.48322917458,
    "YEAR_7": 10219.2469352829,
    "YEAR_8": 10240.010641391224,
    "YEAR_9": 10260.774347499542,
    "YEAR_10": 10281.538053607861,
    "AD_Around": 91786.34906257121
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Around",
    "No of Households": 181720,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 81774,
    "No of HHs for ad": 3270.96,
    "AD Revenue Per month": 834.0948,
    "YEAR_1": 0,
    "YEAR_2": 10039.18978541556,
    "YEAR_3": 10059.796998271944,
    "YEAR_4": 10080.404211128327,
    "YEAR_5": 10101.011423984713,
    "YEAR_6": 10121.618636841094,
    "YEAR_7": 10142.22584969748,
    "YEAR_8": 10162.833062553864,
    "YEAR_9": 10183.440275410248,
    "YEAR_10": 10204.047488266633,
    "AD_Around": 91094.56773156986
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Around",
    "No of Households": 181440,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 81648,
    "No of HHs for ad": 3265.92,
    "AD Revenue Per month": 832.8095999999999,
    "YEAR_1": 0,
    "YEAR_2": 10023.72108004512,
    "YEAR_3": 10044.296540647485,
    "YEAR_4": 10064.872001249856,
    "YEAR_5": 10085.447461852225,
    "YEAR_6": 10106.02292245459,
    "YEAR_7": 10126.59838305696,
    "YEAR_8": 10147.173843659326,
    "YEAR_9": 10167.749304261695,
    "YEAR_10": 10188.324764864064,
    "AD_Around": 90954.20630209133
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Around",
    "No of Households": 174760,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 78642,
    "No of HHs for ad": 3145.6800000000003,
    "AD Revenue Per month": 802.1483999999999,
    "YEAR_1": 0,
    "YEAR_2": 9654.68196620748,
    "YEAR_3": 9674.49990874975,
    "YEAR_4": 9694.317851292022,
    "YEAR_5": 9714.135793834295,
    "YEAR_6": 9733.953736376567,
    "YEAR_7": 9753.771678918838,
    "YEAR_8": 9773.58962146111,
    "YEAR_9": 9793.407564003383,
    "YEAR_10": 9813.225506545656,
    "AD_Around": 87605.58362738912
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Around",
    "No of Households": 173940,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 78273,
    "No of HHs for ad": 3130.92,
    "AD Revenue Per month": 798.3846,
    "YEAR_1": 0,
    "YEAR_2": 9609.38075762262,
    "YEAR_3": 9629.105711420987,
    "YEAR_4": 9648.830665219355,
    "YEAR_5": 9668.555619017723,
    "YEAR_6": 9688.280572816093,
    "YEAR_7": 9708.005526614459,
    "YEAR_8": 9727.730480412827,
    "YEAR_9": 9747.455434211195,
    "YEAR_10": 9767.180388009565,
    "AD_Around": 87194.52515534483
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Around",
    "No of Households": 173370,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 78016.5,
    "No of HHs for ad": 3120.66,
    "AD Revenue Per month": 795.7683,
    "YEAR_1": 0,
    "YEAR_2": 9577.890893118509,
    "YEAR_3": 9597.551208399773,
    "YEAR_4": 9617.211523681037,
    "YEAR_5": 9636.871838962303,
    "YEAR_6": 9656.532154243565,
    "YEAR_7": 9676.192469524829,
    "YEAR_8": 9695.852784806093,
    "YEAR_9": 9715.513100087357,
    "YEAR_10": 9735.173415368621,
    "AD_Around": 86908.78938819209
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Around",
    "No of Households": 171450,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 77152.5,
    "No of HHs for ad": 3086.1,
    "AD Revenue Per month": 786.9555,
    "YEAR_1": 0,
    "YEAR_2": 9471.81977057835,
    "YEAR_3": 9491.262356117792,
    "YEAR_4": 9510.704941657232,
    "YEAR_5": 9530.14752719667,
    "YEAR_6": 9549.59011273611,
    "YEAR_7": 9569.03269827555,
    "YEAR_8": 9588.47528381499,
    "YEAR_9": 9607.917869354429,
    "YEAR_10": 9627.360454893871,
    "AD_Around": 85946.311014625
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Around",
    "No of Households": 168960,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 76032,
    "No of HHs for ad": 3041.28,
    "AD Revenue Per month": 775.5264,
    "YEAR_1": 0,
    "YEAR_2": 9334.25878353408,
    "YEAR_3": 9353.41900081459,
    "YEAR_4": 9372.579218095105,
    "YEAR_5": 9391.739435375615,
    "YEAR_6": 9410.899652656128,
    "YEAR_7": 9430.05986993664,
    "YEAR_8": 9449.22008721715,
    "YEAR_9": 9468.380304497663,
    "YEAR_10": 9487.540521778175,
    "AD_Around": 84698.09687390516
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Around",
    "No of Households": 168210,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 75694.5,
    "No of HHs for ad": 3027.78,
    "AD Revenue Per month": 772.0839000000001,
    "YEAR_1": 0,
    "YEAR_2": 9292.824751291831,
    "YEAR_3": 9311.899917891946,
    "YEAR_4": 9330.975084492053,
    "YEAR_5": 9350.050251092167,
    "YEAR_6": 9369.125417692278,
    "YEAR_7": 9388.20058429239,
    "YEAR_8": 9407.275750892504,
    "YEAR_9": 9426.350917492615,
    "YEAR_10": 9445.426084092725,
    "AD_Around": 84322.12875923052
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Around",
    "No of Households": 166190,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 74785.5,
    "No of HHs for ad": 2991.42,
    "AD Revenue Per month": 762.8121,
    "YEAR_1": 0,
    "YEAR_2": 9181.229091119369,
    "YEAR_3": 9200.075187886938,
    "YEAR_4": 9218.921284654505,
    "YEAR_5": 9237.767381422074,
    "YEAR_6": 9256.613478189642,
    "YEAR_7": 9275.459574957209,
    "YEAR_8": 9294.305671724778,
    "YEAR_9": 9313.151768492346,
    "YEAR_10": 9331.997865259915,
    "AD_Around": 83309.52130370677
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Around",
    "No of Households": 161210,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 72544.5,
    "No of HHs for ad": 2901.78,
    "AD Revenue Per month": 739.9538999999999,
    "YEAR_1": 0,
    "YEAR_2": 8906.10711703083,
    "YEAR_3": 8924.388477280541,
    "YEAR_4": 8942.669837530253,
    "YEAR_5": 8960.951197779965,
    "YEAR_6": 8979.232558029677,
    "YEAR_7": 8997.513918279388,
    "YEAR_8": 9015.7952785291,
    "YEAR_9": 9034.076638778812,
    "YEAR_10": 9052.357999028523,
    "AD_Around": 80813.09302226709
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Around",
    "No of Households": 160200,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 72090,
    "No of HHs for ad": 2883.6,
    "AD Revenue Per month": 735.318,
    "YEAR_1": 0,
    "YEAR_2": 8850.309286944599,
    "YEAR_3": 8868.47611227804,
    "YEAR_4": 8886.64293761148,
    "YEAR_5": 8904.80976294492,
    "YEAR_6": 8922.97658827836,
    "YEAR_7": 8941.1434136118,
    "YEAR_8": 8959.310238945242,
    "YEAR_9": 8977.477064278679,
    "YEAR_10": 8995.64388961212,
    "AD_Around": 80306.78929450524
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Around",
    "No of Households": 155590,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 70015.5,
    "No of HHs for ad": 2800.62,
    "AD Revenue Per month": 714.1581,
    "YEAR_1": 0,
    "YEAR_2": 8595.628102095568,
    "YEAR_3": 8613.272149246817,
    "YEAR_4": 8630.916196398066,
    "YEAR_5": 8648.560243549315,
    "YEAR_6": 8666.204290700562,
    "YEAR_7": 8683.84833785181,
    "YEAR_8": 8701.49238500306,
    "YEAR_9": 8719.136432154306,
    "YEAR_10": 8736.780479305553,
    "AD_Around": 77995.83861630506
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Around",
    "No of Households": 155440,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 69948,
    "No of HHs for ad": 2797.92,
    "AD Revenue Per month": 713.4696,
    "YEAR_1": 0,
    "YEAR_2": 8587.34129564712,
    "YEAR_3": 8604.968332662289,
    "YEAR_4": 8622.595369677456,
    "YEAR_5": 8640.222406692625,
    "YEAR_6": 8657.849443707792,
    "YEAR_7": 8675.47648072296,
    "YEAR_8": 8693.103517738129,
    "YEAR_9": 8710.730554753296,
    "YEAR_10": 8728.357591768465,
    "AD_Around": 77920.64499337012
  },
  {
    "DMA": "Erie",
    "PlatformName": "Around",
    "No of Households": 152420,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 68589,
    "No of HHs for ad": 2743.56,
    "AD Revenue Per month": 699.6077999999999,
    "YEAR_1": 0,
    "YEAR_2": 8420.50025915166,
    "YEAR_3": 8437.784825427083,
    "YEAR_4": 8455.069391702506,
    "YEAR_5": 8472.35395797793,
    "YEAR_6": 8489.638524253354,
    "YEAR_7": 8506.923090528779,
    "YEAR_8": 8524.207656804203,
    "YEAR_9": 8541.492223079626,
    "YEAR_10": 8558.776789355048,
    "AD_Around": 76406.74671828018
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Around",
    "No of Households": 152190,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 68485.5,
    "No of HHs for ad": 2739.42,
    "AD Revenue Per month": 698.5521,
    "YEAR_1": 0,
    "YEAR_2": 8407.793822597368,
    "YEAR_3": 8425.052306664138,
    "YEAR_4": 8442.310790730908,
    "YEAR_5": 8459.569274797674,
    "YEAR_6": 8476.827758864443,
    "YEAR_7": 8494.086242931211,
    "YEAR_8": 8511.344726997979,
    "YEAR_9": 8528.603211064747,
    "YEAR_10": 8545.861695131514,
    "AD_Around": 76291.44982977999
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Around",
    "No of Households": 151160,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 68022,
    "No of HHs for ad": 2720.88,
    "AD Revenue Per month": 693.8244,
    "YEAR_1": 0,
    "YEAR_2": 8350.89108498468,
    "YEAR_3": 8368.032766117032,
    "YEAR_4": 8385.174447249383,
    "YEAR_5": 8402.316128381735,
    "YEAR_6": 8419.457809514088,
    "YEAR_7": 8436.59949064644,
    "YEAR_8": 8453.74117177879,
    "YEAR_9": 8470.882852911143,
    "YEAR_10": 8488.024534043496,
    "AD_Around": 75775.12028562678
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Around",
    "No of Households": 150700,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 67815,
    "No of HHs for ad": 2712.6,
    "AD Revenue Per month": 691.713,
    "YEAR_1": 0,
    "YEAR_2": 8325.4782118761,
    "YEAR_3": 8342.567728591139,
    "YEAR_4": 8359.65724530618,
    "YEAR_5": 8376.74676202122,
    "YEAR_6": 8393.836278736258,
    "YEAR_7": 8410.9257954513,
    "YEAR_8": 8428.015312166339,
    "YEAR_9": 8445.10482888138,
    "YEAR_10": 8462.194345596421,
    "AD_Around": 75544.52650862634
  },
  {
    "DMA": "Albany, GA",
    "PlatformName": "Around",
    "No of Households": 144570,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 65056.5,
    "No of HHs for ad": 2602.26,
    "AD Revenue Per month": 663.5763000000001,
    "YEAR_1": 0,
    "YEAR_2": 7986.824055016113,
    "YEAR_3": 8003.218424170016,
    "YEAR_4": 8019.6127933239195,
    "YEAR_5": 8036.007162477823,
    "YEAR_6": 8052.401531631725,
    "YEAR_7": 8068.795900785629,
    "YEAR_8": 8085.190269939534,
    "YEAR_9": 8101.584639093439,
    "YEAR_10": 8117.979008247344,
    "AD_Around": 72471.61378468554
  },
  {
    "DMA": "Idaho Falls-Pocatello-Jackson",
    "PlatformName": "Around",
    "No of Households": 142930,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 64318.5,
    "No of HHs for ad": 2572.7400000000002,
    "AD Revenue Per month": 656.0487,
    "YEAR_1": 0,
    "YEAR_2": 7896.22163784639,
    "YEAR_3": 7912.430029512487,
    "YEAR_4": 7928.638421178582,
    "YEAR_5": 7944.846812844678,
    "YEAR_6": 7961.055204510775,
    "YEAR_7": 7977.26359617687,
    "YEAR_8": 7993.471987842967,
    "YEAR_9": 8009.680379509062,
    "YEAR_10": 8025.888771175159,
    "AD_Around": 71649.49684059697
  },
  {
    "DMA": "Bangor",
    "PlatformName": "Around",
    "No of Households": 142180,
    "% No of Smart Households": 0.45,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "No of Smart Households": 63981,
    "No of HHs for ad": 2559.2400000000002,
    "AD Revenue Per month": 652.6062000000001,
    "YEAR_1": 0,
    "YEAR_2": 7854.7876056041405,
    "YEAR_3": 7870.910946589836,
    "YEAR_4": 7887.034287575533,
    "YEAR_5": 7903.157628561229,
    "YEAR_6": 7919.280969546924,
    "YEAR_7": 7935.40431053262,
    "YEAR_8": 7951.527651518316,
    "YEAR_9": 7967.650992504013,
    "YEAR_10": 7983.774333489709,
    "AD_Around": 71273.52872592231
  },
  {
    "DMA": "New York",
    "PlatformName": "Around",
    "No of Households": 7726580,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 3476961,
    "No of Households for Subscription": 3476.9610000000002,
    "Subscription Revenue per month": 17384.805,
    "YEAR_1": 104460.94826068633,
    "YEAR_2": 209244.0293087085,
    "YEAR_3": 209673.53969182295,
    "YEAR_4": 210103.0500749373,
    "YEAR_5": 210532.56045805174,
    "YEAR_6": 210962.07084116607,
    "YEAR_7": 211391.58122428053,
    "YEAR_8": 211821.09160739486,
    "YEAR_9": 212250.6019905093,
    "YEAR_10": 212680.11237362365,
    "SUBSCRIPTION_Around": 2003119.5858311811
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Around",
    "No of Households": 5838090,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 2627140.5,
    "No of Households for Subscription": 2627.1405,
    "Subscription Revenue per month": 13135.7025,
    "YEAR_1": 78929.15331637417,
    "YEAR_2": 158101.70542036425,
    "YEAR_3": 158426.23713718547,
    "YEAR_4": 158750.76885400663,
    "YEAR_5": 159075.30057082785,
    "YEAR_6": 159399.83228764904,
    "YEAR_7": 159724.36400447023,
    "YEAR_8": 160048.89572129145,
    "YEAR_9": 160373.42743811262,
    "YEAR_10": 160697.95915493387,
    "SUBSCRIPTION_Around": 1513527.6439052157
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Around",
    "No of Households": 3624820,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 1631169,
    "No of Households for Subscription": 1631.169,
    "Subscription Revenue per month": 8155.845,
    "YEAR_1": 49006.43421465915,
    "YEAR_2": 98163.99264859651,
    "YEAR_3": 98365.49160763409,
    "YEAR_4": 98566.99056667174,
    "YEAR_5": 98768.4895257093,
    "YEAR_6": 98969.98848474688,
    "YEAR_7": 99171.48744378451,
    "YEAR_8": 99372.98640282209,
    "YEAR_9": 99574.48536185971,
    "YEAR_10": 99775.9843208973,
    "SUBSCRIPTION_Around": 939736.3305773812
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Around",
    "No of Households": 3108960,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 1399032,
    "No of Households for Subscription": 1399.032,
    "Subscription Revenue per month": 6995.16,
    "YEAR_1": 42032.168139661204,
    "YEAR_2": 84193.95351625199,
    "YEAR_3": 84366.7764988248,
    "YEAR_4": 84539.5994813976,
    "YEAR_5": 84712.42246397039,
    "YEAR_6": 84885.2454465432,
    "YEAR_7": 85058.06842911599,
    "YEAR_8": 85230.8914116888,
    "YEAR_9": 85403.71439426161,
    "YEAR_10": 85576.5373768344,
    "SUBSCRIPTION_Around": 805999.3771585498
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Around",
    "No of Households": 3041540,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 1368693,
    "No of Households for Subscription": 1368.693,
    "Subscription Revenue per month": 6843.465,
    "YEAR_1": 41120.670797792554,
    "YEAR_2": 82368.1479909105,
    "YEAR_3": 82537.22318467771,
    "YEAR_4": 82706.2983784449,
    "YEAR_5": 82875.37357221209,
    "YEAR_6": 83044.44876597931,
    "YEAR_7": 83213.5239597465,
    "YEAR_8": 83382.5991535137,
    "YEAR_9": 83551.6743472809,
    "YEAR_10": 83720.7495410481,
    "SUBSCRIPTION_Around": 788520.7096916062
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Around",
    "No of Households": 2679850,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 1205932.5,
    "No of Households for Subscription": 1205.9325000000001,
    "Subscription Revenue per month": 6029.6625,
    "YEAR_1": 36230.73496895138,
    "YEAR_2": 72573.19693097626,
    "YEAR_3": 72722.16625507426,
    "YEAR_4": 72871.13557917225,
    "YEAR_5": 73020.10490327024,
    "YEAR_6": 73169.07422736826,
    "YEAR_7": 73318.04355146625,
    "YEAR_8": 73467.01287556425,
    "YEAR_9": 73615.98219966226,
    "YEAR_10": 73764.95152376025,
    "SUBSCRIPTION_Around": 694752.4030152656
  },
  {
    "DMA": "Houston",
    "PlatformName": "Around",
    "No of Households": 2666330,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 1199848.5,
    "No of Households for Subscription": 1199.8485,
    "Subscription Revenue per month": 5999.2425,
    "YEAR_1": 36047.948791821975,
    "YEAR_2": 72207.06090750225,
    "YEAR_3": 72355.27867264664,
    "YEAR_4": 72503.49643779107,
    "YEAR_5": 72651.71420293544,
    "YEAR_6": 72799.93196807985,
    "YEAR_7": 72948.14973322424,
    "YEAR_8": 73096.36749836867,
    "YEAR_9": 73244.58526351306,
    "YEAR_10": 73392.80302865745,
    "SUBSCRIPTION_Around": 691247.3365045406
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Around",
    "No of Households": 2617350,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 1177807.5,
    "No of Households for Subscription": 1177.8075000000001,
    "Subscription Revenue per month": 5889.0375,
    "YEAR_1": 35385.75449035762,
    "YEAR_2": 70880.63025441376,
    "YEAR_3": 71026.12528601175,
    "YEAR_4": 71171.62031760976,
    "YEAR_5": 71317.11534920776,
    "YEAR_6": 71462.61038080575,
    "YEAR_7": 71608.10541240375,
    "YEAR_8": 71753.60044400176,
    "YEAR_9": 71899.09547559975,
    "YEAR_10": 72044.59050719775,
    "SUBSCRIPTION_Around": 678549.2479176094
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Around",
    "No of Households": 2596190,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 1168285.5,
    "No of Households for Subscription": 1168.2855,
    "Subscription Revenue per month": 5841.4275,
    "YEAR_1": 35099.677899524926,
    "YEAR_2": 70307.59488039675,
    "YEAR_3": 70451.91365552595,
    "YEAR_4": 70596.23243065515,
    "YEAR_5": 70740.55120578434,
    "YEAR_6": 70884.86998091356,
    "YEAR_7": 71029.18875604276,
    "YEAR_8": 71173.50753117196,
    "YEAR_9": 71317.82630630115,
    "YEAR_10": 71462.14508143035,
    "SUBSCRIPTION_Around": 673063.507727747
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Around",
    "No of Households": 2593210,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 1166944.5,
    "No of Households for Subscription": 1166.9445,
    "Subscription Revenue per month": 5834.7225,
    "YEAR_1": 35059.389230305576,
    "YEAR_2": 70226.89330125826,
    "YEAR_3": 70371.04642212106,
    "YEAR_4": 70515.19954298384,
    "YEAR_5": 70659.35266384664,
    "YEAR_6": 70803.50578470943,
    "YEAR_7": 70947.65890557226,
    "YEAR_8": 71091.81202643506,
    "YEAR_9": 71235.96514729786,
    "YEAR_10": 71380.11826816064,
    "SUBSCRIPTION_Around": 672290.9412926906
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Around",
    "No of Households": 2138870,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 962491.5,
    "No of Households for Subscription": 962.4915,
    "Subscription Revenue per month": 4812.4574999999995,
    "YEAR_1": 28916.854339997022,
    "YEAR_2": 57922.88139998773,
    "YEAR_3": 58041.77835997934,
    "YEAR_4": 58160.67531997095,
    "YEAR_5": 58279.57227996254,
    "YEAR_6": 58398.469239954145,
    "YEAR_7": 58517.366199945754,
    "YEAR_8": 58636.26315993734,
    "YEAR_9": 58755.16011992894,
    "YEAR_10": 58874.05707992055,
    "SUBSCRIPTION_Around": 554503.0774995843
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Around",
    "No of Households": 2116440,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 952398,
    "No of Households for Subscription": 952.398,
    "Subscription Revenue per month": 4761.99,
    "YEAR_1": 28613.607745839297,
    "YEAR_2": 57315.453071102995,
    "YEAR_3": 57433.1031770022,
    "YEAR_4": 57550.753282901394,
    "YEAR_5": 57668.403388800594,
    "YEAR_6": 57786.0534946998,
    "YEAR_7": 57903.703600598994,
    "YEAR_8": 58021.35370649821,
    "YEAR_9": 58139.0038123974,
    "YEAR_10": 58256.65391829659,
    "SUBSCRIPTION_Around": 548688.0891981375
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Around",
    "No of Households": 2068720,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 930924,
    "No of Households for Subscription": 930.924,
    "Subscription Revenue per month": 4654.62,
    "YEAR_1": 27968.4482508234,
    "YEAR_2": 56023.144562213995,
    "YEAR_3": 56138.141976303596,
    "YEAR_4": 56253.139390393204,
    "YEAR_5": 56368.13680448281,
    "YEAR_6": 56483.1342185724,
    "YEAR_7": 56598.131632662,
    "YEAR_8": 56713.1290467516,
    "YEAR_9": 56828.1264608412,
    "YEAR_10": 56943.12387493081,
    "SUBSCRIPTION_Around": 536316.656217975
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Around",
    "No of Households": 1937250,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 871762.5,
    "No of Households for Subscription": 871.7625,
    "Subscription Revenue per month": 4358.8125,
    "YEAR_1": 26191.014914491876,
    "YEAR_2": 52462.79670673126,
    "YEAR_3": 52570.48587706125,
    "YEAR_4": 52678.175047391254,
    "YEAR_5": 52785.86421772125,
    "YEAR_6": 52893.553388051245,
    "YEAR_7": 53001.24255838125,
    "YEAR_8": 53108.93172871124,
    "YEAR_9": 53216.62089904125,
    "YEAR_10": 53324.31006937125,
    "SUBSCRIPTION_Around": 502232.9954069531
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Around",
    "No of Households": 1839480,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 827766,
    "No of Households for Subscription": 827.766,
    "Subscription Revenue per month": 4138.83,
    "YEAR_1": 24869.1950522181,
    "YEAR_2": 49815.080803251,
    "YEAR_3": 49917.3350683374,
    "YEAR_4": 50019.589333423806,
    "YEAR_5": 50121.84359851021,
    "YEAR_6": 50224.0978635966,
    "YEAR_7": 50326.35212868301,
    "YEAR_8": 50428.606393769405,
    "YEAR_9": 50530.8606588558,
    "YEAR_10": 50633.11492394219,
    "SUBSCRIPTION_Around": 476886.0758245875
  },
  {
    "DMA": "Denver",
    "PlatformName": "Around",
    "No of Households": 1792540,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 806643,
    "No of Households for Subscription": 806.643,
    "Subscription Revenue per month": 4033.215,
    "YEAR_1": 24234.58091357505,
    "YEAR_2": 48543.8955264855,
    "YEAR_3": 48643.54045893271,
    "YEAR_4": 48743.1853913799,
    "YEAR_5": 48842.830323827104,
    "YEAR_6": 48942.47525627431,
    "YEAR_7": 49042.1201887215,
    "YEAR_8": 49141.765121168704,
    "YEAR_9": 49241.4100536159,
    "YEAR_10": 49341.05498606311,
    "SUBSCRIPTION_Around": 464716.8582200438
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Around",
    "No of Households": 1775140,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 798813,
    "No of Households for Subscription": 798.813,
    "Subscription Revenue per month": 3994.065,
    "YEAR_1": 23999.33834833455,
    "YEAR_2": 48072.6849637305,
    "YEAR_3": 48171.3626531457,
    "YEAR_4": 48270.040342560904,
    "YEAR_5": 48368.718031976096,
    "YEAR_6": 48467.3957213913,
    "YEAR_7": 48566.0734108065,
    "YEAR_8": 48664.7511002217,
    "YEAR_9": 48763.4287896369,
    "YEAR_10": 48862.106479052105,
    "SUBSCRIPTION_Around": 460205.8998408562
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Around",
    "No of Households": 1720970,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 774436.5,
    "No of Households for Subscription": 774.4365,
    "Subscription Revenue per month": 3872.1825,
    "YEAR_1": 23266.976867927773,
    "YEAR_2": 46605.703573820254,
    "YEAR_3": 46701.37002443985,
    "YEAR_4": 46797.036475059445,
    "YEAR_5": 46892.70292567905,
    "YEAR_6": 46988.36937629865,
    "YEAR_7": 47084.03582691825,
    "YEAR_8": 47179.70227753785,
    "YEAR_9": 47275.36872815745,
    "YEAR_10": 47371.03517877705,
    "SUBSCRIPTION_Around": 446162.30125461565
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Around",
    "No of Households": 1552420,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 698589,
    "No of Households for Subscription": 698.589,
    "Subscription Revenue per month": 3492.945,
    "YEAR_1": 20988.23351325615,
    "YEAR_2": 42041.1897604665,
    "YEAR_3": 42127.486739072105,
    "YEAR_4": 42213.7837176777,
    "YEAR_5": 42300.08069628331,
    "YEAR_6": 42386.3776748889,
    "YEAR_7": 42472.674653494505,
    "YEAR_8": 42558.9716321001,
    "YEAR_9": 42645.2686107057,
    "YEAR_10": 42731.5655893113,
    "SUBSCRIPTION_Around": 402465.63258725626
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Around",
    "No of Households": 1502080,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 675936,
    "No of Households for Subscription": 675.936,
    "Subscription Revenue per month": 3379.6800000000003,
    "YEAR_1": 20307.652436577602,
    "YEAR_2": 40677.92885649599,
    "YEAR_3": 40761.42750095041,
    "YEAR_4": 40844.9261454048,
    "YEAR_5": 40928.4247898592,
    "YEAR_6": 41011.9234343136,
    "YEAR_7": 41095.42207876801,
    "YEAR_8": 41178.9207232224,
    "YEAR_9": 41262.4193676768,
    "YEAR_10": 41345.91801213121,
    "SUBSCRIPTION_Around": 389414.96334539994
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Around",
    "No of Households": 1323400,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 595530,
    "No of Households for Subscription": 595.53,
    "Subscription Revenue per month": 2977.6499999999996,
    "YEAR_1": 17891.954645935497,
    "YEAR_2": 35839.083836205,
    "YEAR_3": 35912.649895316994,
    "YEAR_4": 35986.215954429,
    "YEAR_5": 36059.78201354099,
    "YEAR_6": 36133.348072652996,
    "YEAR_7": 36206.914131765,
    "YEAR_8": 36280.480190876995,
    "YEAR_9": 36354.046249989,
    "YEAR_10": 36427.612309101,
    "SUBSCRIPTION_Around": 343092.08729981247
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Around",
    "No of Households": 1293400,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 582030,
    "No of Households for Subscription": 582.03,
    "Subscription Revenue per month": 2910.1499999999996,
    "YEAR_1": 17486.3640162105,
    "YEAR_2": 35026.651831455,
    "YEAR_3": 35098.55023016699,
    "YEAR_4": 35170.448628879,
    "YEAR_5": 35242.34702759099,
    "YEAR_6": 35314.24542630299,
    "YEAR_7": 35386.143825015,
    "YEAR_8": 35458.042223726996,
    "YEAR_9": 35529.94062243899,
    "YEAR_10": 35601.839021151,
    "SUBSCRIPTION_Around": 335314.57285293745
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Around",
    "No of Households": 1289510,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 580279.5,
    "No of Households for Subscription": 580.2795,
    "Subscription Revenue per month": 2901.3975,
    "YEAR_1": 17433.772431222824,
    "YEAR_2": 34921.30648150575,
    "YEAR_3": 34992.988640252544,
    "YEAR_4": 35064.67079899935,
    "YEAR_5": 35136.352957746145,
    "YEAR_6": 35208.03511649295,
    "YEAR_7": 35279.71727523975,
    "YEAR_8": 35351.39943398655,
    "YEAR_9": 35423.081592733346,
    "YEAR_10": 35494.763751480146,
    "SUBSCRIPTION_Around": 334306.08847965935
  },
  {
    "DMA": "Saint Louis",
    "PlatformName": "Around",
    "No of Households": 1255160,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 564822,
    "No of Households for Subscription": 564.822,
    "Subscription Revenue per month": 2824.11,
    "YEAR_1": 16969.3711601877,
    "YEAR_2": 33991.071836067,
    "YEAR_3": 34060.844523655804,
    "YEAR_4": 34130.6172112446,
    "YEAR_5": 34200.389898833404,
    "YEAR_6": 34270.1625864222,
    "YEAR_7": 34339.935274011004,
    "YEAR_8": 34409.7079615998,
    "YEAR_9": 34479.480649188605,
    "YEAR_10": 34549.2533367774,
    "SUBSCRIPTION_Around": 325400.83443798753
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Around",
    "No of Households": 1207280,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 543276,
    "No of Households for Subscription": 543.2760000000001,
    "Subscription Revenue per month": 2716.38,
    "YEAR_1": 16322.048515146602,
    "YEAR_2": 32694.430356486002,
    "YEAR_3": 32761.541458076397,
    "YEAR_4": 32828.6525596668,
    "YEAR_5": 32895.7636612572,
    "YEAR_6": 32962.874762847605,
    "YEAR_7": 33029.985864438,
    "YEAR_8": 33097.09696602841,
    "YEAR_9": 33164.208067618805,
    "YEAR_10": 33231.3191692092,
    "SUBSCRIPTION_Around": 312987.92138077505
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Around",
    "No of Households": 1174940,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 528723,
    "No of Households for Subscription": 528.723,
    "Subscription Revenue per month": 2643.615,
    "YEAR_1": 15884.821816303049,
    "YEAR_2": 31818.628655365497,
    "YEAR_3": 31883.9420190447,
    "YEAR_4": 31949.255382723895,
    "YEAR_5": 32014.5687464031,
    "YEAR_6": 32079.882110082297,
    "YEAR_7": 32145.195473761494,
    "YEAR_8": 32210.5088374407,
    "YEAR_9": 32275.822201119896,
    "YEAR_10": 32341.135564799097,
    "SUBSCRIPTION_Around": 304603.7608070437
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Around",
    "No of Households": 1168540,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 525843,
    "No of Households for Subscription": 525.843,
    "Subscription Revenue per month": 2629.2149999999997,
    "YEAR_1": 15798.295815295049,
    "YEAR_2": 31645.309827685494,
    "YEAR_3": 31710.267423812693,
    "YEAR_4": 31775.225019939895,
    "YEAR_5": 31840.182616067093,
    "YEAR_6": 31905.140212194296,
    "YEAR_7": 31970.097808321494,
    "YEAR_8": 32035.055404448693,
    "YEAR_9": 32100.013000575895,
    "YEAR_10": 32164.970596703093,
    "SUBSCRIPTION_Around": 302944.55772504373
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Around",
    "No of Households": 1149480,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 517266,
    "No of Households for Subscription": 517.266,
    "Subscription Revenue per month": 2586.33,
    "YEAR_1": 15540.6105685431,
    "YEAR_2": 31129.144694000996,
    "YEAR_3": 31193.0427698874,
    "YEAR_4": 31256.94084577379,
    "YEAR_5": 31320.838921660197,
    "YEAR_6": 31384.7369975466,
    "YEAR_7": 31448.635073432997,
    "YEAR_8": 31512.533149319395,
    "YEAR_9": 31576.431225205797,
    "YEAR_10": 31640.329301092203,
    "SUBSCRIPTION_Around": 298003.24354646244
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Around",
    "No of Households": 1148120,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 516654,
    "No of Households for Subscription": 516.654,
    "Subscription Revenue per month": 2583.27,
    "YEAR_1": 15522.2237933289,
    "YEAR_2": 31092.314443118998,
    "YEAR_3": 31156.136918400596,
    "YEAR_4": 31219.9593936822,
    "YEAR_5": 31283.7818689638,
    "YEAR_6": 31347.604344245403,
    "YEAR_7": 31411.426819527005,
    "YEAR_8": 31475.2492948086,
    "YEAR_9": 31539.0717700902,
    "YEAR_10": 31602.894245371797,
    "SUBSCRIPTION_Around": 297650.6628915375
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Around",
    "No of Households": 1107010,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 498154.5,
    "No of Households for Subscription": 498.1545,
    "Subscription Revenue per month": 2490.7725,
    "YEAR_1": 14966.429433729074,
    "YEAR_2": 29979.01178594325,
    "YEAR_3": 30040.549010590046,
    "YEAR_4": 30102.086235236853,
    "YEAR_5": 30163.623459883653,
    "YEAR_6": 30225.16068453045,
    "YEAR_7": 30286.697909177245,
    "YEAR_8": 30348.23513382405,
    "YEAR_9": 30409.772358470855,
    "YEAR_10": 30471.30958311765,
    "SUBSCRIPTION_Around": 286992.87559450313
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Around",
    "No of Households": 1059540,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 476793,
    "No of Households for Subscription": 476.793,
    "Subscription Revenue per month": 2383.965,
    "YEAR_1": 14324.649860627553,
    "YEAR_2": 28693.4735437605,
    "YEAR_3": 28752.371973767702,
    "YEAR_4": 28811.2704037749,
    "YEAR_5": 28870.168833782103,
    "YEAR_6": 28929.0672637893,
    "YEAR_7": 28987.965693796505,
    "YEAR_8": 29046.8641238037,
    "YEAR_9": 29105.762553810902,
    "YEAR_10": 29164.660983818103,
    "SUBSCRIPTION_Around": 274686.25523473124
  },
  {
    "DMA": "Columbus, OH",
    "PlatformName": "Around",
    "No of Households": 1023600,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 460620,
    "No of Households for Subscription": 460.62,
    "Subscription Revenue per month": 2303.1,
    "YEAR_1": 13838.752286216997,
    "YEAR_2": 27720.180002069996,
    "YEAR_3": 27777.080574918,
    "YEAR_4": 27833.981147765997,
    "YEAR_5": 27890.881720613997,
    "YEAR_6": 27947.782293461994,
    "YEAR_7": 28004.68286631,
    "YEAR_8": 28061.583439158,
    "YEAR_9": 28118.484012006,
    "YEAR_10": 28175.384584854,
    "SUBSCRIPTION_Around": 265368.79292737495
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Around",
    "No of Households": 1019550,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 458797.5,
    "No of Households for Subscription": 458.7975,
    "Subscription Revenue per month": 2293.9875,
    "YEAR_1": 13783.997551204126,
    "YEAR_2": 27610.501681428752,
    "YEAR_3": 27667.177120122753,
    "YEAR_4": 27723.852558816754,
    "YEAR_5": 27780.527997510755,
    "YEAR_6": 27837.203436204753,
    "YEAR_7": 27893.878874898754,
    "YEAR_8": 27950.554313592755,
    "YEAR_9": 28007.229752286756,
    "YEAR_10": 28063.905190980757,
    "SUBSCRIPTION_Around": 264318.82847704686
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Around",
    "No of Households": 1014160,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 456372,
    "No of Households for Subscription": 456.372,
    "Subscription Revenue per month": 2281.86,
    "YEAR_1": 13711.1264347302,
    "YEAR_2": 27464.534731241998,
    "YEAR_3": 27520.9105469508,
    "YEAR_4": 27577.2863626596,
    "YEAR_5": 27633.662178368402,
    "YEAR_6": 27690.037994077204,
    "YEAR_7": 27746.413809785998,
    "YEAR_8": 27802.7896254948,
    "YEAR_9": 27859.1654412036,
    "YEAR_10": 27915.541256912402,
    "SUBSCRIPTION_Around": 262921.468381425
  },
  {
    "DMA": "Austin",
    "PlatformName": "Around",
    "No of Households": 978520,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 440334,
    "No of Households for Subscription": 440.334,
    "Subscription Revenue per month": 2201.67,
    "YEAR_1": 13229.2847666169,
    "YEAR_2": 26499.365509599,
    "YEAR_3": 26553.7601447526,
    "YEAR_4": 26608.154779906203,
    "YEAR_5": 26662.549415059802,
    "YEAR_6": 26716.944050213402,
    "YEAR_7": 26771.338685367,
    "YEAR_8": 26825.7333205206,
    "YEAR_9": 26880.1279556742,
    "YEAR_10": 26934.5225908278,
    "SUBSCRIPTION_Around": 253681.7812185375
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Around",
    "No of Households": 953940,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 429273,
    "No of Households for Subscription": 429.273,
    "Subscription Revenue per month": 2146.3650000000002,
    "YEAR_1": 12896.970843995552,
    "YEAR_2": 25833.712887040503,
    "YEAR_3": 25886.741152439703,
    "YEAR_4": 25939.7694178389,
    "YEAR_5": 25992.797683238103,
    "YEAR_6": 26045.825948637303,
    "YEAR_7": 26098.854214036503,
    "YEAR_8": 26151.8824794357,
    "YEAR_9": 26204.910744834906,
    "YEAR_10": 26257.939010234102,
    "SUBSCRIPTION_Around": 247309.4043817313
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Around",
    "No of Households": 947350,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 426307.5,
    "No of Households for Subscription": 426.3075,
    "Subscription Revenue per month": 2131.5375,
    "YEAR_1": 12807.876102332626,
    "YEAR_2": 25655.248656663753,
    "YEAR_3": 25707.91059266175,
    "YEAR_4": 25760.572528659748,
    "YEAR_5": 25813.23446465775,
    "YEAR_6": 25865.896400655747,
    "YEAR_7": 25918.558336653754,
    "YEAR_8": 25971.220272651746,
    "YEAR_9": 26023.88220864975,
    "YEAR_10": 26076.54414464775,
    "SUBSCRIPTION_Around": 245600.94370823435
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Around",
    "No of Households": 900200,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 405090,
    "No of Households for Subscription": 405.09000000000003,
    "Subscription Revenue per month": 2025.4500000000003,
    "YEAR_1": 12170.422829281502,
    "YEAR_2": 24378.376355865003,
    "YEAR_3": 24428.417285600997,
    "YEAR_4": 24478.458215337,
    "YEAR_5": 24528.499145073,
    "YEAR_6": 24578.540074809003,
    "YEAR_7": 24628.581004545005,
    "YEAR_8": 24678.621934281,
    "YEAR_9": 24728.662864017006,
    "YEAR_10": 24778.703793753004,
    "SUBSCRIPTION_Around": 233377.2835025625
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Around",
    "No of Households": 888210,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 399694.5,
    "No of Households for Subscription": 399.6945,
    "Subscription Revenue per month": 1998.4725,
    "YEAR_1": 12008.321774268075,
    "YEAR_2": 24053.67436463325,
    "YEAR_3": 24103.04878609605,
    "YEAR_4": 24152.42320755885,
    "YEAR_5": 24201.797629021647,
    "YEAR_6": 24251.172050484452,
    "YEAR_7": 24300.546471947247,
    "YEAR_8": 24349.920893410053,
    "YEAR_9": 24399.29531487285,
    "YEAR_10": 24448.669736335658,
    "SUBSCRIPTION_Around": 230268.87022862813
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Around",
    "No of Households": 870240,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 391608,
    "No of Households for Subscription": 391.608,
    "Subscription Revenue per month": 1958.04,
    "YEAR_1": 11765.372987062801,
    "YEAR_2": 23567.027593788,
    "YEAR_3": 23615.4030866712,
    "YEAR_4": 23663.778579554397,
    "YEAR_5": 23712.1540724376,
    "YEAR_6": 23760.529565320805,
    "YEAR_7": 23808.905058204,
    "YEAR_8": 23857.2805510872,
    "YEAR_9": 23905.656043970397,
    "YEAR_10": 23954.0315368536,
    "SUBSCRIPTION_Around": 225610.13907495
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Around",
    "No of Households": 790580,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 355761,
    "No of Households for Subscription": 355.761,
    "Subscription Revenue per month": 1778.805,
    "YEAR_1": 10688.39466826635,
    "YEAR_2": 21409.7498105085,
    "YEAR_3": 21453.697109142904,
    "YEAR_4": 21497.644407777305,
    "YEAR_5": 21541.591706411702,
    "YEAR_6": 21585.539005046096,
    "YEAR_7": 21629.486303680507,
    "YEAR_8": 21673.4336023149,
    "YEAR_9": 21717.3809009493,
    "YEAR_10": 21761.328199583695,
    "SUBSCRIPTION_Around": 204958.24571368127
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Around",
    "No of Households": 781030,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 351463.5,
    "No of Households for Subscription": 351.4635,
    "Subscription Revenue per month": 1757.3175,
    "YEAR_1": 10559.281651137226,
    "YEAR_2": 21151.12562232975,
    "YEAR_3": 21194.54204907015,
    "YEAR_4": 21237.958475810556,
    "YEAR_5": 21281.374902550953,
    "YEAR_6": 21324.79132929135,
    "YEAR_7": 21368.207756031752,
    "YEAR_8": 21411.62418277215,
    "YEAR_9": 21455.04060951255,
    "YEAR_10": 21498.457036252952,
    "SUBSCRIPTION_Around": 202482.4036147594
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Around",
    "No of Households": 772320,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 347544,
    "No of Households for Subscription": 347.544,
    "Subscription Revenue per month": 1737.7199999999998,
    "YEAR_1": 10441.5251716404,
    "YEAR_2": 20915.249530284,
    "YEAR_3": 20958.181779621595,
    "YEAR_4": 21001.114028959193,
    "YEAR_5": 21044.046278296795,
    "YEAR_6": 21086.978527634397,
    "YEAR_7": 21129.910776971996,
    "YEAR_8": 21172.843026309598,
    "YEAR_9": 21215.7752756472,
    "YEAR_10": 21258.707524984802,
    "SUBSCRIPTION_Around": 200224.33192034997
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Around",
    "No of Households": 772190,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 347485.5,
    "No of Households for Subscription": 347.4855,
    "Subscription Revenue per month": 1737.4275,
    "YEAR_1": 10439.767612244925,
    "YEAR_2": 20911.72899159675,
    "YEAR_3": 20954.65401440595,
    "YEAR_4": 20997.57903721515,
    "YEAR_5": 21040.50406002435,
    "YEAR_6": 21083.42908283355,
    "YEAR_7": 21126.354105642753,
    "YEAR_8": 21169.279128451948,
    "YEAR_9": 21212.204151261147,
    "YEAR_10": 21255.129174070353,
    "SUBSCRIPTION_Around": 200190.6293577469
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Around",
    "No of Households": 766220,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 344799,
    "No of Households for Subscription": 344.79900000000004,
    "Subscription Revenue per month": 1723.9950000000001,
    "YEAR_1": 10359.05507692965,
    "YEAR_2": 20750.0550226515,
    "YEAR_3": 20792.648181041102,
    "YEAR_4": 20835.2413394307,
    "YEAR_5": 20877.834497820302,
    "YEAR_6": 20920.4276562099,
    "YEAR_7": 20963.0208145995,
    "YEAR_8": 21005.6139729891,
    "YEAR_9": 21048.207131378702,
    "YEAR_10": 21090.8002897683,
    "SUBSCRIPTION_Around": 198642.90398281874
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Around",
    "No of Households": 743340,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 334503,
    "No of Households for Subscription": 334.503,
    "Subscription Revenue per month": 1672.5149999999999,
    "YEAR_1": 10049.724623326048,
    "YEAR_2": 20130.4402136955,
    "YEAR_3": 20171.761503086695,
    "YEAR_4": 20213.082792477897,
    "YEAR_5": 20254.4040818691,
    "YEAR_6": 20295.725371260298,
    "YEAR_7": 20337.046660651496,
    "YEAR_8": 20378.3679500427,
    "YEAR_9": 20419.6892394339,
    "YEAR_10": 20461.010528825096,
    "SUBSCRIPTION_Around": 192711.25296466876
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Around",
    "No of Households": 739970,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 332986.5,
    "No of Households for Subscription": 332.98650000000004,
    "Subscription Revenue per month": 1664.9325000000001,
    "YEAR_1": 10004.163275920275,
    "YEAR_2": 20039.177018495255,
    "YEAR_3": 20080.310974034852,
    "YEAR_4": 20121.444929574453,
    "YEAR_5": 20162.57888511405,
    "YEAR_6": 20203.712840653658,
    "YEAR_7": 20244.846796193247,
    "YEAR_8": 20285.98075173285,
    "YEAR_9": 20327.114707272453,
    "YEAR_10": 20368.248662812057,
    "SUBSCRIPTION_Around": 191837.57884180316
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Around",
    "No of Households": 721070,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 324481.5,
    "No of Households for Subscription": 324.4815,
    "Subscription Revenue per month": 1622.4074999999998,
    "YEAR_1": 9748.641179193524,
    "YEAR_2": 19527.344855502746,
    "YEAR_3": 19567.428184990345,
    "YEAR_4": 19607.51151447795,
    "YEAR_5": 19647.594843965548,
    "YEAR_6": 19687.678173453147,
    "YEAR_7": 19727.76150294075,
    "YEAR_8": 19767.844832428353,
    "YEAR_9": 19807.928161915945,
    "YEAR_10": 19848.011491403544,
    "SUBSCRIPTION_Around": 186937.74474027182
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Around",
    "No of Households": 720750,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 324337.5,
    "No of Households for Subscription": 324.33750000000003,
    "Subscription Revenue per month": 1621.6875000000002,
    "YEAR_1": 9744.314879143127,
    "YEAR_2": 19518.678914118755,
    "YEAR_3": 19558.744455228752,
    "YEAR_4": 19598.809996338754,
    "YEAR_5": 19638.87553744875,
    "YEAR_6": 19678.941078558757,
    "YEAR_7": 19719.006619668755,
    "YEAR_8": 19759.072160778753,
    "YEAR_9": 19799.137701888754,
    "YEAR_10": 19839.203242998752,
    "SUBSCRIPTION_Around": 186854.78458617188
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Around",
    "No of Households": 687110,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 309199.5,
    "No of Households for Subscription": 309.1995,
    "Subscription Revenue per month": 1545.9975,
    "YEAR_1": 9289.512586344823,
    "YEAR_2": 18607.67182612575,
    "YEAR_3": 18645.86736404055,
    "YEAR_4": 18684.06290195535,
    "YEAR_5": 18722.258439870147,
    "YEAR_6": 18760.45397778495,
    "YEAR_7": 18798.649515699748,
    "YEAR_8": 18836.84505361455,
    "YEAR_9": 18875.04059152935,
    "YEAR_10": 18913.236129444147,
    "SUBSCRIPTION_Around": 178133.59838640934
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Around",
    "No of Households": 663900,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 298755,
    "No of Households for Subscription": 298.755,
    "Subscription Revenue per month": 1493.775,
    "YEAR_1": 8975.72063581425,
    "YEAR_2": 17979.1202651175,
    "YEAR_3": 18016.0255897695,
    "YEAR_4": 18052.9309144215,
    "YEAR_5": 18089.8362390735,
    "YEAR_6": 18126.741563725504,
    "YEAR_7": 18163.646888377498,
    "YEAR_8": 18200.552213029503,
    "YEAR_9": 18237.457537681505,
    "YEAR_10": 18274.362862333503,
    "SUBSCRIPTION_Around": 172116.39470934376
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Around",
    "No of Households": 644360,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 289962,
    "No of Households for Subscription": 289.962,
    "Subscription Revenue per month": 1449.81,
    "YEAR_1": 8711.545938986701,
    "YEAR_2": 17449.956219357002,
    "YEAR_3": 17485.7753412018,
    "YEAR_4": 17521.594463046597,
    "YEAR_5": 17557.4135848914,
    "YEAR_6": 17593.232706736202,
    "YEAR_7": 17629.051828581,
    "YEAR_8": 17664.8709504258,
    "YEAR_9": 17700.6900722706,
    "YEAR_10": 17736.5091941154,
    "SUBSCRIPTION_Around": 167050.6402996125
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Around",
    "No of Households": 634120,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 285354,
    "No of Households for Subscription": 285.354,
    "Subscription Revenue per month": 1426.77,
    "YEAR_1": 8573.1043373739,
    "YEAR_2": 17172.646095069,
    "YEAR_3": 17207.8959888306,
    "YEAR_4": 17243.1458825922,
    "YEAR_5": 17278.395776353802,
    "YEAR_6": 17313.6456701154,
    "YEAR_7": 17348.895563877,
    "YEAR_8": 17384.1454576386,
    "YEAR_9": 17419.395351400202,
    "YEAR_10": 17454.6452451618,
    "SUBSCRIPTION_Around": 164395.9153684125
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Around",
    "No of Households": 632110,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 284449.5,
    "No of Households for Subscription": 284.4495,
    "Subscription Revenue per month": 1422.2475,
    "YEAR_1": 8545.929765182325,
    "YEAR_2": 17118.21315075075,
    "YEAR_3": 17153.35131126555,
    "YEAR_4": 17188.489471780347,
    "YEAR_5": 17223.62763229515,
    "YEAR_6": 17258.765792809947,
    "YEAR_7": 17293.90395332475,
    "YEAR_8": 17329.042113839547,
    "YEAR_9": 17364.18027435435,
    "YEAR_10": 17399.31843486915,
    "SUBSCRIPTION_Around": 163874.82190047184
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Around",
    "No of Households": 618120,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 278154,
    "No of Households for Subscription": 278.154,
    "Subscription Revenue per month": 1390.77,
    "YEAR_1": 8356.7893348539,
    "YEAR_2": 16739.349025869,
    "YEAR_3": 16773.7095007506,
    "YEAR_4": 16808.0699756322,
    "YEAR_5": 16842.4304505138,
    "YEAR_6": 16876.7909253954,
    "YEAR_7": 16911.151400276998,
    "YEAR_8": 16945.511875158598,
    "YEAR_9": 16979.872350040197,
    "YEAR_10": 17014.2328249218,
    "SUBSCRIPTION_Around": 160247.9076634125
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Around",
    "No of Households": 608190,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 273685.5,
    "No of Households for Subscription": 273.6855,
    "Subscription Revenue per month": 1368.4275,
    "YEAR_1": 8222.538836414926,
    "YEAR_2": 16470.43403229675,
    "YEAR_3": 16504.242511585948,
    "YEAR_4": 16538.05099087515,
    "YEAR_5": 16571.859470164352,
    "YEAR_6": 16605.667949453553,
    "YEAR_7": 16639.47642874275,
    "YEAR_8": 16673.28490803195,
    "YEAR_9": 16707.09338732115,
    "YEAR_10": 16740.901866610347,
    "SUBSCRIPTION_Around": 157673.55038149687
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Around",
    "No of Households": 590390,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 265675.5,
    "No of Households for Subscription": 265.6755,
    "Subscription Revenue per month": 1328.3775,
    "YEAR_1": 7981.888396111426,
    "YEAR_2": 15988.39104281175,
    "YEAR_3": 16021.210043596951,
    "YEAR_4": 16054.029044382149,
    "YEAR_5": 16086.848045167353,
    "YEAR_6": 16119.66704595255,
    "YEAR_7": 16152.486046737755,
    "YEAR_8": 16185.305047522948,
    "YEAR_9": 16218.124048308153,
    "YEAR_10": 16250.943049093348,
    "SUBSCRIPTION_Around": 153058.89180968443
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Around",
    "No of Households": 588650,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 264892.5,
    "No of Households for Subscription": 264.8925,
    "Subscription Revenue per month": 1324.4624999999999,
    "YEAR_1": 7958.364139587375,
    "YEAR_2": 15941.269986536248,
    "YEAR_3": 15973.992263018248,
    "YEAR_4": 16006.714539500246,
    "YEAR_5": 16039.436815982248,
    "YEAR_6": 16072.15909246425,
    "YEAR_7": 16104.881368946248,
    "YEAR_8": 16137.603645428248,
    "YEAR_9": 16170.325921910247,
    "YEAR_10": 16203.04819839225,
    "SUBSCRIPTION_Around": 152607.7959717656
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Around",
    "No of Households": 578760,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 260442,
    "No of Households for Subscription": 260.442,
    "Subscription Revenue per month": 1302.21,
    "YEAR_1": 7824.6544286547005,
    "YEAR_2": 15673.438235637,
    "YEAR_3": 15705.6107400738,
    "YEAR_4": 15737.7832445106,
    "YEAR_5": 15769.9557489474,
    "YEAR_6": 15802.128253384199,
    "YEAR_7": 15834.300757821,
    "YEAR_8": 15866.4732622578,
    "YEAR_9": 15898.6457666946,
    "YEAR_10": 15930.8182711314,
    "SUBSCRIPTION_Around": 150043.8087091125
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Around",
    "No of Households": 577130,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 259708.5,
    "No of Households for Subscription": 259.7085,
    "Subscription Revenue per month": 1298.5425,
    "YEAR_1": 7802.617337772975,
    "YEAR_2": 15629.296096712253,
    "YEAR_3": 15661.37799160065,
    "YEAR_4": 15693.45988648905,
    "YEAR_5": 15725.541781377451,
    "YEAR_6": 15757.623676265852,
    "YEAR_7": 15789.705571154247,
    "YEAR_8": 15821.78746604265,
    "YEAR_9": 15853.869360931049,
    "YEAR_10": 15885.951255819451,
    "SUBSCRIPTION_Around": 149621.2304241656
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Around",
    "No of Households": 559650,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 251842.5,
    "No of Households for Subscription": 251.8425,
    "Subscription Revenue per month": 1259.2125,
    "YEAR_1": 7566.293197519875,
    "YEAR_2": 15155.919048611253,
    "YEAR_3": 15187.02925337325,
    "YEAR_4": 15218.139458135252,
    "YEAR_5": 15249.249662897251,
    "YEAR_6": 15280.359867659248,
    "YEAR_7": 15311.470072421249,
    "YEAR_8": 15342.58027718325,
    "YEAR_9": 15373.69048194525,
    "YEAR_10": 15404.800686707249,
    "SUBSCRIPTION_Around": 145089.53200645314
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Around",
    "No of Households": 543710,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 244669.5,
    "No of Households for Subscription": 244.6695,
    "Subscription Revenue per month": 1223.3475,
    "YEAR_1": 7350.789376259325,
    "YEAR_2": 14724.246843420751,
    "YEAR_3": 14754.470964623551,
    "YEAR_4": 14784.695085826354,
    "YEAR_5": 14814.919207029156,
    "YEAR_6": 14845.143328231954,
    "YEAR_7": 14875.36744943475,
    "YEAR_8": 14905.591570637547,
    "YEAR_9": 14935.81569184035,
    "YEAR_10": 14966.039813043148,
    "SUBSCRIPTION_Around": 140957.0793303469
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Around",
    "No of Households": 510900,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 229905,
    "No of Households for Subscription": 229.905,
    "Subscription Revenue per month": 1149.525,
    "YEAR_1": 6907.20842421675,
    "YEAR_2": 13835.717040892501,
    "YEAR_3": 13864.117297504501,
    "YEAR_4": 13892.5175541165,
    "YEAR_5": 13920.9178107285,
    "YEAR_6": 13949.318067340502,
    "YEAR_7": 13977.7183239525,
    "YEAR_8": 14006.118580564504,
    "YEAR_9": 14034.518837176502,
    "YEAR_10": 14062.919093788501,
    "SUBSCRIPTION_Around": 132451.07103028128
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Around",
    "No of Households": 491820,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 221319,
    "No of Households for Subscription": 221.31900000000002,
    "Subscription Revenue per month": 1106.595,
    "YEAR_1": 6649.252783711651,
    "YEAR_2": 13319.0102858715,
    "YEAR_3": 13346.3499104691,
    "YEAR_4": 13373.6895350667,
    "YEAR_5": 13401.0291596643,
    "YEAR_6": 13428.3687842619,
    "YEAR_7": 13455.7084088595,
    "YEAR_8": 13483.0480334571,
    "YEAR_9": 13510.3876580547,
    "YEAR_10": 13537.7272826523,
    "SUBSCRIPTION_Around": 127504.57184206876
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Around",
    "No of Households": 490560,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 220752,
    "No of Households for Subscription": 220.752,
    "Subscription Revenue per month": 1103.76,
    "YEAR_1": 6632.217977263201,
    "YEAR_2": 13284.888141672,
    "YEAR_3": 13312.1577245328,
    "YEAR_4": 13339.427307393598,
    "YEAR_5": 13366.6968902544,
    "YEAR_6": 13393.9664731152,
    "YEAR_7": 13421.236055976002,
    "YEAR_8": 13448.505638836801,
    "YEAR_9": 13475.7752216976,
    "YEAR_10": 13503.044804558402,
    "SUBSCRIPTION_Around": 127177.9162353
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Around",
    "No of Households": 484300,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 217935,
    "No of Households for Subscription": 217.935,
    "Subscription Revenue per month": 1089.675,
    "YEAR_1": 6547.58473252725,
    "YEAR_2": 13115.360663347497,
    "YEAR_3": 13142.282261071501,
    "YEAR_4": 13169.2038587955,
    "YEAR_5": 13196.125456519501,
    "YEAR_6": 13223.0470542435,
    "YEAR_7": 13249.968651967498,
    "YEAR_8": 13276.890249691502,
    "YEAR_9": 13303.811847415498,
    "YEAR_10": 13330.733445139502,
    "SUBSCRIPTION_Around": 125555.00822071872
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Around",
    "No of Households": 481910,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 216859.5,
    "No of Households for Subscription": 216.8595,
    "Subscription Revenue per month": 1084.2975,
    "YEAR_1": 6515.272679025824,
    "YEAR_2": 13050.636913635748,
    "YEAR_3": 13077.425654414548,
    "YEAR_4": 13104.214395193349,
    "YEAR_5": 13131.003135972149,
    "YEAR_6": 13157.791876750947,
    "YEAR_7": 13184.58061752975,
    "YEAR_8": 13211.369358308548,
    "YEAR_9": 13238.158099087348,
    "YEAR_10": 13264.94683986615,
    "SUBSCRIPTION_Around": 124935.39956978435
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Around",
    "No of Households": 472310,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 212539.5,
    "No of Households for Subscription": 212.5395,
    "Subscription Revenue per month": 1062.6975,
    "YEAR_1": 6385.483677513826,
    "YEAR_2": 12790.658672115747,
    "YEAR_3": 12816.913761566548,
    "YEAR_4": 12843.168851017352,
    "YEAR_5": 12869.42394046815,
    "YEAR_6": 12895.67902991895,
    "YEAR_7": 12921.934119369751,
    "YEAR_8": 12948.18920882055,
    "YEAR_9": 12974.444298271348,
    "YEAR_10": 13000.699387722148,
    "SUBSCRIPTION_Around": 122446.59494678437
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Around",
    "No of Households": 471190,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 212035.5,
    "No of Households for Subscription": 212.0355,
    "Subscription Revenue per month": 1060.1775,
    "YEAR_1": 6370.341627337426,
    "YEAR_2": 12760.327877271753,
    "YEAR_3": 12786.520707400949,
    "YEAR_4": 12812.71353753015,
    "YEAR_5": 12838.906367659354,
    "YEAR_6": 12865.099197788548,
    "YEAR_7": 12891.292027917747,
    "YEAR_8": 12917.484858046952,
    "YEAR_9": 12943.67768817615,
    "YEAR_10": 12969.87051830535,
    "SUBSCRIPTION_Around": 122156.23440743437
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Around",
    "No of Households": 457620,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 205929,
    "No of Households for Subscription": 205.929,
    "Subscription Revenue per month": 1029.645,
    "YEAR_1": 6186.87946582515,
    "YEAR_2": 12392.8378004565,
    "YEAR_3": 12418.276292198101,
    "YEAR_4": 12443.7147839397,
    "YEAR_5": 12469.1532756813,
    "YEAR_6": 12494.591767422899,
    "YEAR_7": 12520.030259164501,
    "YEAR_8": 12545.4687509061,
    "YEAR_9": 12570.9072426477,
    "YEAR_10": 12596.3457343893,
    "SUBSCRIPTION_Around": 118638.20537263126
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Around",
    "No of Households": 456390,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 205375.5,
    "No of Households for Subscription": 205.37550000000002,
    "Subscription Revenue per month": 1026.8775,
    "YEAR_1": 6170.250250006426,
    "YEAR_2": 12359.528088261752,
    "YEAR_3": 12384.898205926951,
    "YEAR_4": 12410.26832359215,
    "YEAR_5": 12435.63844125735,
    "YEAR_6": 12461.00855892255,
    "YEAR_7": 12486.37867658775,
    "YEAR_8": 12511.748794252951,
    "YEAR_9": 12537.11891191815,
    "YEAR_10": 12562.48902958335,
    "SUBSCRIPTION_Around": 118319.32728030937
  },
  {
    "DMA": "Madison",
    "PlatformName": "Around",
    "No of Households": 454700,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 204615,
    "No of Households for Subscription": 204.615,
    "Subscription Revenue per month": 1023.075,
    "YEAR_1": 6147.401977865249,
    "YEAR_2": 12313.7610853275,
    "YEAR_3": 12339.0372581235,
    "YEAR_4": 12364.3134309195,
    "YEAR_5": 12389.5896037155,
    "YEAR_6": 12414.8657765115,
    "YEAR_7": 12440.141949307503,
    "YEAR_8": 12465.4181221035,
    "YEAR_9": 12490.694294899502,
    "YEAR_10": 12515.970467695503,
    "SUBSCRIPTION_Around": 117881.19396646875
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Around",
    "No of Households": 451790,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 203305.5,
    "No of Households for Subscription": 203.3055,
    "Subscription Revenue per month": 1016.5274999999999,
    "YEAR_1": 6108.059686781924,
    "YEAR_2": 12234.95518086675,
    "YEAR_3": 12260.069590603947,
    "YEAR_4": 12285.184000341149,
    "YEAR_5": 12310.298410078349,
    "YEAR_6": 12335.41281981555,
    "YEAR_7": 12360.527229552748,
    "YEAR_8": 12385.641639289948,
    "YEAR_9": 12410.756049027148,
    "YEAR_10": 12435.870458764348,
    "SUBSCRIPTION_Around": 117126.77506512185
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Around",
    "No of Households": 442050,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 198922.5,
    "No of Households for Subscription": 198.9225,
    "Subscription Revenue per month": 994.6125000000001,
    "YEAR_1": 5976.377928997876,
    "YEAR_2": 11971.185589991252,
    "YEAR_3": 11995.758565985252,
    "YEAR_4": 12020.331541979249,
    "YEAR_5": 12044.904517973251,
    "YEAR_6": 12069.477493967252,
    "YEAR_7": 12094.050469961248,
    "YEAR_8": 12118.623445955252,
    "YEAR_9": 12143.196421949251,
    "YEAR_10": 12167.76939794325,
    "SUBSCRIPTION_Around": 114601.67537470313
  },
  {
    "DMA": "Springfield, MO",
    "PlatformName": "Around",
    "No of Households": 436340,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 196353,
    "No of Households for Subscription": 196.353,
    "Subscription Revenue per month": 981.7650000000001,
    "YEAR_1": 5899.18051247355,
    "YEAR_2": 11816.552698420503,
    "YEAR_3": 11840.8082630517,
    "YEAR_4": 11865.063827682901,
    "YEAR_5": 11889.3193923141,
    "YEAR_6": 11913.574956945302,
    "YEAR_7": 11937.830521576501,
    "YEAR_8": 11962.0860862077,
    "YEAR_9": 11986.341650838902,
    "YEAR_10": 12010.597215470101,
    "SUBSCRIPTION_Around": 113121.35512498127
  },
  {
    "DMA": "Columbia, SC",
    "PlatformName": "Around",
    "No of Households": 435570,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 196006.5,
    "No of Households for Subscription": 196.00650000000002,
    "Subscription Revenue per month": 980.0325,
    "YEAR_1": 5888.770352977275,
    "YEAR_2": 11795.700276965252,
    "YEAR_3": 11819.91303831285,
    "YEAR_4": 11844.125799660447,
    "YEAR_5": 11868.33856100805,
    "YEAR_6": 11892.55132235565,
    "YEAR_7": 11916.764083703252,
    "YEAR_8": 11940.97684505085,
    "YEAR_9": 11965.18960639845,
    "YEAR_10": 11989.40236774605,
    "SUBSCRIPTION_Around": 112921.73225417812
  },
  {
    "DMA": "Rochester, NY",
    "PlatformName": "Around",
    "No of Households": 434190,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 195385.5,
    "No of Households for Subscription": 195.3855,
    "Subscription Revenue per month": 976.9275,
    "YEAR_1": 5870.113184009925,
    "YEAR_2": 11758.32840474675,
    "YEAR_3": 11782.464453715951,
    "YEAR_4": 11806.60050268515,
    "YEAR_5": 11830.73655165435,
    "YEAR_6": 11854.87260062355,
    "YEAR_7": 11879.00864959275,
    "YEAR_8": 11903.144698561951,
    "YEAR_9": 11927.28074753115,
    "YEAR_10": 11951.416796500349,
    "SUBSCRIPTION_Around": 112563.96658962186
  },
  {
    "DMA": "Portland",
    "PlatformName": "Around",
    "No of Households": 429130,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 193108.5,
    "No of Households for Subscription": 193.1085,
    "Subscription Revenue per month": 965.5425,
    "YEAR_1": 5801.703564462976,
    "YEAR_2": 11621.29820661225,
    "YEAR_3": 11645.152976860652,
    "YEAR_4": 11669.00774710905,
    "YEAR_5": 11692.86251735745,
    "YEAR_6": 11716.717287605852,
    "YEAR_7": 11740.57205785425,
    "YEAR_8": 11764.42682810265,
    "YEAR_9": 11788.281598351052,
    "YEAR_10": 11812.13636859945,
    "SUBSCRIPTION_Around": 111252.15915291561
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Around",
    "No of Households": 427650,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 192442.5,
    "No of Households for Subscription": 192.4425,
    "Subscription Revenue per month": 962.2125,
    "YEAR_1": 5781.694426729875,
    "YEAR_2": 11581.21822771125,
    "YEAR_3": 11604.990726713251,
    "YEAR_4": 11628.76322571525,
    "YEAR_5": 11652.53572471725,
    "YEAR_6": 11676.30822371925,
    "YEAR_7": 11700.080722721252,
    "YEAR_8": 11723.853221723251,
    "YEAR_9": 11747.625720725251,
    "YEAR_10": 11771.39821972725,
    "SUBSCRIPTION_Around": 110868.46844020314
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Around",
    "No of Households": 424380,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 190971,
    "No of Households for Subscription": 190.971,
    "Subscription Revenue per month": 954.855,
    "YEAR_1": 5737.485048089849,
    "YEAR_2": 11492.6631391935,
    "YEAR_3": 11516.2538632119,
    "YEAR_4": 11539.844587230298,
    "YEAR_5": 11563.435311248697,
    "YEAR_6": 11587.026035267103,
    "YEAR_7": 11610.616759285502,
    "YEAR_8": 11634.2074833039,
    "YEAR_9": 11657.798207322301,
    "YEAR_10": 11681.3889313407,
    "SUBSCRIPTION_Around": 110020.71936549374
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Around",
    "No of Households": 412370,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 185566.5,
    "No of Households for Subscription": 185.5665,
    "Subscription Revenue per month": 927.8325,
    "YEAR_1": 5575.113599323275,
    "YEAR_2": 11167.41952662525,
    "YEAR_3": 11190.34263059685,
    "YEAR_4": 11213.265734568451,
    "YEAR_5": 11236.18883854005,
    "YEAR_6": 11259.111942511649,
    "YEAR_7": 11282.03504648325,
    "YEAR_8": 11304.95815045485,
    "YEAR_9": 11327.88125442645,
    "YEAR_10": 11350.80435839805,
    "SUBSCRIPTION_Around": 106907.12108192813
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Around",
    "No of Households": 407620,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 183429,
    "No of Households for Subscription": 183.429,
    "Subscription Revenue per month": 917.145,
    "YEAR_1": 5510.89508295015,
    "YEAR_2": 11038.7844592065,
    "YEAR_3": 11061.443516948098,
    "YEAR_4": 11084.1025746897,
    "YEAR_5": 11106.761632431302,
    "YEAR_6": 11129.420690172901,
    "YEAR_7": 11152.0797479145,
    "YEAR_8": 11174.7388056561,
    "YEAR_9": 11197.397863397699,
    "YEAR_10": 11220.0569211393,
    "SUBSCRIPTION_Around": 105675.68129450626
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Around",
    "No of Households": 403470,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 181561.5,
    "No of Households for Subscription": 181.5615,
    "Subscription Revenue per month": 907.8075,
    "YEAR_1": 5454.788379171525,
    "YEAR_2": 10926.39803188275,
    "YEAR_3": 10948.82639660235,
    "YEAR_4": 10971.25476132195,
    "YEAR_5": 10993.68312604155,
    "YEAR_6": 11016.11149076115,
    "YEAR_7": 11038.539855480749,
    "YEAR_8": 11060.96822020035,
    "YEAR_9": 11083.39658491995,
    "YEAR_10": 11105.82494963955,
    "SUBSCRIPTION_Around": 104599.79179602186
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Around",
    "No of Households": 401510,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 180679.5,
    "No of Households for Subscription": 180.6795,
    "Subscription Revenue per month": 903.3974999999999,
    "YEAR_1": 5428.289791362825,
    "YEAR_2": 10873.319140905749,
    "YEAR_3": 10895.63855181255,
    "YEAR_4": 10917.957962719349,
    "YEAR_5": 10940.27737362615,
    "YEAR_6": 10962.596784532949,
    "YEAR_7": 10984.91619543975,
    "YEAR_8": 11007.235606346549,
    "YEAR_9": 11029.55501725335,
    "YEAR_10": 11051.874428160148,
    "SUBSCRIPTION_Around": 104091.66085215939
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Around",
    "No of Households": 388650,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 174892.5,
    "No of Households for Subscription": 174.8925,
    "Subscription Revenue per month": 874.4625000000001,
    "YEAR_1": 5254.426608087375,
    "YEAR_2": 10525.05662153625,
    "YEAR_3": 10546.661162018252,
    "YEAR_4": 10568.26570250025,
    "YEAR_5": 10589.870242982252,
    "YEAR_6": 10611.474783464251,
    "YEAR_7": 10633.079323946251,
    "YEAR_8": 10654.68386442825,
    "YEAR_9": 10676.28840491025,
    "YEAR_10": 10697.892945392252,
    "SUBSCRIPTION_Around": 100757.69965926562
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Around",
    "No of Households": 385730,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 173578.5,
    "No of Households for Subscription": 173.5785,
    "Subscription Revenue per month": 867.8924999999999,
    "YEAR_1": 5214.949120127475,
    "YEAR_2": 10445.97990640725,
    "YEAR_3": 10467.422127943648,
    "YEAR_4": 10488.864349480049,
    "YEAR_5": 10510.306571016448,
    "YEAR_6": 10531.748792552848,
    "YEAR_7": 10553.191014089249,
    "YEAR_8": 10574.63323562565,
    "YEAR_9": 10596.07545716205,
    "YEAR_10": 10617.51767869845,
    "SUBSCRIPTION_Around": 100000.68825310312
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Around",
    "No of Households": 378040,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 170118,
    "No of Households for Subscription": 170.118,
    "Subscription Revenue per month": 850.5899999999999,
    "YEAR_1": 5110.9827220413,
    "YEAR_2": 10237.726502523,
    "YEAR_3": 10258.7412471102,
    "YEAR_4": 10279.755991697399,
    "YEAR_5": 10300.7707362846,
    "YEAR_6": 10321.785480871798,
    "YEAR_7": 10342.800225459,
    "YEAR_8": 10363.814970046198,
    "YEAR_9": 10384.829714633399,
    "YEAR_10": 10405.8444592206,
    "SUBSCRIPTION_Around": 98007.05204988748
  },
  {
    "DMA": "Charleston, SC",
    "PlatformName": "Around",
    "No of Households": 374290,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 168430.5,
    "No of Households for Subscription": 168.4305,
    "Subscription Revenue per month": 842.1524999999999,
    "YEAR_1": 5060.283893325674,
    "YEAR_2": 10136.17250192925,
    "YEAR_3": 10156.978788966448,
    "YEAR_4": 10177.785076003649,
    "YEAR_5": 10198.59136304085,
    "YEAR_6": 10219.39765007805,
    "YEAR_7": 10240.20393711525,
    "YEAR_8": 10261.010224152447,
    "YEAR_9": 10281.816511189649,
    "YEAR_10": 10302.62279822685,
    "SUBSCRIPTION_Around": 97034.86274402811
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Around",
    "No of Households": 373520,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 168084,
    "No of Households for Subscription": 168.084,
    "Subscription Revenue per month": 840.4200000000001,
    "YEAR_1": 5049.873733829401,
    "YEAR_2": 10115.320080473999,
    "YEAR_3": 10136.0835642276,
    "YEAR_4": 10156.847047981202,
    "YEAR_5": 10177.610531734801,
    "YEAR_6": 10198.3740154884,
    "YEAR_7": 10219.137499242002,
    "YEAR_8": 10239.900982995601,
    "YEAR_9": 10260.6644667492,
    "YEAR_10": 10281.427950502803,
    "SUBSCRIPTION_Around": 96835.23987322501
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Around",
    "No of Households": 373080,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 167886,
    "No of Households for Subscription": 167.886,
    "Subscription Revenue per month": 839.43,
    "YEAR_1": 5043.9250712601,
    "YEAR_2": 10103.404411070998,
    "YEAR_3": 10124.1434358054,
    "YEAR_4": 10144.8824605398,
    "YEAR_5": 10165.6214852742,
    "YEAR_6": 10186.3605100086,
    "YEAR_7": 10207.099534743,
    "YEAR_8": 10227.838559477399,
    "YEAR_9": 10248.5775842118,
    "YEAR_10": 10269.3166089462,
    "SUBSCRIPTION_Around": 96721.1696613375
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Around",
    "No of Households": 371730,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 167278.5,
    "No of Households for Subscription": 167.2785,
    "Subscription Revenue per month": 836.3925,
    "YEAR_1": 5025.673492922475,
    "YEAR_2": 10066.844970857252,
    "YEAR_3": 10087.50895087365,
    "YEAR_4": 10108.172930890052,
    "YEAR_5": 10128.83691090645,
    "YEAR_6": 10149.50089092285,
    "YEAR_7": 10170.16487093925,
    "YEAR_8": 10190.828850955651,
    "YEAR_9": 10211.492830972049,
    "YEAR_10": 10232.15681098845,
    "SUBSCRIPTION_Around": 96371.18151122813
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Around",
    "No of Households": 369270,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 166171.5,
    "No of Households for Subscription": 166.1715,
    "Subscription Revenue per month": 830.8575000000001,
    "YEAR_1": 4992.415061285025,
    "YEAR_2": 10000.22554646775,
    "YEAR_3": 10020.752778331349,
    "YEAR_4": 10041.280010194952,
    "YEAR_5": 10061.80724205855,
    "YEAR_6": 10082.334473922154,
    "YEAR_7": 10102.86170578575,
    "YEAR_8": 10123.388937649348,
    "YEAR_9": 10143.91616951295,
    "YEAR_10": 10164.44340137655,
    "SUBSCRIPTION_Around": 95733.42532658437
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Around",
    "No of Households": 360880,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 162396,
    "No of Households for Subscription": 162.39600000000002,
    "Subscription Revenue per month": 811.98,
    "YEAR_1": 4878.9848818386,
    "YEAR_2": 9773.015395806,
    "YEAR_3": 9793.0762386444,
    "YEAR_4": 9813.1370814828,
    "YEAR_5": 9833.197924321199,
    "YEAR_6": 9853.258767159601,
    "YEAR_7": 9873.319609998001,
    "YEAR_8": 9893.3804528364,
    "YEAR_9": 9913.4412956748,
    "YEAR_10": 9933.502138513202,
    "SUBSCRIPTION_Around": 93558.31378627499
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Around",
    "No of Households": 360200,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 162090,
    "No of Households for Subscription": 162.09,
    "Subscription Revenue per month": 810.45,
    "YEAR_1": 4869.7914942315,
    "YEAR_2": 9754.600270365001,
    "YEAR_3": 9774.623312901002,
    "YEAR_4": 9794.646355437,
    "YEAR_5": 9814.669397972999,
    "YEAR_6": 9834.692440509001,
    "YEAR_7": 9854.715483045,
    "YEAR_8": 9874.738525581,
    "YEAR_9": 9894.761568117001,
    "YEAR_10": 9914.784610653001,
    "SUBSCRIPTION_Around": 93382.02345881252
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Around",
    "No of Households": 353500,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 159075,
    "No of Households for Subscription": 159.07500000000002,
    "Subscription Revenue per month": 795.3750000000001,
    "YEAR_1": 4779.20958692625,
    "YEAR_2": 9573.157122637504,
    "YEAR_3": 9592.8077210175,
    "YEAR_4": 9612.458319397501,
    "YEAR_5": 9632.108917777501,
    "YEAR_6": 9651.759516157503,
    "YEAR_7": 9671.4101145375,
    "YEAR_8": 9691.060712917502,
    "YEAR_9": 9710.7113112975,
    "YEAR_10": 9730.361909677502,
    "SUBSCRIPTION_Around": 91645.04523234378
  },
  {
    "DMA": "Jackson, MS",
    "PlatformName": "Around",
    "No of Households": 340720,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 153324,
    "No of Households for Subscription": 153.324,
    "Subscription Revenue per month": 766.6200000000001,
    "YEAR_1": 4606.427978663401,
    "YEAR_2": 9227.061088614002,
    "YEAR_3": 9246.0012636636,
    "YEAR_4": 9264.941438713202,
    "YEAR_5": 9283.881613762802,
    "YEAR_6": 9302.8217888124,
    "YEAR_7": 9321.761963862,
    "YEAR_8": 9340.702138911603,
    "YEAR_9": 9359.642313961202,
    "YEAR_10": 9378.582489010802,
    "SUBSCRIPTION_Around": 88331.824077975
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Around",
    "No of Households": 338310,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 152239.5,
    "No of Households for Subscription": 152.2395,
    "Subscription Revenue per month": 761.1975,
    "YEAR_1": 4573.845531408825,
    "YEAR_2": 9161.795717565748,
    "YEAR_3": 9180.601923896551,
    "YEAR_4": 9199.408130227348,
    "YEAR_5": 9218.21433655815,
    "YEAR_6": 9237.02054288895,
    "YEAR_7": 9255.82674921975,
    "YEAR_8": 9274.63295555055,
    "YEAR_9": 9293.43916188135,
    "YEAR_10": 9312.24536821215,
    "SUBSCRIPTION_Around": 87707.03041740938
  },
  {
    "DMA": "Boise",
    "PlatformName": "Around",
    "No of Households": 330040,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 148518,
    "No of Households for Subscription": 148.518,
    "Subscription Revenue per month": 742.59,
    "YEAR_1": 4462.0377144813,
    "YEAR_2": 8937.835294923,
    "YEAR_3": 8956.1817828702,
    "YEAR_4": 8974.5282708174,
    "YEAR_5": 8992.874758764601,
    "YEAR_6": 9011.221246711799,
    "YEAR_7": 9029.567734659,
    "YEAR_8": 9047.914222606201,
    "YEAR_9": 9066.2607105534,
    "YEAR_10": 9084.607198500598,
    "SUBSCRIPTION_Around": 85563.0289348875
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Around",
    "No of Households": 329080,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 148086,
    "No of Households for Subscription": 148.086,
    "Subscription Revenue per month": 740.4300000000001,
    "YEAR_1": 4449.058814330101,
    "YEAR_2": 8911.837470771,
    "YEAR_3": 8930.130593585402,
    "YEAR_4": 8948.4237163998,
    "YEAR_5": 8966.716839214201,
    "YEAR_6": 8985.0099620286,
    "YEAR_7": 9003.303084843,
    "YEAR_8": 9021.596207657401,
    "YEAR_9": 9039.8893304718,
    "YEAR_10": 9058.182453286201,
    "SUBSCRIPTION_Around": 85314.14847258749
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Around",
    "No of Households": 322100,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 144945,
    "No of Households for Subscription": 144.945,
    "Subscription Revenue per month": 724.7249999999999,
    "YEAR_1": 4354.691394480749,
    "YEAR_2": 8722.8116243325,
    "YEAR_3": 8740.716738160498,
    "YEAR_4": 8758.621851988499,
    "YEAR_5": 8776.5269658165,
    "YEAR_6": 8794.432079644499,
    "YEAR_7": 8812.3371934725,
    "YEAR_8": 8830.2423073005,
    "YEAR_9": 8848.1474211285,
    "YEAR_10": 8866.052534956498,
    "SUBSCRIPTION_Around": 83504.58011128125
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Around",
    "No of Households": 320820,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 144369,
    "No of Households for Subscription": 144.369,
    "Subscription Revenue per month": 721.845,
    "YEAR_1": 4337.38619427915,
    "YEAR_2": 8688.1478587965,
    "YEAR_3": 8705.9818191141,
    "YEAR_4": 8723.815779431701,
    "YEAR_5": 8741.6497397493,
    "YEAR_6": 8759.4837000669,
    "YEAR_7": 8777.3176603845,
    "YEAR_8": 8795.151620702101,
    "YEAR_9": 8812.9855810197,
    "YEAR_10": 8830.8195413373,
    "SUBSCRIPTION_Around": 83172.73949488126
  },
  {
    "DMA": "Reno",
    "PlatformName": "Around",
    "No of Households": 306940,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 138123,
    "No of Households for Subscription": 138.123,
    "Subscription Revenue per month": 690.615,
    "YEAR_1": 4149.73292959305,
    "YEAR_2": 8312.262651265499,
    "YEAR_3": 8329.3250407047,
    "YEAR_4": 8346.387430143901,
    "YEAR_5": 8363.4498195831,
    "YEAR_6": 8380.5122090223,
    "YEAR_7": 8397.574598461499,
    "YEAR_8": 8414.6369879007,
    "YEAR_9": 8431.699377339899,
    "YEAR_10": 8448.7617667791,
    "SUBSCRIPTION_Around": 79574.34281079377
  },
  {
    "DMA": "Washington",
    "PlatformName": "Around",
    "No of Households": 305320,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 137394,
    "No of Households for Subscription": 137.394,
    "Subscription Revenue per month": 686.97,
    "YEAR_1": 4127.8310355879,
    "YEAR_2": 8268.391323008998,
    "YEAR_3": 8285.3636587866,
    "YEAR_4": 8302.335994564202,
    "YEAR_5": 8319.3083303418,
    "YEAR_6": 8336.280666119399,
    "YEAR_7": 8353.253001897,
    "YEAR_8": 8370.2253376746,
    "YEAR_9": 8387.197673452201,
    "YEAR_10": 8404.1700092298,
    "SUBSCRIPTION_Around": 79154.3570306625
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Around",
    "No of Households": 302600,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 136170,
    "No of Households for Subscription": 136.17000000000002,
    "Subscription Revenue per month": 680.8500000000001,
    "YEAR_1": 4091.057485159501,
    "YEAR_2": 8194.730821245,
    "YEAR_3": 8211.551955813002,
    "YEAR_4": 8228.373090381003,
    "YEAR_5": 8245.194224949,
    "YEAR_6": 8262.015359517001,
    "YEAR_7": 8278.836494085002,
    "YEAR_8": 8295.657628653002,
    "YEAR_9": 8312.478763221003,
    "YEAR_10": 8329.299897789002,
    "SUBSCRIPTION_Around": 78449.19572081251
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Around",
    "No of Households": 299530,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 134788.5,
    "No of Households for Subscription": 134.7885,
    "Subscription Revenue per month": 673.9425,
    "YEAR_1": 4049.552044050975,
    "YEAR_2": 8111.59194609225,
    "YEAR_3": 8128.24242341265,
    "YEAR_4": 8144.89290073305,
    "YEAR_5": 8161.5433780534495,
    "YEAR_6": 8178.19385537385,
    "YEAR_7": 8194.84433269425,
    "YEAR_8": 8211.494810014648,
    "YEAR_9": 8228.14528733505,
    "YEAR_10": 8244.79576465545,
    "SUBSCRIPTION_Around": 77653.29674241564
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Around",
    "No of Households": 291650,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 131242.5,
    "No of Households for Subscription": 131.2425,
    "Subscription Revenue per month": 656.2125000000001,
    "YEAR_1": 3943.0169053098757,
    "YEAR_2": 7898.193139511251,
    "YEAR_3": 7914.405578033251,
    "YEAR_4": 7930.618016555251,
    "YEAR_5": 7946.830455077252,
    "YEAR_6": 7963.042893599251,
    "YEAR_7": 7979.255332121252,
    "YEAR_8": 7995.467770643252,
    "YEAR_9": 8011.680209165252,
    "YEAR_10": 8027.892647687251,
    "SUBSCRIPTION_Around": 75610.40294770314
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Around",
    "No of Households": 290990,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 130945.5,
    "No of Households for Subscription": 130.9455,
    "Subscription Revenue per month": 654.7275000000001,
    "YEAR_1": 3934.093911455925,
    "YEAR_2": 7880.319635406751,
    "YEAR_3": 7896.4953853999505,
    "YEAR_4": 7912.67113539315,
    "YEAR_5": 7928.8468853863515,
    "YEAR_6": 7945.022635379551,
    "YEAR_7": 7961.198385372752,
    "YEAR_8": 7977.37413536595,
    "YEAR_9": 7993.549885359152,
    "YEAR_10": 8009.725635352352,
    "SUBSCRIPTION_Around": 75439.29762987189
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Around",
    "No of Households": 285360,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 128412,
    "No of Households for Subscription": 128.412,
    "Subscription Revenue per month": 642.0600000000001,
    "YEAR_1": 3857.9780699442003,
    "YEAR_2": 7727.853229182001,
    "YEAR_3": 7743.716014906801,
    "YEAR_4": 7759.578800631601,
    "YEAR_5": 7775.441586356401,
    "YEAR_6": 7791.3043720812,
    "YEAR_7": 7807.1671578059995,
    "YEAR_8": 7823.029943530801,
    "YEAR_9": 7838.892729255601,
    "YEAR_10": 7854.7555149804,
    "SUBSCRIPTION_Around": 73979.717418675
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Around",
    "No of Households": 284300,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 127935,
    "No of Households for Subscription": 127.935,
    "Subscription Revenue per month": 639.675,
    "YEAR_1": 3843.64720102725,
    "YEAR_2": 7699.147298347499,
    "YEAR_3": 7714.9511600714995,
    "YEAR_4": 7730.7550217954995,
    "YEAR_5": 7746.5588835195,
    "YEAR_6": 7762.3627452434985,
    "YEAR_7": 7778.166606967499,
    "YEAR_8": 7793.970468691499,
    "YEAR_9": 7809.7743304155,
    "YEAR_10": 7825.578192139498,
    "SUBSCRIPTION_Around": 73704.91190821874
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Around",
    "No of Households": 283100,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 127395,
    "No of Households for Subscription": 127.395,
    "Subscription Revenue per month": 636.975,
    "YEAR_1": 3827.42357583825,
    "YEAR_2": 7666.6500181575,
    "YEAR_3": 7682.3871734655,
    "YEAR_4": 7698.124328773501,
    "YEAR_5": 7713.8614840815,
    "YEAR_6": 7729.5986393895,
    "YEAR_7": 7745.335794697501,
    "YEAR_8": 7761.0729500055,
    "YEAR_9": 7776.8101053135,
    "YEAR_10": 7792.547260621501,
    "SUBSCRIPTION_Around": 73393.81133034376
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Around",
    "No of Households": 282090,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 126940.5,
    "No of Households for Subscription": 126.9405,
    "Subscription Revenue per month": 634.7025,
    "YEAR_1": 3813.7686913041753,
    "YEAR_2": 7639.298140664249,
    "YEAR_3": 7654.97915140545,
    "YEAR_4": 7670.660162146651,
    "YEAR_5": 7686.341172887851,
    "YEAR_6": 7702.022183629049,
    "YEAR_7": 7717.70319437025,
    "YEAR_8": 7733.384205111451,
    "YEAR_9": 7749.065215852649,
    "YEAR_10": 7764.746226593849,
    "SUBSCRIPTION_Around": 73131.96834396562
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Around",
    "No of Households": 276580,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 124461,
    "No of Households for Subscription": 124.461,
    "Subscription Revenue per month": 622.305,
    "YEAR_1": 3739.275212311349,
    "YEAR_2": 7490.081462458499,
    "YEAR_3": 7505.4561795729005,
    "YEAR_4": 7520.8308966873,
    "YEAR_5": 7536.205613801698,
    "YEAR_6": 7551.580330916099,
    "YEAR_7": 7566.9550480305,
    "YEAR_8": 7582.3297651449,
    "YEAR_9": 7597.704482259299,
    "YEAR_10": 7613.079199373698,
    "SUBSCRIPTION_Around": 71703.49819055625
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Around",
    "No of Households": 265790,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 119605.5,
    "No of Households for Subscription": 119.6055,
    "Subscription Revenue per month": 598.0275,
    "YEAR_1": 3593.3977824869253,
    "YEAR_2": 7197.876751416751,
    "YEAR_3": 7212.651666673951,
    "YEAR_4": 7227.42658193115,
    "YEAR_5": 7242.20149718835,
    "YEAR_6": 7256.9764124455505,
    "YEAR_7": 7271.751327702751,
    "YEAR_8": 7286.526242959951,
    "YEAR_9": 7301.30115821715,
    "YEAR_10": 7316.07607347435,
    "SUBSCRIPTION_Around": 68906.1854944969
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Around",
    "No of Households": 264420,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 118989,
    "No of Households for Subscription": 118.989,
    "Subscription Revenue per month": 594.945,
    "YEAR_1": 3574.87581039615,
    "YEAR_2": 7160.7756898665,
    "YEAR_3": 7175.474448632101,
    "YEAR_4": 7190.1732073977,
    "YEAR_5": 7204.8719661633,
    "YEAR_6": 7219.570724928901,
    "YEAR_7": 7234.269483694501,
    "YEAR_8": 7248.9682424601015,
    "YEAR_9": 7263.667001225702,
    "YEAR_10": 7278.365759991302,
    "SUBSCRIPTION_Around": 68551.01233475626
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Around",
    "No of Households": 260780,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 117351,
    "No of Households for Subscription": 117.351,
    "Subscription Revenue per month": 586.755,
    "YEAR_1": 3525.66414732285,
    "YEAR_2": 7062.200606623499,
    "YEAR_3": 7076.6970225939,
    "YEAR_4": 7091.1934385643,
    "YEAR_5": 7105.6898545347,
    "YEAR_6": 7120.186270505101,
    "YEAR_7": 7134.6826864755,
    "YEAR_8": 7149.1791024459,
    "YEAR_9": 7163.675518416299,
    "YEAR_10": 7178.171934386701,
    "SUBSCRIPTION_Around": 67607.34058186875
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Around",
    "No of Households": 260220,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 117099,
    "No of Households for Subscription": 117.099,
    "Subscription Revenue per month": 585.495,
    "YEAR_1": 3518.09312223465,
    "YEAR_2": 7047.0352092015,
    "YEAR_3": 7061.5004955111,
    "YEAR_4": 7075.9657818207,
    "YEAR_5": 7090.4310681303,
    "YEAR_6": 7104.896354439899,
    "YEAR_7": 7119.361640749499,
    "YEAR_8": 7133.826927059101,
    "YEAR_9": 7148.2922133687,
    "YEAR_10": 7162.757499678299,
    "SUBSCRIPTION_Around": 67462.16031219375
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Around",
    "No of Households": 258360,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 116262,
    "No of Households for Subscription": 116.262,
    "Subscription Revenue per month": 581.31,
    "YEAR_1": 3492.9465031917,
    "YEAR_2": 6996.664424906998,
    "YEAR_3": 7011.0263162718,
    "YEAR_4": 7025.388207636599,
    "YEAR_5": 7039.750099001401,
    "YEAR_6": 7054.111990366199,
    "YEAR_7": 7068.473881731001,
    "YEAR_8": 7082.8357730958005,
    "YEAR_9": 7097.197664460597,
    "YEAR_10": 7111.5595558254,
    "SUBSCRIPTION_Around": 66979.9544164875
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Around",
    "No of Households": 254120,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 114354,
    "No of Households for Subscription": 114.354,
    "Subscription Revenue per month": 571.77,
    "YEAR_1": 3435.6230275239,
    "YEAR_2": 6881.840701568999,
    "YEAR_3": 6895.966896930599,
    "YEAR_4": 6910.0930922922,
    "YEAR_5": 6924.219287653799,
    "YEAR_6": 6938.345483015399,
    "YEAR_7": 6952.471678377001,
    "YEAR_8": 6966.597873738599,
    "YEAR_9": 6980.724069100201,
    "YEAR_10": 6994.8502644618,
    "SUBSCRIPTION_Around": 65880.7323746625
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Around",
    "No of Households": 250840,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 112878,
    "No of Households for Subscription": 112.878,
    "Subscription Revenue per month": 564.39,
    "YEAR_1": 3391.2784520073,
    "YEAR_2": 6793.014802383,
    "YEAR_3": 6806.9586668742,
    "YEAR_4": 6820.902531365399,
    "YEAR_5": 6834.846395856601,
    "YEAR_6": 6848.7902603478,
    "YEAR_7": 6862.734124838999,
    "YEAR_8": 6876.6779893302,
    "YEAR_9": 6890.6218538214,
    "YEAR_10": 6904.5657183126,
    "SUBSCRIPTION_Around": 65030.39079513751
  },
  {
    "DMA": "Macon",
    "PlatformName": "Around",
    "No of Households": 250620,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 112779,
    "No of Households for Subscription": 112.779,
    "Subscription Revenue per month": 563.895,
    "YEAR_1": 3388.3041207226493,
    "YEAR_2": 6787.056967681499,
    "YEAR_3": 6800.988602663099,
    "YEAR_4": 6814.9202376447,
    "YEAR_5": 6828.851872626299,
    "YEAR_6": 6842.7835076079,
    "YEAR_7": 6856.7151425895,
    "YEAR_8": 6870.646777571099,
    "YEAR_9": 6884.5784125527,
    "YEAR_10": 6898.5100475343,
    "SUBSCRIPTION_Around": 64973.35568919374
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Around",
    "No of Households": 249140,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 112113,
    "No of Households for Subscription": 112.113,
    "Subscription Revenue per month": 560.565,
    "YEAR_1": 3368.29498298955,
    "YEAR_2": 6746.976988780502,
    "YEAR_3": 6760.8263525157,
    "YEAR_4": 6774.675716250901,
    "YEAR_5": 6788.525079986101,
    "YEAR_6": 6802.3744437213,
    "YEAR_7": 6816.223807456501,
    "YEAR_8": 6830.0731711917,
    "YEAR_9": 6843.922534926901,
    "YEAR_10": 6857.771898662102,
    "SUBSCRIPTION_Around": 64589.66497648127
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Around",
    "No of Households": 248110,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 111649.5,
    "No of Households for Subscription": 111.6495,
    "Subscription Revenue per month": 558.2475000000001,
    "YEAR_1": 3354.3697047023256,
    "YEAR_2": 6719.08348995075,
    "YEAR_3": 6732.87559734555,
    "YEAR_4": 6746.667704740352,
    "YEAR_5": 6760.459812135152,
    "YEAR_6": 6774.251919529952,
    "YEAR_7": 6788.044026924751,
    "YEAR_8": 6801.83613431955,
    "YEAR_9": 6815.628241714349,
    "YEAR_10": 6829.42034910915,
    "SUBSCRIPTION_Around": 64322.636980471885
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Around",
    "No of Households": 247180,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 111231,
    "No of Households for Subscription": 111.23100000000001,
    "Subscription Revenue per month": 556.1550000000001,
    "YEAR_1": 3341.7963951808506,
    "YEAR_2": 6693.8980978035,
    "YEAR_3": 6707.638507725901,
    "YEAR_4": 6721.378917648302,
    "YEAR_5": 6735.119327570699,
    "YEAR_6": 6748.859737493101,
    "YEAR_7": 6762.600147415502,
    "YEAR_8": 6776.3405573379005,
    "YEAR_9": 6790.080967260302,
    "YEAR_10": 6803.8213771827,
    "SUBSCRIPTION_Around": 64081.53403261875
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Around",
    "No of Households": 241200,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 108540,
    "No of Households for Subscription": 108.54,
    "Subscription Revenue per month": 542.7,
    "YEAR_1": 3260.948662989,
    "YEAR_2": 6531.953318190001,
    "YEAR_3": 6545.361307806001,
    "YEAR_4": 6558.769297422,
    "YEAR_5": 6572.177287038001,
    "YEAR_6": 6585.585276654001,
    "YEAR_7": 6598.99326627,
    "YEAR_8": 6612.401255886,
    "YEAR_9": 6625.8092455020005,
    "YEAR_10": 6639.217235118001,
    "SUBSCRIPTION_Around": 62531.216152875
  },
  {
    "DMA": "Lafayette, LA",
    "PlatformName": "Around",
    "No of Households": 240150,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 108067.5,
    "No of Households for Subscription": 108.0675,
    "Subscription Revenue per month": 540.3375,
    "YEAR_1": 3246.7529909486248,
    "YEAR_2": 6503.518198023749,
    "YEAR_3": 6516.867819525749,
    "YEAR_4": 6530.217441027749,
    "YEAR_5": 6543.56706252975,
    "YEAR_6": 6556.916684031749,
    "YEAR_7": 6570.26630553375,
    "YEAR_8": 6583.615927035749,
    "YEAR_9": 6596.96554853775,
    "YEAR_10": 6610.31517003975,
    "SUBSCRIPTION_Around": 62259.00314723437
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Around",
    "No of Households": 235290,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 105880.5,
    "No of Households for Subscription": 105.8805,
    "Subscription Revenue per month": 529.4025,
    "YEAR_1": 3181.0473089331754,
    "YEAR_2": 6371.90421325425,
    "YEAR_3": 6384.9836737714495,
    "YEAR_4": 6398.06313428865,
    "YEAR_5": 6411.1425948058495,
    "YEAR_6": 6424.222055323052,
    "YEAR_7": 6437.30151584025,
    "YEAR_8": 6450.380976357451,
    "YEAR_9": 6463.4604368746495,
    "YEAR_10": 6476.539897391851,
    "SUBSCRIPTION_Around": 60999.04580684063
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Around",
    "No of Households": 232880,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 104796,
    "No of Households for Subscription": 104.796,
    "Subscription Revenue per month": 523.98,
    "YEAR_1": 3148.4648616786008,
    "YEAR_2": 6306.638842206001,
    "YEAR_3": 6319.584334004398,
    "YEAR_4": 6332.5298258028015,
    "YEAR_5": 6345.475317601201,
    "YEAR_6": 6358.4208093995985,
    "YEAR_7": 6371.366301198001,
    "YEAR_8": 6384.311792996402,
    "YEAR_9": 6397.2572847947995,
    "YEAR_10": 6410.202776593202,
    "SUBSCRIPTION_Around": 60374.252146275
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Around",
    "No of Households": 226710,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 102019.5,
    "No of Households for Subscription": 102.01950000000001,
    "Subscription Revenue per month": 510.0975,
    "YEAR_1": 3065.0483888318254,
    "YEAR_2": 6139.548659895751,
    "YEAR_3": 6152.151169538551,
    "YEAR_4": 6164.75367918135,
    "YEAR_5": 6177.35618882415,
    "YEAR_6": 6189.958698466951,
    "YEAR_7": 6202.561208109751,
    "YEAR_8": 6215.16371775255,
    "YEAR_9": 6227.766227395352,
    "YEAR_10": 6240.36873703815,
    "SUBSCRIPTION_Around": 58774.67667503438
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Around",
    "No of Households": 219540,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 98793,
    "No of Households for Subscription": 98.793,
    "Subscription Revenue per month": 493.96500000000003,
    "YEAR_1": 2968.1122283275504,
    "YEAR_2": 5945.3774107605,
    "YEAR_3": 5957.581349567701,
    "YEAR_4": 5969.785288374901,
    "YEAR_5": 5981.9892271821,
    "YEAR_6": 5994.1931659893,
    "YEAR_7": 6006.397104796499,
    "YEAR_8": 6018.601043603701,
    "YEAR_9": 6030.804982410901,
    "YEAR_10": 6043.0089212181,
    "SUBSCRIPTION_Around": 56915.850722231255
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Around",
    "No of Households": 208490,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 93820.5,
    "No of Households for Subscription": 93.8205,
    "Subscription Revenue per month": 469.10249999999996,
    "YEAR_1": 2818.719679712175,
    "YEAR_2": 5646.131622344249,
    "YEAR_3": 5657.721306237449,
    "YEAR_4": 5669.310990130651,
    "YEAR_5": 5680.900674023849,
    "YEAR_6": 5692.49035791705,
    "YEAR_7": 5704.080041810249,
    "YEAR_8": 5715.669725703449,
    "YEAR_9": 5727.259409596651,
    "YEAR_10": 5738.84909348985,
    "SUBSCRIPTION_Around": 54051.13290096562
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Around",
    "No of Households": 197080,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 88686,
    "No of Households for Subscription": 88.686,
    "Subscription Revenue per month": 443.43000000000006,
    "YEAR_1": 2664.4600435401003,
    "YEAR_2": 5337.136649871,
    "YEAR_3": 5348.092066925401,
    "YEAR_4": 5359.047483979801,
    "YEAR_5": 5370.0029010342005,
    "YEAR_6": 5380.9583180886,
    "YEAR_7": 5391.913735143001,
    "YEAR_8": 5402.869152197401,
    "YEAR_9": 5413.824569251801,
    "YEAR_10": 5424.779986306201,
    "SUBSCRIPTION_Around": 51093.08490633751
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Around",
    "No of Households": 188980,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 85041,
    "No of Households for Subscription": 85.041,
    "Subscription Revenue per month": 425.205,
    "YEAR_1": 2554.95057351435,
    "YEAR_2": 5117.780008588499,
    "YEAR_3": 5128.2851573349,
    "YEAR_4": 5138.7903060813005,
    "YEAR_5": 5149.2954548277,
    "YEAR_6": 5159.8006035741,
    "YEAR_7": 5170.3057523205,
    "YEAR_8": 5180.8109010668995,
    "YEAR_9": 5191.316049813299,
    "YEAR_10": 5201.8211985597,
    "SUBSCRIPTION_Around": 48993.15600568125
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Around",
    "No of Households": 188900,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 85005,
    "No of Households for Subscription": 85.005,
    "Subscription Revenue per month": 425.025,
    "YEAR_1": 2553.86899850175,
    "YEAR_2": 5115.6135232424995,
    "YEAR_3": 5126.114224894499,
    "YEAR_4": 5136.6149265465,
    "YEAR_5": 5147.1156281985,
    "YEAR_6": 5157.6163298505,
    "YEAR_7": 5168.117031502499,
    "YEAR_8": 5178.6177331545,
    "YEAR_9": 5189.118434806499,
    "YEAR_10": 5199.619136458501,
    "SUBSCRIPTION_Around": 48972.415967156245
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Around",
    "No of Households": 188520,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 84834,
    "No of Households for Subscription": 84.834,
    "Subscription Revenue per month": 424.17,
    "YEAR_1": 2548.7315171919004,
    "YEAR_2": 5105.3227178490015,
    "YEAR_3": 5115.802295802601,
    "YEAR_4": 5126.281873756201,
    "YEAR_5": 5136.761451709801,
    "YEAR_6": 5147.241029663401,
    "YEAR_7": 5157.7206076170005,
    "YEAR_8": 5168.2001855706,
    "YEAR_9": 5178.6797635242,
    "YEAR_10": 5189.1593414778,
    "SUBSCRIPTION_Around": 48873.90078416251
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Around",
    "No of Households": 185470,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 83461.5,
    "No of Households for Subscription": 83.4615,
    "Subscription Revenue per month": 417.3075,
    "YEAR_1": 2507.496469836525,
    "YEAR_2": 5022.725464032749,
    "YEAR_3": 5033.0354965123515,
    "YEAR_4": 5043.345528991949,
    "YEAR_5": 5053.65556147155,
    "YEAR_6": 5063.96559395115,
    "YEAR_7": 5074.27562643075,
    "YEAR_8": 5084.58565891035,
    "YEAR_9": 5094.89569138995,
    "YEAR_10": 5105.20572386955,
    "SUBSCRIPTION_Around": 48083.186815396875
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Around",
    "No of Households": 183100,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 82395,
    "No of Households for Subscription": 82.395,
    "Subscription Revenue per month": 411.97499999999997,
    "YEAR_1": 2475.45481008825,
    "YEAR_2": 4958.5433356574995,
    "YEAR_3": 4968.7216229655005,
    "YEAR_4": 4978.8999102735,
    "YEAR_5": 4989.0781975815,
    "YEAR_6": 4999.2564848895,
    "YEAR_7": 5009.434772197499,
    "YEAR_8": 5019.6130595055,
    "YEAR_9": 5029.791346813499,
    "YEAR_10": 5039.969634121499,
    "SUBSCRIPTION_Around": 47468.76317409375
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Around",
    "No of Households": 181720,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 81774,
    "No of Households for Subscription": 81.774,
    "Subscription Revenue per month": 408.87,
    "YEAR_1": 2456.7976411209,
    "YEAR_2": 4921.171463439,
    "YEAR_3": 4931.2730383686,
    "YEAR_4": 4941.3746132982,
    "YEAR_5": 4951.476188227801,
    "YEAR_6": 4961.5777631574,
    "YEAR_7": 4971.679338087,
    "YEAR_8": 4981.7809130166,
    "YEAR_9": 4991.8824879462,
    "YEAR_10": 5001.9840628758,
    "SUBSCRIPTION_Around": 47110.9975095375
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Around",
    "No of Households": 181440,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 81648,
    "No of Households for Subscription": 81.648,
    "Subscription Revenue per month": 408.24,
    "YEAR_1": 2453.0121285768,
    "YEAR_2": 4913.588764728,
    "YEAR_3": 4923.6747748272,
    "YEAR_4": 4933.7607849264,
    "YEAR_5": 4943.8467950256,
    "YEAR_6": 4953.932805124799,
    "YEAR_7": 4964.018815224001,
    "YEAR_8": 4974.104825323201,
    "YEAR_9": 4984.1908354224,
    "YEAR_10": 4994.2768455216,
    "SUBSCRIPTION_Around": 47038.4073747
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Around",
    "No of Households": 174760,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 78642,
    "No of Households for Subscription": 78.642,
    "Subscription Revenue per month": 393.21,
    "YEAR_1": 2362.7006150247,
    "YEAR_2": 4732.687238336999,
    "YEAR_3": 4742.401916053799,
    "YEAR_4": 4752.1165937706,
    "YEAR_5": 4761.831271487399,
    "YEAR_6": 4771.5459492042,
    "YEAR_7": 4781.260626921,
    "YEAR_8": 4790.975304637799,
    "YEAR_9": 4800.689982354599,
    "YEAR_10": 4810.4046600714,
    "SUBSCRIPTION_Around": 45306.61415786249
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Around",
    "No of Households": 173940,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 78273,
    "No of Households for Subscription": 78.273,
    "Subscription Revenue per month": 391.365,
    "YEAR_1": 2351.61447114555,
    "YEAR_2": 4710.4807635405,
    "YEAR_3": 4720.1498585397,
    "YEAR_4": 4729.8189535389,
    "YEAR_5": 4739.488048538101,
    "YEAR_6": 4749.1571435373,
    "YEAR_7": 4758.8262385365,
    "YEAR_8": 4768.4953335357,
    "YEAR_9": 4778.1644285349,
    "YEAR_10": 4787.8335235341,
    "SUBSCRIPTION_Around": 45094.02876298125
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Around",
    "No of Households": 173370,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 78016.5,
    "No of Households for Subscription": 78.01650000000001,
    "Subscription Revenue per month": 390.08250000000004,
    "YEAR_1": 2343.908249180775,
    "YEAR_2": 4695.04455545025,
    "YEAR_3": 4704.681964901852,
    "YEAR_4": 4714.319374353449,
    "YEAR_5": 4723.956783805051,
    "YEAR_6": 4733.59419325665,
    "YEAR_7": 4743.231602708251,
    "YEAR_8": 4752.869012159851,
    "YEAR_9": 4762.506421611451,
    "YEAR_10": 4772.143831063051,
    "SUBSCRIPTION_Around": 44946.25598849062
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Around",
    "No of Households": 171450,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 77152.5,
    "No of Households for Subscription": 77.1525,
    "Subscription Revenue per month": 385.76250000000005,
    "YEAR_1": 2317.9504488783755,
    "YEAR_2": 4643.048907146251,
    "YEAR_3": 4652.579586332251,
    "YEAR_4": 4662.11026551825,
    "YEAR_5": 4671.6409447042515,
    "YEAR_6": 4681.17162389025,
    "YEAR_7": 4690.702303076251,
    "YEAR_8": 4700.2329822622505,
    "YEAR_9": 4709.763661448252,
    "YEAR_10": 4719.29434063425,
    "SUBSCRIPTION_Around": 44448.49506389064
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Around",
    "No of Households": 168960,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 76032,
    "No of Households for Subscription": 76.032,
    "Subscription Revenue per month": 380.15999999999997,
    "YEAR_1": 2284.2864266111997,
    "YEAR_2": 4575.617050751999,
    "YEAR_3": 4585.0093141248,
    "YEAR_4": 4594.4015774976,
    "YEAR_5": 4603.793840870399,
    "YEAR_6": 4613.1861042432,
    "YEAR_7": 4622.578367616,
    "YEAR_8": 4631.9706309888,
    "YEAR_9": 4641.3628943615995,
    "YEAR_10": 4650.7551577344,
    "SUBSCRIPTION_Around": 43802.961364799994
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Around",
    "No of Households": 168210,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 75694.5,
    "No of Households for Subscription": 75.6945,
    "Subscription Revenue per month": 378.4725,
    "YEAR_1": 2274.1466608680753,
    "YEAR_2": 4555.30625063325,
    "YEAR_3": 4564.656822496051,
    "YEAR_4": 4574.00739435885,
    "YEAR_5": 4583.357966221651,
    "YEAR_6": 4592.70853808445,
    "YEAR_7": 4602.05910994725,
    "YEAR_8": 4611.40968181005,
    "YEAR_9": 4620.76025367285,
    "YEAR_10": 4630.110825535649,
    "SUBSCRIPTION_Around": 43608.52350362813
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Around",
    "No of Households": 166190,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 74785.5,
    "No of Households for Subscription": 74.7855,
    "Subscription Revenue per month": 373.9275,
    "YEAR_1": 2246.836891799925,
    "YEAR_2": 4500.60249564675,
    "YEAR_3": 4509.84077837595,
    "YEAR_4": 4519.07906110515,
    "YEAR_5": 4528.317343834351,
    "YEAR_6": 4537.55562656355,
    "YEAR_7": 4546.793909292751,
    "YEAR_8": 4556.03219202195,
    "YEAR_9": 4565.270474751151,
    "YEAR_10": 4574.50875748035,
    "SUBSCRIPTION_Around": 43084.837530871875
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Around",
    "No of Households": 161210,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 72544.5,
    "No of Households for Subscription": 72.5445,
    "Subscription Revenue per month": 362.72249999999997,
    "YEAR_1": 2179.508847265575,
    "YEAR_2": 4365.73878285825,
    "YEAR_3": 4374.70023396105,
    "YEAR_4": 4383.66168506385,
    "YEAR_5": 4392.623136166649,
    "YEAR_6": 4401.58458726945,
    "YEAR_7": 4410.546038372249,
    "YEAR_8": 4419.50748947505,
    "YEAR_9": 4428.46894057785,
    "YEAR_10": 4437.430391680649,
    "SUBSCRIPTION_Around": 41793.77013269061
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Around",
    "No of Households": 160200,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 72090,
    "No of Households for Subscription": 72.09,
    "Subscription Revenue per month": 360.45000000000005,
    "YEAR_1": 2165.8539627315004,
    "YEAR_2": 4338.3869053650005,
    "YEAR_3": 4347.292211901,
    "YEAR_4": 4356.197518437001,
    "YEAR_5": 4365.102824973001,
    "YEAR_6": 4374.008131509001,
    "YEAR_7": 4382.9134380450005,
    "YEAR_8": 4391.818744581,
    "YEAR_9": 4400.724051117001,
    "YEAR_10": 4409.629357653001,
    "SUBSCRIPTION_Around": 41531.92714631251
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Around",
    "No of Households": 155590,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 70015.5,
    "No of Households for Subscription": 70.0155,
    "Subscription Revenue per month": 350.0775,
    "YEAR_1": 2103.528202630425,
    "YEAR_2": 4213.54318730175,
    "YEAR_3": 4222.19223002295,
    "YEAR_4": 4230.841272744149,
    "YEAR_5": 4239.49031546535,
    "YEAR_6": 4248.1393581865495,
    "YEAR_7": 4256.78840090775,
    "YEAR_8": 4265.43744362895,
    "YEAR_9": 4274.08648635015,
    "YEAR_10": 4282.73552907135,
    "SUBSCRIPTION_Around": 40336.78242630938
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Around",
    "No of Households": 155440,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 69948,
    "No of Households for Subscription": 69.94800000000001,
    "Subscription Revenue per month": 349.74,
    "YEAR_1": 2101.5002494818,
    "YEAR_2": 4209.481027278,
    "YEAR_3": 4218.1217316972,
    "YEAR_4": 4226.7624361164,
    "YEAR_5": 4235.4031405356,
    "YEAR_6": 4244.0438449548,
    "YEAR_7": 4252.684549374,
    "YEAR_8": 4261.3252537932,
    "YEAR_9": 4269.9659582124,
    "YEAR_10": 4278.606662631601,
    "SUBSCRIPTION_Around": 40297.894854075006
  },
  {
    "DMA": "Erie",
    "PlatformName": "Around",
    "No of Households": 152420,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 68589,
    "No of Households for Subscription": 68.589,
    "Subscription Revenue per month": 342.945,
    "YEAR_1": 2060.67079275615,
    "YEAR_2": 4127.696205466499,
    "YEAR_3": 4136.1690320721,
    "YEAR_4": 4144.6418586777,
    "YEAR_5": 4153.1146852833,
    "YEAR_6": 4161.5875118889,
    "YEAR_7": 4170.0603384945,
    "YEAR_8": 4178.5331651001,
    "YEAR_9": 4187.0059917057,
    "YEAR_10": 4195.4788183113,
    "SUBSCRIPTION_Around": 39514.958399756244
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Around",
    "No of Households": 152190,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 68485.5,
    "No of Households for Subscription": 68.4855,
    "Subscription Revenue per month": 342.4275,
    "YEAR_1": 2057.561264594925,
    "YEAR_2": 4121.46756009675,
    "YEAR_3": 4129.927601305951,
    "YEAR_4": 4138.38764251515,
    "YEAR_5": 4146.84768372435,
    "YEAR_6": 4155.307724933549,
    "YEAR_7": 4163.76776614275,
    "YEAR_8": 4172.227807351949,
    "YEAR_9": 4180.68784856115,
    "YEAR_10": 4189.14788977035,
    "SUBSCRIPTION_Around": 39455.33078899688
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Around",
    "No of Households": 151160,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 68022,
    "No of Households for Subscription": 68.022,
    "Subscription Revenue per month": 340.11,
    "YEAR_1": 2043.6359863077,
    "YEAR_2": 4093.574061267,
    "YEAR_3": 4101.9768461358,
    "YEAR_4": 4110.3796310046,
    "YEAR_5": 4118.7824158734,
    "YEAR_6": 4127.1852007422,
    "YEAR_7": 4135.5879856110005,
    "YEAR_8": 4143.990770479801,
    "YEAR_9": 4152.393555348601,
    "YEAR_10": 4160.796340217401,
    "SUBSCRIPTION_Around": 39188.302792987495
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Around",
    "No of Households": 150700,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 67815,
    "No of Households for Subscription": 67.815,
    "Subscription Revenue per month": 339.075,
    "YEAR_1": 2037.4169299852501,
    "YEAR_2": 4081.1167705274997,
    "YEAR_3": 4089.4939846034995,
    "YEAR_4": 4097.8711986795,
    "YEAR_5": 4106.2484127555,
    "YEAR_6": 4114.6256268315,
    "YEAR_7": 4123.002840907499,
    "YEAR_8": 4131.3800549835005,
    "YEAR_9": 4139.7572690595,
    "YEAR_10": 4148.1344831355,
    "SUBSCRIPTION_Around": 39069.04757146875
  },
  {
    "DMA": "Albany, GA",
    "PlatformName": "Around",
    "No of Households": 144570,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 65056.5,
    "No of Households for Subscription": 65.0565,
    "Subscription Revenue per month": 325.2825,
    "YEAR_1": 1954.5412446447754,
    "YEAR_2": 3915.1098308902497,
    "YEAR_3": 3923.14628635785,
    "YEAR_4": 3931.182741825451,
    "YEAR_5": 3939.2191972930495,
    "YEAR_6": 3947.255652760651,
    "YEAR_7": 3955.2921082282505,
    "YEAR_8": 3963.3285636958494,
    "YEAR_9": 3971.365019163451,
    "YEAR_10": 3979.4014746310504,
    "SUBSCRIPTION_Around": 37479.842119490626
  },
  {
    "DMA": "Idaho Falls-Pocatello-Jackson",
    "PlatformName": "Around",
    "No of Households": 142930,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 64318.5,
    "No of Households for Subscription": 64.3185,
    "Subscription Revenue per month": 321.5925,
    "YEAR_1": 1932.3689568864747,
    "YEAR_2": 3870.69688129725,
    "YEAR_3": 3878.6421713296495,
    "YEAR_4": 3886.58746136205,
    "YEAR_5": 3894.53275139445,
    "YEAR_6": 3902.4780414268503,
    "YEAR_7": 3910.4233314592498,
    "YEAR_8": 3918.3686214916497,
    "YEAR_9": 3926.31391152405,
    "YEAR_10": 3934.2592015564496,
    "SUBSCRIPTION_Around": 37054.671329728124
  },
  {
    "DMA": "Bangor",
    "PlatformName": "Around",
    "No of Households": 142180,
    "% No of Smart Households": 0.45,
    "Subscription Charges": 5,
    "% of Households for Subscription": 0.001,
    "%inc in Smart Households / month": 0.0129,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "No of Smart Households": 63981,
    "No of Households for Subscription": 63.981,
    "Subscription Revenue per month": 319.90500000000003,
    "YEAR_1": 1922.2291911433501,
    "YEAR_2": 3850.3860811785,
    "YEAR_3": 3858.2896797009007,
    "YEAR_4": 3866.1932782233007,
    "YEAR_5": 3874.0968767457007,
    "YEAR_6": 3882.0004752681007,
    "YEAR_7": 3889.9040737905007,
    "YEAR_8": 3897.8076723129006,
    "YEAR_9": 3905.7112708353006,
    "YEAR_10": 3913.6148693577006,
    "SUBSCRIPTION_Around": 36860.23346855625
  },
  {
    "DMA": "New York",
    "PlatformName": "Hear,here",
    "No of Households": 7726580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 3476961,
    "No of HH's Considered for CPL": 347.6961,
    "Revenue Per month": 10430.883,
    "YEAR_1": 62797.55603816701,
    "YEAR_2": 126044.59968656999,
    "YEAR_3": 126643.91650021801,
    "YEAR_4": 127243.23331386599,
    "YEAR_5": 127842.550127514,
    "YEAR_6": 128441.866941162,
    "YEAR_7": 129041.18375480999,
    "YEAR_8": 129640.50056845798,
    "YEAR_9": 130239.817382106,
    "YEAR_10": 130839.134195754,
    "SUBSCRIPTION_Hear": 1218774.358508625
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Hear,here",
    "No of Households": 5838090,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 2627140.5,
    "No of HH's Considered for CPL": 262.71405,
    "Revenue Per month": 7881.4214999999995,
    "YEAR_1": 47448.9080461035,
    "YEAR_2": 95237.44230748502,
    "YEAR_3": 95690.277261189,
    "YEAR_4": 96143.112214893,
    "YEAR_5": 96595.94716859699,
    "YEAR_6": 97048.782122301,
    "YEAR_7": 97501.61707600497,
    "YEAR_8": 97954.45202970899,
    "YEAR_9": 98407.28698341301,
    "YEAR_10": 98860.121937117,
    "SUBSCRIPTION_Hear": 920887.9471468126
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Hear,here",
    "No of Households": 3624820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 1631169,
    "No of HH's Considered for CPL": 163.11690000000002,
    "Revenue Per month": 4893.5070000000005,
    "YEAR_1": 29460.619973943005,
    "YEAR_2": 59132.110951530005,
    "YEAR_3": 59413.27228972201,
    "YEAR_4": 59694.433627914004,
    "YEAR_5": 59975.59496610601,
    "YEAR_6": 60256.75630429801,
    "YEAR_7": 60537.91764249,
    "YEAR_8": 60819.07898068201,
    "YEAR_9": 61100.24031887401,
    "YEAR_10": 61381.401657066,
    "SUBSCRIPTION_Hear": 571771.4267126251
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Hear,here",
    "No of Households": 3108960,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 1399032,
    "No of HH's Considered for CPL": 139.9032,
    "Revenue Per month": 4197.096,
    "YEAR_1": 25267.982706503997,
    "YEAR_2": 50716.82667383999,
    "YEAR_3": 50957.975021615996,
    "YEAR_4": 51199.12336939199,
    "YEAR_5": 51440.27171716799,
    "YEAR_6": 51681.420064943995,
    "YEAR_7": 51922.56841271999,
    "YEAR_8": 52163.716760496,
    "YEAR_9": 52404.865108271995,
    "YEAR_10": 52646.013456048,
    "SUBSCRIPTION_Hear": 490400.763291
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Hear,here",
    "No of Households": 3041540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 1368693,
    "No of HH's Considered for CPL": 136.8693,
    "Revenue Per month": 4106.079000000001,
    "YEAR_1": 24720.028601571004,
    "YEAR_2": 49616.99635941001,
    "YEAR_3": 49852.915234434004,
    "YEAR_4": 50088.83410945801,
    "YEAR_5": 50324.75298448201,
    "YEAR_6": 50560.67185950601,
    "YEAR_7": 50796.59073453,
    "YEAR_8": 51032.509609554014,
    "YEAR_9": 51268.428484578006,
    "YEAR_10": 51504.34735960201,
    "SUBSCRIPTION_Hear": 479766.0753371251
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Hear,here",
    "No of Households": 2679850,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 1205932.5,
    "No of HH's Considered for CPL": 120.59325000000001,
    "Revenue Per month": 3617.7975000000006,
    "YEAR_1": 21780.403561327505,
    "YEAR_2": 43716.70525252501,
    "YEAR_3": 43924.56942568501,
    "YEAR_4": 44132.43359884501,
    "YEAR_5": 44340.297772005004,
    "YEAR_6": 44548.161945165004,
    "YEAR_7": 44756.026118325,
    "YEAR_8": 44963.890291485004,
    "YEAR_9": 45171.754464645004,
    "YEAR_10": 45379.618637805004,
    "SUBSCRIPTION_Hear": 422713.86106781254
  },
  {
    "DMA": "Houston",
    "PlatformName": "Hear,here",
    "No of Households": 2666330,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 1199848.5,
    "No of HH's Considered for CPL": 119.98485000000001,
    "Revenue Per month": 3599.5455,
    "YEAR_1": 21670.5201513795,
    "YEAR_2": 43496.151917445,
    "YEAR_3": 43702.96740369301,
    "YEAR_4": 43909.782889941,
    "YEAR_5": 44116.598376189,
    "YEAR_6": 44323.41386243701,
    "YEAR_7": 44530.229348684996,
    "YEAR_8": 44737.044834933,
    "YEAR_9": 44943.86032118101,
    "YEAR_10": 45150.675807429,
    "SUBSCRIPTION_Hear": 420581.24491331255
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Hear,here",
    "No of Households": 2617350,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 1177807.5,
    "No of HH's Considered for CPL": 117.78075000000001,
    "Revenue Per month": 3533.4225000000006,
    "YEAR_1": 21272.4366144525,
    "YEAR_2": 42697.135471275,
    "YEAR_3": 42900.15179443501,
    "YEAR_4": 43103.16811759501,
    "YEAR_5": 43306.184440755,
    "YEAR_6": 43509.200763915,
    "YEAR_7": 43712.21708707501,
    "YEAR_8": 43915.233410235,
    "YEAR_9": 44118.249733395,
    "YEAR_10": 44321.26605655501,
    "SUBSCRIPTION_Hear": 412855.24348968756
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Hear,here",
    "No of Households": 2596190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 1168285.5,
    "No of HH's Considered for CPL": 116.82855,
    "Revenue Per month": 3504.8565000000003,
    "YEAR_1": 21100.4593249185,
    "YEAR_2": 42351.949926135,
    "YEAR_3": 42553.324961199,
    "YEAR_4": 42754.699996263,
    "YEAR_5": 42956.07503132701,
    "YEAR_6": 43157.45006639101,
    "YEAR_7": 43358.825101455,
    "YEAR_8": 43560.200136519,
    "YEAR_9": 43761.575171583005,
    "YEAR_10": 43962.95020664701,
    "SUBSCRIPTION_Hear": 409517.5099224375
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Hear,here",
    "No of Households": 2593210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 1166944.5,
    "No of HH's Considered for CPL": 116.69445,
    "Revenue Per month": 3500.8335,
    "YEAR_1": 21076.239460891502,
    "YEAR_2": 42303.336838965,
    "YEAR_3": 42504.480728541006,
    "YEAR_4": 42705.624618117006,
    "YEAR_5": 42906.768507693,
    "YEAR_6": 43107.912397269,
    "YEAR_7": 43309.05628684501,
    "YEAR_8": 43510.20017642101,
    "YEAR_9": 43711.344065997,
    "YEAR_10": 43912.48795557301,
    "SUBSCRIPTION_Hear": 409047.45103631256
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Hear,here",
    "No of Households": 2138870,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 962491.5,
    "No of HH's Considered for CPL": 96.24915,
    "Revenue Per month": 2887.4745,
    "YEAR_1": 17383.6042186005,
    "YEAR_2": 34891.635488355,
    "YEAR_3": 35057.538223227,
    "YEAR_4": 35223.440958098996,
    "YEAR_5": 35389.343692971,
    "YEAR_6": 35555.246427843,
    "YEAR_7": 35721.149162715,
    "YEAR_8": 35887.05189758699,
    "YEAR_9": 36052.954632459,
    "YEAR_10": 36218.857367331,
    "SUBSCRIPTION_Hear": 337380.8220691875
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Hear,here",
    "No of Households": 2116440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 952398,
    "No of HH's Considered for CPL": 95.2398,
    "Revenue Per month": 2857.194,
    "YEAR_1": 17201.305040706,
    "YEAR_2": 34525.73228526,
    "YEAR_3": 34689.895223724,
    "YEAR_4": 34854.058162188005,
    "YEAR_5": 35018.221100652,
    "YEAR_6": 35182.384039116,
    "YEAR_7": 35346.54697758,
    "YEAR_8": 35510.709916044005,
    "YEAR_9": 35674.872854508,
    "YEAR_10": 35839.035792972,
    "SUBSCRIPTION_Hear": 333842.76139275
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Hear,here",
    "No of Households": 2068720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 930924,
    "No of HH's Considered for CPL": 93.0924,
    "Revenue Per month": 2792.772,
    "YEAR_1": 16813.462117428,
    "YEAR_2": 33747.27036588,
    "YEAR_3": 33907.731873911995,
    "YEAR_4": 34068.193381944,
    "YEAR_5": 34228.654889976,
    "YEAR_6": 34389.116398008,
    "YEAR_7": 34549.577906039995,
    "YEAR_8": 34710.039414072,
    "YEAR_9": 34870.500922104,
    "YEAR_10": 35030.962430136,
    "SUBSCRIPTION_Hear": 326315.5096995
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Hear,here",
    "No of Households": 1937250,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 871762.5,
    "No of HH's Considered for CPL": 87.17625000000001,
    "Revenue Per month": 2615.2875000000004,
    "YEAR_1": 15744.943485337502,
    "YEAR_2": 31602.58493962501,
    "YEAR_3": 31752.84889822501,
    "YEAR_4": 31903.11285682501,
    "YEAR_5": 32053.37681542501,
    "YEAR_6": 32203.64077402501,
    "YEAR_7": 32353.904732625,
    "YEAR_8": 32504.168691225,
    "YEAR_9": 32654.432649825,
    "YEAR_10": 32804.696608425,
    "SUBSCRIPTION_Hear": 305577.71045156254
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Hear,here",
    "No of Households": 1839480,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 827766,
    "No of HH's Considered for CPL": 82.7766,
    "Revenue Per month": 2483.2980000000002,
    "YEAR_1": 14950.320631002001,
    "YEAR_2": 30007.651539420003,
    "YEAR_3": 30150.331909308003,
    "YEAR_4": 30293.012279196006,
    "YEAR_5": 30435.692649084005,
    "YEAR_6": 30578.373018972005,
    "YEAR_7": 30721.05338886,
    "YEAR_8": 30863.733758748007,
    "YEAR_9": 31006.414128636003,
    "YEAR_10": 31149.094498524,
    "SUBSCRIPTION_Hear": 290155.67780175
  },
  {
    "DMA": "Denver",
    "PlatformName": "Hear,here",
    "No of Households": 1792540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 806643,
    "No of HH's Considered for CPL": 80.6643,
    "Revenue Per month": 2419.929,
    "YEAR_1": 14568.817135221001,
    "YEAR_2": 29241.91385091,
    "YEAR_3": 29380.953291534002,
    "YEAR_4": 29519.992732158003,
    "YEAR_5": 29659.032172782,
    "YEAR_6": 29798.071613406002,
    "YEAR_7": 29937.11105403,
    "YEAR_8": 30076.150494653997,
    "YEAR_9": 30215.189935278002,
    "YEAR_10": 30354.229375902003,
    "SUBSCRIPTION_Hear": 282751.461655875
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Hear,here",
    "No of Households": 1775140,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 798813,
    "No of HH's Considered for CPL": 79.88130000000001,
    "Revenue Per month": 2396.4390000000003,
    "YEAR_1": 14427.399137211001,
    "YEAR_2": 28958.065623810005,
    "YEAR_3": 29095.755422994003,
    "YEAR_4": 29233.445222178,
    "YEAR_5": 29371.135021362003,
    "YEAR_6": 29508.824820546004,
    "YEAR_7": 29646.514619730002,
    "YEAR_8": 29784.204418914003,
    "YEAR_9": 29921.894218098,
    "YEAR_10": 30059.584017282003,
    "SUBSCRIPTION_Hear": 280006.822522125
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Hear,here",
    "No of Households": 1720970,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 774436.5,
    "No of HH's Considered for CPL": 77.44365,
    "Revenue Per month": 2323.3095000000003,
    "YEAR_1": 13987.1340250155,
    "YEAR_2": 28074.384103005003,
    "YEAR_3": 28207.872173637003,
    "YEAR_4": 28341.360244269003,
    "YEAR_5": 28474.848314901006,
    "YEAR_6": 28608.336385533003,
    "YEAR_7": 28741.824456165006,
    "YEAR_8": 28875.312526797006,
    "YEAR_9": 29008.800597429003,
    "YEAR_10": 29142.288668061006,
    "SUBSCRIPTION_Hear": 271462.1614948125
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Hear,here",
    "No of Households": 1552420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 698589,
    "No of HH's Considered for CPL": 69.8589,
    "Revenue Per month": 2095.7670000000003,
    "YEAR_1": 12617.248762683002,
    "YEAR_2": 25324.808316930004,
    "YEAR_3": 25445.222705682,
    "YEAR_4": 25565.637094434005,
    "YEAR_5": 25686.051483186002,
    "YEAR_6": 25806.465871938006,
    "YEAR_7": 25926.880260690003,
    "YEAR_8": 26047.294649442003,
    "YEAR_9": 26167.709038194,
    "YEAR_10": 26288.123426946004,
    "SUBSCRIPTION_Hear": 244875.44161012504
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Hear,here",
    "No of Households": 1502080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 675936,
    "No of HH's Considered for CPL": 67.59360000000001,
    "Revenue Per month": 2027.8080000000002,
    "YEAR_1": 12208.111864992003,
    "YEAR_2": 24503.60603232001,
    "YEAR_3": 24620.115768768006,
    "YEAR_4": 24736.62550521601,
    "YEAR_5": 24853.135241664,
    "YEAR_6": 24969.644978112003,
    "YEAR_7": 25086.154714559998,
    "YEAR_8": 25202.664451007997,
    "YEAR_9": 25319.174187456,
    "YEAR_10": 25435.683923903995,
    "SUBSCRIPTION_Hear": 236934.91666800002
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Hear,here",
    "No of Households": 1323400,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 595530,
    "No of HH's Considered for CPL": 59.553000000000004,
    "Revenue Per month": 1786.5900000000001,
    "YEAR_1": 10755.89531991,
    "YEAR_2": 21588.778376100003,
    "YEAR_3": 21691.42869114,
    "YEAR_4": 21794.079006180003,
    "YEAR_5": 21896.729321220002,
    "YEAR_6": 21999.37963626,
    "YEAR_7": 22102.029951299995,
    "YEAR_8": 22204.68026634,
    "YEAR_9": 22307.330581380003,
    "YEAR_10": 22409.980896420006,
    "SUBSCRIPTION_Hear": 208750.31204625004
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Hear,here",
    "No of Households": 1293400,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 582030,
    "No of HH's Considered for CPL": 58.203,
    "Revenue Per month": 1746.0900000000001,
    "YEAR_1": 10512.071185410003,
    "YEAR_2": 21099.3848811,
    "YEAR_3": 21199.70822814,
    "YEAR_4": 21300.03157518,
    "YEAR_5": 21400.35492222,
    "YEAR_6": 21500.67826926,
    "YEAR_7": 21601.001616300004,
    "YEAR_8": 21701.324963340005,
    "YEAR_9": 21801.64831038,
    "YEAR_10": 21901.971657420003,
    "SUBSCRIPTION_Hear": 204018.17560875003
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Hear,here",
    "No of Households": 1289510,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 580279.5,
    "No of HH's Considered for CPL": 58.027950000000004,
    "Revenue Per month": 1740.8385,
    "YEAR_1": 10480.4553226365,
    "YEAR_2": 21035.926857914998,
    "YEAR_3": 21135.948474771,
    "YEAR_4": 21235.970091627,
    "YEAR_5": 21335.991708482998,
    "YEAR_6": 21436.013325339,
    "YEAR_7": 21536.034942195,
    "YEAR_8": 21636.056559050998,
    "YEAR_9": 21736.078175907,
    "YEAR_10": 21836.099792763,
    "SUBSCRIPTION_Hear": 203404.5752506875
  },
  {
    "DMA": "Saint Louis",
    "PlatformName": "Hear,here",
    "No of Households": 1255160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 564822,
    "No of HH's Considered for CPL": 56.482200000000006,
    "Revenue Per month": 1694.4660000000001,
    "YEAR_1": 10201.276688634001,
    "YEAR_2": 20475.571306140002,
    "YEAR_3": 20572.928544636,
    "YEAR_4": 20670.285783132,
    "YEAR_5": 20767.643021628002,
    "YEAR_6": 20865.000260124,
    "YEAR_7": 20962.357498619996,
    "YEAR_8": 21059.714737116003,
    "YEAR_9": 21157.071975612,
    "YEAR_10": 21254.429214108,
    "SUBSCRIPTION_Hear": 197986.27902975003
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Hear,here",
    "No of Households": 1207280,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 543276,
    "No of HH's Considered for CPL": 54.327600000000004,
    "Revenue Per month": 1629.8280000000002,
    "YEAR_1": 9812.133369972,
    "YEAR_2": 19694.49928812,
    "YEAR_3": 19788.142685688006,
    "YEAR_4": 19881.786083256004,
    "YEAR_5": 19975.429480824005,
    "YEAR_6": 20069.072878392006,
    "YEAR_7": 20162.716275960003,
    "YEAR_8": 20256.359673528004,
    "YEAR_9": 20350.003071096,
    "YEAR_10": 20443.646468664003,
    "SUBSCRIPTION_Hear": 190433.78927550002
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Hear,here",
    "No of Households": 1174940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 528723,
    "No of HH's Considered for CPL": 52.8723,
    "Revenue Per month": 1586.169,
    "YEAR_1": 9549.290952981,
    "YEAR_2": 19166.93310051,
    "YEAR_3": 19258.068026574,
    "YEAR_4": 19349.202952638003,
    "YEAR_5": 19440.337878702005,
    "YEAR_6": 19531.472804766003,
    "YEAR_7": 19622.607730830005,
    "YEAR_8": 19713.742656894003,
    "YEAR_9": 19804.877582958,
    "YEAR_10": 19896.012509022,
    "SUBSCRIPTION_Hear": 185332.546195875
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Hear,here",
    "No of Households": 1168540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 525843,
    "No of HH's Considered for CPL": 52.584300000000006,
    "Revenue Per month": 1577.5290000000002,
    "YEAR_1": 9497.275137621002,
    "YEAR_2": 19062.529154910004,
    "YEAR_3": 19153.167661134004,
    "YEAR_4": 19243.806167358,
    "YEAR_5": 19334.444673582002,
    "YEAR_6": 19425.083179806003,
    "YEAR_7": 19515.721686030003,
    "YEAR_8": 19606.360192254004,
    "YEAR_9": 19696.998698478004,
    "YEAR_10": 19787.637204701998,
    "SUBSCRIPTION_Hear": 184323.02375587507
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Hear,here",
    "No of Households": 1149480,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 517266,
    "No of HH's Considered for CPL": 51.726600000000005,
    "Revenue Per month": 1551.7980000000002,
    "YEAR_1": 9342.365537502,
    "YEAR_2": 18751.60115442,
    "YEAR_3": 18840.761260308005,
    "YEAR_4": 18929.921366196006,
    "YEAR_5": 19019.081472084,
    "YEAR_6": 19108.241577972003,
    "YEAR_7": 19197.401683860004,
    "YEAR_8": 19286.561789748004,
    "YEAR_9": 19375.721895636,
    "YEAR_10": 19464.882001524005,
    "SUBSCRIPTION_Hear": 181316.53973925003
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Hear,here",
    "No of Households": 1148120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 516654,
    "No of HH's Considered for CPL": 51.665400000000005,
    "Revenue Per month": 1549.9620000000002,
    "YEAR_1": 9331.312176738002,
    "YEAR_2": 18729.41531598,
    "YEAR_3": 18818.469932652002,
    "YEAR_4": 18907.524549324004,
    "YEAR_5": 18996.579165996005,
    "YEAR_6": 19085.633782668003,
    "YEAR_7": 19174.688399340004,
    "YEAR_8": 19263.743016012002,
    "YEAR_9": 19352.797632684003,
    "YEAR_10": 19441.852249356,
    "SUBSCRIPTION_Hear": 181102.01622075005
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Hear,here",
    "No of Households": 1107010,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 498154.5,
    "No of HH's Considered for CPL": 49.815450000000006,
    "Revenue Per month": 1494.4635,
    "YEAR_1": 8997.1918377615,
    "YEAR_2": 18058.783096665,
    "YEAR_3": 18144.648991521004,
    "YEAR_4": 18230.514886377,
    "YEAR_5": 18316.380781233,
    "YEAR_6": 18402.246676089,
    "YEAR_7": 18488.112570945003,
    "YEAR_8": 18573.978465801003,
    "YEAR_9": 18659.844360657,
    "YEAR_10": 18745.710255513004,
    "SUBSCRIPTION_Hear": 174617.41192256252
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Hear,here",
    "No of Households": 1059540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 476793,
    "No of HH's Considered for CPL": 47.679300000000005,
    "Revenue Per month": 1430.3790000000001,
    "YEAR_1": 8611.380782271002,
    "YEAR_2": 17284.399456410003,
    "YEAR_3": 17366.583312234,
    "YEAR_4": 17448.767168058002,
    "YEAR_5": 17530.951023882,
    "YEAR_6": 17613.134879706,
    "YEAR_7": 17695.31873553,
    "YEAR_8": 17777.502591353998,
    "YEAR_9": 17859.686447177995,
    "YEAR_10": 17941.870303002,
    "SUBSCRIPTION_Hear": 167129.594699625
  },
  {
    "DMA": "Columbus, OH",
    "PlatformName": "Hear,here",
    "No of Households": 1023600,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 460620,
    "No of HH's Considered for CPL": 46.062000000000005,
    "Revenue Per month": 1381.8600000000001,
    "YEAR_1": 8319.279469140001,
    "YEAR_2": 16698.106049400005,
    "YEAR_3": 16777.502197560003,
    "YEAR_4": 16856.89834572,
    "YEAR_5": 16936.29449388,
    "YEAR_6": 17015.690642040005,
    "YEAR_7": 17095.086790200003,
    "YEAR_8": 17174.48293836,
    "YEAR_9": 17253.87908652,
    "YEAR_10": 17333.275234680004,
    "SUBSCRIPTION_Hear": 161460.49524750005
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Hear,here",
    "No of Households": 1019550,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 458797.5,
    "No of HH's Considered for CPL": 45.87975,
    "Revenue Per month": 1376.3925,
    "YEAR_1": 8286.3632109825,
    "YEAR_2": 16632.037927575,
    "YEAR_3": 16711.119935054998,
    "YEAR_4": 16790.201942534997,
    "YEAR_5": 16869.283950015,
    "YEAR_6": 16948.365957495,
    "YEAR_7": 17027.447964975,
    "YEAR_8": 17106.529972455,
    "YEAR_9": 17185.611979934998,
    "YEAR_10": 17264.693987414998,
    "SUBSCRIPTION_Hear": 160821.6568284375
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Hear,here",
    "No of Households": 1014160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 456372,
    "No of HH's Considered for CPL": 45.6372,
    "Revenue Per month": 1369.116,
    "YEAR_1": 8242.556141484,
    "YEAR_2": 16544.11022964,
    "YEAR_3": 16622.774158535998,
    "YEAR_4": 16701.438087432,
    "YEAR_5": 16780.102016327997,
    "YEAR_6": 16858.765945224,
    "YEAR_7": 16937.429874120004,
    "YEAR_8": 17016.093803016,
    "YEAR_9": 17094.757731912,
    "YEAR_10": 17173.421660808002,
    "SUBSCRIPTION_Hear": 159971.4496485
  },
  {
    "DMA": "Austin",
    "PlatformName": "Hear,here",
    "No of Households": 978520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 440334,
    "No of HH's Considered for CPL": 44.0334,
    "Revenue Per month": 1321.002,
    "YEAR_1": 7952.8930696980005,
    "YEAR_2": 15962.71075758,
    "YEAR_3": 16038.610248491997,
    "YEAR_4": 16114.509739403999,
    "YEAR_5": 16190.409230316,
    "YEAR_6": 16266.308721228,
    "YEAR_7": 16342.20821214,
    "YEAR_8": 16418.107703052,
    "YEAR_9": 16494.007193963997,
    "YEAR_10": 16569.906684876,
    "SUBSCRIPTION_Hear": 154349.67156075
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Hear,here",
    "No of Households": 953940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 429273,
    "No of HH's Considered for CPL": 42.9273,
    "Revenue Per month": 1287.819,
    "YEAR_1": 7753.119828830999,
    "YEAR_2": 15561.73435401,
    "YEAR_3": 15635.727282473998,
    "YEAR_4": 15709.720210938001,
    "YEAR_5": 15783.713139402,
    "YEAR_6": 15857.706067866,
    "YEAR_7": 15931.698996329997,
    "YEAR_8": 16005.691924793999,
    "YEAR_9": 16079.684853258,
    "YEAR_10": 16153.677781721999,
    "SUBSCRIPTION_Hear": 150472.474439625
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Hear,here",
    "No of Households": 947350,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 426307.5,
    "No of HH's Considered for CPL": 42.63075,
    "Revenue Per month": 1278.9225,
    "YEAR_1": 7699.5597939525,
    "YEAR_2": 15454.230916274997,
    "YEAR_3": 15527.712687434998,
    "YEAR_4": 15601.194458595,
    "YEAR_5": 15674.676229755003,
    "YEAR_6": 15748.158000914997,
    "YEAR_7": 15821.639772074997,
    "YEAR_8": 15895.121543234998,
    "YEAR_9": 15968.603314395,
    "YEAR_10": 16042.085085555003,
    "SUBSCRIPTION_Hear": 149432.98180218748
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Hear,here",
    "No of Households": 900200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 405090,
    "No of HH's Considered for CPL": 40.509,
    "Revenue Per month": 1215.27,
    "YEAR_1": 7316.34952923,
    "YEAR_2": 14685.0674733,
    "YEAR_3": 14754.89202642,
    "YEAR_4": 14824.71657954,
    "YEAR_5": 14894.54113266,
    "YEAR_6": 14964.365685779998,
    "YEAR_7": 15034.190238899999,
    "YEAR_8": 15104.014792020003,
    "YEAR_9": 15173.839345139999,
    "YEAR_10": 15243.66389826,
    "SUBSCRIPTION_Hear": 141995.64070125003
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Hear,here",
    "No of Households": 888210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 399694.5,
    "No of HH's Considered for CPL": 39.96945,
    "Revenue Per month": 1199.0835,
    "YEAR_1": 7218.901150141501,
    "YEAR_2": 14489.473206465002,
    "YEAR_3": 14558.367748040999,
    "YEAR_4": 14627.262289617001,
    "YEAR_5": 14696.156831193,
    "YEAR_6": 14765.051372769001,
    "YEAR_7": 14833.945914344999,
    "YEAR_8": 14902.840455921,
    "YEAR_9": 14971.734997496998,
    "YEAR_10": 15040.629539073,
    "SUBSCRIPTION_Hear": 140104.3635050625
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Hear,here",
    "No of Households": 870240,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 391608,
    "No of HH's Considered for CPL": 39.1608,
    "Revenue Per month": 1174.824,
    "YEAR_1": 7072.850493576001,
    "YEAR_2": 14196.32650296,
    "YEAR_3": 14263.827190704,
    "YEAR_4": 14331.327878448,
    "YEAR_5": 14398.828566192002,
    "YEAR_6": 14466.329253935999,
    "YEAR_7": 14533.82994168,
    "YEAR_8": 14601.330629424003,
    "YEAR_9": 14668.831317168,
    "YEAR_10": 14736.332004912001,
    "SUBSCRIPTION_Hear": 137269.81377900002
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Hear,here",
    "No of Households": 790580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 355761,
    "No of HH's Considered for CPL": 35.576100000000004,
    "Revenue Per month": 1067.2830000000001,
    "YEAR_1": 6425.416141767,
    "YEAR_2": 12896.823642570002,
    "YEAR_3": 12958.145454618001,
    "YEAR_4": 13019.467266666,
    "YEAR_5": 13080.789078714,
    "YEAR_6": 13142.110890762002,
    "YEAR_7": 13203.432702810002,
    "YEAR_8": 13264.754514858001,
    "YEAR_9": 13326.076326906,
    "YEAR_10": 13387.398138954002,
    "SUBSCRIPTION_Hear": 124704.41415862499
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Hear,here",
    "No of Households": 781030,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 351463.5,
    "No of HH's Considered for CPL": 35.14635,
    "Revenue Per month": 1054.3905,
    "YEAR_1": 6347.7987922845,
    "YEAR_2": 12741.033379995,
    "YEAR_3": 12801.614440563,
    "YEAR_4": 12862.195501130998,
    "YEAR_5": 12922.776561698998,
    "YEAR_6": 12983.357622267,
    "YEAR_7": 13043.938682835,
    "YEAR_8": 13104.519743402998,
    "YEAR_9": 13165.100803971,
    "YEAR_10": 13225.681864539,
    "SUBSCRIPTION_Hear": 123198.0173926875
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Hear,here",
    "No of Households": 772320,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 347544,
    "No of HH's Considered for CPL": 34.754400000000004,
    "Revenue Per month": 1042.632,
    "YEAR_1": 6277.008518568001,
    "YEAR_2": 12598.946135279999,
    "YEAR_3": 12658.851599472002,
    "YEAR_4": 12718.757063664001,
    "YEAR_5": 12778.662527856002,
    "YEAR_6": 12838.567992048,
    "YEAR_7": 12898.47345624,
    "YEAR_8": 12958.378920432,
    "YEAR_9": 13018.284384624,
    "YEAR_10": 13078.189848816,
    "SUBSCRIPTION_Hear": 121824.120447
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Hear,here",
    "No of Households": 772190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 347485.5,
    "No of HH's Considered for CPL": 34.74855,
    "Revenue Per month": 1042.4565,
    "YEAR_1": 6275.951947318501,
    "YEAR_2": 12596.825430135,
    "YEAR_3": 12656.720810799003,
    "YEAR_4": 12716.616191463001,
    "YEAR_5": 12776.511572126998,
    "YEAR_6": 12836.406952791,
    "YEAR_7": 12896.302333455002,
    "YEAR_8": 12956.197714119002,
    "YEAR_9": 13016.093094783,
    "YEAR_10": 13075.988475446999,
    "SUBSCRIPTION_Hear": 121803.6145224375
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Hear,here",
    "No of Households": 766220,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 344799,
    "No of HH's Considered for CPL": 34.4799,
    "Revenue Per month": 1034.397,
    "YEAR_1": 6227.430944553,
    "YEAR_2": 12499.43612463,
    "YEAR_3": 12558.868438661999,
    "YEAR_4": 12618.300752694002,
    "YEAR_5": 12677.733066726,
    "YEAR_6": 12737.165380758,
    "YEAR_7": 12796.59769479,
    "YEAR_8": 12856.030008822,
    "YEAR_9": 12915.462322853999,
    "YEAR_10": 12974.894636886,
    "SUBSCRIPTION_Hear": 120861.919371375
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Hear,here",
    "No of Households": 743340,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 334503,
    "No of HH's Considered for CPL": 33.4503,
    "Revenue Per month": 1003.509,
    "YEAR_1": 6041.474404641001,
    "YEAR_2": 12126.19201911,
    "YEAR_3": 12183.849632214002,
    "YEAR_4": 12241.507245318002,
    "YEAR_5": 12299.164858421998,
    "YEAR_6": 12356.822471525998,
    "YEAR_7": 12414.480084629999,
    "YEAR_8": 12472.137697734,
    "YEAR_9": 12529.795310838,
    "YEAR_10": 12587.452923942003,
    "SUBSCRIPTION_Hear": 117252.87664837501
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Hear,here",
    "No of Households": 739970,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 332986.5,
    "No of HH's Considered for CPL": 33.29865,
    "Revenue Per month": 998.9595,
    "YEAR_1": 6014.084826865501,
    "YEAR_2": 12071.216816505,
    "YEAR_3": 12128.613033537,
    "YEAR_4": 12186.009250569,
    "YEAR_5": 12243.405467601002,
    "YEAR_6": 12300.801684633001,
    "YEAR_7": 12358.197901665,
    "YEAR_8": 12415.594118697001,
    "YEAR_9": 12472.990335729,
    "YEAR_10": 12530.386552761001,
    "SUBSCRIPTION_Hear": 116721.2999885625
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Hear,here",
    "No of Households": 721070,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 324481.5,
    "No of HH's Considered for CPL": 32.44815,
    "Revenue Per month": 973.4445,
    "YEAR_1": 5860.475622130499,
    "YEAR_2": 11762.898914655,
    "YEAR_3": 11818.829141846998,
    "YEAR_4": 11874.759369038999,
    "YEAR_5": 11930.689596230997,
    "YEAR_6": 11986.619823422998,
    "YEAR_7": 12042.550050614998,
    "YEAR_8": 12098.480277807,
    "YEAR_9": 12154.410504999,
    "YEAR_10": 12210.340732191,
    "SUBSCRIPTION_Hear": 113740.05403293749
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Hear,here",
    "No of Households": 720750,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 324337.5,
    "No of HH's Considered for CPL": 32.43375,
    "Revenue Per month": 973.0125,
    "YEAR_1": 5857.8748313625,
    "YEAR_2": 11757.678717374998,
    "YEAR_3": 11813.584123575,
    "YEAR_4": 11869.489529774997,
    "YEAR_5": 11925.394935974999,
    "YEAR_6": 11981.300342175002,
    "YEAR_7": 12037.205748375001,
    "YEAR_8": 12093.111154575001,
    "YEAR_9": 12149.016560775,
    "YEAR_10": 12204.921966975,
    "SUBSCRIPTION_Hear": 113689.5779109375
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Hear,here",
    "No of Households": 687110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 309199.5,
    "No of HH's Considered for CPL": 30.91995,
    "Revenue Per month": 927.5985000000001,
    "YEAR_1": 5584.4667018765,
    "YEAR_2": 11208.905478315,
    "YEAR_3": 11262.201577731,
    "YEAR_4": 11315.497677147001,
    "YEAR_5": 11368.793776563001,
    "YEAR_6": 11422.089875979,
    "YEAR_7": 11475.385975395002,
    "YEAR_8": 11528.682074810999,
    "YEAR_9": 11581.978174227002,
    "YEAR_10": 11635.274273643001,
    "SUBSCRIPTION_Hear": 108383.27558568752
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Hear,here",
    "No of Households": 663900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 298755,
    "No of HH's Considered for CPL": 29.875500000000002,
    "Revenue Per month": 896.2650000000001,
    "YEAR_1": 5395.828096485001,
    "YEAR_2": 10830.278044350003,
    "YEAR_3": 10881.77384619,
    "YEAR_4": 10933.26964803,
    "YEAR_5": 10984.765449870001,
    "YEAR_6": 11036.26125171,
    "YEAR_7": 11087.757053550002,
    "YEAR_8": 11139.252855390003,
    "YEAR_9": 11190.74865723,
    "YEAR_10": 11242.244459070002,
    "SUBSCRIPTION_Hear": 104722.17936187501
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Hear,here",
    "No of Households": 644360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 289962,
    "No of HH's Considered for CPL": 28.9962,
    "Revenue Per month": 869.8860000000001,
    "YEAR_1": 5237.017310214001,
    "YEAR_2": 10511.519747940001,
    "YEAR_3": 10561.499917956,
    "YEAR_4": 10611.480087972,
    "YEAR_5": 10661.460257988001,
    "YEAR_6": 10711.440428003998,
    "YEAR_7": 10761.42059802,
    "YEAR_8": 10811.400768036001,
    "YEAR_9": 10861.380938052002,
    "YEAR_10": 10911.361108068004,
    "SUBSCRIPTION_Hear": 101639.98116224998
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Hear,here",
    "No of Households": 634120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 285354,
    "No of HH's Considered for CPL": 28.535400000000003,
    "Revenue Per month": 856.0620000000001,
    "YEAR_1": 5153.792005638001,
    "YEAR_2": 10344.473434980002,
    "YEAR_3": 10393.659333252,
    "YEAR_4": 10442.845231524001,
    "YEAR_5": 10492.031129796002,
    "YEAR_6": 10541.217028068,
    "YEAR_7": 10590.40292634,
    "YEAR_8": 10639.588824612001,
    "YEAR_9": 10688.774722884002,
    "YEAR_10": 10737.960621156002,
    "SUBSCRIPTION_Hear": 100024.74525825001
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Hear,here",
    "No of Households": 632110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 284449.5,
    "No of HH's Considered for CPL": 28.444950000000002,
    "Revenue Per month": 853.3485000000001,
    "YEAR_1": 5137.4557886265,
    "YEAR_2": 10311.684070815003,
    "YEAR_3": 10360.714062231,
    "YEAR_4": 10409.744053647,
    "YEAR_5": 10458.774045063,
    "YEAR_6": 10507.804036479003,
    "YEAR_7": 10556.834027894998,
    "YEAR_8": 10605.864019311,
    "YEAR_9": 10654.894010727001,
    "YEAR_10": 10703.924002143,
    "SUBSCRIPTION_Hear": 99707.69211693751
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Hear,here",
    "No of Households": 618120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 278154,
    "No of HH's Considered for CPL": 27.8154,
    "Revenue Per month": 834.462,
    "YEAR_1": 5023.752467238,
    "YEAR_2": 10083.46357098,
    "YEAR_3": 10131.408419652,
    "YEAR_4": 10179.353268324,
    "YEAR_5": 10227.298116996,
    "YEAR_6": 10275.242965668001,
    "YEAR_7": 10323.18781434,
    "YEAR_8": 10371.132663011998,
    "YEAR_9": 10419.077511684,
    "YEAR_10": 10467.022360356,
    "SUBSCRIPTION_Hear": 97500.93915825
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Hear,here",
    "No of Households": 608190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 273685.5,
    "No of HH's Considered for CPL": 27.368550000000003,
    "Revenue Per month": 821.0565,
    "YEAR_1": 4943.0466787185005,
    "YEAR_2": 9921.474324135,
    "YEAR_3": 9968.648946399,
    "YEAR_4": 10015.823568663001,
    "YEAR_5": 10062.998190927,
    "YEAR_6": 10110.172813190999,
    "YEAR_7": 10157.347435455002,
    "YEAR_8": 10204.522057719001,
    "YEAR_9": 10251.696679982999,
    "YEAR_10": 10298.871302247,
    "SUBSCRIPTION_Hear": 95934.60199743751
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Hear,here",
    "No of Households": 590390,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 265675.5,
    "No of HH's Considered for CPL": 26.56755,
    "Revenue Per month": 797.0265,
    "YEAR_1": 4798.3776922485,
    "YEAR_2": 9631.100850435,
    "YEAR_3": 9676.894805019,
    "YEAR_4": 9722.688759603,
    "YEAR_5": 9768.482714187003,
    "YEAR_6": 9814.276668771003,
    "YEAR_7": 9860.070623355,
    "YEAR_8": 9905.864577939,
    "YEAR_9": 9951.658532523,
    "YEAR_10": 9997.452487107,
    "SUBSCRIPTION_Hear": 93126.86771118751
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Hear,here",
    "No of Households": 588650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 264892.5,
    "No of HH's Considered for CPL": 26.489250000000002,
    "Revenue Per month": 794.6775,
    "YEAR_1": 4784.235892447499,
    "YEAR_2": 9602.716027724999,
    "YEAR_3": 9648.375018165,
    "YEAR_4": 9694.034008604998,
    "YEAR_5": 9739.692999045,
    "YEAR_6": 9785.351989485,
    "YEAR_7": 9831.010979924999,
    "YEAR_8": 9876.669970365001,
    "YEAR_9": 9922.328960805,
    "YEAR_10": 9967.987951245,
    "SUBSCRIPTION_Hear": 92852.4037978125
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Hear,here",
    "No of Households": 578760,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 260442,
    "No of HH's Considered for CPL": 26.0442,
    "Revenue Per month": 781.326,
    "YEAR_1": 4703.855202774,
    "YEAR_2": 9441.37930554,
    "YEAR_3": 9486.271172196,
    "YEAR_4": 9531.163038851999,
    "YEAR_5": 9576.054905508003,
    "YEAR_6": 9620.946772164001,
    "YEAR_7": 9665.838638820002,
    "YEAR_8": 9710.730505476,
    "YEAR_9": 9755.622372132,
    "YEAR_10": 9800.514238788002,
    "SUBSCRIPTION_Hear": 91292.37615225001
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Hear,here",
    "No of Households": 577130,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 259708.5,
    "No of HH's Considered for CPL": 25.970850000000002,
    "Revenue Per month": 779.1255000000001,
    "YEAR_1": 4690.607424799501,
    "YEAR_2": 9414.788925645002,
    "YEAR_3": 9459.554360373,
    "YEAR_4": 9504.319795101002,
    "YEAR_5": 9549.085229829001,
    "YEAR_6": 9593.850664557001,
    "YEAR_7": 9638.616099285004,
    "YEAR_8": 9683.381534013002,
    "YEAR_9": 9728.146968741,
    "YEAR_10": 9772.912403469,
    "SUBSCRIPTION_Hear": 91035.26340581253
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Hear,here",
    "No of Households": 559650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 251842.5,
    "No of HH's Considered for CPL": 25.184250000000002,
    "Revenue Per month": 755.5275,
    "YEAR_1": 4548.5392290975,
    "YEAR_2": 9129.635649225,
    "YEAR_3": 9173.045237265,
    "YEAR_4": 9216.454825305002,
    "YEAR_5": 9259.864413345,
    "YEAR_6": 9303.274001385,
    "YEAR_7": 9346.683589425,
    "YEAR_8": 9390.093177465,
    "YEAR_9": 9433.502765505,
    "YEAR_10": 9476.912353545,
    "SUBSCRIPTION_Hear": 88278.00524156251
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Hear,here",
    "No of Households": 543710,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 244669.5,
    "No of HH's Considered for CPL": 24.46695,
    "Revenue Per month": 734.0085,
    "YEAR_1": 4418.9873389665,
    "YEAR_2": 8869.604572215001,
    "YEAR_3": 8911.777764591001,
    "YEAR_4": 8953.950956966999,
    "YEAR_5": 8996.124149342999,
    "YEAR_6": 9038.297341719002,
    "YEAR_7": 9080.470534095,
    "YEAR_8": 9122.643726471,
    "YEAR_9": 9164.816918846998,
    "YEAR_10": 9206.990111222998,
    "SUBSCRIPTION_Hear": 85763.66341443751
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Hear,here",
    "No of Households": 510900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 229905,
    "No of HH's Considered for CPL": 22.9905,
    "Revenue Per month": 689.715,
    "YEAR_1": 4152.325010535,
    "YEAR_2": 8334.37121985,
    "YEAR_3": 8373.99948489,
    "YEAR_4": 8413.62774993,
    "YEAR_5": 8453.256014970002,
    "YEAR_6": 8492.884280010001,
    "YEAR_7": 8532.51254505,
    "YEAR_8": 8572.14081009,
    "YEAR_9": 8611.76907513,
    "YEAR_10": 8651.397340169999,
    "SUBSCRIPTION_Hear": 80588.28353062499
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Hear,here",
    "No of Households": 491820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 221319,
    "No of HH's Considered for CPL": 22.1319,
    "Revenue Per month": 663.9570000000001,
    "YEAR_1": 3997.252860993001,
    "YEAR_2": 8023.116957030002,
    "YEAR_3": 8061.265270422001,
    "YEAR_4": 8099.413583814002,
    "YEAR_5": 8137.561897206001,
    "YEAR_6": 8175.710210598001,
    "YEAR_7": 8213.85852399,
    "YEAR_8": 8252.006837382001,
    "YEAR_9": 8290.155150774002,
    "YEAR_10": 8328.303464166002,
    "SUBSCRIPTION_Hear": 77578.64475637503
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Hear,here",
    "No of Households": 490560,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 220752,
    "No of HH's Considered for CPL": 22.075200000000002,
    "Revenue Per month": 662.2560000000001,
    "YEAR_1": 3987.0122473440006,
    "YEAR_2": 8002.562430240001,
    "YEAR_3": 8040.613010976001,
    "YEAR_4": 8078.663591712,
    "YEAR_5": 8116.714172448002,
    "YEAR_6": 8154.764753184001,
    "YEAR_7": 8192.81533392,
    "YEAR_8": 8230.865914656002,
    "YEAR_9": 8268.916495392,
    "YEAR_10": 8306.967076128001,
    "SUBSCRIPTION_Hear": 77379.895026
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Hear,here",
    "No of Households": 484300,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 217935,
    "No of HH's Considered for CPL": 21.7935,
    "Revenue Per month": 653.8050000000001,
    "YEAR_1": 3936.134277945,
    "YEAR_2": 7900.442320950002,
    "YEAR_3": 7938.007341030001,
    "YEAR_4": 7975.57236111,
    "YEAR_5": 8013.137381190001,
    "YEAR_6": 8050.702401270001,
    "YEAR_7": 8088.267421350001,
    "YEAR_8": 8125.8324414300005,
    "YEAR_9": 8163.397461510001,
    "YEAR_10": 8200.962481590002,
    "SUBSCRIPTION_Hear": 76392.455889375
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Hear,here",
    "No of Households": 481910,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 216859.5,
    "No of HH's Considered for CPL": 21.685950000000002,
    "Revenue Per month": 650.5785000000001,
    "YEAR_1": 3916.7096218965003,
    "YEAR_2": 7861.453972515001,
    "YEAR_3": 7898.833610811001,
    "YEAR_4": 7936.2132491070015,
    "YEAR_5": 7973.592887403002,
    "YEAR_6": 8010.972525699001,
    "YEAR_7": 8048.352163995,
    "YEAR_8": 8085.731802291001,
    "YEAR_9": 8123.111440587001,
    "YEAR_10": 8160.491078883,
    "SUBSCRIPTION_Hear": 76015.46235318751
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Hear,here",
    "No of Households": 472310,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 212539.5,
    "No of HH's Considered for CPL": 21.25395,
    "Revenue Per month": 637.6185,
    "YEAR_1": 3838.6858988565,
    "YEAR_2": 7704.848054115,
    "YEAR_3": 7741.483062651001,
    "YEAR_4": 7778.118071187001,
    "YEAR_5": 7814.753079723,
    "YEAR_6": 7851.388088259001,
    "YEAR_7": 7888.023096795,
    "YEAR_8": 7924.658105331,
    "YEAR_9": 7961.293113867002,
    "YEAR_10": 7997.928122403,
    "SUBSCRIPTION_Hear": 74501.1786931875
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Hear,here",
    "No of Households": 471190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 212035.5,
    "No of HH's Considered for CPL": 21.20355,
    "Revenue Per month": 636.1065,
    "YEAR_1": 3829.5831311684997,
    "YEAR_2": 7686.577363635,
    "YEAR_3": 7723.125498699,
    "YEAR_4": 7759.673633762999,
    "YEAR_5": 7796.221768827001,
    "YEAR_6": 7832.7699038910005,
    "YEAR_7": 7869.318038954999,
    "YEAR_8": 7905.866174019,
    "YEAR_9": 7942.414309082999,
    "YEAR_10": 7978.962444147,
    "SUBSCRIPTION_Hear": 74324.51226618749
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Hear,here",
    "No of Households": 457620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 205929,
    "No of HH's Considered for CPL": 20.5929,
    "Revenue Per month": 617.787,
    "YEAR_1": 3719.2933476629996,
    "YEAR_2": 7465.20837273,
    "YEAR_3": 7500.703942602,
    "YEAR_4": 7536.199512474001,
    "YEAR_5": 7571.695082346,
    "YEAR_6": 7607.190652218001,
    "YEAR_7": 7642.68622209,
    "YEAR_8": 7678.181791962,
    "YEAR_9": 7713.677361834001,
    "YEAR_10": 7749.172931706,
    "SUBSCRIPTION_Hear": 72184.009217625
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Hear,here",
    "No of Households": 456390,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 205375.5,
    "No of HH's Considered for CPL": 20.53755,
    "Revenue Per month": 616.1265,
    "YEAR_1": 3709.2965581484996,
    "YEAR_2": 7445.1432394349995,
    "YEAR_3": 7480.543403619,
    "YEAR_4": 7515.9435678029995,
    "YEAR_5": 7551.343731986999,
    "YEAR_6": 7586.7438961709995,
    "YEAR_7": 7622.144060355,
    "YEAR_8": 7657.544224539,
    "YEAR_9": 7692.944388723,
    "YEAR_10": 7728.3445529069995,
    "SUBSCRIPTION_Hear": 71989.9916236875
  },
  {
    "DMA": "Madison",
    "PlatformName": "Hear,here",
    "No of Households": 454700,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 204615,
    "No of HH's Considered for CPL": 20.4615,
    "Revenue Per month": 613.845,
    "YEAR_1": 3695.5611319050004,
    "YEAR_2": 7417.57407255,
    "YEAR_3": 7452.8431508700005,
    "YEAR_4": 7488.11222919,
    "YEAR_5": 7523.3813075100015,
    "YEAR_6": 7558.65038583,
    "YEAR_7": 7593.919464150001,
    "YEAR_8": 7629.18854247,
    "YEAR_9": 7664.457620790001,
    "YEAR_10": 7699.72669911,
    "SUBSCRIPTION_Hear": 71723.41460437501
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Hear,here",
    "No of Households": 451790,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 203305.5,
    "No of HH's Considered for CPL": 20.330550000000002,
    "Revenue Per month": 609.9165,
    "YEAR_1": 3671.9101908585003,
    "YEAR_2": 7370.102903535,
    "YEAR_3": 7405.146265959001,
    "YEAR_4": 7440.189628383,
    "YEAR_5": 7475.232990807,
    "YEAR_6": 7510.276353231,
    "YEAR_7": 7545.319715655001,
    "YEAR_8": 7580.363078079001,
    "YEAR_9": 7615.406440503,
    "YEAR_10": 7650.449802927001,
    "SUBSCRIPTION_Hear": 71264.39736993749
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Hear,here",
    "No of Households": 442050,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 198922.5,
    "No of HH's Considered for CPL": 19.89225,
    "Revenue Per month": 596.7675,
    "YEAR_1": 3592.7486218575004,
    "YEAR_2": 7211.213148825001,
    "YEAR_3": 7245.501022305,
    "YEAR_4": 7279.788895785,
    "YEAR_5": 7314.076769265001,
    "YEAR_6": 7348.364642745,
    "YEAR_7": 7382.652516225,
    "YEAR_8": 7416.940389705001,
    "YEAR_9": 7451.228263185,
    "YEAR_10": 7485.516136665001,
    "SUBSCRIPTION_Hear": 69728.03040656251
  },
  {
    "DMA": "Springfield, MO",
    "PlatformName": "Hear,here",
    "No of Households": 436340,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 196353,
    "No of HH's Considered for CPL": 19.6353,
    "Revenue Per month": 589.059,
    "YEAR_1": 3546.340761591,
    "YEAR_2": 7118.06525361,
    "YEAR_3": 7151.910227514,
    "YEAR_4": 7185.755201417998,
    "YEAR_5": 7219.600175321999,
    "YEAR_6": 7253.445149226,
    "YEAR_7": 7287.290123129999,
    "YEAR_8": 7321.135097033999,
    "YEAR_9": 7354.980070938,
    "YEAR_10": 7388.825044842,
    "SUBSCRIPTION_Hear": 68827.34710462499
  },
  {
    "DMA": "Columbia, SC",
    "PlatformName": "Hear,here",
    "No of Households": 435570,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 196006.5,
    "No of HH's Considered for CPL": 19.60065,
    "Revenue Per month": 588.0195000000001,
    "YEAR_1": 3540.082608805501,
    "YEAR_2": 7105.504153905003,
    "YEAR_3": 7139.289402297002,
    "YEAR_4": 7173.074650689002,
    "YEAR_5": 7206.8598990810015,
    "YEAR_6": 7240.645147473002,
    "YEAR_7": 7274.430395865002,
    "YEAR_8": 7308.215644257001,
    "YEAR_9": 7342.000892649001,
    "YEAR_10": 7375.7861410410005,
    "SUBSCRIPTION_Hear": 68705.8889360625
  },
  {
    "DMA": "Rochester, NY",
    "PlatformName": "Hear,here",
    "No of Households": 434190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 195385.5,
    "No of HH's Considered for CPL": 19.53855,
    "Revenue Per month": 586.1565,
    "YEAR_1": 3528.8666986185003,
    "YEAR_2": 7082.992053135001,
    "YEAR_3": 7116.670260999001,
    "YEAR_4": 7150.3484688630015,
    "YEAR_5": 7184.0266767269995,
    "YEAR_6": 7217.704884591,
    "YEAR_7": 7251.383092455,
    "YEAR_8": 7285.061300319,
    "YEAR_9": 7318.739508183,
    "YEAR_10": 7352.417716047001,
    "SUBSCRIPTION_Hear": 68488.2106599375
  },
  {
    "DMA": "Portland",
    "PlatformName": "Hear,here",
    "No of Households": 429130,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 193108.5,
    "No of HH's Considered for CPL": 19.310850000000002,
    "Revenue Per month": 579.3255,
    "YEAR_1": 3487.7416945995005,
    "YEAR_2": 7000.447683645001,
    "YEAR_3": 7033.733409573,
    "YEAR_4": 7067.019135501,
    "YEAR_5": 7100.304861428999,
    "YEAR_6": 7133.590587357001,
    "YEAR_7": 7166.876313285,
    "YEAR_8": 7200.162039213001,
    "YEAR_9": 7233.447765141001,
    "YEAR_10": 7266.733491069001,
    "SUBSCRIPTION_Hear": 67690.0569808125
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Hear,here",
    "No of Households": 427650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 192442.5,
    "No of HH's Considered for CPL": 19.24425,
    "Revenue Per month": 577.3275,
    "YEAR_1": 3475.7130372975,
    "YEAR_2": 6976.304271225,
    "YEAR_3": 7009.4752000650005,
    "YEAR_4": 7042.646128904999,
    "YEAR_5": 7075.817057745,
    "YEAR_6": 7108.987986585001,
    "YEAR_7": 7142.158915425,
    "YEAR_8": 7175.329844265,
    "YEAR_9": 7208.500773104999,
    "YEAR_10": 7241.671701945001,
    "SUBSCRIPTION_Hear": 67456.6049165625
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Hear,here",
    "No of Households": 424380,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 190971,
    "No of HH's Considered for CPL": 19.0971,
    "Revenue Per month": 572.913,
    "YEAR_1": 3449.136206637,
    "YEAR_2": 6922.960380270001,
    "YEAR_3": 6955.8776695980005,
    "YEAR_4": 6988.7949589260015,
    "YEAR_5": 7021.712248254,
    "YEAR_6": 7054.629537582001,
    "YEAR_7": 7087.54682691,
    "YEAR_8": 7120.464116237999,
    "YEAR_9": 7153.3814055660005,
    "YEAR_10": 7186.298694894,
    "SUBSCRIPTION_Hear": 66940.802044875
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Hear,here",
    "No of Households": 412370,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 185566.5,
    "No of HH's Considered for CPL": 18.55665,
    "Revenue Per month": 556.6995000000001,
    "YEAR_1": 3351.5252781255003,
    "YEAR_2": 6727.039851105002,
    "YEAR_3": 6759.025577577,
    "YEAR_4": 6791.011304049001,
    "YEAR_5": 6822.997030521001,
    "YEAR_6": 6854.982756993001,
    "YEAR_7": 6886.968483465,
    "YEAR_8": 6918.954209937001,
    "YEAR_9": 6950.939936409,
    "YEAR_10": 6982.925662881001,
    "SUBSCRIPTION_Hear": 65046.370091062505
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Hear,here",
    "No of Households": 407620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 183429,
    "No of HH's Considered for CPL": 18.3429,
    "Revenue Per month": 550.287,
    "YEAR_1": 3312.919790163,
    "YEAR_2": 6649.552547730002,
    "YEAR_3": 6681.1698376019995,
    "YEAR_4": 6712.787127474001,
    "YEAR_5": 6744.404417346001,
    "YEAR_6": 6776.0217072179985,
    "YEAR_7": 6807.638997090001,
    "YEAR_8": 6839.256286962,
    "YEAR_9": 6870.873576834001,
    "YEAR_10": 6902.490866706001,
    "SUBSCRIPTION_Hear": 64297.115155125
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Hear,here",
    "No of Households": 403470,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 181561.5,
    "No of HH's Considered for CPL": 18.15615,
    "Revenue Per month": 544.6845,
    "YEAR_1": 3279.1907848905,
    "YEAR_2": 6581.853114255,
    "YEAR_3": 6613.148506886999,
    "YEAR_4": 6644.443899518999,
    "YEAR_5": 6675.739292151,
    "YEAR_6": 6707.034684782999,
    "YEAR_7": 6738.330077414999,
    "YEAR_8": 6769.625470046999,
    "YEAR_9": 6800.9208626790005,
    "YEAR_10": 6832.216255310999,
    "SUBSCRIPTION_Hear": 63642.50294793749
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Hear,here",
    "No of Households": 401510,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 180679.5,
    "No of HH's Considered for CPL": 18.06795,
    "Revenue Per month": 542.0385,
    "YEAR_1": 3263.2609414365,
    "YEAR_2": 6549.879405915,
    "YEAR_3": 6581.022769970999,
    "YEAR_4": 6612.166134027,
    "YEAR_5": 6643.309498083,
    "YEAR_6": 6674.452862139,
    "YEAR_7": 6705.596226195001,
    "YEAR_8": 6736.739590250999,
    "YEAR_9": 6767.882954307,
    "YEAR_10": 6799.0263183629995,
    "SUBSCRIPTION_Hear": 63333.3367006875
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Hear,here",
    "No of Households": 388650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 174892.5,
    "No of HH's Considered for CPL": 17.489250000000002,
    "Revenue Per month": 524.6775,
    "YEAR_1": 3158.7416624475,
    "YEAR_2": 6340.092727725,
    "YEAR_3": 6370.238598165,
    "YEAR_4": 6400.384468605001,
    "YEAR_5": 6430.530339044999,
    "YEAR_6": 6460.676209485,
    "YEAR_7": 6490.822079925001,
    "YEAR_8": 6520.967950364999,
    "YEAR_9": 6551.113820805,
    "YEAR_10": 6581.259691245001,
    "SUBSCRIPTION_Hear": 61304.82754781249
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Hear,here",
    "No of Households": 385730,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 173578.5,
    "No of HH's Considered for CPL": 17.35785,
    "Revenue Per month": 520.7355,
    "YEAR_1": 3135.0094466895007,
    "YEAR_2": 6292.458427544998,
    "YEAR_3": 6322.377806433,
    "YEAR_4": 6352.297185321001,
    "YEAR_5": 6382.2165642089985,
    "YEAR_6": 6412.135943097001,
    "YEAR_7": 6442.055321985001,
    "YEAR_8": 6471.974700872999,
    "YEAR_9": 6501.894079761,
    "YEAR_10": 6531.8134586490005,
    "SUBSCRIPTION_Hear": 60844.23293456249
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Hear,here",
    "No of Households": 378040,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 170118,
    "No of HH's Considered for CPL": 17.0118,
    "Revenue Per month": 510.35400000000004,
    "YEAR_1": 3072.5091935460005,
    "YEAR_2": 6167.010561660001,
    "YEAR_3": 6196.333461084,
    "YEAR_4": 6225.656360508001,
    "YEAR_5": 6254.979259932001,
    "YEAR_6": 6284.302159356001,
    "YEAR_7": 6313.625058780002,
    "YEAR_8": 6342.947958204,
    "YEAR_9": 6372.270857628,
    "YEAR_10": 6401.593757052001,
    "SUBSCRIPTION_Hear": 59631.22862775
  },
  {
    "DMA": "Charleston, SC",
    "PlatformName": "Hear,here",
    "No of Households": 374290,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 168430.5,
    "No of HH's Considered for CPL": 16.84305,
    "Revenue Per month": 505.29150000000004,
    "YEAR_1": 3042.0311767335,
    "YEAR_2": 6105.836374785,
    "YEAR_3": 6134.8684032090005,
    "YEAR_4": 6163.900431633,
    "YEAR_5": 6192.932460057,
    "YEAR_6": 6221.964488481,
    "YEAR_7": 6250.996516905,
    "YEAR_8": 6280.028545329001,
    "YEAR_9": 6309.060573753001,
    "YEAR_10": 6338.092602176999,
    "SUBSCRIPTION_Hear": 59039.711573062494
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Hear,here",
    "No of Households": 373520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 168084,
    "No of HH's Considered for CPL": 16.808400000000002,
    "Revenue Per month": 504.25200000000007,
    "YEAR_1": 3035.773023948,
    "YEAR_2": 6093.275275080001,
    "YEAR_3": 6122.2475779920005,
    "YEAR_4": 6151.219880904002,
    "YEAR_5": 6180.1921838160015,
    "YEAR_6": 6209.164486728001,
    "YEAR_7": 6238.136789640001,
    "YEAR_8": 6267.109092552001,
    "YEAR_9": 6296.081395464002,
    "YEAR_10": 6325.053698376001,
    "SUBSCRIPTION_Hear": 58918.25340450002
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Hear,here",
    "No of Households": 373080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 167886,
    "No of HH's Considered for CPL": 16.788600000000002,
    "Revenue Per month": 503.6580000000001,
    "YEAR_1": 3032.1969366420003,
    "YEAR_2": 6086.097503820001,
    "YEAR_3": 6115.035677868002,
    "YEAR_4": 6143.973851916001,
    "YEAR_5": 6172.912025964002,
    "YEAR_6": 6201.850200012001,
    "YEAR_7": 6230.788374060001,
    "YEAR_8": 6259.726548108,
    "YEAR_9": 6288.664722156,
    "YEAR_10": 6317.602896204001,
    "SUBSCRIPTION_Hear": 58848.848736750006
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Hear,here",
    "No of Households": 371730,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 167278.5,
    "No of HH's Considered for CPL": 16.72785,
    "Revenue Per month": 501.8355,
    "YEAR_1": 3021.2248505895,
    "YEAR_2": 6064.074796545,
    "YEAR_3": 6092.908257033,
    "YEAR_4": 6121.741717521,
    "YEAR_5": 6150.575178009,
    "YEAR_6": 6179.4086384970005,
    "YEAR_7": 6208.242098985001,
    "YEAR_8": 6237.075559473001,
    "YEAR_9": 6265.909019961001,
    "YEAR_10": 6294.742480449,
    "SUBSCRIPTION_Hear": 58635.9025970625
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Hear,here",
    "No of Households": 369270,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 166171.5,
    "No of HH's Considered for CPL": 16.617150000000002,
    "Revenue Per month": 498.51450000000006,
    "YEAR_1": 3001.2312715605003,
    "YEAR_2": 6023.944529955002,
    "YEAR_3": 6052.587179067001,
    "YEAR_4": 6081.229828179001,
    "YEAR_5": 6109.872477291002,
    "YEAR_6": 6138.515126403001,
    "YEAR_7": 6167.157775515001,
    "YEAR_8": 6195.800424627001,
    "YEAR_9": 6224.443073738999,
    "YEAR_10": 6253.085722851001,
    "SUBSCRIPTION_Hear": 58247.86740918751
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Hear,here",
    "No of Households": 360880,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 162396,
    "No of HH's Considered for CPL": 16.2396,
    "Revenue Per month": 487.188,
    "YEAR_1": 2933.0417886120003,
    "YEAR_2": 5887.077482520001,
    "YEAR_3": 5915.069356247999,
    "YEAR_4": 5943.0612299760005,
    "YEAR_5": 5971.053103703999,
    "YEAR_6": 5999.0449774319995,
    "YEAR_7": 6027.036851159999,
    "YEAR_8": 6055.0287248879995,
    "YEAR_9": 6083.020598616,
    "YEAR_10": 6111.012472344,
    "SUBSCRIPTION_Hear": 56924.4465855
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Hear,here",
    "No of Households": 360200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 162090,
    "No of HH's Considered for CPL": 16.209,
    "Revenue Per month": 486.27,
    "YEAR_1": 2927.51510823,
    "YEAR_2": 5875.984563299999,
    "YEAR_3": 5903.9236924199995,
    "YEAR_4": 5931.8628215399995,
    "YEAR_5": 5959.80195066,
    "YEAR_6": 5987.74107978,
    "YEAR_7": 6015.680208899999,
    "YEAR_8": 6043.61933802,
    "YEAR_9": 6071.558467139999,
    "YEAR_10": 6099.49759626,
    "SUBSCRIPTION_Hear": 56817.18482624999
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Hear,here",
    "No of Households": 353500,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 159075,
    "No of HH's Considered for CPL": 15.9075,
    "Revenue Per month": 477.225,
    "YEAR_1": 2873.061051525,
    "YEAR_2": 5766.68668275,
    "YEAR_3": 5794.10612235,
    "YEAR_4": 5821.52556195,
    "YEAR_5": 5848.945001550001,
    "YEAR_6": 5876.364441150001,
    "YEAR_7": 5903.78388075,
    "YEAR_8": 5931.2033203500005,
    "YEAR_9": 5958.62275995,
    "YEAR_10": 5986.04219955,
    "SUBSCRIPTION_Hear": 55760.34102187501
  },
  {
    "DMA": "Jackson, MS",
    "PlatformName": "Hear,here",
    "No of Households": 340720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 153324,
    "No of HH's Considered for CPL": 15.332400000000002,
    "Revenue Per month": 459.97200000000004,
    "YEAR_1": 2769.191970228,
    "YEAR_2": 5558.20505388,
    "YEAR_3": 5584.633205112002,
    "YEAR_4": 5611.061356344,
    "YEAR_5": 5637.489507576,
    "YEAR_6": 5663.917658807999,
    "YEAR_7": 5690.345810040001,
    "YEAR_8": 5716.773961272001,
    "YEAR_9": 5743.202112504,
    "YEAR_10": 5769.6302637359995,
    "SUBSCRIPTION_Hear": 53744.45089950001
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Hear,here",
    "No of Households": 338310,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 152239.5,
    "No of HH's Considered for CPL": 15.22395,
    "Revenue Per month": 456.7185,
    "YEAR_1": 2749.6047647565,
    "YEAR_2": 5518.890443115,
    "YEAR_3": 5545.131661251,
    "YEAR_4": 5571.3728793870005,
    "YEAR_5": 5597.614097523,
    "YEAR_6": 5623.855315659001,
    "YEAR_7": 5650.096533795,
    "YEAR_8": 5676.337751931001,
    "YEAR_9": 5702.578970067,
    "YEAR_10": 5728.820188203001,
    "SUBSCRIPTION_Hear": 53364.302605687495
  },
  {
    "DMA": "Boise",
    "PlatformName": "Hear,here",
    "No of Households": 330040,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 148518,
    "No of HH's Considered for CPL": 14.8518,
    "Revenue Per month": 445.55400000000003,
    "YEAR_1": 2682.3905783460004,
    "YEAR_2": 5383.9809696600005,
    "YEAR_3": 5409.580720283999,
    "YEAR_4": 5435.180470908001,
    "YEAR_5": 5460.780221531999,
    "YEAR_6": 5486.379972156001,
    "YEAR_7": 5511.979722779999,
    "YEAR_8": 5537.579473404001,
    "YEAR_9": 5563.179224027999,
    "YEAR_10": 5588.778974652001,
    "SUBSCRIPTION_Hear": 52059.81032775
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Hear,here",
    "No of Households": 329080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 148086,
    "No of HH's Considered for CPL": 14.8086,
    "Revenue Per month": 444.258,
    "YEAR_1": 2674.588206042,
    "YEAR_2": 5368.3203778199995,
    "YEAR_3": 5393.845665468,
    "YEAR_4": 5419.370953116,
    "YEAR_5": 5444.896240764,
    "YEAR_6": 5470.421528411999,
    "YEAR_7": 5495.946816059999,
    "YEAR_8": 5521.472103708,
    "YEAR_9": 5546.997391356,
    "YEAR_10": 5572.522679004,
    "SUBSCRIPTION_Hear": 51908.38196175
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Hear,here",
    "No of Households": 322100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 144945,
    "No of HH's Considered for CPL": 14.4945,
    "Revenue Per month": 434.83500000000004,
    "YEAR_1": 2617.858457415,
    "YEAR_2": 5254.45482465,
    "YEAR_3": 5279.438704409999,
    "YEAR_4": 5304.422584170002,
    "YEAR_5": 5329.40646393,
    "YEAR_6": 5354.390343690001,
    "YEAR_7": 5379.3742234500005,
    "YEAR_8": 5404.35810321,
    "YEAR_9": 5429.34198297,
    "YEAR_10": 5454.32586273,
    "SUBSCRIPTION_Hear": 50807.371550625
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Hear,here",
    "No of Households": 320820,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 144369,
    "No of HH's Considered for CPL": 14.436900000000001,
    "Revenue Per month": 433.107,
    "YEAR_1": 2607.4552943430003,
    "YEAR_2": 5233.57403553,
    "YEAR_3": 5258.458631322001,
    "YEAR_4": 5283.343227114,
    "YEAR_5": 5308.227822905999,
    "YEAR_6": 5333.1124186980005,
    "YEAR_7": 5357.997014490001,
    "YEAR_8": 5382.881610282001,
    "YEAR_9": 5407.766206074001,
    "YEAR_10": 5432.650801865999,
    "SUBSCRIPTION_Hear": 50605.467062625
  },
  {
    "DMA": "Reno",
    "PlatformName": "Hear,here",
    "No of Households": 306940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 138123,
    "No of HH's Considered for CPL": 13.8123,
    "Revenue Per month": 414.369,
    "YEAR_1": 2494.645994781,
    "YEAR_2": 5007.147978510001,
    "YEAR_3": 5030.955963774,
    "YEAR_4": 5054.763949038,
    "YEAR_5": 5078.571934302001,
    "YEAR_6": 5102.379919566,
    "YEAR_7": 5126.187904830001,
    "YEAR_8": 5149.995890094,
    "YEAR_9": 5173.803875358,
    "YEAR_10": 5197.611860622001,
    "SUBSCRIPTION_Hear": 48416.065270875
  },
  {
    "DMA": "Washington",
    "PlatformName": "Hear,here",
    "No of Households": 305320,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 137394,
    "No of HH's Considered for CPL": 13.7394,
    "Revenue Per month": 412.182,
    "YEAR_1": 2481.479491518,
    "YEAR_2": 4980.72072978,
    "YEAR_3": 5004.403058772,
    "YEAR_4": 5028.085387764,
    "YEAR_5": 5051.767716756,
    "YEAR_6": 5075.450045748,
    "YEAR_7": 5099.13237474,
    "YEAR_8": 5122.814703732,
    "YEAR_9": 5146.497032724001,
    "YEAR_10": 5170.179361716,
    "SUBSCRIPTION_Hear": 48160.529903250004
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Hear,here",
    "No of Households": 302600,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 136170,
    "No of HH's Considered for CPL": 13.617,
    "Revenue Per month": 408.51000000000005,
    "YEAR_1": 2459.3727699900005,
    "YEAR_2": 4936.349052900001,
    "YEAR_3": 4959.820403459999,
    "YEAR_4": 4983.291754020001,
    "YEAR_5": 5006.763104580002,
    "YEAR_6": 5030.234455140001,
    "YEAR_7": 5053.705805700001,
    "YEAR_8": 5077.17715626,
    "YEAR_9": 5100.648506820001,
    "YEAR_10": 5124.119857379999,
    "SUBSCRIPTION_Hear": 47731.48286625
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Hear,here",
    "No of Households": 299530,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 134788.5,
    "No of HH's Considered for CPL": 13.478850000000001,
    "Revenue Per month": 404.36550000000005,
    "YEAR_1": 2434.4214335595,
    "YEAR_2": 4886.267785245001,
    "YEAR_3": 4909.501009413,
    "YEAR_4": 4932.734233581,
    "YEAR_5": 4955.967457749,
    "YEAR_6": 4979.200681917,
    "YEAR_7": 5002.433906085,
    "YEAR_8": 5025.667130253,
    "YEAR_9": 5048.900354420999,
    "YEAR_10": 5072.133578589001,
    "SUBSCRIPTION_Hear": 47247.2275708125
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Hear,here",
    "No of Households": 291650,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 131242.5,
    "No of HH's Considered for CPL": 13.12425,
    "Revenue Per month": 393.7275,
    "YEAR_1": 2370.3769608975,
    "YEAR_2": 4757.720427225,
    "YEAR_3": 4780.342434465,
    "YEAR_4": 4802.964441705,
    "YEAR_5": 4825.5864489450005,
    "YEAR_6": 4848.208456185001,
    "YEAR_7": 4870.830463425001,
    "YEAR_8": 4893.452470665,
    "YEAR_9": 4916.074477904999,
    "YEAR_10": 4938.696485145001,
    "SUBSCRIPTION_Hear": 46004.2530665625
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Hear,here",
    "No of Households": 290990,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 130945.5,
    "No of HH's Considered for CPL": 13.09455,
    "Revenue Per month": 392.8365,
    "YEAR_1": 2365.0128299385,
    "YEAR_2": 4746.953770335,
    "YEAR_3": 4769.524584279,
    "YEAR_4": 4792.095398223,
    "YEAR_5": 4814.666212167,
    "YEAR_6": 4837.237026111001,
    "YEAR_7": 4859.807840055,
    "YEAR_8": 4882.378653998999,
    "YEAR_9": 4904.949467943,
    "YEAR_10": 4927.520281887,
    "SUBSCRIPTION_Hear": 45900.1460649375
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Hear,here",
    "No of Households": 285360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 128412,
    "No of HH's Considered for CPL": 12.8412,
    "Revenue Per month": 385.236,
    "YEAR_1": 2319.255167364,
    "YEAR_2": 4655.1109244399995,
    "YEAR_3": 4677.245044056,
    "YEAR_4": 4699.379163672,
    "YEAR_5": 4721.513283288,
    "YEAR_6": 4743.647402904,
    "YEAR_7": 4765.78152252,
    "YEAR_8": 4787.915642135999,
    "YEAR_9": 4810.0497617519995,
    "YEAR_10": 4832.183881368001,
    "SUBSCRIPTION_Hear": 45012.0817935
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Hear,here",
    "No of Households": 284300,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 127935,
    "No of HH's Considered for CPL": 12.7935,
    "Revenue Per month": 383.805,
    "YEAR_1": 2310.640047945,
    "YEAR_2": 4637.81902095,
    "YEAR_3": 4659.870921029999,
    "YEAR_4": 4681.92282111,
    "YEAR_5": 4703.974721189999,
    "YEAR_6": 4726.026621270001,
    "YEAR_7": 4748.078521349999,
    "YEAR_8": 4770.13042143,
    "YEAR_9": 4792.182321509999,
    "YEAR_10": 4814.23422159,
    "SUBSCRIPTION_Hear": 44844.879639374994
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Hear,here",
    "No of Households": 283100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 127395,
    "No of HH's Considered for CPL": 12.739500000000001,
    "Revenue Per month": 382.18500000000006,
    "YEAR_1": 2300.887082565,
    "YEAR_2": 4618.24328115,
    "YEAR_3": 4640.20210251,
    "YEAR_4": 4662.16092387,
    "YEAR_5": 4684.11974523,
    "YEAR_6": 4706.07856659,
    "YEAR_7": 4728.03738795,
    "YEAR_8": 4749.99620931,
    "YEAR_9": 4771.95503067,
    "YEAR_10": 4793.91385203,
    "SUBSCRIPTION_Hear": 44655.594181875
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Hear,here",
    "No of Households": 282090,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 126940.5,
    "No of HH's Considered for CPL": 12.69405,
    "Revenue Per month": 380.8215,
    "YEAR_1": 2292.6783367035,
    "YEAR_2": 4601.767033485,
    "YEAR_3": 4623.6475135890005,
    "YEAR_4": 4645.527993693,
    "YEAR_5": 4667.408473797,
    "YEAR_6": 4689.288953901,
    "YEAR_7": 4711.169434004999,
    "YEAR_8": 4733.049914109001,
    "YEAR_9": 4754.930394213,
    "YEAR_10": 4776.810874317,
    "SUBSCRIPTION_Hear": 44496.278921812496
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Hear,here",
    "No of Households": 276580,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 124461,
    "No of HH's Considered for CPL": 12.446100000000001,
    "Revenue Per month": 373.38300000000004,
    "YEAR_1": 2247.8959706670003,
    "YEAR_2": 4511.88176157,
    "YEAR_3": 4533.334855218001,
    "YEAR_4": 4554.787948866,
    "YEAR_5": 4576.241042514001,
    "YEAR_6": 4597.694136162001,
    "YEAR_7": 4619.147229810001,
    "YEAR_8": 4640.600323458001,
    "YEAR_9": 4662.053417106,
    "YEAR_10": 4683.506510754001,
    "SUBSCRIPTION_Hear": 43627.143196125005
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Hear,here",
    "No of Households": 265790,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 119605.5,
    "No of HH's Considered for CPL": 11.960550000000001,
    "Revenue Per month": 358.8165,
    "YEAR_1": 2160.2005569585,
    "YEAR_2": 4335.863234535,
    "YEAR_3": 4356.479395359,
    "YEAR_4": 4377.095556183,
    "YEAR_5": 4397.711717007,
    "YEAR_6": 4418.327877831,
    "YEAR_7": 4438.9440386549995,
    "YEAR_8": 4459.5601994789995,
    "YEAR_9": 4480.1763603029995,
    "YEAR_10": 4500.792521127,
    "SUBSCRIPTION_Hear": 41925.15145743749
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Hear,here",
    "No of Households": 264420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 118989,
    "No of HH's Considered for CPL": 11.898900000000001,
    "Revenue Per month": 356.96700000000004,
    "YEAR_1": 2149.0659214830002,
    "YEAR_2": 4313.5142649300005,
    "YEAR_3": 4334.024160882001,
    "YEAR_4": 4354.534056834001,
    "YEAR_5": 4375.043952786001,
    "YEAR_6": 4395.553848738001,
    "YEAR_7": 4416.063744690001,
    "YEAR_8": 4436.573640642001,
    "YEAR_9": 4457.083536594001,
    "YEAR_10": 4477.593432546001,
    "SUBSCRIPTION_Hear": 41709.050560125004
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Hear,here",
    "No of Households": 260780,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 117351,
    "No of HH's Considered for CPL": 11.735100000000001,
    "Revenue Per month": 352.05300000000005,
    "YEAR_1": 2119.4819264970006,
    "YEAR_2": 4254.13452087,
    "YEAR_3": 4274.362078038001,
    "YEAR_4": 4294.589635206001,
    "YEAR_5": 4314.817192374001,
    "YEAR_6": 4335.044749542,
    "YEAR_7": 4355.2723067100005,
    "YEAR_8": 4375.499863878001,
    "YEAR_9": 4395.727421046001,
    "YEAR_10": 4415.954978214,
    "SUBSCRIPTION_Hear": 41134.88467237501
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Hear,here",
    "No of Households": 260220,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 117099,
    "No of HH's Considered for CPL": 11.709900000000001,
    "Revenue Per month": 351.297,
    "YEAR_1": 2114.9305426530004,
    "YEAR_2": 4244.9991756300005,
    "YEAR_3": 4265.183296062,
    "YEAR_4": 4285.367416494001,
    "YEAR_5": 4305.551536926,
    "YEAR_6": 4325.735657358,
    "YEAR_7": 4345.91977779,
    "YEAR_8": 4366.103898222001,
    "YEAR_9": 4386.288018654001,
    "YEAR_10": 4406.472139086,
    "SUBSCRIPTION_Hear": 41046.55145887501
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Hear,here",
    "No of Households": 258360,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 116262,
    "No of HH's Considered for CPL": 11.6262,
    "Revenue Per month": 348.786,
    "YEAR_1": 2099.813446314,
    "YEAR_2": 4214.65677894,
    "YEAR_3": 4234.696627356,
    "YEAR_4": 4254.736475772,
    "YEAR_5": 4274.776324188,
    "YEAR_6": 4294.816172604,
    "YEAR_7": 4314.85602102,
    "YEAR_8": 4334.895869436001,
    "YEAR_9": 4354.935717852,
    "YEAR_10": 4374.975566268,
    "SUBSCRIPTION_Hear": 40753.15899975
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Hear,here",
    "No of Households": 254120,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 114354,
    "No of HH's Considered for CPL": 11.435400000000001,
    "Revenue Per month": 343.062,
    "YEAR_1": 2065.352968638,
    "YEAR_2": 4145.48916498,
    "YEAR_3": 4165.200135252,
    "YEAR_4": 4184.911105524,
    "YEAR_5": 4204.622075796,
    "YEAR_6": 4224.333046068001,
    "YEAR_7": 4244.044016340001,
    "YEAR_8": 4263.754986612001,
    "YEAR_9": 4283.465956884001,
    "YEAR_10": 4303.176927156001,
    "SUBSCRIPTION_Hear": 40084.35038325
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Hear,here",
    "No of Households": 250840,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 112878,
    "No of HH's Considered for CPL": 11.2878,
    "Revenue Per month": 338.634,
    "YEAR_1": 2038.694863266,
    "YEAR_2": 4091.9821428600003,
    "YEAR_3": 4111.438697964,
    "YEAR_4": 4130.895253068,
    "YEAR_5": 4150.351808172,
    "YEAR_6": 4169.808363276001,
    "YEAR_7": 4189.2649183799995,
    "YEAR_8": 4208.721473484001,
    "YEAR_9": 4228.1780285879995,
    "YEAR_10": 4247.634583692,
    "SUBSCRIPTION_Hear": 39566.97013275
  },
  {
    "DMA": "Macon",
    "PlatformName": "Hear,here",
    "No of Households": 250620,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 112779,
    "No of HH's Considered for CPL": 11.2779,
    "Revenue Per month": 338.33700000000005,
    "YEAR_1": 2036.9068196130002,
    "YEAR_2": 4088.3932572300005,
    "YEAR_3": 4107.832747902,
    "YEAR_4": 4127.272238574,
    "YEAR_5": 4146.711729246,
    "YEAR_6": 4166.151219918001,
    "YEAR_7": 4185.590710590001,
    "YEAR_8": 4205.030201262,
    "YEAR_9": 4224.469691934,
    "YEAR_10": 4243.909182606,
    "SUBSCRIPTION_Hear": 39532.26779887501
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Hear,here",
    "No of Households": 249140,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 112113,
    "No of HH's Considered for CPL": 11.211300000000001,
    "Revenue Per month": 336.33900000000006,
    "YEAR_1": 2024.8781623110003,
    "YEAR_2": 4064.249844810001,
    "YEAR_3": 4083.574538394001,
    "YEAR_4": 4102.899231978001,
    "YEAR_5": 4122.223925562,
    "YEAR_6": 4141.548619146,
    "YEAR_7": 4160.873312730001,
    "YEAR_8": 4180.198006314001,
    "YEAR_9": 4199.522699898001,
    "YEAR_10": 4218.847393482001,
    "SUBSCRIPTION_Hear": 39298.81573462501
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Hear,here",
    "No of Households": 248110,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 111649.5,
    "No of HH's Considered for CPL": 11.164950000000001,
    "Revenue Per month": 334.9485,
    "YEAR_1": 2016.5068670265,
    "YEAR_2": 4047.4473348150004,
    "YEAR_3": 4066.6921358310005,
    "YEAR_4": 4085.936936847001,
    "YEAR_5": 4105.181737863001,
    "YEAR_6": 4124.426538878999,
    "YEAR_7": 4143.671339895,
    "YEAR_8": 4162.916140910999,
    "YEAR_9": 4182.160941927,
    "YEAR_10": 4201.405742943,
    "SUBSCRIPTION_Hear": 39136.3457169375
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Hear,here",
    "No of Households": 247180,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 111231,
    "No of HH's Considered for CPL": 11.1231,
    "Revenue Per month": 333.69300000000004,
    "YEAR_1": 2008.9483188570002,
    "YEAR_2": 4032.2761364700004,
    "YEAR_3": 4051.4488014780004,
    "YEAR_4": 4070.6214664860004,
    "YEAR_5": 4089.7941314940003,
    "YEAR_6": 4108.966796502,
    "YEAR_7": 4128.13946151,
    "YEAR_8": 4147.312126518,
    "YEAR_9": 4166.484791526,
    "YEAR_10": 4185.657456534,
    "SUBSCRIPTION_Hear": 38989.649487375005
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Hear,here",
    "No of Households": 241200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 108540,
    "No of HH's Considered for CPL": 10.854000000000001,
    "Revenue Per month": 325.62,
    "YEAR_1": 1960.34604138,
    "YEAR_2": 3934.7236998,
    "YEAR_3": 3953.43252252,
    "YEAR_4": 3972.14134524,
    "YEAR_5": 3990.85016796,
    "YEAR_6": 4009.55899068,
    "YEAR_7": 4028.2678134,
    "YEAR_8": 4046.97663612,
    "YEAR_9": 4065.68545884,
    "YEAR_10": 4084.3942815600003,
    "SUBSCRIPTION_Hear": 38046.37695750001
  },
  {
    "DMA": "Lafayette, LA",
    "PlatformName": "Hear,here",
    "No of Households": 240150,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 108067.5,
    "No of HH's Considered for CPL": 10.806750000000001,
    "Revenue Per month": 324.20250000000004,
    "YEAR_1": 1951.8121966725002,
    "YEAR_2": 3917.5949274750005,
    "YEAR_3": 3936.2223063150004,
    "YEAR_4": 3954.8496851550008,
    "YEAR_5": 3973.477063995,
    "YEAR_6": 3992.1044428350006,
    "YEAR_7": 4010.731821675001,
    "YEAR_8": 4029.3592005150003,
    "YEAR_9": 4047.9865793550007,
    "YEAR_10": 4066.6139581950006,
    "SUBSCRIPTION_Hear": 37880.7521821875
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Hear,here",
    "No of Households": 235290,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 105880.5,
    "No of HH's Considered for CPL": 10.58805,
    "Revenue Per month": 317.6415,
    "YEAR_1": 1912.3126868835,
    "YEAR_2": 3838.3131812849997,
    "YEAR_3": 3856.563591309001,
    "YEAR_4": 3874.8140013330003,
    "YEAR_5": 3893.0644113569997,
    "YEAR_6": 3911.314821381,
    "YEAR_7": 3929.5652314050003,
    "YEAR_8": 3947.815641429,
    "YEAR_9": 3966.066051452999,
    "YEAR_10": 3984.316461477001,
    "SUBSCRIPTION_Hear": 37114.14607931249
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Hear,here",
    "No of Households": 232880,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 104796,
    "No of HH's Considered for CPL": 10.479600000000001,
    "Revenue Per month": 314.38800000000003,
    "YEAR_1": 1892.7254814120001,
    "YEAR_2": 3798.99857052,
    "YEAR_3": 3817.062047448,
    "YEAR_4": 3835.1255243760006,
    "YEAR_5": 3853.189001304,
    "YEAR_6": 3871.252478232,
    "YEAR_7": 3889.31595516,
    "YEAR_8": 3907.3794320880006,
    "YEAR_9": 3925.4429090159997,
    "YEAR_10": 3943.506385944,
    "SUBSCRIPTION_Hear": 36733.997785499996
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Hear,here",
    "No of Households": 226710,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 102019.5,
    "No of HH's Considered for CPL": 10.20195,
    "Revenue Per month": 306.0585,
    "YEAR_1": 1842.5789844164997,
    "YEAR_2": 3698.3466417150003,
    "YEAR_3": 3715.931538891,
    "YEAR_4": 3733.516436067,
    "YEAR_5": 3751.1013332429993,
    "YEAR_6": 3768.6862304189995,
    "YEAR_7": 3786.271127595,
    "YEAR_8": 3803.8560247709997,
    "YEAR_9": 3821.4409219469994,
    "YEAR_10": 3839.025819123,
    "SUBSCRIPTION_Hear": 35760.755058187504
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Hear,here",
    "No of Households": 219540,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 98793,
    "No of HH's Considered for CPL": 9.8793,
    "Revenue Per month": 296.379,
    "YEAR_1": 1784.3050162709999,
    "YEAR_2": 3581.38159641,
    "YEAR_3": 3598.410348234,
    "YEAR_4": 3615.439100058,
    "YEAR_5": 3632.467851882,
    "YEAR_6": 3649.4966037060003,
    "YEAR_7": 3666.52535553,
    "YEAR_8": 3683.5541073540003,
    "YEAR_9": 3700.582859178,
    "YEAR_10": 3717.611611002,
    "SUBSCRIPTION_Hear": 34629.774449625
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Hear,here",
    "No of Households": 208490,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 93820.5,
    "No of HH's Considered for CPL": 9.382050000000001,
    "Revenue Per month": 281.46150000000006,
    "YEAR_1": 1694.4964600635003,
    "YEAR_2": 3401.121659085001,
    "YEAR_3": 3417.293311029002,
    "YEAR_4": 3433.464962973,
    "YEAR_5": 3449.6366149170003,
    "YEAR_6": 3465.8082668610014,
    "YEAR_7": 3481.979918805,
    "YEAR_8": 3498.1515707490007,
    "YEAR_9": 3514.3232226930004,
    "YEAR_10": 3530.4948746370014,
    "SUBSCRIPTION_Hear": 32886.7708618125
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Hear,here",
    "No of Households": 197080,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 88686,
    "No of HH's Considered for CPL": 8.8686,
    "Revenue Per month": 266.058,
    "YEAR_1": 1601.762014242,
    "YEAR_2": 3214.9889998200006,
    "YEAR_3": 3230.275628268,
    "YEAR_4": 3245.562256716,
    "YEAR_5": 3260.848885163999,
    "YEAR_6": 3276.135513612,
    "YEAR_7": 3291.42214206,
    "YEAR_8": 3306.708770508,
    "YEAR_9": 3321.9953989559995,
    "YEAR_10": 3337.282027404,
    "SUBSCRIPTION_Hear": 31086.98163675
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Hear,here",
    "No of Households": 188980,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 85041,
    "No of HH's Considered for CPL": 8.504100000000001,
    "Revenue Per month": 255.12300000000005,
    "YEAR_1": 1535.9294979270003,
    "YEAR_2": 3082.8527561700002,
    "YEAR_3": 3097.5111032580003,
    "YEAR_4": 3112.1694503460003,
    "YEAR_5": 3126.827797434001,
    "YEAR_6": 3141.486144522001,
    "YEAR_7": 3156.144491610001,
    "YEAR_8": 3170.8028386980004,
    "YEAR_9": 3185.4611857860004,
    "YEAR_10": 3200.1195328740005,
    "SUBSCRIPTION_Hear": 29809.304798625006
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Hear,here",
    "No of Households": 188900,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 85005,
    "No of HH's Considered for CPL": 8.5005,
    "Revenue Per month": 255.01500000000001,
    "YEAR_1": 1535.279300235,
    "YEAR_2": 3081.54770685,
    "YEAR_3": 3096.19984869,
    "YEAR_4": 3110.85199053,
    "YEAR_5": 3125.5041323699998,
    "YEAR_6": 3140.15627421,
    "YEAR_7": 3154.8084160500002,
    "YEAR_8": 3169.46055789,
    "YEAR_9": 3184.1126997300007,
    "YEAR_10": 3198.76484157,
    "SUBSCRIPTION_Hear": 29796.685768125
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Hear,here",
    "No of Households": 188520,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 84834,
    "No of HH's Considered for CPL": 8.4834,
    "Revenue Per month": 254.50199999999998,
    "YEAR_1": 1532.1908611979998,
    "YEAR_2": 3075.3487225799995,
    "YEAR_3": 3089.9713894919996,
    "YEAR_4": 3104.5940564039997,
    "YEAR_5": 3119.2167233159994,
    "YEAR_6": 3133.839390228,
    "YEAR_7": 3148.4620571399996,
    "YEAR_8": 3163.0847240519997,
    "YEAR_9": 3177.707390964,
    "YEAR_10": 3192.330057876,
    "SUBSCRIPTION_Hear": 29736.74537325
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Hear,here",
    "No of Households": 185470,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 83461.5,
    "No of HH's Considered for CPL": 8.34615,
    "Revenue Per month": 250.3845,
    "YEAR_1": 1507.4020741905001,
    "YEAR_2": 3025.593717255,
    "YEAR_3": 3039.9798090870004,
    "YEAR_4": 3054.365900919,
    "YEAR_5": 3068.751992751,
    "YEAR_6": 3083.138084583,
    "YEAR_7": 3097.524176415,
    "YEAR_8": 3111.910268247,
    "YEAR_9": 3126.296360079,
    "YEAR_10": 3140.682451911,
    "SUBSCRIPTION_Hear": 29255.644835437502
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Hear,here",
    "No of Households": 183100,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 82395,
    "No of HH's Considered for CPL": 8.2395,
    "Revenue Per month": 247.185,
    "YEAR_1": 1488.139967565,
    "YEAR_2": 2986.9316311500006,
    "YEAR_3": 3001.1338925100004,
    "YEAR_4": 3015.336153869999,
    "YEAR_5": 3029.5384152300007,
    "YEAR_6": 3043.7406765900005,
    "YEAR_7": 3057.9429379499998,
    "YEAR_8": 3072.14519931,
    "YEAR_9": 3086.34746067,
    "YEAR_10": 3100.5497220300003,
    "SUBSCRIPTION_Hear": 28881.806056875
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Hear,here",
    "No of Households": 181720,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 81774,
    "No of HH's Considered for CPL": 8.1774,
    "Revenue Per month": 245.322,
    "YEAR_1": 1476.9240573779998,
    "YEAR_2": 2964.41953038,
    "YEAR_3": 2978.5147512119997,
    "YEAR_4": 2992.6099720439997,
    "YEAR_5": 3006.705192876,
    "YEAR_6": 3020.800413708,
    "YEAR_7": 3034.8956345399997,
    "YEAR_8": 3048.9908553719997,
    "YEAR_9": 3063.086076204,
    "YEAR_10": 3077.181297036,
    "SUBSCRIPTION_Hear": 28664.12778075
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Hear,here",
    "No of Households": 181440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 81648,
    "No of HH's Considered for CPL": 8.1648,
    "Revenue Per month": 244.944,
    "YEAR_1": 1474.648365456,
    "YEAR_2": 2959.85185776,
    "YEAR_3": 2973.925360224,
    "YEAR_4": 2987.9988626880004,
    "YEAR_5": 3002.0723651519993,
    "YEAR_6": 3016.145867616,
    "YEAR_7": 3030.2193700800003,
    "YEAR_8": 3044.2928725439997,
    "YEAR_9": 3058.3663750080004,
    "YEAR_10": 3072.439877472,
    "SUBSCRIPTION_Hear": 28619.961174
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Hear,here",
    "No of Households": 174760,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 78642,
    "No of HH's Considered for CPL": 7.8642,
    "Revenue Per month": 235.92600000000002,
    "YEAR_1": 1420.356858174,
    "YEAR_2": 2850.8802395400003,
    "YEAR_3": 2864.435603796,
    "YEAR_4": 2877.9909680519995,
    "YEAR_5": 2891.5463323080003,
    "YEAR_6": 2905.101696564,
    "YEAR_7": 2918.6570608200004,
    "YEAR_8": 2932.2124250760003,
    "YEAR_9": 2945.767789332,
    "YEAR_10": 2959.323153588,
    "SUBSCRIPTION_Hear": 27566.272127250002
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Hear,here",
    "No of Households": 173940,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 78273,
    "No of HH's Considered for CPL": 7.8273,
    "Revenue Per month": 234.81900000000002,
    "YEAR_1": 1413.692331831,
    "YEAR_2": 2837.50348401,
    "YEAR_3": 2850.995244474,
    "YEAR_4": 2864.487004938,
    "YEAR_5": 2877.9787654020006,
    "YEAR_6": 2891.470525866,
    "YEAR_7": 2904.96228633,
    "YEAR_8": 2918.4540467939996,
    "YEAR_9": 2931.9458072580005,
    "YEAR_10": 2945.4375677220005,
    "SUBSCRIPTION_Hear": 27436.927064625
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Hear,here",
    "No of Households": 173370,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 78016.5,
    "No of HH's Considered for CPL": 7.80165,
    "Revenue Per month": 234.04950000000002,
    "YEAR_1": 1409.0596732755002,
    "YEAR_2": 2828.205007605,
    "YEAR_3": 2841.6525556770007,
    "YEAR_4": 2855.1001037490005,
    "YEAR_5": 2868.547651821,
    "YEAR_6": 2881.9951998930005,
    "YEAR_7": 2895.4427479650003,
    "YEAR_8": 2908.8902960370006,
    "YEAR_9": 2922.3378441090003,
    "YEAR_10": 2935.785392181,
    "SUBSCRIPTION_Hear": 27347.016472312505
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Hear,here",
    "No of Households": 171450,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 77152.5,
    "No of HH's Considered for CPL": 7.71525,
    "Revenue Per month": 231.4575,
    "YEAR_1": 1393.4549286675,
    "YEAR_2": 2796.8838239250003,
    "YEAR_3": 2810.182446045,
    "YEAR_4": 2823.481068165,
    "YEAR_5": 2836.779690285,
    "YEAR_6": 2850.078312405,
    "YEAR_7": 2863.3769345250007,
    "YEAR_8": 2876.675556645,
    "YEAR_9": 2889.974178765,
    "YEAR_10": 2903.272800885,
    "SUBSCRIPTION_Hear": 27044.1597403125
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Hear,here",
    "No of Households": 168960,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 76032,
    "No of HH's Considered for CPL": 7.6032,
    "Revenue Per month": 228.096,
    "YEAR_1": 1373.2175255040002,
    "YEAR_2": 2756.2641638400005,
    "YEAR_3": 2769.369647616,
    "YEAR_4": 2782.475131392,
    "YEAR_5": 2795.5806151680003,
    "YEAR_6": 2808.686098944,
    "YEAR_7": 2821.79158272,
    "YEAR_8": 2834.897066496,
    "YEAR_9": 2848.0025502720005,
    "YEAR_10": 2861.108034048,
    "SUBSCRIPTION_Hear": 26651.392416000006
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Hear,here",
    "No of Households": 168210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 75694.5,
    "No of HH's Considered for CPL": 7.569450000000001,
    "Revenue Per month": 227.08350000000002,
    "YEAR_1": 1367.1219221415001,
    "YEAR_2": 2744.0293264650004,
    "YEAR_3": 2757.0766360410003,
    "YEAR_4": 2770.123945617,
    "YEAR_5": 2783.1712551930004,
    "YEAR_6": 2796.2185647690003,
    "YEAR_7": 2809.2658743449997,
    "YEAR_8": 2822.313183921,
    "YEAR_9": 2835.3604934970003,
    "YEAR_10": 2848.407803073,
    "SUBSCRIPTION_Hear": 26533.089005062502
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Hear,here",
    "No of Households": 166190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 74785.5,
    "No of HH's Considered for CPL": 7.47855,
    "Revenue Per month": 224.3565,
    "YEAR_1": 1350.7044304185,
    "YEAR_2": 2711.0768311350002,
    "YEAR_3": 2723.9674581990002,
    "YEAR_4": 2736.8580852630002,
    "YEAR_5": 2749.7487123270002,
    "YEAR_6": 2762.6393393910002,
    "YEAR_7": 2775.5299664550002,
    "YEAR_8": 2788.4205935190002,
    "YEAR_9": 2801.3112205830002,
    "YEAR_10": 2814.2018476470002,
    "SUBSCRIPTION_Hear": 26214.458484937502
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Hear,here",
    "No of Households": 161210,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 72544.5,
    "No of HH's Considered for CPL": 7.25445,
    "Revenue Per month": 217.6335,
    "YEAR_1": 1310.2296240914998,
    "YEAR_2": 2629.837510965,
    "YEAR_3": 2642.341861341,
    "YEAR_4": 2654.846211717,
    "YEAR_5": 2667.350562093,
    "YEAR_6": 2679.854912469,
    "YEAR_7": 2692.359262845,
    "YEAR_8": 2704.863613221,
    "YEAR_9": 2717.367963597,
    "YEAR_10": 2729.872313973,
    "SUBSCRIPTION_Hear": 25428.9238363125
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Hear,here",
    "No of Households": 160200,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 72090,
    "No of HH's Considered for CPL": 7.2090000000000005,
    "Revenue Per month": 216.27,
    "YEAR_1": 1302.0208782299999,
    "YEAR_2": 2613.3612633000002,
    "YEAR_3": 2625.78727242,
    "YEAR_4": 2638.21328154,
    "YEAR_5": 2650.6392906599995,
    "YEAR_6": 2663.0652997800003,
    "YEAR_7": 2675.4913089,
    "YEAR_8": 2687.91731802,
    "YEAR_9": 2700.34332714,
    "YEAR_10": 2712.76933626,
    "SUBSCRIPTION_Hear": 25269.60857625
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Hear,here",
    "No of Households": 155590,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 70015.5,
    "No of HH's Considered for CPL": 7.00155,
    "Revenue Per month": 210.0465,
    "YEAR_1": 1264.5532362285,
    "YEAR_2": 2538.157796235,
    "YEAR_3": 2550.2262279389997,
    "YEAR_4": 2562.294659643,
    "YEAR_5": 2574.363091347,
    "YEAR_6": 2586.431523051,
    "YEAR_7": 2598.4999547549996,
    "YEAR_8": 2610.568386459,
    "YEAR_9": 2622.6368181630005,
    "YEAR_10": 2634.705249867,
    "SUBSCRIPTION_Hear": 24542.4369436875
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Hear,here",
    "No of Households": 155440,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 69948,
    "No of HH's Considered for CPL": 6.994800000000001,
    "Revenue Per month": 209.84400000000002,
    "YEAR_1": 1263.3341155560001,
    "YEAR_2": 2535.7108287600004,
    "YEAR_3": 2547.767625624,
    "YEAR_4": 2559.8244224880004,
    "YEAR_5": 2571.8812193520007,
    "YEAR_6": 2583.9380162160005,
    "YEAR_7": 2595.9948130800003,
    "YEAR_8": 2608.051609944,
    "YEAR_9": 2620.1084068080004,
    "YEAR_10": 2632.1652036720006,
    "SUBSCRIPTION_Hear": 24518.776261500003
  },
  {
    "DMA": "Erie",
    "PlatformName": "Hear,here",
    "No of Households": 152420,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 68589,
    "No of HH's Considered for CPL": 6.8589,
    "Revenue Per month": 205.767,
    "YEAR_1": 1238.789152683,
    "YEAR_2": 2486.4452169300002,
    "YEAR_3": 2498.267765682,
    "YEAR_4": 2510.090314434,
    "YEAR_5": 2521.912863186,
    "YEAR_6": 2533.7354119379997,
    "YEAR_7": 2545.55796069,
    "YEAR_8": 2557.380509442,
    "YEAR_9": 2569.203058194,
    "YEAR_10": 2581.025606946,
    "SUBSCRIPTION_Hear": 24042.407860125
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Hear,here",
    "No of Households": 152190,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 68485.5,
    "No of HH's Considered for CPL": 6.84855,
    "Revenue Per month": 205.4565,
    "YEAR_1": 1236.9198343185,
    "YEAR_2": 2482.693200135,
    "YEAR_3": 2494.4979087989996,
    "YEAR_4": 2506.3026174630004,
    "YEAR_5": 2518.1073261270003,
    "YEAR_6": 2529.9120347909998,
    "YEAR_7": 2541.716743455,
    "YEAR_8": 2553.5214521190005,
    "YEAR_9": 2565.326160783,
    "YEAR_10": 2577.130869447,
    "SUBSCRIPTION_Hear": 24006.128147437495
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Hear,here",
    "No of Households": 151160,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 68022,
    "No of HH's Considered for CPL": 6.8022,
    "Revenue Per month": 204.066,
    "YEAR_1": 1228.548539034,
    "YEAR_2": 2465.89069014,
    "YEAR_3": 2477.615506236,
    "YEAR_4": 2489.340322332,
    "YEAR_5": 2501.0651384279995,
    "YEAR_6": 2512.7899545239998,
    "YEAR_7": 2524.5147706200005,
    "YEAR_8": 2536.239586716,
    "YEAR_9": 2547.964402812,
    "YEAR_10": 2559.689218908,
    "SUBSCRIPTION_Hear": 23843.658129749998
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Hear,here",
    "No of Households": 150700,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 67815,
    "No of HH's Considered for CPL": 6.7815,
    "Revenue Per month": 203.44500000000002,
    "YEAR_1": 1224.8099023050002,
    "YEAR_2": 2458.3866565500007,
    "YEAR_3": 2470.075792470001,
    "YEAR_4": 2481.764928390001,
    "YEAR_5": 2493.454064310001,
    "YEAR_6": 2505.1432002300007,
    "YEAR_7": 2516.8323361500006,
    "YEAR_8": 2528.5214720700005,
    "YEAR_9": 2540.2106079900004,
    "YEAR_10": 2551.8997439100003,
    "SUBSCRIPTION_Hear": 23771.098704375006
  },
  {
    "DMA": "Albany, GA",
    "PlatformName": "Hear,here",
    "No of Households": 144570,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 65056.5,
    "No of HH's Considered for CPL": 6.50565,
    "Revenue Per month": 195.1695,
    "YEAR_1": 1174.9885041554999,
    "YEAR_2": 2358.387252405,
    "YEAR_3": 2369.600911197,
    "YEAR_4": 2380.814569989,
    "YEAR_5": 2392.0282287809996,
    "YEAR_6": 2403.241887573,
    "YEAR_7": 2414.4555463649995,
    "YEAR_8": 2425.6692051570003,
    "YEAR_9": 2436.8828639490002,
    "YEAR_10": 2448.0965227409997,
    "SUBSCRIPTION_Hear": 22804.165492312495
  },
  {
    "DMA": "Idaho Falls-Pocatello-Jackson",
    "PlatformName": "Hear,here",
    "No of Households": 142930,
    "% No of Smart Households": 0.45,
    "% Of HH's Considered": 0.0001,
    "CPL": 30,
    "DMA Consdered": "Y",
    "%increase in App growth Monthly": 0.0133,
    "Increse in Households every month": 0.03,
    "Holiday Months": 6,
    "No of Smart Households": 64318.5,
    "No of HH's Considered for CPL": 6.431850000000001,
    "Revenue Per month": 192.95550000000003,
    "YEAR_1": 1161.6594514695003,
    "YEAR_2": 2331.633741345,
    "YEAR_3": 2342.7201925530007,
    "YEAR_4": 2353.806643761,
    "YEAR_5": 2364.8930949690007,
    "YEAR_6": 2375.9795461770004,
    "YEAR_7": 2387.0659973850006,
    "YEAR_8": 2398.1524485930004,
    "YEAR_9": 2409.238899801,
    "YEAR_10": 2420.3253510090008,
    "SUBSCRIPTION_Hear": 22545.4753670625
  },
  {
    "DMA": "New York",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 17384805,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 3476961,
    "No of HHS for Ads": 8692.4025,
    "Revenue per 1000 Devices Hour": 221.65626375000002,
    "Revenue per 1000 Devices per day": 886.6250550000001,
    "Revenue per month(Video Ad)": 26598.751650000002,
    "Revenue per month (Banner Ad)": 265987.5165,
    "Total Revenue per month from all Ads": 292586.26815,
    "YEAR_1": 3514356.0584845343,
    "YEAR_2": 3521601.529068973,
    "YEAR_3": 3528846.9996534106,
    "YEAR_4": 3536092.4702378493,
    "YEAR_5": 3543337.940822287,
    "YEAR_6": 3550583.4114067256,
    "YEAR_7": 3557828.8819911634,
    "YEAR_8": 3565074.352575602,
    "YEAR_9": 3572319.8231600397,
    "YEAR_10": 3579565.2937444784,
    "AD_Reve": 35469606.76114506
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 13135702.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 2627140.5,
    "No of HHS for Ads": 6567.85125,
    "Revenue per 1000 Devices Hour": 167.480206875,
    "Revenue per 1000 Devices per day": 669.9208275,
    "Revenue per month(Video Ad)": 20097.624825,
    "Revenue per month (Banner Ad)": 200976.24825,
    "Total Revenue per month from all Ads": 221073.873075,
    "YEAR_1": 2655395.655190003,
    "YEAR_2": 2660870.2260045554,
    "YEAR_3": 2666344.7968191076,
    "YEAR_4": 2671819.36763366,
    "YEAR_5": 2677293.938448212,
    "YEAR_6": 2682768.5092627644,
    "YEAR_7": 2688243.0800773166,
    "YEAR_8": 2693717.6508918684,
    "YEAR_9": 2699192.2217064206,
    "YEAR_10": 2704666.792520973,
    "AD_Reve": 26800312.238554884
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 8155845,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1631169,
    "No of HHS for Ads": 4077.9225,
    "Revenue per 1000 Devices Hour": 103.98702374999999,
    "Revenue per 1000 Devices per day": 415.94809499999997,
    "Revenue per month(Video Ad)": 12478.44285,
    "Revenue per month (Banner Ad)": 124784.4285,
    "Total Revenue per month from all Ads": 137262.87135,
    "YEAR_1": 1648712.3834757307,
    "YEAR_2": 1652111.4975318694,
    "YEAR_3": 1655510.6115880087,
    "YEAR_4": 1658909.7256441475,
    "YEAR_5": 1662308.8397002867,
    "YEAR_6": 1665707.953756426,
    "YEAR_7": 1669107.0678125648,
    "YEAR_8": 1672506.1818687045,
    "YEAR_9": 1675905.295924843,
    "YEAR_10": 1679304.4099809825,
    "AD_Reve": 16640083.967283564
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 6995160,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1399032,
    "No of HHS for Ads": 3497.58,
    "Revenue per 1000 Devices Hour": 89.18829,
    "Revenue per 1000 Devices per day": 356.75316,
    "Revenue per month(Video Ad)": 10702.594799999999,
    "Revenue per month (Banner Ad)": 107025.94799999999,
    "Total Revenue per month from all Ads": 117728.5428,
    "YEAR_1": 1414078.727145267,
    "YEAR_2": 1416994.1021531224,
    "YEAR_3": 1419909.4771609777,
    "YEAR_4": 1422824.8521688331,
    "YEAR_5": 1425740.2271766884,
    "YEAR_6": 1428655.6021845436,
    "YEAR_7": 1431570.977192399,
    "YEAR_8": 1434486.352200254,
    "YEAR_9": 1437401.7272081098,
    "YEAR_10": 1440317.1022159653,
    "AD_Reve": 14271979.146806162
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 6843465,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1368693,
    "No of HHS for Ads": 3421.7325,
    "Revenue per 1000 Devices Hour": 87.25417875000001,
    "Revenue per 1000 Devices per day": 349.01671500000003,
    "Revenue per month(Video Ad)": 10470.501450000002,
    "Revenue per month (Banner Ad)": 104705.01450000002,
    "Total Revenue per month from all Ads": 115175.51595000002,
    "YEAR_1": 1383413.428207959,
    "YEAR_2": 1386265.5812435055,
    "YEAR_3": 1389117.734279052,
    "YEAR_4": 1391969.887314598,
    "YEAR_5": 1394822.0403501445,
    "YEAR_6": 1397674.1933856907,
    "YEAR_7": 1400526.3464212376,
    "YEAR_8": 1403378.4994567838,
    "YEAR_9": 1406230.65249233,
    "YEAR_10": 1409082.8055278766,
    "AD_Reve": 13962481.168679178
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 6029662.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1205932.5,
    "No of HHS for Ads": 3014.83125,
    "Revenue per 1000 Devices Hour": 76.87819687500001,
    "Revenue per 1000 Devices per day": 307.51278750000006,
    "Revenue per month(Video Ad)": 9225.383625000002,
    "Revenue per month (Banner Ad)": 92253.83625000002,
    "Total Revenue per month from all Ads": 101479.21987500002,
    "YEAR_1": 1218902.4229775374,
    "YEAR_2": 1221415.407292164,
    "YEAR_3": 1223928.3916067905,
    "YEAR_4": 1226441.375921417,
    "YEAR_5": 1228954.3602360433,
    "YEAR_6": 1231467.34455067,
    "YEAR_7": 1233980.3288652962,
    "YEAR_8": 1236493.313179923,
    "YEAR_9": 1239006.2974945493,
    "YEAR_10": 1241519.2818091756,
    "AD_Reve": 12302108.523933567
  },
  {
    "DMA": "Houston",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 5999242.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1199848.5,
    "No of HHS for Ads": 2999.62125,
    "Revenue per 1000 Devices Hour": 76.490341875,
    "Revenue per 1000 Devices per day": 305.9613675,
    "Revenue per month(Video Ad)": 9178.841025,
    "Revenue per month (Banner Ad)": 91788.41025,
    "Total Revenue per month from all Ads": 100967.251275,
    "YEAR_1": 1212752.9889574775,
    "YEAR_2": 1215253.2951192472,
    "YEAR_3": 1217753.6012810166,
    "YEAR_4": 1220253.9074427863,
    "YEAR_5": 1222754.2136045557,
    "YEAR_6": 1225254.5197663254,
    "YEAR_7": 1227754.8259280948,
    "YEAR_8": 1230255.1320898642,
    "YEAR_9": 1232755.438251634,
    "YEAR_10": 1235255.7444134033,
    "AD_Reve": 12240043.666854404
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 5889037.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1177807.5,
    "No of HHS for Ads": 2944.51875,
    "Revenue per 1000 Devices Hour": 75.08522812500001,
    "Revenue per 1000 Devices per day": 300.34091250000006,
    "Revenue per month(Video Ad)": 9010.227375000002,
    "Revenue per month (Banner Ad)": 90102.27375000002,
    "Total Revenue per month from all Ads": 99112.50112500002,
    "YEAR_1": 1190474.935828594,
    "YEAR_2": 1192929.311818253,
    "YEAR_3": 1195383.687807912,
    "YEAR_4": 1197838.063797571,
    "YEAR_5": 1200292.43978723,
    "YEAR_6": 1202746.815776889,
    "YEAR_7": 1205201.191766548,
    "YEAR_8": 1207655.567756207,
    "YEAR_9": 1210109.9437458657,
    "YEAR_10": 1212564.319735525,
    "AD_Reve": 12015196.277820596
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 5841427.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1168285.5,
    "No of HHS for Ads": 2920.71375,
    "Revenue per 1000 Devices Hour": 74.478200625,
    "Revenue per 1000 Devices per day": 297.9128025,
    "Revenue per month(Video Ad)": 8937.384075,
    "Revenue per month (Banner Ad)": 89373.84075,
    "Total Revenue per month from all Ads": 98311.224825,
    "YEAR_1": 1180850.5257794475,
    "YEAR_2": 1183285.0593346052,
    "YEAR_3": 1185719.5928897632,
    "YEAR_4": 1188154.1264449214,
    "YEAR_5": 1190588.660000079,
    "YEAR_6": 1193023.1935552373,
    "YEAR_7": 1195457.7271103954,
    "YEAR_8": 1197892.2606655532,
    "YEAR_9": 1200326.794220711,
    "YEAR_10": 1202761.3277758693,
    "AD_Reve": 11918059.267776582
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 5834722.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1166944.5,
    "No of HHS for Ads": 2917.36125,
    "Revenue per 1000 Devices Hour": 74.39271187499999,
    "Revenue per 1000 Devices per day": 297.57084749999996,
    "Revenue per month(Video Ad)": 8927.125424999998,
    "Revenue per month (Banner Ad)": 89271.25424999998,
    "Total Revenue per month from all Ads": 98198.37967499997,
    "YEAR_1": 1179495.1031921855,
    "YEAR_2": 1181926.842302409,
    "YEAR_3": 1184358.5814126327,
    "YEAR_4": 1186790.320522856,
    "YEAR_5": 1189222.0596330797,
    "YEAR_6": 1191653.798743303,
    "YEAR_7": 1194085.5378535269,
    "YEAR_8": 1196517.2769637501,
    "YEAR_9": 1198949.0160739738,
    "YEAR_10": 1201380.755184197,
    "AD_Reve": 11904379.291881915
  },
  {
    "DMA": "New York",
    "PlatformName": "Izak",
    "Total Smart Devices": 18429594.24,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 36859.188480000004,
    "YEAR_1": 2702161.8511441923,
    "YEAR_2": 8343740.77784064,
    "YEAR_3": 13761864.560295936,
    "YEAR_4": 19179988.34275123,
    "YEAR_5": 24598112.125206526,
    "YEAR_6": 30016235.907661833,
    "YEAR_7": 35434359.69011711,
    "YEAR_8": 40852483.47257242,
    "YEAR_9": 46270607.25502771,
    "YEAR_10": 51688731.03748301,
    "FIXED_Izak": 272848285.0201006
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Izak",
    "Total Smart Devices": 13947729.05,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 27895.458100000003,
    "YEAR_1": 2045027.1914942402,
    "YEAR_2": 6314639.059180801,
    "YEAR_3": 10415137.50168192,
    "YEAR_4": 14515635.94418304,
    "YEAR_5": 18616134.386684157,
    "YEAR_6": 22716632.829185285,
    "YEAR_7": 26817131.2716864,
    "YEAR_8": 30917629.71418752,
    "YEAR_9": 35018128.156688645,
    "YEAR_10": 39118626.599189766,
    "FIXED_Izak": 206494722.65416178
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Izak",
    "Total Smart Devices": 8667326.99,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 17334.65398,
    "YEAR_1": 1270810.4171353919,
    "YEAR_2": 3924010.9521446396,
    "YEAR_3": 6472121.880865536,
    "YEAR_4": 9020232.80958643,
    "YEAR_5": 11568343.738307327,
    "YEAR_6": 14116454.667028224,
    "YEAR_7": 16664565.595749117,
    "YEAR_8": 19212676.524470013,
    "YEAR_9": 21760787.453190908,
    "YEAR_10": 24308898.3819118,
    "FIXED_Izak": 128318902.42038938
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Izak",
    "Total Smart Devices": 7450689.965,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 14901.379930000003,
    "YEAR_1": 1092426.1232202721,
    "YEAR_2": 3373195.571994241,
    "YEAR_3": 5563626.895080578,
    "YEAR_4": 7754058.218166915,
    "YEAR_5": 9944489.541253252,
    "YEAR_6": 12134920.864339586,
    "YEAR_7": 14325352.18742592,
    "YEAR_8": 16515783.510512259,
    "YEAR_9": 18706214.833598595,
    "YEAR_10": 20896646.156684935,
    "FIXED_Izak": 110306713.90227655
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Izak",
    "Total Smart Devices": 7285114.017,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 14570.228034,
    "YEAR_1": 1068149.2452637535,
    "YEAR_2": 3298233.379600511,
    "YEAR_3": 5439986.9635039475,
    "YEAR_4": 7581740.547407385,
    "YEAR_5": 9723494.131310822,
    "YEAR_6": 11865247.71521426,
    "YEAR_7": 14007001.299117694,
    "YEAR_8": 16148754.883021131,
    "YEAR_9": 18290508.466924567,
    "YEAR_10": 20432262.050828006,
    "FIXED_Izak": 107855378.68219207
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Izak",
    "Total Smart Devices": 6409667.645,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12819.33529,
    "YEAR_1": 939790.5978440159,
    "YEAR_2": 2901887.29092672,
    "YEAR_3": 4786268.045747329,
    "YEAR_4": 6670648.800567934,
    "YEAR_5": 8555029.555388544,
    "YEAR_6": 10439410.310209151,
    "YEAR_7": 12323791.065029759,
    "YEAR_8": 14208171.819850367,
    "YEAR_9": 16092552.574670976,
    "YEAR_10": 17976933.32949158,
    "FIXED_Izak": 94894483.38972637
  },
  {
    "DMA": "Houston",
    "PlatformName": "Izak",
    "Total Smart Devices": 6391466.714,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12782.933428,
    "YEAR_1": 937121.9627800512,
    "YEAR_2": 2893647.0742295035,
    "YEAR_3": 4772676.93006505,
    "YEAR_4": 6651706.785900595,
    "YEAR_5": 8530736.64173614,
    "YEAR_6": 10409766.497571684,
    "YEAR_7": 12288796.35340723,
    "YEAR_8": 14167826.209242778,
    "YEAR_9": 16046856.065078322,
    "YEAR_10": 17925885.920913868,
    "FIXED_Izak": 94625020.44092521
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Izak",
    "Total Smart Devices": 6271809.788,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12543.619576000001,
    "YEAR_1": 919577.7685643905,
    "YEAR_2": 2839474.0761799677,
    "YEAR_3": 4683325.944478003,
    "YEAR_4": 6527177.812776038,
    "YEAR_5": 8371029.681074074,
    "YEAR_6": 10214881.549372109,
    "YEAR_7": 12058733.417670142,
    "YEAR_8": 13902585.285968179,
    "YEAR_9": 15746437.154266212,
    "YEAR_10": 17590289.022564247,
    "FIXED_Izak": 92853511.71291336
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Izak",
    "Total Smart Devices": 6261412.472,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12522.824944,
    "YEAR_1": 918053.3057746174,
    "YEAR_2": 2834766.836923391,
    "YEAR_3": 4675561.994131661,
    "YEAR_4": 6516357.15133993,
    "YEAR_5": 8357152.308548197,
    "YEAR_6": 10197947.465756465,
    "YEAR_7": 12038742.622964732,
    "YEAR_8": 13879537.780173004,
    "YEAR_9": 15720332.937381273,
    "YEAR_10": 17561128.09458954,
    "FIXED_Izak": 92699580.4975828
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Izak",
    "Total Smart Devices": 6219314.93,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12438.629860000001,
    "YEAR_1": 911880.930488544,
    "YEAR_2": 2815707.7641484803,
    "YEAR_3": 4644126.648145151,
    "YEAR_4": 6472545.532141824,
    "YEAR_5": 8300964.416138496,
    "YEAR_6": 10129383.30013517,
    "YEAR_7": 11957802.18413184,
    "YEAR_8": 13786221.068128513,
    "YEAR_9": 15614639.952125184,
    "YEAR_10": 17443058.836121857,
    "FIXED_Izak": 92076330.63170506
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Izak",
    "Total Smart Devices": 5145201.67,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 10290.40334,
    "YEAR_1": 754393.5850167361,
    "YEAR_2": 2329418.0232691197,
    "YEAR_3": 3842057.9203130873,
    "YEAR_4": 5354697.817357055,
    "YEAR_5": 6867337.7144010225,
    "YEAR_6": 8379977.61144499,
    "YEAR_7": 9892617.508488959,
    "YEAR_8": 11405257.405532924,
    "YEAR_9": 12917897.302576892,
    "YEAR_10": 14430537.199620863,
    "FIXED_Izak": 76174192.08802165
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Izak",
    "Total Smart Devices": 5080779.563,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 10161.559126,
    "YEAR_1": 744947.9641507104,
    "YEAR_2": 2300251.816234368,
    "YEAR_3": 3793952.232272563,
    "YEAR_4": 5287652.648310758,
    "YEAR_5": 6781353.064348953,
    "YEAR_6": 8275053.480387149,
    "YEAR_7": 9768753.896425344,
    "YEAR_8": 11262454.312463539,
    "YEAR_9": 12756154.728501733,
    "YEAR_10": 14249855.14453993,
    "FIXED_Izak": 75220429.28763506
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Izak",
    "Total Smart Devices": 4962123.612,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 9924.247224,
    "YEAR_1": 727550.5336903296,
    "YEAR_2": 2246531.9956024317,
    "YEAR_3": 3705348.7011437565,
    "YEAR_4": 5164165.406685081,
    "YEAR_5": 6622982.112226407,
    "YEAR_6": 8081798.817767732,
    "YEAR_7": 9540615.523309058,
    "YEAR_8": 10999432.228850383,
    "YEAR_9": 12458248.934391705,
    "YEAR_10": 13917065.639933035,
    "FIXED_Izak": 73463739.89359993
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Izak",
    "Total Smart Devices": 4639998.133,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 9279.996266,
    "YEAR_1": 680320.2382589664,
    "YEAR_2": 2100694.194741888,
    "YEAR_3": 3464809.101861811,
    "YEAR_4": 4828924.008981734,
    "YEAR_5": 6193038.916101657,
    "YEAR_6": 7557153.82322158,
    "YEAR_7": 8921268.730341502,
    "YEAR_8": 10285383.637461426,
    "YEAR_9": 11649498.54458135,
    "YEAR_10": 13013613.45170127,
    "FIXED_Izak": 68694704.64725319
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Izak",
    "Total Smart Devices": 4419136.81,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 8838.27362,
    "YEAR_1": 647937.374391648,
    "YEAR_2": 2000702.3228121598,
    "YEAR_3": 3299886.1212387835,
    "YEAR_4": 4599069.919665408,
    "YEAR_5": 5898253.718092032,
    "YEAR_6": 7197437.516518654,
    "YEAR_7": 8496621.314945279,
    "YEAR_8": 9795805.113371903,
    "YEAR_9": 11094988.911798527,
    "YEAR_10": 12394172.710225152,
    "FIXED_Izak": 65424875.02305955
  },
  {
    "DMA": "Denver",
    "PlatformName": "Izak",
    "Total Smart Devices": 4310338.605,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 8620.677210000002,
    "YEAR_1": 631985.2945359842,
    "YEAR_2": 1951445.4586732797,
    "YEAR_3": 3218643.629292673,
    "YEAR_4": 4485841.799912064,
    "YEAR_5": 5753039.970531457,
    "YEAR_6": 7020238.141150849,
    "YEAR_7": 8287436.311770241,
    "YEAR_8": 9554634.482389634,
    "YEAR_9": 10821832.653009025,
    "YEAR_10": 12089030.823628418,
    "FIXED_Izak": 63814128.564893626
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Izak",
    "Total Smart Devices": 4262896.578,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 8525.793156,
    "YEAR_1": 625029.3065836222,
    "YEAR_2": 1929966.7451374077,
    "YEAR_3": 3183217.415262259,
    "YEAR_4": 4436468.08538711,
    "YEAR_5": 5689718.75551196,
    "YEAR_6": 6942969.425636812,
    "YEAR_7": 8196220.095761663,
    "YEAR_8": 9449470.765886514,
    "YEAR_9": 10702721.436011367,
    "YEAR_10": 11955972.106136214,
    "FIXED_Izak": 63111754.13731493
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Izak",
    "Total Smart Devices": 4133878.496,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 8267.756992,
    "YEAR_1": 606112.5721863167,
    "YEAR_2": 1871555.614765056,
    "YEAR_3": 3086876.207355494,
    "YEAR_4": 4302196.799945933,
    "YEAR_5": 5517517.392536371,
    "YEAR_6": 6732837.98512681,
    "YEAR_7": 7948158.577717248,
    "YEAR_8": 9163479.170307687,
    "YEAR_9": 10378799.762898125,
    "YEAR_10": 11594120.355488565,
    "FIXED_Izak": 61201654.43832761
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Izak",
    "Total Smart Devices": 3728438.627,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 7456.877254000001,
    "YEAR_1": 546666.6542416416,
    "YEAR_2": 1687998.3902334725,
    "YEAR_3": 2784123.553560653,
    "YEAR_4": 3880248.7168878336,
    "YEAR_5": 4976373.880215014,
    "YEAR_6": 6072499.043542195,
    "YEAR_7": 7168624.206869377,
    "YEAR_8": 8264749.370196558,
    "YEAR_9": 9360874.533523738,
    "YEAR_10": 10456999.696850918,
    "FIXED_Izak": 55199158.0461214
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Izak",
    "Total Smart Devices": 3620542.555,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 7241.08511,
    "YEAR_1": 530846.8458481439,
    "YEAR_2": 1639149.9541804797,
    "YEAR_3": 2703554.708141952,
    "YEAR_4": 3767959.4621034237,
    "YEAR_5": 4832364.2160648955,
    "YEAR_6": 5896768.970026367,
    "YEAR_7": 6961173.723987839,
    "YEAR_8": 8025578.477949311,
    "YEAR_9": 9089983.231910784,
    "YEAR_10": 10154387.985872254,
    "FIXED_Izak": 53601767.57608545
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Izak",
    "Total Smart Devices": 3181975.805,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 6363.951610000001,
    "YEAR_1": 466543.838109744,
    "YEAR_2": 1440594.9980524802,
    "YEAR_3": 2376065.3377547525,
    "YEAR_4": 3311535.6774570243,
    "YEAR_5": 4247006.017159295,
    "YEAR_6": 5182476.356861568,
    "YEAR_7": 6117946.69656384,
    "YEAR_8": 7053417.036266113,
    "YEAR_9": 7988887.375968384,
    "YEAR_10": 8924357.715670656,
    "FIXED_Izak": 47108831.04986386
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Izak",
    "Total Smart Devices": 3098524.034,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 6197.048068,
    "YEAR_1": 454308.07268430723,
    "YEAR_2": 1402813.3770570243,
    "YEAR_3": 2313749.6972222975,
    "YEAR_4": 3224686.0173875713,
    "YEAR_5": 4135622.3375528445,
    "YEAR_6": 5046558.657718118,
    "YEAR_7": 5957494.977883391,
    "YEAR_8": 6868431.298048665,
    "YEAR_9": 7779367.6182139395,
    "YEAR_10": 8690303.938379213,
    "FIXED_Izak": 45873335.99214737
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Izak",
    "Total Smart Devices": 3101259.121,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 6202.518242,
    "YEAR_1": 454709.0933283168,
    "YEAR_2": 1404051.6494050561,
    "YEAR_3": 2315792.058891494,
    "YEAR_4": 3227532.4683779334,
    "YEAR_5": 4139272.877864371,
    "YEAR_6": 5051013.287350809,
    "YEAR_7": 5962753.69683725,
    "YEAR_8": 6874494.1063236855,
    "YEAR_9": 7786234.5158101255,
    "YEAR_10": 8697974.925296562,
    "FIXED_Izak": 45913828.679485604
  },
  {
    "DMA": "St. Louis",
    "PlatformName": "Izak",
    "Total Smart Devices": 3008446.843,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 6016.893686,
    "YEAR_1": 441100.8828781344,
    "YEAR_2": 1362032.1899124482,
    "YEAR_3": 2246486.6806647554,
    "YEAR_4": 3130941.171417062,
    "YEAR_5": 4015395.66216937,
    "YEAR_6": 4899850.152921677,
    "YEAR_7": 5784304.643673984,
    "YEAR_8": 6668759.13442629,
    "YEAR_9": 7553213.6251786,
    "YEAR_10": 8437668.115930907,
    "FIXED_Izak": 44539752.25917322
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Izak",
    "Total Smart Devices": 2910409.816,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5820.819632000001,
    "YEAR_1": 426726.6155497728,
    "YEAR_2": 1317647.2984565762,
    "YEAR_3": 2173279.8444263423,
    "YEAR_4": 3028912.390396109,
    "YEAR_5": 3884544.936365875,
    "YEAR_6": 4740177.482335643,
    "YEAR_7": 5595810.028305409,
    "YEAR_8": 6451442.574275175,
    "YEAR_9": 7307075.120244942,
    "YEAR_10": 8162707.666214708,
    "FIXED_Izak": 43088323.95657055
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Izak",
    "Total Smart Devices": 2844384.806,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5688.769612,
    "YEAR_1": 417045.9757635648,
    "YEAR_2": 1287755.399529216,
    "YEAR_3": 2123977.2263990785,
    "YEAR_4": 2960199.0532689407,
    "YEAR_5": 3796420.880138803,
    "YEAR_6": 4632642.7070086645,
    "YEAR_7": 5468864.533878528,
    "YEAR_8": 6305086.36074839,
    "YEAR_9": 7141308.187618252,
    "YEAR_10": 7977530.014488114,
    "FIXED_Izak": 42110830.33884156
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Izak",
    "Total Smart Devices": 2813141.762,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5626.283524,
    "YEAR_1": 412465.09565784957,
    "YEAR_2": 1273610.548760832,
    "YEAR_3": 2100647.2206279165,
    "YEAR_4": 2927683.8924950017,
    "YEAR_5": 3754720.564362087,
    "YEAR_6": 4581757.236229171,
    "YEAR_7": 5408793.908096257,
    "YEAR_8": 6235830.57996334,
    "YEAR_9": 7062867.251830425,
    "YEAR_10": 7889903.923697511,
    "FIXED_Izak": 41648280.22172039
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Izak",
    "Total Smart Devices": 2761746.07,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5523.49214,
    "YEAR_1": 404929.41818025603,
    "YEAR_2": 1250341.86874752,
    "YEAR_3": 2062268.7005652476,
    "YEAR_4": 2874195.5323829753,
    "YEAR_5": 3686122.364200704,
    "YEAR_6": 4498049.196018433,
    "YEAR_7": 5309976.027836159,
    "YEAR_8": 6121902.859653888,
    "YEAR_9": 6933829.691471616,
    "YEAR_10": 7745756.523289343,
    "FIXED_Izak": 40887372.18234614
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Izak",
    "Total Smart Devices": 2802506.923,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5605.013846000001,
    "YEAR_1": 410905.8070557985,
    "YEAR_2": 1268795.7742913282,
    "YEAR_3": 2092705.9055868671,
    "YEAR_4": 2916616.0368824066,
    "YEAR_5": 3740526.168177946,
    "YEAR_6": 4564436.299473485,
    "YEAR_7": 5388346.430769024,
    "YEAR_8": 6212256.562064563,
    "YEAR_9": 7036166.693360102,
    "YEAR_10": 7860076.824655642,
    "FIXED_Izak": 41490832.50231717
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Izak",
    "Total Smart Devices": 2679594.179,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5359.188358,
    "YEAR_1": 392884.24220032315,
    "YEAR_2": 1213148.750223744,
    "YEAR_3": 2000923.7147456259,
    "YEAR_4": 2788698.679267507,
    "YEAR_5": 3576473.643789389,
    "YEAR_6": 4364248.608311269,
    "YEAR_7": 5152023.5728331525,
    "YEAR_8": 5939798.537355034,
    "YEAR_9": 6727573.501876915,
    "YEAR_10": 7515348.466398797,
    "FIXED_Izak": 39671121.71700176
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Izak",
    "Total Smart Devices": 2564533.659,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5129.067318,
    "YEAR_1": 376013.97670950723,
    "YEAR_2": 1161056.710641024,
    "YEAR_3": 1915004.9868638972,
    "YEAR_4": 2668953.2630867707,
    "YEAR_5": 3422901.539309644,
    "YEAR_6": 4176849.815532518,
    "YEAR_7": 4930798.091755392,
    "YEAR_8": 5684746.3679782655,
    "YEAR_9": 6438694.644201139,
    "YEAR_10": 7192642.9204240125,
    "FIXED_Izak": 37967662.31650217
  },
  {
    "DMA": "Columbus",
    "PlatformName": "Izak",
    "Total Smart Devices": 2468934.437,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4937.868874,
    "YEAR_1": 361997.1423004896,
    "YEAR_2": 1117775.5012696318,
    "YEAR_3": 1843618.5239770366,
    "YEAR_4": 2569461.5466844416,
    "YEAR_5": 3295304.5693918453,
    "YEAR_6": 4021147.5920992503,
    "YEAR_7": 4746990.614806654,
    "YEAR_8": 5472833.63751406,
    "YEAR_9": 6198676.660221465,
    "YEAR_10": 6924519.6829288695,
    "FIXED_Izak": 36552325.471193746
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Izak",
    "Total Smart Devices": 2488288.448,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4976.5768960000005,
    "YEAR_1": 364834.8428765184,
    "YEAR_2": 1126537.758793728,
    "YEAR_3": 1858070.6749366273,
    "YEAR_4": 2589603.591079527,
    "YEAR_5": 3321136.507222426,
    "YEAR_6": 4052669.4233653247,
    "YEAR_7": 4784202.339508224,
    "YEAR_8": 5515735.255651123,
    "YEAR_9": 6247268.1717940215,
    "YEAR_10": 6978801.08793692,
    "FIXED_Izak": 36838859.65316445
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Izak",
    "Total Smart Devices": 2468450.947,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4936.9018940000005,
    "YEAR_1": 361926.25260989764,
    "YEAR_2": 1117556.607940992,
    "YEAR_3": 1843257.4892299008,
    "YEAR_4": 2568958.3705188097,
    "YEAR_5": 3294659.2518077185,
    "YEAR_6": 4020360.133096627,
    "YEAR_7": 4746061.014385536,
    "YEAR_8": 5471761.895674446,
    "YEAR_9": 6197462.776963354,
    "YEAR_10": 6923163.658252263,
    "FIXED_Izak": 36545167.450479545
  },
  {
    "DMA": "Austin",
    "PlatformName": "Izak",
    "Total Smart Devices": 2371566.242,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4743.132484000001,
    "YEAR_1": 347720.93965503364,
    "YEAR_2": 1073693.414138112,
    "YEAR_3": 1770911.122250189,
    "YEAR_4": 2468128.830362266,
    "YEAR_5": 3165346.5384743433,
    "YEAR_6": 3862564.2465864196,
    "YEAR_7": 4559781.9546984965,
    "YEAR_8": 5256999.662810573,
    "YEAR_9": 5954217.370922649,
    "YEAR_10": 6651435.079034726,
    "FIXED_Izak": 35110799.158932805
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Izak",
    "Total Smart Devices": 2303023.424,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4606.046848000001,
    "YEAR_1": 337671.13684561924,
    "YEAR_2": 1042661.6128880641,
    "YEAR_3": 1719728.3905191938,
    "YEAR_4": 2396795.1681503234,
    "YEAR_5": 3073861.9457814535,
    "YEAR_6": 3750928.723412583,
    "YEAR_7": 4427995.501043713,
    "YEAR_8": 5105062.278674843,
    "YEAR_9": 5782129.056305971,
    "YEAR_10": 6459195.833937101,
    "FIXED_Izak": 34096029.64755887
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Izak",
    "Total Smart Devices": 2307327.852,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4614.655704,
    "YEAR_1": 338302.25552252156,
    "YEAR_2": 1044610.382403072,
    "YEAR_3": 1722942.6205436927,
    "YEAR_4": 2401274.858684313,
    "YEAR_5": 3079607.096824934,
    "YEAR_6": 3757939.3349655545,
    "YEAR_7": 4436271.573106175,
    "YEAR_8": 5114603.811246796,
    "YEAR_9": 5792936.049387417,
    "YEAR_10": 6471268.287528038,
    "FIXED_Izak": 34159756.270212516
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Izak",
    "Total Smart Devices": 2174029.485,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4348.05897,
    "YEAR_1": 318757.942314288,
    "YEAR_2": 984261.41292096,
    "YEAR_3": 1623405.2108279038,
    "YEAR_4": 2262549.008734848,
    "YEAR_5": 2901692.806641792,
    "YEAR_6": 3540836.6045487355,
    "YEAR_7": 4179980.402455679,
    "YEAR_8": 4819124.200362624,
    "YEAR_9": 5458267.998269566,
    "YEAR_10": 6097411.796176512,
    "FIXED_Izak": 32186287.383252908
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Izak",
    "Total Smart Devices": 2150472.593,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4300.945186,
    "YEAR_1": 315304.01196373435,
    "YEAR_2": 973596.3598644477,
    "YEAR_3": 1605814.657669555,
    "YEAR_4": 2238032.955474662,
    "YEAR_5": 2870251.253279769,
    "YEAR_6": 3502469.551084877,
    "YEAR_7": 4134687.848889984,
    "YEAR_8": 4766906.14669509,
    "YEAR_9": 5399124.444500199,
    "YEAR_10": 6031342.742305305,
    "FIXED_Izak": 31837529.97172762
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Izak",
    "Total Smart Devices": 2107672.982,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4215.345964,
    "YEAR_1": 309028.6987592256,
    "YEAR_2": 954219.435178752,
    "YEAR_3": 1573855.0582261248,
    "YEAR_4": 2193490.681273498,
    "YEAR_5": 2813126.30432087,
    "YEAR_6": 3432761.927368243,
    "YEAR_7": 4052397.5504156156,
    "YEAR_8": 4672033.173462988,
    "YEAR_9": 5291668.796510362,
    "YEAR_10": 5911304.419557734,
    "FIXED_Izak": 31203886.045073412
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Izak",
    "Total Smart Devices": 1917944.712,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3835.8894240000004,
    "YEAR_1": 281210.5880292096,
    "YEAR_2": 868322.6171320319,
    "YEAR_3": 1432179.9501907967,
    "YEAR_4": 1996037.2832495612,
    "YEAR_5": 2559894.6163083264,
    "YEAR_6": 3123751.949367091,
    "YEAR_7": 3687609.282425856,
    "YEAR_8": 4251466.61548462,
    "YEAR_9": 4815323.948543386,
    "YEAR_10": 5379181.281602151,
    "FIXED_Izak": 28394978.13233303
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Izak",
    "Total Smart Devices": 1886199.232,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3772.3984640000003,
    "YEAR_1": 276556.0403552256,
    "YEAR_2": 853950.295498752,
    "YEAR_3": 1408474.7621941247,
    "YEAR_4": 1962999.2288894977,
    "YEAR_5": 2517523.6955848704,
    "YEAR_6": 3072048.1622802434,
    "YEAR_7": 3626572.628975617,
    "YEAR_8": 4181097.0956709897,
    "YEAR_9": 4735621.562366362,
    "YEAR_10": 5290146.029061735,
    "FIXED_Izak": 27924989.500877418
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Izak",
    "Total Smart Devices": 1885454.401,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3770.9088020000004,
    "YEAR_1": 276446.83263814077,
    "YEAR_2": 853613.083691136,
    "YEAR_3": 1407918.5772228865,
    "YEAR_4": 1962224.0707546368,
    "YEAR_5": 2516529.564286387,
    "YEAR_6": 3070835.057818138,
    "YEAR_7": 3625140.5513498876,
    "YEAR_8": 4179446.0448816377,
    "YEAR_9": 4733751.538413389,
    "YEAR_10": 5288057.031945139,
    "FIXED_Izak": 27913962.35300138
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Izak",
    "Total Smart Devices": 1862144.776,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3724.2895520000006,
    "YEAR_1": 273029.1567729408,
    "YEAR_2": 843059.977307136,
    "YEAR_3": 1390512.6648612865,
    "YEAR_4": 1937965.352415437,
    "YEAR_5": 2485418.0399695877,
    "YEAR_6": 3032870.7275237376,
    "YEAR_7": 3580323.4150778884,
    "YEAR_8": 4127776.1026320383,
    "YEAR_9": 4675228.790186189,
    "YEAR_10": 5222681.47774034,
    "FIXED_Izak": 27568865.704486582
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Izak",
    "Total Smart Devices": 1846925.874,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3693.8517480000005,
    "YEAR_1": 270797.7491865792,
    "YEAR_2": 836169.832491264,
    "YEAR_3": 1379148.3089588736,
    "YEAR_4": 1922126.785426483,
    "YEAR_5": 2465105.261894093,
    "YEAR_6": 3008083.7383617023,
    "YEAR_7": 3551062.214829312,
    "YEAR_8": 4094040.691296922,
    "YEAR_9": 4637019.167764531,
    "YEAR_10": 5179997.644232141,
    "FIXED_Izak": 27343551.394441903
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Izak",
    "Total Smart Devices": 1813431.99,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3626.86398,
    "YEAR_1": 265886.84911939205,
    "YEAR_2": 821005.9454246399,
    "YEAR_3": 1354137.5415375358,
    "YEAR_4": 1887269.137650432,
    "YEAR_5": 2420400.7337633283,
    "YEAR_6": 2953532.329876223,
    "YEAR_7": 3486663.9259891203,
    "YEAR_8": 4019795.522102017,
    "YEAR_9": 4552927.118214911,
    "YEAR_10": 5086058.714327809,
    "FIXED_Izak": 26847677.81800541
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Izak",
    "Total Smart Devices": 1792405.574,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3584.8111480000002,
    "YEAR_1": 262803.9391843392,
    "YEAR_2": 811486.529950464,
    "YEAR_3": 1338436.5616129537,
    "YEAR_4": 1865386.5932754432,
    "YEAR_5": 2392336.624937933,
    "YEAR_6": 2919286.6566004227,
    "YEAR_7": 3446236.688262912,
    "YEAR_8": 3973186.7199254017,
    "YEAR_9": 4500136.751587891,
    "YEAR_10": 5027086.783250381,
    "FIXED_Izak": 26536383.84858814
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Izak",
    "Total Smart Devices": 1750390.958,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3500.781916000001,
    "YEAR_1": 256643.72257472642,
    "YEAR_2": 792465.000761088,
    "YEAR_3": 1307063.1386598914,
    "YEAR_4": 1821661.276558695,
    "YEAR_5": 2336259.414457498,
    "YEAR_6": 2850857.5523563013,
    "YEAR_7": 3365455.6902551046,
    "YEAR_8": 3880053.828153908,
    "YEAR_9": 4394651.966052711,
    "YEAR_10": 4909250.103951515,
    "FIXED_Izak": 25914361.69378144
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Izak",
    "Total Smart Devices": 1750628.806,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3501.257612000001,
    "YEAR_1": 256678.59603876484,
    "YEAR_2": 792572.6831132161,
    "YEAR_3": 1307240.7460406786,
    "YEAR_4": 1821908.8089681412,
    "YEAR_5": 2336576.871895604,
    "YEAR_6": 2851244.9348230665,
    "YEAR_7": 3365912.9977505286,
    "YEAR_8": 3880581.060677991,
    "YEAR_9": 4395249.123605453,
    "YEAR_10": 4909917.1865329165,
    "FIXED_Izak": 25917883.00944636
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Izak",
    "Total Smart Devices": 1672507.553,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3345.0151060000007,
    "YEAR_1": 245224.39542690243,
    "YEAR_2": 757204.3795150082,
    "YEAR_3": 1248905.5440244996,
    "YEAR_4": 1740606.7085339907,
    "YEAR_5": 2232307.873043482,
    "YEAR_6": 2724009.0375529733,
    "YEAR_7": 3215710.2020624643,
    "YEAR_8": 3707411.3665719554,
    "YEAR_9": 4199112.531081447,
    "YEAR_10": 4690813.695590938,
    "FIXED_Izak": 24761305.733403664
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Izak",
    "Total Smart Devices": 1641227.699,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3282.4553980000005,
    "YEAR_1": 240638.11820953924,
    "YEAR_2": 743042.863534464,
    "YEAR_3": 1225548.051254554,
    "YEAR_4": 1708053.2389746432,
    "YEAR_5": 2190558.426694733,
    "YEAR_6": 2673063.6144148223,
    "YEAR_7": 3155568.8021349125,
    "YEAR_8": 3638073.989855002,
    "YEAR_9": 4120579.177575092,
    "YEAR_10": 4603084.365295182,
    "FIXED_Izak": 24298210.647942945
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Izak",
    "Total Smart Devices": 1564688.779,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3129.377558,
    "YEAR_1": 229415.9205280032,
    "YEAR_2": 708390.939049344,
    "YEAR_3": 1168394.4190630652,
    "YEAR_4": 1628397.8990767875,
    "YEAR_5": 2088401.3790905091,
    "YEAR_6": 2548404.85910423,
    "YEAR_7": 3008408.339117951,
    "YEAR_8": 3468411.819131673,
    "YEAR_9": 3928415.2991453954,
    "YEAR_10": 4388418.7791591175,
    "FIXED_Izak": 23165059.652466077
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Izak",
    "Total Smart Devices": 1553299.908,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3106.5998160000004,
    "YEAR_1": 227746.07515088643,
    "YEAR_2": 703234.7871482881,
    "YEAR_3": 1159890.0484211713,
    "YEAR_4": 1616545.3096940543,
    "YEAR_5": 2073200.5709669378,
    "YEAR_6": 2529855.832239821,
    "YEAR_7": 2986511.0935127046,
    "YEAR_8": 3443166.3547855867,
    "YEAR_9": 3899821.61605847,
    "YEAR_10": 4356476.877331354,
    "FIXED_Izak": 22996448.56530927
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Izak",
    "Total Smart Devices": 1532512.639,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3065.025278,
    "YEAR_1": 224698.22914029122,
    "YEAR_2": 693823.642130304,
    "YEAR_3": 1144367.6458749697,
    "YEAR_4": 1594911.649619635,
    "YEAR_5": 2045455.6533643007,
    "YEAR_6": 2495999.6571089667,
    "YEAR_7": 2946543.6608536313,
    "YEAR_8": 3397087.664598298,
    "YEAR_9": 3847631.668342964,
    "YEAR_10": 4298175.672087628,
    "FIXED_Izak": 22688695.14312099
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Izak",
    "Total Smart Devices": 1507192.296,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3014.3845920000003,
    "YEAR_1": 220985.74019335682,
    "YEAR_2": 682360.2113218561,
    "YEAR_3": 1125460.2772998144,
    "YEAR_4": 1568560.3432777727,
    "YEAR_5": 2011660.4092557312,
    "YEAR_6": 2454760.4752336894,
    "YEAR_7": 2897860.5412116484,
    "YEAR_8": 3340960.6071896064,
    "YEAR_9": 3784060.6731675654,
    "YEAR_10": 4227160.739145523,
    "FIXED_Izak": 22313830.01729656
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Izak",
    "Total Smart Devices": 1471541.76,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2943.08352,
    "YEAR_1": 215758.630084608,
    "YEAR_2": 666219.93025536,
    "YEAR_3": 1098839.080894464,
    "YEAR_4": 1531458.2315335681,
    "YEAR_5": 1964077.3821726718,
    "YEAR_6": 2396696.532811776,
    "YEAR_7": 2829315.68345088,
    "YEAR_8": 3261934.834089984,
    "YEAR_9": 3694553.984729088,
    "YEAR_10": 4127173.1353681916,
    "FIXED_Izak": 21786027.42539059
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Izak",
    "Total Smart Devices": 1452147.042,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2904.294084,
    "YEAR_1": 212914.96101567362,
    "YEAR_2": 657439.2432069121,
    "YEAR_3": 1084356.5329433088,
    "YEAR_4": 1511273.8226797055,
    "YEAR_5": 1938191.1124161023,
    "YEAR_6": 2365108.402152499,
    "YEAR_7": 2792025.691888896,
    "YEAR_8": 3218942.981625293,
    "YEAR_9": 3645860.27136169,
    "YEAR_10": 4072777.5610980866,
    "FIXED_Izak": 21498890.580388166
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Izak",
    "Total Smart Devices": 1459002.839,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2918.005678,
    "YEAR_1": 213920.1634564512,
    "YEAR_2": 660543.109317504,
    "YEAR_3": 1089475.9375562496,
    "YEAR_4": 1518408.7657949948,
    "YEAR_5": 1947341.5940337405,
    "YEAR_6": 2376274.422272486,
    "YEAR_7": 2805207.2505112323,
    "YEAR_8": 3234140.078749977,
    "YEAR_9": 3663072.9069887227,
    "YEAR_10": 4092005.7352274684,
    "FIXED_Izak": 21600389.96390883
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Izak",
    "Total Smart Devices": 1405447.572,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2810.895144,
    "YEAR_1": 206067.8473646976,
    "YEAR_2": 636296.711956992,
    "YEAR_3": 1049484.8058283008,
    "YEAR_4": 1462672.8996996097,
    "YEAR_5": 1875860.9935709185,
    "YEAR_6": 2289049.087442227,
    "YEAR_7": 2702237.1813135357,
    "YEAR_8": 3115425.275184845,
    "YEAR_9": 3528613.369056153,
    "YEAR_10": 3941801.4629274625,
    "FIXED_Izak": 20807509.63434474
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Izak",
    "Total Smart Devices": 1404565.359,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2809.1307180000003,
    "YEAR_1": 205938.49658886722,
    "YEAR_2": 635897.3023722239,
    "YEAR_3": 1048826.0340907776,
    "YEAR_4": 1461754.765809331,
    "YEAR_5": 1874683.4975278848,
    "YEAR_6": 2287612.2292464385,
    "YEAR_7": 2700540.960964992,
    "YEAR_8": 3113469.692683546,
    "YEAR_9": 3526398.4244020996,
    "YEAR_10": 3939327.1561206533,
    "FIXED_Izak": 20794448.559806816
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Izak",
    "Total Smart Devices": 1362932.794,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2725.8655880000006,
    "YEAR_1": 199834.29660251524,
    "YEAR_2": 617048.7414243842,
    "YEAR_3": 1017737.8987055618,
    "YEAR_4": 1418427.0559867395,
    "YEAR_5": 1819116.213267917,
    "YEAR_6": 2219805.370549095,
    "YEAR_7": 2620494.527830273,
    "YEAR_8": 3021183.68511145,
    "YEAR_9": 3421872.8423926276,
    "YEAR_10": 3822561.999673805,
    "FIXED_Izak": 20178082.631544366
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Izak",
    "Total Smart Devices": 1337968.086,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2675.9361719999997,
    "YEAR_1": 196173.95114378876,
    "YEAR_2": 605746.319383296,
    "YEAR_3": 999096.0921736702,
    "YEAR_4": 1392445.8649640447,
    "YEAR_5": 1785795.6377544187,
    "YEAR_6": 2179145.410544793,
    "YEAR_7": 2572495.1833351674,
    "YEAR_8": 2965844.9561255416,
    "YEAR_9": 3359194.7289159163,
    "YEAR_10": 3752544.50170629,
    "FIXED_Izak": 19808482.646046925
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Izak",
    "Total Smart Devices": 1249823.665,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2499.6473300000002,
    "YEAR_1": 183250.14562123202,
    "YEAR_2": 565840.16679744,
    "YEAR_3": 933276.3260002563,
    "YEAR_4": 1300712.4852030722,
    "YEAR_5": 1668148.6444058882,
    "YEAR_6": 2035584.8036087041,
    "YEAR_7": 2403020.9628115203,
    "YEAR_8": 2770457.122014336,
    "YEAR_9": 3137893.2812171527,
    "YEAR_10": 3505329.440419968,
    "FIXED_Izak": 18503513.37809957
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Izak",
    "Total Smart Devices": 1202380.339,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2404.760678,
    "YEAR_1": 176293.96720845118,
    "YEAR_2": 544360.8651575041,
    "YEAR_3": 897849.1419722495,
    "YEAR_4": 1251337.418786995,
    "YEAR_5": 1604825.6956017409,
    "YEAR_6": 1958313.9724164861,
    "YEAR_7": 2311802.249231232,
    "YEAR_8": 2665290.5260459776,
    "YEAR_9": 3018778.8028607233,
    "YEAR_10": 3372267.0796754686,
    "FIXED_Izak": 17801119.718956828
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Izak",
    "Total Smart Devices": 1219379.365,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2438.75873,
    "YEAR_1": 178786.377999792,
    "YEAR_2": 552056.9361926399,
    "YEAR_3": 910542.763460736,
    "YEAR_4": 1269028.590728832,
    "YEAR_5": 1627514.4179969279,
    "YEAR_6": 1986000.245265024,
    "YEAR_7": 2344486.0725331204,
    "YEAR_8": 2702971.899801216,
    "YEAR_9": 3061457.727069312,
    "YEAR_10": 3419943.554337408,
    "FIXED_Izak": 18052788.585385006
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Izak",
    "Total Smart Devices": 1183469.762,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2366.9395240000003,
    "YEAR_1": 173521.28328024963,
    "YEAR_2": 535799.366168832,
    "YEAR_3": 883728.1148871167,
    "YEAR_4": 1231656.863605402,
    "YEAR_5": 1579585.6123236865,
    "YEAR_6": 1927514.3610419712,
    "YEAR_7": 2275443.109760256,
    "YEAR_8": 2623371.858478541,
    "YEAR_9": 2971300.6071968256,
    "YEAR_10": 3319229.3559151096,
    "FIXED_Izak": 17521150.532657992
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Izak",
    "Total Smart Devices": 1187777.437,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2375.554874,
    "YEAR_1": 174152.87803488958,
    "YEAR_2": 537749.605717632,
    "YEAR_3": 886944.7695322367,
    "YEAR_4": 1236139.9333468415,
    "YEAR_5": 1585335.0971614458,
    "YEAR_6": 1934530.2609760503,
    "YEAR_7": 2283725.424790655,
    "YEAR_8": 2632920.5886052605,
    "YEAR_9": 2982115.7524198648,
    "YEAR_10": 3331310.9162344695,
    "FIXED_Izak": 17584925.226819344
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Izak",
    "Total Smart Devices": 1143912.87,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2287.82574,
    "YEAR_1": 167721.420129696,
    "YEAR_2": 517890.53711232,
    "YEAR_3": 854189.939328768,
    "YEAR_4": 1190489.341545216,
    "YEAR_5": 1526788.7437616643,
    "YEAR_6": 1863088.1459781118,
    "YEAR_7": 2199387.5481945598,
    "YEAR_8": 2535686.950411008,
    "YEAR_9": 2871986.352627456,
    "YEAR_10": 3208285.7548439037,
    "FIXED_Izak": 16935514.733932704
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Izak",
    "Total Smart Devices": 1152245.338,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2304.4906760000003,
    "YEAR_1": 168943.13325383043,
    "YEAR_2": 521662.945344768,
    "YEAR_3": 860412.0131615233,
    "YEAR_4": 1199161.0809782783,
    "YEAR_5": 1537910.1487950333,
    "YEAR_6": 1876659.2166117888,
    "YEAR_7": 2215408.284428544,
    "YEAR_8": 2554157.3522452987,
    "YEAR_9": 2892906.420062054,
    "YEAR_10": 3231655.4878788097,
    "FIXED_Izak": 17058876.082759928
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Izak",
    "Total Smart Devices": 1130925.32,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2261.85064,
    "YEAR_1": 165817.17515865597,
    "YEAR_2": 512010.6056755199,
    "YEAR_3": 844491.792872448,
    "YEAR_4": 1176972.980069376,
    "YEAR_5": 1509454.167266304,
    "YEAR_6": 1841935.3544632317,
    "YEAR_7": 2174416.54166016,
    "YEAR_8": 2506897.7288570874,
    "YEAR_9": 2839378.9160540155,
    "YEAR_10": 3171860.103250944,
    "FIXED_Izak": 16743235.365327742
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Izak",
    "Total Smart Devices": 1109995.797,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2219.9915940000005,
    "YEAR_1": 162748.47175277764,
    "YEAR_2": 502535.05715059215,
    "YEAR_3": 828863.165508941,
    "YEAR_4": 1155191.27386729,
    "YEAR_5": 1481519.382225639,
    "YEAR_6": 1807847.4905839877,
    "YEAR_7": 2134175.598942336,
    "YEAR_8": 2460503.7073006853,
    "YEAR_9": 2786831.815659034,
    "YEAR_10": 3113159.9240173833,
    "FIXED_Izak": 16433375.887008665
  },
  {
    "DMA": "Madison",
    "PlatformName": "Izak",
    "Total Smart Devices": 1112970.681,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2225.9413620000005,
    "YEAR_1": 163184.65162476484,
    "YEAR_2": 503881.89423321607,
    "YEAR_3": 831084.5899286786,
    "YEAR_4": 1158287.285624141,
    "YEAR_5": 1485489.9813196035,
    "YEAR_6": 1812692.6770150661,
    "YEAR_7": 2139895.3727105283,
    "YEAR_8": 2467098.068405991,
    "YEAR_9": 2794300.764101453,
    "YEAR_10": 3121503.4597969153,
    "FIXED_Izak": 16477418.744760359
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Izak",
    "Total Smart Devices": 1102039.859,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2204.079718,
    "YEAR_1": 161581.96575846718,
    "YEAR_2": 498933.11760422395,
    "YEAR_3": 822922.2565675776,
    "YEAR_4": 1146911.3955309312,
    "YEAR_5": 1470900.534494285,
    "YEAR_6": 1794889.6734576384,
    "YEAR_7": 2118878.812420992,
    "YEAR_8": 2442867.9513843455,
    "YEAR_9": 2766857.0903477,
    "YEAR_10": 3090846.2293110527,
    "FIXED_Izak": 16315589.026877213
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Izak",
    "Total Smart Devices": 1078841.85,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2157.6837000000005,
    "YEAR_1": 158180.65512048005,
    "YEAR_2": 488430.5438016001,
    "YEAR_3": 805599.6908198401,
    "YEAR_4": 1122768.83783808,
    "YEAR_5": 1439937.98485632,
    "YEAR_6": 1757107.1318745601,
    "YEAR_7": 2074276.2788928007,
    "YEAR_8": 2391445.42591104,
    "YEAR_9": 2708614.5729292803,
    "YEAR_10": 3025783.7199475206,
    "FIXED_Izak": 15972144.841991523
  },
  {
    "DMA": "Springfield",
    "PlatformName": "Izak",
    "Total Smart Devices": 1058242.196,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2116.484392,
    "YEAR_1": 155160.3173712768,
    "YEAR_2": 479104.3388482559,
    "YEAR_3": 790217.3853471742,
    "YEAR_4": 1101330.4318460925,
    "YEAR_5": 1412443.4783450111,
    "YEAR_6": 1723556.5248439293,
    "YEAR_7": 2034669.5713428482,
    "YEAR_8": 2345782.617841766,
    "YEAR_9": 2656895.6643406837,
    "YEAR_10": 2968008.7108396036,
    "FIXED_Izak": 15667169.040966643
  },
  {
    "DMA": "Columbia",
    "PlatformName": "Izak",
    "Total Smart Devices": 1058408.268,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2116.816536,
    "YEAR_1": 155184.66698077437,
    "YEAR_2": 479179.52562124794,
    "YEAR_3": 790341.395693875,
    "YEAR_4": 1101503.2657665021,
    "YEAR_5": 1412665.1358391296,
    "YEAR_6": 1723827.0059117563,
    "YEAR_7": 2034988.875984384,
    "YEAR_8": 2346150.7460570103,
    "YEAR_9": 2657312.6161296386,
    "YEAR_10": 2968474.486202265,
    "FIXED_Izak": 15669627.720186584
  },
  {
    "DMA": "Rochester",
    "PlatformName": "Izak",
    "Total Smart Devices": 1061121.486,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2122.242972,
    "YEAR_1": 155582.48117450878,
    "YEAR_2": 480407.89708569593,
    "YEAR_3": 792367.4272034303,
    "YEAR_4": 1104326.9573211647,
    "YEAR_5": 1416286.487438899,
    "YEAR_6": 1728246.0175566338,
    "YEAR_7": 2040205.5476743681,
    "YEAR_8": 2352165.0777921015,
    "YEAR_9": 2664124.6079098363,
    "YEAR_10": 2976084.13802757,
    "FIXED_Izak": 15709796.639184207
  },
  {
    "DMA": "Portland",
    "PlatformName": "Izak",
    "Total Smart Devices": 1036069.954,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2072.139908,
    "YEAR_1": 151909.4055114432,
    "YEAR_2": 469066.166694144,
    "YEAR_3": 773660.7868985856,
    "YEAR_4": 1078255.4071030272,
    "YEAR_5": 1382850.0273074687,
    "YEAR_6": 1687444.6475119104,
    "YEAR_7": 1992039.267716352,
    "YEAR_8": 2296633.8879207936,
    "YEAR_9": 2601228.5081252353,
    "YEAR_10": 2905823.1283296766,
    "FIXED_Izak": 15338911.233118635
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Izak",
    "Total Smart Devices": 1038545,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2077.09,
    "YEAR_1": 152272.298736,
    "YEAR_2": 470186.7091199999,
    "YEAR_3": 775508.9690879999,
    "YEAR_4": 1080831.2290559998,
    "YEAR_5": 1386153.4890239998,
    "YEAR_6": 1691475.7489919998,
    "YEAR_7": 1996798.00896,
    "YEAR_8": 2302120.2689279993,
    "YEAR_9": 2607442.5288960002,
    "YEAR_10": 2912764.7888639993,
    "FIXED_Izak": 15375554.039663998
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Izak",
    "Total Smart Devices": 1041756.757,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2083.5135139999998,
    "YEAR_1": 152743.20911674557,
    "YEAR_2": 471640.78713715187,
    "YEAR_3": 777907.2728302847,
    "YEAR_4": 1084173.7585234174,
    "YEAR_5": 1390440.2442165501,
    "YEAR_6": 1696706.7299096829,
    "YEAR_7": 2002973.2156028154,
    "YEAR_8": 2309239.701295948,
    "YEAR_9": 2615506.186989081,
    "YEAR_10": 2921772.672682214,
    "FIXED_Izak": 15423103.778303891
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Izak",
    "Total Smart Devices": 1004152.231,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2008.304462,
    "YEAR_1": 147229.60343100477,
    "YEAR_2": 454615.864454016,
    "YEAR_3": 749826.9805065983,
    "YEAR_4": 1045038.0965591808,
    "YEAR_5": 1340249.212611763,
    "YEAR_6": 1635460.3286643454,
    "YEAR_7": 1930671.4447169276,
    "YEAR_8": 2225882.56076951,
    "YEAR_9": 2521093.6768220924,
    "YEAR_10": 2816304.7928746752,
    "FIXED_Izak": 14866372.561410114
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Izak",
    "Total Smart Devices": 1011839.034,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2023.6780680000002,
    "YEAR_1": 148356.64863630722,
    "YEAR_2": 458095.95689702407,
    "YEAR_3": 755566.9192382975,
    "YEAR_4": 1053037.8815795714,
    "YEAR_5": 1350508.8439208446,
    "YEAR_6": 1647979.8062621185,
    "YEAR_7": 1945450.7686033924,
    "YEAR_8": 2242921.730944666,
    "YEAR_9": 2540392.6932859397,
    "YEAR_10": 2837863.6556272125,
    "FIXED_Izak": 14980174.904995374
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Izak",
    "Total Smart Devices": 1001954.872,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2003.9097440000003,
    "YEAR_1": 146907.42489653762,
    "YEAR_2": 453621.040929792,
    "YEAR_3": 748186.1545310208,
    "YEAR_4": 1042751.2681322498,
    "YEAR_5": 1337316.3817334785,
    "YEAR_6": 1631881.4953347074,
    "YEAR_7": 1926446.6089359364,
    "YEAR_8": 2221011.722537165,
    "YEAR_9": 2515576.8361383937,
    "YEAR_10": 2810141.949739623,
    "FIXED_Izak": 14833840.882908903
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Izak",
    "Total Smart Devices": 986286.69,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1972.5733799999998,
    "YEAR_1": 144610.14351715197,
    "YEAR_2": 446527.4908838399,
    "YEAR_3": 736486.3093916159,
    "YEAR_4": 1026445.1278993919,
    "YEAR_5": 1316403.946407168,
    "YEAR_6": 1606362.7649149434,
    "YEAR_7": 1896321.5834227195,
    "YEAR_8": 2186280.4019304956,
    "YEAR_9": 2476239.2204382713,
    "YEAR_10": 2766198.038946048,
    "FIXED_Izak": 14601875.027751645
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Izak",
    "Total Smart Devices": 952657.6974,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1905.3153948,
    "YEAR_1": 139679.4337189459,
    "YEAR_2": 431302.43529008643,
    "YEAR_3": 711374.6528117913,
    "YEAR_4": 991446.8703334962,
    "YEAR_5": 1271519.087855201,
    "YEAR_6": 1551591.305376906,
    "YEAR_7": 1831663.5228986111,
    "YEAR_8": 2111735.740420316,
    "YEAR_9": 2391807.957942021,
    "YEAR_10": 2671880.175463726,
    "FIXED_Izak": 14104001.1821111
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Izak",
    "Total Smart Devices": 959703.0583,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1919.4061166000001,
    "YEAR_1": 140712.43017039265,
    "YEAR_2": 434492.1238025088,
    "YEAR_3": 716635.6097933492,
    "YEAR_4": 998779.0957841893,
    "YEAR_5": 1280922.58177503,
    "YEAR_6": 1563066.06776587,
    "YEAR_7": 1845209.5537567106,
    "YEAR_8": 2127353.039747551,
    "YEAR_9": 2409496.525738391,
    "YEAR_10": 2691640.0117292316,
    "FIXED_Izak": 14208307.040063223
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Izak",
    "Total Smart Devices": 927387.5876,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1854.7751752000001,
    "YEAR_1": 135974.3100039821,
    "YEAR_2": 419861.7468596736,
    "YEAR_3": 692504.7946932326,
    "YEAR_4": 965147.8425267916,
    "YEAR_5": 1237790.8903603507,
    "YEAR_6": 1510433.9381939094,
    "YEAR_7": 1783076.9860274687,
    "YEAR_8": 2055720.0338610276,
    "YEAR_9": 2328363.081694587,
    "YEAR_10": 2601006.129528145,
    "FIXED_Izak": 13729879.753749168
  },
  {
    "DMA": "Charleston",
    "PlatformName": "Izak",
    "Total Smart Devices": 912456.1162,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1824.9122324,
    "YEAR_1": 133785.04572213694,
    "YEAR_2": 413101.73222392314,
    "YEAR_3": 681355.0708080076,
    "YEAR_4": 949608.4093920921,
    "YEAR_5": 1217861.7479761764,
    "YEAR_6": 1486115.086560261,
    "YEAR_7": 1754368.4251443453,
    "YEAR_8": 2022621.7637284298,
    "YEAR_9": 2290875.102312514,
    "YEAR_10": 2559128.4408965986,
    "FIXED_Izak": 13508820.824764486
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Izak",
    "Total Smart Devices": 925622.1874,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1851.2443748000003,
    "YEAR_1": 135715.46561433794,
    "YEAR_2": 419062.4866347264,
    "YEAR_3": 691186.5237573275,
    "YEAR_4": 963310.5608799283,
    "YEAR_5": 1235434.5980025292,
    "YEAR_6": 1507558.6351251302,
    "YEAR_7": 1779682.6722477314,
    "YEAR_8": 2051806.7093703325,
    "YEAR_9": 2323930.7464929335,
    "YEAR_10": 2596054.783615534,
    "FIXED_Izak": 13703743.181740511
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Izak",
    "Total Smart Devices": 922574.2281,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1845.1484561999998,
    "YEAR_1": 135268.57138340446,
    "YEAR_2": 417682.5657330815,
    "YEAR_3": 688910.5320818917,
    "YEAR_4": 960138.4984307019,
    "YEAR_5": 1231366.4647795123,
    "YEAR_6": 1502594.4311283224,
    "YEAR_7": 1773822.3974771325,
    "YEAR_8": 2045050.3638259429,
    "YEAR_9": 2316278.3301747525,
    "YEAR_10": 2587506.2965235626,
    "FIXED_Izak": 13658618.451538306
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Izak",
    "Total Smart Devices": 960358.8983,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1920.7177966,
    "YEAR_1": 140808.58995586465,
    "YEAR_2": 434789.04618074873,
    "YEAR_3": 717125.3428355253,
    "YEAR_4": 999461.6394903015,
    "YEAR_5": 1281797.9361450775,
    "YEAR_6": 1564134.2327998534,
    "YEAR_7": 1846470.5294546303,
    "YEAR_8": 2128806.8261094065,
    "YEAR_9": 2411143.1227641827,
    "YEAR_10": 2693479.419418959,
    "FIXED_Izak": 14218016.68515455
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Izak",
    "Total Smart Devices": 965499.8531,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1930.9997062,
    "YEAR_1": 141562.36086140448,
    "YEAR_2": 437116.5414930815,
    "YEAR_3": 720964.2295058918,
    "YEAR_4": 1004811.9175187021,
    "YEAR_5": 1288659.6055315123,
    "YEAR_6": 1572507.2935443225,
    "YEAR_7": 1856354.9815571324,
    "YEAR_8": 2140202.6695699426,
    "YEAR_9": 2424050.357582753,
    "YEAR_10": 2707898.045595563,
    "FIXED_Izak": 14294128.002760306
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Izak",
    "Total Smart Devices": 878517.1744,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1757.0343488000003,
    "YEAR_1": 128808.89092426753,
    "YEAR_2": 397736.3514691585,
    "YEAR_3": 656011.9669778843,
    "YEAR_4": 914287.5824866102,
    "YEAR_5": 1172563.1979953358,
    "YEAR_6": 1430838.8135040614,
    "YEAR_7": 1689114.4290127875,
    "YEAR_8": 1947390.0445215132,
    "YEAR_9": 2205665.6600302393,
    "YEAR_10": 2463941.2755389647,
    "FIXED_Izak": 13006358.212460821
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Izak",
    "Total Smart Devices": 901897.6009,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1803.7952018,
    "YEAR_1": 132236.94776203873,
    "YEAR_2": 408321.5122410624,
    "YEAR_3": 673470.7486886937,
    "YEAR_4": 938619.985136325,
    "YEAR_5": 1203769.2215839566,
    "YEAR_6": 1468918.4580315878,
    "YEAR_7": 1734067.6944792191,
    "YEAR_8": 1999216.9309268508,
    "YEAR_9": 2264366.1673744824,
    "YEAR_10": 2529515.4038221133,
    "FIXED_Izak": 13352503.07004633
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Izak",
    "Total Smart Devices": 877940.0386,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1755.8800772,
    "YEAR_1": 128724.27081156286,
    "YEAR_2": 397475.06131560955,
    "YEAR_3": 655581.0044396389,
    "YEAR_4": 913686.9475636682,
    "YEAR_5": 1171792.8906876976,
    "YEAR_6": 1429898.8338117271,
    "YEAR_7": 1688004.7769357567,
    "YEAR_8": 1946110.720059786,
    "YEAR_9": 2204216.663183816,
    "YEAR_10": 2462322.6063078446,
    "FIXED_Izak": 12997813.775117107
  },
  {
    "DMA": "Jackson",
    "PlatformName": "Izak",
    "Total Smart Devices": 832501.5841,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1665.0031682000001,
    "YEAR_1": 122062.04826200927,
    "YEAR_2": 376903.43717909756,
    "YEAR_3": 621650.9108892902,
    "YEAR_4": 866398.3845994828,
    "YEAR_5": 1111145.8583096755,
    "YEAR_6": 1355893.3320198683,
    "YEAR_7": 1600640.805730061,
    "YEAR_8": 1845388.2794402533,
    "YEAR_9": 2090135.7531504459,
    "YEAR_10": 2334883.2268606387,
    "FIXED_Izak": 12325102.036440821
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Izak",
    "Total Smart Devices": 835761.6251,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1671.5232502,
    "YEAR_1": 122540.03808146206,
    "YEAR_2": 378379.3751012736,
    "YEAR_3": 624085.2695690726,
    "YEAR_4": 869791.1640368716,
    "YEAR_5": 1115497.0585046704,
    "YEAR_6": 1361202.9529724694,
    "YEAR_7": 1606908.8474402684,
    "YEAR_8": 1852614.7419080674,
    "YEAR_9": 2098320.6363758664,
    "YEAR_10": 2344026.5308436654,
    "FIXED_Izak": 12373366.614833688
  },
  {
    "DMA": "Boise",
    "PlatformName": "Izak",
    "Total Smart Devices": 805064.7535,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1610.129507,
    "YEAR_1": 118039.23820997281,
    "YEAR_2": 364481.79624057596,
    "YEAR_3": 601163.1051479424,
    "YEAR_4": 837844.4140553088,
    "YEAR_5": 1074525.7229626752,
    "YEAR_6": 1311207.0318700415,
    "YEAR_7": 1547888.340777408,
    "YEAR_8": 1784569.6496847747,
    "YEAR_9": 2021250.9585921406,
    "YEAR_10": 2257932.2674995074,
    "FIXED_Izak": 11918902.525040347
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Izak",
    "Total Smart Devices": 818778.2968,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1637.5565936000003,
    "YEAR_1": 120049.92889945344,
    "YEAR_2": 370690.4109800448,
    "YEAR_3": 611403.3699675957,
    "YEAR_4": 852116.3289551464,
    "YEAR_5": 1092829.287942697,
    "YEAR_6": 1333542.2469302479,
    "YEAR_7": 1574255.2059177984,
    "YEAR_8": 1814968.164905349,
    "YEAR_9": 2055681.1238928998,
    "YEAR_10": 2296394.0828804504,
    "FIXED_Izak": 12121930.151271682
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Izak",
    "Total Smart Devices": 788153.8513,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1576.3077026,
    "YEAR_1": 115559.74820068703,
    "YEAR_2": 356825.6220221568,
    "YEAR_3": 588535.2880273843,
    "YEAR_4": 820244.9540326117,
    "YEAR_5": 1051954.6200378393,
    "YEAR_6": 1283664.286043067,
    "YEAR_7": 1515373.9520482942,
    "YEAR_8": 1747083.6180535217,
    "YEAR_9": 1978793.2840587492,
    "YEAR_10": 2210502.950063977,
    "FIXED_Izak": 11668538.32258829
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Izak",
    "Total Smart Devices": 817105.2424,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1634.2104848000001,
    "YEAR_1": 119804.62432488192,
    "YEAR_2": 369932.9590232064,
    "YEAR_3": 610154.0560784794,
    "YEAR_4": 850375.1531337523,
    "YEAR_5": 1090596.2501890254,
    "YEAR_6": 1330817.347244298,
    "YEAR_7": 1571038.444299571,
    "YEAR_8": 1811259.5413548439,
    "YEAR_9": 2051480.638410117,
    "YEAR_10": 2291701.7354653897,
    "FIXED_Izak": 12097160.749523565
  },
  {
    "DMA": "Reno",
    "PlatformName": "Izak",
    "Total Smart Devices": 766046.8896,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1532.0937792000002,
    "YEAR_1": 112318.40779066368,
    "YEAR_2": 346817.0046099456,
    "YEAR_3": 572027.4361022054,
    "YEAR_4": 797237.8675944653,
    "YEAR_5": 1022448.2990867252,
    "YEAR_6": 1247658.7305789848,
    "YEAR_7": 1472869.1620712448,
    "YEAR_8": 1698079.5935635047,
    "YEAR_9": 1923290.0250557645,
    "YEAR_10": 2148500.456548024,
    "FIXED_Izak": 11341246.983001528
  },
  {
    "DMA": "Washington",
    "PlatformName": "Izak",
    "Total Smart Devices": 757188.4939,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1514.3769878000003,
    "YEAR_1": 111019.58272641314,
    "YEAR_2": 342806.48997431045,
    "YEAR_3": 565412.6381713691,
    "YEAR_4": 788018.7863684277,
    "YEAR_5": 1010624.9345654862,
    "YEAR_6": 1233231.082762545,
    "YEAR_7": 1455837.2309596033,
    "YEAR_8": 1678443.379156662,
    "YEAR_9": 1901049.5273537207,
    "YEAR_10": 2123655.675550779,
    "FIXED_Izak": 11210099.327589316
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Izak",
    "Total Smart Devices": 772710.2,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1545.4204,
    "YEAR_1": 113295.38769216,
    "YEAR_2": 349833.72510720004,
    "YEAR_3": 577003.10588928,
    "YEAR_4": 804172.48667136,
    "YEAR_5": 1031341.86745344,
    "YEAR_6": 1258511.2482355202,
    "YEAR_7": 1485680.6290176,
    "YEAR_8": 1712850.0097996802,
    "YEAR_9": 1940019.39058176,
    "YEAR_10": 2167188.77136384,
    "FIXED_Izak": 11439896.62181184
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Izak",
    "Total Smart Devices": 775398.3021,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1550.7966042,
    "YEAR_1": 113689.51937254367,
    "YEAR_2": 351050.7256995455,
    "YEAR_3": 579010.3826932453,
    "YEAR_4": 806970.0396869453,
    "YEAR_5": 1034929.6966806452,
    "YEAR_6": 1262889.3536743447,
    "YEAR_7": 1490849.0106680447,
    "YEAR_8": 1718808.6676617446,
    "YEAR_9": 1946768.3246554444,
    "YEAR_10": 2174727.981649144,
    "FIXED_Izak": 11479693.702441646
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Izak",
    "Total Smart Devices": 720630.3035,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1441.2606070000002,
    "YEAR_1": 105659.3916034128,
    "YEAR_2": 326255.28108537605,
    "YEAR_3": 538113.6722634626,
    "YEAR_4": 749972.063441549,
    "YEAR_5": 961830.4546196354,
    "YEAR_6": 1173688.8457977218,
    "YEAR_7": 1385547.2369758084,
    "YEAR_8": 1597405.6281538943,
    "YEAR_9": 1809264.0193319812,
    "YEAR_10": 2021122.4105100676,
    "FIXED_Izak": 10668859.003782908
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Izak",
    "Total Smart Devices": 728058.3645,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1456.1167290000003,
    "YEAR_1": 106748.49984968163,
    "YEAR_2": 329618.231710272,
    "YEAR_3": 543660.4015129729,
    "YEAR_4": 757702.5713156736,
    "YEAR_5": 971744.7411183745,
    "YEAR_6": 1185786.9109210754,
    "YEAR_7": 1399829.080723776,
    "YEAR_8": 1613871.2505264776,
    "YEAR_9": 1827913.420329178,
    "YEAR_10": 2041955.5901318786,
    "FIXED_Izak": 10778830.698139362
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Izak",
    "Total Smart Devices": 714649.2487,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1429.2984974,
    "YEAR_1": 104782.44456379296,
    "YEAR_2": 323547.4422594432,
    "YEAR_3": 533647.4607444557,
    "YEAR_4": 743747.4792294682,
    "YEAR_5": 953847.4977144806,
    "YEAR_6": 1163947.516199493,
    "YEAR_7": 1374047.5346845055,
    "YEAR_8": 1584147.5531695182,
    "YEAR_9": 1794247.571654531,
    "YEAR_10": 2004347.5901395436,
    "FIXED_Izak": 10580310.090359231
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Izak",
    "Total Smart Devices": 723124.6195,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1446.2492390000002,
    "YEAR_1": 106025.1102107856,
    "YEAR_2": 327384.547733952,
    "YEAR_3": 539976.2438706048,
    "YEAR_4": 752567.9400072575,
    "YEAR_5": 965159.6361439107,
    "YEAR_6": 1177751.3322805632,
    "YEAR_7": 1390343.028417216,
    "YEAR_8": 1602934.7245538686,
    "YEAR_9": 1815526.4206905216,
    "YEAR_10": 2028118.1168271748,
    "FIXED_Izak": 10705787.100735854
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Izak",
    "Total Smart Devices": 722266.5486,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1444.5330972000002,
    "YEAR_1": 105899.29916897089,
    "YEAR_2": 326996.06814696966,
    "YEAR_3": 539335.499676503,
    "YEAR_4": 751674.9312060365,
    "YEAR_5": 964014.36273557,
    "YEAR_6": 1176353.7942651033,
    "YEAR_7": 1388693.2257946369,
    "YEAR_8": 1601032.6573241705,
    "YEAR_9": 1813372.0888537038,
    "YEAR_10": 2025711.5203832374,
    "FIXED_Izak": 10693083.447554901
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Izak",
    "Total Smart Devices": 712861.0057,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1425.7220114000002,
    "YEAR_1": 104520.25094453857,
    "YEAR_2": 322737.8402765952,
    "YEAR_3": 532312.1324867405,
    "YEAR_4": 741886.4246968859,
    "YEAR_5": 951460.7169070309,
    "YEAR_6": 1161035.0091171763,
    "YEAR_7": 1370609.3013273217,
    "YEAR_8": 1580183.5935374673,
    "YEAR_9": 1789757.885747612,
    "YEAR_10": 1999332.1779577574,
    "FIXED_Izak": 10553835.332999125
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Izak",
    "Total Smart Devices": 693737.697,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1387.475394,
    "YEAR_1": 101716.37612429759,
    "YEAR_2": 314080.02998899197,
    "YEAR_3": 518032.2530251008,
    "YEAR_4": 721984.4760612096,
    "YEAR_5": 925936.6990973183,
    "YEAR_6": 1129888.922133427,
    "YEAR_7": 1333841.145169536,
    "YEAR_8": 1537793.3682056447,
    "YEAR_9": 1741745.5912417537,
    "YEAR_10": 1945697.8142778627,
    "FIXED_Izak": 10270716.675325142
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Izak",
    "Total Smart Devices": 661038.8766,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1322.0777532000002,
    "YEAR_1": 96922.0489181933,
    "YEAR_2": 299276.0968363777,
    "YEAR_3": 493615.18058356224,
    "YEAR_4": 687954.264330747,
    "YEAR_5": 882293.3480779318,
    "YEAR_6": 1076632.4318251163,
    "YEAR_7": 1270971.515572301,
    "YEAR_8": 1465310.5993194855,
    "YEAR_9": 1659649.68306667,
    "YEAR_10": 1853988.7668138547,
    "FIXED_Izak": 9786613.935344238
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Izak",
    "Total Smart Devices": 672775.9124,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1345.5518248,
    "YEAR_1": 98642.94249681794,
    "YEAR_2": 304589.8754763264,
    "YEAR_3": 502379.5350731673,
    "YEAR_4": 700169.1946700084,
    "YEAR_5": 897958.8542668493,
    "YEAR_6": 1095748.5138636902,
    "YEAR_7": 1293538.173460531,
    "YEAR_8": 1491327.8330573724,
    "YEAR_9": 1689117.4926542132,
    "YEAR_10": 1886907.1522510538,
    "FIXED_Izak": 9960379.56727003
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Izak",
    "Total Smart Devices": 647106.4308,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1294.2128616,
    "YEAR_1": 94879.26256904064,
    "YEAR_2": 292968.3770546688,
    "YEAR_3": 483211.455488133,
    "YEAR_4": 673454.5339215975,
    "YEAR_5": 863697.6123550616,
    "YEAR_6": 1053940.6907885259,
    "YEAR_7": 1244183.7692219901,
    "YEAR_8": 1434426.8476554544,
    "YEAR_9": 1624669.9260889192,
    "YEAR_10": 1814913.004522383,
    "FIXED_Izak": 9580345.479665775
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Izak",
    "Total Smart Devices": 650772.6644,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1301.5453288,
    "YEAR_1": 95416.80867245953,
    "YEAR_2": 294628.21298979846,
    "YEAR_3": 485949.1289058202,
    "YEAR_4": 677270.0448218419,
    "YEAR_5": 868590.9607378638,
    "YEAR_6": 1059911.8766538855,
    "YEAR_7": 1251232.7925699074,
    "YEAR_8": 1442553.708485929,
    "YEAR_9": 1633874.6244019507,
    "YEAR_10": 1825195.5403179727,
    "FIXED_Izak": 9634623.698557429
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Izak",
    "Total Smart Devices": 655342.6546,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1310.6853092000001,
    "YEAR_1": 96086.8642915757,
    "YEAR_2": 296697.2120729856,
    "YEAR_3": 489361.6612359014,
    "YEAR_4": 682026.1103988173,
    "YEAR_5": 874690.5595617329,
    "YEAR_6": 1067355.008724649,
    "YEAR_7": 1260019.4578875646,
    "YEAR_8": 1452683.9070504808,
    "YEAR_9": 1645348.3562133964,
    "YEAR_10": 1838012.8053763122,
    "FIXED_Izak": 9702281.942813417
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Izak",
    "Total Smart Devices": 631244.1375,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1262.488275,
    "YEAR_1": 92553.52043555999,
    "YEAR_2": 285786.9458352,
    "YEAR_3": 471366.66231647995,
    "YEAR_4": 656946.3787977601,
    "YEAR_5": 842526.0952790398,
    "YEAR_6": 1028105.81176032,
    "YEAR_7": 1213685.5282416,
    "YEAR_8": 1399265.24472288,
    "YEAR_9": 1584844.9612041602,
    "YEAR_10": 1770424.67768544,
    "FIXED_Izak": 9345505.826278439
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Izak",
    "Total Smart Devices": 615432.0772,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1230.8641544000002,
    "YEAR_1": 90235.14350472578,
    "YEAR_2": 278628.2569032192,
    "YEAR_3": 459559.3794520781,
    "YEAR_4": 640490.502000937,
    "YEAR_5": 821421.6245497959,
    "YEAR_6": 1002352.7470986547,
    "YEAR_7": 1183283.8696475136,
    "YEAR_8": 1364214.9921963725,
    "YEAR_9": 1545146.1147452313,
    "YEAR_10": 1726077.2372940907,
    "FIXED_Izak": 9111409.867392618
  },
  {
    "DMA": "Macon",
    "PlatformName": "Izak",
    "Total Smart Devices": 623908.0976,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1247.8161952,
    "YEAR_1": 91477.90439659008,
    "YEAR_2": 282465.6564750336,
    "YEAR_3": 465888.64765169664,
    "YEAR_4": 649311.6388283598,
    "YEAR_5": 832734.6300050227,
    "YEAR_6": 1016157.6211816856,
    "YEAR_7": 1199580.6123583487,
    "YEAR_8": 1383003.6035350119,
    "YEAR_9": 1566426.5947116746,
    "YEAR_10": 1749849.585888338,
    "FIXED_Izak": 9236896.495031761
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Izak",
    "Total Smart Devices": 615383.1435,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1230.7662870000001,
    "YEAR_1": 90227.9688064848,
    "YEAR_2": 278606.102855616,
    "YEAR_3": 459522.83936643845,
    "YEAR_4": 640439.5758772609,
    "YEAR_5": 821356.3123880833,
    "YEAR_6": 1002273.0488989056,
    "YEAR_7": 1183189.785409728,
    "YEAR_8": 1364106.5219205506,
    "YEAR_9": 1545023.2584313732,
    "YEAR_10": 1725939.9949421955,
    "FIXED_Izak": 9110685.408896636
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Izak",
    "Total Smart Devices": 624929.966,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1249.8599320000003,
    "YEAR_1": 91627.73155889282,
    "YEAR_2": 282928.29308697605,
    "YEAR_3": 466651.70376330253,
    "YEAR_4": 650375.1144396288,
    "YEAR_5": 834098.5251159554,
    "YEAR_6": 1017821.9357922818,
    "YEAR_7": 1201545.3464686084,
    "YEAR_8": 1385268.7571449345,
    "YEAR_9": 1568992.1678212609,
    "YEAR_10": 1752715.5784975872,
    "FIXED_Izak": 9252025.15368943
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Izak",
    "Total Smart Devices": 631714.9611,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1263.4299222,
    "YEAR_1": 92622.55296845088,
    "YEAR_2": 286000.1046285696,
    "YEAR_3": 471718.23872834304,
    "YEAR_4": 657436.3728281164,
    "YEAR_5": 843154.5069278898,
    "YEAR_6": 1028872.6410276633,
    "YEAR_7": 1214590.7751274367,
    "YEAR_8": 1400308.90922721,
    "YEAR_9": 1586027.0433269835,
    "YEAR_10": 1771745.1774267573,
    "FIXED_Izak": 9352476.32221742
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Izak",
    "Total Smart Devices": 617472.2414,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1234.9444828000003,
    "YEAR_1": 90534.27401186114,
    "YEAR_2": 279551.9126824705,
    "YEAR_3": 461082.8239205531,
    "YEAR_4": 642613.7351586355,
    "YEAR_5": 824144.6463967183,
    "YEAR_6": 1005675.5576348008,
    "YEAR_7": 1187206.4688728836,
    "YEAR_8": 1368737.380110966,
    "YEAR_9": 1550268.2913490487,
    "YEAR_10": 1731799.2025871314,
    "FIXED_Izak": 9141614.29272507
  },
  {
    "DMA": "Lafayette",
    "PlatformName": "Izak",
    "Total Smart Devices": 607971.4294,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1215.9428588,
    "YEAR_1": 89141.25735577151,
    "YEAR_2": 275250.5530608384,
    "YEAR_3": 453988.3167787162,
    "YEAR_4": 632726.0804965941,
    "YEAR_5": 811463.8442144718,
    "YEAR_6": 990201.6079323496,
    "YEAR_7": 1168939.371650227,
    "YEAR_8": 1347677.135368105,
    "YEAR_9": 1526414.8990859827,
    "YEAR_10": 1705152.6628038604,
    "FIXED_Izak": 9000955.728746917
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Izak",
    "Total Smart Devices": 609396.2424,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1218.7924848,
    "YEAR_1": 89350.1645776819,
    "YEAR_2": 275895.61719920643,
    "YEAR_3": 455052.2622608793,
    "YEAR_4": 634208.9073225523,
    "YEAR_5": 813365.5523842252,
    "YEAR_6": 992522.1974458981,
    "YEAR_7": 1171678.842507571,
    "YEAR_8": 1350835.4875692439,
    "YEAR_9": 1529992.1326309168,
    "YEAR_10": 1709148.77769259,
    "FIXED_Izak": 9022049.941590765
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Izak",
    "Total Smart Devices": 597656.2835,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1195.3125670000002,
    "YEAR_1": 87628.8424117968,
    "YEAR_2": 270580.515166656,
    "YEAR_3": 446285.72501533444,
    "YEAR_4": 621990.9348640129,
    "YEAR_5": 797696.1447126912,
    "YEAR_6": 973401.3545613696,
    "YEAR_7": 1149106.564410048,
    "YEAR_8": 1324811.7742587265,
    "YEAR_9": 1500516.984107405,
    "YEAR_10": 1676222.193956083,
    "FIXED_Izak": 8848241.033464124
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Izak",
    "Total Smart Devices": 569961.0358,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1139.9220716,
    "YEAR_1": 83568.14303782463,
    "YEAR_2": 258041.8795039488,
    "YEAR_3": 425604.95240320504,
    "YEAR_4": 593168.0253024613,
    "YEAR_5": 760731.0982017177,
    "YEAR_6": 928294.1711009741,
    "YEAR_7": 1095857.2440002302,
    "YEAR_8": 1263420.3168994866,
    "YEAR_9": 1430983.389798743,
    "YEAR_10": 1598546.4626979996,
    "FIXED_Izak": 8438215.68294659
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Izak",
    "Total Smart Devices": 552884.0625,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1105.768125,
    "YEAR_1": 81064.30355100002,
    "YEAR_2": 250310.51892,
    "YEAR_3": 412853.125608,
    "YEAR_4": 575395.7322960001,
    "YEAR_5": 737938.3389840001,
    "YEAR_6": 900480.945672,
    "YEAR_7": 1063023.5523599999,
    "YEAR_8": 1225566.159048,
    "YEAR_9": 1388108.765736,
    "YEAR_10": 1550651.372424,
    "FIXED_Izak": 8185392.814599
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Izak",
    "Total Smart Devices": 537565.9934,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1075.1319868,
    "YEAR_1": 78818.35600510272,
    "YEAR_2": 243375.47758794233,
    "YEAR_3": 401414.71901400574,
    "YEAR_4": 559453.9604400691,
    "YEAR_5": 717493.2018661323,
    "YEAR_6": 875532.4432921957,
    "YEAR_7": 1033571.6847182589,
    "YEAR_8": 1191610.9261443224,
    "YEAR_9": 1349650.1675703856,
    "YEAR_10": 1507689.408996449,
    "FIXED_Izak": 7958610.345634864
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Izak",
    "Total Smart Devices": 510390.503,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1020.7810060000002,
    "YEAR_1": 74833.8638622624,
    "YEAR_2": 231072.15476620806,
    "YEAR_3": 381122.06289937923,
    "YEAR_4": 531171.9710325504,
    "YEAR_5": 681221.8791657216,
    "YEAR_6": 831271.7872988929,
    "YEAR_7": 981321.6954320641,
    "YEAR_8": 1131371.6035652354,
    "YEAR_9": 1281421.5116984067,
    "YEAR_10": 1431471.4198315777,
    "FIXED_Izak": 7556279.949552299
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Izak",
    "Total Smart Devices": 480098.5248,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 960.1970496,
    "YEAR_1": 70392.42978499584,
    "YEAR_2": 217357.88572385279,
    "YEAR_3": 358502.24306921475,
    "YEAR_4": 499646.60041457653,
    "YEAR_5": 640790.9577599386,
    "YEAR_6": 781935.3151053005,
    "YEAR_7": 923079.6724506624,
    "YEAR_8": 1064224.0297960243,
    "YEAR_9": 1205368.3871413863,
    "YEAR_10": 1346512.7444867478,
    "FIXED_Izak": 7107810.265732698
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Izak",
    "Total Smart Devices": 492907.9867,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 985.8159734000001,
    "YEAR_1": 72270.56333634336,
    "YEAR_2": 223157.1902666112,
    "YEAR_3": 368067.40643973893,
    "YEAR_4": 512977.6226128665,
    "YEAR_5": 657887.8387859943,
    "YEAR_6": 802798.0549591221,
    "YEAR_7": 947708.2711322496,
    "YEAR_8": 1092618.4873053774,
    "YEAR_9": 1237528.7034785051,
    "YEAR_10": 1382438.919651633,
    "FIXED_Izak": 7297453.057968441
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Izak",
    "Total Smart Devices": 470002.9309,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 940.0058617999999,
    "YEAR_1": 68912.2057309027,
    "YEAR_2": 212787.24692394238,
    "YEAR_3": 350963.59658040566,
    "YEAR_4": 489139.94623686903,
    "YEAR_5": 627316.2958933322,
    "YEAR_6": 765492.6455497958,
    "YEAR_7": 903668.9952062592,
    "YEAR_8": 1041845.3448627224,
    "YEAR_9": 1180021.6945191857,
    "YEAR_10": 1318198.044175649,
    "FIXED_Izak": 6958346.015679064
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Izak",
    "Total Smart Devices": 465738.6675,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 931.477335,
    "YEAR_1": 68286.976019784,
    "YEAR_2": 210856.66136928,
    "YEAR_3": 347779.35852307203,
    "YEAR_4": 484702.055676864,
    "YEAR_5": 621624.752830656,
    "YEAR_6": 758547.449984448,
    "YEAR_7": 895470.1471382399,
    "YEAR_8": 1032392.8442920321,
    "YEAR_9": 1169315.541445824,
    "YEAR_10": 1306238.238599616,
    "FIXED_Izak": 6895214.025879817
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Izak",
    "Total Smart Devices": 454093.9776,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 908.1879552,
    "YEAR_1": 66579.62227089406,
    "YEAR_2": 205584.69104271356,
    "YEAR_3": 339083.96115492866,
    "YEAR_4": 472583.2312671436,
    "YEAR_5": 606082.5013793587,
    "YEAR_6": 739581.7714915738,
    "YEAR_7": 873081.0416037888,
    "YEAR_8": 1006580.3117160038,
    "YEAR_9": 1140079.581828219,
    "YEAR_10": 1273578.8519404337,
    "FIXED_Izak": 6722815.565695057
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Izak",
    "Total Smart Devices": 451807.1961,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 903.6143922000001,
    "YEAR_1": 66244.33253793888,
    "YEAR_2": 204549.3827335296,
    "YEAR_3": 337376.36103784706,
    "YEAR_4": 470203.33934216446,
    "YEAR_5": 603030.3176464819,
    "YEAR_6": 735857.2959507994,
    "YEAR_7": 868684.2742551169,
    "YEAR_8": 1001511.2525594344,
    "YEAR_9": 1134338.2308637518,
    "YEAR_10": 1267165.209168069,
    "FIXED_Izak": 6688959.996095134
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Izak",
    "Total Smart Devices": 466140.3129,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 932.2806258,
    "YEAR_1": 68345.86558964831,
    "YEAR_2": 211038.5007010944,
    "YEAR_3": 348079.2777466905,
    "YEAR_4": 485120.05479228665,
    "YEAR_5": 622160.8318378828,
    "YEAR_6": 759201.608883479,
    "YEAR_7": 896242.3859290751,
    "YEAR_8": 1033283.1629746713,
    "YEAR_9": 1170323.9400202674,
    "YEAR_10": 1307364.7170658638,
    "FIXED_Izak": 6901160.345540959
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Izak",
    "Total Smart Devices": 484230.3852,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 968.4607704,
    "YEAR_1": 70998.24646233216,
    "YEAR_2": 219228.52767390717,
    "YEAR_3": 361587.6123110093,
    "YEAR_4": 503946.6969481113,
    "YEAR_5": 646305.7815852134,
    "YEAR_6": 788664.8662223155,
    "YEAR_7": 931023.9508594176,
    "YEAR_8": 1073383.0354965196,
    "YEAR_9": 1215742.1201336214,
    "YEAR_10": 1358101.2047707238,
    "FIXED_Izak": 7168982.042463172
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Izak",
    "Total Smart Devices": 481277.3701,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 962.5547402000001,
    "YEAR_1": 70565.27302595807,
    "YEAR_2": 217891.59142959362,
    "YEAR_3": 359382.5179762407,
    "YEAR_4": 500873.4445228877,
    "YEAR_5": 642364.3710695348,
    "YEAR_6": 783855.2976161818,
    "YEAR_7": 925346.2241628286,
    "YEAR_8": 1066837.1507094759,
    "YEAR_9": 1208328.0772561228,
    "YEAR_10": 1349819.0038027698,
    "FIXED_Izak": 7125262.951571594
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Izak",
    "Total Smart Devices": 453919.7864,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 907.8395728,
    "YEAR_1": 66554.08221779713,
    "YEAR_2": 205505.82841559043,
    "YEAR_3": 338953.8879872409,
    "YEAR_4": 472401.94755889155,
    "YEAR_5": 605850.0071305421,
    "YEAR_6": 739298.0667021926,
    "YEAR_7": 872746.1262738431,
    "YEAR_8": 1006194.1858454937,
    "YEAR_9": 1139642.2454171444,
    "YEAR_10": 1273090.304988795,
    "FIXED_Izak": 6720236.682537531
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Izak",
    "Total Smart Devices": 449346.8731,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 898.6937462000001,
    "YEAR_1": 65883.59801142049,
    "YEAR_2": 203435.5059398016,
    "YEAR_3": 335539.1729012198,
    "YEAR_4": 467642.8398626381,
    "YEAR_5": 599746.5068240564,
    "YEAR_6": 731850.1737854746,
    "YEAR_7": 863953.8407468931,
    "YEAR_8": 996057.5077083112,
    "YEAR_9": 1128161.1746697295,
    "YEAR_10": 1260264.8416311475,
    "FIXED_Izak": 6652535.162080692
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Izak",
    "Total Smart Devices": 443416.376,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 886.8327520000001,
    "YEAR_1": 65014.0637822208,
    "YEAR_2": 200750.556404736,
    "YEAR_3": 331110.7141515264,
    "YEAR_4": 461470.8718983169,
    "YEAR_5": 591831.0296451073,
    "YEAR_6": 722191.1873918977,
    "YEAR_7": 852551.345138688,
    "YEAR_8": 982911.5028854785,
    "YEAR_9": 1113271.660632269,
    "YEAR_10": 1243631.8183790594,
    "FIXED_Izak": 6564734.7503093
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Izak",
    "Total Smart Devices": 443630.0818,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 887.2601635999999,
    "YEAR_1": 65045.397497581434,
    "YEAR_2": 200847.30871380476,
    "YEAR_3": 331270.2939142194,
    "YEAR_4": 461693.27911463415,
    "YEAR_5": 592116.2643150488,
    "YEAR_6": 722539.2495154636,
    "YEAR_7": 852962.2347158784,
    "YEAR_8": 983385.2199162929,
    "YEAR_9": 1113808.2051167078,
    "YEAR_10": 1244231.1903171223,
    "FIXED_Izak": 6567898.643136754
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Izak",
    "Total Smart Devices": 423818.9958,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 847.6379916000001,
    "YEAR_1": 62140.68021939264,
    "YEAR_2": 191878.11688250885,
    "YEAR_3": 316476.8329853491,
    "YEAR_4": 441075.54908818944,
    "YEAR_5": 565674.2651910298,
    "YEAR_6": 690272.9812938703,
    "YEAR_7": 814871.6973967105,
    "YEAR_8": 939470.4134995508,
    "YEAR_9": 1064069.1296023913,
    "YEAR_10": 1188667.8457052317,
    "FIXED_Izak": 6274597.511864224
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Izak",
    "Total Smart Devices": 393958.0018,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 787.9160036000002,
    "YEAR_1": 57762.43739031744,
    "YEAR_2": 178358.96990292481,
    "YEAR_3": 294178.8404353076,
    "YEAR_4": 409998.7109676904,
    "YEAR_5": 525818.5815000731,
    "YEAR_6": 641638.4520324559,
    "YEAR_7": 757458.3225648386,
    "YEAR_8": 873278.1930972212,
    "YEAR_9": 989098.0636296041,
    "YEAR_10": 1104917.9341619867,
    "FIXED_Izak": 5832508.50568242
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Izak",
    "Total Smart Devices": 422552.4588,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 845.1049176000001,
    "YEAR_1": 61954.97955122304,
    "YEAR_2": 191304.70998727687,
    "YEAR_3": 315531.0763708724,
    "YEAR_4": 439757.44275446783,
    "YEAR_5": 563983.8091380633,
    "YEAR_6": 688210.175521659,
    "YEAR_7": 812436.5419052544,
    "YEAR_8": 936662.90828885,
    "YEAR_9": 1060889.2746724454,
    "YEAR_10": 1185115.6410560408,
    "FIXED_Izak": 6255846.559246153
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Izak",
    "Total Smart Devices": 413572.7033,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 827.1454066000001,
    "YEAR_1": 60638.36061600864,
    "YEAR_2": 187239.25140122883,
    "YEAR_3": 308825.65587347717,
    "YEAR_4": 430412.0603457254,
    "YEAR_5": 551998.4648179738,
    "YEAR_6": 673584.8692902222,
    "YEAR_7": 795171.2737624705,
    "YEAR_8": 916757.6782347187,
    "YEAR_9": 1038344.0827069673,
    "YEAR_10": 1159930.4871792153,
    "FIXED_Izak": 6122902.184228008
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Izak",
    "Total Smart Devices": 389215.4444,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 778.4308888,
    "YEAR_1": 57067.07983028352,
    "YEAR_2": 176211.84343587837,
    "YEAR_3": 290637.44762121217,
    "YEAR_4": 405063.0518065458,
    "YEAR_5": 519488.65599187964,
    "YEAR_6": 633914.2601772135,
    "YEAR_7": 748339.8643625472,
    "YEAR_8": 862765.4685478809,
    "YEAR_9": 977191.0727332146,
    "YEAR_10": 1091616.6769185483,
    "FIXED_Izak": 5762295.421425204
  },
  {
    "DMA": "Erie",
    "PlatformName": "Izak",
    "Total Smart Devices": 409022.6328,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 818.0452656000001,
    "YEAR_1": 59971.22563924224,
    "YEAR_2": 185179.2706833408,
    "YEAR_3": 305427.9981092659,
    "YEAR_4": 425676.725535191,
    "YEAR_5": 545925.4529611162,
    "YEAR_6": 666174.1803870413,
    "YEAR_7": 786422.9078129665,
    "YEAR_8": 906671.6352388916,
    "YEAR_9": 1026920.3626648167,
    "YEAR_10": 1147169.0900907419,
    "FIXED_Izak": 6055538.849122614
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Izak",
    "Total Smart Devices": 419474.8359,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 838.9496718,
    "YEAR_1": 61503.73601952672,
    "YEAR_2": 189911.3593060224,
    "YEAR_3": 313232.93410219776,
    "YEAR_4": 436554.5088983731,
    "YEAR_5": 559876.0836945485,
    "YEAR_6": 683197.6584907239,
    "YEAR_7": 806519.2332868993,
    "YEAR_8": 929840.8080830746,
    "YEAR_9": 1053162.38287925,
    "YEAR_10": 1176483.9576754253,
    "FIXED_Izak": 6210282.662436042
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Izak",
    "Total Smart Devices": 420021.6584,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 840.0433168,
    "YEAR_1": 61583.91157193472,
    "YEAR_2": 190158.92553738237,
    "YEAR_3": 313641.2608990618,
    "YEAR_4": 437123.5962607412,
    "YEAR_5": 560605.9316224203,
    "YEAR_6": 684088.2669840999,
    "YEAR_7": 807570.6023457791,
    "YEAR_8": 931052.9377074585,
    "YEAR_9": 1054535.2730691377,
    "YEAR_10": 1178017.608430817,
    "FIXED_Izak": 6218378.314428832
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Izak",
    "Total Smart Devices": 393926.0646,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 787.8521292,
    "YEAR_1": 57757.75473250369,
    "YEAR_2": 178344.5107827456,
    "YEAR_3": 294154.99208492547,
    "YEAR_4": 409965.4733871053,
    "YEAR_5": 525775.9546892851,
    "YEAR_6": 641586.435991465,
    "YEAR_7": 757396.9172936447,
    "YEAR_8": 873207.3985958244,
    "YEAR_9": 989017.8798980042,
    "YEAR_10": 1104828.361200184,
    "FIXED_Izak": 5832035.678655688
  },
  {
    "DMA": "New York",
    "PlatformName": "Izak",
    "Total Smart Devices": 18429594.24,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 36859.188480000004,
    "overall Metered": 5528.878272000001,
    "YEAR_1": 405324.27767162886,
    "YEAR_2": 1251561.116676096,
    "YEAR_3": 2064279.6840443907,
    "YEAR_4": 2876998.2514126855,
    "YEAR_5": 3689716.8187809796,
    "YEAR_6": 4502435.386149273,
    "YEAR_7": 5315153.953517568,
    "YEAR_8": 6127872.520885862,
    "YEAR_9": 6940591.0882541565,
    "YEAR_10": 7753309.655622452,
    "METERED_Izak": 40927242.75301509
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Izak",
    "Total Smart Devices": 13947729.05,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 27895.458100000003,
    "overall Metered": 4184.318715,
    "YEAR_1": 306754.078724136,
    "YEAR_2": 947195.85887712,
    "YEAR_3": 1562270.6252522883,
    "YEAR_4": 2177345.391627456,
    "YEAR_5": 2792420.158002624,
    "YEAR_6": 3407494.9243777916,
    "YEAR_7": 4022569.6907529603,
    "YEAR_8": 4637644.457128128,
    "YEAR_9": 5252719.223503297,
    "YEAR_10": 5867793.989878464,
    "METERED_Izak": 30974208.398124266
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Izak",
    "Total Smart Devices": 8667326.99,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 17334.65398,
    "overall Metered": 2600.198097,
    "YEAR_1": 190621.56257030877,
    "YEAR_2": 588601.642821696,
    "YEAR_3": 970818.2821298304,
    "YEAR_4": 1353034.9214379648,
    "YEAR_5": 1735251.5607460993,
    "YEAR_6": 2117468.200054233,
    "YEAR_7": 2499684.8393623685,
    "YEAR_8": 2881901.4786705016,
    "YEAR_9": 3264118.1179786366,
    "YEAR_10": 3646334.757286771,
    "METERED_Izak": 19247835.36305841
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Izak",
    "Total Smart Devices": 7450689.965,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 14901.379930000003,
    "overall Metered": 2235.2069895000004,
    "YEAR_1": 163863.91848304082,
    "YEAR_2": 505979.335799136,
    "YEAR_3": 834544.0342620865,
    "YEAR_4": 1163108.732725037,
    "YEAR_5": 1491673.4311879873,
    "YEAR_6": 1820238.1296509379,
    "YEAR_7": 2148802.828113888,
    "YEAR_8": 2477367.5265768385,
    "YEAR_9": 2805932.2250397885,
    "YEAR_10": 3134496.923502739,
    "METERED_Izak": 16546007.08534148
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Izak",
    "Total Smart Devices": 7285114.017,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 14570.228034,
    "overall Metered": 2185.5342051,
    "YEAR_1": 160222.38678956302,
    "YEAR_2": 494735.00694007665,
    "YEAR_3": 815998.0445255921,
    "YEAR_4": 1137261.0821111077,
    "YEAR_5": 1458524.119696623,
    "YEAR_6": 1779787.1572821387,
    "YEAR_7": 2101050.1948676542,
    "YEAR_8": 2422313.2324531698,
    "YEAR_9": 2743576.270038685,
    "YEAR_10": 3064839.3076242,
    "METERED_Izak": 16178306.80232881
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Izak",
    "Total Smart Devices": 6409667.645,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12819.33529,
    "overall Metered": 1922.9002935,
    "YEAR_1": 140968.5896766024,
    "YEAR_2": 435283.093639008,
    "YEAR_3": 717940.2068620991,
    "YEAR_4": 1000597.3200851905,
    "YEAR_5": 1283254.4333082815,
    "YEAR_6": 1565911.5465313727,
    "YEAR_7": 1848568.6597544637,
    "YEAR_8": 2131225.7729775547,
    "YEAR_9": 2413882.8862006464,
    "YEAR_10": 2696539.9994237376,
    "METERED_Izak": 14234172.508458957
  },
  {
    "DMA": "Houston",
    "PlatformName": "Izak",
    "Total Smart Devices": 6391466.714,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12782.933428,
    "overall Metered": 1917.4400142,
    "YEAR_1": 140568.29441700765,
    "YEAR_2": 434047.0611344255,
    "YEAR_3": 715901.5395097574,
    "YEAR_4": 997756.0178850889,
    "YEAR_5": 1279610.496260421,
    "YEAR_6": 1561464.9746357528,
    "YEAR_7": 1843319.4530110846,
    "YEAR_8": 2125173.9313864163,
    "YEAR_9": 2407028.409761748,
    "YEAR_10": 2688882.8881370793,
    "METERED_Izak": 14193753.066138782
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Izak",
    "Total Smart Devices": 6271809.788,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12543.619576000001,
    "overall Metered": 1881.5429364000001,
    "YEAR_1": 137936.66528465855,
    "YEAR_2": 425921.1114269951,
    "YEAR_3": 702498.8916717004,
    "YEAR_4": 979076.6719164058,
    "YEAR_5": 1255654.4521611112,
    "YEAR_6": 1532232.2324058162,
    "YEAR_7": 1808810.012650521,
    "YEAR_8": 2085387.7928952263,
    "YEAR_9": 2361965.573139932,
    "YEAR_10": 2638543.3533846373,
    "METERED_Izak": 13928026.756937005
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Izak",
    "Total Smart Devices": 6261412.472,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12522.824944,
    "overall Metered": 1878.4237415999999,
    "YEAR_1": 137707.99586619262,
    "YEAR_2": 425215.0255385087,
    "YEAR_3": 701334.299119749,
    "YEAR_4": 977453.5727009892,
    "YEAR_5": 1253572.8462822298,
    "YEAR_6": 1529692.1198634696,
    "YEAR_7": 1805811.3934447102,
    "YEAR_8": 2081930.6670259505,
    "YEAR_9": 2358049.940607191,
    "YEAR_10": 2634169.214188431,
    "METERED_Izak": 13904937.07463742
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Izak",
    "Total Smart Devices": 6219314.93,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 12438.629860000001,
    "overall Metered": 1865.7944790000001,
    "YEAR_1": 136782.13957328157,
    "YEAR_2": 422356.16462227196,
    "YEAR_3": 696618.9972217728,
    "YEAR_4": 970881.8298212737,
    "YEAR_5": 1245144.6624207746,
    "YEAR_6": 1519407.4950202752,
    "YEAR_7": 1793670.3276197761,
    "YEAR_8": 2067933.1602192768,
    "YEAR_9": 2342195.992818778,
    "YEAR_10": 2616458.8254182786,
    "METERED_Izak": 13811449.59475576
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Izak",
    "Total Smart Devices": 5145201.67,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 10290.40334,
    "overall Metered": 1543.5605010000002,
    "YEAR_1": 113159.0377525104,
    "YEAR_2": 349412.703490368,
    "YEAR_3": 576308.6880469632,
    "YEAR_4": 803204.6726035584,
    "YEAR_5": 1030100.6571601536,
    "YEAR_6": 1256996.6417167487,
    "YEAR_7": 1483892.626273344,
    "YEAR_8": 1710788.6108299391,
    "YEAR_9": 1937684.5953865345,
    "YEAR_10": 2164580.5799431293,
    "METERED_Izak": 11426128.81320325
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Izak",
    "Total Smart Devices": 5080779.563,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 10161.559126,
    "overall Metered": 1524.2338689,
    "YEAR_1": 111742.19462260655,
    "YEAR_2": 345037.7724351552,
    "YEAR_3": 569092.8348408844,
    "YEAR_4": 793147.8972466139,
    "YEAR_5": 1017202.9596523431,
    "YEAR_6": 1241258.0220580723,
    "YEAR_7": 1465313.0844638015,
    "YEAR_8": 1689368.1468695307,
    "YEAR_9": 1913423.20927526,
    "YEAR_10": 2137478.2716809893,
    "METERED_Izak": 11283064.393145256
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Izak",
    "Total Smart Devices": 4962123.612,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 9924.247224,
    "overall Metered": 1488.6370836,
    "YEAR_1": 109132.58005354943,
    "YEAR_2": 336979.79934036476,
    "YEAR_3": 555802.3051715634,
    "YEAR_4": 774624.8110027623,
    "YEAR_5": 993447.316833961,
    "YEAR_6": 1212269.8226651598,
    "YEAR_7": 1431092.3284963581,
    "YEAR_8": 1649914.8343275574,
    "YEAR_9": 1868737.3401587554,
    "YEAR_10": 2087559.8459899544,
    "METERED_Izak": 11019560.984039985
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Izak",
    "Total Smart Devices": 4639998.133,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 9279.996266,
    "overall Metered": 1391.9994399,
    "YEAR_1": 102048.03573884495,
    "YEAR_2": 315104.1292112832,
    "YEAR_3": 519721.3652792716,
    "YEAR_4": 724338.6013472601,
    "YEAR_5": 928955.8374152486,
    "YEAR_6": 1133573.073483237,
    "YEAR_7": 1338190.3095512257,
    "YEAR_8": 1542807.545619214,
    "YEAR_9": 1747424.7816872026,
    "YEAR_10": 1952042.0177551913,
    "METERED_Izak": 10304205.697087979
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Izak",
    "Total Smart Devices": 4419136.81,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 8838.27362,
    "overall Metered": 1325.741043,
    "YEAR_1": 97190.60615874718,
    "YEAR_2": 300105.348421824,
    "YEAR_3": 494982.91818581754,
    "YEAR_4": 689860.4879498112,
    "YEAR_5": 884738.0577138045,
    "YEAR_6": 1079615.6274777984,
    "YEAR_7": 1274493.197241792,
    "YEAR_8": 1469370.7670057854,
    "YEAR_9": 1664248.336769779,
    "YEAR_10": 1859125.9065337724,
    "METERED_Izak": 9813731.253458932
  },
  {
    "DMA": "Denver",
    "PlatformName": "Izak",
    "Total Smart Devices": 4310338.605,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 8620.677210000002,
    "overall Metered": 1293.1015815000003,
    "YEAR_1": 94797.79418039761,
    "YEAR_2": 292716.81880099204,
    "YEAR_3": 482796.5443939009,
    "YEAR_4": 672876.2699868097,
    "YEAR_5": 862955.9955797186,
    "YEAR_6": 1053035.7211726275,
    "YEAR_7": 1243115.4467655362,
    "YEAR_8": 1433195.172358445,
    "YEAR_9": 1623274.8979513538,
    "YEAR_10": 1813354.6235442627,
    "METERED_Izak": 9572119.284734044
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Izak",
    "Total Smart Devices": 4262896.578,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 8525.793156,
    "overall Metered": 1278.8689734,
    "YEAR_1": 93754.39598754335,
    "YEAR_2": 289495.01177061116,
    "YEAR_3": 477482.61228933884,
    "YEAR_4": 665470.2128080665,
    "YEAR_5": 853457.8133267942,
    "YEAR_6": 1041445.4138455219,
    "YEAR_7": 1229433.0143642495,
    "YEAR_8": 1417420.6148829772,
    "YEAR_9": 1605408.215401705,
    "YEAR_10": 1793395.8159204326,
    "METERED_Izak": 9466763.120597241
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Izak",
    "Total Smart Devices": 4133878.496,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 8267.756992,
    "overall Metered": 1240.1635488,
    "YEAR_1": 90916.88582794751,
    "YEAR_2": 280733.3422147584,
    "YEAR_3": 463031.43110332417,
    "YEAR_4": 645329.51999189,
    "YEAR_5": 827627.6088804556,
    "YEAR_6": 1009925.6977690214,
    "YEAR_7": 1192223.7866575872,
    "YEAR_8": 1374521.8755461525,
    "YEAR_9": 1556819.9644347185,
    "YEAR_10": 1739118.053323284,
    "METERED_Izak": 9180248.16574914
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Izak",
    "Total Smart Devices": 3728438.627,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 7456.877254000001,
    "overall Metered": 1118.5315881000001,
    "YEAR_1": 81999.99813624624,
    "YEAR_2": 253199.7585350208,
    "YEAR_3": 417618.53303409787,
    "YEAR_4": 582037.307533175,
    "YEAR_5": 746456.0820322521,
    "YEAR_6": 910874.8565313291,
    "YEAR_7": 1075293.6310304063,
    "YEAR_8": 1239712.4055294835,
    "YEAR_9": 1404131.1800285606,
    "YEAR_10": 1568549.954527638,
    "METERED_Izak": 8279873.70691821
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Izak",
    "Total Smart Devices": 3620542.555,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 7241.08511,
    "overall Metered": 1086.1627664999999,
    "YEAR_1": 79627.0268772216,
    "YEAR_2": 245872.49312707197,
    "YEAR_3": 405533.2062212927,
    "YEAR_4": 565193.9193155135,
    "YEAR_5": 724854.6324097343,
    "YEAR_6": 884515.3455039551,
    "YEAR_7": 1044176.0585981759,
    "YEAR_8": 1203836.7716923966,
    "YEAR_9": 1363497.4847866176,
    "YEAR_10": 1523158.1978808383,
    "METERED_Izak": 8040265.136412817
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Izak",
    "Total Smart Devices": 3181975.805,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 6363.951610000001,
    "overall Metered": 954.5927415000001,
    "YEAR_1": 69981.57571646161,
    "YEAR_2": 216089.249707872,
    "YEAR_3": 356409.8006632128,
    "YEAR_4": 496730.3516185535,
    "YEAR_5": 637050.9025738944,
    "YEAR_6": 777371.4535292352,
    "YEAR_7": 917692.004484576,
    "YEAR_8": 1058012.5554399167,
    "YEAR_9": 1198333.1063952574,
    "YEAR_10": 1338653.6573505984,
    "METERED_Izak": 7066324.657479579
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Izak",
    "Total Smart Devices": 3098524.034,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 6197.048068,
    "overall Metered": 929.5572102,
    "YEAR_1": 68146.21090264607,
    "YEAR_2": 210422.00655855358,
    "YEAR_3": 347062.45458334463,
    "YEAR_4": 483702.9026081356,
    "YEAR_5": 620343.3506329267,
    "YEAR_6": 756983.7986577179,
    "YEAR_7": 893624.2466825086,
    "YEAR_8": 1030264.6947072996,
    "YEAR_9": 1166905.1427320908,
    "YEAR_10": 1303545.5907568817,
    "METERED_Izak": 6881000.398822105
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Izak",
    "Total Smart Devices": 3101259.121,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 6202.518242,
    "overall Metered": 930.3777362999999,
    "YEAR_1": 68206.3639992475,
    "YEAR_2": 210607.74741075834,
    "YEAR_3": 347368.80883372406,
    "YEAR_4": 484129.8702566897,
    "YEAR_5": 620890.9316796557,
    "YEAR_6": 757651.9931026213,
    "YEAR_7": 894413.0545255872,
    "YEAR_8": 1031174.1159485526,
    "YEAR_9": 1167935.1773715187,
    "YEAR_10": 1304696.2387944842,
    "METERED_Izak": 6887074.301922839
  },
  {
    "DMA": "St. Louis",
    "PlatformName": "Izak",
    "Total Smart Devices": 3008446.843,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 6016.893686,
    "overall Metered": 902.5340529,
    "YEAR_1": 66165.13243172015,
    "YEAR_2": 204304.82848686716,
    "YEAR_3": 336973.0020997132,
    "YEAR_4": 469641.1757125593,
    "YEAR_5": 602309.3493254054,
    "YEAR_6": 734977.5229382515,
    "YEAR_7": 867645.6965510974,
    "YEAR_8": 1000313.8701639435,
    "YEAR_9": 1132982.0437767894,
    "YEAR_10": 1265650.2173896355,
    "METERED_Izak": 6680962.838875983
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Izak",
    "Total Smart Devices": 2910409.816,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5820.819632000001,
    "overall Metered": 873.1229448,
    "YEAR_1": 64008.99233246592,
    "YEAR_2": 197647.09476848642,
    "YEAR_3": 325991.97666395135,
    "YEAR_4": 454336.8585594164,
    "YEAR_5": 582681.7404548813,
    "YEAR_6": 711026.6223503462,
    "YEAR_7": 839371.5042458111,
    "YEAR_8": 967716.386141276,
    "YEAR_9": 1096061.268036741,
    "YEAR_10": 1224406.1499322061,
    "METERED_Izak": 6463248.593485583
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Izak",
    "Total Smart Devices": 2844384.806,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5688.769612,
    "overall Metered": 853.3154418,
    "YEAR_1": 62556.89636453471,
    "YEAR_2": 193163.30992938238,
    "YEAR_3": 318596.5839598618,
    "YEAR_4": 444029.857990341,
    "YEAR_5": 569463.1320208204,
    "YEAR_6": 694896.4060512998,
    "YEAR_7": 820329.6800817791,
    "YEAR_8": 945762.9541122586,
    "YEAR_9": 1071196.2281427379,
    "YEAR_10": 1196629.5021732175,
    "METERED_Izak": 6316624.550826233
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Izak",
    "Total Smart Devices": 2813141.762,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5626.283524,
    "overall Metered": 843.9425286000001,
    "YEAR_1": 61869.76434867744,
    "YEAR_2": 191041.58231412485,
    "YEAR_3": 315097.0830941876,
    "YEAR_4": 439152.58387425024,
    "YEAR_5": 563208.0846543129,
    "YEAR_6": 687263.5854343757,
    "YEAR_7": 811319.0862144385,
    "YEAR_8": 935374.5869945013,
    "YEAR_9": 1059430.087774564,
    "YEAR_10": 1183485.5885546266,
    "METERED_Izak": 6247242.033258059
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Izak",
    "Total Smart Devices": 2761746.07,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5523.49214,
    "overall Metered": 828.523821,
    "YEAR_1": 60739.412727038405,
    "YEAR_2": 187551.28031212802,
    "YEAR_3": 309340.30508478713,
    "YEAR_4": 431129.3298574464,
    "YEAR_5": 552918.3546301055,
    "YEAR_6": 674707.3794027648,
    "YEAR_7": 796496.4041754239,
    "YEAR_8": 918285.428948083,
    "YEAR_9": 1040074.4537207423,
    "YEAR_10": 1161863.4784934013,
    "METERED_Izak": 6133105.82735192
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Izak",
    "Total Smart Devices": 2802506.923,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5605.013846000001,
    "overall Metered": 840.7520769000001,
    "YEAR_1": 61635.871058369776,
    "YEAR_2": 190319.36614369924,
    "YEAR_3": 313905.8858380302,
    "YEAR_4": 437492.405532361,
    "YEAR_5": 561078.9252266918,
    "YEAR_6": 684665.4449210227,
    "YEAR_7": 808251.9646153537,
    "YEAR_8": 931838.4843096845,
    "YEAR_9": 1055425.0040040154,
    "YEAR_10": 1179011.5236983465,
    "METERED_Izak": 6223624.875347575
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Izak",
    "Total Smart Devices": 2679594.179,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5359.188358,
    "overall Metered": 803.8782537000001,
    "YEAR_1": 58932.63633004848,
    "YEAR_2": 181972.3125335616,
    "YEAR_3": 300138.55721184384,
    "YEAR_4": 418304.8018901261,
    "YEAR_5": 536471.0465684084,
    "YEAR_6": 654637.2912466907,
    "YEAR_7": 772803.5359249728,
    "YEAR_8": 890969.7806032549,
    "YEAR_9": 1009136.0252815372,
    "YEAR_10": 1127302.2699598193,
    "METERED_Izak": 5950668.257550264
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Izak",
    "Total Smart Devices": 2564533.659,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 5129.067318,
    "overall Metered": 769.3600977,
    "YEAR_1": 56402.096506426074,
    "YEAR_2": 174158.50659615357,
    "YEAR_3": 287250.7480295846,
    "YEAR_4": 400342.98946301575,
    "YEAR_5": 513435.23089644674,
    "YEAR_6": 626527.4723298778,
    "YEAR_7": 739619.713763309,
    "YEAR_8": 852711.95519674,
    "YEAR_9": 965804.1966301706,
    "YEAR_10": 1078896.438063602,
    "METERED_Izak": 5695149.347475326
  },
  {
    "DMA": "Columbus",
    "PlatformName": "Izak",
    "Total Smart Devices": 2468934.437,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4937.868874,
    "overall Metered": 740.6803311,
    "YEAR_1": 54299.57134507343,
    "YEAR_2": 167666.3251904448,
    "YEAR_3": 276542.7785965555,
    "YEAR_4": 385419.2320026662,
    "YEAR_5": 494295.68540877686,
    "YEAR_6": 603172.1388148875,
    "YEAR_7": 712048.5922209983,
    "YEAR_8": 820925.0456271089,
    "YEAR_9": 929801.4990332198,
    "YEAR_10": 1038677.9524393304,
    "METERED_Izak": 5482848.820679062
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Izak",
    "Total Smart Devices": 2488288.448,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4976.5768960000005,
    "overall Metered": 746.4865344000001,
    "YEAR_1": 54725.22643147776,
    "YEAR_2": 168980.6638190592,
    "YEAR_3": 278710.60124049406,
    "YEAR_4": 388440.5386619289,
    "YEAR_5": 498170.47608336376,
    "YEAR_6": 607900.4135047987,
    "YEAR_7": 717630.3509262336,
    "YEAR_8": 827360.2883476685,
    "YEAR_9": 937090.2257691033,
    "YEAR_10": 1046820.1631905383,
    "METERED_Izak": 5525828.947974666
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Izak",
    "Total Smart Devices": 2468450.947,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4936.9018940000005,
    "overall Metered": 740.5352841,
    "YEAR_1": 54288.937891484646,
    "YEAR_2": 167633.49119114882,
    "YEAR_3": 276488.62338448514,
    "YEAR_4": 385343.75557782146,
    "YEAR_5": 494198.8877711578,
    "YEAR_6": 603054.019964494,
    "YEAR_7": 711909.1521578303,
    "YEAR_8": 820764.2843511667,
    "YEAR_9": 929619.4165445031,
    "YEAR_10": 1038474.5487378393,
    "METERED_Izak": 5481775.117571931
  },
  {
    "DMA": "Austin",
    "PlatformName": "Izak",
    "Total Smart Devices": 2371566.242,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4743.132484000001,
    "overall Metered": 711.4698726,
    "YEAR_1": 52158.140948255044,
    "YEAR_2": 161054.0121207168,
    "YEAR_3": 265636.6683375283,
    "YEAR_4": 370219.32455433987,
    "YEAR_5": 474801.9807711514,
    "YEAR_6": 579384.6369879628,
    "YEAR_7": 683967.2932047745,
    "YEAR_8": 788549.949421586,
    "YEAR_9": 893132.6056383975,
    "YEAR_10": 997715.261855209,
    "METERED_Izak": 5266619.873839921
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Izak",
    "Total Smart Devices": 2303023.424,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4606.046848000001,
    "overall Metered": 690.9070272000001,
    "YEAR_1": 50650.67052684288,
    "YEAR_2": 156399.24193320965,
    "YEAR_3": 257959.25857787908,
    "YEAR_4": 359519.2752225485,
    "YEAR_5": 461079.291867218,
    "YEAR_6": 562639.3085118874,
    "YEAR_7": 664199.3251565568,
    "YEAR_8": 765759.3418012263,
    "YEAR_9": 867319.3584458957,
    "YEAR_10": 968879.3750905651,
    "METERED_Izak": 5114404.44713383
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Izak",
    "Total Smart Devices": 2307327.852,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4614.655704,
    "overall Metered": 692.1983555999999,
    "YEAR_1": 50745.338328378224,
    "YEAR_2": 156691.55736046078,
    "YEAR_3": 258441.39308155386,
    "YEAR_4": 360191.2288026469,
    "YEAR_5": 461941.0645237401,
    "YEAR_6": 563690.9002448331,
    "YEAR_7": 665440.7359659261,
    "YEAR_8": 767190.5716870193,
    "YEAR_9": 868940.4074081124,
    "YEAR_10": 970690.2431292055,
    "METERED_Izak": 5123963.440531877
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Izak",
    "Total Smart Devices": 2174029.485,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4348.05897,
    "overall Metered": 652.2088454999999,
    "YEAR_1": 47813.69134714318,
    "YEAR_2": 147639.211938144,
    "YEAR_3": 243510.78162418562,
    "YEAR_4": 339382.3513102271,
    "YEAR_5": 435253.9209962687,
    "YEAR_6": 531125.4906823103,
    "YEAR_7": 626997.0603683519,
    "YEAR_8": 722868.6300543936,
    "YEAR_9": 818740.1997404352,
    "YEAR_10": 914611.7694264768,
    "METERED_Izak": 4827943.1074879365
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Izak",
    "Total Smart Devices": 2150472.593,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4300.945186,
    "overall Metered": 645.1417779,
    "YEAR_1": 47295.60179456016,
    "YEAR_2": 146039.4539796672,
    "YEAR_3": 240872.19865043327,
    "YEAR_4": 335704.94332119933,
    "YEAR_5": 430537.68799196533,
    "YEAR_6": 525370.4326627315,
    "YEAR_7": 620203.1773334974,
    "YEAR_8": 715035.9220042636,
    "YEAR_9": 809868.6666750299,
    "YEAR_10": 904701.411345796,
    "METERED_Izak": 4775629.4957591435
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Izak",
    "Total Smart Devices": 2107672.982,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 4215.345964,
    "overall Metered": 632.3018946,
    "YEAR_1": 46354.304813883835,
    "YEAR_2": 143132.9152768128,
    "YEAR_3": 236078.25873391872,
    "YEAR_4": 329023.6021910246,
    "YEAR_5": 421968.9456481306,
    "YEAR_6": 514914.2891052364,
    "YEAR_7": 607859.6325623424,
    "YEAR_8": 700804.9760194483,
    "YEAR_9": 793750.3194765542,
    "YEAR_10": 886695.6629336602,
    "METERED_Izak": 4680582.906761012
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Izak",
    "Total Smart Devices": 1917944.712,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3835.8894240000004,
    "overall Metered": 575.3834136,
    "YEAR_1": 42181.58820438144,
    "YEAR_2": 130248.39256980483,
    "YEAR_3": 214826.99252861954,
    "YEAR_4": 299405.5924874342,
    "YEAR_5": 383984.1924462489,
    "YEAR_6": 468562.79240506364,
    "YEAR_7": 553141.3923638783,
    "YEAR_8": 637719.992322693,
    "YEAR_9": 722298.5922815079,
    "YEAR_10": 806877.1922403225,
    "METERED_Izak": 4259246.719849954
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Izak",
    "Total Smart Devices": 1886199.232,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3772.3984640000003,
    "overall Metered": 565.8597696,
    "YEAR_1": 41483.40605328385,
    "YEAR_2": 128092.5443248128,
    "YEAR_3": 211271.21432911872,
    "YEAR_4": 294449.8843334246,
    "YEAR_5": 377628.5543377306,
    "YEAR_6": 460807.2243420365,
    "YEAR_7": 543985.8943463424,
    "YEAR_8": 627164.5643506483,
    "YEAR_9": 710343.2343549542,
    "YEAR_10": 793521.9043592603,
    "METERED_Izak": 4188748.4251316125
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Izak",
    "Total Smart Devices": 1885454.401,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3770.9088020000004,
    "overall Metered": 565.6363203000001,
    "YEAR_1": 41467.02489572112,
    "YEAR_2": 128041.96255367041,
    "YEAR_3": 211187.78658343296,
    "YEAR_4": 294333.6106131955,
    "YEAR_5": 377479.4346429581,
    "YEAR_6": 460625.2586727207,
    "YEAR_7": 543771.0827024833,
    "YEAR_8": 626916.9067322458,
    "YEAR_9": 710062.7307620085,
    "YEAR_10": 793208.554791771,
    "METERED_Izak": 4187094.3529502074
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Izak",
    "Total Smart Devices": 1862144.776,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3724.2895520000006,
    "overall Metered": 558.6434328,
    "YEAR_1": 40954.37351594112,
    "YEAR_2": 126458.99659607038,
    "YEAR_3": 208576.89972919296,
    "YEAR_4": 290694.80286231544,
    "YEAR_5": 372812.70599543804,
    "YEAR_6": 454930.60912856064,
    "YEAR_7": 537048.5122616832,
    "YEAR_8": 619166.4153948057,
    "YEAR_9": 701284.3185279283,
    "YEAR_10": 783402.2216610509,
    "METERED_Izak": 4135329.8556729862
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Izak",
    "Total Smart Devices": 1846925.874,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3693.8517480000005,
    "overall Metered": 554.0777622,
    "YEAR_1": 40619.66237798688,
    "YEAR_2": 125425.47487368961,
    "YEAR_3": 206872.246343831,
    "YEAR_4": 288319.01781397243,
    "YEAR_5": 369765.7892841139,
    "YEAR_6": 451212.56075425545,
    "YEAR_7": 532659.3322243969,
    "YEAR_8": 614106.1036945382,
    "YEAR_9": 695552.8751646797,
    "YEAR_10": 776999.6466348211,
    "METERED_Izak": 4101532.7091662847
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Izak",
    "Total Smart Devices": 1813431.99,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3626.86398,
    "overall Metered": 544.029597,
    "YEAR_1": 39883.027367908784,
    "YEAR_2": 123150.89181369597,
    "YEAR_3": 203120.6312306304,
    "YEAR_4": 283090.37064756476,
    "YEAR_5": 363060.1100644992,
    "YEAR_6": 443029.84948143346,
    "YEAR_7": 522999.58889836795,
    "YEAR_8": 602969.3283153023,
    "YEAR_9": 682939.0677322367,
    "YEAR_10": 762908.807149171,
    "METERED_Izak": 4027151.6727008107
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Izak",
    "Total Smart Devices": 1792405.574,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3584.8111480000002,
    "overall Metered": 537.7216722000001,
    "YEAR_1": 39420.59087765088,
    "YEAR_2": 121722.9794925696,
    "YEAR_3": 200765.48424194305,
    "YEAR_4": 279807.98899131647,
    "YEAR_5": 358850.49374068994,
    "YEAR_6": 437892.99849006336,
    "YEAR_7": 516935.5032394368,
    "YEAR_8": 595978.0079888102,
    "YEAR_9": 675020.5127381836,
    "YEAR_10": 754063.0174875571,
    "METERED_Izak": 3980457.577288221
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Izak",
    "Total Smart Devices": 1750390.958,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3500.781916000001,
    "overall Metered": 525.1172874000001,
    "YEAR_1": 38496.55838620897,
    "YEAR_2": 118869.75011416321,
    "YEAR_3": 196059.4707989837,
    "YEAR_4": 273249.1914838042,
    "YEAR_5": 350438.91216862475,
    "YEAR_6": 427628.63285344513,
    "YEAR_7": 504818.3535382657,
    "YEAR_8": 582008.074223086,
    "YEAR_9": 659197.7949079067,
    "YEAR_10": 736387.515592727,
    "METERED_Izak": 3887154.254067215
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Izak",
    "Total Smart Devices": 1750628.806,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3501.257612000001,
    "overall Metered": 525.1886418000001,
    "YEAR_1": 38501.78940581473,
    "YEAR_2": 118885.90246698243,
    "YEAR_3": 196086.1119061018,
    "YEAR_4": 273286.3213452212,
    "YEAR_5": 350486.5307843405,
    "YEAR_6": 427686.7402234599,
    "YEAR_7": 504886.9496625792,
    "YEAR_8": 582087.1591016987,
    "YEAR_9": 659287.3685408181,
    "YEAR_10": 736487.5779799374,
    "METERED_Izak": 3887682.4514169535
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Izak",
    "Total Smart Devices": 1672507.553,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3345.0151060000007,
    "overall Metered": 501.7522659000001,
    "YEAR_1": 36783.659314035365,
    "YEAR_2": 113580.65692725123,
    "YEAR_3": 187335.83160367492,
    "YEAR_4": 261091.0062800986,
    "YEAR_5": 334846.18095652235,
    "YEAR_6": 408601.3556329461,
    "YEAR_7": 482356.5303093698,
    "YEAR_8": 556111.7049857934,
    "YEAR_9": 629866.8796622172,
    "YEAR_10": 703622.054338641,
    "METERED_Izak": 3714195.86001055
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Izak",
    "Total Smart Devices": 1641227.699,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3282.4553980000005,
    "overall Metered": 492.36830970000005,
    "YEAR_1": 36095.71773143089,
    "YEAR_2": 111456.4295301696,
    "YEAR_3": 183832.20768818306,
    "YEAR_4": 256207.98584619648,
    "YEAR_5": 328583.76400420995,
    "YEAR_6": 400959.5421622234,
    "YEAR_7": 473335.3203202369,
    "YEAR_8": 545711.0984782503,
    "YEAR_9": 618086.8766362638,
    "YEAR_10": 690462.6547942772,
    "METERED_Izak": 3644731.597191442
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Izak",
    "Total Smart Devices": 1564688.779,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3129.377558,
    "overall Metered": 469.4066337,
    "YEAR_1": 34412.38807920048,
    "YEAR_2": 106258.6408574016,
    "YEAR_3": 175259.16285945984,
    "YEAR_4": 244259.68486151806,
    "YEAR_5": 313260.20686357626,
    "YEAR_6": 382260.7288656346,
    "YEAR_7": 451261.25086769275,
    "YEAR_8": 520261.77286975086,
    "YEAR_9": 589262.2948718091,
    "YEAR_10": 658262.8168738676,
    "METERED_Izak": 3474758.9478699113
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Izak",
    "Total Smart Devices": 1553299.908,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3106.5998160000004,
    "overall Metered": 465.98997240000006,
    "YEAR_1": 34161.91127263296,
    "YEAR_2": 105485.21807224321,
    "YEAR_3": 173983.5072631757,
    "YEAR_4": 242481.79645410817,
    "YEAR_5": 310980.0856450406,
    "YEAR_6": 379478.374835973,
    "YEAR_7": 447976.66402690567,
    "YEAR_8": 516474.9532178381,
    "YEAR_9": 584973.2424087705,
    "YEAR_10": 653471.531599703,
    "METERED_Izak": 3449467.2847963907
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Izak",
    "Total Smart Devices": 1532512.639,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3065.025278,
    "overall Metered": 459.7537917,
    "YEAR_1": 33704.73437104368,
    "YEAR_2": 104073.5463195456,
    "YEAR_3": 171655.14688124543,
    "YEAR_4": 239236.74744294529,
    "YEAR_5": 306818.3480046451,
    "YEAR_6": 374399.9485663449,
    "YEAR_7": 441981.54912804475,
    "YEAR_8": 509563.1496897447,
    "YEAR_9": 577144.7502514445,
    "YEAR_10": 644726.3508131444,
    "METERED_Izak": 3403304.2714681486
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Izak",
    "Total Smart Devices": 1507192.296,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 3014.3845920000003,
    "overall Metered": 452.1576888,
    "YEAR_1": 33147.86102900352,
    "YEAR_2": 102354.0316982784,
    "YEAR_3": 168819.04159497214,
    "YEAR_4": 235284.05149166597,
    "YEAR_5": 301749.06138835975,
    "YEAR_6": 368214.07128505345,
    "YEAR_7": 434679.08118174714,
    "YEAR_8": 501144.0910784409,
    "YEAR_9": 567609.1009751346,
    "YEAR_10": 634074.1108718284,
    "METERED_Izak": 3347074.502594484
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Izak",
    "Total Smart Devices": 1471541.76,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2943.08352,
    "overall Metered": 441.462528,
    "YEAR_1": 32363.794512691198,
    "YEAR_2": 99932.98953830398,
    "YEAR_3": 164825.8621341696,
    "YEAR_4": 229718.73473003518,
    "YEAR_5": 294611.60732590075,
    "YEAR_6": 359504.4799217665,
    "YEAR_7": 424397.352517632,
    "YEAR_8": 489290.2251134977,
    "YEAR_9": 554183.0977093633,
    "YEAR_10": 619075.9703052286,
    "METERED_Izak": 3267904.113808589
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Izak",
    "Total Smart Devices": 1452147.042,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2904.294084,
    "overall Metered": 435.6441126,
    "YEAR_1": 31937.244152351042,
    "YEAR_2": 98615.8864810368,
    "YEAR_3": 162653.4799414963,
    "YEAR_4": 226691.07340195583,
    "YEAR_5": 290728.6668624153,
    "YEAR_6": 354766.2603228749,
    "YEAR_7": 418803.8537833343,
    "YEAR_8": 482841.44724379387,
    "YEAR_9": 546879.0407042535,
    "YEAR_10": 610916.6341647131,
    "METERED_Izak": 3224833.5870582247
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Izak",
    "Total Smart Devices": 1459002.839,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2918.005678,
    "overall Metered": 437.7008517,
    "YEAR_1": 32088.02451846768,
    "YEAR_2": 99081.4663976256,
    "YEAR_3": 163421.3906334374,
    "YEAR_4": 227761.3148692493,
    "YEAR_5": 292101.2391050611,
    "YEAR_6": 356441.16334087297,
    "YEAR_7": 420781.0875766848,
    "YEAR_8": 485121.0118124966,
    "YEAR_9": 549460.9360483085,
    "YEAR_10": 613800.8602841203,
    "METERED_Izak": 3240058.4945863243
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Izak",
    "Total Smart Devices": 1405447.572,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2810.895144,
    "overall Metered": 421.6342716,
    "YEAR_1": 30910.17710470464,
    "YEAR_2": 95444.50679354879,
    "YEAR_3": 157422.7208742451,
    "YEAR_4": 219400.93495494142,
    "YEAR_5": 281379.14903563773,
    "YEAR_6": 343357.3631163341,
    "YEAR_7": 405335.5771970304,
    "YEAR_8": 467313.79127772665,
    "YEAR_9": 529292.005358423,
    "YEAR_10": 591270.2194391192,
    "METERED_Izak": 3121126.445151711
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Izak",
    "Total Smart Devices": 1404565.359,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2809.1307180000003,
    "overall Metered": 421.3696077,
    "YEAR_1": 30890.77448833008,
    "YEAR_2": 95384.5953558336,
    "YEAR_3": 157323.90511361664,
    "YEAR_4": 219263.21487139966,
    "YEAR_5": 281202.5246291827,
    "YEAR_6": 343141.8343869657,
    "YEAR_7": 405081.14414474875,
    "YEAR_8": 467020.45390253176,
    "YEAR_9": 528959.7636603148,
    "YEAR_10": 590899.0734180979,
    "METERED_Izak": 3119167.283971022
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Izak",
    "Total Smart Devices": 1362932.794,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2725.8655880000006,
    "overall Metered": 408.87983820000005,
    "YEAR_1": 29975.144490377286,
    "YEAR_2": 92557.31121365761,
    "YEAR_3": 152660.68480583426,
    "YEAR_4": 212764.0583980109,
    "YEAR_5": 272867.43199018756,
    "YEAR_6": 332970.8055823642,
    "YEAR_7": 393074.17917454086,
    "YEAR_8": 453177.5527667176,
    "YEAR_9": 513280.92635889415,
    "YEAR_10": 573384.2999510708,
    "METERED_Izak": 3026712.3947316553
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Izak",
    "Total Smart Devices": 1337968.086,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2675.9361719999997,
    "overall Metered": 401.39042579999995,
    "YEAR_1": 29426.09267156831,
    "YEAR_2": 90861.94790749438,
    "YEAR_3": 149864.41382605053,
    "YEAR_4": 208866.87974460667,
    "YEAR_5": 267869.34566316276,
    "YEAR_6": 326871.81158171897,
    "YEAR_7": 385874.2775002752,
    "YEAR_8": 444876.74341883126,
    "YEAR_9": 503879.20933738735,
    "YEAR_10": 562881.6752559436,
    "METERED_Izak": 2971272.396907039
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Izak",
    "Total Smart Devices": 1249823.665,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2499.6473300000002,
    "overall Metered": 374.94709950000004,
    "YEAR_1": 27487.521843184804,
    "YEAR_2": 84876.025019616,
    "YEAR_3": 139991.44890003838,
    "YEAR_4": 195106.8727804608,
    "YEAR_5": 250222.2966608832,
    "YEAR_6": 305337.7205413056,
    "YEAR_7": 360453.144421728,
    "YEAR_8": 415568.56830215047,
    "YEAR_9": 470683.99218257284,
    "YEAR_10": 525799.4160629952,
    "METERED_Izak": 2775527.0067149354
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Izak",
    "Total Smart Devices": 1202380.339,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2404.760678,
    "overall Metered": 360.7141017,
    "YEAR_1": 26444.095081267682,
    "YEAR_2": 81654.12977362561,
    "YEAR_3": 134677.37129583742,
    "YEAR_4": 187700.61281804927,
    "YEAR_5": 240723.8543402611,
    "YEAR_6": 293747.09586247295,
    "YEAR_7": 346770.33738468477,
    "YEAR_8": 399793.57890689664,
    "YEAR_9": 452816.8204291085,
    "YEAR_10": 505840.0619513203,
    "METERED_Izak": 2670167.957843524
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Izak",
    "Total Smart Devices": 1219379.365,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2438.75873,
    "overall Metered": 365.8138095,
    "YEAR_1": 26817.956699968796,
    "YEAR_2": 82808.54042889601,
    "YEAR_3": 136581.41451911037,
    "YEAR_4": 190354.28860932478,
    "YEAR_5": 244127.16269953916,
    "YEAR_6": 297900.0367897536,
    "YEAR_7": 351672.9108799679,
    "YEAR_8": 405445.78497018234,
    "YEAR_9": 459218.65906039684,
    "YEAR_10": 512991.53315061115,
    "METERED_Izak": 2707918.2878077505
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Izak",
    "Total Smart Devices": 1183469.762,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2366.9395240000003,
    "overall Metered": 355.04092860000003,
    "YEAR_1": 26028.192492037437,
    "YEAR_2": 80369.90492532481,
    "YEAR_3": 132559.21723306752,
    "YEAR_4": 184748.52954081024,
    "YEAR_5": 236937.84184855298,
    "YEAR_6": 289127.1541562957,
    "YEAR_7": 341316.4664640384,
    "YEAR_8": 393505.7787717811,
    "YEAR_9": 445695.0910795239,
    "YEAR_10": 497884.40338726656,
    "METERED_Izak": 2628172.5798986987
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Izak",
    "Total Smart Devices": 1187777.437,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2375.554874,
    "overall Metered": 356.3332311,
    "YEAR_1": 26122.931705233437,
    "YEAR_2": 80662.44085764479,
    "YEAR_3": 133041.7154298355,
    "YEAR_4": 185420.9900020262,
    "YEAR_5": 237800.26457421694,
    "YEAR_6": 290179.5391464077,
    "YEAR_7": 342558.81371859834,
    "YEAR_8": 394938.088290789,
    "YEAR_9": 447317.3628629797,
    "YEAR_10": 499696.63743517036,
    "METERED_Izak": 2637738.7840229017
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Izak",
    "Total Smart Devices": 1143912.87,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2287.82574,
    "overall Metered": 343.17386100000004,
    "YEAR_1": 25158.2130194544,
    "YEAR_2": 77683.580566848,
    "YEAR_3": 128128.4908993152,
    "YEAR_4": 178573.40123178242,
    "YEAR_5": 229018.3115642496,
    "YEAR_6": 279463.2218967168,
    "YEAR_7": 329908.13222918403,
    "YEAR_8": 380353.0425616512,
    "YEAR_9": 430797.95289411844,
    "YEAR_10": 481242.86322658573,
    "METERED_Izak": 2540327.2100899057
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Izak",
    "Total Smart Devices": 1152245.338,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2304.4906760000003,
    "overall Metered": 345.67360140000005,
    "YEAR_1": 25341.46998807456,
    "YEAR_2": 78249.4418017152,
    "YEAR_3": 129061.80197422848,
    "YEAR_4": 179874.16214674182,
    "YEAR_5": 230686.5223192551,
    "YEAR_6": 281498.8824917684,
    "YEAR_7": 332311.2426642816,
    "YEAR_8": 383123.602836795,
    "YEAR_9": 433935.96300930815,
    "YEAR_10": 484748.32318182144,
    "METERED_Izak": 2558831.4124139897
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Izak",
    "Total Smart Devices": 1130925.32,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2261.85064,
    "overall Metered": 339.277596,
    "YEAR_1": 24872.576273798397,
    "YEAR_2": 76801.59085132799,
    "YEAR_3": 126673.7689308672,
    "YEAR_4": 176545.94701040638,
    "YEAR_5": 226418.12508994556,
    "YEAR_6": 276290.3031694848,
    "YEAR_7": 326162.481249024,
    "YEAR_8": 376034.6593285632,
    "YEAR_9": 425906.83740810235,
    "YEAR_10": 475779.01548764156,
    "METERED_Izak": 2511485.304799161
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Izak",
    "Total Smart Devices": 1109995.797,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2219.9915940000005,
    "overall Metered": 332.9987391000001,
    "YEAR_1": 24412.270762916643,
    "YEAR_2": 75380.2585725888,
    "YEAR_3": 124329.47482634115,
    "YEAR_4": 173278.69108009347,
    "YEAR_5": 222227.9073338458,
    "YEAR_6": 271177.1235875981,
    "YEAR_7": 320126.3398413505,
    "YEAR_8": 369075.5560951028,
    "YEAR_9": 418024.7723488551,
    "YEAR_10": 466973.9886026075,
    "METERED_Izak": 2465006.3830513
  },
  {
    "DMA": "Madison",
    "PlatformName": "Izak",
    "Total Smart Devices": 1112970.681,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2225.9413620000005,
    "overall Metered": 333.8912043000001,
    "YEAR_1": 24477.697743714725,
    "YEAR_2": 75582.28413498243,
    "YEAR_3": 124662.68848930179,
    "YEAR_4": 173743.09284362118,
    "YEAR_5": 222823.49719794054,
    "YEAR_6": 271903.9015522599,
    "YEAR_7": 320984.30590657936,
    "YEAR_8": 370064.71026089863,
    "YEAR_9": 419145.114615218,
    "YEAR_10": 468225.5189695374,
    "METERED_Izak": 2471612.811714054
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Izak",
    "Total Smart Devices": 1102039.859,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2204.079718,
    "overall Metered": 330.6119577,
    "YEAR_1": 24237.29486377008,
    "YEAR_2": 74839.96764063358,
    "YEAR_3": 123438.33848513666,
    "YEAR_4": 172036.70932963965,
    "YEAR_5": 220635.0801741427,
    "YEAR_6": 269233.4510186458,
    "YEAR_7": 317831.82186314877,
    "YEAR_8": 366430.1927076518,
    "YEAR_9": 415028.5635521548,
    "YEAR_10": 463626.9343966579,
    "METERED_Izak": 2447338.354031582
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Izak",
    "Total Smart Devices": 1078841.85,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2157.6837000000005,
    "overall Metered": 323.65255500000006,
    "YEAR_1": 23727.098268072004,
    "YEAR_2": 73264.58157024001,
    "YEAR_3": 120839.95362297603,
    "YEAR_4": 168415.32567571205,
    "YEAR_5": 215990.69772844802,
    "YEAR_6": 263566.0697811841,
    "YEAR_7": 311141.4418339201,
    "YEAR_8": 358716.8138866561,
    "YEAR_9": 406292.1859393921,
    "YEAR_10": 453867.5579921281,
    "METERED_Izak": 2395821.7262987285
  },
  {
    "DMA": "Springfield",
    "PlatformName": "Izak",
    "Total Smart Devices": 1058242.196,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2116.484392,
    "overall Metered": 317.4726588,
    "YEAR_1": 23274.047605691514,
    "YEAR_2": 71865.65082723841,
    "YEAR_3": 118532.60780207615,
    "YEAR_4": 165199.5647769139,
    "YEAR_5": 211866.5217517517,
    "YEAR_6": 258533.4787265894,
    "YEAR_7": 305200.43570142717,
    "YEAR_8": 351867.3926762649,
    "YEAR_9": 398534.3496511027,
    "YEAR_10": 445201.30662594043,
    "METERED_Izak": 2350075.3561449964
  },
  {
    "DMA": "Columbia",
    "PlatformName": "Izak",
    "Total Smart Devices": 1058408.268,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2116.816536,
    "overall Metered": 317.52248039999995,
    "YEAR_1": 23277.700047116152,
    "YEAR_2": 71876.92884318718,
    "YEAR_3": 118551.20935408125,
    "YEAR_4": 165225.4898649753,
    "YEAR_5": 211899.77037586935,
    "YEAR_6": 258574.05088676343,
    "YEAR_7": 305248.3313976575,
    "YEAR_8": 351922.6119085516,
    "YEAR_9": 398596.8924194457,
    "YEAR_10": 445271.17293033976,
    "METERED_Izak": 2350444.1580279875
  },
  {
    "DMA": "Rochester",
    "PlatformName": "Izak",
    "Total Smart Devices": 1061121.486,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2122.242972,
    "overall Metered": 318.3364458,
    "YEAR_1": 23337.37217617632,
    "YEAR_2": 72061.18456285438,
    "YEAR_3": 118855.11408051454,
    "YEAR_4": 165649.0435981747,
    "YEAR_5": 212442.97311583487,
    "YEAR_6": 259236.902633495,
    "YEAR_7": 306030.8321511551,
    "YEAR_8": 352824.7616688153,
    "YEAR_9": 399618.6911864755,
    "YEAR_10": 446412.6207041356,
    "METERED_Izak": 2356469.495877631
  },
  {
    "DMA": "Portland",
    "PlatformName": "Izak",
    "Total Smart Devices": 1036069.954,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2072.139908,
    "overall Metered": 310.8209862,
    "YEAR_1": 22786.410826716477,
    "YEAR_2": 70359.9250041216,
    "YEAR_3": 116049.11803478784,
    "YEAR_4": 161738.31106545407,
    "YEAR_5": 207427.5040961203,
    "YEAR_6": 253116.69712678654,
    "YEAR_7": 298805.8901574528,
    "YEAR_8": 344495.08318811905,
    "YEAR_9": 390184.27621878526,
    "YEAR_10": 435873.4692494514,
    "METERED_Izak": 2300836.6849677954
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Izak",
    "Total Smart Devices": 1038545,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2077.09,
    "overall Metered": 311.56350000000003,
    "YEAR_1": 22840.8448104,
    "YEAR_2": 70528.006368,
    "YEAR_3": 116326.3453632,
    "YEAR_4": 162124.68435840003,
    "YEAR_5": 207923.0233536,
    "YEAR_6": 253721.3623488,
    "YEAR_7": 299519.701344,
    "YEAR_8": 345318.0403392,
    "YEAR_9": 391116.3793344,
    "YEAR_10": 436914.7183296,
    "METERED_Izak": 2306333.1059495998
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Izak",
    "Total Smart Devices": 1041756.757,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2083.5135139999998,
    "overall Metered": 312.52702709999994,
    "YEAR_1": 22911.481367511835,
    "YEAR_2": 70746.11807057279,
    "YEAR_3": 116686.09092454272,
    "YEAR_4": 162626.06377851262,
    "YEAR_5": 208566.03663248254,
    "YEAR_6": 254506.00948645247,
    "YEAR_7": 300445.98234042234,
    "YEAR_8": 346385.95519439224,
    "YEAR_9": 392325.9280483621,
    "YEAR_10": 438265.90090233204,
    "METERED_Izak": 2313465.566745584
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Izak",
    "Total Smart Devices": 1004152.231,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2008.304462,
    "overall Metered": 301.2456693,
    "YEAR_1": 22084.440514650712,
    "YEAR_2": 68192.3796681024,
    "YEAR_3": 112474.04707598976,
    "YEAR_4": 156755.7144838771,
    "YEAR_5": 201037.38189176444,
    "YEAR_6": 245319.0492996518,
    "YEAR_7": 289600.7167075391,
    "YEAR_8": 333882.38411542645,
    "YEAR_9": 378164.05152331386,
    "YEAR_10": 422445.71893120115,
    "METERED_Izak": 2229955.884211517
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Izak",
    "Total Smart Devices": 1011839.034,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2023.6780680000002,
    "overall Metered": 303.5517102,
    "YEAR_1": 22253.49729544608,
    "YEAR_2": 68714.39353455359,
    "YEAR_3": 113335.03788574468,
    "YEAR_4": 157955.68223693565,
    "YEAR_5": 202576.3265881267,
    "YEAR_6": 247196.97093931778,
    "YEAR_7": 291817.6152905087,
    "YEAR_8": 336438.2596416998,
    "YEAR_9": 381058.9039928909,
    "YEAR_10": 425679.5483440819,
    "METERED_Izak": 2247026.2357493057
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Izak",
    "Total Smart Devices": 1001954.872,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 2003.9097440000003,
    "overall Metered": 300.5864616,
    "YEAR_1": 22036.11373448064,
    "YEAR_2": 68043.1561394688,
    "YEAR_3": 112227.92317965312,
    "YEAR_4": 156412.69021983744,
    "YEAR_5": 200597.45726002177,
    "YEAR_6": 244782.2243002061,
    "YEAR_7": 288966.9913403904,
    "YEAR_8": 333151.75838057476,
    "YEAR_9": 377336.5254207591,
    "YEAR_10": 421521.29246094334,
    "METERED_Izak": 2225076.1324363356
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Izak",
    "Total Smart Devices": 986286.69,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1972.5733799999998,
    "overall Metered": 295.88600699999995,
    "YEAR_1": 21691.521527572797,
    "YEAR_2": 66979.12363257598,
    "YEAR_3": 110472.94640874238,
    "YEAR_4": 153966.76918490874,
    "YEAR_5": 197460.59196107514,
    "YEAR_6": 240954.41473724158,
    "YEAR_7": 284448.23751340795,
    "YEAR_8": 327942.0602895744,
    "YEAR_9": 371435.8830657407,
    "YEAR_10": 414929.7058419072,
    "METERED_Izak": 2190281.254162747
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Izak",
    "Total Smart Devices": 952657.6974,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1905.3153948,
    "overall Metered": 285.79730922,
    "YEAR_1": 20951.915057841885,
    "YEAR_2": 64695.36529351296,
    "YEAR_3": 106706.19792176873,
    "YEAR_4": 148717.03055002447,
    "YEAR_5": 190727.86317828018,
    "YEAR_6": 232738.69580653592,
    "YEAR_7": 274749.52843479166,
    "YEAR_8": 316760.36106304737,
    "YEAR_9": 358771.1936913031,
    "YEAR_10": 400782.0263195588,
    "METERED_Izak": 2115600.1773166647
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Izak",
    "Total Smart Devices": 959703.0583,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1919.4061166000001,
    "overall Metered": 287.91091749000003,
    "YEAR_1": 21106.864525558896,
    "YEAR_2": 65173.818570376316,
    "YEAR_3": 107495.34146900236,
    "YEAR_4": 149816.8643676284,
    "YEAR_5": 192138.38726625443,
    "YEAR_6": 234459.9101648805,
    "YEAR_7": 276781.43306350656,
    "YEAR_8": 319102.9559621326,
    "YEAR_9": 361424.47886075865,
    "YEAR_10": 403746.0017593847,
    "METERED_Izak": 2131246.0560094835
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Izak",
    "Total Smart Devices": 927387.5876,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1854.7751752000001,
    "overall Metered": 278.21627628,
    "YEAR_1": 20396.146500597308,
    "YEAR_2": 62979.262028951045,
    "YEAR_3": 103875.71920398489,
    "YEAR_4": 144772.17637901875,
    "YEAR_5": 185668.63355405262,
    "YEAR_6": 226565.09072908649,
    "YEAR_7": 267461.5479041203,
    "YEAR_8": 308358.00507915416,
    "YEAR_9": 349254.4622541881,
    "YEAR_10": 390150.9194292219,
    "METERED_Izak": 2059481.9630623756
  },
  {
    "DMA": "Charleston",
    "PlatformName": "Izak",
    "Total Smart Devices": 912456.1162,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1824.9122324,
    "overall Metered": 273.73683486,
    "YEAR_1": 20067.756858320543,
    "YEAR_2": 61965.25983358848,
    "YEAR_3": 102203.26062120116,
    "YEAR_4": 142441.2614088138,
    "YEAR_5": 182679.26219642645,
    "YEAR_6": 222917.26298403915,
    "YEAR_7": 263155.2637716518,
    "YEAR_8": 303393.26455926447,
    "YEAR_9": 343631.26534687716,
    "YEAR_10": 383869.2661344898,
    "METERED_Izak": 2026323.1237146729
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Izak",
    "Total Smart Devices": 925622.1874,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1851.2443748000003,
    "overall Metered": 277.68665622000003,
    "YEAR_1": 20357.31984215069,
    "YEAR_2": 62859.372995208956,
    "YEAR_3": 103677.97856359908,
    "YEAR_4": 144496.58413198922,
    "YEAR_5": 185315.1897003794,
    "YEAR_6": 226133.79526876955,
    "YEAR_7": 266952.40083715966,
    "YEAR_8": 307771.0064055499,
    "YEAR_9": 348589.61197394,
    "YEAR_10": 389408.2175423301,
    "METERED_Izak": 2055561.4772610764
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Izak",
    "Total Smart Devices": 922574.2281,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1845.1484561999998,
    "overall Metered": 276.77226842999994,
    "YEAR_1": 20290.285707510666,
    "YEAR_2": 62652.38485996221,
    "YEAR_3": 103336.57981228374,
    "YEAR_4": 144020.77476460527,
    "YEAR_5": 184704.9697169268,
    "YEAR_6": 225389.1646692483,
    "YEAR_7": 266073.3596215698,
    "YEAR_8": 306757.55457389134,
    "YEAR_9": 347441.74952621287,
    "YEAR_10": 388125.9444785344,
    "METERED_Izak": 2048792.7677307453
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Izak",
    "Total Smart Devices": 960358.8983,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1920.7177966,
    "overall Metered": 288.10766949,
    "YEAR_1": 21121.28849337969,
    "YEAR_2": 65218.35692711231,
    "YEAR_3": 107568.80142532874,
    "YEAR_4": 149919.24592354518,
    "YEAR_5": 192269.6904217616,
    "YEAR_6": 234620.13491997804,
    "YEAR_7": 276970.5794181945,
    "YEAR_8": 319321.0239164109,
    "YEAR_9": 361671.46841462736,
    "YEAR_10": 404021.91291284375,
    "METERED_Izak": 2132702.502773182
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Izak",
    "Total Smart Devices": 965499.8531,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1930.9997062,
    "overall Metered": 289.64995593,
    "YEAR_1": 21234.35412921067,
    "YEAR_2": 65567.48122396224,
    "YEAR_3": 108144.63442588376,
    "YEAR_4": 150721.7876278053,
    "YEAR_5": 193298.9408297268,
    "YEAR_6": 235876.0940316483,
    "YEAR_7": 278453.2472335699,
    "YEAR_8": 321030.4004354914,
    "YEAR_9": 363607.55363741296,
    "YEAR_10": 406184.70683933445,
    "METERED_Izak": 2144119.2004140457
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Izak",
    "Total Smart Devices": 878517.1744,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1757.0343488000003,
    "overall Metered": 263.55515232000005,
    "YEAR_1": 19321.33363864013,
    "YEAR_2": 59660.45272037377,
    "YEAR_3": 98401.79504668264,
    "YEAR_4": 137143.1373729915,
    "YEAR_5": 175884.47969930034,
    "YEAR_6": 214625.82202560926,
    "YEAR_7": 253367.16435191815,
    "YEAR_8": 292108.506678227,
    "YEAR_9": 330849.8490045358,
    "YEAR_10": 369591.1913308448,
    "METERED_Izak": 1950953.7318691232
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Izak",
    "Total Smart Devices": 901897.6009,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1803.7952018,
    "overall Metered": 270.56928027,
    "YEAR_1": 19835.542164305807,
    "YEAR_2": 61248.226836159345,
    "YEAR_3": 101020.61230330406,
    "YEAR_4": 140792.99777044874,
    "YEAR_5": 180565.38323759343,
    "YEAR_6": 220337.76870473815,
    "YEAR_7": 260110.1541718828,
    "YEAR_8": 299882.53963902756,
    "YEAR_9": 339654.9251061722,
    "YEAR_10": 379427.31057331694,
    "METERED_Izak": 2002875.460506949
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Izak",
    "Total Smart Devices": 877940.0386,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1755.8800772,
    "overall Metered": 263.38201158,
    "YEAR_1": 19308.64062173443,
    "YEAR_2": 59621.25919734144,
    "YEAR_3": 98337.15066594584,
    "YEAR_4": 137053.04213455028,
    "YEAR_5": 175768.93360315467,
    "YEAR_6": 214484.82507175906,
    "YEAR_7": 253200.7165403635,
    "YEAR_8": 291916.6080089679,
    "YEAR_9": 330632.4994775723,
    "YEAR_10": 369348.39094617683,
    "METERED_Izak": 1949672.0662675663
  },
  {
    "DMA": "Jackson",
    "PlatformName": "Izak",
    "Total Smart Devices": 832501.5841,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1665.0031682000001,
    "overall Metered": 249.75047523,
    "YEAR_1": 18309.30723930139,
    "YEAR_2": 56535.51557686464,
    "YEAR_3": 93247.63663339353,
    "YEAR_4": 129959.7576899224,
    "YEAR_5": 166671.87874645132,
    "YEAR_6": 203383.99980298022,
    "YEAR_7": 240096.12085950913,
    "YEAR_8": 276808.24191603804,
    "YEAR_9": 313520.36297256686,
    "YEAR_10": 350232.48402909585,
    "METERED_Izak": 1848765.3054661234
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Izak",
    "Total Smart Devices": 835761.6251,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1671.5232502,
    "overall Metered": 250.72848752999997,
    "YEAR_1": 18381.00571221931,
    "YEAR_2": 56756.90626519103,
    "YEAR_3": 93612.79043536089,
    "YEAR_4": 130468.67460553072,
    "YEAR_5": 167324.5587757006,
    "YEAR_6": 204180.44294587043,
    "YEAR_7": 241036.32711604028,
    "YEAR_8": 277892.2112862101,
    "YEAR_9": 314748.09545637993,
    "YEAR_10": 351603.97962654976,
    "METERED_Izak": 1856004.9922250533
  },
  {
    "DMA": "Boise",
    "PlatformName": "Izak",
    "Total Smart Devices": 805064.7535,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1610.129507,
    "overall Metered": 241.51942605,
    "YEAR_1": 17705.88573149592,
    "YEAR_2": 54672.269436086404,
    "YEAR_3": 90174.46577219135,
    "YEAR_4": 125676.66210829631,
    "YEAR_5": 161178.85844440127,
    "YEAR_6": 196681.0547805062,
    "YEAR_7": 232183.25111661115,
    "YEAR_8": 267685.4474527161,
    "YEAR_9": 303187.6437888211,
    "YEAR_10": 338689.84012492606,
    "METERED_Izak": 1787835.3787560519
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Izak",
    "Total Smart Devices": 818778.2968,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1637.5565936000003,
    "overall Metered": 245.63348904000003,
    "YEAR_1": 18007.489334918017,
    "YEAR_2": 55603.56164700672,
    "YEAR_3": 91710.50549513932,
    "YEAR_4": 127817.44934327195,
    "YEAR_5": 163924.39319140455,
    "YEAR_6": 200031.33703953712,
    "YEAR_7": 236138.28088766974,
    "YEAR_8": 272245.22473580233,
    "YEAR_9": 308352.16858393495,
    "YEAR_10": 344459.1124320675,
    "METERED_Izak": 1818289.5226907523
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Izak",
    "Total Smart Devices": 788153.8513,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1576.3077026,
    "overall Metered": 236.44615539,
    "YEAR_1": 17333.962230103054,
    "YEAR_2": 53523.84330332352,
    "YEAR_3": 88280.29320410766,
    "YEAR_4": 123036.74310489178,
    "YEAR_5": 157793.1930056759,
    "YEAR_6": 192549.64290646007,
    "YEAR_7": 227306.09280724413,
    "YEAR_8": 262062.54270802828,
    "YEAR_9": 296818.99260881246,
    "YEAR_10": 331575.44250959647,
    "METERED_Izak": 1750280.7483882434
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Izak",
    "Total Smart Devices": 817105.2424,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1634.2104848000001,
    "overall Metered": 245.13157272,
    "YEAR_1": 17970.693648732286,
    "YEAR_2": 55489.94385348097,
    "YEAR_3": 91523.10841177191,
    "YEAR_4": 127556.2729700628,
    "YEAR_5": 163589.43752835377,
    "YEAR_6": 199622.6020866447,
    "YEAR_7": 235655.76664493568,
    "YEAR_8": 271688.9312032266,
    "YEAR_9": 307722.0957615176,
    "YEAR_10": 343755.2603198085,
    "METERED_Izak": 1814574.1124285348
  },
  {
    "DMA": "Reno",
    "PlatformName": "Izak",
    "Total Smart Devices": 766046.8896,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1532.0937792000002,
    "overall Metered": 229.81406688,
    "YEAR_1": 16847.761168599554,
    "YEAR_2": 52022.55069149184,
    "YEAR_3": 85804.1154153308,
    "YEAR_4": 119585.6801391698,
    "YEAR_5": 153367.2448630088,
    "YEAR_6": 187148.80958684778,
    "YEAR_7": 220930.37431068672,
    "YEAR_8": 254711.93903452568,
    "YEAR_9": 288493.5037583647,
    "YEAR_10": 322275.06848220364,
    "METERED_Izak": 1701187.0474502293
  },
  {
    "DMA": "Washington",
    "PlatformName": "Izak",
    "Total Smart Devices": 757188.4939,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1514.3769878000003,
    "overall Metered": 227.15654817000004,
    "YEAR_1": 16652.937408961967,
    "YEAR_2": 51420.973496146566,
    "YEAR_3": 84811.89572570536,
    "YEAR_4": 118202.81795526412,
    "YEAR_5": 151593.7401848229,
    "YEAR_6": 184984.66241438172,
    "YEAR_7": 218375.5846439405,
    "YEAR_8": 251766.5068734993,
    "YEAR_9": 285157.42910305806,
    "YEAR_10": 318548.3513326169,
    "METERED_Izak": 1681514.8991383975
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Izak",
    "Total Smart Devices": 772710.2,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1545.4204,
    "overall Metered": 231.81305999999998,
    "YEAR_1": 16994.308153823997,
    "YEAR_2": 52475.058766079994,
    "YEAR_3": 86550.465883392,
    "YEAR_4": 120625.87300070398,
    "YEAR_5": 154701.28011801597,
    "YEAR_6": 188776.68723532796,
    "YEAR_7": 222852.09435264,
    "YEAR_8": 256927.50146995197,
    "YEAR_9": 291002.9085872639,
    "YEAR_10": 325078.315704576,
    "METERED_Izak": 1715984.4932717758
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Izak",
    "Total Smart Devices": 775398.3021,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1550.7966042,
    "overall Metered": 232.61949063,
    "YEAR_1": 17053.427905881552,
    "YEAR_2": 52657.608854931845,
    "YEAR_3": 86851.55740398682,
    "YEAR_4": 121045.50595304176,
    "YEAR_5": 155239.45450209675,
    "YEAR_6": 189433.40305115172,
    "YEAR_7": 223627.35160020666,
    "YEAR_8": 257821.3001492617,
    "YEAR_9": 292015.2486983167,
    "YEAR_10": 326209.19724737166,
    "METERED_Izak": 1721954.0553662474
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Izak",
    "Total Smart Devices": 720630.3035,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1441.2606070000002,
    "overall Metered": 216.18909105000003,
    "YEAR_1": 15848.908740511923,
    "YEAR_2": 48938.29216280641,
    "YEAR_3": 80717.05083951936,
    "YEAR_4": 112495.80951623233,
    "YEAR_5": 144274.5681929453,
    "YEAR_6": 176053.32686965828,
    "YEAR_7": 207832.0855463712,
    "YEAR_8": 239610.8442230842,
    "YEAR_9": 271389.60289979714,
    "YEAR_10": 303168.3615765101,
    "METERED_Izak": 1600328.8505674363
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Izak",
    "Total Smart Devices": 728058.3645,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1456.1167290000003,
    "overall Metered": 218.41750935000005,
    "YEAR_1": 16012.274977452244,
    "YEAR_2": 49442.7347565408,
    "YEAR_3": 81549.06022694592,
    "YEAR_4": 113655.38569735106,
    "YEAR_5": 145761.71116775615,
    "YEAR_6": 177868.03663816128,
    "YEAR_7": 209974.36210856642,
    "YEAR_8": 242080.68757897156,
    "YEAR_9": 274187.0130493766,
    "YEAR_10": 306293.33851978177,
    "METERED_Izak": 1616824.604720904
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Izak",
    "Total Smart Devices": 714649.2487,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1429.2984974,
    "overall Metered": 214.39477461,
    "YEAR_1": 15717.366684568942,
    "YEAR_2": 48532.11633891648,
    "YEAR_3": 80047.11911166836,
    "YEAR_4": 111562.12188442024,
    "YEAR_5": 143077.1246571721,
    "YEAR_6": 174592.12742992397,
    "YEAR_7": 206107.13020267582,
    "YEAR_8": 237622.1329754277,
    "YEAR_9": 269137.1357481796,
    "YEAR_10": 300652.1385209314,
    "METERED_Izak": 1587046.5135538846
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Izak",
    "Total Smart Devices": 723124.6195,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1446.2492390000002,
    "overall Metered": 216.93738585000003,
    "YEAR_1": 15903.76653161784,
    "YEAR_2": 49107.6821600928,
    "YEAR_3": 80996.43658059073,
    "YEAR_4": 112885.19100108865,
    "YEAR_5": 144773.94542158657,
    "YEAR_6": 176662.6998420845,
    "YEAR_7": 208551.4542625824,
    "YEAR_8": 240440.20868308036,
    "YEAR_9": 272328.9631035783,
    "YEAR_10": 304217.7175240762,
    "METERED_Izak": 1605868.0651103782
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Izak",
    "Total Smart Devices": 722266.5486,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1444.5330972000002,
    "overall Metered": 216.67996458000002,
    "YEAR_1": 15884.894875345633,
    "YEAR_2": 49049.41022204544,
    "YEAR_3": 80900.32495147546,
    "YEAR_4": 112751.2396809055,
    "YEAR_5": 144602.1544103355,
    "YEAR_6": 176453.06913976552,
    "YEAR_7": 208303.98386919554,
    "YEAR_8": 240154.89859862556,
    "YEAR_9": 272005.81332805555,
    "YEAR_10": 303856.72805748554,
    "METERED_Izak": 1603962.5171332352
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Izak",
    "Total Smart Devices": 712861.0057,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1425.7220114000002,
    "overall Metered": 213.85830171,
    "YEAR_1": 15678.037641680781,
    "YEAR_2": 48410.67604148928,
    "YEAR_3": 79846.81987301105,
    "YEAR_4": 111282.96370453287,
    "YEAR_5": 142719.10753605465,
    "YEAR_6": 174155.25136757645,
    "YEAR_7": 205591.39519909822,
    "YEAR_8": 237027.53903061996,
    "YEAR_9": 268463.68286214175,
    "YEAR_10": 299899.8266936636,
    "METERED_Izak": 1583075.2999498683
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Izak",
    "Total Smart Devices": 693737.697,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1387.475394,
    "overall Metered": 208.1213091,
    "YEAR_1": 15257.456418644637,
    "YEAR_2": 47112.00449834879,
    "YEAR_3": 77704.83795376512,
    "YEAR_4": 108297.67140918144,
    "YEAR_5": 138890.50486459775,
    "YEAR_6": 169483.33832001407,
    "YEAR_7": 200076.17177543035,
    "YEAR_8": 230669.0052308467,
    "YEAR_9": 261261.83868626298,
    "YEAR_10": 291854.6721416793,
    "METERED_Izak": 1540607.5012987712
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Izak",
    "Total Smart Devices": 661038.8766,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1322.0777532000002,
    "overall Metered": 198.31166298000002,
    "YEAR_1": 14538.307337728991,
    "YEAR_2": 44891.41452545664,
    "YEAR_3": 74042.27708753434,
    "YEAR_4": 103193.13964961204,
    "YEAR_5": 132344.00221168975,
    "YEAR_6": 161494.86477376745,
    "YEAR_7": 190645.72733584515,
    "YEAR_8": 219796.58989792282,
    "YEAR_9": 248947.45246000052,
    "YEAR_10": 278098.3150220782,
    "METERED_Izak": 1467992.0903016361
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Izak",
    "Total Smart Devices": 672775.9124,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1345.5518248,
    "overall Metered": 201.83277372,
    "YEAR_1": 14796.441374522685,
    "YEAR_2": 45688.48132144896,
    "YEAR_3": 75356.9302609751,
    "YEAR_4": 105025.37920050125,
    "YEAR_5": 134693.82814002738,
    "YEAR_6": 164362.27707955352,
    "YEAR_7": 194030.72601907968,
    "YEAR_8": 223699.17495860584,
    "YEAR_9": 253367.62389813198,
    "YEAR_10": 283036.0728376581,
    "METERED_Izak": 1494056.9350905046
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Izak",
    "Total Smart Devices": 647106.4308,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1294.2128616,
    "overall Metered": 194.13192924,
    "YEAR_1": 14231.889385356097,
    "YEAR_2": 43945.25655820032,
    "YEAR_3": 72481.71832321996,
    "YEAR_4": 101018.18008823962,
    "YEAR_5": 129554.64185325927,
    "YEAR_6": 158091.1036182789,
    "YEAR_7": 186627.56538329853,
    "YEAR_8": 215164.0271483182,
    "YEAR_9": 243700.48891333787,
    "YEAR_10": 272236.9506783575,
    "METERED_Izak": 1437051.8219498661
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Izak",
    "Total Smart Devices": 650772.6644,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1301.5453288,
    "overall Metered": 195.23179932000002,
    "YEAR_1": 14312.521300868928,
    "YEAR_2": 44194.23194846976,
    "YEAR_3": 72892.36933587302,
    "YEAR_4": 101590.5067232763,
    "YEAR_5": 130288.64411067952,
    "YEAR_6": 158986.7814980828,
    "YEAR_7": 187684.91888548608,
    "YEAR_8": 216383.05627288937,
    "YEAR_9": 245081.19366029263,
    "YEAR_10": 273779.3310476959,
    "METERED_Izak": 1445193.5547836141
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Izak",
    "Total Smart Devices": 655342.6546,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1310.6853092000001,
    "overall Metered": 196.60279638,
    "YEAR_1": 14413.029643736352,
    "YEAR_2": 44504.58181094783,
    "YEAR_3": 73404.24918538521,
    "YEAR_4": 102303.9165598226,
    "YEAR_5": 131203.58393425995,
    "YEAR_6": 160103.25130869733,
    "YEAR_7": 189002.9186831347,
    "YEAR_8": 217902.58605757207,
    "YEAR_9": 246802.25343200946,
    "YEAR_10": 275701.9208064468,
    "METERED_Izak": 1455342.2914220123
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Izak",
    "Total Smart Devices": 631244.1375,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1262.488275,
    "overall Metered": 189.37324124999998,
    "YEAR_1": 13883.028065333998,
    "YEAR_2": 42868.04187527999,
    "YEAR_3": 70704.99934747198,
    "YEAR_4": 98541.95681966399,
    "YEAR_5": 126378.91429185595,
    "YEAR_6": 154215.87176404794,
    "YEAR_7": 182052.82923623995,
    "YEAR_8": 209889.78670843196,
    "YEAR_9": 237726.74418062397,
    "YEAR_10": 265563.7016528159,
    "METERED_Izak": 1401825.8739417656
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Izak",
    "Total Smart Devices": 615432.0772,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1230.8641544000002,
    "overall Metered": 184.62962316000002,
    "YEAR_1": 13535.271525708864,
    "YEAR_2": 41794.23853548289,
    "YEAR_3": 68933.90691781172,
    "YEAR_4": 96073.57530014054,
    "YEAR_5": 123213.2436824694,
    "YEAR_6": 150352.91206479826,
    "YEAR_7": 177492.58044712705,
    "YEAR_8": 204632.24882945587,
    "YEAR_9": 231771.91721178472,
    "YEAR_10": 258911.58559411357,
    "METERED_Izak": 1366711.480108893
  },
  {
    "DMA": "Macon",
    "PlatformName": "Izak",
    "Total Smart Devices": 623908.0976,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1247.8161952,
    "overall Metered": 187.17242928,
    "YEAR_1": 13721.685659488508,
    "YEAR_2": 42369.848471255034,
    "YEAR_3": 69883.29714775449,
    "YEAR_4": 97396.74582425396,
    "YEAR_5": 124910.1945007534,
    "YEAR_6": 152423.64317725282,
    "YEAR_7": 179937.0918537523,
    "YEAR_8": 207450.54053025172,
    "YEAR_9": 234963.9892067512,
    "YEAR_10": 262477.43788325065,
    "METERED_Izak": 1385534.474254764
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Izak",
    "Total Smart Devices": 615383.1435,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1230.7662870000001,
    "overall Metered": 184.61494305000002,
    "YEAR_1": 13534.195320972722,
    "YEAR_2": 41790.91542834241,
    "YEAR_3": 68928.42590496576,
    "YEAR_4": 96065.93638158914,
    "YEAR_5": 123203.4468582125,
    "YEAR_6": 150340.95733483587,
    "YEAR_7": 177478.4678114592,
    "YEAR_8": 204615.97828808258,
    "YEAR_9": 231753.48876470592,
    "YEAR_10": 258890.9992413293,
    "METERED_Izak": 1366602.8113344952
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Izak",
    "Total Smart Devices": 624929.966,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1249.8599320000003,
    "overall Metered": 187.47898980000005,
    "YEAR_1": 13744.159733833923,
    "YEAR_2": 42439.243963046414,
    "YEAR_3": 69997.75556449538,
    "YEAR_4": 97556.26716594434,
    "YEAR_5": 125114.77876739332,
    "YEAR_6": 152673.29036884228,
    "YEAR_7": 180231.8019702912,
    "YEAR_8": 207790.31357174026,
    "YEAR_9": 235348.8251731892,
    "YEAR_10": 262907.33677463816,
    "METERED_Izak": 1387803.7730534144
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Izak",
    "Total Smart Devices": 631714.9611,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1263.4299222,
    "overall Metered": 189.51448832999998,
    "YEAR_1": 13893.38294526763,
    "YEAR_2": 42900.01569428542,
    "YEAR_3": 70757.73580925145,
    "YEAR_4": 98615.45592421746,
    "YEAR_5": 126473.17603918347,
    "YEAR_6": 154330.89615414944,
    "YEAR_7": 182188.61626911548,
    "YEAR_8": 210046.33638408151,
    "YEAR_9": 237904.0564990475,
    "YEAR_10": 265761.7766140136,
    "METERED_Izak": 1402871.4483326129
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Izak",
    "Total Smart Devices": 617472.2414,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1234.9444828000003,
    "overall Metered": 185.24167242000004,
    "YEAR_1": 13580.141101779172,
    "YEAR_2": 41932.786902370564,
    "YEAR_3": 69162.42358808295,
    "YEAR_4": 96392.06027379534,
    "YEAR_5": 123621.69695950774,
    "YEAR_6": 150851.33364522012,
    "YEAR_7": 178080.9703309325,
    "YEAR_8": 205310.6070166449,
    "YEAR_9": 232540.2437023573,
    "YEAR_10": 259769.8803880697,
    "METERED_Izak": 1371242.1439087603
  },
  {
    "DMA": "Lafayette",
    "PlatformName": "Izak",
    "Total Smart Devices": 607971.4294,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1215.9428588,
    "overall Metered": 182.39142882000002,
    "YEAR_1": 13371.188603365728,
    "YEAR_2": 41287.582959125764,
    "YEAR_3": 68098.24751680743,
    "YEAR_4": 94908.91207448908,
    "YEAR_5": 121719.57663217076,
    "YEAR_6": 148530.24118985242,
    "YEAR_7": 175340.9057475341,
    "YEAR_8": 202151.57030521575,
    "YEAR_9": 228962.2348628974,
    "YEAR_10": 255772.89942057908,
    "METERED_Izak": 1350143.3593120375
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Izak",
    "Total Smart Devices": 609396.2424,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1218.7924848,
    "overall Metered": 182.81887272,
    "YEAR_1": 13402.524686652288,
    "YEAR_2": 41384.34257988096,
    "YEAR_3": 68257.83933913191,
    "YEAR_4": 95131.33609838286,
    "YEAR_5": 122004.83285763381,
    "YEAR_6": 148878.32961688473,
    "YEAR_7": 175751.8263761357,
    "YEAR_8": 202625.32313538663,
    "YEAR_9": 229498.81989463753,
    "YEAR_10": 256372.31665388853,
    "METERED_Izak": 1353307.4912386148
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Izak",
    "Total Smart Devices": 597656.2835,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1195.3125670000002,
    "overall Metered": 179.29688505000001,
    "YEAR_1": 13144.32636176952,
    "YEAR_2": 40587.0772749984,
    "YEAR_3": 66942.85875230016,
    "YEAR_4": 93298.64022960192,
    "YEAR_5": 119654.4217069037,
    "YEAR_6": 146010.20318420546,
    "YEAR_7": 172365.98466150722,
    "YEAR_8": 198721.76613880898,
    "YEAR_9": 225077.54761611074,
    "YEAR_10": 251433.3290934125,
    "METERED_Izak": 1327236.1550196186
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Izak",
    "Total Smart Devices": 569961.0358,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1139.9220716,
    "overall Metered": 170.98831074,
    "YEAR_1": 12535.221455673698,
    "YEAR_2": 38706.28192559231,
    "YEAR_3": 63840.742860480765,
    "YEAR_4": 88975.2037953692,
    "YEAR_5": 114109.66473025766,
    "YEAR_6": 139244.1256651461,
    "YEAR_7": 164378.58660003456,
    "YEAR_8": 189513.04753492298,
    "YEAR_9": 214647.5084698114,
    "YEAR_10": 239781.96940469986,
    "METERED_Izak": 1265732.3524419884
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Izak",
    "Total Smart Devices": 552884.0625,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1105.768125,
    "overall Metered": 165.86521875,
    "YEAR_1": 12159.645532649998,
    "YEAR_2": 37546.577838,
    "YEAR_3": 61927.968841199996,
    "YEAR_4": 86309.35984439998,
    "YEAR_5": 110690.75084759996,
    "YEAR_6": 135072.14185079996,
    "YEAR_7": 159453.53285399996,
    "YEAR_8": 183834.92385719996,
    "YEAR_9": 208216.31486039996,
    "YEAR_10": 232597.70586359996,
    "METERED_Izak": 1227808.9221898497
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Izak",
    "Total Smart Devices": 537565.9934,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1075.1319868,
    "overall Metered": 161.26979802,
    "YEAR_1": 11822.753400765407,
    "YEAR_2": 36506.321638191366,
    "YEAR_3": 60212.20785210085,
    "YEAR_4": 83918.09406601035,
    "YEAR_5": 107623.98027991985,
    "YEAR_6": 131329.86649382935,
    "YEAR_7": 155035.75270773884,
    "YEAR_8": 178741.63892164832,
    "YEAR_9": 202447.5251355578,
    "YEAR_10": 226153.41134946738,
    "METERED_Izak": 1193791.5518452297
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Izak",
    "Total Smart Devices": 510390.503,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 1020.7810060000002,
    "overall Metered": 153.1171509,
    "YEAR_1": 11225.079579339359,
    "YEAR_2": 34660.8232149312,
    "YEAR_3": 57168.30943490686,
    "YEAR_4": 79675.79565488255,
    "YEAR_5": 102183.28187485822,
    "YEAR_6": 124690.76809483391,
    "YEAR_7": 147198.25431480957,
    "YEAR_8": 169705.74053478526,
    "YEAR_9": 192213.22675476098,
    "YEAR_10": 214720.71297473664,
    "METERED_Izak": 1133441.9924328446
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Izak",
    "Total Smart Devices": 480098.5248,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 960.1970496,
    "overall Metered": 144.02955744,
    "YEAR_1": 10558.864467749374,
    "YEAR_2": 32603.682858577915,
    "YEAR_3": 53775.3364603822,
    "YEAR_4": 74946.99006218648,
    "YEAR_5": 96118.64366399078,
    "YEAR_6": 117290.29726579507,
    "YEAR_7": 138461.95086759934,
    "YEAR_8": 159633.60446940362,
    "YEAR_9": 180805.25807120794,
    "YEAR_10": 201976.9116730122,
    "METERED_Izak": 1066171.539859905
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Izak",
    "Total Smart Devices": 492907.9867,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 985.8159734000001,
    "overall Metered": 147.87239601000002,
    "YEAR_1": 10840.584500451505,
    "YEAR_2": 33473.578539991686,
    "YEAR_3": 55210.110965960834,
    "YEAR_4": 76946.64339192997,
    "YEAR_5": 98683.17581789914,
    "YEAR_6": 120419.70824386828,
    "YEAR_7": 142156.24066983745,
    "YEAR_8": 163892.77309580657,
    "YEAR_9": 185629.3055217757,
    "YEAR_10": 207365.83794774493,
    "METERED_Izak": 1094617.9586952662
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Izak",
    "Total Smart Devices": 470002.9309,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 940.0058617999999,
    "overall Metered": 141.00087926999998,
    "YEAR_1": 10336.830859635407,
    "YEAR_2": 31918.087038591344,
    "YEAR_3": 52644.53948706085,
    "YEAR_4": 73370.99193553036,
    "YEAR_5": 94097.44438399986,
    "YEAR_6": 114823.89683246934,
    "YEAR_7": 135550.34928093883,
    "YEAR_8": 156276.80172940835,
    "YEAR_9": 177003.25417787782,
    "YEAR_10": 197729.70662634732,
    "METERED_Izak": 1043751.9023518595
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Izak",
    "Total Smart Devices": 465738.6675,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 931.477335,
    "overall Metered": 139.72160025,
    "YEAR_1": 10243.046402967599,
    "YEAR_2": 31628.499205391996,
    "YEAR_3": 52166.90377846079,
    "YEAR_4": 72705.30835152959,
    "YEAR_5": 93243.7129245984,
    "YEAR_6": 113782.1174976672,
    "YEAR_7": 134320.522070736,
    "YEAR_8": 154858.9266438048,
    "YEAR_9": 175397.3312168736,
    "YEAR_10": 195935.7357899424,
    "METERED_Izak": 1034282.1038819724
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Izak",
    "Total Smart Devices": 454093.9776,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 908.1879552,
    "overall Metered": 136.22819328,
    "YEAR_1": 9986.943340634112,
    "YEAR_2": 30837.70365640704,
    "YEAR_3": 50862.5941732393,
    "YEAR_4": 70887.48469007155,
    "YEAR_5": 90912.3752069038,
    "YEAR_6": 110937.26572373605,
    "YEAR_7": 130962.15624056832,
    "YEAR_8": 150987.04675740056,
    "YEAR_9": 171011.9372742328,
    "YEAR_10": 191036.8277910651,
    "METERED_Izak": 1008422.3348542586
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Izak",
    "Total Smart Devices": 451807.1961,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 903.6143922000001,
    "overall Metered": 135.54215883,
    "YEAR_1": 9936.649880690831,
    "YEAR_2": 30682.40741002944,
    "YEAR_3": 50606.45415567704,
    "YEAR_4": 70530.50090132466,
    "YEAR_5": 90454.54764697228,
    "YEAR_6": 110378.5943926199,
    "YEAR_7": 130302.64113826751,
    "YEAR_8": 150226.68788391515,
    "YEAR_9": 170150.73462956276,
    "YEAR_10": 190074.78137521038,
    "METERED_Izak": 1003343.9994142699
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Izak",
    "Total Smart Devices": 466140.3129,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 932.2806258,
    "overall Metered": 139.84209387,
    "YEAR_1": 10251.879838447248,
    "YEAR_2": 31655.77510516416,
    "YEAR_3": 52211.89166200359,
    "YEAR_4": 72768.00821884301,
    "YEAR_5": 93324.12477568243,
    "YEAR_6": 113880.24133252185,
    "YEAR_7": 134436.35788936127,
    "YEAR_8": 154992.4744462007,
    "YEAR_9": 175548.59100304014,
    "YEAR_10": 196104.70755987952,
    "METERED_Izak": 1035174.051831144
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Izak",
    "Total Smart Devices": 484230.3852,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 968.4607704,
    "overall Metered": 145.26911556,
    "YEAR_1": 10649.736969349822,
    "YEAR_2": 32884.27915108608,
    "YEAR_3": 54238.14184665138,
    "YEAR_4": 75592.00454221669,
    "YEAR_5": 96945.86723778202,
    "YEAR_6": 118299.72993334732,
    "YEAR_7": 139653.59262891262,
    "YEAR_8": 161007.45532447795,
    "YEAR_9": 182361.31802004328,
    "YEAR_10": 203715.18071560856,
    "METERED_Izak": 1075347.3063694758
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Izak",
    "Total Smart Devices": 481277.3701,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 962.5547402000001,
    "overall Metered": 144.38321103,
    "YEAR_1": 10584.790953893711,
    "YEAR_2": 32683.738714439038,
    "YEAR_3": 53907.377696436095,
    "YEAR_4": 75131.01667843317,
    "YEAR_5": 96354.6556604302,
    "YEAR_6": 117578.29464242728,
    "YEAR_7": 138801.93362442436,
    "YEAR_8": 160025.57260642137,
    "YEAR_9": 181249.21158841843,
    "YEAR_10": 202472.8505704155,
    "METERED_Izak": 1068789.4427357393
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Izak",
    "Total Smart Devices": 453919.7864,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 907.8395728,
    "overall Metered": 136.17593592,
    "YEAR_1": 9983.112332669567,
    "YEAR_2": 30825.874262338555,
    "YEAR_3": 50843.083198086155,
    "YEAR_4": 70860.29213383372,
    "YEAR_5": 90877.50106958131,
    "YEAR_6": 110894.7100053289,
    "YEAR_7": 130911.91894107648,
    "YEAR_8": 150929.12787682406,
    "YEAR_9": 170946.33681257168,
    "YEAR_10": 190963.54574831924,
    "METERED_Izak": 1008035.5023806295
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Izak",
    "Total Smart Devices": 449346.8731,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 898.6937462000001,
    "overall Metered": 134.80406193000002,
    "YEAR_1": 9882.539701713073,
    "YEAR_2": 30515.325890970245,
    "YEAR_3": 50330.87593518298,
    "YEAR_4": 70146.42597939572,
    "YEAR_5": 89961.97602360847,
    "YEAR_6": 109777.5260678212,
    "YEAR_7": 129593.07611203394,
    "YEAR_8": 149408.62615624667,
    "YEAR_9": 169224.17620045942,
    "YEAR_10": 189039.72624467214,
    "METERED_Izak": 997880.2743121039
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Izak",
    "Total Smart Devices": 443416.376,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 886.8327520000001,
    "overall Metered": 133.0249128,
    "YEAR_1": 9752.10956733312,
    "YEAR_2": 30112.583460710397,
    "YEAR_3": 49666.607122728965,
    "YEAR_4": 69220.63078474751,
    "YEAR_5": 88774.65444676607,
    "YEAR_6": 108328.67810878465,
    "YEAR_7": 127882.70177080319,
    "YEAR_8": 147436.72543282175,
    "YEAR_9": 166990.7490948403,
    "YEAR_10": 186544.77275685887,
    "METERED_Izak": 984710.2125463948
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Izak",
    "Total Smart Devices": 443630.0818,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 887.2601635999999,
    "overall Metered": 133.08902454,
    "YEAR_1": 9756.809624637217,
    "YEAR_2": 30127.096307070715,
    "YEAR_3": 49690.54408713292,
    "YEAR_4": 69253.99186719513,
    "YEAR_5": 88817.43964725734,
    "YEAR_6": 108380.88742731957,
    "YEAR_7": 127944.33520738175,
    "YEAR_8": 147507.78298744393,
    "YEAR_9": 167071.2307675062,
    "YEAR_10": 186634.6785475684,
    "METERED_Izak": 985184.7964705131
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Izak",
    "Total Smart Devices": 423818.9958,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 847.6379916000001,
    "overall Metered": 127.14569874,
    "YEAR_1": 9321.102032908897,
    "YEAR_2": 28781.717532376322,
    "YEAR_3": 47471.52494780237,
    "YEAR_4": 66161.33236322842,
    "YEAR_5": 84851.13977865444,
    "YEAR_6": 103540.94719408052,
    "YEAR_7": 122230.75460950656,
    "YEAR_8": 140920.56202493262,
    "YEAR_9": 159610.36944035866,
    "YEAR_10": 178300.17685578472,
    "METERED_Izak": 941189.6267796335
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Izak",
    "Total Smart Devices": 393958.0018,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 787.9160036000002,
    "overall Metered": 118.18740054000003,
    "YEAR_1": 8664.365608547618,
    "YEAR_2": 26753.845485438727,
    "YEAR_3": 44126.82606529614,
    "YEAR_4": 61499.806645153556,
    "YEAR_5": 78872.78722501096,
    "YEAR_6": 96245.76780486837,
    "YEAR_7": 113618.7483847258,
    "YEAR_8": 130991.72896458318,
    "YEAR_9": 148364.70954444061,
    "YEAR_10": 165737.690124298,
    "METERED_Izak": 874876.2758523629
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Izak",
    "Total Smart Devices": 422552.4588,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 845.1049176000001,
    "overall Metered": 126.76573764000001,
    "YEAR_1": 9293.246932683456,
    "YEAR_2": 28695.706498091524,
    "YEAR_3": 47329.66145563084,
    "YEAR_4": 65963.61641317017,
    "YEAR_5": 84597.5713707095,
    "YEAR_6": 103231.52632824883,
    "YEAR_7": 121865.48128578816,
    "YEAR_8": 140499.43624332748,
    "YEAR_9": 159133.3912008668,
    "YEAR_10": 177767.34615840612,
    "METERED_Izak": 938376.983886923
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Izak",
    "Total Smart Devices": 413572.7033,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 827.1454066000001,
    "overall Metered": 124.07181099000002,
    "YEAR_1": 9095.754092401296,
    "YEAR_2": 28085.887710184317,
    "YEAR_3": 46323.84838102157,
    "YEAR_4": 64561.809051858814,
    "YEAR_5": 82799.76972269607,
    "YEAR_6": 101037.73039353332,
    "YEAR_7": 119275.69106437058,
    "YEAR_8": 137513.65173520782,
    "YEAR_9": 155751.61240604508,
    "YEAR_10": 173989.57307688234,
    "METERED_Izak": 918435.3276342012
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Izak",
    "Total Smart Devices": 389215.4444,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 778.4308888,
    "overall Metered": 116.76463332,
    "YEAR_1": 8560.061974542527,
    "YEAR_2": 26431.77651538176,
    "YEAR_3": 43595.61714318182,
    "YEAR_4": 60759.457770981884,
    "YEAR_5": 77923.29839878193,
    "YEAR_6": 95087.13902658201,
    "YEAR_7": 112250.97965438207,
    "YEAR_8": 129414.82028218213,
    "YEAR_9": 146578.6609099822,
    "YEAR_10": 163742.5015377823,
    "METERED_Izak": 864344.3132137806
  },
  {
    "DMA": "Erie",
    "PlatformName": "Izak",
    "Total Smart Devices": 409022.6328,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 818.0452656000001,
    "overall Metered": 122.70678984000001,
    "YEAR_1": 8995.683845886337,
    "YEAR_2": 27776.890602501124,
    "YEAR_3": 45814.19971638989,
    "YEAR_4": 63851.50883027867,
    "YEAR_5": 81888.81794416744,
    "YEAR_6": 99926.1270580562,
    "YEAR_7": 117963.43617194497,
    "YEAR_8": 136000.74528583372,
    "YEAR_9": 154038.0543997225,
    "YEAR_10": 172075.3635136113,
    "METERED_Izak": 908330.8273683921
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Izak",
    "Total Smart Devices": 419474.8359,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 838.9496718,
    "overall Metered": 125.84245077,
    "YEAR_1": 9225.560402929006,
    "YEAR_2": 28486.703895903356,
    "YEAR_3": 46984.94011532967,
    "YEAR_4": 65483.17633475597,
    "YEAR_5": 83981.41255418227,
    "YEAR_6": 102479.64877360857,
    "YEAR_7": 120977.88499303488,
    "YEAR_8": 139476.12121246118,
    "YEAR_9": 157974.3574318875,
    "YEAR_10": 176472.5936513138,
    "METERED_Izak": 931542.3993654063
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Izak",
    "Total Smart Devices": 420021.6584,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 840.0433168,
    "overall Metered": 126.00649751999998,
    "YEAR_1": 9237.586735790206,
    "YEAR_2": 28523.838830607354,
    "YEAR_3": 47046.189134859254,
    "YEAR_4": 65568.53943911116,
    "YEAR_5": 84090.88974336305,
    "YEAR_6": 102613.24004761495,
    "YEAR_7": 121135.59035186683,
    "YEAR_8": 139657.94065611876,
    "YEAR_9": 158180.29096037065,
    "YEAR_10": 176702.64126462254,
    "METERED_Izak": 932756.7471643249
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Izak",
    "Total Smart Devices": 393926.0646,
    "Rate Card ( in $ per GB)": 0.02,
    "Size of Update(in GB)": 0.1,
    "DMA Consdered": "Y",
    "Metered SaaS": 0.15,
    "Monthly Adoption Growth Rate": 1.0208,
    "Holiday Months": 3,
    "Overall Cost for devices (Fixed)": 787.8521292,
    "overall Metered": 118.17781938,
    "YEAR_1": 8663.66320987555,
    "YEAR_2": 26751.676617411842,
    "YEAR_3": 44123.24881273882,
    "YEAR_4": 61494.82100806579,
    "YEAR_5": 78866.39320339277,
    "YEAR_6": 96237.96539871975,
    "YEAR_7": 113609.53759404672,
    "YEAR_8": 130981.1097893737,
    "YEAR_9": 148352.6819847007,
    "YEAR_10": 165724.25418002764,
    "METERED_Izak": 874805.3517983533
  },
  {
    "DMA": "New York",
    "PlatformName": "Izak",
    "Total Households": 3525394.987,
    "Total Smart Devices": 18429594.24,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 705078.9974000001,
    "Total Concierge": 16921.895937600002,
    "Susbscription/Concierge": 722000.8933376002,
    "YEAR_1": 42419869.124530405,
    "YEAR_2": 165496390.9268357,
    "YEAR_3": 273038594.4972022,
    "YEAR_4": 380580798.06756866,
    "YEAR_5": 488123001.6379352,
    "YEAR_6": 595665205.2083018,
    "YEAR_7": 703207408.7786682,
    "YEAR_8": 810749612.3490348,
    "YEAR_9": 918291815.9194013,
    "YEAR_10": 1025834019.4897678,
    "SUBSCRIPTION_Izak": 5403406715.999247
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Izak",
    "Total Households": 2663759.106,
    "Total Smart Devices": 13947729.05,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 532751.8212,
    "Total Concierge": 12786.0437088,
    "Susbscription/Concierge": 545537.8649088,
    "YEAR_1": 32052099.998006865,
    "YEAR_2": 125047695.35530467,
    "YEAR_3": 206305689.16769263,
    "YEAR_4": 287563682.9800806,
    "YEAR_5": 368821676.79246855,
    "YEAR_6": 450079670.6048564,
    "YEAR_7": 531337664.41724443,
    "YEAR_8": 612595658.2296324,
    "YEAR_9": 693853652.0420202,
    "YEAR_10": 775111645.8544083,
    "SUBSCRIPTION_Izak": 4082769135.4417152
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Izak",
    "Total Households": 1653912.48,
    "Total Smart Devices": 8667326.99,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 330782.49600000004,
    "Total Concierge": 7938.779904000001,
    "Susbscription/Concierge": 338721.27590400004,
    "YEAR_1": 19900961.793994717,
    "YEAR_2": 77641384.11672743,
    "YEAR_3": 128093998.15504475,
    "YEAR_4": 178546612.19336206,
    "YEAR_5": 228999226.23167944,
    "YEAR_6": 279451840.26999676,
    "YEAR_7": 329904454.3083141,
    "YEAR_8": 380357068.34663135,
    "YEAR_9": 430809682.3849487,
    "YEAR_10": 481262296.42326605,
    "SUBSCRIPTION_Izak": 2534967524.223965
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Izak",
    "Total Households": 1418555.62,
    "Total Smart Devices": 7450689.965,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 283711.124,
    "Total Concierge": 6809.066976000001,
    "Susbscription/Concierge": 290520.190976,
    "YEAR_1": 17068993.394545574,
    "YEAR_2": 66592775.08043378,
    "YEAR_3": 109865826.1355573,
    "YEAR_4": 153138877.19068077,
    "YEAR_5": 196411928.24580428,
    "YEAR_6": 239684979.30092776,
    "YEAR_7": 282958030.3560513,
    "YEAR_8": 326231081.41117483,
    "YEAR_9": 369504132.4662983,
    "YEAR_10": 412777183.5214219,
    "SUBSCRIPTION_Izak": 2174233807.1028957
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Izak",
    "Total Households": 1387789.234,
    "Total Smart Devices": 7285114.017,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 277557.8468,
    "Total Concierge": 6661.388323200001,
    "Susbscription/Concierge": 284219.2351232,
    "YEAR_1": 16698792.020694586,
    "YEAR_2": 65148475.68600057,
    "YEAR_3": 107482997.87881579,
    "YEAR_4": 149817520.07163098,
    "YEAR_5": 192152042.26444617,
    "YEAR_6": 234486564.45726135,
    "YEAR_7": 276821086.6500766,
    "YEAR_8": 319155608.84289175,
    "YEAR_9": 361490131.035707,
    "YEAR_10": 403824653.22852224,
    "SUBSCRIPTION_Izak": 2127077872.1360471
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Izak",
    "Total Households": 1222748.747,
    "Total Smart Devices": 6409667.645,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 244549.74939999997,
    "Total Concierge": 5869.193985599999,
    "Susbscription/Concierge": 250418.94338559997,
    "YEAR_1": 14712916.428142501,
    "YEAR_2": 57400803.423452094,
    "YEAR_3": 94700764.17967486,
    "YEAR_4": 132000724.93589765,
    "YEAR_5": 169300685.69212043,
    "YEAR_6": 206600646.44834325,
    "YEAR_7": 243900607.20456606,
    "YEAR_8": 281200567.96078885,
    "YEAR_9": 318500528.7170116,
    "YEAR_10": 355800489.4732344,
    "SUBSCRIPTION_Izak": 1874118734.4632318
  },
  {
    "DMA": "Houston",
    "PlatformName": "Izak",
    "Total Households": 1216594.045,
    "Total Smart Devices": 6391466.714,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 243318.80899999998,
    "Total Concierge": 5839.651416,
    "Susbscription/Concierge": 249158.460416,
    "YEAR_1": 14638859.009242427,
    "YEAR_2": 57111876.65865375,
    "YEAR_3": 94224088.17887898,
    "YEAR_4": 131336299.69910419,
    "YEAR_5": 168448511.2193294,
    "YEAR_6": 205560722.7395546,
    "YEAR_7": 242672934.2597798,
    "YEAR_8": 279785145.780005,
    "YEAR_9": 316897357.3002302,
    "YEAR_10": 354009568.82045543,
    "SUBSCRIPTION_Izak": 1864685363.6652336
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Izak",
    "Total Households": 1194243.185,
    "Total Smart Devices": 6271809.788,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 238848.63700000005,
    "Total Concierge": 5732.367288000001,
    "Susbscription/Concierge": 244581.00428800005,
    "YEAR_1": 14369918.7743136,
    "YEAR_2": 56062636.310337886,
    "YEAR_3": 92493034.65928549,
    "YEAR_4": 128923433.00823304,
    "YEAR_5": 165353831.35718066,
    "YEAR_6": 201784229.7061282,
    "YEAR_7": 238214628.05507576,
    "YEAR_8": 274645026.4040234,
    "YEAR_9": 311075424.75297093,
    "YEAR_10": 347505823.1019185,
    "SUBSCRIPTION_Izak": 1830427986.1294675
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Izak",
    "Total Households": 1184628.618,
    "Total Smart Devices": 6261412.472,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 236925.7236,
    "Total Concierge": 5686.2173664,
    "Susbscription/Concierge": 242611.9409664,
    "YEAR_1": 14254229.986154262,
    "YEAR_2": 55611289.39894447,
    "YEAR_3": 91748395.30112574,
    "YEAR_4": 127885501.20330697,
    "YEAR_5": 164022607.1054882,
    "YEAR_6": 200159713.0076695,
    "YEAR_7": 236296818.90985078,
    "YEAR_8": 272433924.81203204,
    "YEAR_9": 308571030.71421325,
    "YEAR_10": 344708136.6163946,
    "SUBSCRIPTION_Izak": 1815691647.0551796
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Izak",
    "Total Households": 1183233.948,
    "Total Smart Devices": 6219314.93,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 236646.78960000002,
    "Total Concierge": 5679.522950400001,
    "Susbscription/Concierge": 242326.3125504,
    "YEAR_1": 14237448.400235502,
    "YEAR_2": 55545817.91209405,
    "YEAR_3": 91640379.39425808,
    "YEAR_4": 127734940.87642214,
    "YEAR_5": 163829502.3585862,
    "YEAR_6": 199924063.84075025,
    "YEAR_7": 236018625.3229143,
    "YEAR_8": 272113186.8050784,
    "YEAR_9": 308207748.28724235,
    "YEAR_10": 344302309.76940644,
    "SUBSCRIPTION_Izak": 1813554022.9669876
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Izak",
    "Total Households": 975942.5103,
    "Total Smart Devices": 5145201.67,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 195188.50206,
    "Total Concierge": 4684.52404944,
    "Susbscription/Concierge": 199873.02610944,
    "YEAR_1": 11743181.604516095,
    "YEAR_2": 45814714.039793395,
    "YEAR_3": 75585848.48080833,
    "YEAR_4": 105356982.92182326,
    "YEAR_5": 135128117.3628382,
    "YEAR_6": 164899251.80385312,
    "YEAR_7": 194670386.2448681,
    "YEAR_8": 224441520.685883,
    "YEAR_9": 254212655.12689793,
    "YEAR_10": 283983789.5679129,
    "SUBSCRIPTION_Izak": 1495836447.8391945
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Izak",
    "Total Households": 965697.4865,
    "Total Smart Devices": 5080779.563,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 193139.49730000002,
    "Total Concierge": 4635.3479352,
    "Susbscription/Concierge": 197774.84523520002,
    "YEAR_1": 11619906.745847415,
    "YEAR_2": 45333770.9199127,
    "YEAR_3": 74792380.8242032,
    "YEAR_4": 104250990.72849369,
    "YEAR_5": 133709600.63278419,
    "YEAR_6": 163168210.5370747,
    "YEAR_7": 192626820.44136518,
    "YEAR_8": 222085430.34565562,
    "YEAR_9": 251544040.24994612,
    "YEAR_10": 281002650.1542367,
    "SUBSCRIPTION_Izak": 1480133801.5795197
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Izak",
    "Total Households": 943919.5209,
    "Total Smart Devices": 4962123.612,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 188783.90418,
    "Total Concierge": 4530.813700320001,
    "Susbscription/Concierge": 193314.71788032,
    "YEAR_1": 11357859.95280518,
    "YEAR_2": 44311424.56671843,
    "YEAR_3": 73105697.44819587,
    "YEAR_4": 101899970.3296733,
    "YEAR_5": 130694243.21115075,
    "YEAR_6": 159488516.0926282,
    "YEAR_7": 188282788.97410566,
    "YEAR_8": 217077061.85558307,
    "YEAR_9": 245871334.73706052,
    "YEAR_10": 274665607.61853796,
    "SUBSCRIPTION_Izak": 1446754504.7864587
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Izak",
    "Total Households": 883925.362,
    "Total Smart Devices": 4639998.133,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 176785.07239999998,
    "Total Concierge": 4242.8417376,
    "Susbscription/Concierge": 181027.9141376,
    "YEAR_1": 10635970.80899042,
    "YEAR_2": 41495054.539741635,
    "YEAR_3": 68459205.10208935,
    "YEAR_4": 95423355.66443707,
    "YEAR_5": 122387506.2267848,
    "YEAR_6": 149351656.78913254,
    "YEAR_7": 176315807.35148028,
    "YEAR_8": 203279957.913828,
    "YEAR_9": 230244108.47617567,
    "YEAR_10": 257208259.03852338,
    "SUBSCRIPTION_Izak": 1354800881.911183
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Izak",
    "Total Households": 839328.3334,
    "Total Smart Devices": 4419136.81,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 167865.66668000002,
    "Total Concierge": 4028.776000320001,
    "Susbscription/Concierge": 171894.44268032003,
    "YEAR_1": 10099350.054853365,
    "YEAR_2": 39401488.48357567,
    "YEAR_3": 65005206.315400906,
    "YEAR_4": 90608924.14722617,
    "YEAR_5": 116212641.97905143,
    "YEAR_6": 141816359.81087667,
    "YEAR_7": 167420077.64270192,
    "YEAR_8": 193023795.47452715,
    "YEAR_9": 218627513.30635247,
    "YEAR_10": 244231231.1381777,
    "SUBSCRIPTION_Izak": 1286446588.3527434
  },
  {
    "DMA": "Denver",
    "PlatformName": "Izak",
    "Total Households": 817914.2545,
    "Total Smart Devices": 4310338.605,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 163582.8509,
    "Total Concierge": 3925.9884216,
    "Susbscription/Concierge": 167508.83932159998,
    "YEAR_1": 9841681.7857062,
    "YEAR_2": 38396224.45329225,
    "YEAR_3": 63346705.629132085,
    "YEAR_4": 88297186.80497189,
    "YEAR_5": 113247667.98081172,
    "YEAR_6": 138198149.1566515,
    "YEAR_7": 163148630.33249134,
    "YEAR_8": 188099111.50833118,
    "YEAR_9": 213049592.6841709,
    "YEAR_10": 238000073.8600107,
    "SUBSCRIPTION_Izak": 1253625024.1955698
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Izak",
    "Total Households": 809969.2436,
    "Total Smart Devices": 4262896.578,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 161993.84872,
    "Total Concierge": 3887.8523692800004,
    "Susbscription/Concierge": 165881.70108928002,
    "YEAR_1": 9746082.193656586,
    "YEAR_2": 38023253.30121627,
    "YEAR_3": 62731371.85308705,
    "YEAR_4": 87439490.40495786,
    "YEAR_5": 112147608.95682862,
    "YEAR_6": 136855727.50869945,
    "YEAR_7": 161563846.06057024,
    "YEAR_8": 186271964.612441,
    "YEAR_9": 210980083.1643118,
    "YEAR_10": 235688201.71618265,
    "SUBSCRIPTION_Izak": 1241447629.7719514
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Izak",
    "Total Households": 785253.3688,
    "Total Smart Devices": 4133878.496,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 157050.67376000003,
    "Total Concierge": 3769.216170240001,
    "Susbscription/Concierge": 160819.88993024002,
    "YEAR_1": 9448684.546533229,
    "YEAR_2": 36862989.5313173,
    "YEAR_3": 60817150.11565176,
    "YEAR_4": 84771310.69998622,
    "YEAR_5": 108725471.2843207,
    "YEAR_6": 132679631.86865517,
    "YEAR_7": 156633792.4529896,
    "YEAR_8": 180587953.03732404,
    "YEAR_9": 204542113.6216585,
    "YEAR_10": 228496274.20599303,
    "SUBSCRIPTION_Izak": 1203565371.3644295
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Izak",
    "Total Households": 708345.9039,
    "Total Smart Devices": 3728438.627,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 141669.18078000002,
    "Total Concierge": 3400.060338720001,
    "Susbscription/Concierge": 145069.24111872003,
    "YEAR_1": 8523283.390694624,
    "YEAR_2": 33252640.074528247,
    "YEAR_3": 54860737.798713565,
    "YEAR_4": 76468835.52289891,
    "YEAR_5": 98076933.24708423,
    "YEAR_6": 119685030.97126956,
    "YEAR_7": 141293128.6954549,
    "YEAR_8": 162901226.41964027,
    "YEAR_9": 184509324.1438256,
    "YEAR_10": 206117421.86801094,
    "SUBSCRIPTION_Izak": 1085688562.1321208
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Izak",
    "Total Households": 685389.524,
    "Total Smart Devices": 3620542.555,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 137077.90480000002,
    "Total Concierge": 3289.8697152000004,
    "Susbscription/Concierge": 140367.77451520003,
    "YEAR_1": 8247057.142424022,
    "YEAR_2": 32174974.15731755,
    "YEAR_3": 53082787.32061022,
    "YEAR_4": 73990600.4839029,
    "YEAR_5": 94898413.64719558,
    "YEAR_6": 115806226.81048824,
    "YEAR_7": 136714039.9737809,
    "YEAR_8": 157621853.13707358,
    "YEAR_9": 178529666.30036625,
    "YEAR_10": 199437479.4636589,
    "SUBSCRIPTION_Izak": 1050503098.4368181
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Izak",
    "Total Households": 603851.0947,
    "Total Smart Devices": 3181975.805,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 120770.21894000002,
    "Total Concierge": 2898.4852545600006,
    "Susbscription/Concierge": 123668.70419456002,
    "YEAR_1": 7265933.179781429,
    "YEAR_2": 28347228.38109853,
    "YEAR_3": 46767711.076479435,
    "YEAR_4": 65188193.77186034,
    "YEAR_5": 83608676.46724121,
    "YEAR_6": 102029159.16262212,
    "YEAR_7": 120449641.85800302,
    "YEAR_8": 138870124.55338392,
    "YEAR_9": 157290607.24876484,
    "YEAR_10": 175711089.94414574,
    "SUBSCRIPTION_Izak": 925528365.6433806
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Izak",
    "Total Households": 590151.1448,
    "Total Smart Devices": 3098524.034,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 118030.22896,
    "Total Concierge": 2832.72549504,
    "Susbscription/Concierge": 120862.95445503999,
    "YEAR_1": 7101086.3799437815,
    "YEAR_2": 27704096.96669271,
    "YEAR_3": 45706662.57576624,
    "YEAR_4": 63709228.18483977,
    "YEAR_5": 81711793.79391329,
    "YEAR_6": 99714359.40298682,
    "YEAR_7": 117716925.01206034,
    "YEAR_8": 135719490.6211339,
    "YEAR_9": 153722056.23020744,
    "YEAR_10": 171724621.83928096,
    "SUBSCRIPTION_Izak": 904530321.0068253
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Izak",
    "Total Households": 588388.274,
    "Total Smart Devices": 3101259.121,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 117677.6548,
    "Total Concierge": 2824.2637152,
    "Susbscription/Concierge": 120501.9185152,
    "YEAR_1": 7079874.360043824,
    "YEAR_2": 27621340.635516733,
    "YEAR_3": 45570129.85608885,
    "YEAR_4": 63518919.07666096,
    "YEAR_5": 81467708.29723307,
    "YEAR_6": 99416497.51780517,
    "YEAR_7": 117365286.73837732,
    "YEAR_8": 135314075.95894942,
    "YEAR_9": 153262865.17952156,
    "YEAR_10": 171211654.40009364,
    "SUBSCRIPTION_Izak": 901828352.0202906
  },
  {
    "DMA": "St. Louis",
    "PlatformName": "Izak",
    "Total Households": 572704.5714,
    "Total Smart Devices": 3008446.843,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 114540.91428,
    "Total Concierge": 2748.98194272,
    "Susbscription/Concierge": 117289.89622272,
    "YEAR_1": 6891157.744137416,
    "YEAR_2": 26885083.79444186,
    "YEAR_3": 44355441.5359979,
    "YEAR_4": 61825799.27755394,
    "YEAR_5": 79296157.01910996,
    "YEAR_6": 96766514.760666,
    "YEAR_7": 114236872.50222203,
    "YEAR_8": 131707230.24377808,
    "YEAR_9": 149177587.9853341,
    "YEAR_10": 166647945.72689015,
    "SUBSCRIPTION_Izak": 877789790.5901315
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Izak",
    "Total Households": 550874.6034,
    "Total Smart Devices": 2910409.816,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 110174.92068,
    "Total Concierge": 2644.19809632,
    "Susbscription/Concierge": 112819.11877632,
    "YEAR_1": 6628485.224046072,
    "YEAR_2": 25860296.23691411,
    "YEAR_3": 42664730.622010075,
    "YEAR_4": 59469165.00710602,
    "YEAR_5": 76273599.39220197,
    "YEAR_6": 93078033.77729793,
    "YEAR_7": 109882468.16239387,
    "YEAR_8": 126686902.54748984,
    "YEAR_9": 143491336.9325858,
    "YEAR_10": 160295771.31768173,
    "SUBSCRIPTION_Izak": 844330789.2197275
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Izak",
    "Total Households": 536129.9935,
    "Total Smart Devices": 2844384.806,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 107225.9987,
    "Total Concierge": 2573.4239688000002,
    "Susbscription/Concierge": 109799.42266879999,
    "YEAR_1": 6451068.388611551,
    "YEAR_2": 25168124.229785163,
    "YEAR_3": 41522774.16653458,
    "YEAR_4": 57877424.10328399,
    "YEAR_5": 74232074.04003341,
    "YEAR_6": 90586723.97678283,
    "YEAR_7": 106941373.91353223,
    "YEAR_8": 123296023.85028166,
    "YEAR_9": 139650673.78703108,
    "YEAR_10": 156005323.7237805,
    "SUBSCRIPTION_Izak": 821731584.1796571
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Izak",
    "Total Households": 533193.8975,
    "Total Smart Devices": 2813141.762,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 106638.77949999999,
    "Total Concierge": 2559.330708,
    "Susbscription/Concierge": 109198.11020799998,
    "YEAR_1": 6415739.352144337,
    "YEAR_2": 25030291.932069156,
    "YEAR_3": 41295376.23577646,
    "YEAR_4": 57560460.53948377,
    "YEAR_5": 73825544.84319109,
    "YEAR_6": 90090629.14689837,
    "YEAR_7": 106355713.45060568,
    "YEAR_8": 122620797.75431298,
    "YEAR_9": 138885882.0580203,
    "YEAR_10": 155150966.36172763,
    "SUBSCRIPTION_Izak": 817231401.6742297
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Izak",
    "Total Households": 524491.4867,
    "Total Smart Devices": 2761746.07,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 104898.29734000002,
    "Total Concierge": 2517.5591361600004,
    "Susbscription/Concierge": 107415.85647616001,
    "YEAR_1": 6311026.226788124,
    "YEAR_2": 24621765.345665775,
    "YEAR_3": 40621382.535118476,
    "YEAR_4": 56620999.72457117,
    "YEAR_5": 72620616.91402388,
    "YEAR_6": 88620234.10347657,
    "YEAR_7": 104619851.29292928,
    "YEAR_8": 120619468.48238197,
    "YEAR_9": 136619085.67183468,
    "YEAR_10": 152618702.86128736,
    "SUBSCRIPTION_Izak": 803893133.1580774
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Izak",
    "Total Households": 523914.9663,
    "Total Smart Devices": 2802506.923,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 104782.99326,
    "Total Concierge": 2514.7918382400003,
    "Susbscription/Concierge": 107297.78509824,
    "YEAR_1": 6304089.154486777,
    "YEAR_2": 24594701.131344385,
    "YEAR_3": 40576731.561172165,
    "YEAR_4": 56558761.99099994,
    "YEAR_5": 72540792.4208277,
    "YEAR_6": 88522822.8506555,
    "YEAR_7": 104504853.28048325,
    "YEAR_8": 120486883.71031107,
    "YEAR_9": 136468914.1401388,
    "YEAR_10": 152450944.5699666,
    "SUBSCRIPTION_Izak": 803009494.8103862
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Izak",
    "Total Households": 505132.9124,
    "Total Smart Devices": 2679594.179,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 101026.58248,
    "Total Concierge": 2424.63797952,
    "Susbscription/Concierge": 103451.22045952,
    "YEAR_1": 6078091.139720814,
    "YEAR_2": 23712995.06830593,
    "YEAR_3": 39122078.786791645,
    "YEAR_4": 54531162.50527738,
    "YEAR_5": 69940246.22376311,
    "YEAR_6": 85349329.94224882,
    "YEAR_7": 100758413.66073456,
    "YEAR_8": 116167497.3792203,
    "YEAR_9": 131576581.09770603,
    "YEAR_10": 146985664.81619173,
    "SUBSCRIPTION_Izak": 774222060.6199603
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Izak",
    "Total Households": 483472.013,
    "Total Smart Devices": 2564533.659,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 96694.4026,
    "Total Concierge": 2320.6656624,
    "Susbscription/Concierge": 99015.0682624,
    "YEAR_1": 5817452.96412463,
    "YEAR_2": 22696144.27906151,
    "YEAR_3": 37444462.0801445,
    "YEAR_4": 52192779.88122752,
    "YEAR_5": 66941097.68231052,
    "YEAR_6": 81689415.48339352,
    "YEAR_7": 96437733.28447653,
    "YEAR_8": 111186051.08555949,
    "YEAR_9": 125934368.88664253,
    "YEAR_10": 140682686.68772554,
    "SUBSCRIPTION_Izak": 741022192.3146663
  },
  {
    "DMA": "Columbus",
    "PlatformName": "Izak",
    "Total Households": 467063.8491,
    "Total Smart Devices": 2468934.437,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 93412.76982000002,
    "Total Concierge": 2241.9064756800003,
    "Susbscription/Concierge": 95654.67629568002,
    "YEAR_1": 5620019.153791752,
    "YEAR_2": 21925878.275620922,
    "YEAR_3": 36173664.89967082,
    "YEAR_4": 50421451.52372071,
    "YEAR_5": 64669238.14777061,
    "YEAR_6": 78917024.7718205,
    "YEAR_7": 93164811.39587039,
    "YEAR_8": 107412598.01992029,
    "YEAR_9": 121660384.64397019,
    "YEAR_10": 135908171.2680201,
    "SUBSCRIPTION_Izak": 715873242.1001763
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Izak",
    "Total Households": 465244.9759,
    "Total Smart Devices": 2488288.448,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 93048.99518000001,
    "Total Concierge": 2233.1758843200005,
    "Susbscription/Concierge": 95282.17106432002,
    "YEAR_1": 5598133.276214165,
    "YEAR_2": 21840492.96382932,
    "YEAR_3": 36032794.845697306,
    "YEAR_4": 50225096.727565296,
    "YEAR_5": 64417398.60943328,
    "YEAR_6": 78609700.49130125,
    "YEAR_7": 92802002.37316926,
    "YEAR_8": 106994304.25503725,
    "YEAR_9": 121186606.13690522,
    "YEAR_10": 135378908.0187732,
    "SUBSCRIPTION_Izak": 713085437.6979256
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Izak",
    "Total Households": 462778.7076,
    "Total Smart Devices": 2468450.947,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 92555.74152000001,
    "Total Concierge": 2221.3377964800006,
    "Susbscription/Concierge": 94777.07931648001,
    "YEAR_1": 5568457.515370978,
    "YEAR_2": 21724716.29080052,
    "YEAR_3": 35841784.637545265,
    "YEAR_4": 49958852.98429002,
    "YEAR_5": 64075921.33103476,
    "YEAR_6": 78192989.6777795,
    "YEAR_7": 92310058.02452426,
    "YEAR_8": 106427126.37126902,
    "YEAR_9": 120544194.71801375,
    "YEAR_10": 134661263.0647585,
    "SUBSCRIPTION_Izak": 709305364.6153866
  },
  {
    "DMA": "Austin",
    "PlatformName": "Izak",
    "Total Households": 446505.4231,
    "Total Smart Devices": 2371566.242,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 89301.08462000001,
    "Total Concierge": 2143.22603088,
    "Susbscription/Concierge": 91444.31065088001,
    "YEAR_1": 5372646.662612128,
    "YEAR_2": 20960782.075426992,
    "YEAR_3": 34581433.742363974,
    "YEAR_4": 48202085.40930096,
    "YEAR_5": 61822737.07623795,
    "YEAR_6": 75443388.74317494,
    "YEAR_7": 89064040.41011192,
    "YEAR_8": 102684692.07704888,
    "YEAR_9": 116305343.74398586,
    "YEAR_10": 129925995.41092286,
    "SUBSCRIPTION_Izak": 684363145.3511865
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Izak",
    "Total Households": 435280.4295,
    "Total Smart Devices": 2303023.424,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 87056.0859,
    "Total Concierge": 2089.3460616,
    "Susbscription/Concierge": 89145.4319616,
    "YEAR_1": 5237580.163342809,
    "YEAR_2": 20433835.184134763,
    "YEAR_3": 33712068.327400304,
    "YEAR_4": 46990301.47066584,
    "YEAR_5": 60268534.613931365,
    "YEAR_6": 73546767.75719689,
    "YEAR_7": 86825000.90046242,
    "YEAR_8": 100103234.04372798,
    "YEAR_9": 113381467.18699348,
    "YEAR_10": 126659700.33025903,
    "SUBSCRIPTION_Izak": 667158489.9781148
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Izak",
    "Total Households": 432293.6432,
    "Total Smart Devices": 2307327.852,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 86458.72864,
    "Total Concierge": 2075.00948736,
    "Susbscription/Concierge": 88533.73812736,
    "YEAR_1": 5201641.187875905,
    "YEAR_2": 20293623.277400214,
    "YEAR_3": 33480744.479598086,
    "YEAR_4": 46667865.68179596,
    "YEAR_5": 59854986.88399383,
    "YEAR_6": 73042108.0861917,
    "YEAR_7": 86229229.28838956,
    "YEAR_8": 99416350.49058744,
    "YEAR_9": 112603471.69278532,
    "YEAR_10": 125790592.89498319,
    "SUBSCRIPTION_Izak": 662580613.9636012
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Izak",
    "Total Households": 410759.7487,
    "Total Smart Devices": 2174029.485,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 82151.94974,
    "Total Concierge": 1971.6467937599998,
    "Susbscription/Concierge": 84123.59653375999,
    "YEAR_1": 4942531.218695181,
    "YEAR_2": 19282734.615139443,
    "YEAR_3": 31812964.18547155,
    "YEAR_4": 44343193.75580366,
    "YEAR_5": 56873423.32613578,
    "YEAR_6": 69403652.89646788,
    "YEAR_7": 81933882.4668,
    "YEAR_8": 94464112.0371321,
    "YEAR_9": 106994341.60746421,
    "YEAR_10": 119524571.1777963,
    "SUBSCRIPTION_Izak": 629575407.2869061
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Izak",
    "Total Households": 405294.1314,
    "Total Smart Devices": 2150472.593,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 81058.82628000001,
    "Total Concierge": 1945.4118307200004,
    "Susbscription/Concierge": 83004.23811072,
    "YEAR_1": 4876765.319723371,
    "YEAR_2": 19026156.291101206,
    "YEAR_3": 31389657.159973823,
    "YEAR_4": 43753158.028846435,
    "YEAR_5": 56116658.897719055,
    "YEAR_6": 68480159.76659167,
    "YEAR_7": 80843660.6354643,
    "YEAR_8": 93207161.5043369,
    "YEAR_9": 105570662.37320949,
    "YEAR_10": 117934163.24208212,
    "SUBSCRIPTION_Izak": 621198203.2190483
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Izak",
    "Total Households": 397095.0494,
    "Total Smart Devices": 2107672.982,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 79419.00988,
    "Total Concierge": 1906.05623712,
    "Susbscription/Concierge": 81325.06611712,
    "YEAR_1": 4778108.577240947,
    "YEAR_2": 18641258.007386375,
    "YEAR_3": 30754645.811259013,
    "YEAR_4": 42868033.615131654,
    "YEAR_5": 54981421.41900429,
    "YEAR_6": 67094809.22287693,
    "YEAR_7": 79208197.02674958,
    "YEAR_8": 91321584.8306222,
    "YEAR_9": 103434972.63449487,
    "YEAR_10": 115548360.43836753,
    "SUBSCRIPTION_Izak": 608631391.5831333
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Izak",
    "Total Households": 360748.9799,
    "Total Smart Devices": 1917944.712,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 72149.79598,
    "Total Concierge": 1731.59510352,
    "Susbscription/Concierge": 73881.39108352,
    "YEAR_1": 4340768.784943486,
    "YEAR_2": 16935025.557176687,
    "YEAR_3": 27939676.206896305,
    "YEAR_4": 38944326.85661593,
    "YEAR_5": 49948977.50633556,
    "YEAR_6": 60953628.15605517,
    "YEAR_7": 71958278.80577478,
    "YEAR_8": 82962929.45549442,
    "YEAR_9": 93967580.10521406,
    "YEAR_10": 104972230.75493366,
    "SUBSCRIPTION_Izak": 552923422.18944
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Izak",
    "Total Households": 356382.6492,
    "Total Smart Devices": 1886199.232,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 71276.52983999999,
    "Total Concierge": 1710.6367161599999,
    "Susbscription/Concierge": 72987.16655616,
    "YEAR_1": 4288230.2247164985,
    "YEAR_2": 16730052.220824962,
    "YEAR_3": 27601507.91601425,
    "YEAR_4": 38472963.611203544,
    "YEAR_5": 49344419.306392826,
    "YEAR_6": 60215875.00158211,
    "YEAR_7": 71087330.6967714,
    "YEAR_8": 81958786.39196068,
    "YEAR_9": 92830242.08714998,
    "YEAR_10": 103701697.78233926,
    "SUBSCRIPTION_Izak": 546231105.2389555
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Izak",
    "Total Households": 352428.5811,
    "Total Smart Devices": 1885454.401,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 70485.71622,
    "Total Concierge": 1691.65718928,
    "Susbscription/Concierge": 72177.37340928,
    "YEAR_1": 4240652.279002615,
    "YEAR_2": 16544432.169045802,
    "YEAR_3": 27295268.983766556,
    "YEAR_4": 38046105.79848732,
    "YEAR_5": 48796942.61320807,
    "YEAR_6": 59547779.42792883,
    "YEAR_7": 70298616.24264959,
    "YEAR_8": 81049453.05737035,
    "YEAR_9": 91800289.87209111,
    "YEAR_10": 102551126.68681188,
    "SUBSCRIPTION_Izak": 540170667.1303622
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Izak",
    "Total Households": 352346.2667,
    "Total Smart Devices": 1862144.776,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 70469.25334,
    "Total Concierge": 1691.2620801599999,
    "Susbscription/Concierge": 72160.51542016,
    "YEAR_1": 4239661.818050597,
    "YEAR_2": 16540567.996046308,
    "YEAR_3": 27288893.809306454,
    "YEAR_4": 38037219.62256661,
    "YEAR_5": 48785545.43582676,
    "YEAR_6": 59533871.24908693,
    "YEAR_7": 70282197.06234705,
    "YEAR_8": 81030522.87560722,
    "YEAR_9": 91778848.68886738,
    "YEAR_10": 102527174.5021275,
    "SUBSCRIPTION_Izak": 540044503.0598328
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Izak",
    "Total Households": 349621.3646,
    "Total Smart Devices": 1846925.874,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 69924.27292,
    "Total Concierge": 1678.1825500800003,
    "Susbscription/Concierge": 71602.45547008,
    "YEAR_1": 4206874.00536992,
    "YEAR_2": 16412649.999668065,
    "YEAR_3": 27077852.65157236,
    "YEAR_4": 37743055.30347667,
    "YEAR_5": 48408257.955380954,
    "YEAR_6": 59073460.60728526,
    "YEAR_7": 69738663.25918956,
    "YEAR_8": 80403865.91109388,
    "YEAR_9": 91069068.56299815,
    "YEAR_10": 101734271.21490246,
    "SUBSCRIPTION_Izak": 535868019.4709373
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Izak",
    "Total Households": 339203.0215,
    "Total Smart Devices": 1813431.99,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 67840.60429999999,
    "Total Concierge": 1628.1745032,
    "Susbscription/Concierge": 69468.7788032,
    "YEAR_1": 4081513.654990419,
    "YEAR_2": 15923570.56634342,
    "YEAR_3": 26270961.57482686,
    "YEAR_4": 36618352.5833103,
    "YEAR_5": 46965743.59179374,
    "YEAR_6": 57313134.600277185,
    "YEAR_7": 67660525.60876061,
    "YEAR_8": 78007916.61724406,
    "YEAR_9": 88355307.62572749,
    "YEAR_10": 98702698.63421094,
    "SUBSCRIPTION_Izak": 519899725.05748504
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Izak",
    "Total Households": 337652.4085,
    "Total Smart Devices": 1792405.574,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 67530.4817,
    "Total Concierge": 1620.7315608000001,
    "Susbscription/Concierge": 69151.21326080001,
    "YEAR_1": 4062855.6604209174,
    "YEAR_2": 15850778.480301848,
    "YEAR_3": 26150868.02624853,
    "YEAR_4": 36450957.57219522,
    "YEAR_5": 46751047.118141904,
    "YEAR_6": 57051136.66408858,
    "YEAR_7": 67351226.21003526,
    "YEAR_8": 77651315.75598194,
    "YEAR_9": 87951405.30192862,
    "YEAR_10": 98251494.84787533,
    "SUBSCRIPTION_Izak": 517523085.6372181
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Izak",
    "Total Households": 329032.0001,
    "Total Smart Devices": 1750390.958,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 65806.40002,
    "Total Concierge": 1579.35360048,
    "Susbscription/Concierge": 67385.75362048,
    "YEAR_1": 3959129.2418276966,
    "YEAR_2": 15446101.420347948,
    "YEAR_3": 25483225.335938018,
    "YEAR_4": 35520349.25152809,
    "YEAR_5": 45557473.16711817,
    "YEAR_6": 55594597.08270824,
    "YEAR_7": 65631720.99829831,
    "YEAR_8": 75668844.91388838,
    "YEAR_9": 85705968.82947844,
    "YEAR_10": 95743092.74506854,
    "SUBSCRIPTION_Izak": 504310502.9862018
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Izak",
    "Total Households": 328886.9953,
    "Total Smart Devices": 1750628.806,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 65777.39906000001,
    "Total Concierge": 1578.6575774400003,
    "Susbscription/Concierge": 67356.05663744001,
    "YEAR_1": 3957384.4487871695,
    "YEAR_2": 15439294.30478911,
    "YEAR_3": 25471994.847742133,
    "YEAR_4": 35504695.390695155,
    "YEAR_5": 45537395.93364818,
    "YEAR_6": 55570096.47660121,
    "YEAR_7": 65602797.01955423,
    "YEAR_8": 75635497.56250724,
    "YEAR_9": 85668198.10546029,
    "YEAR_10": 95700898.6484133,
    "SUBSCRIPTION_Izak": 504088252.73819804
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Izak",
    "Total Households": 313540.2409,
    "Total Smart Devices": 1672507.553,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 62708.04818,
    "Total Concierge": 1504.99315632,
    "Susbscription/Concierge": 64213.04133632,
    "YEAR_1": 3772722.215041753,
    "YEAR_2": 14718855.183781033,
    "YEAR_3": 24283402.855377737,
    "YEAR_4": 33847950.52697444,
    "YEAR_5": 43412498.198571146,
    "YEAR_6": 52977045.87016786,
    "YEAR_7": 62541593.541764565,
    "YEAR_8": 72106141.21336128,
    "YEAR_9": 81670688.88495798,
    "YEAR_10": 91235236.55655468,
    "SUBSCRIPTION_Izak": 480566135.0465524
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Izak",
    "Total Households": 302974.3303,
    "Total Smart Devices": 1641227.699,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 60594.86606,
    "Total Concierge": 1454.2767854400001,
    "Susbscription/Concierge": 62049.14284544,
    "YEAR_1": 3645586.2355313,
    "YEAR_2": 14222848.331327997,
    "YEAR_3": 23465082.81168186,
    "YEAR_4": 32707317.292035725,
    "YEAR_5": 41949551.77238959,
    "YEAR_6": 51191786.252743445,
    "YEAR_7": 60434020.73309731,
    "YEAR_8": 69676255.21345118,
    "YEAR_9": 78918489.69380504,
    "YEAR_10": 88160724.1741589,
    "SUBSCRIPTION_Izak": 464371662.5102223
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Izak",
    "Total Households": 294028.9123,
    "Total Smart Devices": 1564688.779,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 58805.78246,
    "Total Concierge": 1411.3387790400002,
    "Susbscription/Concierge": 60217.12123904,
    "YEAR_1": 3537949.0878574927,
    "YEAR_2": 13802913.997787757,
    "YEAR_3": 22772268.427218117,
    "YEAR_4": 31741622.856648482,
    "YEAR_5": 40710977.28607884,
    "YEAR_6": 49680331.7155092,
    "YEAR_7": 58649686.144939564,
    "YEAR_8": 67619040.57436992,
    "YEAR_9": 76588395.00380029,
    "YEAR_10": 85557749.43323064,
    "SUBSCRIPTION_Izak": 450660934.5274403
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Izak",
    "Total Households": 289369.7588,
    "Total Smart Devices": 1553299.908,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 57873.95176,
    "Total Concierge": 1388.9748422400003,
    "Susbscription/Concierge": 59262.926602240004,
    "YEAR_1": 3481887.0912784133,
    "YEAR_2": 13584194.367259124,
    "YEAR_3": 22411421.28019552,
    "YEAR_4": 31238648.193131916,
    "YEAR_5": 40065875.106068306,
    "YEAR_6": 48893102.0190047,
    "YEAR_7": 57720328.93194109,
    "YEAR_8": 66547555.8448775,
    "YEAR_9": 75374782.7578139,
    "YEAR_10": 84202009.67075029,
    "SUBSCRIPTION_Izak": 443519805.26232076
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Izak",
    "Total Households": 288436.6661,
    "Total Smart Devices": 1532512.639,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 57687.33322,
    "Total Concierge": 1384.49599728,
    "Susbscription/Concierge": 59071.82921728,
    "YEAR_1": 3470659.5067493003,
    "YEAR_2": 13540391.197736386,
    "YEAR_3": 22339154.110053428,
    "YEAR_4": 31137917.02237047,
    "YEAR_5": 39936679.93468752,
    "YEAR_6": 48735442.847004555,
    "YEAR_7": 57534205.75932161,
    "YEAR_8": 66332968.67163865,
    "YEAR_9": 75131731.5839557,
    "YEAR_10": 83930494.49627274,
    "SUBSCRIPTION_Izak": 442089645.12979037
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Izak",
    "Total Households": 282061.5188,
    "Total Smart Devices": 1507192.296,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 56412.30376,
    "Total Concierge": 1353.8952902400001,
    "Susbscription/Concierge": 57766.19905024,
    "YEAR_1": 3393949.54513852,
    "YEAR_2": 13241115.83322615,
    "YEAR_3": 21845404.82382497,
    "YEAR_4": 30449693.814423792,
    "YEAR_5": 39053982.805022605,
    "YEAR_6": 47658271.79562141,
    "YEAR_7": 56262560.786220215,
    "YEAR_8": 64866849.77681905,
    "YEAR_9": 73471138.76741788,
    "YEAR_10": 82075427.75801668,
    "SUBSCRIPTION_Izak": 432318395.7057313
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Izak",
    "Total Households": 277518.8074,
    "Total Smart Devices": 1471541.76,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 55503.76148,
    "Total Concierge": 1332.0902755200002,
    "Susbscription/Concierge": 56835.85175552,
    "YEAR_1": 3339288.656424177,
    "YEAR_2": 13027862.468852945,
    "YEAR_3": 21493576.0101924,
    "YEAR_4": 29959289.55153185,
    "YEAR_5": 38425003.09287131,
    "YEAR_6": 46890716.634210765,
    "YEAR_7": 55356430.17555022,
    "YEAR_8": 63822143.71688967,
    "YEAR_9": 72287857.25822912,
    "YEAR_10": 80753570.79956858,
    "SUBSCRIPTION_Izak": 425355738.36432105
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Izak",
    "Total Households": 269420.2905,
    "Total Smart Devices": 1452147.042,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 53884.05810000001,
    "Total Concierge": 1293.2173944000003,
    "Susbscription/Concierge": 55177.27549440001,
    "YEAR_1": 3241841.979309243,
    "YEAR_2": 12647685.120285682,
    "YEAR_3": 20866353.335841943,
    "YEAR_4": 29085021.551398203,
    "YEAR_5": 37303689.766954474,
    "YEAR_6": 45522357.98251073,
    "YEAR_7": 53741026.198067,
    "YEAR_8": 61959694.41362326,
    "YEAR_9": 70178362.62917952,
    "YEAR_10": 78397030.84473577,
    "SUBSCRIPTION_Izak": 412943063.82190585
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Izak",
    "Total Households": 268637.3894,
    "Total Smart Devices": 1459002.839,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 53727.47788,
    "Total Concierge": 1289.45946912,
    "Susbscription/Concierge": 55016.937349119995,
    "YEAR_1": 3232421.5988066555,
    "YEAR_2": 12610932.55582682,
    "YEAR_3": 20805718.366778173,
    "YEAR_4": 29000504.17772952,
    "YEAR_5": 37195289.98868087,
    "YEAR_6": 45390075.799632214,
    "YEAR_7": 53584861.61058356,
    "YEAR_8": 61779647.42153491,
    "YEAR_9": 69974433.23248625,
    "YEAR_10": 78169219.0434376,
    "SUBSCRIPTION_Izak": 411743103.7954966
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Izak",
    "Total Households": 264094.9286,
    "Total Smart Devices": 1405447.572,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 52818.98572,
    "Total Concierge": 1267.65565728,
    "Susbscription/Concierge": 54086.64137728,
    "YEAR_1": 3177763.7254761886,
    "YEAR_2": 12397690.95563769,
    "YEAR_3": 20453908.961884774,
    "YEAR_4": 28510126.968131863,
    "YEAR_5": 36566344.97437895,
    "YEAR_6": 44622562.980626024,
    "YEAR_7": 52678780.98687311,
    "YEAR_8": 60734998.99312019,
    "YEAR_9": 68791216.99936728,
    "YEAR_10": 76847435.00561436,
    "SUBSCRIPTION_Izak": 404780830.55111045
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Izak",
    "Total Households": 263354.2167,
    "Total Smart Devices": 1404565.359,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 52670.84334,
    "Total Concierge": 1264.10024016,
    "Susbscription/Concierge": 53934.94358016,
    "YEAR_1": 3168850.9931517686,
    "YEAR_2": 12362918.923959367,
    "YEAR_3": 20396541.507500473,
    "YEAR_4": 28430164.09104158,
    "YEAR_5": 36463786.67458269,
    "YEAR_6": 44497409.25812379,
    "YEAR_7": 52531031.8416649,
    "YEAR_8": 60564654.42520599,
    "YEAR_9": 68598277.00874712,
    "YEAR_10": 76631899.59228823,
    "SUBSCRIPTION_Izak": 403645534.31626594
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Izak",
    "Total Households": 255378.7046,
    "Total Smart Devices": 1362932.794,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 51075.740920000004,
    "Total Concierge": 1225.8177820800001,
    "Susbscription/Concierge": 52301.55870208,
    "YEAR_1": 3072884.390620514,
    "YEAR_2": 11988515.921399219,
    "YEAR_3": 19778845.441610135,
    "YEAR_4": 27569174.961821057,
    "YEAR_5": 35359504.48203198,
    "YEAR_6": 43149834.002242886,
    "YEAR_7": 50940163.52245381,
    "YEAR_8": 58730493.04266472,
    "YEAR_9": 66520822.56287564,
    "YEAR_10": 74311152.08308657,
    "SUBSCRIPTION_Izak": 391421390.4108065
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Izak",
    "Total Households": 248118.8406,
    "Total Smart Devices": 1337968.086,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 49623.76812,
    "Total Concierge": 1190.97043488,
    "Susbscription/Concierge": 50814.73855488,
    "YEAR_1": 2985528.936301917,
    "YEAR_2": 11647708.353722358,
    "YEAR_3": 19216575.661880393,
    "YEAR_4": 26785442.97003842,
    "YEAR_5": 34354310.27819646,
    "YEAR_6": 41923177.58635449,
    "YEAR_7": 49492044.89451253,
    "YEAR_8": 57060912.20267056,
    "YEAR_9": 64629779.51082859,
    "YEAR_10": 72198646.81898662,
    "SUBSCRIPTION_Izak": 380294127.2134924
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Izak",
    "Total Households": 233138.7861,
    "Total Smart Devices": 1249823.665,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 46627.75722,
    "Total Concierge": 1119.06617328,
    "Susbscription/Concierge": 47746.82339328,
    "YEAR_1": 2805279.076722613,
    "YEAR_2": 10944483.618684378,
    "YEAR_3": 18056384.239003245,
    "YEAR_4": 25168284.859322116,
    "YEAR_5": 32280185.479640983,
    "YEAR_6": 39392086.09995985,
    "YEAR_7": 46503986.720278725,
    "YEAR_8": 53615887.34059759,
    "YEAR_9": 60727787.96091646,
    "YEAR_10": 67839688.58123533,
    "SUBSCRIPTION_Izak": 357334053.97636133
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Izak",
    "Total Households": 224431.25,
    "Total Smart Devices": 1202380.339,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 44886.25,
    "Total Concierge": 1077.27,
    "Susbscription/Concierge": 45963.52,
    "YEAR_1": 2700504.280388813,
    "YEAR_2": 10535716.429836288,
    "YEAR_3": 17381993.58858117,
    "YEAR_4": 24228270.747326054,
    "YEAR_5": 31074547.90607094,
    "YEAR_6": 37920825.06481582,
    "YEAR_7": 44767102.223560706,
    "YEAR_8": 51613379.382305585,
    "YEAR_9": 58459656.541050464,
    "YEAR_10": 65305933.69979536,
    "SUBSCRIPTION_Izak": 343987929.8637312
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Izak",
    "Total Households": 223876.3561,
    "Total Smart Devices": 1219379.365,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 44775.27122,
    "Total Concierge": 1074.6065092800002,
    "Susbscription/Concierge": 45849.87772928,
    "YEAR_1": 2693827.4323468776,
    "YEAR_2": 10509667.45145629,
    "YEAR_3": 17339017.56713967,
    "YEAR_4": 24168367.68282305,
    "YEAR_5": 30997717.79850643,
    "YEAR_6": 37827067.914189816,
    "YEAR_7": 44656418.02987321,
    "YEAR_8": 51485768.14555657,
    "YEAR_9": 58315118.26123996,
    "YEAR_10": 65144468.376923345,
    "SUBSCRIPTION_Izak": 343137438.66005516
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Izak",
    "Total Households": 220999.1372,
    "Total Smart Devices": 1183469.762,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 44199.82744,
    "Total Concierge": 1060.79585856,
    "Susbscription/Concierge": 45260.62329856,
    "YEAR_1": 2659206.8438367406,
    "YEAR_2": 10374599.084475463,
    "YEAR_3": 17116179.613544773,
    "YEAR_4": 23857760.142614085,
    "YEAR_5": 30599340.67168339,
    "YEAR_6": 37340921.2007527,
    "YEAR_7": 44082501.72982202,
    "YEAR_8": 50824082.25889132,
    "YEAR_9": 57565662.78796064,
    "YEAR_10": 64307243.317029946,
    "SUBSCRIPTION_Izak": 338727497.65061104
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Izak",
    "Total Households": 219918.6638,
    "Total Smart Devices": 1187777.437,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 43983.732760000006,
    "Total Concierge": 1055.6095862400002,
    "Susbscription/Concierge": 45039.34234624001,
    "YEAR_1": 2646205.877877027,
    "YEAR_2": 10323877.264976708,
    "YEAR_3": 17032497.94394025,
    "YEAR_4": 23741118.62290379,
    "YEAR_5": 30449739.301867314,
    "YEAR_6": 37158359.98083085,
    "YEAR_7": 43866980.65979438,
    "YEAR_8": 50575601.338757925,
    "YEAR_9": 57284222.01772147,
    "YEAR_10": 63992842.69668501,
    "SUBSCRIPTION_Izak": 337071445.70535475
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Izak",
    "Total Households": 215517.5199,
    "Total Smart Devices": 1143912.87,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 43103.50398,
    "Total Concierge": 1034.48409552,
    "Susbscription/Concierge": 44137.98807552,
    "YEAR_1": 2593248.4223508583,
    "YEAR_2": 10117269.655308692,
    "YEAR_3": 16691633.40278466,
    "YEAR_4": 23265997.150260627,
    "YEAR_5": 29840360.897736594,
    "YEAR_6": 36414724.64521256,
    "YEAR_7": 42989088.39268853,
    "YEAR_8": 49563452.140164495,
    "YEAR_9": 56137815.88764047,
    "YEAR_10": 62712179.63511644,
    "SUBSCRIPTION_Izak": 330325770.22926396
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Izak",
    "Total Households": 215017.5031,
    "Total Smart Devices": 1152245.338,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 43003.50062,
    "Total Concierge": 1032.08401488,
    "Susbscription/Concierge": 44035.58463488,
    "YEAR_1": 2587231.8916374827,
    "YEAR_2": 10093796.831381747,
    "YEAR_3": 16652907.562190792,
    "YEAR_4": 23212018.292999838,
    "YEAR_5": 29771129.023808878,
    "YEAR_6": 36330239.75461792,
    "YEAR_7": 42889350.48542696,
    "YEAR_8": 49448461.216236,
    "YEAR_9": 56007571.94704505,
    "YEAR_10": 62566682.6778541,
    "SUBSCRIPTION_Izak": 329559389.6831988
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Izak",
    "Total Households": 208836.9871,
    "Total Smart Devices": 1130925.32,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 41767.39742,
    "Total Concierge": 1002.41753808,
    "Susbscription/Concierge": 42769.81495808,
    "YEAR_1": 2512863.8617262673,
    "YEAR_2": 9803658.252346672,
    "YEAR_3": 16174232.290872188,
    "YEAR_4": 22544806.329397704,
    "YEAR_5": 28915380.367923222,
    "YEAR_6": 35285954.40644874,
    "YEAR_7": 41656528.44497425,
    "YEAR_8": 48027102.483499765,
    "YEAR_9": 54397676.52202529,
    "YEAR_10": 60768250.56055081,
    "SUBSCRIPTION_Izak": 320086453.5197649
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Izak",
    "Total Households": 208257.7812,
    "Total Smart Devices": 1109995.797,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 41651.55624,
    "Total Concierge": 999.6373497600001,
    "Susbscription/Concierge": 42651.19358976,
    "YEAR_1": 2505894.4757242,
    "YEAR_2": 9776467.969723871,
    "YEAR_3": 16129373.327424502,
    "YEAR_4": 22482278.685125135,
    "YEAR_5": 28835184.04282576,
    "YEAR_6": 35188089.40052639,
    "YEAR_7": 41540994.75822702,
    "YEAR_8": 47893900.11592765,
    "YEAR_9": 54246805.47362828,
    "YEAR_10": 60599710.83132891,
    "SUBSCRIPTION_Izak": 319198699.08046174
  },
  {
    "DMA": "Madison",
    "PlatformName": "Izak",
    "Total Households": 207493.6933,
    "Total Smart Devices": 1112970.681,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 41498.73866,
    "Total Concierge": 995.96972784,
    "Susbscription/Concierge": 42494.70838784,
    "YEAR_1": 2496700.4680067217,
    "YEAR_2": 9740598.573452769,
    "YEAR_3": 16070195.423371874,
    "YEAR_4": 22399792.27329097,
    "YEAR_5": 28729389.123210073,
    "YEAR_6": 35058985.973129176,
    "YEAR_7": 41388582.82304827,
    "YEAR_8": 47718179.672967374,
    "YEAR_9": 54047776.52288647,
    "YEAR_10": 60377373.37280557,
    "SUBSCRIPTION_Izak": 318027574.2261693
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Izak",
    "Total Households": 206161.9622,
    "Total Smart Devices": 1102039.859,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 41232.39244,
    "Total Concierge": 989.5774185600002,
    "Susbscription/Concierge": 42221.969858560005,
    "YEAR_1": 2480676.204291767,
    "YEAR_2": 9678081.69476322,
    "YEAR_3": 15967054.076336134,
    "YEAR_4": 22256026.457909044,
    "YEAR_5": 28544998.83948196,
    "YEAR_6": 34833971.221054874,
    "YEAR_7": 41122943.602627784,
    "YEAR_8": 47411915.98420071,
    "YEAR_9": 53700888.3657736,
    "YEAR_10": 59989860.74734652,
    "SUBSCRIPTION_Izak": 315986417.1937856
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Izak",
    "Total Households": 201717.9411,
    "Total Smart Devices": 1078841.85,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 40343.58822,
    "Total Concierge": 968.2461172799999,
    "Susbscription/Concierge": 41311.83433728,
    "YEAR_1": 2427202.822119328,
    "YEAR_2": 9469461.254794147,
    "YEAR_3": 15622868.735534798,
    "YEAR_4": 21776276.216275454,
    "YEAR_5": 27929683.69701611,
    "YEAR_6": 34083091.177756764,
    "YEAR_7": 40236498.658497415,
    "YEAR_8": 46389906.13923807,
    "YEAR_9": 52543313.61997872,
    "YEAR_10": 58696721.10071937,
    "SUBSCRIPTION_Izak": 309175023.4219302
  },
  {
    "DMA": "Springfield",
    "PlatformName": "Izak",
    "Total Households": 199105.668,
    "Total Smart Devices": 1058242.196,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 39821.1336,
    "Total Concierge": 955.7072064,
    "Susbscription/Concierge": 40776.8408064,
    "YEAR_1": 2395770.2355784853,
    "YEAR_2": 9346830.522135977,
    "YEAR_3": 15420550.590107981,
    "YEAR_4": 21494270.658079986,
    "YEAR_5": 27567990.726051994,
    "YEAR_6": 33641710.794024,
    "YEAR_7": 39715430.86199601,
    "YEAR_8": 45789150.929968014,
    "YEAR_9": 51862870.99794002,
    "YEAR_10": 57936591.06591203,
    "SUBSCRIPTION_Izak": 305171167.38179445
  },
  {
    "DMA": "Columbia",
    "PlatformName": "Izak",
    "Total Households": 198756.344,
    "Total Smart Devices": 1058408.268,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 39751.268800000005,
    "Total Concierge": 954.0304512000002,
    "Susbscription/Concierge": 40705.299251200006,
    "YEAR_1": 2391566.9396593897,
    "YEAR_2": 9330431.831641065,
    "YEAR_3": 15393495.768070776,
    "YEAR_4": 21456559.704500485,
    "YEAR_5": 27519623.640930198,
    "YEAR_6": 33582687.577359915,
    "YEAR_7": 39645751.513789624,
    "YEAR_8": 45708815.45021933,
    "YEAR_9": 51771879.38664904,
    "YEAR_10": 57834943.32307875,
    "SUBSCRIPTION_Izak": 304635755.1358986
  },
  {
    "DMA": "Rochester",
    "PlatformName": "Izak",
    "Total Households": 198132.6983,
    "Total Smart Devices": 1061121.486,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 39626.53966,
    "Total Concierge": 951.0369518400001,
    "Susbscription/Concierge": 40577.57661184,
    "YEAR_1": 2384062.8247810197,
    "YEAR_2": 9301155.363912586,
    "YEAR_3": 15345194.983046647,
    "YEAR_4": 21389234.602180704,
    "YEAR_5": 27433274.22131477,
    "YEAR_6": 33477313.84044883,
    "YEAR_7": 39521353.459582895,
    "YEAR_8": 45565393.07871695,
    "YEAR_9": 51609432.69785102,
    "YEAR_10": 57653472.31698507,
    "SUBSCRIPTION_Izak": 303679887.38882047
  },
  {
    "DMA": "Portland",
    "PlatformName": "Izak",
    "Total Households": 195810.9974,
    "Total Smart Devices": 1036069.954,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 39162.19948,
    "Total Concierge": 939.8927875200002,
    "Susbscription/Concierge": 40102.092267520005,
    "YEAR_1": 2356126.593894134,
    "YEAR_2": 9192165.27310618,
    "YEAR_3": 15165381.3868634,
    "YEAR_4": 21138597.50062061,
    "YEAR_5": 27111813.614377826,
    "YEAR_6": 33085029.72813504,
    "YEAR_7": 39058245.84189226,
    "YEAR_8": 45031461.95564948,
    "YEAR_9": 51004678.06940669,
    "YEAR_10": 56977894.1831639,
    "SUBSCRIPTION_Izak": 300121394.1471095
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Izak",
    "Total Households": 195141.7252,
    "Total Smart Devices": 1038545,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 39028.34504,
    "Total Concierge": 936.68028096,
    "Susbscription/Concierge": 39965.02532096,
    "YEAR_1": 2348073.470985246,
    "YEAR_2": 9160746.91174352,
    "YEAR_3": 15113546.871440891,
    "YEAR_4": 21066346.831138264,
    "YEAR_5": 27019146.790835634,
    "YEAR_6": 32971946.750533015,
    "YEAR_7": 38924746.71023038,
    "YEAR_8": 44877546.66992776,
    "YEAR_9": 50830346.62962512,
    "YEAR_10": 56783146.58932249,
    "SUBSCRIPTION_Izak": 299095594.22578233
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Izak",
    "Total Households": 193660.7387,
    "Total Smart Devices": 1041756.757,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 38732.14774,
    "Total Concierge": 929.5715457599999,
    "Susbscription/Concierge": 39661.71928576,
    "YEAR_1": 2330253.26821737,
    "YEAR_2": 9091223.377028925,
    "YEAR_3": 14998845.831154507,
    "YEAR_4": 20906468.285280086,
    "YEAR_5": 26814090.73940566,
    "YEAR_6": 32721713.19353125,
    "YEAR_7": 38629335.647656836,
    "YEAR_8": 44536958.101782404,
    "YEAR_9": 50444580.55590798,
    "YEAR_10": 56352203.01003358,
    "SUBSCRIPTION_Izak": 296825672.00999856
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Izak",
    "Total Households": 188171.9961,
    "Total Smart Devices": 1004152.231,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 37634.39922,
    "Total Concierge": 903.22558128,
    "Susbscription/Concierge": 38537.62480128,
    "YEAR_1": 2264209.1104396423,
    "YEAR_2": 8833559.457276382,
    "YEAR_3": 14573747.772472512,
    "YEAR_4": 20313936.08766864,
    "YEAR_5": 26054124.40286477,
    "YEAR_6": 31794312.7180609,
    "YEAR_7": 37534501.03325703,
    "YEAR_8": 43274689.348453164,
    "YEAR_9": 49014877.66364928,
    "YEAR_10": 54755065.97884542,
    "SUBSCRIPTION_Izak": 288413023.57298774
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Izak",
    "Total Households": 186023.7374,
    "Total Smart Devices": 1011839.034,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 37204.747480000005,
    "Total Concierge": 892.9139395200001,
    "Susbscription/Concierge": 38097.66141952001,
    "YEAR_1": 2238359.850077138,
    "YEAR_2": 8732711.449340196,
    "YEAR_3": 14407367.114921423,
    "YEAR_4": 20082022.78050265,
    "YEAR_5": 25756678.446083874,
    "YEAR_6": 31431334.111665104,
    "YEAR_7": 37105989.77724633,
    "YEAR_8": 42780645.44282756,
    "YEAR_9": 48455301.108408794,
    "YEAR_10": 54129956.77399001,
    "SUBSCRIPTION_Izak": 285120366.8550631
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Izak",
    "Total Households": 184130.2376,
    "Total Smart Devices": 1001954.872,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 36826.04752,
    "Total Concierge": 883.82514048,
    "Susbscription/Concierge": 37709.87266048,
    "YEAR_1": 2215576.0162090138,
    "YEAR_2": 8643822.861174546,
    "YEAR_3": 14260717.29951657,
    "YEAR_4": 19877611.737858593,
    "YEAR_5": 25494506.176200617,
    "YEAR_6": 31111400.61454264,
    "YEAR_7": 36728295.05288466,
    "YEAR_8": 42345189.49122669,
    "YEAR_9": 47962083.929568715,
    "YEAR_10": 53578978.36791073,
    "SUBSCRIPTION_Izak": 282218181.5470928
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Izak",
    "Total Households": 183224.9582,
    "Total Smart Devices": 986286.69,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 36644.99164,
    "Total Concierge": 879.47979936,
    "Susbscription/Concierge": 37524.47143936,
    "YEAR_1": 2204683.0995824393,
    "YEAR_2": 8601325.361168765,
    "YEAR_3": 14190604.243840609,
    "YEAR_4": 19779883.12651246,
    "YEAR_5": 25369162.0091843,
    "YEAR_6": 30958440.891856156,
    "YEAR_7": 36547719.774528,
    "YEAR_8": 42136998.657199845,
    "YEAR_9": 47726277.53987169,
    "YEAR_10": 53315556.42254354,
    "SUBSCRIPTION_Izak": 280830651.1262878
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Izak",
    "Total Households": 177354.3904,
    "Total Smart Devices": 952657.6974,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 35470.87808,
    "Total Concierge": 851.30107392,
    "Susbscription/Concierge": 36322.179153920006,
    "YEAR_1": 2134044.570089721,
    "YEAR_2": 8325737.012296104,
    "YEAR_3": 13735934.175118318,
    "YEAR_4": 19146131.337940533,
    "YEAR_5": 24556328.500762746,
    "YEAR_6": 29966525.663584962,
    "YEAR_7": 35376722.82640718,
    "YEAR_8": 40786919.98922939,
    "YEAR_9": 46197117.152051605,
    "YEAR_10": 51607314.31487382,
    "SUBSCRIPTION_Izak": 271832775.5423544
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Izak",
    "Total Households": 176036.0966,
    "Total Smart Devices": 959703.0583,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 35207.219320000004,
    "Total Concierge": 844.9732636800001,
    "Susbscription/Concierge": 36052.19258368001,
    "YEAR_1": 2118181.992798412,
    "YEAR_2": 8263850.935165527,
    "YEAR_3": 13633833.534590466,
    "YEAR_4": 19003816.134015404,
    "YEAR_5": 24373798.73344034,
    "YEAR_6": 29743781.332865283,
    "YEAR_7": 35113763.932290226,
    "YEAR_8": 40483746.53171516,
    "YEAR_9": 45853729.13114009,
    "YEAR_10": 51223711.73056504,
    "SUBSCRIPTION_Izak": 269812213.98858595
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Izak",
    "Total Households": 172513.4188,
    "Total Smart Devices": 927387.5876,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 34502.68376,
    "Total Concierge": 828.06441024,
    "Susbscription/Concierge": 35330.74817024,
    "YEAR_1": 2075794.8186534084,
    "YEAR_2": 8098482.100056868,
    "YEAR_3": 13361005.383723604,
    "YEAR_4": 18623528.66739034,
    "YEAR_5": 23886051.951057076,
    "YEAR_6": 29148575.23472381,
    "YEAR_7": 34411098.51839054,
    "YEAR_8": 39673621.80205727,
    "YEAR_9": 44936145.08572401,
    "YEAR_10": 50198668.36939075,
    "SUBSCRIPTION_Izak": 264412971.9311677
  },
  {
    "DMA": "Charleston",
    "PlatformName": "Izak",
    "Total Households": 170796.4251,
    "Total Smart Devices": 912456.1162,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 34159.285019999996,
    "Total Concierge": 819.82284048,
    "Susbscription/Concierge": 34979.10786048,
    "YEAR_1": 2055134.822167845,
    "YEAR_2": 8017879.426699147,
    "YEAR_3": 13228025.803183753,
    "YEAR_4": 18438172.17966836,
    "YEAR_5": 23648318.556152973,
    "YEAR_6": 28858464.932637572,
    "YEAR_7": 34068611.30912219,
    "YEAR_8": 39278757.6856068,
    "YEAR_9": 44488904.062091395,
    "YEAR_10": 49699050.43857601,
    "SUBSCRIPTION_Izak": 261781319.21590605
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Izak",
    "Total Households": 170460.101,
    "Total Smart Devices": 925622.1874,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 34092.0202,
    "Total Concierge": 818.2084848,
    "Susbscription/Concierge": 34910.2286848,
    "YEAR_1": 2051087.949588167,
    "YEAR_2": 8002091.004426759,
    "YEAR_3": 13201977.811427325,
    "YEAR_4": 18401864.61842789,
    "YEAR_5": 23601751.425428458,
    "YEAR_6": 28801638.232429024,
    "YEAR_7": 34001525.03942959,
    "YEAR_8": 39201411.84643015,
    "YEAR_9": 44401298.65343072,
    "YEAR_10": 49601185.460431285,
    "SUBSCRIPTION_Izak": 261265832.04144937
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Izak",
    "Total Households": 170257.3444,
    "Total Smart Devices": 922574.2281,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 34051.46888,
    "Total Concierge": 817.23525312,
    "Susbscription/Concierge": 34868.70413312,
    "YEAR_1": 2048648.24893963,
    "YEAR_2": 7992572.784295305,
    "YEAR_3": 13186274.499516694,
    "YEAR_4": 18379976.21473809,
    "YEAR_5": 23573677.929959483,
    "YEAR_6": 28767379.645180874,
    "YEAR_7": 33961081.36040226,
    "YEAR_8": 39154783.07562366,
    "YEAR_9": 44348484.79084505,
    "YEAR_10": 49542186.50606644,
    "SUBSCRIPTION_Izak": 260955065.0555675
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Izak",
    "Total Households": 169682.3867,
    "Total Smart Devices": 960358.8983,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 33936.477340000005,
    "Total Concierge": 814.4754561600001,
    "Susbscription/Concierge": 34750.95279616,
    "YEAR_1": 2041729.9800715803,
    "YEAR_2": 7965581.929472942,
    "YEAR_3": 13141744.555245988,
    "YEAR_4": 18317907.18101903,
    "YEAR_5": 23494069.806792077,
    "YEAR_6": 28670232.432565115,
    "YEAR_7": 33846395.05833816,
    "YEAR_8": 39022557.68411121,
    "YEAR_9": 44198720.30988426,
    "YEAR_10": 49374882.93565729,
    "SUBSCRIPTION_Izak": 260073821.87315765
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Izak",
    "Total Households": 168570.9748,
    "Total Smart Devices": 965499.8531,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 33714.19496,
    "Total Concierge": 809.1406790400001,
    "Susbscription/Concierge": 34523.33563904,
    "YEAR_1": 2028356.741749265,
    "YEAR_2": 7913407.730847995,
    "YEAR_3": 13055666.727313947,
    "YEAR_4": 18197925.7237799,
    "YEAR_5": 23340184.720245853,
    "YEAR_6": 28482443.716711808,
    "YEAR_7": 33624702.713177755,
    "YEAR_8": 38766961.70964371,
    "YEAR_9": 43909220.70610966,
    "YEAR_10": 49051479.70257562,
    "SUBSCRIPTION_Izak": 258370350.19215548
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Izak",
    "Total Households": 164675.9115,
    "Total Smart Devices": 878517.1744,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 32935.18229999999,
    "Total Concierge": 790.4443751999999,
    "Susbscription/Concierge": 33725.626675199994,
    "YEAR_1": 1981488.7805627745,
    "YEAR_2": 7730557.604561825,
    "YEAR_3": 12753997.66247686,
    "YEAR_4": 17777437.720391896,
    "YEAR_5": 22800877.77830693,
    "YEAR_6": 27824317.836221967,
    "YEAR_7": 32847757.894137,
    "YEAR_8": 37871197.952052034,
    "YEAR_9": 42894638.00996707,
    "YEAR_10": 47918078.0678821,
    "SUBSCRIPTION_Izak": 252400349.30656043
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Izak",
    "Total Households": 164390.6513,
    "Total Smart Devices": 901897.6009,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 32878.13026,
    "Total Concierge": 789.07512624,
    "Susbscription/Concierge": 33667.20538624,
    "YEAR_1": 1978056.3423834904,
    "YEAR_2": 7717166.329612736,
    "YEAR_3": 12731904.522740407,
    "YEAR_4": 17746642.715868082,
    "YEAR_5": 22761380.908995755,
    "YEAR_6": 27776119.102123424,
    "YEAR_7": 32790857.295251098,
    "YEAR_8": 37805595.48837877,
    "YEAR_9": 42820333.68150644,
    "YEAR_10": 47835071.87463411,
    "SUBSCRIPTION_Izak": 251963128.2614943
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Izak",
    "Total Households": 161325.6758,
    "Total Smart Devices": 877940.0386,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 32265.135159999998,
    "Total Concierge": 774.36324384,
    "Susbscription/Concierge": 33039.49840384,
    "YEAR_1": 1941176.5430817585,
    "YEAR_2": 7573283.903558451,
    "YEAR_3": 12494524.993418362,
    "YEAR_4": 17415766.08327827,
    "YEAR_5": 22337007.173138183,
    "YEAR_6": 27258248.262998097,
    "YEAR_7": 32179489.352858007,
    "YEAR_8": 37100730.44271791,
    "YEAR_9": 42021971.53257783,
    "YEAR_10": 46943212.62243774,
    "SUBSCRIPTION_Izak": 247265410.9100646
  },
  {
    "DMA": "Jackson",
    "PlatformName": "Izak",
    "Total Households": 155479.6092,
    "Total Smart Devices": 832501.5841,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 31095.921840000003,
    "Total Concierge": 746.3021241600001,
    "Susbscription/Concierge": 31842.223964160003,
    "YEAR_1": 1870832.8281279004,
    "YEAR_2": 7298845.740746733,
    "YEAR_3": 12041752.520067977,
    "YEAR_4": 16784659.29938922,
    "YEAR_5": 21527566.078710463,
    "YEAR_6": 26270472.858031705,
    "YEAR_7": 31013379.63735295,
    "YEAR_8": 35756286.4166742,
    "YEAR_9": 40499193.195995435,
    "YEAR_10": 45242099.97531669,
    "SUBSCRIPTION_Izak": 238305088.5504133
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Izak",
    "Total Households": 154389.0105,
    "Total Smart Devices": 835761.6251,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 30877.8021,
    "Total Concierge": 741.0672504000001,
    "Susbscription/Concierge": 31618.8693504,
    "YEAR_1": 1857710.0279049524,
    "YEAR_2": 7247648.598450605,
    "YEAR_3": 11957286.655304871,
    "YEAR_4": 16666924.712159136,
    "YEAR_5": 21376562.7690134,
    "YEAR_6": 26086200.825867668,
    "YEAR_7": 30795838.882721934,
    "YEAR_8": 35505476.93957619,
    "YEAR_9": 40215114.996430464,
    "YEAR_10": 44924753.053284734,
    "SUBSCRIPTION_Izak": 236633517.46071395
  },
  {
    "DMA": "Boise",
    "PlatformName": "Izak",
    "Total Households": 150604.699,
    "Total Smart Devices": 805064.7535,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 30120.9398,
    "Total Concierge": 722.9025552,
    "Susbscription/Concierge": 30843.8423552,
    "YEAR_1": 1812174.7051543347,
    "YEAR_2": 7069997.612475307,
    "YEAR_3": 11664195.215364158,
    "YEAR_4": 16258392.81825301,
    "YEAR_5": 20852590.42114186,
    "YEAR_6": 25446788.024030708,
    "YEAR_7": 30040985.62691956,
    "YEAR_8": 34635183.229808405,
    "YEAR_9": 39229380.83269726,
    "YEAR_10": 43823578.43558611,
    "SUBSCRIPTION_Izak": 230833266.9214307
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Izak",
    "Total Households": 150182.6846,
    "Total Smart Devices": 818778.2968,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 30036.536920000002,
    "Total Concierge": 720.8768860800001,
    "Susbscription/Concierge": 30757.413806080003,
    "YEAR_1": 1807096.7505754349,
    "YEAR_2": 7050186.5387157155,
    "YEAR_3": 11631510.588802177,
    "YEAR_4": 16212834.638888637,
    "YEAR_5": 20794158.6889751,
    "YEAR_6": 25375482.73906156,
    "YEAR_7": 29956806.789148018,
    "YEAR_8": 34538130.83923448,
    "YEAR_9": 39119454.88932094,
    "YEAR_10": 43700778.93940741,
    "SUBSCRIPTION_Izak": 230186441.40212947
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Izak",
    "Total Households": 146983.955,
    "Total Smart Devices": 788153.8513,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 29396.790999999997,
    "Total Concierge": 705.5229840000001,
    "Susbscription/Concierge": 30102.313983999997,
    "YEAR_1": 1768607.5340487415,
    "YEAR_2": 6900025.150756935,
    "YEAR_3": 11383771.927636204,
    "YEAR_4": 15867518.704515472,
    "YEAR_5": 20351265.481394745,
    "YEAR_6": 24835012.258274015,
    "YEAR_7": 29318759.035153285,
    "YEAR_8": 33802505.81203255,
    "YEAR_9": 38286252.588911824,
    "YEAR_10": 42769999.365791105,
    "SUBSCRIPTION_Izak": 225283717.85851488
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Izak",
    "Total Households": 146431.9358,
    "Total Smart Devices": 817105.2424,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 29286.387160000002,
    "Total Concierge": 702.8732918400001,
    "Susbscription/Concierge": 29989.260451840004,
    "YEAR_1": 1761965.2762862563,
    "YEAR_2": 6874111.122496502,
    "YEAR_3": 11341018.549061818,
    "YEAR_4": 15807925.975627134,
    "YEAR_5": 20274833.40219245,
    "YEAR_6": 24741740.82875777,
    "YEAR_7": 29208648.255323086,
    "YEAR_8": 33675555.68188841,
    "YEAR_9": 38142463.10845372,
    "YEAR_10": 42609370.53501904,
    "SUBSCRIPTION_Izak": 224437632.73510617
  },
  {
    "DMA": "Reno",
    "PlatformName": "Izak",
    "Total Households": 140080.9776,
    "Total Smart Devices": 766046.8896,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 28016.19552,
    "Total Concierge": 672.38869248,
    "Susbscription/Concierge": 28688.58421248,
    "YEAR_1": 1685546.3738220476,
    "YEAR_2": 6575971.292802804,
    "YEAR_3": 10849142.686347747,
    "YEAR_4": 15122314.079892691,
    "YEAR_5": 19395485.473437633,
    "YEAR_6": 23668656.86698258,
    "YEAR_7": 27941828.260527525,
    "YEAR_8": 32214999.654072467,
    "YEAR_9": 36488171.04761741,
    "YEAR_10": 40761342.44116236,
    "SUBSCRIPTION_Izak": 214703458.17666525
  },
  {
    "DMA": "Washington",
    "PlatformName": "Izak",
    "Total Households": 139336.8283,
    "Total Smart Devices": 757188.4939,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 27867.365659999996,
    "Total Concierge": 668.8167758399999,
    "Susbscription/Concierge": 28536.182435839997,
    "YEAR_1": 1676592.2804420108,
    "YEAR_2": 6541037.8955764305,
    "YEAR_3": 10791509.008499643,
    "YEAR_4": 15041980.121422855,
    "YEAR_5": 19292451.234346066,
    "YEAR_6": 23542922.347269278,
    "YEAR_7": 27793393.46019249,
    "YEAR_8": 32043864.5731157,
    "YEAR_9": 36294335.68603891,
    "YEAR_10": 40544806.79896212,
    "SUBSCRIPTION_Izak": 213562893.4058655
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Izak",
    "Total Households": 138117.7876,
    "Total Smart Devices": 772710.2,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 27623.557520000002,
    "Total Concierge": 662.96538048,
    "Susbscription/Concierge": 28286.522900480002,
    "YEAR_1": 1661923.9816720397,
    "YEAR_2": 6483811.162972887,
    "YEAR_3": 10697095.429144632,
    "YEAR_4": 14910379.695316378,
    "YEAR_5": 19123663.961488124,
    "YEAR_6": 23336948.227659866,
    "YEAR_7": 27550232.49383161,
    "YEAR_8": 31763516.760003354,
    "YEAR_9": 35976801.026175104,
    "YEAR_10": 40190085.29234684,
    "SUBSCRIPTION_Izak": 211694458.03061083
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Izak",
    "Total Households": 136727.054,
    "Total Smart Devices": 775398.3021,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 27345.4108,
    "Total Concierge": 656.2898592,
    "Susbscription/Concierge": 28001.7006592,
    "YEAR_1": 1645189.7611048033,
    "YEAR_2": 6418524.466761708,
    "YEAR_3": 10589384.392831177,
    "YEAR_4": 14760244.318900645,
    "YEAR_5": 18931104.244970113,
    "YEAR_6": 23101964.17103958,
    "YEAR_7": 27272824.09710905,
    "YEAR_8": 31443684.023178518,
    "YEAR_9": 35614543.949247986,
    "YEAR_10": 39785403.875317454,
    "SUBSCRIPTION_Izak": 209562867.30046105
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Izak",
    "Total Households": 133095.6859,
    "Total Smart Devices": 720630.3035,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 26619.137180000005,
    "Total Concierge": 638.8592923200001,
    "Susbscription/Concierge": 27257.996472320006,
    "YEAR_1": 1601494.7538466011,
    "YEAR_2": 6248053.266543586,
    "YEAR_3": 10308138.278344098,
    "YEAR_4": 14368223.290144611,
    "YEAR_5": 18428308.301945124,
    "YEAR_6": 22488393.313745636,
    "YEAR_7": 26548478.325546145,
    "YEAR_8": 30608563.337346658,
    "YEAR_9": 34668648.34914717,
    "YEAR_10": 38728733.360947676,
    "SUBSCRIPTION_Izak": 203997034.5775573
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Izak",
    "Total Households": 132803.551,
    "Total Smart Devices": 728058.3645,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 26560.7102,
    "Total Concierge": 637.4570448000001,
    "Susbscription/Concierge": 27198.167244800003,
    "YEAR_1": 1597979.5947593485,
    "YEAR_2": 6234339.2651928,
    "YEAR_3": 10285512.699424936,
    "YEAR_4": 14336686.133657072,
    "YEAR_5": 18387859.56788921,
    "YEAR_6": 22439033.002121344,
    "YEAR_7": 26490206.436353486,
    "YEAR_8": 30541379.870585617,
    "YEAR_9": 34592553.30481775,
    "YEAR_10": 38643726.7390499,
    "SUBSCRIPTION_Izak": 203549276.6138515
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Izak",
    "Total Households": 130234.779,
    "Total Smart Devices": 714649.2487,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 26046.9558,
    "Total Concierge": 625.1269392,
    "Susbscription/Concierge": 26672.0827392,
    "YEAR_1": 1567070.4420395603,
    "YEAR_2": 6113750.651241295,
    "YEAR_3": 10086563.674124196,
    "YEAR_4": 14059376.697007094,
    "YEAR_5": 18032189.719889995,
    "YEAR_6": 22005002.742772892,
    "YEAR_7": 25977815.765655793,
    "YEAR_8": 29950628.788538694,
    "YEAR_9": 33923441.811421596,
    "YEAR_10": 37896254.83430449,
    "SUBSCRIPTION_Izak": 199612095.1269956
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Izak",
    "Total Households": 129762.1381,
    "Total Smart Devices": 723124.6195,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 25952.427620000002,
    "Total Concierge": 622.8582628800001,
    "Susbscription/Concierge": 26575.28588288,
    "YEAR_1": 1561383.3161444953,
    "YEAR_2": 6091562.963494858,
    "YEAR_3": 10049958.07176935,
    "YEAR_4": 14008353.180043843,
    "YEAR_5": 17966748.28831833,
    "YEAR_6": 21925143.39659282,
    "YEAR_7": 25883538.504867315,
    "YEAR_8": 29841933.613141797,
    "YEAR_9": 33800328.721416295,
    "YEAR_10": 37758723.829690784,
    "SUBSCRIPTION_Izak": 198887673.8854799
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Izak",
    "Total Households": 129216.6201,
    "Total Smart Devices": 722266.5486,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 25843.32402,
    "Total Concierge": 620.23977648,
    "Susbscription/Concierge": 26463.56379648,
    "YEAR_1": 1554819.28509254,
    "YEAR_2": 6065954.128025772,
    "YEAR_3": 10007708.205146693,
    "YEAR_4": 13949462.282267613,
    "YEAR_5": 17891216.35938854,
    "YEAR_6": 21832970.436509464,
    "YEAR_7": 25774724.51363039,
    "YEAR_8": 29716478.590751305,
    "YEAR_9": 33658232.667872235,
    "YEAR_10": 37599986.74499316,
    "SUBSCRIPTION_Izak": 198051553.2136777
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Izak",
    "Total Households": 128748.7925,
    "Total Smart Devices": 712861.0057,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 25749.7585,
    "Total Concierge": 617.9942040000001,
    "Susbscription/Concierge": 26367.752704,
    "YEAR_1": 1549190.075986036,
    "YEAR_2": 6043992.39617403,
    "YEAR_3": 9971475.388443308,
    "YEAR_4": 13898958.38071259,
    "YEAR_5": 17826441.372981865,
    "YEAR_6": 21753924.365251146,
    "YEAR_7": 25681407.357520428,
    "YEAR_8": 29608890.349789705,
    "YEAR_9": 33536373.342058983,
    "YEAR_10": 37463856.334328264,
    "SUBSCRIPTION_Izak": 197334509.36324635
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Izak",
    "Total Households": 126228.7724,
    "Total Smart Devices": 693737.697,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 25245.75448,
    "Total Concierge": 605.89810752,
    "Susbscription/Concierge": 25851.65258752,
    "YEAR_1": 1518867.5381633583,
    "YEAR_2": 5925692.3948547505,
    "YEAR_3": 9776302.152892131,
    "YEAR_4": 13626911.910929509,
    "YEAR_5": 17477521.668966886,
    "YEAR_6": 21328131.427004267,
    "YEAR_7": 25178741.185041644,
    "YEAR_8": 29029350.94307903,
    "YEAR_9": 32879960.701116398,
    "YEAR_10": 36730570.45915378,
    "SUBSCRIPTION_Izak": 193472050.38120174
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Izak",
    "Total Households": 121298.6731,
    "Total Smart Devices": 661038.8766,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 24259.73462,
    "Total Concierge": 582.23363088,
    "Susbscription/Concierge": 24841.96825088,
    "YEAR_1": 1459545.3436722085,
    "YEAR_2": 5694253.465580266,
    "YEAR_3": 9394470.503228063,
    "YEAR_4": 13094687.540875861,
    "YEAR_5": 16794904.57852366,
    "YEAR_6": 20495121.61617146,
    "YEAR_7": 24195338.65381926,
    "YEAR_8": 27895555.691467054,
    "YEAR_9": 31595772.729114853,
    "YEAR_10": 35295989.76676265,
    "SUBSCRIPTION_Izak": 185915639.88921535
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Izak",
    "Total Households": 120688.59,
    "Total Smart Devices": 672775.9124,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 24137.717999999997,
    "Total Concierge": 579.3052319999999,
    "Susbscription/Concierge": 24717.023231999996,
    "YEAR_1": 1452204.422909423,
    "YEAR_2": 5665613.6815027995,
    "YEAR_3": 9347220.129081406,
    "YEAR_4": 13028826.576660013,
    "YEAR_5": 16710433.02423862,
    "YEAR_6": 20392039.471817225,
    "YEAR_7": 24073645.91939583,
    "YEAR_8": 27755252.366974436,
    "YEAR_9": 31436858.81455304,
    "YEAR_10": 35118465.262131654,
    "SUBSCRIPTION_Izak": 184980559.66926447
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Izak",
    "Total Households": 119010.7855,
    "Total Smart Devices": 647106.4308,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 23802.1571,
    "Total Concierge": 571.2517704,
    "Susbscription/Concierge": 24373.4088704,
    "YEAR_1": 1432015.976630638,
    "YEAR_2": 5586850.708714015,
    "YEAR_3": 9217275.715984335,
    "YEAR_4": 12847700.723254653,
    "YEAR_5": 16478125.73052497,
    "YEAR_6": 20108550.737795293,
    "YEAR_7": 23738975.745065615,
    "YEAR_8": 27369400.75233593,
    "YEAR_9": 30999825.759606242,
    "YEAR_10": 34630250.76687656,
    "SUBSCRIPTION_Izak": 182408972.61678827
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Izak",
    "Total Households": 118760.2771,
    "Total Smart Devices": 650772.6644,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 23752.055420000004,
    "Total Concierge": 570.0493300800001,
    "Susbscription/Concierge": 24322.104750080005,
    "YEAR_1": 1429001.6949453857,
    "YEAR_2": 5575090.824715278,
    "YEAR_3": 9197874.071147952,
    "YEAR_4": 12820657.317580627,
    "YEAR_5": 16443440.564013302,
    "YEAR_6": 20066223.810445976,
    "YEAR_7": 23689007.056878652,
    "YEAR_8": 27311790.303311326,
    "YEAR_9": 30934573.549744003,
    "YEAR_10": 34557356.79617667,
    "SUBSCRIPTION_Izak": 182025015.9889592
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Izak",
    "Total Households": 117920.6242,
    "Total Smart Devices": 655342.6546,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 23584.12484,
    "Total Concierge": 566.01899616,
    "Susbscription/Concierge": 24150.14383616,
    "YEAR_1": 1418898.4394919188,
    "YEAR_2": 5535674.099754338,
    "YEAR_3": 9132843.727448339,
    "YEAR_4": 12730013.355142338,
    "YEAR_5": 16327182.98283634,
    "YEAR_6": 19924352.61053034,
    "YEAR_7": 23521522.238224342,
    "YEAR_8": 27118691.865918342,
    "YEAR_9": 30715861.49361235,
    "YEAR_10": 34313031.121306345,
    "SUBSCRIPTION_Izak": 180738071.93426502
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Izak",
    "Total Households": 115972.0606,
    "Total Smart Devices": 631244.1375,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 23194.412119999997,
    "Total Concierge": 556.66589088,
    "Susbscription/Concierge": 23751.078010879995,
    "YEAR_1": 1395452.0417981485,
    "YEAR_2": 5444200.592677666,
    "YEAR_3": 8981929.271452826,
    "YEAR_4": 12519657.950227985,
    "YEAR_5": 16057386.629003141,
    "YEAR_6": 19595115.3077783,
    "YEAR_7": 23132843.986553457,
    "YEAR_8": 26670572.66532862,
    "YEAR_9": 30208301.344103776,
    "YEAR_10": 33746030.02287893,
    "SUBSCRIPTION_Izak": 177751489.81180286
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Izak",
    "Total Households": 114467.5114,
    "Total Smart Devices": 615432.0772,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 22893.50228,
    "Total Concierge": 549.44405472,
    "Susbscription/Concierge": 23442.94633472,
    "YEAR_1": 1377348.3171401275,
    "YEAR_2": 5373570.928912318,
    "YEAR_3": 8865403.32175507,
    "YEAR_4": 12357235.714597818,
    "YEAR_5": 15849068.107440569,
    "YEAR_6": 19340900.50028332,
    "YEAR_7": 22832732.89312607,
    "YEAR_8": 26324565.285968818,
    "YEAR_9": 29816397.678811572,
    "YEAR_10": 33308230.071654323,
    "SUBSCRIPTION_Izak": 175445452.81969002
  },
  {
    "DMA": "Macon",
    "PlatformName": "Izak",
    "Total Households": 114376.1331,
    "Total Smart Devices": 623908.0976,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 22875.22662,
    "Total Concierge": 549.0054388800002,
    "Susbscription/Concierge": 23424.232058880003,
    "YEAR_1": 1376248.7933871537,
    "YEAR_2": 5369281.259551923,
    "YEAR_3": 8858326.156588743,
    "YEAR_4": 12347371.053625561,
    "YEAR_5": 15836415.950662382,
    "YEAR_6": 19325460.847699203,
    "YEAR_7": 22814505.74473602,
    "YEAR_8": 26303550.641772844,
    "YEAR_9": 29792595.538809665,
    "YEAR_10": 33281640.43584648,
    "SUBSCRIPTION_Izak": 175305396.42267996
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Izak",
    "Total Households": 113695.8609,
    "Total Smart Devices": 615383.1435,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 22739.172179999998,
    "Total Concierge": 545.7401323199999,
    "Susbscription/Concierge": 23284.912312319997,
    "YEAR_1": 1368063.3112498415,
    "YEAR_2": 5337346.513413399,
    "YEAR_3": 8805639.701298354,
    "YEAR_4": 12273932.889183309,
    "YEAR_5": 15742226.077068266,
    "YEAR_6": 19210519.264953222,
    "YEAR_7": 22678812.45283818,
    "YEAR_8": 26147105.640723135,
    "YEAR_9": 29615398.82860809,
    "YEAR_10": 33083692.016493045,
    "SUBSCRIPTION_Izak": 174262736.69582886
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Izak",
    "Total Households": 113237.908,
    "Total Smart Devices": 624929.966,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 22647.5816,
    "Total Concierge": 543.5419584000001,
    "Susbscription/Concierge": 23191.123558400002,
    "YEAR_1": 1362552.9210226943,
    "YEAR_2": 5315848.340174954,
    "YEAR_3": 8770171.671014372,
    "YEAR_4": 12224495.001853792,
    "YEAR_5": 15678818.332693212,
    "YEAR_6": 19133141.663532633,
    "YEAR_7": 22587464.99437205,
    "YEAR_8": 26041788.325211477,
    "YEAR_9": 29496111.65605089,
    "YEAR_10": 32950434.98689032,
    "SUBSCRIPTION_Izak": 173560827.8928164
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Izak",
    "Total Households": 112822.5815,
    "Total Smart Devices": 631714.9611,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 22564.5163,
    "Total Concierge": 541.5483912,
    "Susbscription/Concierge": 23106.0646912,
    "YEAR_1": 1357555.4396514108,
    "YEAR_2": 5296351.2236646805,
    "YEAR_3": 8738005.016147159,
    "YEAR_4": 12179658.808629636,
    "YEAR_5": 15621312.601112114,
    "YEAR_6": 19062966.39359459,
    "YEAR_7": 22504620.18607707,
    "YEAR_8": 25946273.978559546,
    "YEAR_9": 29387927.771042023,
    "YEAR_10": 32829581.5635245,
    "SUBSCRIPTION_Izak": 172924252.98200274
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Izak",
    "Total Households": 110094.1168,
    "Total Smart Devices": 617472.2414,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 22018.823360000002,
    "Total Concierge": 528.4517606400001,
    "Susbscription/Concierge": 22547.275120640003,
    "YEAR_1": 1324724.7594264431,
    "YEAR_2": 5168265.9843407525,
    "YEAR_3": 8526687.938324574,
    "YEAR_4": 11885109.892308393,
    "YEAR_5": 15243531.846292214,
    "YEAR_6": 18601953.800276037,
    "YEAR_7": 21960375.75425986,
    "YEAR_8": 25318797.708243683,
    "YEAR_9": 28677219.662227497,
    "YEAR_10": 32035641.61621132,
    "SUBSCRIPTION_Izak": 168742308.96191075
  },
  {
    "DMA": "Lafayette",
    "PlatformName": "Izak",
    "Total Households": 109608.0386,
    "Total Smart Devices": 607971.4294,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 21921.60772,
    "Total Concierge": 526.11858528,
    "Susbscription/Concierge": 22447.72630528,
    "YEAR_1": 1318875.947107732,
    "YEAR_2": 5145447.49503534,
    "YEAR_3": 8489041.62946184,
    "YEAR_4": 11832635.76388834,
    "YEAR_5": 15176229.898314841,
    "YEAR_6": 18519824.03274134,
    "YEAR_7": 21863418.167167842,
    "YEAR_8": 25207012.30159434,
    "YEAR_9": 28550606.43602084,
    "YEAR_10": 31894200.57044734,
    "SUBSCRIPTION_Izak": 167997292.2417798
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Izak",
    "Total Households": 107403.5907,
    "Total Smart Devices": 609396.2424,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 21480.718140000004,
    "Total Concierge": 515.5372353600001,
    "Susbscription/Concierge": 21996.255375360004,
    "YEAR_1": 1292350.581367248,
    "YEAR_2": 5041961.737331153,
    "YEAR_3": 8318309.170126695,
    "YEAR_4": 11594656.602922237,
    "YEAR_5": 14871004.035717778,
    "YEAR_6": 18147351.46851332,
    "YEAR_7": 21423698.901308864,
    "YEAR_8": 24700046.334104408,
    "YEAR_9": 27976393.76689995,
    "YEAR_10": 31252741.19969549,
    "SUBSCRIPTION_Izak": 164618513.79798716
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Izak",
    "Total Households": 106297.9921,
    "Total Smart Devices": 597656.2835,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 21259.598420000002,
    "Total Concierge": 510.2303620800001,
    "Susbscription/Concierge": 21769.828782080003,
    "YEAR_1": 1279047.2924906234,
    "YEAR_2": 4990060.438671434,
    "YEAR_3": 8232681.576925015,
    "YEAR_4": 11475302.715178598,
    "YEAR_5": 14717923.853432178,
    "YEAR_6": 17960544.99168576,
    "YEAR_7": 21203166.129939344,
    "YEAR_8": 24445787.268192925,
    "YEAR_9": 27688408.40644651,
    "YEAR_10": 30931029.54470009,
    "SUBSCRIPTION_Izak": 162923952.21766248
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Izak",
    "Total Households": 103469.8371,
    "Total Smart Devices": 569961.0358,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 20693.96742,
    "Total Concierge": 496.65521808000005,
    "Susbscription/Concierge": 21190.62263808,
    "YEAR_1": 1245017.0730666218,
    "YEAR_2": 4857295.330872837,
    "YEAR_3": 8013643.577192296,
    "YEAR_4": 11169991.823511757,
    "YEAR_5": 14326340.069831215,
    "YEAR_6": 17482688.316150673,
    "YEAR_7": 20639036.56247013,
    "YEAR_8": 23795384.80878959,
    "YEAR_9": 26951733.05510905,
    "YEAR_10": 30108081.301428508,
    "SUBSCRIPTION_Izak": 158589211.91842267
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Izak",
    "Total Households": 100198.4171,
    "Total Smart Devices": 552884.0625,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 20039.68342,
    "Total Concierge": 480.95240208,
    "Susbscription/Concierge": 20520.635822080003,
    "YEAR_1": 1205653.1978801244,
    "YEAR_2": 4703721.559649379,
    "YEAR_3": 7760275.111501548,
    "YEAR_4": 10816828.663353723,
    "YEAR_5": 13873382.215205893,
    "YEAR_6": 16929935.767058074,
    "YEAR_7": 19986489.31891024,
    "YEAR_8": 23043042.87076241,
    "YEAR_9": 26099596.422614582,
    "YEAR_10": 29156149.974466752,
    "SUBSCRIPTION_Izak": 153575075.1014027
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Izak",
    "Total Households": 95167.68885,
    "Total Smart Devices": 537565.9934,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 19033.537770000003,
    "Total Concierge": 456.8049064800001,
    "Susbscription/Concierge": 19490.342676480002,
    "YEAR_1": 1145120.1697363255,
    "YEAR_2": 4467558.697848419,
    "YEAR_3": 7370649.842349441,
    "YEAR_4": 10273740.986850463,
    "YEAR_5": 13176832.131351486,
    "YEAR_6": 16079923.275852509,
    "YEAR_7": 18983014.42035353,
    "YEAR_8": 21886105.564854555,
    "YEAR_9": 24789196.709355578,
    "YEAR_10": 27692287.853856597,
    "SUBSCRIPTION_Izak": 145864429.65240893
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Izak",
    "Total Households": 89961.70526,
    "Total Smart Devices": 510390.503,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 17992.341052,
    "Total Concierge": 431.816185248,
    "Susbscription/Concierge": 18424.157237248,
    "YEAR_1": 1082478.3541761977,
    "YEAR_2": 4223168.637005192,
    "YEAR_3": 6967451.208542256,
    "YEAR_4": 9711733.780079318,
    "YEAR_5": 12456016.35161638,
    "YEAR_6": 15200298.923153449,
    "YEAR_7": 17944581.494690504,
    "YEAR_8": 20688864.066227574,
    "YEAR_9": 23433146.637764633,
    "YEAR_10": 26177429.209301695,
    "SUBSCRIPTION_Izak": 137885168.66255718
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Izak",
    "Total Households": 86254.9588,
    "Total Smart Devices": 480098.5248,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 17250.99176,
    "Total Concierge": 414.02380224,
    "Susbscription/Concierge": 17665.01556224,
    "YEAR_1": 1037876.3449571338,
    "YEAR_2": 4049158.8697385592,
    "YEAR_3": 6680367.109504283,
    "YEAR_4": 9311575.349270007,
    "YEAR_5": 11942783.589035729,
    "YEAR_6": 14573991.828801453,
    "YEAR_7": 17205200.068567175,
    "YEAR_8": 19836408.308332898,
    "YEAR_9": 22467616.548098624,
    "YEAR_10": 25098824.787864342,
    "SUBSCRIPTION_Izak": 132203802.8041702
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Izak",
    "Total Households": 86231.4576,
    "Total Smart Devices": 492907.9867,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 17246.29152,
    "Total Concierge": 413.91099648,
    "Susbscription/Concierge": 17660.20251648,
    "YEAR_1": 1037593.5630754023,
    "YEAR_2": 4048055.627748146,
    "YEAR_3": 6678546.963211267,
    "YEAR_4": 9309038.29867439,
    "YEAR_5": 11939529.634137513,
    "YEAR_6": 14570020.969600637,
    "YEAR_7": 17200512.305063758,
    "YEAR_8": 19831003.64052688,
    "YEAR_9": 22461494.97599,
    "YEAR_10": 25091986.311453126,
    "SUBSCRIPTION_Izak": 132167782.2894811
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Izak",
    "Total Households": 86036.0769,
    "Total Smart Devices": 470002.9309,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 17207.21538,
    "Total Concierge": 412.97316912,
    "Susbscription/Concierge": 17620.18854912,
    "YEAR_1": 1035242.6141025863,
    "YEAR_2": 4038883.662386536,
    "YEAR_3": 6663414.907961688,
    "YEAR_4": 9287946.15353684,
    "YEAR_5": 11912477.399111992,
    "YEAR_6": 14537008.644687142,
    "YEAR_7": 17161539.890262295,
    "YEAR_8": 19786071.135837447,
    "YEAR_9": 22410602.3814126,
    "YEAR_10": 25035133.62698775,
    "SUBSCRIPTION_Izak": 131868320.41628687
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Izak",
    "Total Households": 84647.46866,
    "Total Smart Devices": 465738.6675,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 16929.493732,
    "Total Concierge": 406.307849568,
    "Susbscription/Concierge": 17335.801581567997,
    "YEAR_1": 1018533.9672635065,
    "YEAR_2": 3973696.739225105,
    "YEAR_3": 6555868.478822558,
    "YEAR_4": 9138040.218420014,
    "YEAR_5": 11720211.958017467,
    "YEAR_6": 14302383.697614927,
    "YEAR_7": 16884555.437212378,
    "YEAR_8": 19466727.176809836,
    "YEAR_9": 22048898.916407287,
    "YEAR_10": 24631070.65600474,
    "SUBSCRIPTION_Izak": 129739987.24579781
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Izak",
    "Total Households": 83560.12063,
    "Total Smart Devices": 454093.9776,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 16712.024126,
    "Total Concierge": 401.088579024,
    "Susbscription/Concierge": 17113.112705024,
    "YEAR_1": 1005450.2812381098,
    "YEAR_2": 3922652.1966107353,
    "YEAR_3": 6471654.375456757,
    "YEAR_4": 9020656.554302776,
    "YEAR_5": 11569658.733148798,
    "YEAR_6": 14118660.911994819,
    "YEAR_7": 16667663.090840839,
    "YEAR_8": 19216665.269686863,
    "YEAR_9": 21765667.448532883,
    "YEAR_10": 24314669.627378903,
    "SUBSCRIPTION_Izak": 128073398.48919147
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Izak",
    "Total Households": 82931.47493,
    "Total Smart Devices": 451807.1961,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 16586.294986,
    "Total Concierge": 398.071079664,
    "Susbscription/Concierge": 16984.366065664002,
    "YEAR_1": 997886.003074093,
    "YEAR_2": 3893141.0085295932,
    "YEAR_3": 6422966.3450381365,
    "YEAR_4": 8952791.681546679,
    "YEAR_5": 11482617.018055221,
    "YEAR_6": 14012442.354563767,
    "YEAR_7": 16542267.691072306,
    "YEAR_8": 19072093.02758085,
    "YEAR_9": 21601918.364089392,
    "YEAR_10": 24131743.700597934,
    "SUBSCRIPTION_Izak": 127109867.19414797
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Izak",
    "Total Households": 82818.72073,
    "Total Smart Devices": 466140.3129,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 16563.744146,
    "Total Concierge": 397.529859504,
    "Susbscription/Concierge": 16961.274005504,
    "YEAR_1": 996529.2704455851,
    "YEAR_2": 3887847.8674118873,
    "YEAR_3": 6414233.636106176,
    "YEAR_4": 8940619.404800467,
    "YEAR_5": 11467005.173494756,
    "YEAR_6": 13993390.942189045,
    "YEAR_7": 16519776.710883338,
    "YEAR_8": 19046162.47957763,
    "YEAR_9": 21572548.24827192,
    "YEAR_10": 24098934.01696621,
    "SUBSCRIPTION_Izak": 126937047.750147
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Izak",
    "Total Households": 79804.87057,
    "Total Smart Devices": 484230.3852,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 15960.974114,
    "Total Concierge": 383.063378736,
    "Susbscription/Concierge": 16344.037492736,
    "YEAR_1": 960264.6448307008,
    "YEAR_2": 3746365.472924593,
    "YEAR_3": 6180813.717275514,
    "YEAR_4": 8615261.961626437,
    "YEAR_5": 11049710.205977356,
    "YEAR_6": 13484158.45032828,
    "YEAR_7": 15918606.6946792,
    "YEAR_8": 18353054.939030122,
    "YEAR_9": 20787503.183381043,
    "YEAR_10": 23221951.427731965,
    "SUBSCRIPTION_Izak": 122317690.6977852
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Izak",
    "Total Households": 79429.73327,
    "Total Smart Devices": 481277.3701,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 15885.946654,
    "Total Concierge": 381.262719696,
    "Susbscription/Concierge": 16267.209373696,
    "YEAR_1": 955750.7463233246,
    "YEAR_2": 3728755.0010537882,
    "YEAR_3": 6151759.678929973,
    "YEAR_4": 8574764.356806159,
    "YEAR_5": 10997769.034682343,
    "YEAR_6": 13420773.712558527,
    "YEAR_7": 15843778.390434708,
    "YEAR_8": 18266783.068310898,
    "YEAR_9": 20689787.746187083,
    "YEAR_10": 23112792.42406327,
    "SUBSCRIPTION_Izak": 121742714.15935007
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Izak",
    "Total Households": 79143.66222,
    "Total Smart Devices": 453919.7864,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 15828.732444,
    "Total Concierge": 379.889578656,
    "Susbscription/Concierge": 16208.622022656,
    "YEAR_1": 952308.5514640068,
    "YEAR_2": 3715325.661505104,
    "YEAR_3": 6129603.739607894,
    "YEAR_4": 8543881.81771068,
    "YEAR_5": 10958159.89581347,
    "YEAR_6": 13372437.973916259,
    "YEAR_7": 15786716.052019045,
    "YEAR_8": 18200994.130121835,
    "YEAR_9": 20615272.20822462,
    "YEAR_10": 23029550.28632741,
    "SUBSCRIPTION_Izak": 121304250.31671034
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Izak",
    "Total Households": 78267.63343,
    "Total Smart Devices": 449346.8731,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 15653.526686,
    "Total Concierge": 375.684640464,
    "Susbscription/Concierge": 16029.211326464,
    "YEAR_1": 941767.5973983905,
    "YEAR_2": 3674201.3041983005,
    "YEAR_3": 6061756.116733661,
    "YEAR_4": 8449310.929269021,
    "YEAR_5": 10836865.741804378,
    "YEAR_6": 13224420.554339739,
    "YEAR_7": 15611975.3668751,
    "YEAR_8": 17999530.179410458,
    "YEAR_9": 20387084.991945818,
    "YEAR_10": 22774639.80448118,
    "SUBSCRIPTION_Izak": 119961552.58645605
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Izak",
    "Total Households": 77131.53359,
    "Total Smart Devices": 443416.376,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 15426.306718000002,
    "Total Concierge": 370.23136123200004,
    "Susbscription/Concierge": 15796.538079232001,
    "YEAR_1": 928097.2975588227,
    "YEAR_2": 3620868.153176675,
    "YEAR_3": 5973766.230588715,
    "YEAR_4": 8326664.308000754,
    "YEAR_5": 10679562.385412794,
    "YEAR_6": 13032460.462824833,
    "YEAR_7": 15385358.540236872,
    "YEAR_8": 17738256.61764891,
    "YEAR_9": 20091154.69506095,
    "YEAR_10": 22444052.77247299,
    "SUBSCRIPTION_Izak": 118220241.46298233
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Izak",
    "Total Households": 76791.33485,
    "Total Smart Devices": 443630.0818,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 15358.266969999999,
    "Total Concierge": 368.59840728,
    "Susbscription/Concierge": 15726.865377279999,
    "YEAR_1": 924003.8027645241,
    "YEAR_2": 3604897.8395308354,
    "YEAR_3": 5947418.151533219,
    "YEAR_4": 8289938.463535603,
    "YEAR_5": 10632458.775537986,
    "YEAR_6": 12974979.08754037,
    "YEAR_7": 15317499.39954275,
    "YEAR_8": 17660019.711545136,
    "YEAR_9": 20002540.023547523,
    "YEAR_10": 22345060.335549902,
    "SUBSCRIPTION_Izak": 117698815.59062786
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Izak",
    "Total Households": 75854.67956,
    "Total Smart Devices": 423818.9958,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 15170.935912,
    "Total Concierge": 364.102461888,
    "Susbscription/Concierge": 15535.038373888001,
    "YEAR_1": 912733.3508114481,
    "YEAR_2": 3560927.4275318556,
    "YEAR_3": 5874875.062077137,
    "YEAR_4": 8188822.696622416,
    "YEAR_5": 10502770.331167698,
    "YEAR_6": 12816717.965712976,
    "YEAR_7": 15130665.60025826,
    "YEAR_8": 17444613.23480354,
    "YEAR_9": 19758560.869348817,
    "YEAR_10": 22072508.503894098,
    "SUBSCRIPTION_Izak": 116263195.04222825
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Izak",
    "Total Households": 73564.47988,
    "Total Smart Devices": 393958.0018,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 14712.895976,
    "Total Concierge": 353.109503424,
    "Susbscription/Concierge": 15066.005479424,
    "YEAR_1": 885176.1633039815,
    "YEAR_2": 3453416.1322190072,
    "YEAR_3": 5697501.206367066,
    "YEAR_4": 7941586.280515127,
    "YEAR_5": 10185671.354663186,
    "YEAR_6": 12429756.428811243,
    "YEAR_7": 14673841.5029593,
    "YEAR_8": 16917926.577107362,
    "YEAR_9": 19162011.65125542,
    "YEAR_10": 21406096.725403477,
    "SUBSCRIPTION_Izak": 112752984.02260517
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Izak",
    "Total Households": 73134.65223,
    "Total Smart Devices": 422552.4588,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 14626.930446000002,
    "Total Concierge": 351.04633070400007,
    "Susbscription/Concierge": 14977.976776704003,
    "YEAR_1": 880004.1945667649,
    "YEAR_2": 3433238.272700323,
    "YEAR_3": 5664211.450789379,
    "YEAR_4": 7895184.628878435,
    "YEAR_5": 10126157.806967491,
    "YEAR_6": 12357130.985056547,
    "YEAR_7": 14588104.163145605,
    "YEAR_8": 16819077.34123466,
    "YEAR_9": 19050050.519323718,
    "YEAR_10": 21281023.697412774,
    "SUBSCRIPTION_Izak": 112094183.0600757
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Izak",
    "Total Households": 71033.27061,
    "Total Smart Devices": 413572.7033,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 14206.654122000002,
    "Total Concierge": 340.959698928,
    "Susbscription/Concierge": 14547.613820928002,
    "YEAR_1": 854718.9900351304,
    "YEAR_2": 3334590.8657140397,
    "YEAR_3": 5501461.379905746,
    "YEAR_4": 7668331.894097452,
    "YEAR_5": 9835202.408289159,
    "YEAR_6": 12002072.922480863,
    "YEAR_7": 14168943.436672568,
    "YEAR_8": 16335813.950864274,
    "YEAR_9": 18502684.465055976,
    "YEAR_10": 20669554.979247686,
    "SUBSCRIPTION_Izak": 108873375.29236288
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Izak",
    "Total Households": 70940.83086,
    "Total Smart Devices": 389215.4444,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 14188.166172,
    "Total Concierge": 340.515988128,
    "Susbscription/Concierge": 14528.682160127999,
    "YEAR_1": 853606.694218246,
    "YEAR_2": 3330251.3675699756,
    "YEAR_3": 5494302.006414621,
    "YEAR_4": 7658352.645259265,
    "YEAR_5": 9822403.284103908,
    "YEAR_6": 11986453.92294855,
    "YEAR_7": 14150504.561793197,
    "YEAR_8": 16314555.200637842,
    "YEAR_9": 18478605.839482483,
    "YEAR_10": 20642656.478327125,
    "SUBSCRIPTION_Izak": 108731692.00075522
  },
  {
    "DMA": "Erie",
    "PlatformName": "Izak",
    "Total Households": 69589.91057,
    "Total Smart Devices": 409022.6328,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 13917.982113999999,
    "Total Concierge": 334.03157073599994,
    "Susbscription/Concierge": 14252.013684735999,
    "YEAR_1": 837351.5335594293,
    "YEAR_2": 3266833.6701915925,
    "YEAR_3": 5389674.47428857,
    "YEAR_4": 7512515.27838555,
    "YEAR_5": 9635356.082482528,
    "YEAR_6": 11758196.886579508,
    "YEAR_7": 13881037.690676484,
    "YEAR_8": 16003878.494773468,
    "YEAR_9": 18126719.29887044,
    "YEAR_10": 20249560.102967426,
    "SUBSCRIPTION_Izak": 106661123.51277499
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Izak",
    "Total Households": 69495.96962,
    "Total Smart Devices": 419474.8359,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 13899.193924000001,
    "Total Concierge": 333.58065417600005,
    "Susbscription/Concierge": 14232.774578176,
    "YEAR_1": 836221.1743176626,
    "YEAR_2": 3262423.6996088447,
    "YEAR_3": 5382398.834240205,
    "YEAR_4": 7502373.968871565,
    "YEAR_5": 9622349.103502925,
    "YEAR_6": 11742324.238134285,
    "YEAR_7": 13862299.372765642,
    "YEAR_8": 15982274.507397003,
    "YEAR_9": 18102249.642028365,
    "YEAR_10": 20222224.776659723,
    "SUBSCRIPTION_Izak": 106517139.31752622
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Izak",
    "Total Households": 69029.01668,
    "Total Smart Devices": 420021.6584,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 13805.803336,
    "Total Concierge": 331.33928006400004,
    "Susbscription/Concierge": 14137.142616064,
    "YEAR_1": 830602.4896950437,
    "YEAR_2": 3240503.0278578373,
    "YEAR_3": 5346233.75914817,
    "YEAR_4": 7451964.490438502,
    "YEAR_5": 9557695.221728839,
    "YEAR_6": 11663425.953019168,
    "YEAR_7": 13769156.684309501,
    "YEAR_8": 15874887.415599838,
    "YEAR_9": 17980618.146890175,
    "YEAR_10": 20086348.878180504,
    "SUBSCRIPTION_Izak": 105801436.06686758
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Izak",
    "Total Households": 68794.13478,
    "Total Smart Devices": 393926.0646,
    "Subscription charges per home": 5,
    "% of household receiving NC signal": 0.04,
    "Concierge Rate( 3 times cpc)/100 Devices": 15,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 6,
    "Total revenue(sub)": 13758.826955999997,
    "Total Concierge": 330.21184694399994,
    "Susbscription/Concierge": 14089.038802943996,
    "YEAR_1": 827776.2363264244,
    "YEAR_2": 3229476.7153772833,
    "YEAR_3": 5328042.372343196,
    "YEAR_4": 7426608.029309108,
    "YEAR_5": 9525173.686275018,
    "YEAR_6": 11623739.343240932,
    "YEAR_7": 13722305.000206843,
    "YEAR_8": 15820870.657172753,
    "YEAR_9": 17919436.314138666,
    "YEAR_10": 20018001.971104577,
    "SUBSCRIPTION_Izak": 105441430.3254948
  },
  {
    "DMA": "New York",
    "PlatformName": "Izak",
    "Total Households": 3525394.987,
    "Total Smart Devices": 18429594.24,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 737183.7696,
    "Total AD revenue": 187981.861248,
    "YEAR_1": 0,
    "YEAR_2": 43089031.998893015,
    "YEAR_3": 71088974.62557895,
    "YEAR_4": 99088917.25226492,
    "YEAR_5": 127088859.87895086,
    "YEAR_6": 155088802.5056368,
    "YEAR_7": 183088745.13232276,
    "YEAR_8": 211088687.75900874,
    "YEAR_9": 239088630.38569468,
    "YEAR_10": 267088573.01238066,
    "AD_Izak": 1395799222.5507314
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Izak",
    "Total Households": 2663759.106,
    "Total Smart Devices": 13947729.05,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 557909.162,
    "Total AD revenue": 142266.83631,
    "YEAR_1": 0,
    "YEAR_2": 32610275.38213124,
    "YEAR_3": 53800954.248241805,
    "YEAR_4": 74991633.11435238,
    "YEAR_5": 96182311.98046294,
    "YEAR_6": 117372990.84657349,
    "YEAR_7": 138563669.71268407,
    "YEAR_8": 159754348.5787946,
    "YEAR_9": 180945027.44490516,
    "YEAR_10": 202135706.31101575,
    "AD_Izak": 1056356917.6191614
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Izak",
    "Total Households": 1653912.48,
    "Total Smart Devices": 8667326.99,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 346693.0796,
    "Total AD revenue": 88406.735298,
    "YEAR_1": 0,
    "YEAR_2": 20264511.80386807,
    "YEAR_3": 33432715.904639777,
    "YEAR_4": 46600920.0054115,
    "YEAR_5": 59769124.1061832,
    "YEAR_6": 72937328.2069549,
    "YEAR_7": 86105532.3077266,
    "YEAR_8": 99273736.40849833,
    "YEAR_9": 112441940.50927004,
    "YEAR_10": 125610144.61004174,
    "AD_Izak": 656435953.8625941
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Izak",
    "Total Households": 1418555.62,
    "Total Smart Devices": 7450689.965,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 298027.5986,
    "Total AD revenue": 75997.037643,
    "YEAR_1": 0,
    "YEAR_2": 17419972.14561117,
    "YEAR_3": 28739748.849996433,
    "YEAR_4": 40059525.55438169,
    "YEAR_5": 51379302.25876694,
    "YEAR_6": 62699078.9631522,
    "YEAR_7": 74018855.66753745,
    "YEAR_8": 85338632.37192272,
    "YEAR_9": 96658409.07630797,
    "YEAR_10": 107978185.78069325,
    "AD_Izak": 564291710.6683698
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Izak",
    "Total Households": 1387789.234,
    "Total Smart Devices": 7285114.017,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 291404.56068,
    "Total AD revenue": 74308.1629734,
    "YEAR_1": 0,
    "YEAR_2": 17032849.82329036,
    "YEAR_3": 28101068.246794064,
    "YEAR_4": 39169286.670297764,
    "YEAR_5": 50237505.09380146,
    "YEAR_6": 61305723.51730515,
    "YEAR_7": 72373941.94080886,
    "YEAR_8": 83442160.36431254,
    "YEAR_9": 94510378.78781626,
    "YEAR_10": 105578597.21131995,
    "AD_Izak": 551751511.6557465
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Izak",
    "Total Households": 1222748.747,
    "Total Smart Devices": 6409667.645,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 256386.7058,
    "Total AD revenue": 65378.60997900001,
    "YEAR_1": 0,
    "YEAR_2": 14986025.772517182,
    "YEAR_3": 24724185.168701768,
    "YEAR_4": 34462344.56488635,
    "YEAR_5": 44200503.96107093,
    "YEAR_6": 53938663.35725551,
    "YEAR_7": 63676822.7534401,
    "YEAR_8": 73414982.14962468,
    "YEAR_9": 83153141.54580927,
    "YEAR_10": 92891300.94199382,
    "AD_Izak": 485447970.2152996
  },
  {
    "DMA": "Houston",
    "PlatformName": "Izak",
    "Total Households": 1216594.045,
    "Total Smart Devices": 6391466.714,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 255658.66856,
    "Total AD revenue": 65192.960482799994,
    "YEAR_1": 0,
    "YEAR_2": 14943471.363122398,
    "YEAR_3": 24653978.223005008,
    "YEAR_4": 34364485.082887605,
    "YEAR_5": 44074991.94277022,
    "YEAR_6": 53785498.80265283,
    "YEAR_7": 63496005.66253544,
    "YEAR_8": 73206512.52241804,
    "YEAR_9": 82917019.38230065,
    "YEAR_10": 92627526.24218324,
    "AD_Izak": 484069489.2238754
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Izak",
    "Total Households": 1194243.185,
    "Total Smart Devices": 6271809.788,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 250872.39152,
    "Total AD revenue": 63972.4598376,
    "YEAR_1": 0,
    "YEAR_2": 14663709.310515042,
    "YEAR_3": 24192422.311061677,
    "YEAR_4": 33721135.311608315,
    "YEAR_5": 43249848.312154956,
    "YEAR_6": 52778561.312701575,
    "YEAR_7": 62307274.31324822,
    "YEAR_8": 71835987.31379485,
    "YEAR_9": 81364700.31434149,
    "YEAR_10": 90893413.31488815,
    "AD_Izak": 475007051.81431425
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Izak",
    "Total Households": 1184628.618,
    "Total Smart Devices": 6261412.472,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 250456.49888,
    "Total AD revenue": 63866.40721440001,
    "YEAR_1": 0,
    "YEAR_2": 14639400.0242664,
    "YEAR_3": 24152316.461542007,
    "YEAR_4": 33665232.89881761,
    "YEAR_5": 43178149.336093225,
    "YEAR_6": 52691065.77336884,
    "YEAR_7": 62203982.210644454,
    "YEAR_8": 71716898.64792006,
    "YEAR_9": 81229815.08519568,
    "YEAR_10": 90742731.52247128,
    "AD_Izak": 474219591.9603195
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Izak",
    "Total Households": 1183233.948,
    "Total Smart Devices": 6219314.93,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 248772.5972,
    "Total AD revenue": 63437.01228599998,
    "YEAR_1": 0,
    "YEAR_2": 14540974.507638589,
    "YEAR_3": 23989932.468923107,
    "YEAR_4": 33438890.43020763,
    "YEAR_5": 42887848.39149215,
    "YEAR_6": 52336806.35277666,
    "YEAR_7": 61785764.314061195,
    "YEAR_8": 71234722.27534571,
    "YEAR_9": 80683680.23663023,
    "YEAR_10": 90132638.19791475,
    "AD_Izak": 471031257.17498994
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Izak",
    "Total Households": 975942.5103,
    "Total Smart Devices": 5145201.67,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 205808.0668,
    "Total AD revenue": 52481.057034000005,
    "YEAR_1": 0,
    "YEAR_2": 12029660.36648823,
    "YEAR_3": 19846726.205628958,
    "YEAR_4": 27663792.04476969,
    "YEAR_5": 35480857.88391042,
    "YEAR_6": 43297923.723051146,
    "YEAR_7": 51114989.562191874,
    "YEAR_8": 58932055.40133261,
    "YEAR_9": 66749121.24047333,
    "YEAR_10": 74566187.07961407,
    "AD_Izak": 389681313.50746036
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Izak",
    "Total Households": 965697.4865,
    "Total Smart Devices": 5080779.563,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 203231.18252,
    "Total AD revenue": 51823.95154260001,
    "YEAR_1": 0,
    "YEAR_2": 11879039.24082426,
    "YEAR_3": 19598229.061838925,
    "YEAR_4": 27317418.882853597,
    "YEAR_5": 35036608.703868255,
    "YEAR_6": 42755798.524882935,
    "YEAR_7": 50474988.3458976,
    "YEAR_8": 58194178.16691226,
    "YEAR_9": 65913367.98792693,
    "YEAR_10": 73632557.8089416,
    "AD_Izak": 384802186.72394633
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Izak",
    "Total Households": 943919.5209,
    "Total Smart Devices": 4962123.612,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 198484.94448,
    "Total AD revenue": 50613.6608424,
    "YEAR_1": 0,
    "YEAR_2": 11601617.50256367,
    "YEAR_3": 19140534.237961296,
    "YEAR_4": 26679450.97335892,
    "YEAR_5": 34218367.70875655,
    "YEAR_6": 41757284.44415417,
    "YEAR_7": 49296201.179551795,
    "YEAR_8": 56835117.91494941,
    "YEAR_9": 64374034.65034705,
    "YEAR_10": 71912951.38574468,
    "AD_Izak": 375815559.9973876
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Izak",
    "Total Households": 883925.362,
    "Total Smart Devices": 4639998.133,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 185599.92532,
    "Total AD revenue": 47327.980956600004,
    "YEAR_1": 0,
    "YEAR_2": 10848476.934652299,
    "YEAR_3": 17897990.875113856,
    "YEAR_4": 24947504.815575406,
    "YEAR_5": 31997018.75603696,
    "YEAR_6": 39046532.69649851,
    "YEAR_7": 46096046.636960074,
    "YEAR_8": 53145560.57742163,
    "YEAR_9": 60195074.517883174,
    "YEAR_10": 67244588.45834473,
    "AD_Izak": 351418794.2684866
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Izak",
    "Total Households": 839328.3334,
    "Total Smart Devices": 4419136.81,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 176765.4724,
    "Total AD revenue": 45075.195461999996,
    "YEAR_1": 0,
    "YEAR_2": 10332095.48370263,
    "YEAR_3": 17046056.492725693,
    "YEAR_4": 23760017.501748748,
    "YEAR_5": 30473978.51077181,
    "YEAR_6": 37187939.51979488,
    "YEAR_7": 43901900.52881794,
    "YEAR_8": 50615861.53784099,
    "YEAR_9": 57329822.54686405,
    "YEAR_10": 64043783.55588711,
    "AD_Izak": 334691455.6781539
  },
  {
    "DMA": "Denver",
    "PlatformName": "Izak",
    "Total Households": 817914.2545,
    "Total Smart Devices": 4310338.605,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 172413.54420000003,
    "Total AD revenue": 43965.45377100001,
    "YEAR_1": 0,
    "YEAR_2": 10077721.498273686,
    "YEAR_3": 16626386.220345698,
    "YEAR_4": 23175050.94241771,
    "YEAR_5": 29723715.664489727,
    "YEAR_6": 36272380.386561744,
    "YEAR_7": 42821045.10863375,
    "YEAR_8": 49369709.83070577,
    "YEAR_9": 55918374.55277778,
    "YEAR_10": 62467039.274849795,
    "AD_Izak": 326451423.47905564
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Izak",
    "Total Households": 809969.2436,
    "Total Smart Devices": 4262896.578,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 170515.86312,
    "Total AD revenue": 43481.54509559999,
    "YEAR_1": 0,
    "YEAR_2": 9966800.389926193,
    "YEAR_3": 16443386.8005175,
    "YEAR_4": 22919973.21110881,
    "YEAR_5": 29396559.621700116,
    "YEAR_6": 35873146.03229143,
    "YEAR_7": 42349732.44288273,
    "YEAR_8": 48826318.85347404,
    "YEAR_9": 55302905.264065355,
    "YEAR_10": 61779491.67465667,
    "AD_Izak": 322858314.2906229
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Izak",
    "Total Households": 785253.3688,
    "Total Smart Devices": 4133878.496,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 165355.13984,
    "Total AD revenue": 42165.5606592,
    "YEAR_1": 0,
    "YEAR_2": 9665151.629170092,
    "YEAR_3": 15945721.847176736,
    "YEAR_4": 22226292.065183382,
    "YEAR_5": 28506862.28319002,
    "YEAR_6": 34787432.501196675,
    "YEAR_7": 41068002.719203316,
    "YEAR_8": 47348572.937209964,
    "YEAR_9": 53629143.1552166,
    "YEAR_10": 59909713.37322326,
    "AD_Izak": 313086892.5107701
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Izak",
    "Total Households": 708345.9039,
    "Total Smart Devices": 3728438.627,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 149137.54508,
    "Total AD revenue": 38030.0739954,
    "YEAR_1": 0,
    "YEAR_2": 8717219.121190578,
    "YEAR_3": 14381807.62398768,
    "YEAR_4": 20046396.12678479,
    "YEAR_5": 25710984.629581895,
    "YEAR_6": 31375573.132379003,
    "YEAR_7": 37040161.63517611,
    "YEAR_8": 42704750.13797322,
    "YEAR_9": 48369338.640770316,
    "YEAR_10": 54033927.14356743,
    "AD_Izak": 282380158.191411
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Izak",
    "Total Households": 685389.524,
    "Total Smart Devices": 3620542.555,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 144821.7022,
    "Total AD revenue": 36929.534061000006,
    "YEAR_1": 0,
    "YEAR_2": 8464954.354076374,
    "YEAR_3": 13965617.17374109,
    "YEAR_4": 19466279.993405804,
    "YEAR_5": 24966942.81307052,
    "YEAR_6": 30467605.632735245,
    "YEAR_7": 35968268.45239995,
    "YEAR_8": 41468931.27206467,
    "YEAR_9": 46969594.09172939,
    "YEAR_10": 52470256.9113941,
    "AD_Izak": 274208450.69461715
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Izak",
    "Total Households": 603851.0947,
    "Total Smart Devices": 3181975.805,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 127279.03220000002,
    "Total AD revenue": 32456.153211,
    "YEAR_1": 0,
    "YEAR_2": 7439570.046733072,
    "YEAR_3": 12273921.732356666,
    "YEAR_4": 17108273.417980257,
    "YEAR_5": 21942625.10360385,
    "YEAR_6": 26776976.78922744,
    "YEAR_7": 31611328.47485104,
    "YEAR_8": 36445680.16047463,
    "YEAR_9": 41280031.846098214,
    "YEAR_10": 46114383.53172181,
    "AD_Izak": 240992791.10304695
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Izak",
    "Total Households": 590151.1448,
    "Total Smart Devices": 3098524.034,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 123940.96136,
    "Total AD revenue": 31604.945146800004,
    "YEAR_1": 0,
    "YEAR_2": 7244456.905111172,
    "YEAR_3": 11952020.94855088,
    "YEAR_4": 16659584.991990585,
    "YEAR_5": 21367149.035430294,
    "YEAR_6": 26074713.07887,
    "YEAR_7": 30782277.122309703,
    "YEAR_8": 35489841.165749416,
    "YEAR_9": 40197405.209189124,
    "YEAR_10": 44904969.252628826,
    "AD_Izak": 234672417.70983002
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Izak",
    "Total Households": 588388.274,
    "Total Smart Devices": 3101259.121,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 124050.36484,
    "Total AD revenue": 31632.8430342,
    "YEAR_1": 0,
    "YEAR_2": 7250851.633596672,
    "YEAR_3": 11962571.073952973,
    "YEAR_4": 16674290.514309272,
    "YEAR_5": 21386009.954665568,
    "YEAR_6": 26097729.39502187,
    "YEAR_7": 30809448.835378177,
    "YEAR_8": 35521168.27573448,
    "YEAR_9": 40232887.71609077,
    "YEAR_10": 44944607.156447075,
    "AD_Izak": 234879564.55519688
  },
  {
    "DMA": "St. Louis",
    "PlatformName": "Izak",
    "Total Households": 572704.5714,
    "Total Smart Devices": 3008446.843,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 120337.87372,
    "Total AD revenue": 30686.157798600005,
    "YEAR_1": 0,
    "YEAR_2": 7033853.301210592,
    "YEAR_3": 11604563.752155088,
    "YEAR_4": 16175274.203099582,
    "YEAR_5": 20745984.65404408,
    "YEAR_6": 25316695.104988575,
    "YEAR_7": 29887405.55593307,
    "YEAR_8": 34458116.00687757,
    "YEAR_9": 39028826.45782206,
    "YEAR_10": 43599536.90876657,
    "AD_Izak": 227850255.94489717
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Izak",
    "Total Households": 550874.6034,
    "Total Smart Devices": 2910409.816,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 116416.39264,
    "Total AD revenue": 29686.180123200003,
    "YEAR_1": 0,
    "YEAR_2": 6804639.324035186,
    "YEAR_3": 11226402.864074124,
    "YEAR_4": 15648166.404113064,
    "YEAR_5": 20069929.944152005,
    "YEAR_6": 24491693.48419094,
    "YEAR_7": 28913457.024229884,
    "YEAR_8": 33335220.56426882,
    "YEAR_9": 37756984.104307756,
    "YEAR_10": 42178747.6443467,
    "AD_Izak": 220425241.35771847
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Izak",
    "Total Households": 536129.9935,
    "Total Smart Devices": 2844384.806,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 113775.39224,
    "Total AD revenue": 29012.7250212,
    "YEAR_1": 0,
    "YEAR_2": 6650270.5554356845,
    "YEAR_3": 10971722.798988566,
    "YEAR_4": 15293175.042541444,
    "YEAR_5": 19614627.286094327,
    "YEAR_6": 23936079.529647205,
    "YEAR_7": 28257531.773200087,
    "YEAR_8": 32578984.016752962,
    "YEAR_9": 36900436.26030585,
    "YEAR_10": 41221888.50385873,
    "AD_Izak": 215424715.76682484
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Izak",
    "Total Households": 533193.8975,
    "Total Smart Devices": 2813141.762,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 112525.67048,
    "Total AD revenue": 28694.045972400003,
    "YEAR_1": 0,
    "YEAR_2": 6577223.232465496,
    "YEAR_3": 10851208.156440374,
    "YEAR_4": 15125193.080415253,
    "YEAR_5": 19399178.004390128,
    "YEAR_6": 23673162.92836501,
    "YEAR_7": 27947147.85233988,
    "YEAR_8": 32221132.77631476,
    "YEAR_9": 36495117.700289644,
    "YEAR_10": 40769102.62426451,
    "AD_Izak": 213058466.35528505
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Izak",
    "Total Households": 524491.4867,
    "Total Smart Devices": 2761746.07,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 110469.8428,
    "Total AD revenue": 28169.809914,
    "YEAR_1": 0,
    "YEAR_2": 6457058.317907222,
    "YEAR_3": 10652958.157179832,
    "YEAR_4": 14848857.99645244,
    "YEAR_5": 19044757.83572505,
    "YEAR_6": 23240657.67499766,
    "YEAR_7": 27436557.51427027,
    "YEAR_8": 31632457.35354288,
    "YEAR_9": 35828357.19281549,
    "YEAR_10": 40024257.0320881,
    "AD_Izak": 209165919.07497895
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Izak",
    "Total Households": 523914.9663,
    "Total Smart Devices": 2802506.923,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 112100.27692,
    "Total AD revenue": 28585.570614599997,
    "YEAR_1": 0,
    "YEAR_2": 6552358.609185864,
    "YEAR_3": 10810186.10299889,
    "YEAR_4": 15068013.596811917,
    "YEAR_5": 19325841.090624947,
    "YEAR_6": 23583668.584437974,
    "YEAR_7": 27841496.078251,
    "YEAR_8": 32099323.572064027,
    "YEAR_9": 36357151.06587705,
    "YEAR_10": 40614978.55969008,
    "AD_Izak": 212253017.25994176
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Izak",
    "Total Households": 505132.9124,
    "Total Smart Devices": 2679594.179,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 107183.76716,
    "Total AD revenue": 27331.860625800004,
    "YEAR_1": 0,
    "YEAR_2": 6264984.340912893,
    "YEAR_3": 10336071.43581801,
    "YEAR_4": 14407158.530723125,
    "YEAR_5": 18478245.625628248,
    "YEAR_6": 22549332.720533356,
    "YEAR_7": 26620419.81543848,
    "YEAR_8": 30691506.910343587,
    "YEAR_9": 34762594.00524871,
    "YEAR_10": 38833681.10015382,
    "AD_Izak": 202943994.48480022
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Izak",
    "Total Households": 483472.013,
    "Total Smart Devices": 2564533.659,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 102581.34636,
    "Total AD revenue": 26158.243321799997,
    "YEAR_1": 0,
    "YEAR_2": 5995968.845317842,
    "YEAR_3": 9892245.365630697,
    "YEAR_4": 13788521.885943549,
    "YEAR_5": 17684798.4062564,
    "YEAR_6": 21581074.92656925,
    "YEAR_7": 25477351.446882103,
    "YEAR_8": 29373627.967194963,
    "YEAR_9": 33269904.487507816,
    "YEAR_10": 37166181.007820666,
    "AD_Izak": 194229674.33912328
  },
  {
    "DMA": "Columbus",
    "PlatformName": "Izak",
    "Total Households": 467063.8491,
    "Total Smart Devices": 2468934.437,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 98757.37748,
    "Total AD revenue": 25183.131257399997,
    "YEAR_1": 0,
    "YEAR_2": 5772454.540977561,
    "YEAR_3": 9523487.889015567,
    "YEAR_4": 13274521.237053573,
    "YEAR_5": 17025554.58509158,
    "YEAR_6": 20776587.933129586,
    "YEAR_7": 24527621.281167593,
    "YEAR_8": 28278654.629205607,
    "YEAR_9": 32029687.97724361,
    "YEAR_10": 35780721.32528161,
    "AD_Izak": 186989291.3981663
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Izak",
    "Total Households": 465244.9759,
    "Total Smart Devices": 2488288.448,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 99531.53792,
    "Total AD revenue": 25380.5421696,
    "YEAR_1": 0,
    "YEAR_2": 5817704.891496723,
    "YEAR_3": 9598142.641527485,
    "YEAR_4": 13378580.391558243,
    "YEAR_5": 17159018.141589,
    "YEAR_6": 20939455.89161976,
    "YEAR_7": 24719893.641650517,
    "YEAR_8": 28500331.391681276,
    "YEAR_9": 32280769.141712032,
    "YEAR_10": 36061206.891742796,
    "AD_Izak": 188455103.02457783
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Izak",
    "Total Households": 462778.7076,
    "Total Smart Devices": 2468450.947,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 98738.03788,
    "Total AD revenue": 25178.1996594,
    "YEAR_1": 0,
    "YEAR_2": 5771324.124550058,
    "YEAR_3": 9521622.90990132,
    "YEAR_4": 13271921.69525258,
    "YEAR_5": 17022220.48060384,
    "YEAR_6": 20772519.265955105,
    "YEAR_7": 24522818.051306367,
    "YEAR_8": 28273116.83665763,
    "YEAR_9": 32023415.622008894,
    "YEAR_10": 35773714.40736015,
    "AD_Izak": 186952673.39359593
  },
  {
    "DMA": "Austin",
    "PlatformName": "Izak",
    "Total Households": 446505.4231,
    "Total Smart Devices": 2371566.242,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 94862.64968,
    "Total AD revenue": 24189.9756684,
    "YEAR_1": 0,
    "YEAR_2": 5544804.316268683,
    "YEAR_3": 9147906.904781517,
    "YEAR_4": 12751009.49329435,
    "YEAR_5": 16354112.081807185,
    "YEAR_6": 19957214.67032002,
    "YEAR_7": 23560317.25883285,
    "YEAR_8": 27163419.847345687,
    "YEAR_9": 30766522.435858525,
    "YEAR_10": 34369625.024371356,
    "AD_Izak": 179614932.03288016
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Izak",
    "Total Households": 435280.4295,
    "Total Smart Devices": 2303023.424,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 92120.93696,
    "Total AD revenue": 23490.838924800002,
    "YEAR_1": 0,
    "YEAR_2": 5384548.825038926,
    "YEAR_3": 8883514.830484407,
    "YEAR_4": 12382480.83592989,
    "YEAR_5": 15881446.84137537,
    "YEAR_6": 19380412.84682085,
    "YEAR_7": 22879378.85226633,
    "YEAR_8": 26378344.857711818,
    "YEAR_9": 29877310.86315729,
    "YEAR_10": 33376276.868602775,
    "AD_Izak": 174423715.62138766
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Izak",
    "Total Households": 432293.6432,
    "Total Smart Devices": 2307327.852,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 92293.11408,
    "Total AD revenue": 23534.7440904,
    "YEAR_1": 0,
    "YEAR_2": 5394612.727337239,
    "YEAR_3": 8900118.417567484,
    "YEAR_4": 12405624.107797733,
    "YEAR_5": 15911129.79802797,
    "YEAR_6": 19416635.488258217,
    "YEAR_7": 22922141.178488463,
    "YEAR_8": 26427646.86871871,
    "YEAR_9": 29933152.55894896,
    "YEAR_10": 33438658.249179207,
    "AD_Izak": 174749719.39432397
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Izak",
    "Total Households": 410759.7487,
    "Total Smart Devices": 2174029.485,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 86961.1794,
    "Total AD revenue": 22175.100746999997,
    "YEAR_1": 0,
    "YEAR_2": 5082956.511456104,
    "YEAR_3": 8385943.004593545,
    "YEAR_4": 11688929.497730989,
    "YEAR_5": 14991915.990868429,
    "YEAR_6": 18294902.484005872,
    "YEAR_7": 21597888.977143317,
    "YEAR_8": 24900875.47028076,
    "YEAR_9": 28203861.963418197,
    "YEAR_10": 31506848.456555642,
    "AD_Izak": 164654122.35605288
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Izak",
    "Total Households": 405294.1314,
    "Total Smart Devices": 2150472.593,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 86018.90372,
    "Total AD revenue": 21934.8204486,
    "YEAR_1": 0,
    "YEAR_2": 5027879.679054694,
    "YEAR_3": 8295076.365000861,
    "YEAR_4": 11562273.050947027,
    "YEAR_5": 14829469.736893196,
    "YEAR_6": 18096666.422839362,
    "YEAR_7": 21363863.10878553,
    "YEAR_8": 24631059.7947317,
    "YEAR_9": 27898256.480677865,
    "YEAR_10": 31165453.166624032,
    "AD_Izak": 162869997.80555427
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Izak",
    "Total Households": 397095.0494,
    "Total Smart Devices": 2107672.982,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 84306.91928,
    "Total AD revenue": 21498.264416399998,
    "YEAR_1": 0,
    "YEAR_2": 4927812.700698953,
    "YEAR_3": 8129984.262551855,
    "YEAR_4": 11332155.82440476,
    "YEAR_5": 14534327.386257662,
    "YEAR_6": 17736498.948110566,
    "YEAR_7": 20938670.509963475,
    "YEAR_8": 24140842.07181638,
    "YEAR_9": 27343013.63366928,
    "YEAR_10": 30545185.195522185,
    "AD_Izak": 159628490.53299513
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Izak",
    "Total Households": 360748.9799,
    "Total Smart Devices": 1917944.712,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 76717.78848,
    "Total AD revenue": 19563.0360624,
    "YEAR_1": 0,
    "YEAR_2": 4484221.409937869,
    "YEAR_3": 7398140.2514389455,
    "YEAR_4": 10312059.092940021,
    "YEAR_5": 13225977.934441099,
    "YEAR_6": 16139896.775942177,
    "YEAR_7": 19053815.617443252,
    "YEAR_8": 21967734.458944328,
    "YEAR_9": 24881653.3004454,
    "YEAR_10": 27795572.141946483,
    "AD_Izak": 145259070.9834796
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Izak",
    "Total Households": 356382.6492,
    "Total Smart Devices": 1886199.232,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 75447.96928,
    "Total AD revenue": 19239.2321664,
    "YEAR_1": 0,
    "YEAR_2": 4409999.374133559,
    "YEAR_3": 7275687.548855907,
    "YEAR_4": 10141375.723578256,
    "YEAR_5": 13007063.898300605,
    "YEAR_6": 15872752.073022954,
    "YEAR_7": 18738440.2477453,
    "YEAR_8": 21604128.42246765,
    "YEAR_9": 24469816.59719,
    "YEAR_10": 27335504.77191235,
    "AD_Izak": 142854768.6572066
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Izak",
    "Total Households": 352428.5811,
    "Total Smart Devices": 1885454.401,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 75418.17604,
    "Total AD revenue": 19231.634890200003,
    "YEAR_1": 0,
    "YEAR_2": 4408257.933363088,
    "YEAR_3": 7272814.492001275,
    "YEAR_4": 10137371.05063946,
    "YEAR_5": 13001927.609277647,
    "YEAR_6": 15866484.167915836,
    "YEAR_7": 18731040.72655402,
    "YEAR_8": 21595597.285192207,
    "YEAR_9": 24460153.84383039,
    "YEAR_10": 27324710.40246858,
    "AD_Izak": 142798357.5112425
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Izak",
    "Total Households": 352346.2667,
    "Total Smart Devices": 1862144.776,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 74485.79104000001,
    "Total AD revenue": 18993.876715200004,
    "YEAR_1": 0,
    "YEAR_2": 4353759.219803392,
    "YEAR_3": 7182901.642126358,
    "YEAR_4": 10012044.064449323,
    "YEAR_5": 12841186.486772288,
    "YEAR_6": 15670328.909095254,
    "YEAR_7": 18499471.33141822,
    "YEAR_8": 21328613.753741186,
    "YEAR_9": 24157756.176064156,
    "YEAR_10": 26986898.598387115,
    "AD_Izak": 141032960.1818573
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Izak",
    "Total Households": 349621.3646,
    "Total Smart Devices": 1846925.874,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 73877.03496,
    "Total AD revenue": 18838.643914800003,
    "YEAR_1": 0,
    "YEAR_2": 4318176.897874527,
    "YEAR_3": 7124197.357918134,
    "YEAR_4": 9930217.81796174,
    "YEAR_5": 12736238.278005349,
    "YEAR_6": 15542258.738048956,
    "YEAR_7": 18348279.19809256,
    "YEAR_8": 21154299.658136167,
    "YEAR_9": 23960320.118179776,
    "YEAR_10": 26766340.57822338,
    "AD_Izak": 139880328.6424406
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Izak",
    "Total Households": 339203.0215,
    "Total Smart Devices": 1813431.99,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 72537.2796,
    "Total AD revenue": 18497.006298,
    "YEAR_1": 0,
    "YEAR_2": 4239867.032738658,
    "YEAR_3": 6995000.489078765,
    "YEAR_4": 9750133.945418874,
    "YEAR_5": 12505267.40175898,
    "YEAR_6": 15260400.85809909,
    "YEAR_7": 18015534.314439192,
    "YEAR_8": 20770667.770779304,
    "YEAR_9": 23525801.227119412,
    "YEAR_10": 26280934.683459517,
    "AD_Izak": 137343607.7228918
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Izak",
    "Total Households": 337652.4085,
    "Total Smart Devices": 1792405.574,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 71696.22296,
    "Total AD revenue": 18282.536854799997,
    "YEAR_1": 0,
    "YEAR_2": 4190706.5411918806,
    "YEAR_3": 6913894.7233182425,
    "YEAR_4": 9637082.905444607,
    "YEAR_5": 12360271.08757097,
    "YEAR_6": 15083459.269697335,
    "YEAR_7": 17806647.451823696,
    "YEAR_8": 20529835.63395006,
    "YEAR_9": 23253023.81607642,
    "YEAR_10": 25976211.998202786,
    "AD_Izak": 135751133.42727602
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Izak",
    "Total Households": 329032.0001,
    "Total Smart Devices": 1750390.958,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 70015.63832000001,
    "Total AD revenue": 17853.9877716,
    "YEAR_1": 0,
    "YEAR_2": 4092474.908434826,
    "YEAR_3": 6751830.603412398,
    "YEAR_4": 9411186.29838997,
    "YEAR_5": 12070541.993367545,
    "YEAR_6": 14729897.688345112,
    "YEAR_7": 17389253.383322686,
    "YEAR_8": 20048609.078300256,
    "YEAR_9": 22707964.77327783,
    "YEAR_10": 25367320.468255408,
    "AD_Izak": 132569079.19510603
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Izak",
    "Total Households": 328886.9953,
    "Total Smart Devices": 1750628.806,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 70025.15224000001,
    "Total AD revenue": 17856.413821200003,
    "YEAR_1": 0,
    "YEAR_2": 4093031.0053271083,
    "YEAR_3": 6752748.060965537,
    "YEAR_4": 9412465.116603965,
    "YEAR_5": 12072182.172242394,
    "YEAR_6": 14731899.22788082,
    "YEAR_7": 17391616.28351925,
    "YEAR_8": 20051333.33915768,
    "YEAR_9": 22711050.394796107,
    "YEAR_10": 25370767.450434536,
    "AD_Izak": 132587093.0509274
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Izak",
    "Total Households": 313540.2409,
    "Total Smart Devices": 1672507.553,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 66900.30212000001,
    "Total AD revenue": 17059.5770406,
    "YEAR_1": 0,
    "YEAR_2": 3910380.8000933644,
    "YEAR_3": 6451408.8290804485,
    "YEAR_4": 8992436.858067535,
    "YEAR_5": 11533464.887054617,
    "YEAR_6": 14074492.916041702,
    "YEAR_7": 16615520.945028787,
    "YEAR_8": 19156548.97401587,
    "YEAR_9": 21697577.003002953,
    "YEAR_10": 24238605.031990036,
    "AD_Izak": 126670436.24437532
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Izak",
    "Total Households": 302974.3303,
    "Total Smart Devices": 1641227.699,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 65649.10796000001,
    "Total AD revenue": 16740.5225298,
    "YEAR_1": 0,
    "YEAR_2": 3837247.4140635533,
    "YEAR_3": 6330752.198319063,
    "YEAR_4": 8824256.982574575,
    "YEAR_5": 11317761.766830085,
    "YEAR_6": 13811266.551085595,
    "YEAR_7": 16304771.335341105,
    "YEAR_8": 18798276.119596615,
    "YEAR_9": 21291780.903852127,
    "YEAR_10": 23785285.688107636,
    "AD_Izak": 124301398.95977035
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Izak",
    "Total Households": 294028.9123,
    "Total Smart Devices": 1564688.779,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 62587.55116,
    "Total AD revenue": 15959.8255458,
    "YEAR_1": 0,
    "YEAR_2": 3658296.7583902613,
    "YEAR_3": 6035516.5425095735,
    "YEAR_4": 8412736.326628884,
    "YEAR_5": 10789956.110748194,
    "YEAR_6": 13167175.894867506,
    "YEAR_7": 15544395.678986814,
    "YEAR_8": 17921615.46310613,
    "YEAR_9": 20298835.247225437,
    "YEAR_10": 22676055.03134475,
    "AD_Izak": 118504583.05380756
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Izak",
    "Total Households": 289369.7588,
    "Total Smart Devices": 1553299.908,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 62131.996320000006,
    "Total AD revenue": 15843.6590616,
    "YEAR_1": 0,
    "YEAR_2": 3631669.1820823057,
    "YEAR_3": 5991585.940946149,
    "YEAR_4": 8351502.699809993,
    "YEAR_5": 10711419.458673837,
    "YEAR_6": 13071336.21753768,
    "YEAR_7": 15431252.976401525,
    "YEAR_8": 17791169.735265367,
    "YEAR_9": 20151086.49412921,
    "YEAR_10": 22511003.252993055,
    "AD_Izak": 117642025.9578391
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Izak",
    "Total Households": 288436.6661,
    "Total Smart Devices": 1532512.639,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 61300.50556,
    "Total AD revenue": 15631.628917799999,
    "YEAR_1": 0,
    "YEAR_2": 3583067.8245349675,
    "YEAR_3": 5911402.643406763,
    "YEAR_4": 8239737.462278558,
    "YEAR_5": 10568072.281150352,
    "YEAR_6": 12896407.100022148,
    "YEAR_7": 15224741.918893943,
    "YEAR_8": 17553076.737765737,
    "YEAR_9": 19881411.556637533,
    "YEAR_10": 22209746.37550933,
    "AD_Izak": 116067663.90019932
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Izak",
    "Total Households": 282061.5188,
    "Total Smart Devices": 1507192.296,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 60287.69184000001,
    "Total AD revenue": 15373.361419200002,
    "YEAR_1": 0,
    "YEAR_2": 3523867.9823929234,
    "YEAR_3": 5813733.796355796,
    "YEAR_4": 8103599.61031867,
    "YEAR_5": 10393465.424281543,
    "YEAR_6": 12683331.238244416,
    "YEAR_7": 14973197.052207287,
    "YEAR_8": 17263062.86617016,
    "YEAR_9": 19552928.680133037,
    "YEAR_10": 21842794.494095907,
    "AD_Izak": 114149981.14419974
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Izak",
    "Total Households": 277518.8074,
    "Total Smart Devices": 1471541.76,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 58861.6704,
    "Total AD revenue": 15009.725951999999,
    "YEAR_1": 0,
    "YEAR_2": 3440515.789909618,
    "YEAR_3": 5676218.015156899,
    "YEAR_4": 7911920.240404178,
    "YEAR_5": 10147622.46565146,
    "YEAR_6": 12383324.690898737,
    "YEAR_7": 14619026.916146021,
    "YEAR_8": 16854729.1413933,
    "YEAR_9": 19090431.366640583,
    "YEAR_10": 21326133.591887858,
    "AD_Izak": 111449922.21808866
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Izak",
    "Total Households": 269420.2905,
    "Total Smart Devices": 1452147.042,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 58085.88168,
    "Total AD revenue": 14811.899828399999,
    "YEAR_1": 0,
    "YEAR_2": 3395170.265009363,
    "YEAR_3": 5601406.242427807,
    "YEAR_4": 7807642.219846247,
    "YEAR_5": 10013878.197264694,
    "YEAR_6": 12220114.174683137,
    "YEAR_7": 14426350.152101582,
    "YEAR_8": 16632586.129520021,
    "YEAR_9": 18838822.10693847,
    "YEAR_10": 21045058.08435691,
    "AD_Izak": 109981027.57214823
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Izak",
    "Total Households": 268637.3894,
    "Total Smart Devices": 1459002.839,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 58360.11356,
    "Total AD revenue": 14881.828957799999,
    "YEAR_1": 0,
    "YEAR_2": 3411199.3567226115,
    "YEAR_3": 5627851.294479648,
    "YEAR_4": 7844503.232236684,
    "YEAR_5": 10061155.169993721,
    "YEAR_6": 12277807.107750759,
    "YEAR_7": 14494459.045507794,
    "YEAR_8": 16711110.983264832,
    "YEAR_9": 18927762.92102187,
    "YEAR_10": 21144414.8587789,
    "AD_Izak": 110500263.96975681
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Izak",
    "Total Households": 264094.9286,
    "Total Smart Devices": 1405447.572,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 56217.90288,
    "Total AD revenue": 14335.565234400001,
    "YEAR_1": 0,
    "YEAR_2": 3285985.280741292,
    "YEAR_3": 5421271.107892258,
    "YEAR_4": 7556556.935043223,
    "YEAR_5": 9691842.76219419,
    "YEAR_6": 11827128.589345153,
    "YEAR_7": 13962414.416496124,
    "YEAR_8": 16097700.24364709,
    "YEAR_9": 18232986.070798054,
    "YEAR_10": 20368271.897949018,
    "AD_Izak": 106444157.30410638
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Izak",
    "Total Households": 263354.2167,
    "Total Smart Devices": 1404565.359,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 56182.61436,
    "Total AD revenue": 14326.566661800001,
    "YEAR_1": 0,
    "YEAR_2": 3283922.636079028,
    "YEAR_3": 5417868.123716122,
    "YEAR_4": 7551813.611353215,
    "YEAR_5": 9685759.098990308,
    "YEAR_6": 11819704.5866274,
    "YEAR_7": 13953650.074264495,
    "YEAR_8": 16087595.561901588,
    "YEAR_9": 18221541.04953868,
    "YEAR_10": 20355486.537175775,
    "AD_Izak": 106377341.2796466
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Izak",
    "Total Households": 255378.7046,
    "Total Smart Devices": 1362932.794,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 54517.311760000004,
    "Total AD revenue": 13901.9144988,
    "YEAR_1": 0,
    "YEAR_2": 3186584.2518411656,
    "YEAR_3": 5257277.699513555,
    "YEAR_4": 7327971.147185941,
    "YEAR_5": 9398664.594858328,
    "YEAR_6": 11469358.04253072,
    "YEAR_7": 13540051.490203105,
    "YEAR_8": 15610744.937875492,
    "YEAR_9": 17681438.38554788,
    "YEAR_10": 19752131.83322027,
    "AD_Izak": 103224222.38277647
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Izak",
    "Total Households": 248118.8406,
    "Total Smart Devices": 1337968.086,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 53518.723439999994,
    "Total AD revenue": 13647.274477199997,
    "YEAR_1": 0,
    "YEAR_2": 3128215.896692017,
    "YEAR_3": 5160980.653011297,
    "YEAR_4": 7193745.409330577,
    "YEAR_5": 9226510.16564986,
    "YEAR_6": 11259274.921969138,
    "YEAR_7": 13292039.678288415,
    "YEAR_8": 15324804.434607694,
    "YEAR_9": 17357569.190926977,
    "YEAR_10": 19390333.947246257,
    "AD_Izak": 101333474.29772222
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Izak",
    "Total Households": 233138.7861,
    "Total Smart Devices": 1249823.665,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 49992.9466,
    "Total AD revenue": 12748.201383000001,
    "YEAR_1": 0,
    "YEAR_2": 2922131.176240088,
    "YEAR_3": 4820978.782853177,
    "YEAR_4": 6719826.389466265,
    "YEAR_5": 8618673.996079354,
    "YEAR_6": 10517521.602692444,
    "YEAR_7": 12416369.209305532,
    "YEAR_8": 14315216.81591862,
    "YEAR_9": 16214064.422531711,
    "YEAR_10": 18112912.0291448,
    "AD_Izak": 94657694.42423198
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Izak",
    "Total Households": 224431.25,
    "Total Smart Devices": 1202380.339,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 48095.21356,
    "Total AD revenue": 12264.2794578,
    "YEAR_1": 0,
    "YEAR_2": 2811207.0307854386,
    "YEAR_3": 4637974.352356985,
    "YEAR_4": 6464741.673928532,
    "YEAR_5": 8291508.995500078,
    "YEAR_6": 10118276.317071626,
    "YEAR_7": 11945043.638643172,
    "YEAR_8": 13771810.96021472,
    "YEAR_9": 15598578.281786269,
    "YEAR_10": 17425345.603357814,
    "AD_Izak": 91064486.85364464
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Izak",
    "Total Households": 223876.3561,
    "Total Smart Devices": 1219379.365,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 48775.1746,
    "Total AD revenue": 12437.669522999999,
    "YEAR_1": 0,
    "YEAR_2": 2850951.34450854,
    "YEAR_3": 4703545.157239424,
    "YEAR_4": 6556138.969970309,
    "YEAR_5": 8408732.782701194,
    "YEAR_6": 10261326.59543208,
    "YEAR_7": 12113920.40816296,
    "YEAR_8": 13966514.220893847,
    "YEAR_9": 15819108.03362473,
    "YEAR_10": 17671701.846355613,
    "AD_Izak": 92351939.3588887
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Izak",
    "Total Households": 220999.1372,
    "Total Smart Devices": 1183469.762,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 47338.79048,
    "Total AD revenue": 12071.3915724,
    "YEAR_1": 0,
    "YEAR_2": 2766993.44437332,
    "YEAR_3": 4565030.069862134,
    "YEAR_4": 6363066.695350947,
    "YEAR_5": 8161103.320839761,
    "YEAR_6": 9959139.946328577,
    "YEAR_7": 11757176.571817387,
    "YEAR_8": 13555213.1973062,
    "YEAR_9": 15353249.822795015,
    "YEAR_10": 17151286.44828383,
    "AD_Izak": 89632259.51695716
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Izak",
    "Total Households": 219918.6638,
    "Total Smart Devices": 1187777.437,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 47511.09748,
    "Total AD revenue": 12115.329857399998,
    "YEAR_1": 0,
    "YEAR_2": 2777064.9382705083,
    "YEAR_3": 4581646.181686536,
    "YEAR_4": 6386227.425102567,
    "YEAR_5": 8190808.668518595,
    "YEAR_6": 9995389.911934623,
    "YEAR_7": 11799971.155350652,
    "YEAR_8": 13604552.398766682,
    "YEAR_9": 15409133.64218271,
    "YEAR_10": 17213714.885598738,
    "AD_Izak": 89958509.2074116
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Izak",
    "Total Households": 215517.5199,
    "Total Smart Devices": 1143912.87,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 45756.514800000004,
    "Total AD revenue": 11667.911274,
    "YEAR_1": 0,
    "YEAR_2": 2674508.0557658304,
    "YEAR_3": 4412446.195522057,
    "YEAR_4": 6150384.335278284,
    "YEAR_5": 7888322.475034509,
    "YEAR_6": 9626260.614790734,
    "YEAR_7": 11364198.75454696,
    "YEAR_8": 13102136.89430319,
    "YEAR_9": 14840075.034059417,
    "YEAR_10": 16578013.173815645,
    "AD_Izak": 86636345.53311661
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Izak",
    "Total Households": 215017.5031,
    "Total Smart Devices": 1152245.338,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 46089.81352,
    "Total AD revenue": 11752.9024476,
    "YEAR_1": 0,
    "YEAR_2": 2693989.6556104156,
    "YEAR_3": 4444587.250745877,
    "YEAR_4": 6195184.845881339,
    "YEAR_5": 7945782.441016803,
    "YEAR_6": 9696380.036152264,
    "YEAR_7": 11446977.631287726,
    "YEAR_8": 13197575.226423187,
    "YEAR_9": 14948172.821558649,
    "YEAR_10": 16698770.416694112,
    "AD_Izak": 87267420.32537037
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Izak",
    "Total Households": 208836.9871,
    "Total Smart Devices": 1130925.32,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 45237.012800000004,
    "Total AD revenue": 11535.438264000002,
    "YEAR_1": 0,
    "YEAR_2": 2644142.7123811888,
    "YEAR_3": 4362348.96601309,
    "YEAR_4": 6080555.219644992,
    "YEAR_5": 7798761.473276892,
    "YEAR_6": 9516967.726908794,
    "YEAR_7": 11235173.980540695,
    "YEAR_8": 12953380.234172594,
    "YEAR_9": 14671586.487804495,
    "YEAR_10": 16389792.741436396,
    "AD_Izak": 85652709.54217914
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Izak",
    "Total Households": 208257.7812,
    "Total Smart Devices": 1109995.797,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 44399.831880000005,
    "Total AD revenue": 11321.9571294,
    "YEAR_1": 0,
    "YEAR_2": 2595208.760036692,
    "YEAR_3": 4281616.948254217,
    "YEAR_4": 5968025.136471743,
    "YEAR_5": 7654433.324689269,
    "YEAR_6": 9340841.512906794,
    "YEAR_7": 11027249.701124318,
    "YEAR_8": 12713657.889341846,
    "YEAR_9": 14400066.077559372,
    "YEAR_10": 16086474.265776897,
    "AD_Izak": 84067573.61616115
  },
  {
    "DMA": "Madison",
    "PlatformName": "Izak",
    "Total Households": 207493.6933,
    "Total Smart Devices": 1112970.681,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 44518.827240000006,
    "Total AD revenue": 11352.300946200003,
    "YEAR_1": 0,
    "YEAR_2": 2602164.1422442277,
    "YEAR_3": 4293092.049140109,
    "YEAR_4": 5984019.956035993,
    "YEAR_5": 7674947.862931874,
    "YEAR_6": 9365875.769827755,
    "YEAR_7": 11056803.67672364,
    "YEAR_8": 12747731.58361952,
    "YEAR_9": 14438659.4905154,
    "YEAR_10": 16129587.397411283,
    "AD_Izak": 84292881.9284498
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Izak",
    "Total Households": 206161.9622,
    "Total Smart Devices": 1102039.859,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 44081.594359999996,
    "Total AD revenue": 11240.806561799998,
    "YEAR_1": 0,
    "YEAR_2": 2576607.5004213736,
    "YEAR_3": 4250928.292430361,
    "YEAR_4": 5925249.084439348,
    "YEAR_5": 7599569.876448335,
    "YEAR_6": 9273890.668457322,
    "YEAR_7": 10948211.46046631,
    "YEAR_8": 12622532.252475295,
    "YEAR_9": 14296853.044484284,
    "YEAR_10": 15971173.836493274,
    "AD_Izak": 83465016.0161159
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Izak",
    "Total Households": 201717.9411,
    "Total Smart Devices": 1078841.85,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 43153.674000000006,
    "Total AD revenue": 11004.186870000001,
    "YEAR_1": 0,
    "YEAR_2": 2522369.7489497704,
    "YEAR_3": 4161445.9819850423,
    "YEAR_4": 5800522.215020313,
    "YEAR_5": 7439598.448055584,
    "YEAR_6": 9078674.681090856,
    "YEAR_7": 10717750.914126126,
    "YEAR_8": 12356827.1471614,
    "YEAR_9": 13995903.380196672,
    "YEAR_10": 15634979.613231942,
    "AD_Izak": 81708072.12981771
  },
  {
    "DMA": "Springfield",
    "PlatformName": "Izak",
    "Total Households": 199105.668,
    "Total Smart Devices": 1058242.196,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 42329.68784,
    "Total AD revenue": 10794.070399199998,
    "YEAR_1": 0,
    "YEAR_2": 2474207.04179447,
    "YEAR_3": 4081986.3768829755,
    "YEAR_4": 5689765.711971481,
    "YEAR_5": 7297545.047059985,
    "YEAR_6": 8905324.38214849,
    "YEAR_7": 10513103.717236996,
    "YEAR_8": 12120883.0523255,
    "YEAR_9": 13728662.387414008,
    "YEAR_10": 15336441.722502511,
    "AD_Izak": 80147919.43933642
  },
  {
    "DMA": "Columbia",
    "PlatformName": "Izak",
    "Total Households": 198756.344,
    "Total Smart Devices": 1058408.268,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 42336.33072,
    "Total AD revenue": 10795.7643336,
    "YEAR_1": 0,
    "YEAR_2": 2474595.323903612,
    "YEAR_3": 4082626.9709210363,
    "YEAR_4": 5690658.617938462,
    "YEAR_5": 7298690.264955886,
    "YEAR_6": 8906721.911973312,
    "YEAR_7": 10514753.558990737,
    "YEAR_8": 12122785.206008162,
    "YEAR_9": 13730816.853025585,
    "YEAR_10": 15338848.500043007,
    "AD_Izak": 80160497.2077598
  },
  {
    "DMA": "Rochester",
    "PlatformName": "Izak",
    "Total Households": 198132.6983,
    "Total Smart Devices": 1061121.486,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 42444.85944,
    "Total AD revenue": 10823.4391572,
    "YEAR_1": 0,
    "YEAR_2": 2480938.921906883,
    "YEAR_3": 4093092.7404352147,
    "YEAR_4": 5705246.558963545,
    "YEAR_5": 7317400.377491878,
    "YEAR_6": 8929554.19602021,
    "YEAR_7": 10541708.014548542,
    "YEAR_8": 12153861.833076874,
    "YEAR_9": 13766015.6516052,
    "YEAR_10": 15378169.47013354,
    "AD_Izak": 80365987.76418188
  },
  {
    "DMA": "Portland",
    "PlatformName": "Izak",
    "Total Households": 195810.9974,
    "Total Smart Devices": 1036069.954,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 41442.79816,
    "Total AD revenue": 10567.9135308,
    "YEAR_1": 0,
    "YEAR_2": 2422367.5692274827,
    "YEAR_3": 3996460.785358602,
    "YEAR_4": 5570554.001489721,
    "YEAR_5": 7144647.21762084,
    "YEAR_6": 8718740.43375196,
    "YEAR_7": 10292833.64988308,
    "YEAR_8": 11866926.866014201,
    "YEAR_9": 13441020.082145318,
    "YEAR_10": 15015113.298276437,
    "AD_Izak": 78468663.90376765
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Izak",
    "Total Households": 195141.7252,
    "Total Smart Devices": 1038545,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 41541.8,
    "Total AD revenue": 10593.159,
    "YEAR_1": 0,
    "YEAR_2": 2428154.312815209,
    "YEAR_3": 4006007.8475456387,
    "YEAR_4": 5583861.382276067,
    "YEAR_5": 7161714.917006496,
    "YEAR_6": 8739568.451736927,
    "YEAR_7": 10317421.986467358,
    "YEAR_8": 11895275.521197783,
    "YEAR_9": 13473129.055928212,
    "YEAR_10": 15050982.590658644,
    "AD_Izak": 78656116.06563234
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Izak",
    "Total Households": 193660.7387,
    "Total Smart Devices": 1041756.757,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 41670.27028,
    "Total AD revenue": 10625.918921399998,
    "YEAR_1": 0,
    "YEAR_2": 2435663.512331132,
    "YEAR_3": 4018396.6450906736,
    "YEAR_4": 5601129.777850216,
    "YEAR_5": 7183862.910609758,
    "YEAR_6": 8766596.043369299,
    "YEAR_7": 10349329.176128842,
    "YEAR_8": 11932062.308888385,
    "YEAR_9": 13514795.441647928,
    "YEAR_10": 15097528.57440747,
    "AD_Izak": 78899364.3903237
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Izak",
    "Total Households": 188171.9961,
    "Total Smart Devices": 1004152.231,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 40166.08924,
    "Total AD revenue": 10242.3527562,
    "YEAR_1": 0,
    "YEAR_2": 2347742.8233015076,
    "YEAR_3": 3873343.6851715245,
    "YEAR_4": 5398944.547041541,
    "YEAR_5": 6924545.408911558,
    "YEAR_6": 8450146.270781573,
    "YEAR_7": 9975747.132651592,
    "YEAR_8": 11501347.994521609,
    "YEAR_9": 13026948.856391625,
    "YEAR_10": 14552549.71826164,
    "AD_Izak": 76051316.43703417
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Izak",
    "Total Households": 186023.7374,
    "Total Smart Devices": 1011839.034,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 40473.56136,
    "Total AD revenue": 10320.758146799999,
    "YEAR_1": 0,
    "YEAR_2": 2365714.835930918,
    "YEAR_3": 3902994.1992470217,
    "YEAR_4": 5440273.562563126,
    "YEAR_5": 6977552.925879231,
    "YEAR_6": 8514832.289195333,
    "YEAR_7": 10052111.652511438,
    "YEAR_8": 11589391.015827542,
    "YEAR_9": 13126670.379143648,
    "YEAR_10": 14663949.742459752,
    "AD_Izak": 76633490.60275802
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Izak",
    "Total Households": 184130.2376,
    "Total Smart Devices": 1001954.872,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 40078.19488,
    "Total AD revenue": 10219.939694400002,
    "YEAR_1": 0,
    "YEAR_2": 2342605.321572982,
    "YEAR_3": 3864867.752594821,
    "YEAR_4": 5387130.183616659,
    "YEAR_5": 6909392.614638496,
    "YEAR_6": 8431655.045660336,
    "YEAR_7": 9953917.476682175,
    "YEAR_8": 11476179.907704014,
    "YEAR_9": 12998442.338725854,
    "YEAR_10": 14520704.769747693,
    "AD_Izak": 75884895.41094302
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Izak",
    "Total Households": 183224.9582,
    "Total Smart Devices": 986286.69,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 39451.467599999996,
    "Total AD revenue": 10060.124237999999,
    "YEAR_1": 0,
    "YEAR_2": 2305972.5673858495,
    "YEAR_3": 3804430.44843489,
    "YEAR_4": 5302888.329483929,
    "YEAR_5": 6801346.21053297,
    "YEAR_6": 8299804.09158201,
    "YEAR_7": 9798261.97263105,
    "YEAR_8": 11296719.85368009,
    "YEAR_9": 12795177.734729128,
    "YEAR_10": 14293635.615778167,
    "AD_Izak": 74698236.82423808
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Izak",
    "Total Households": 177354.3904,
    "Total Smart Devices": 952657.6974,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 38106.307896,
    "Total AD revenue": 9717.10851348,
    "YEAR_1": 0,
    "YEAR_2": 2227346.8136464157,
    "YEAR_3": 3674712.4215216083,
    "YEAR_4": 5122078.0293968,
    "YEAR_5": 6569443.637271994,
    "YEAR_6": 8016809.245147186,
    "YEAR_7": 9464174.85302238,
    "YEAR_8": 10911540.460897572,
    "YEAR_9": 12358906.068772763,
    "YEAR_10": 13806271.676647957,
    "AD_Izak": 72151283.20632468
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Izak",
    "Total Households": 176036.0966,
    "Total Smart Devices": 959703.0583,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 38388.122332,
    "Total AD revenue": 9788.971194659998,
    "YEAR_1": 0,
    "YEAR_2": 2243819.1123476513,
    "YEAR_3": 3701888.683555696,
    "YEAR_4": 5159958.25476374,
    "YEAR_5": 6618027.825971783,
    "YEAR_6": 8076097.397179827,
    "YEAR_7": 9534166.968387872,
    "YEAR_8": 10992236.539595917,
    "YEAR_9": 12450306.110803962,
    "YEAR_10": 13908375.682012005,
    "AD_Izak": 72684876.57461846
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Izak",
    "Total Households": 172513.4188,
    "Total Smart Devices": 927387.5876,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 37095.503504,
    "Total AD revenue": 9459.353393520001,
    "YEAR_1": 0,
    "YEAR_2": 2168264.418472221,
    "YEAR_3": 3577237.3403579225,
    "YEAR_4": 4986210.262243625,
    "YEAR_5": 6395183.184129327,
    "YEAR_6": 7804156.106015028,
    "YEAR_7": 9213129.02790073,
    "YEAR_8": 10622101.949786432,
    "YEAR_9": 12031074.871672135,
    "YEAR_10": 13440047.793557834,
    "AD_Izak": 70237404.95413525
  },
  {
    "DMA": "Charleston",
    "PlatformName": "Izak",
    "Total Households": 170796.4251,
    "Total Smart Devices": 912456.1162,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 36498.244648,
    "Total AD revenue": 9307.05238524,
    "YEAR_1": 0,
    "YEAR_2": 2133354.119278073,
    "YEAR_3": 3519641.770013062,
    "YEAR_4": 4905929.42074805,
    "YEAR_5": 6292217.071483041,
    "YEAR_6": 7678504.72221803,
    "YEAR_7": 9064792.372953018,
    "YEAR_8": 10451080.023688009,
    "YEAR_9": 11837367.674422998,
    "YEAR_10": 13223655.325157989,
    "AD_Izak": 69106542.49996227
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Izak",
    "Total Households": 170460.101,
    "Total Smart Devices": 925622.1874,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 37024.887496,
    "Total AD revenue": 9441.34631148,
    "YEAR_1": 0,
    "YEAR_2": 2164136.851434226,
    "YEAR_3": 3570427.614197517,
    "YEAR_4": 4976718.376960808,
    "YEAR_5": 6383009.1397241,
    "YEAR_6": 7789299.902487392,
    "YEAR_7": 9195590.665250683,
    "YEAR_8": 10601881.428013973,
    "YEAR_9": 12008172.190777263,
    "YEAR_10": 13414462.953540556,
    "AD_Izak": 70103699.12238652
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Izak",
    "Total Households": 170257.3444,
    "Total Smart Devices": 922574.2281,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 36902.969123999996,
    "Total AD revenue": 9410.257126619998,
    "YEAR_1": 0,
    "YEAR_2": 2157010.6166349817,
    "YEAR_3": 3558670.6379713537,
    "YEAR_4": 4960330.659307724,
    "YEAR_5": 6361990.680644097,
    "YEAR_6": 7763650.701980469,
    "YEAR_7": 9165310.723316839,
    "YEAR_8": 10566970.744653212,
    "YEAR_9": 11968630.765989583,
    "YEAR_10": 13370290.787325956,
    "AD_Izak": 69872856.31782421
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Izak",
    "Total Households": 169682.3867,
    "Total Smart Devices": 960358.8983,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 38414.355932,
    "Total AD revenue": 9795.660762659998,
    "YEAR_1": 0,
    "YEAR_2": 2245352.489066538,
    "YEAR_3": 3704418.47300799,
    "YEAR_4": 5163484.456949442,
    "YEAR_5": 6622550.440890895,
    "YEAR_6": 8081616.424832348,
    "YEAR_7": 9540682.4087738,
    "YEAR_8": 10999748.392715253,
    "YEAR_9": 12458814.376656704,
    "YEAR_10": 13917880.360598156,
    "AD_Izak": 72734547.82349113
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Izak",
    "Total Households": 168570.9748,
    "Total Smart Devices": 965499.8531,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 38619.994124,
    "Total AD revenue": 9848.098501620001,
    "YEAR_1": 0,
    "YEAR_2": 2257372.2201033332,
    "YEAR_3": 3724248.817646575,
    "YEAR_4": 5191125.415189816,
    "YEAR_5": 6658002.012733057,
    "YEAR_6": 8124878.610276299,
    "YEAR_7": 9591755.20781954,
    "YEAR_8": 11058631.805362782,
    "YEAR_9": 12525508.402906023,
    "YEAR_10": 13992385.000449263,
    "AD_Izak": 73123907.49248669
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Izak",
    "Total Households": 164675.9115,
    "Total Smart Devices": 878517.1744,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 35140.686976000005,
    "Total AD revenue": 8960.87517888,
    "YEAR_1": 0,
    "YEAR_2": 2054003.6935343118,
    "YEAR_3": 3388728.1676287698,
    "YEAR_4": 4723452.641723229,
    "YEAR_5": 6058177.1158176875,
    "YEAR_6": 7392901.589912145,
    "YEAR_7": 8727626.064006604,
    "YEAR_8": 10062350.538101062,
    "YEAR_9": 11397075.012195518,
    "YEAR_10": 12731799.486289978,
    "AD_Izak": 66536114.30920931
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Izak",
    "Total Households": 164390.6513,
    "Total Smart Devices": 901897.6009,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 36075.904036,
    "Total AD revenue": 9199.355529179998,
    "YEAR_1": 0,
    "YEAR_2": 2108667.943462272,
    "YEAR_3": 3478914.1220534346,
    "YEAR_4": 4849160.300644597,
    "YEAR_5": 6219406.47923576,
    "YEAR_6": 7589652.657826923,
    "YEAR_7": 8959898.836418085,
    "YEAR_8": 10330145.015009249,
    "YEAR_9": 11700391.19360041,
    "YEAR_10": 13070637.372191573,
    "AD_Izak": 68306873.9204423
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Izak",
    "Total Households": 161325.6758,
    "Total Smart Devices": 877940.0386,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 35117.601544,
    "Total AD revenue": 8954.98839372,
    "YEAR_1": 0,
    "YEAR_2": 2052654.329971009,
    "YEAR_3": 3386501.966025661,
    "YEAR_4": 4720349.602080314,
    "YEAR_5": 6054197.238134967,
    "YEAR_6": 7388044.874189621,
    "YEAR_7": 8721892.510244273,
    "YEAR_8": 10055740.146298926,
    "YEAR_9": 11389587.782353578,
    "YEAR_10": 12723435.418408234,
    "AD_Izak": 66492403.86770658
  },
  {
    "DMA": "Jackson",
    "PlatformName": "Izak",
    "Total Households": 155479.6092,
    "Total Smart Devices": 832501.5841,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 33300.063364,
    "Total AD revenue": 8491.516157819999,
    "YEAR_1": 0,
    "YEAR_2": 1946417.643778468,
    "YEAR_3": 3211230.9808422127,
    "YEAR_4": 4476044.317905957,
    "YEAR_5": 5740857.654969702,
    "YEAR_6": 7005670.992033447,
    "YEAR_7": 8270484.329097192,
    "YEAR_8": 9535297.666160937,
    "YEAR_9": 10800111.00322468,
    "YEAR_10": 12064924.340288427,
    "AD_Izak": 63051038.92830102
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Izak",
    "Total Households": 154389.0105,
    "Total Smart Devices": 835761.6251,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 33430.465004,
    "Total AD revenue": 8524.768576019998,
    "YEAR_1": 0,
    "YEAR_2": 1954039.7329648812,
    "YEAR_3": 3223806.025572407,
    "YEAR_4": 4493572.318179933,
    "YEAR_5": 5763338.610787459,
    "YEAR_6": 7033104.903394984,
    "YEAR_7": 8302871.196002509,
    "YEAR_8": 9572637.488610037,
    "YEAR_9": 10842403.78121756,
    "YEAR_10": 12112170.073825086,
    "AD_Izak": 63297944.130554855
  },
  {
    "DMA": "Boise",
    "PlatformName": "Izak",
    "Total Households": 150604.699,
    "Total Smart Devices": 805064.7535,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 32202.59014,
    "Total AD revenue": 8211.6604857,
    "YEAR_1": 0,
    "YEAR_2": 1882269.3800524184,
    "YEAR_3": 3105398.1486631734,
    "YEAR_4": 4328526.917273929,
    "YEAR_5": 5551655.685884685,
    "YEAR_6": 6774784.45449544,
    "YEAR_7": 7997913.223106196,
    "YEAR_8": 9221041.991716951,
    "YEAR_9": 10444170.760327706,
    "YEAR_10": 11667299.528938461,
    "AD_Izak": 60973060.09045896
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Izak",
    "Total Households": 150182.6846,
    "Total Smart Devices": 818778.2968,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 32751.131872,
    "Total AD revenue": 8351.53862736,
    "YEAR_1": 0,
    "YEAR_2": 1914332.1210100784,
    "YEAR_3": 3158295.771854713,
    "YEAR_4": 4402259.422699347,
    "YEAR_5": 5646223.073543982,
    "YEAR_6": 6890186.724388616,
    "YEAR_7": 8134150.37523325,
    "YEAR_8": 9378114.026077885,
    "YEAR_9": 10622077.67692252,
    "YEAR_10": 11866041.32776715,
    "AD_Izak": 62011680.519497536
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Izak",
    "Total Households": 146983.955,
    "Total Smart Devices": 788153.8513,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 31526.154052,
    "Total AD revenue": 8039.16928326,
    "YEAR_1": 0,
    "YEAR_2": 1842731.1028371542,
    "YEAR_3": 3040167.2661089497,
    "YEAR_4": 4237603.429380746,
    "YEAR_5": 5435039.59265254,
    "YEAR_6": 6632475.755924335,
    "YEAR_7": 7829911.919196131,
    "YEAR_8": 9027348.082467925,
    "YEAR_9": 10224784.24573972,
    "YEAR_10": 11422220.409011515,
    "AD_Izak": 59692281.803319015
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Izak",
    "Total Households": 146431.9358,
    "Total Smart Devices": 817105.2424,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 32684.209696,
    "Total AD revenue": 8334.47347248,
    "YEAR_1": 0,
    "YEAR_2": 1910420.4616626888,
    "YEAR_3": 3151842.2536578397,
    "YEAR_4": 4393264.045652991,
    "YEAR_5": 5634685.837648142,
    "YEAR_6": 6876107.629643293,
    "YEAR_7": 8117529.421638444,
    "YEAR_8": 9358951.213633595,
    "YEAR_9": 10600373.005628748,
    "YEAR_10": 11841794.797623897,
    "AD_Izak": 61884968.66678964
  },
  {
    "DMA": "Reno",
    "PlatformName": "Izak",
    "Total Households": 140080.9776,
    "Total Smart Devices": 766046.8896,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 30641.875584,
    "Total AD revenue": 7813.67827392,
    "YEAR_1": 0,
    "YEAR_2": 1791044.2578808975,
    "YEAR_3": 2954893.4820595426,
    "YEAR_4": 4118742.7062381883,
    "YEAR_5": 5282591.930416834,
    "YEAR_6": 6446441.154595478,
    "YEAR_7": 7610290.378774123,
    "YEAR_8": 8774139.602952767,
    "YEAR_9": 9937988.827131413,
    "YEAR_10": 11101838.051310059,
    "AD_Izak": 58017970.3913593
  },
  {
    "DMA": "Washington",
    "PlatformName": "Izak",
    "Total Households": 139336.8283,
    "Total Smart Devices": 757188.4939,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 30287.539756000002,
    "Total AD revenue": 7723.32263778,
    "YEAR_1": 0,
    "YEAR_2": 1770333.0207909509,
    "YEAR_3": 2920723.751628156,
    "YEAR_4": 4071114.4824653612,
    "YEAR_5": 5221505.213302567,
    "YEAR_6": 6371895.944139772,
    "YEAR_7": 7522286.674976976,
    "YEAR_8": 8672677.405814182,
    "YEAR_9": 9823068.136651387,
    "YEAR_10": 10973458.867488593,
    "AD_Izak": 57347063.49725794
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Izak",
    "Total Households": 138117.7876,
    "Total Smart Devices": 772710.2,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 30908.408,
    "Total AD revenue": 7881.64404,
    "YEAR_1": 0,
    "YEAR_2": 1806623.3092319577,
    "YEAR_3": 2980596.0503190146,
    "YEAR_4": 4154568.791406071,
    "YEAR_5": 5328541.532493127,
    "YEAR_6": 6502514.273580183,
    "YEAR_7": 7676487.014667242,
    "YEAR_8": 8850459.755754296,
    "YEAR_9": 10024432.496841354,
    "YEAR_10": 11198405.23792841,
    "AD_Izak": 58522628.46222165
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Izak",
    "Total Households": 136727.054,
    "Total Smart Devices": 775398.3021,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 31015.932084,
    "Total AD revenue": 7909.0626814199995,
    "YEAR_1": 0,
    "YEAR_2": 1812908.1853879276,
    "YEAR_3": 2990964.9395896792,
    "YEAR_4": 4169021.6937914314,
    "YEAR_5": 5347078.447993182,
    "YEAR_6": 6525135.202194935,
    "YEAR_7": 7703191.956396687,
    "YEAR_8": 8881248.710598437,
    "YEAR_9": 10059305.46480019,
    "YEAR_10": 11237362.219001941,
    "AD_Izak": 58726216.819754414
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Izak",
    "Total Households": 133095.6859,
    "Total Smart Devices": 720630.3035,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 28825.212140000003,
    "Total AD revenue": 7350.429095700001,
    "YEAR_1": 0,
    "YEAR_2": 1684858.7007677653,
    "YEAR_3": 2779706.8504496156,
    "YEAR_4": 3874555.000131466,
    "YEAR_5": 4969403.149813317,
    "YEAR_6": 6064251.299495166,
    "YEAR_7": 7159099.449177017,
    "YEAR_8": 8253947.598858869,
    "YEAR_9": 9348795.74854072,
    "YEAR_10": 10443643.89822257,
    "AD_Izak": 54578261.695456505
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Izak",
    "Total Households": 132803.551,
    "Total Smart Devices": 728058.3645,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 29122.334580000002,
    "Total AD revenue": 7426.195317900001,
    "YEAR_1": 0,
    "YEAR_2": 1702225.7656065584,
    "YEAR_3": 2808359.31197805,
    "YEAR_4": 3914492.8583495417,
    "YEAR_5": 5020626.404721033,
    "YEAR_6": 6126759.951092524,
    "YEAR_7": 7232893.497464017,
    "YEAR_8": 8339027.043835508,
    "YEAR_9": 9445160.590207,
    "YEAR_10": 10551294.136578491,
    "AD_Izak": 55140839.55983273
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Izak",
    "Total Households": 130234.779,
    "Total Smart Devices": 714649.2487,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 28585.969948,
    "Total AD revenue": 7289.42233674,
    "YEAR_1": 0,
    "YEAR_2": 1670874.7867266745,
    "YEAR_3": 2756635.9652540768,
    "YEAR_4": 3842397.1437814794,
    "YEAR_5": 4928158.322308881,
    "YEAR_6": 6013919.500836282,
    "YEAR_7": 7099680.679363684,
    "YEAR_8": 8185441.8578910865,
    "YEAR_9": 9271203.036418488,
    "YEAR_10": 10356964.21494589,
    "AD_Izak": 54125275.50752655
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Izak",
    "Total Households": 129762.1381,
    "Total Smart Devices": 723124.6195,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 28924.984780000003,
    "Total AD revenue": 7375.871118900001,
    "YEAR_1": 0,
    "YEAR_2": 1690690.4982853727,
    "YEAR_3": 2789328.2433312517,
    "YEAR_4": 3887965.9883771306,
    "YEAR_5": 4986603.7334230095,
    "YEAR_6": 6085241.4784688875,
    "YEAR_7": 7183879.2235147655,
    "YEAR_8": 8282516.968560645,
    "YEAR_9": 9381154.713606525,
    "YEAR_10": 10479792.458652403,
    "AD_Izak": 54767173.306219995
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Izak",
    "Total Households": 129216.6201,
    "Total Smart Devices": 722266.5486,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 28890.661944,
    "Total AD revenue": 7367.11879572,
    "YEAR_1": 0,
    "YEAR_2": 1688684.2986921568,
    "YEAR_3": 2786018.3831334263,
    "YEAR_4": 3883352.467574694,
    "YEAR_5": 4980686.552015964,
    "YEAR_6": 6078020.636457233,
    "YEAR_7": 7175354.7208985025,
    "YEAR_8": 8272688.80533977,
    "YEAR_9": 9370022.889781041,
    "YEAR_10": 10467356.974222308,
    "AD_Izak": 54702185.7281151
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Izak",
    "Total Households": 128748.7925,
    "Total Smart Devices": 712861.0057,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 28514.440228,
    "Total AD revenue": 7271.182258139999,
    "YEAR_1": 0,
    "YEAR_2": 1666693.8124281978,
    "YEAR_3": 2749738.1269405526,
    "YEAR_4": 3832782.441452908,
    "YEAR_5": 4915826.755965264,
    "YEAR_6": 5998871.070477618,
    "YEAR_7": 7081915.384989971,
    "YEAR_8": 8164959.699502326,
    "YEAR_9": 9248004.014014684,
    "YEAR_10": 10331048.328527037,
    "AD_Izak": 53989839.63429856
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Izak",
    "Total Households": 126228.7724,
    "Total Smart Devices": 693737.697,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 27749.50788,
    "Total AD revenue": 7076.1245094,
    "YEAR_1": 0,
    "YEAR_2": 1621982.8519063122,
    "YEAR_3": 2675973.268679006,
    "YEAR_4": 3729963.6854517004,
    "YEAR_5": 4783954.102224395,
    "YEAR_6": 5837944.518997088,
    "YEAR_7": 6891934.935769783,
    "YEAR_8": 7945925.352542478,
    "YEAR_9": 8999915.76931517,
    "YEAR_10": 10053906.186087864,
    "AD_Izak": 52541500.6709738
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Izak",
    "Total Households": 121298.6731,
    "Total Smart Devices": 661038.8766,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 26441.555064,
    "Total AD revenue": 6742.59654132,
    "YEAR_1": 0,
    "YEAR_2": 1545531.8731059425,
    "YEAR_3": 2549843.2202671557,
    "YEAR_4": 3554154.5674283686,
    "YEAR_5": 4558465.914589582,
    "YEAR_6": 5562777.261750795,
    "YEAR_7": 6567088.608912009,
    "YEAR_8": 7571399.956073223,
    "YEAR_9": 8575711.303234432,
    "YEAR_10": 9580022.650395649,
    "AD_Izak": 50064995.35575715
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Izak",
    "Total Households": 120688.59,
    "Total Smart Devices": 672775.9124,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 26911.036496,
    "Total AD revenue": 6862.31430648,
    "YEAR_1": 0,
    "YEAR_2": 1572973.4708195094,
    "YEAR_3": 2595116.807373852,
    "YEAR_4": 3617260.143928195,
    "YEAR_5": 4639403.480482536,
    "YEAR_6": 5661546.817036879,
    "YEAR_7": 6683690.153591222,
    "YEAR_8": 7705833.490145565,
    "YEAR_9": 8727976.826699907,
    "YEAR_10": 9750120.16325425,
    "AD_Izak": 50953921.35333191
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Izak",
    "Total Households": 119010.7855,
    "Total Smart Devices": 647106.4308,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 25884.257232,
    "Total AD revenue": 6600.48559416,
    "YEAR_1": 0,
    "YEAR_2": 1512957.3304936013,
    "YEAR_3": 2496101.2185144112,
    "YEAR_4": 3479245.1065352215,
    "YEAR_5": 4462388.99455603,
    "YEAR_6": 5445532.882576841,
    "YEAR_7": 6428676.770597652,
    "YEAR_8": 7411820.65861846,
    "YEAR_9": 8394964.546639271,
    "YEAR_10": 9378108.434660083,
    "AD_Izak": 49009795.94319157
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Izak",
    "Total Households": 118760.2771,
    "Total Smart Devices": 650772.6644,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 26030.906576,
    "Total AD revenue": 6637.881176880001,
    "YEAR_1": 0,
    "YEAR_2": 1521529.1120992398,
    "YEAR_3": 2510243.0809975346,
    "YEAR_4": 3498957.049895829,
    "YEAR_5": 4487671.018794123,
    "YEAR_6": 5476384.987692419,
    "YEAR_7": 6465098.956590713,
    "YEAR_8": 7453812.925489008,
    "YEAR_9": 8442526.894387301,
    "YEAR_10": 9431240.863285597,
    "AD_Izak": 49287464.88923177
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Izak",
    "Total Households": 117920.6242,
    "Total Smart Devices": 655342.6546,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 26213.706184000002,
    "Total AD revenue": 6684.4950769199995,
    "YEAR_1": 0,
    "YEAR_2": 1532213.9080528608,
    "YEAR_3": 2527871.0283704516,
    "YEAR_4": 3523528.1486880435,
    "YEAR_5": 4519185.269005635,
    "YEAR_6": 5514842.389323226,
    "YEAR_7": 6510499.509640817,
    "YEAR_8": 7506156.629958409,
    "YEAR_9": 8501813.750276,
    "YEAR_10": 9497470.87059359,
    "AD_Izak": 49633581.50390904
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Izak",
    "Total Households": 115972.0606,
    "Total Smart Devices": 631244.1375,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 25249.765499999998,
    "Total AD revenue": 6438.6902025,
    "YEAR_1": 0,
    "YEAR_2": 1475870.7373392025,
    "YEAR_3": 2434915.163562656,
    "YEAR_4": 3393959.589786109,
    "YEAR_5": 4353004.016009562,
    "YEAR_6": 5312048.442233015,
    "YEAR_7": 6271092.868456468,
    "YEAR_8": 7230137.294679922,
    "YEAR_9": 8189181.720903374,
    "YEAR_10": 9148226.147126827,
    "AD_Izak": 47808435.98009713
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Izak",
    "Total Households": 114467.5114,
    "Total Smart Devices": 615432.0772,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 24617.283088000004,
    "Total AD revenue": 6277.407187440001,
    "YEAR_1": 0,
    "YEAR_2": 1438901.590685048,
    "YEAR_3": 2373922.873726717,
    "YEAR_4": 3308944.1567683867,
    "YEAR_5": 4243965.439810056,
    "YEAR_6": 5178986.722851726,
    "YEAR_7": 6114008.005893396,
    "YEAR_8": 7049029.288935065,
    "YEAR_9": 7984050.571976734,
    "YEAR_10": 8919071.855018405,
    "AD_Izak": 46610880.50566554
  },
  {
    "DMA": "Macon",
    "PlatformName": "Izak",
    "Total Households": 114376.1331,
    "Total Smart Devices": 623908.0976,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 24956.323904,
    "Total AD revenue": 6363.8625955200005,
    "YEAR_1": 0,
    "YEAR_2": 1458718.8210311278,
    "YEAR_3": 2406617.6575236227,
    "YEAR_4": 3354516.4940161174,
    "YEAR_5": 4302415.330508612,
    "YEAR_6": 5250314.167001106,
    "YEAR_7": 6198213.003493602,
    "YEAR_8": 7146111.839986095,
    "YEAR_9": 8094010.676478591,
    "YEAR_10": 9041909.512971085,
    "AD_Izak": 47252827.50300996
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Izak",
    "Total Households": 113695.8609,
    "Total Smart Devices": 615383.1435,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 24615.32574,
    "Total AD revenue": 6276.9080637,
    "YEAR_1": 0,
    "YEAR_2": 1438787.1819933713,
    "YEAR_3": 2373734.1204360947,
    "YEAR_4": 3308681.0588788185,
    "YEAR_5": 4243627.997321542,
    "YEAR_6": 5178574.935764265,
    "YEAR_7": 6113521.874206988,
    "YEAR_8": 7048468.812649712,
    "YEAR_9": 7983415.751092434,
    "YEAR_10": 8918362.689535158,
    "AD_Izak": 46607174.42187838
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Izak",
    "Total Households": 113237.908,
    "Total Smart Devices": 624929.966,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 24997.198640000002,
    "Total AD revenue": 6374.2856532,
    "YEAR_1": 0,
    "YEAR_2": 1461107.9848734164,
    "YEAR_3": 2410559.338268856,
    "YEAR_4": 3360010.691664296,
    "YEAR_5": 4309462.045059736,
    "YEAR_6": 5258913.3984551765,
    "YEAR_7": 6208364.751850615,
    "YEAR_8": 7157816.105246055,
    "YEAR_9": 8107267.458641496,
    "YEAR_10": 9056718.812036935,
    "AD_Izak": 47330220.586096585
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Izak",
    "Total Households": 112822.5815,
    "Total Smart Devices": 631714.9611,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 25268.598444,
    "Total AD revenue": 6443.49260322,
    "YEAR_1": 0,
    "YEAR_2": 1476971.5392831867,
    "YEAR_3": 2436731.2842280194,
    "YEAR_4": 3396491.0291728517,
    "YEAR_5": 4356250.774117683,
    "YEAR_6": 5316010.519062515,
    "YEAR_7": 6275770.264007349,
    "YEAR_8": 7235530.00895218,
    "YEAR_9": 8195289.753897013,
    "YEAR_10": 9155049.498841844,
    "AD_Izak": 47844094.67156264
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Izak",
    "Total Households": 110094.1168,
    "Total Smart Devices": 617472.2414,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 24698.889656000003,
    "Total AD revenue": 6298.216862280001,
    "YEAR_1": 0,
    "YEAR_2": 1443671.5655066313,
    "YEAR_3": 2381792.454530132,
    "YEAR_4": 3319913.3435536325,
    "YEAR_5": 4258034.232577133,
    "YEAR_6": 5196155.1216006335,
    "YEAR_7": 6134276.010624134,
    "YEAR_8": 7072396.899647636,
    "YEAR_9": 8010517.788671136,
    "YEAR_10": 8948638.677694635,
    "AD_Izak": 46765396.0944057
  },
  {
    "DMA": "Lafayette",
    "PlatformName": "Izak",
    "Total Households": 109608.0386,
    "Total Smart Devices": 607971.4294,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 24318.857176,
    "Total AD revenue": 6201.308579880001,
    "YEAR_1": 0,
    "YEAR_2": 1421458.336775044,
    "YEAR_3": 2345144.714249204,
    "YEAR_4": 3268831.0917233643,
    "YEAR_5": 4192517.4691975247,
    "YEAR_6": 5116203.846671686,
    "YEAR_7": 6039890.2241458455,
    "YEAR_8": 6963576.601620007,
    "YEAR_9": 7887262.979094168,
    "YEAR_10": 8810949.356568327,
    "AD_Izak": 46045834.62004517
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Izak",
    "Total Households": 107403.5907,
    "Total Smart Devices": 609396.2424,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 24375.849696,
    "Total AD revenue": 6215.84167248,
    "YEAR_1": 0,
    "YEAR_2": 1424789.599099647,
    "YEAR_3": 2350640.6841487125,
    "YEAR_4": 3276491.769197778,
    "YEAR_5": 4202342.854246843,
    "YEAR_6": 5128193.939295908,
    "YEAR_7": 6054045.024344973,
    "YEAR_8": 6979896.10939404,
    "YEAR_9": 7905747.194443104,
    "YEAR_10": 8831598.279492171,
    "AD_Izak": 46153745.45366318
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Izak",
    "Total Households": 106297.9921,
    "Total Smart Devices": 597656.2835,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 23906.251340000003,
    "Total AD revenue": 6096.0940917,
    "YEAR_1": 0,
    "YEAR_2": 1397341.1670766647,
    "YEAR_3": 2305355.8216889603,
    "YEAR_4": 3213370.4763012556,
    "YEAR_5": 4121385.130913551,
    "YEAR_6": 5029399.785525846,
    "YEAR_7": 5937414.440138143,
    "YEAR_8": 6845429.094750438,
    "YEAR_9": 7753443.749362735,
    "YEAR_10": 8661458.40397503,
    "AD_Izak": 45264598.06973263
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Izak",
    "Total Households": 103469.8371,
    "Total Smart Devices": 569961.0358,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 22798.441432,
    "Total AD revenue": 5813.602565159999,
    "YEAR_1": 0,
    "YEAR_2": 1332588.715187492,
    "YEAR_3": 2198526.190208456,
    "YEAR_4": 3064463.6652294192,
    "YEAR_5": 3930401.140250384,
    "YEAR_6": 4796338.615271348,
    "YEAR_7": 5662276.090292311,
    "YEAR_8": 6528213.565313274,
    "YEAR_9": 7394151.040334238,
    "YEAR_10": 8260088.515355201,
    "AD_Izak": 43167047.537442125
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Izak",
    "Total Households": 100198.4171,
    "Total Smart Devices": 552884.0625,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 22115.3625,
    "Total AD revenue": 5639.4174375,
    "YEAR_1": 0,
    "YEAR_2": 1292662.1579673185,
    "YEAR_3": 2132654.7170877657,
    "YEAR_4": 2972647.2762082135,
    "YEAR_5": 3812639.8353286604,
    "YEAR_6": 4652632.394449107,
    "YEAR_7": 5492624.953569554,
    "YEAR_8": 6332617.512690001,
    "YEAR_9": 7172610.071810448,
    "YEAR_10": 8012602.630930894,
    "AD_Izak": 41873691.55004196
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Izak",
    "Total Households": 95167.68885,
    "Total Smart Devices": 537565.9934,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 21502.639736,
    "Total AD revenue": 5483.17313268,
    "YEAR_1": 0,
    "YEAR_2": 1256847.9799113206,
    "YEAR_3": 2073567.9129301736,
    "YEAR_4": 2890287.8459490268,
    "YEAR_5": 3707007.7789678792,
    "YEAR_6": 4523727.711986733,
    "YEAR_7": 5340447.645005586,
    "YEAR_8": 6157167.5780244395,
    "YEAR_9": 6973887.51104329,
    "YEAR_10": 7790607.444062146,
    "AD_Izak": 40713549.40788059
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Izak",
    "Total Households": 89961.70526,
    "Total Smart Devices": 510390.503,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 20415.620120000003,
    "Total AD revenue": 5205.9831306000015,
    "YEAR_1": 0,
    "YEAR_2": 1193310.738657809,
    "YEAR_3": 1968743.1554056557,
    "YEAR_4": 2744175.572153502,
    "YEAR_5": 3519607.988901349,
    "YEAR_6": 4295040.4056491945,
    "YEAR_7": 5070472.822397041,
    "YEAR_8": 5845905.239144888,
    "YEAR_9": 6621337.6558927335,
    "YEAR_10": 7396770.072640579,
    "AD_Izak": 38655363.650842756
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Izak",
    "Total Households": 86254.9588,
    "Total Smart Devices": 480098.5248,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 19203.940992,
    "Total AD revenue": 4897.004952959999,
    "YEAR_1": 0,
    "YEAR_2": 1122487.0405898057,
    "YEAR_3": 1851897.0848098872,
    "YEAR_4": 2581307.1290299688,
    "YEAR_5": 3310717.1732500503,
    "YEAR_6": 4040127.217470132,
    "YEAR_7": 4769537.261690212,
    "YEAR_8": 5498947.305910295,
    "YEAR_9": 6228357.3501303755,
    "YEAR_10": 6957767.394350458,
    "AD_Izak": 36361144.95723119
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Izak",
    "Total Households": 86231.4576,
    "Total Smart Devices": 492907.9867,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 19716.319468,
    "Total AD revenue": 5027.66146434,
    "YEAR_1": 0,
    "YEAR_2": 1152436.0078057926,
    "YEAR_3": 1901307.370251767,
    "YEAR_4": 2650178.732697741,
    "YEAR_5": 3399050.095143716,
    "YEAR_6": 4147921.4575896906,
    "YEAR_7": 4896792.820035665,
    "YEAR_8": 5645664.182481639,
    "YEAR_9": 6394535.544927614,
    "YEAR_10": 7143406.907373588,
    "AD_Izak": 37331293.11830721
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Izak",
    "Total Households": 86036.0769,
    "Total Smart Devices": 470002.9309,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 18800.117236,
    "Total AD revenue": 4794.029895179999,
    "YEAR_1": 0,
    "YEAR_2": 1098883.191099686,
    "YEAR_3": 1812955.076144847,
    "YEAR_4": 2527026.9611900076,
    "YEAR_5": 3241098.846235169,
    "YEAR_6": 3955170.7312803296,
    "YEAR_7": 4669242.61632549,
    "YEAR_8": 5383314.501370652,
    "YEAR_9": 6097386.386415811,
    "YEAR_10": 6811458.271460973,
    "AD_Izak": 35596536.581522964
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Izak",
    "Total Households": 84647.46866,
    "Total Smart Devices": 465738.6675,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 18629.5467,
    "Total AD revenue": 4750.5344085,
    "YEAR_1": 0,
    "YEAR_2": 1088913.1950324094,
    "YEAR_3": 1796506.4170646905,
    "YEAR_4": 2504099.639096971,
    "YEAR_5": 3211692.861129252,
    "YEAR_6": 3919286.083161533,
    "YEAR_7": 4626879.305193814,
    "YEAR_8": 5334472.527226095,
    "YEAR_9": 6042065.749258376,
    "YEAR_10": 6749658.971290655,
    "AD_Izak": 35273574.748453796
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Izak",
    "Total Households": 83560.12063,
    "Total Smart Devices": 454093.9776,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 18163.759104,
    "Total AD revenue": 4631.758571519999,
    "YEAR_1": 0,
    "YEAR_2": 1061687.505243252,
    "YEAR_3": 1751589.0383072598,
    "YEAR_4": 2441490.5713712685,
    "YEAR_5": 3131392.1044352762,
    "YEAR_6": 3821293.6374992835,
    "YEAR_7": 4511195.170563294,
    "YEAR_8": 5201096.703627301,
    "YEAR_9": 5890998.23669131,
    "YEAR_10": 6580899.769755318,
    "AD_Izak": 34391642.73749357
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Izak",
    "Total Households": 82931.47493,
    "Total Smart Devices": 451807.1961,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 18072.287844000002,
    "Total AD revenue": 4608.43340022,
    "YEAR_1": 0,
    "YEAR_2": 1056340.9306011414,
    "YEAR_3": 1742768.1738915422,
    "YEAR_4": 2429195.4171819435,
    "YEAR_5": 3115622.6604723446,
    "YEAR_6": 3802049.9037627457,
    "YEAR_7": 4488477.147053147,
    "YEAR_8": 5174904.390343548,
    "YEAR_9": 5861331.633633947,
    "YEAR_10": 6547758.876924349,
    "AD_Izak": 34218449.13386471
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Izak",
    "Total Households": 82818.72073,
    "Total Smart Devices": 466140.3129,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 18645.612516,
    "Total AD revenue": 4754.631191580001,
    "YEAR_1": 0,
    "YEAR_2": 1089852.2559399607,
    "YEAR_3": 1798055.694779504,
    "YEAR_4": 2506259.1336190477,
    "YEAR_5": 3214462.5724585904,
    "YEAR_6": 3922666.011298134,
    "YEAR_7": 4630869.450137678,
    "YEAR_8": 5339072.88897722,
    "YEAR_9": 6047276.327816764,
    "YEAR_10": 6755479.7666563075,
    "AD_Izak": 35303994.10168321
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Izak",
    "Total Households": 79804.87057,
    "Total Smart Devices": 484230.3852,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 19369.215408,
    "Total AD revenue": 4939.14992904,
    "YEAR_1": 0,
    "YEAR_2": 1132147.473840373,
    "YEAR_3": 1867835.0222005283,
    "YEAR_4": 2603522.5705606835,
    "YEAR_5": 3339210.1189208385,
    "YEAR_6": 4074897.667280994,
    "YEAR_7": 4810585.215641149,
    "YEAR_8": 5546272.764001304,
    "YEAR_9": 6281960.31236146,
    "YEAR_10": 7017647.860721615,
    "AD_Izak": 36674079.00552894
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Izak",
    "Total Households": 79429.73327,
    "Total Smart Devices": 481277.3701,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 19251.094804,
    "Total AD revenue": 4909.02917502,
    "YEAR_1": 0,
    "YEAR_2": 1125243.221881264,
    "YEAR_3": 1856444.2768168228,
    "YEAR_4": 2587645.331752382,
    "YEAR_5": 3318846.386687941,
    "YEAR_6": 4050047.4416234996,
    "YEAR_7": 4781248.496559059,
    "YEAR_8": 5512449.551494619,
    "YEAR_9": 6243650.606430178,
    "YEAR_10": 6974851.661365737,
    "AD_Izak": 36450426.9746115
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Izak",
    "Total Households": 79143.66222,
    "Total Smart Devices": 453919.7864,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 18156.791456,
    "Total AD revenue": 4629.98182128,
    "YEAR_1": 0,
    "YEAR_2": 1061280.2401622643,
    "YEAR_3": 1750917.1258584273,
    "YEAR_4": 2440554.01155459,
    "YEAR_5": 3130190.897250753,
    "YEAR_6": 3819827.782946916,
    "YEAR_7": 4509464.668643079,
    "YEAR_8": 5199101.554339241,
    "YEAR_9": 5888738.440035405,
    "YEAR_10": 6578375.325731568,
    "AD_Izak": 34378450.046522245
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Izak",
    "Total Households": 78267.63343,
    "Total Smart Devices": 449346.8731,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 17973.874924,
    "Total AD revenue": 4583.33810562,
    "YEAR_1": 0,
    "YEAR_2": 1050588.609899228,
    "YEAR_3": 1733277.9031324536,
    "YEAR_4": 2415967.1963656796,
    "YEAR_5": 3098656.4895989057,
    "YEAR_6": 3781345.7828321317,
    "YEAR_7": 4464035.076065358,
    "YEAR_8": 5146724.369298584,
    "YEAR_9": 5829413.66253181,
    "YEAR_10": 6512102.955765037,
    "AD_Izak": 34032112.04548919
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Izak",
    "Total Households": 77131.53359,
    "Total Smart Devices": 443416.376,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 17736.65504,
    "Total AD revenue": 4522.8470351999995,
    "YEAR_1": 0,
    "YEAR_2": 1036722.9015182687,
    "YEAR_3": 1710402.0355268642,
    "YEAR_4": 2384081.1695354595,
    "YEAR_5": 3057760.3035440557,
    "YEAR_6": 3731439.4375526514,
    "YEAR_7": 4405118.571561247,
    "YEAR_8": 5078797.705569843,
    "YEAR_9": 5752476.839578439,
    "YEAR_10": 6426155.973587034,
    "AD_Izak": 33582954.937973864
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Izak",
    "Total Households": 76791.33485,
    "Total Smart Devices": 443630.0818,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 17745.203272,
    "Total AD revenue": 4525.02683436,
    "YEAR_1": 0,
    "YEAR_2": 1037222.5531076982,
    "YEAR_3": 1711226.3687159573,
    "YEAR_4": 2385230.184324216,
    "YEAR_5": 3059233.999932476,
    "YEAR_6": 3733237.815540734,
    "YEAR_7": 4407241.631148994,
    "YEAR_8": 5081245.446757252,
    "YEAR_9": 5755249.262365513,
    "YEAR_10": 6429253.077973771,
    "AD_Izak": 33599140.33986661
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Izak",
    "Total Households": 75854.67956,
    "Total Smart Devices": 423818.9958,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 16952.759832,
    "Total AD revenue": 4322.95375716,
    "YEAR_1": 0,
    "YEAR_2": 990903.5453492927,
    "YEAR_3": 1634808.528387034,
    "YEAR_4": 2278713.5114247752,
    "YEAR_5": 2922618.494462517,
    "YEAR_6": 3566523.477500258,
    "YEAR_7": 4210428.460537999,
    "YEAR_8": 4854333.443575741,
    "YEAR_9": 5498238.426613482,
    "YEAR_10": 6142143.409651224,
    "AD_Izak": 32098711.29750232
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Izak",
    "Total Households": 73564.47988,
    "Total Smart Devices": 393958.0018,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 15758.320072000002,
    "Total AD revenue": 4018.3716183600013,
    "YEAR_1": 0,
    "YEAR_2": 921087.5033231421,
    "YEAR_3": 1519624.9048565056,
    "YEAR_4": 2118162.306389869,
    "YEAR_5": 2716699.7079232326,
    "YEAR_6": 3315237.109456596,
    "YEAR_7": 3913774.5109899594,
    "YEAR_8": 4512311.912523324,
    "YEAR_9": 5110849.314056687,
    "YEAR_10": 5709386.71559005,
    "AD_Izak": 29837133.985109366
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Izak",
    "Total Households": 73134.65223,
    "Total Smart Devices": 422552.4588,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 16902.098352,
    "Total AD revenue": 4310.03507976,
    "YEAR_1": 0,
    "YEAR_2": 987942.3378148191,
    "YEAR_3": 1629923.0808029557,
    "YEAR_4": 2271903.8237910923,
    "YEAR_5": 2913884.566779229,
    "YEAR_6": 3555865.309767366,
    "YEAR_7": 4197846.052755501,
    "YEAR_8": 4839826.795743638,
    "YEAR_9": 5481807.538731775,
    "YEAR_10": 6123788.281719912,
    "AD_Izak": 32002787.78790629
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Izak",
    "Total Households": 71033.27061,
    "Total Smart Devices": 413572.7033,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 16542.908132,
    "Total AD revenue": 4218.44157366,
    "YEAR_1": 0,
    "YEAR_2": 966947.3572936563,
    "YEAR_3": 1595285.2259174753,
    "YEAR_4": 2223623.0945412945,
    "YEAR_5": 2851960.9631651137,
    "YEAR_6": 3480298.831788933,
    "YEAR_7": 4108636.700412753,
    "YEAR_8": 4736974.56903657,
    "YEAR_9": 5365312.4376603905,
    "YEAR_10": 5993650.30628421,
    "AD_Izak": 31322689.486100398
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Izak",
    "Total Households": 70940.83086,
    "Total Smart Devices": 389215.4444,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 15568.617776,
    "Total AD revenue": 3969.9975328799997,
    "YEAR_1": 0,
    "YEAR_2": 909999.2392569879,
    "YEAR_3": 1501331.3093340811,
    "YEAR_4": 2092663.3794111745,
    "YEAR_5": 2683995.4494882682,
    "YEAR_6": 3275327.5195653616,
    "YEAR_7": 3866659.589642455,
    "YEAR_8": 4457991.659719548,
    "YEAR_9": 5049323.7297966415,
    "YEAR_10": 5640655.799873736,
    "AD_Izak": 29477947.676088255
  },
  {
    "DMA": "Erie",
    "PlatformName": "Izak",
    "Total Households": 69589.91057,
    "Total Smart Devices": 409022.6328,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 16360.905312,
    "Total AD revenue": 4172.03085456,
    "YEAR_1": 0,
    "YEAR_2": 956309.1342911015,
    "YEAR_3": 1577734.11534462,
    "YEAR_4": 2199159.096398139,
    "YEAR_5": 2820584.0774516575,
    "YEAR_6": 3442009.058505176,
    "YEAR_7": 4063434.0395586947,
    "YEAR_8": 4684859.020612215,
    "YEAR_9": 5306284.001665732,
    "YEAR_10": 5927708.982719251,
    "AD_Izak": 30978081.526546583
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Izak",
    "Total Households": 69495.96962,
    "Total Smart Devices": 419474.8359,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 16778.993436,
    "Total AD revenue": 4278.64332618,
    "YEAR_1": 0,
    "YEAR_2": 980746.7484875834,
    "YEAR_3": 1618051.6823665022,
    "YEAR_4": 2255356.616245421,
    "YEAR_5": 2892661.5501243398,
    "YEAR_6": 3529966.4840032593,
    "YEAR_7": 4167271.417882178,
    "YEAR_8": 4804576.351761096,
    "YEAR_9": 5441881.285640015,
    "YEAR_10": 6079186.219518935,
    "AD_Izak": 31769698.35602933
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Izak",
    "Total Households": 69029.01668,
    "Total Smart Devices": 420021.6584,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 16800.866336,
    "Total AD revenue": 4284.22091568,
    "YEAR_1": 0,
    "YEAR_2": 982025.2385017083,
    "YEAR_3": 1620160.9556538556,
    "YEAR_4": 2258296.672806003,
    "YEAR_5": 2896432.3899581507,
    "YEAR_6": 3534568.1071102982,
    "YEAR_7": 4172703.8242624453,
    "YEAR_8": 4810839.541414592,
    "YEAR_9": 5448975.25856674,
    "YEAR_10": 6087110.9757188875,
    "AD_Izak": 31811112.963992685
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Izak",
    "Total Households": 68794.13478,
    "Total Smart Devices": 393926.0646,
    "House Holds considered for ads in % of Total HHS": 0.04,
    "% of the revenue for nexus(ads) after agency commission": 0.85,
    "CPM/1000 devices": 10,
    "No of Ads per Day": 1,
    "Monthly Adoption Growth Rate": 1.0208,
    "App Growth": 1.0133,
    "DMA Consdered": "Y",
    "Holiday Months": 12,
    "House Holds considered for ads": 15757.042583999999,
    "Total AD revenue": 4018.04585892,
    "YEAR_1": 0,
    "YEAR_2": 921012.8330393123,
    "YEAR_3": 1519501.7126271566,
    "YEAR_4": 2117990.5922150007,
    "YEAR_5": 2716479.471802845,
    "YEAR_6": 3314968.351390689,
    "YEAR_7": 3913457.230978533,
    "YEAR_8": 4511946.110566377,
    "YEAR_9": 5110434.99015422,
    "YEAR_10": 5708923.869742064,
    "AD_Izak": 29834715.1625162
  },
  {
    "DMA": "New York",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 17384805,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 3476961,
    "No of HHS for Ads": 8692.4025,
    "Revenue per 1000 Devices Hour": 221.65626375000002,
    "Revenue per 1000 Devices per day": 886.6250550000001,
    "Revenue per month(Video Ad)": 26598.751650000002,
    "Revenue per month (Banner Ad)": 265987.5165,
    "Total Revenue per month from all Ads": 292586.26815,
    "YEAR_1": 3514356.0584845343,
    "YEAR_2": 3521601.529068973,
    "YEAR_3": 3528846.9996534106,
    "YEAR_4": 3536092.4702378493,
    "YEAR_5": 3543337.940822287,
    "YEAR_6": 3550583.4114067256,
    "YEAR_7": 3557828.8819911634,
    "YEAR_8": 3565074.352575602,
    "YEAR_9": 3572319.8231600397,
    "YEAR_10": 3579565.2937444784,
    "AD_Reve": 35469606.76114506
  },
  {
    "DMA": "Los Angeles",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 13135702.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 2627140.5,
    "No of HHS for Ads": 6567.85125,
    "Revenue per 1000 Devices Hour": 167.480206875,
    "Revenue per 1000 Devices per day": 669.9208275,
    "Revenue per month(Video Ad)": 20097.624825,
    "Revenue per month (Banner Ad)": 200976.24825,
    "Total Revenue per month from all Ads": 221073.873075,
    "YEAR_1": 2655395.655190003,
    "YEAR_2": 2660870.2260045554,
    "YEAR_3": 2666344.7968191076,
    "YEAR_4": 2671819.36763366,
    "YEAR_5": 2677293.938448212,
    "YEAR_6": 2682768.5092627644,
    "YEAR_7": 2688243.0800773166,
    "YEAR_8": 2693717.6508918684,
    "YEAR_9": 2699192.2217064206,
    "YEAR_10": 2704666.792520973,
    "AD_Reve": 26800312.238554884
  },
  {
    "DMA": "Chicago",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 8155845,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1631169,
    "No of HHS for Ads": 4077.9225,
    "Revenue per 1000 Devices Hour": 103.98702374999999,
    "Revenue per 1000 Devices per day": 415.94809499999997,
    "Revenue per month(Video Ad)": 12478.44285,
    "Revenue per month (Banner Ad)": 124784.4285,
    "Total Revenue per month from all Ads": 137262.87135,
    "YEAR_1": 1648712.3834757307,
    "YEAR_2": 1652111.4975318694,
    "YEAR_3": 1655510.6115880087,
    "YEAR_4": 1658909.7256441475,
    "YEAR_5": 1662308.8397002867,
    "YEAR_6": 1665707.953756426,
    "YEAR_7": 1669107.0678125648,
    "YEAR_8": 1672506.1818687045,
    "YEAR_9": 1675905.295924843,
    "YEAR_10": 1679304.4099809825,
    "AD_Reve": 16640083.967283564
  },
  {
    "DMA": "Philadelphia",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 6995160,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1399032,
    "No of HHS for Ads": 3497.58,
    "Revenue per 1000 Devices Hour": 89.18829,
    "Revenue per 1000 Devices per day": 356.75316,
    "Revenue per month(Video Ad)": 10702.594799999999,
    "Revenue per month (Banner Ad)": 107025.94799999999,
    "Total Revenue per month from all Ads": 117728.5428,
    "YEAR_1": 1414078.727145267,
    "YEAR_2": 1416994.1021531224,
    "YEAR_3": 1419909.4771609777,
    "YEAR_4": 1422824.8521688331,
    "YEAR_5": 1425740.2271766884,
    "YEAR_6": 1428655.6021845436,
    "YEAR_7": 1431570.977192399,
    "YEAR_8": 1434486.352200254,
    "YEAR_9": 1437401.7272081098,
    "YEAR_10": 1440317.1022159653,
    "AD_Reve": 14271979.146806162
  },
  {
    "DMA": "Dallas-Ft. Worth",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 6843465,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1368693,
    "No of HHS for Ads": 3421.7325,
    "Revenue per 1000 Devices Hour": 87.25417875000001,
    "Revenue per 1000 Devices per day": 349.01671500000003,
    "Revenue per month(Video Ad)": 10470.501450000002,
    "Revenue per month (Banner Ad)": 104705.01450000002,
    "Total Revenue per month from all Ads": 115175.51595000002,
    "YEAR_1": 1383413.428207959,
    "YEAR_2": 1386265.5812435055,
    "YEAR_3": 1389117.734279052,
    "YEAR_4": 1391969.887314598,
    "YEAR_5": 1394822.0403501445,
    "YEAR_6": 1397674.1933856907,
    "YEAR_7": 1400526.3464212376,
    "YEAR_8": 1403378.4994567838,
    "YEAR_9": 1406230.65249233,
    "YEAR_10": 1409082.8055278766,
    "AD_Reve": 13962481.168679178
  },
  {
    "DMA": "Atlanta",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 6029662.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1205932.5,
    "No of HHS for Ads": 3014.83125,
    "Revenue per 1000 Devices Hour": 76.87819687500001,
    "Revenue per 1000 Devices per day": 307.51278750000006,
    "Revenue per month(Video Ad)": 9225.383625000002,
    "Revenue per month (Banner Ad)": 92253.83625000002,
    "Total Revenue per month from all Ads": 101479.21987500002,
    "YEAR_1": 1218902.4229775374,
    "YEAR_2": 1221415.407292164,
    "YEAR_3": 1223928.3916067905,
    "YEAR_4": 1226441.375921417,
    "YEAR_5": 1228954.3602360433,
    "YEAR_6": 1231467.34455067,
    "YEAR_7": 1233980.3288652962,
    "YEAR_8": 1236493.313179923,
    "YEAR_9": 1239006.2974945493,
    "YEAR_10": 1241519.2818091756,
    "AD_Reve": 12302108.523933567
  },
  {
    "DMA": "Houston",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 5999242.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1199848.5,
    "No of HHS for Ads": 2999.62125,
    "Revenue per 1000 Devices Hour": 76.490341875,
    "Revenue per 1000 Devices per day": 305.9613675,
    "Revenue per month(Video Ad)": 9178.841025,
    "Revenue per month (Banner Ad)": 91788.41025,
    "Total Revenue per month from all Ads": 100967.251275,
    "YEAR_1": 1212752.9889574775,
    "YEAR_2": 1215253.2951192472,
    "YEAR_3": 1217753.6012810166,
    "YEAR_4": 1220253.9074427863,
    "YEAR_5": 1222754.2136045557,
    "YEAR_6": 1225254.5197663254,
    "YEAR_7": 1227754.8259280948,
    "YEAR_8": 1230255.1320898642,
    "YEAR_9": 1232755.438251634,
    "YEAR_10": 1235255.7444134033,
    "AD_Reve": 12240043.666854404
  },
  {
    "DMA": "Washington-Hagerstown",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 5889037.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1177807.5,
    "No of HHS for Ads": 2944.51875,
    "Revenue per 1000 Devices Hour": 75.08522812500001,
    "Revenue per 1000 Devices per day": 300.34091250000006,
    "Revenue per month(Video Ad)": 9010.227375000002,
    "Revenue per month (Banner Ad)": 90102.27375000002,
    "Total Revenue per month from all Ads": 99112.50112500002,
    "YEAR_1": 1190474.935828594,
    "YEAR_2": 1192929.311818253,
    "YEAR_3": 1195383.687807912,
    "YEAR_4": 1197838.063797571,
    "YEAR_5": 1200292.43978723,
    "YEAR_6": 1202746.815776889,
    "YEAR_7": 1205201.191766548,
    "YEAR_8": 1207655.567756207,
    "YEAR_9": 1210109.9437458657,
    "YEAR_10": 1212564.319735525,
    "AD_Reve": 12015196.277820596
  },
  {
    "DMA": "Boston-Manchester",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 5841427.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1168285.5,
    "No of HHS for Ads": 2920.71375,
    "Revenue per 1000 Devices Hour": 74.478200625,
    "Revenue per 1000 Devices per day": 297.9128025,
    "Revenue per month(Video Ad)": 8937.384075,
    "Revenue per month (Banner Ad)": 89373.84075,
    "Total Revenue per month from all Ads": 98311.224825,
    "YEAR_1": 1180850.5257794475,
    "YEAR_2": 1183285.0593346052,
    "YEAR_3": 1185719.5928897632,
    "YEAR_4": 1188154.1264449214,
    "YEAR_5": 1190588.660000079,
    "YEAR_6": 1193023.1935552373,
    "YEAR_7": 1195457.7271103954,
    "YEAR_8": 1197892.2606655532,
    "YEAR_9": 1200326.794220711,
    "YEAR_10": 1202761.3277758693,
    "AD_Reve": 11918059.267776582
  },
  {
    "DMA": "San Francisco-Oakland-San Jose",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 5834722.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 1166944.5,
    "No of HHS for Ads": 2917.36125,
    "Revenue per 1000 Devices Hour": 74.39271187499999,
    "Revenue per 1000 Devices per day": 297.57084749999996,
    "Revenue per month(Video Ad)": 8927.125424999998,
    "Revenue per month (Banner Ad)": 89271.25424999998,
    "Total Revenue per month from all Ads": 98198.37967499997,
    "YEAR_1": 1179495.1031921855,
    "YEAR_2": 1181926.842302409,
    "YEAR_3": 1184358.5814126327,
    "YEAR_4": 1186790.320522856,
    "YEAR_5": 1189222.0596330797,
    "YEAR_6": 1191653.798743303,
    "YEAR_7": 1194085.5378535269,
    "YEAR_8": 1196517.2769637501,
    "YEAR_9": 1198949.0160739738,
    "YEAR_10": 1201380.755184197,
    "AD_Reve": 11904379.291881915
  },
  {
    "DMA": "Phoenix-Prescott",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 4812457.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 962491.5,
    "No of HHS for Ads": 2406.22875,
    "Revenue per 1000 Devices Hour": 61.358833125000004,
    "Revenue per 1000 Devices per day": 245.43533250000002,
    "Revenue per month(Video Ad)": 7363.059975,
    "Revenue per month (Banner Ad)": 73630.59975,
    "Total Revenue per month from all Ads": 80993.65972499999,
    "YEAR_1": 972843.1910121704,
    "YEAR_2": 974848.8804205421,
    "YEAR_3": 976854.5698289138,
    "YEAR_4": 978860.2592372856,
    "YEAR_5": 980865.9486456575,
    "YEAR_6": 982871.6380540292,
    "YEAR_7": 984877.3274624009,
    "YEAR_8": 986883.0168707728,
    "YEAR_9": 988888.7062791446,
    "YEAR_10": 990894.3956875163,
    "AD_Reve": 9818687.933498435
  },
  {
    "DMA": "Seattle-Tacoma",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 4761990,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 952398,
    "No of HHS for Ads": 2380.995,
    "Revenue per 1000 Devices Hour": 60.7153725,
    "Revenue per 1000 Devices per day": 242.86149,
    "Revenue per month(Video Ad)": 7285.8447,
    "Revenue per month (Banner Ad)": 72858.447,
    "Total Revenue per month from all Ads": 80144.2917,
    "YEAR_1": 962641.1344241576,
    "YEAR_2": 964625.790476865,
    "YEAR_3": 966610.4465295725,
    "YEAR_4": 968595.1025822798,
    "YEAR_5": 970579.7586349874,
    "YEAR_6": 972564.4146876947,
    "YEAR_7": 974549.0707404022,
    "YEAR_8": 976533.7267931098,
    "YEAR_9": 978518.382845817,
    "YEAR_10": 980503.0388985246,
    "AD_Reve": 9715720.866613409
  },
  {
    "DMA": "Tampa-St Petersburg-Sarasota",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 4654620,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 930924,
    "No of HHS for Ads": 2327.31,
    "Revenue per 1000 Devices Hour": 59.346405,
    "Revenue per 1000 Devices per day": 237.38562,
    "Revenue per month(Video Ad)": 7121.5686,
    "Revenue per month (Banner Ad)": 71215.686,
    "Total Revenue per month from all Ads": 78337.2546,
    "YEAR_1": 940936.1794361963,
    "YEAR_2": 942876.0868606245,
    "YEAR_3": 944815.9942850529,
    "YEAR_4": 946755.901709481,
    "YEAR_5": 948695.8091339094,
    "YEAR_6": 950635.7165583377,
    "YEAR_7": 952575.623982766,
    "YEAR_8": 954515.5314071942,
    "YEAR_9": 956455.4388316225,
    "YEAR_10": 958395.3462560507,
    "AD_Reve": 9496657.628461236
  },
  {
    "DMA": "Detroit",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 4358812.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 871762.5,
    "No of HHS for Ads": 2179.40625,
    "Revenue per 1000 Devices Hour": 55.574859375,
    "Revenue per 1000 Devices per day": 222.2994375,
    "Revenue per month(Video Ad)": 6668.983125000001,
    "Revenue per month (Banner Ad)": 66689.83125,
    "Total Revenue per month from all Ads": 73358.814375,
    "YEAR_1": 881138.3916686508,
    "YEAR_2": 882955.0153093433,
    "YEAR_3": 884771.6389500361,
    "YEAR_4": 886588.2625907286,
    "YEAR_5": 888404.8862314214,
    "YEAR_6": 890221.5098721139,
    "YEAR_7": 892038.1335128065,
    "YEAR_8": 893854.7571534992,
    "YEAR_9": 895671.3807941918,
    "YEAR_10": 897488.0044348844,
    "AD_Reve": 8893131.980517676
  },
  {
    "DMA": "Minneapolis-Saint Paul",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 4138830,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 827766,
    "No of HHS for Ads": 2069.415,
    "Revenue per 1000 Devices Hour": 52.770082499999994,
    "Revenue per 1000 Devices per day": 211.08032999999998,
    "Revenue per month(Video Ad)": 6332.4099,
    "Revenue per month (Banner Ad)": 63324.098999999995,
    "Total Revenue per month from all Ads": 69656.5089,
    "YEAR_1": 836668.7049718156,
    "YEAR_2": 838393.6464375949,
    "YEAR_3": 840118.5879033745,
    "YEAR_4": 841843.529369154,
    "YEAR_5": 843568.4708349335,
    "YEAR_6": 845293.412300713,
    "YEAR_7": 847018.3537664926,
    "YEAR_8": 848743.2952322719,
    "YEAR_9": 850468.2366980512,
    "YEAR_10": 852193.1781638308,
    "AD_Reve": 8444309.415678231
  },
  {
    "DMA": "Denver",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 4033215,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 806643,
    "No of HHS for Ads": 2016.6075,
    "Revenue per 1000 Devices Hour": 51.42349125,
    "Revenue per 1000 Devices per day": 205.693965,
    "Revenue per month(Video Ad)": 6170.81895,
    "Revenue per month (Banner Ad)": 61708.1895,
    "Total Revenue per month from all Ads": 67879.00845,
    "YEAR_1": 815318.525023473,
    "YEAR_2": 816999.449292869,
    "YEAR_3": 818680.3735622648,
    "YEAR_4": 820361.2978316606,
    "YEAR_5": 822042.2221010566,
    "YEAR_6": 823723.1463704525,
    "YEAR_7": 825404.0706398482,
    "YEAR_8": 827084.9949092442,
    "YEAR_9": 828765.9191786401,
    "YEAR_10": 830446.8434480359,
    "AD_Reve": 8228826.842357546
  },
  {
    "DMA": "Orlando-Daytona Beach-Melbourne",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 3994065,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 798813,
    "No of HHS for Ads": 1997.0325,
    "Revenue per 1000 Devices Hour": 50.92432875,
    "Revenue per 1000 Devices per day": 203.697315,
    "Revenue per month(Video Ad)": 6110.91945,
    "Revenue per month (Banner Ad)": 61109.194500000005,
    "Total Revenue per month from all Ads": 67220.11395,
    "YEAR_1": 807404.3126012073,
    "YEAR_2": 809068.920312932,
    "YEAR_3": 810733.5280246572,
    "YEAR_4": 812398.1357363821,
    "YEAR_5": 814062.7434481068,
    "YEAR_6": 815727.351159832,
    "YEAR_7": 817391.9588715569,
    "YEAR_8": 819056.5665832816,
    "YEAR_9": 820721.1742950067,
    "YEAR_10": 822385.7820067317,
    "AD_Reve": 8148950.473039692
  },
  {
    "DMA": "Miami-Fort Lauderdale",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 3872182.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 774436.5,
    "No of HHS for Ads": 1936.09125,
    "Revenue per 1000 Devices Hour": 49.370326874999996,
    "Revenue per 1000 Devices per day": 197.48130749999999,
    "Revenue per month(Video Ad)": 5924.439224999999,
    "Revenue per month (Banner Ad)": 59244.39224999999,
    "Total Revenue per month from all Ads": 65168.83147499999,
    "YEAR_1": 782765.6409394749,
    "YEAR_2": 784379.4516437841,
    "YEAR_3": 785993.262348093,
    "YEAR_4": 787607.0730524024,
    "YEAR_5": 789220.8837567111,
    "YEAR_6": 790834.6944610203,
    "YEAR_7": 792448.5051653296,
    "YEAR_8": 794062.3158696385,
    "YEAR_9": 795676.1265739476,
    "YEAR_10": 797289.9372782568,
    "AD_Reve": 7900277.891088658
  },
  {
    "DMA": "Cleveland-Akron-Canton",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 3492945,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 698589,
    "No of HHS for Ads": 1746.4725,
    "Revenue per 1000 Devices Hour": 44.53504875,
    "Revenue per 1000 Devices per day": 178.140195,
    "Revenue per month(Video Ad)": 5344.20585,
    "Revenue per month (Banner Ad)": 53442.0585,
    "Total Revenue per month from all Ads": 58786.26435,
    "YEAR_1": 706102.3935962042,
    "YEAR_2": 707558.1493697411,
    "YEAR_3": 709013.9051432778,
    "YEAR_4": 710469.6609168146,
    "YEAR_5": 711925.4166903513,
    "YEAR_6": 713381.172463888,
    "YEAR_7": 714836.9282374248,
    "YEAR_8": 716292.6840109614,
    "YEAR_9": 717748.4397844983,
    "YEAR_10": 719204.195558035,
    "AD_Reve": 7126532.945771196
  },
  {
    "DMA": "Sacramento-Stockton-Modesto",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 3379680,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 675936,
    "No of HHS for Ads": 1689.8400000000001,
    "Revenue per 1000 Devices Hour": 43.090920000000004,
    "Revenue per 1000 Devices per day": 172.36368000000002,
    "Revenue per month(Video Ad)": 5170.910400000001,
    "Revenue per month (Banner Ad)": 51709.10400000001,
    "Total Revenue per month from all Ads": 56880.01440000001,
    "YEAR_1": 683205.7583469595,
    "YEAR_2": 684614.3086312343,
    "YEAR_3": 686022.8589155092,
    "YEAR_4": 687431.4091997843,
    "YEAR_5": 688839.9594840591,
    "YEAR_6": 690248.5097683341,
    "YEAR_7": 691657.0600526091,
    "YEAR_8": 693065.6103368839,
    "YEAR_9": 694474.1606211588,
    "YEAR_10": 695882.7109054337,
    "AD_Reve": 6895442.346261966
  },
  {
    "DMA": "Charlotte",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2977650,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 595530,
    "No of HHS for Ads": 1488.825,
    "Revenue per 1000 Devices Hour": 37.96503750000001,
    "Revenue per 1000 Devices per day": 151.86015000000003,
    "Revenue per month(Video Ad)": 4555.804500000001,
    "Revenue per month (Banner Ad)": 45558.04500000001,
    "Total Revenue per month from all Ads": 50113.84950000001,
    "YEAR_1": 601934.9838865881,
    "YEAR_2": 603175.9800027799,
    "YEAR_3": 604416.9761189717,
    "YEAR_4": 605657.9722351636,
    "YEAR_5": 606898.9683513553,
    "YEAR_6": 608139.9644675473,
    "YEAR_7": 609380.9605837391,
    "YEAR_8": 610621.9566999308,
    "YEAR_9": 611862.9528161227,
    "YEAR_10": 613103.9489323145,
    "AD_Reve": 6075194.664094513
  },
  {
    "DMA": "Portland, OR",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2910150,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 582030,
    "No of HHS for Ads": 1455.075,
    "Revenue per 1000 Devices Hour": 37.10441250000001,
    "Revenue per 1000 Devices per day": 148.41765000000004,
    "Revenue per month(Video Ad)": 4452.5295000000015,
    "Revenue per month (Banner Ad)": 44525.29500000001,
    "Total Revenue per month from all Ads": 48977.82450000002,
    "YEAR_1": 588289.7900550952,
    "YEAR_2": 589502.6541753028,
    "YEAR_3": 590715.5182955102,
    "YEAR_4": 591928.3824157177,
    "YEAR_5": 593141.2465359251,
    "YEAR_6": 594354.1106561325,
    "YEAR_7": 595566.9747763398,
    "YEAR_8": 596779.8388965472,
    "YEAR_9": 597992.7030167547,
    "YEAR_10": 599205.5671369621,
    "AD_Reve": 5937476.785960287
  },
  {
    "DMA": "Raleigh-Durham (Fayetteville)",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2901397.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 580279.5,
    "No of HHS for Ads": 1450.69875,
    "Revenue per 1000 Devices Hour": 36.99281812500001,
    "Revenue per 1000 Devices per day": 147.97127250000003,
    "Revenue per month(Video Ad)": 4439.138175000001,
    "Revenue per month (Banner Ad)": 44391.38175000001,
    "Total Revenue per month from all Ads": 48830.51992500001,
    "YEAR_1": 586520.4632549449,
    "YEAR_2": 587729.6795930064,
    "YEAR_3": 588938.8959310679,
    "YEAR_4": 590148.1122691293,
    "YEAR_5": 591357.3286071906,
    "YEAR_6": 592566.5449452522,
    "YEAR_7": 593775.7612833136,
    "YEAR_8": 594984.9776213752,
    "YEAR_9": 596194.1939594366,
    "YEAR_10": 597403.4102974981,
    "AD_Reve": 5919619.367762215
  },
  {
    "DMA": "St. Louis",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2824110,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 564822,
    "No of HHS for Ads": 1412.055,
    "Revenue per 1000 Devices Hour": 36.0074025,
    "Revenue per 1000 Devices per day": 144.02961,
    "Revenue per month(Video Ad)": 4320.8883,
    "Revenue per month (Banner Ad)": 43208.882999999994,
    "Total Revenue per month from all Ads": 47529.77129999999,
    "YEAR_1": 570896.7163178854,
    "YEAR_2": 572073.7215205448,
    "YEAR_3": 573250.726723204,
    "YEAR_4": 574427.7319258634,
    "YEAR_5": 575604.7371285227,
    "YEAR_6": 576781.742331182,
    "YEAR_7": 577958.7475338413,
    "YEAR_8": 579135.7527365006,
    "YEAR_9": 580312.75793916,
    "YEAR_10": 581489.7631418193,
    "AD_Reve": 5761932.397298523
  },
  {
    "DMA": "Indianapolis",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2716380,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 543276,
    "No of HHS for Ads": 1358.19,
    "Revenue per 1000 Devices Hour": 34.633845,
    "Revenue per 1000 Devices per day": 138.53538,
    "Revenue per month(Video Ad)": 4156.0614000000005,
    "Revenue per month (Banner Ad)": 41560.614,
    "Total Revenue per month from all Ads": 45716.6754,
    "YEAR_1": 549118.986962823,
    "YEAR_2": 550251.0934998912,
    "YEAR_3": 551383.2000369594,
    "YEAR_4": 552515.3065740276,
    "YEAR_5": 553647.4131110959,
    "YEAR_6": 554779.519648164,
    "YEAR_7": 555911.6261852321,
    "YEAR_8": 557043.7327223005,
    "YEAR_9": 558175.8392593687,
    "YEAR_10": 559307.9457964369,
    "AD_Reve": 5542134.663796298
  },
  {
    "DMA": "Pittsburgh",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2643615,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 528723,
    "No of HHS for Ads": 1321.8075000000001,
    "Revenue per 1000 Devices Hour": 33.70609125000001,
    "Revenue per 1000 Devices per day": 134.82436500000003,
    "Revenue per month(Video Ad)": 4044.730950000001,
    "Revenue per month (Banner Ad)": 40447.30950000001,
    "Total Revenue per month from all Ads": 44492.040450000015,
    "YEAR_1": 534409.4680124738,
    "YEAR_2": 535511.2482578708,
    "YEAR_3": 536613.0285032679,
    "YEAR_4": 537714.8087486648,
    "YEAR_5": 538816.5889940619,
    "YEAR_6": 539918.369239459,
    "YEAR_7": 541020.1494848559,
    "YEAR_8": 542121.9297302531,
    "YEAR_9": 543223.7099756501,
    "YEAR_10": 544325.4902210472,
    "AD_Reve": 5393674.791167605
  },
  {
    "DMA": "Nashville",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2629215,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 525843,
    "No of HHS for Ads": 1314.6075,
    "Revenue per 1000 Devices Hour": 33.52249125,
    "Revenue per 1000 Devices per day": 134.089965,
    "Revenue per month(Video Ad)": 4022.69895,
    "Revenue per month (Banner Ad)": 40226.989499999996,
    "Total Revenue per month from all Ads": 44249.688449999994,
    "YEAR_1": 531498.4933284218,
    "YEAR_2": 532594.2720813421,
    "YEAR_3": 533690.0508342624,
    "YEAR_4": 534785.8295871827,
    "YEAR_5": 535881.6083401033,
    "YEAR_6": 536977.3870930236,
    "YEAR_7": 538073.1658459438,
    "YEAR_8": 539168.9445988642,
    "YEAR_9": 540264.7233517845,
    "YEAR_10": 541360.5021047051,
    "AD_Reve": 5364294.977165632
  },
  {
    "DMA": "Baltimore",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2586330,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 517266,
    "No of HHS for Ads": 1293.165,
    "Revenue per 1000 Devices Hour": 32.9757075,
    "Revenue per 1000 Devices per day": 131.90283,
    "Revenue per month(Video Ad)": 3957.0849,
    "Revenue per month (Banner Ad)": 39570.849,
    "Total Revenue per month from all Ads": 43527.9339,
    "YEAR_1": 522829.24684748007,
    "YEAR_2": 523907.15240561834,
    "YEAR_3": 524985.0579637566,
    "YEAR_4": 526062.9635218949,
    "YEAR_5": 527140.8690800332,
    "YEAR_6": 528218.7746381714,
    "YEAR_7": 529296.6801963097,
    "YEAR_8": 530374.585754448,
    "YEAR_9": 531452.4913125862,
    "YEAR_10": 532530.3968707245,
    "AD_Reve": 5276798.218591023
  },
  {
    "DMA": "Salt Lake City",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2583270,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 516654,
    "No of HHS for Ads": 1291.635,
    "Revenue per 1000 Devices Hour": 32.9366925,
    "Revenue per 1000 Devices per day": 131.74677,
    "Revenue per month(Video Ad)": 3952.4031,
    "Revenue per month (Banner Ad)": 39524.031,
    "Total Revenue per month from all Ads": 43476.434100000006,
    "YEAR_1": 522210.66472711915,
    "YEAR_2": 523287.2949681061,
    "YEAR_3": 524363.925209093,
    "YEAR_4": 525440.5554500801,
    "YEAR_5": 526517.185691067,
    "YEAR_6": 527593.8159320541,
    "YEAR_7": 528670.446173041,
    "YEAR_8": 529747.0764140278,
    "YEAR_9": 530823.706655015,
    "YEAR_10": 531900.3368960018,
    "AD_Reve": 5270555.008115606
  },
  {
    "DMA": "San Diego",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2490772.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 498154.5,
    "No of HHS for Ads": 1245.38625,
    "Revenue per 1000 Devices Hour": 31.757349375,
    "Revenue per 1000 Devices per day": 127.0293975,
    "Revenue per month(Video Ad)": 3810.881925,
    "Revenue per month (Banner Ad)": 38108.81925,
    "Total Revenue per month from all Ads": 41919.701175,
    "YEAR_1": 503512.20078003,
    "YEAR_2": 504550.2808091864,
    "YEAR_3": 505588.3608383427,
    "YEAR_4": 506626.4408674991,
    "YEAR_5": 507664.52089665545,
    "YEAR_6": 508702.6009258118,
    "YEAR_7": 509740.68095496815,
    "YEAR_8": 510778.7609841245,
    "YEAR_9": 511816.84101328085,
    "YEAR_10": 512854.9210424372,
    "AD_Reve": 5081835.609112337
  },
  {
    "DMA": "San Antonio",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2383965,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 476793,
    "No of HHS for Ads": 1191.9825,
    "Revenue per 1000 Devices Hour": 30.39555375,
    "Revenue per 1000 Devices per day": 121.582215,
    "Revenue per month(Video Ad)": 3647.4664500000003,
    "Revenue per month (Banner Ad)": 36474.664500000006,
    "Total Revenue per month from all Ads": 40122.130950000006,
    "YEAR_1": 481920.95574066456,
    "YEAR_2": 482914.5215748416,
    "YEAR_3": 483908.0874090186,
    "YEAR_4": 484901.6532431957,
    "YEAR_5": 485895.2190773727,
    "YEAR_6": 486888.78491154977,
    "YEAR_7": 487882.35074572684,
    "YEAR_8": 488875.9165799038,
    "YEAR_9": 489869.4824140809,
    "YEAR_10": 490863.0482482579,
    "AD_Reve": 4863920.019944612
  },
  {
    "DMA": "Columbus",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2303100,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 460620,
    "No of HHS for Ads": 1151.55,
    "Revenue per 1000 Devices Hour": 29.364524999999997,
    "Revenue per 1000 Devices per day": 117.45809999999999,
    "Revenue per month(Video Ad)": 3523.7429999999995,
    "Revenue per month (Banner Ad)": 35237.42999999999,
    "Total Revenue per month from all Ads": 38761.172999999995,
    "YEAR_1": 465574.0135305361,
    "YEAR_2": 466533.87723352376,
    "YEAR_3": 467493.7409365115,
    "YEAR_4": 468453.60463949916,
    "YEAR_5": 469413.4683424869,
    "YEAR_6": 470373.3320454747,
    "YEAR_7": 471333.19574846234,
    "YEAR_8": 472293.0594514501,
    "YEAR_9": 473252.9231544378,
    "YEAR_10": 474212.7868574255,
    "AD_Reve": 4698934.001939807
  },
  {
    "DMA": "Kansas City",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2293987.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 458797.5,
    "No of HHS for Ads": 1146.99375,
    "Revenue per 1000 Devices Hour": 29.248340625,
    "Revenue per 1000 Devices per day": 116.9933625,
    "Revenue per month(Video Ad)": 3509.800875,
    "Revenue per month (Banner Ad)": 35098.00875,
    "Total Revenue per month from all Ads": 38607.809625,
    "YEAR_1": 463731.91236328456,
    "YEAR_2": 464687.9782468144,
    "YEAR_3": 465644.04413034423,
    "YEAR_4": 466600.1100138741,
    "YEAR_5": 467556.1758974039,
    "YEAR_6": 468512.24178093375,
    "YEAR_7": 469468.3076644636,
    "YEAR_8": 470424.3735479934,
    "YEAR_9": 471380.43943152326,
    "YEAR_10": 472336.5053150531,
    "AD_Reve": 4680342.088391689
  },
  {
    "DMA": "Hartford-New Haven",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2281860,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 456372,
    "No of HHS for Ads": 1140.93,
    "Revenue per 1000 Devices Hour": 29.093715,
    "Revenue per 1000 Devices per day": 116.37486,
    "Revenue per month(Video Ad)": 3491.2458,
    "Revenue per month (Banner Ad)": 34912.458,
    "Total Revenue per month from all Ads": 38403.703799999996,
    "YEAR_1": 461280.32587155956,
    "YEAR_2": 462231.3373731442,
    "YEAR_3": 463182.34887472884,
    "YEAR_4": 464133.36037631345,
    "YEAR_5": 465084.3718778981,
    "YEAR_6": 466035.3833794828,
    "YEAR_7": 466986.39488106745,
    "YEAR_8": 467937.406382652,
    "YEAR_9": 468888.41788423667,
    "YEAR_10": 469839.4293858213,
    "AD_Reve": 4655598.776286904
  },
  {
    "DMA": "Austin",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2201670,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 440334,
    "No of HHS for Ads": 1100.835,
    "Revenue per 1000 Devices Hour": 28.0712925,
    "Revenue per 1000 Devices per day": 112.28517,
    "Revenue per month(Video Ad)": 3368.5550999999996,
    "Revenue per month (Banner Ad)": 33685.55099999999,
    "Total Revenue per month from all Ads": 37054.10609999999,
    "YEAR_1": 445069.83559974603,
    "YEAR_2": 445987.42629010114,
    "YEAR_3": 446905.0169804563,
    "YEAR_4": 447822.6076708115,
    "YEAR_5": 448740.19836116664,
    "YEAR_6": 449657.78905152186,
    "YEAR_7": 450575.37974187697,
    "YEAR_8": 451492.9704322322,
    "YEAR_9": 452410.5611225873,
    "YEAR_10": 453328.1518129425,
    "AD_Reve": 4491989.937063443
  },
  {
    "DMA": "Cincinnati",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2146365,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 429273,
    "No of HHS for Ads": 1073.1825000000001,
    "Revenue per 1000 Devices Hour": 27.366153750000006,
    "Revenue per 1000 Devices per day": 109.46461500000002,
    "Revenue per month(Video Ad)": 3283.9384500000006,
    "Revenue per month (Banner Ad)": 32839.38450000001,
    "Total Revenue per month from all Ads": 36123.32295000001,
    "YEAR_1": 433889.8734538097,
    "YEAR_2": 434784.4146621216,
    "YEAR_3": 435678.95587043354,
    "YEAR_4": 436573.49707874545,
    "YEAR_5": 437468.0382870575,
    "YEAR_6": 438362.5794953694,
    "YEAR_7": 439257.1207036816,
    "YEAR_8": 440151.6619119935,
    "YEAR_9": 441046.20312030544,
    "YEAR_10": 441940.7443286174,
    "AD_Reve": 4379153.088912134
  },
  {
    "DMA": "Greenville-Spartanburg-Asheville-Anderson",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2131537.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 426307.5,
    "No of HHS for Ads": 1065.76875,
    "Revenue per 1000 Devices Hour": 27.177103125,
    "Revenue per 1000 Devices per day": 108.7084125,
    "Revenue per month(Video Ad)": 3261.252375,
    "Revenue per month (Banner Ad)": 32612.52375,
    "Total Revenue per month from all Ads": 35873.776125000004,
    "YEAR_1": 430892.47920882504,
    "YEAR_2": 431780.8407553525,
    "YEAR_3": 432669.2023018799,
    "YEAR_4": 433557.5638484072,
    "YEAR_5": 434445.9253949346,
    "YEAR_6": 435334.286941462,
    "YEAR_7": 436222.6484879893,
    "YEAR_8": 437111.0100345167,
    "YEAR_9": 437999.37158104416,
    "YEAR_10": 438887.7331275715,
    "AD_Reve": 4348901.061681983
  },
  {
    "DMA": "Milwaukee",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 2025450,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 405090,
    "No of HHS for Ads": 1012.725,
    "Revenue per 1000 Devices Hour": 25.824487500000004,
    "Revenue per 1000 Devices per day": 103.29795000000001,
    "Revenue per month(Video Ad)": 3098.9385,
    "Revenue per month (Banner Ad)": 30989.385000000002,
    "Total Revenue per month from all Ads": 34088.3235,
    "YEAR_1": 409446.7829036621,
    "YEAR_2": 410290.930329834,
    "YEAR_3": 411135.07775600586,
    "YEAR_4": 411979.22518217785,
    "YEAR_5": 412823.37260834966,
    "YEAR_6": 413667.5200345216,
    "YEAR_7": 414511.66746069345,
    "YEAR_8": 415355.8148868654,
    "YEAR_9": 416199.96231303725,
    "YEAR_10": 417044.1097392091,
    "AD_Reve": 4132454.4632143565
  },
  {
    "DMA": "West Palm Beach-Fort Pierce",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1998472.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 399694.5,
    "No of HHS for Ads": 999.23625,
    "Revenue per 1000 Devices Hour": 25.480524375,
    "Revenue per 1000 Devices per day": 101.9220975,
    "Revenue per month(Video Ad)": 3057.662925,
    "Revenue per month (Banner Ad)": 30576.62925,
    "Total Revenue per month from all Ads": 33634.292175,
    "YEAR_1": 403993.2537690089,
    "YEAR_2": 404826.157774119,
    "YEAR_3": 405659.0617792291,
    "YEAR_4": 406491.9657843392,
    "YEAR_5": 407324.86978944927,
    "YEAR_6": 408157.77379455947,
    "YEAR_7": 408990.6777996696,
    "YEAR_8": 409823.5818047798,
    "YEAR_9": 410656.4858098899,
    "YEAR_10": 411489.389815,
    "AD_Reve": 4077413.2179200444
  },
  {
    "DMA": "Las Vegas",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1958040,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 391608,
    "No of HHS for Ads": 979.02,
    "Revenue per 1000 Devices Hour": 24.96501,
    "Revenue per 1000 Devices per day": 99.86004,
    "Revenue per month(Video Ad)": 2995.8012,
    "Revenue per month (Banner Ad)": 29958.012,
    "Total Revenue per month from all Ads": 32953.8132,
    "YEAR_1": 395819.78266394447,
    "YEAR_2": 396635.83560346003,
    "YEAR_3": 397451.88854297553,
    "YEAR_4": 398267.94148249103,
    "YEAR_5": 399083.9944220065,
    "YEAR_6": 399900.047361522,
    "YEAR_7": 400716.10030103737,
    "YEAR_8": 401532.15324055287,
    "YEAR_9": 402348.20618006843,
    "YEAR_10": 403164.25911958376,
    "AD_Reve": 3994920.2089176425
  },
  {
    "DMA": "Jacksonville",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1778805,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 355761,
    "No of HHS for Ads": 889.4025,
    "Revenue per 1000 Devices Hour": 22.67976375,
    "Revenue per 1000 Devices per day": 90.719055,
    "Revenue per month(Video Ad)": 2721.57165,
    "Revenue per month (Banner Ad)": 27215.7165,
    "Total Revenue per month from all Ads": 29937.28815,
    "YEAR_1": 359587.24464338727,
    "YEAR_2": 360328.59775623213,
    "YEAR_3": 361069.95086907706,
    "YEAR_4": 361811.303981922,
    "YEAR_5": 362552.65709476685,
    "YEAR_6": 363294.0102076118,
    "YEAR_7": 364035.3633204567,
    "YEAR_8": 364776.71643330157,
    "YEAR_9": 365518.0695461465,
    "YEAR_10": 366259.4226589913,
    "AD_Reve": 3629233.336511893
  },
  {
    "DMA": "Grand Rapids-Kalamazoo-Battle Creek",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1757317.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 351463.5,
    "No of HHS for Ads": 878.65875,
    "Revenue per 1000 Devices Hour": 22.405798125,
    "Revenue per 1000 Devices per day": 89.6231925,
    "Revenue per month(Video Ad)": 2688.695775,
    "Revenue per month (Banner Ad)": 26886.95775,
    "Total Revenue per month from all Ads": 29575.653525,
    "YEAR_1": 355243.5246070286,
    "YEAR_2": 355975.92236781865,
    "YEAR_3": 356708.3201286085,
    "YEAR_4": 357440.71788939834,
    "YEAR_5": 358173.1156501882,
    "YEAR_6": 358905.5134109781,
    "YEAR_7": 359637.9111717679,
    "YEAR_8": 360370.3089325579,
    "YEAR_9": 361102.7066933477,
    "YEAR_10": 361835.10445413756,
    "AD_Reve": 3585393.145305831
  },
  {
    "DMA": "Harrisburg-Lancaster-Lebanon-York",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1737720,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 347544,
    "No of HHS for Ads": 868.86,
    "Revenue per 1000 Devices Hour": 22.15593,
    "Revenue per 1000 Devices per day": 88.62372,
    "Revenue per month(Video Ad)": 2658.7116,
    "Revenue per month (Banner Ad)": 26587.116,
    "Total Revenue per month from all Ads": 29245.8276,
    "YEAR_1": 351281.8699979519,
    "YEAR_2": 352006.1001025744,
    "YEAR_3": 352730.3302071967,
    "YEAR_4": 353454.56031181914,
    "YEAR_5": 354178.7904164415,
    "YEAR_6": 354903.02052106394,
    "YEAR_7": 355627.2506256863,
    "YEAR_8": 356351.4807303087,
    "YEAR_9": 357075.7108349311,
    "YEAR_10": 357799.9409395535,
    "AD_Reve": 3545409.054687527
  },
  {
    "DMA": "Norfolk-Portsmouth-Newport News",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1737427.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 347485.5,
    "No of HHS for Ads": 868.71375,
    "Revenue per 1000 Devices Hour": 22.152200625000003,
    "Revenue per 1000 Devices per day": 88.60880250000001,
    "Revenue per month(Video Ad)": 2658.2640750000005,
    "Revenue per month (Banner Ad)": 26582.640750000006,
    "Total Revenue per month from all Ads": 29240.904825000005,
    "YEAR_1": 351222.7408246822,
    "YEAR_2": 351946.84902398865,
    "YEAR_3": 352670.9572232951,
    "YEAR_4": 353395.0654226016,
    "YEAR_5": 354119.17362190806,
    "YEAR_6": 354843.2818212145,
    "YEAR_7": 355567.390020521,
    "YEAR_8": 356291.4982198274,
    "YEAR_9": 357015.6064191339,
    "YEAR_10": 357739.7146184403,
    "AD_Reve": 3544812.2772156126
  },
  {
    "DMA": "Birmingham-Anniston-Tuscaloosa",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1723995,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 344799,
    "No of HHS for Ads": 861.9975000000001,
    "Revenue per 1000 Devices Hour": 21.980936250000003,
    "Revenue per 1000 Devices per day": 87.92374500000001,
    "Revenue per month(Video Ad)": 2637.7123500000002,
    "Revenue per month (Banner Ad)": 26377.1235,
    "Total Revenue per month from all Ads": 29014.835850000003,
    "YEAR_1": 348507.3472522151,
    "YEAR_2": 349225.8571843206,
    "YEAR_3": 349944.36711642623,
    "YEAR_4": 350662.87704853184,
    "YEAR_5": 351381.3869806373,
    "YEAR_6": 352099.89691274293,
    "YEAR_7": 352818.4068448485,
    "YEAR_8": 353536.9167769541,
    "YEAR_9": 354255.4267090596,
    "YEAR_10": 354973.9366411652,
    "AD_Reve": 3517406.419466901
  },
  {
    "DMA": "Oklahoma City",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1672515,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 334503,
    "No of HHS for Ads": 836.2575,
    "Revenue per 1000 Devices Hour": 21.32456625,
    "Revenue per 1000 Devices per day": 85.298265,
    "Revenue per month(Video Ad)": 2558.94795,
    "Revenue per month (Banner Ad)": 25589.4795,
    "Total Revenue per month from all Ads": 28148.427450000003,
    "YEAR_1": 338100.6127567299,
    "YEAR_2": 338797.66735323134,
    "YEAR_3": 339494.7219497328,
    "YEAR_4": 340191.77654623426,
    "YEAR_5": 340888.8311427357,
    "YEAR_6": 341585.8857392372,
    "YEAR_7": 342282.94033573865,
    "YEAR_8": 342979.9949322401,
    "YEAR_9": 343677.0495287416,
    "YEAR_10": 344374.10412524303,
    "AD_Reve": 3412373.5844098646
  },
  {
    "DMA": "Greensboro-High Point-Winston Salem",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1664932.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 332986.5,
    "No of HHS for Ads": 832.4662500000001,
    "Revenue per 1000 Devices Hour": 21.227889375000004,
    "Revenue per 1000 Devices per day": 84.91155750000001,
    "Revenue per month(Video Ad)": 2547.3467250000003,
    "Revenue per month (Banner Ad)": 25473.46725,
    "Total Revenue per month from all Ads": 28020.813975,
    "YEAR_1": 336567.8026496588,
    "YEAR_2": 337261.69708527805,
    "YEAR_3": 337955.59152089723,
    "YEAR_4": 338649.48595651647,
    "YEAR_5": 339343.3803921357,
    "YEAR_6": 340037.2748277549,
    "YEAR_7": 340731.1692633741,
    "YEAR_8": 341425.0636989933,
    "YEAR_9": 342118.95813461253,
    "YEAR_10": 342812.8525702318,
    "AD_Reve": 3396903.2760994527
  },
  {
    "DMA": "Louisville",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1622407.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 324481.5,
    "No of HHS for Ads": 811.20375,
    "Revenue per 1000 Devices Hour": 20.685695625,
    "Revenue per 1000 Devices per day": 82.7427825,
    "Revenue per month(Video Ad)": 2482.283475,
    "Revenue per month (Banner Ad)": 24822.83475,
    "Total Revenue per month from all Ads": 27305.118225000002,
    "YEAR_1": 327971.33053581836,
    "YEAR_2": 328647.5018139674,
    "YEAR_3": 329323.6730921164,
    "YEAR_4": 329999.8443702655,
    "YEAR_5": 330676.0156484145,
    "YEAR_6": 331352.1869265635,
    "YEAR_7": 332028.35820471257,
    "YEAR_8": 332704.52948286163,
    "YEAR_9": 333380.7007610107,
    "YEAR_10": 334056.8720391597,
    "AD_Reve": 3310141.01287489
  },
  {
    "DMA": "Albuquerque-Santa Fe",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1621687.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 324337.5,
    "No of HHS for Ads": 810.84375,
    "Revenue per 1000 Devices Hour": 20.676515625,
    "Revenue per 1000 Devices per day": 82.7060625,
    "Revenue per month(Video Ad)": 2481.181875,
    "Revenue per month (Banner Ad)": 24811.818750000002,
    "Total Revenue per month from all Ads": 27293.000625,
    "YEAR_1": 327825.7818016158,
    "YEAR_2": 328501.6530051409,
    "YEAR_3": 329177.5242086661,
    "YEAR_4": 329853.3954121914,
    "YEAR_5": 330529.26661571657,
    "YEAR_6": 331205.1378192418,
    "YEAR_7": 331881.009022767,
    "YEAR_8": 332556.8802262922,
    "YEAR_9": 333232.75142981746,
    "YEAR_10": 333908.6226333426,
    "AD_Reve": 3308672.022174792
  },
  {
    "DMA": "New Orleans",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1545997.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 309199.5,
    "No of HHS for Ads": 772.99875,
    "Revenue per 1000 Devices Hour": 19.711468125,
    "Revenue per 1000 Devices per day": 78.8458725,
    "Revenue per month(Video Ad)": 2365.376175,
    "Revenue per month (Banner Ad)": 23653.761749999998,
    "Total Revenue per month from all Ads": 26019.137925,
    "YEAR_1": 312524.9711185684,
    "YEAR_2": 313169.29697726306,
    "YEAR_3": 313813.6228359578,
    "YEAR_4": 314457.94869465247,
    "YEAR_5": 315102.2745533472,
    "YEAR_6": 315746.60041204194,
    "YEAR_7": 316390.92627073656,
    "YEAR_8": 317035.2521294313,
    "YEAR_9": 317679.57798812597,
    "YEAR_10": 318323.9038468207,
    "AD_Reve": 3154244.3748269454
  },
  {
    "DMA": "Providence-New Bedford",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1493775,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 298755,
    "No of HHS for Ads": 746.8875,
    "Revenue per 1000 Devices Hour": 19.045631250000003,
    "Revenue per 1000 Devices per day": 76.18252500000001,
    "Revenue per month(Video Ad)": 2285.4757500000005,
    "Revenue per month (Banner Ad)": 22854.757500000007,
    "Total Revenue per month from all Ads": 25140.23325000001,
    "YEAR_1": 301968.13949093694,
    "YEAR_2": 302590.7005620717,
    "YEAR_3": 303213.26163320645,
    "YEAR_4": 303835.82270434115,
    "YEAR_5": 304458.38377547596,
    "YEAR_6": 305080.9448466108,
    "YEAR_7": 305703.5059177455,
    "YEAR_8": 306326.0669888803,
    "YEAR_9": 306948.62806001503,
    "YEAR_10": 307571.1891311498,
    "AD_Reve": 3047696.6431104336
  },
  {
    "DMA": "Memphis",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1449810,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 289962,
    "No of HHS for Ads": 724.905,
    "Revenue per 1000 Devices Hour": 18.4850775,
    "Revenue per 1000 Devices per day": 73.94031,
    "Revenue per month(Video Ad)": 2218.2093,
    "Revenue per month (Banner Ad)": 22182.093,
    "Total Revenue per month from all Ads": 24400.3023,
    "YEAR_1": 293080.56990869105,
    "YEAR_2": 293684.80767310807,
    "YEAR_3": 294289.04543752497,
    "YEAR_4": 294893.2832019419,
    "YEAR_5": 295497.52096635883,
    "YEAR_6": 296101.7587307757,
    "YEAR_7": 296705.99649519275,
    "YEAR_8": 297310.2342596096,
    "YEAR_9": 297914.47202402656,
    "YEAR_10": 298518.7097884435,
    "AD_Reve": 2957996.3984856736
  },
  {
    "DMA": "Fresno-Visalia",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1426770,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 285354,
    "No of HHS for Ads": 713.385,
    "Revenue per 1000 Devices Hour": 18.191317499999997,
    "Revenue per 1000 Devices per day": 72.76526999999999,
    "Revenue per month(Video Ad)": 2182.9581,
    "Revenue per month (Banner Ad)": 21829.581,
    "Total Revenue per month from all Ads": 24012.539099999998,
    "YEAR_1": 288423.01041420817,
    "YEAR_2": 289017.64579066244,
    "YEAR_3": 289612.28116711666,
    "YEAR_4": 290206.91654357093,
    "YEAR_5": 290801.5519200252,
    "YEAR_6": 291396.1872964795,
    "YEAR_7": 291990.82267293375,
    "YEAR_8": 292585.458049388,
    "YEAR_9": 293180.09342584223,
    "YEAR_10": 293774.72880229645,
    "AD_Reve": 2910988.696082523
  },
  {
    "DMA": "Buffalo",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1422247.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 284449.5,
    "No of HHS for Ads": 711.12375,
    "Revenue per 1000 Devices Hour": 18.133655625,
    "Revenue per 1000 Devices per day": 72.5346225,
    "Revenue per month(Video Ad)": 2176.038675,
    "Revenue per month (Banner Ad)": 21760.386749999998,
    "Total Revenue per month from all Ads": 23936.425424999998,
    "YEAR_1": 287508.7824274982,
    "YEAR_2": 288101.5329602214,
    "YEAR_3": 288694.2834929448,
    "YEAR_4": 289287.03402566805,
    "YEAR_5": 289879.78455839143,
    "YEAR_6": 290472.53509111464,
    "YEAR_7": 291065.2856238379,
    "YEAR_8": 291658.0361565613,
    "YEAR_9": 292250.78668928455,
    "YEAR_10": 292843.5372220079,
    "AD_Reve": 2901761.5982475304
  },
  {
    "DMA": "Fort Myers-Naples",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1390770,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 278154,
    "No of HHS for Ads": 695.385,
    "Revenue per 1000 Devices Hour": 17.732317499999997,
    "Revenue per 1000 Devices per day": 70.92926999999999,
    "Revenue per month(Video Ad)": 2127.8780999999994,
    "Revenue per month (Banner Ad)": 21278.780999999995,
    "Total Revenue per month from all Ads": 23406.659099999993,
    "YEAR_1": 281145.5737040786,
    "YEAR_2": 281725.2053493412,
    "YEAR_3": 282304.83699460374,
    "YEAR_4": 282884.4686398664,
    "YEAR_5": 283464.10028512886,
    "YEAR_6": 284043.73193039146,
    "YEAR_7": 284623.3635756541,
    "YEAR_8": 285202.99522091664,
    "YEAR_9": 285782.6268661792,
    "YEAR_10": 286362.2585114418,
    "AD_Reve": 2837539.161077602
  },
  {
    "DMA": "Richmond-Petersburg",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1368427.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 273685.5,
    "No of HHS for Ads": 684.21375,
    "Revenue per 1000 Devices Hour": 17.447450625000002,
    "Revenue per 1000 Devices per day": 69.78980250000001,
    "Revenue per month(Video Ad)": 2093.6940750000003,
    "Revenue per month (Banner Ad)": 20936.94075,
    "Total Revenue per month from all Ads": 23030.634825,
    "YEAR_1": 276629.01454585453,
    "YEAR_2": 277199.3345004463,
    "YEAR_3": 277769.654455038,
    "YEAR_4": 278339.9744096298,
    "YEAR_5": 278910.29436422157,
    "YEAR_6": 279480.6143188132,
    "YEAR_7": 280050.93427340494,
    "YEAR_8": 280621.25422799675,
    "YEAR_9": 281191.5741825885,
    "YEAR_10": 281761.89413718024,
    "AD_Reve": 2791954.5434151744
  },
  {
    "DMA": "Wilkes Barre-Scranton",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1328377.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 265675.5,
    "No of HHS for Ads": 664.18875,
    "Revenue per 1000 Devices Hour": 16.936813125,
    "Revenue per 1000 Devices per day": 67.7472525,
    "Revenue per month(Video Ad)": 2032.417575,
    "Revenue per month (Banner Ad)": 20324.17575,
    "Total Revenue per month from all Ads": 22356.593324999998,
    "YEAR_1": 268532.8662058354,
    "YEAR_2": 269086.4945094764,
    "YEAR_3": 269640.12281311746,
    "YEAR_4": 270193.7511167584,
    "YEAR_5": 270747.37942039943,
    "YEAR_6": 271301.0077240404,
    "YEAR_7": 271854.6360276814,
    "YEAR_8": 272408.2643313224,
    "YEAR_9": 272961.89263496344,
    "YEAR_10": 273515.5209386044,
    "AD_Reve": 2710241.9357221993
  },
  {
    "DMA": "AL-Pensacola (Ft. Walton Beach)",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1324462.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 264892.5,
    "No of HHS for Ads": 662.23125,
    "Revenue per 1000 Devices Hour": 16.886896875,
    "Revenue per 1000 Devices per day": 67.5475875,
    "Revenue per month(Video Ad)": 2026.4276250000003,
    "Revenue per month (Banner Ad)": 20264.276250000003,
    "Total Revenue per month from all Ads": 22290.703875000003,
    "YEAR_1": 267741.4449636089,
    "YEAR_2": 268293.4416114828,
    "YEAR_3": 268845.4382593567,
    "YEAR_4": 269397.4349072306,
    "YEAR_5": 269949.4315551045,
    "YEAR_6": 270501.42820297845,
    "YEAR_7": 271053.42485085235,
    "YEAR_8": 271605.42149872624,
    "YEAR_9": 272157.41814660013,
    "YEAR_10": 272709.414794474,
    "AD_Reve": 2702254.2987904144
  },
  {
    "DMA": "Albany-Schenectady-Troy",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1302210,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 260442,
    "No of HHS for Ads": 651.105,
    "Revenue per 1000 Devices Hour": 16.6031775,
    "Revenue per 1000 Devices per day": 66.41271,
    "Revenue per month(Video Ad)": 1992.3813,
    "Revenue per month (Banner Ad)": 19923.813000000002,
    "Total Revenue per month from all Ads": 21916.194300000003,
    "YEAR_1": 263243.0793971601,
    "YEAR_2": 263785.80186369113,
    "YEAR_3": 264328.5243302222,
    "YEAR_4": 264871.24679675326,
    "YEAR_5": 265413.9692632843,
    "YEAR_6": 265956.6917298153,
    "YEAR_7": 266499.41419634636,
    "YEAR_8": 267042.1366628774,
    "YEAR_9": 267584.8591294085,
    "YEAR_10": 268127.58159593947,
    "AD_Reve": 2656853.304965498
  },
  {
    "DMA": "Little Rock-Pine Bluff",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1298542.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 259708.5,
    "No of HHS for Ads": 649.27125,
    "Revenue per 1000 Devices Hour": 16.556416875,
    "Revenue per 1000 Devices per day": 66.2256675,
    "Revenue per month(Video Ad)": 1986.770025,
    "Revenue per month (Banner Ad)": 19867.70025,
    "Total Revenue per month from all Ads": 21854.470275000003,
    "YEAR_1": 262501.69053231564,
    "YEAR_2": 263042.88449373154,
    "YEAR_3": 263584.07845514745,
    "YEAR_4": 264125.2724165633,
    "YEAR_5": 264666.46637797926,
    "YEAR_6": 265207.66033939517,
    "YEAR_7": 265748.854300811,
    "YEAR_8": 266290.0482622269,
    "YEAR_9": 266831.24222364277,
    "YEAR_10": 267372.43618505873,
    "AD_Reve": 2649370.6335868714
  },
  {
    "DMA": "Knoxville",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1259212.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 251842.5,
    "No of HHS for Ads": 629.60625,
    "Revenue per 1000 Devices Hour": 16.054959375,
    "Revenue per 1000 Devices per day": 64.2198375,
    "Revenue per month(Video Ad)": 1926.5951249999998,
    "Revenue per month (Banner Ad)": 19265.95125,
    "Total Revenue per month from all Ads": 21192.546374999998,
    "YEAR_1": 254551.09092649908,
    "YEAR_2": 255075.89331158806,
    "YEAR_3": 255600.69569667708,
    "YEAR_4": 256125.49808176604,
    "YEAR_5": 256650.300466855,
    "YEAR_6": 257175.102851944,
    "YEAR_7": 257699.90523703297,
    "YEAR_8": 258224.70762212196,
    "YEAR_9": 258749.51000721095,
    "YEAR_10": 259274.31239229994,
    "AD_Reve": 2569127.0165939955
  },
  {
    "DMA": "Tulsa",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1223347.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 244669.5,
    "No of HHS for Ads": 611.67375,
    "Revenue per 1000 Devices Hour": 15.597680625000002,
    "Revenue per 1000 Devices per day": 62.39072250000001,
    "Revenue per month(Video Ad)": 1871.7216750000002,
    "Revenue per month (Banner Ad)": 18717.216750000003,
    "Total Revenue per month from all Ads": 20588.938425000004,
    "YEAR_1": 247300.9446040326,
    "YEAR_2": 247810.7995219219,
    "YEAR_3": 248320.65443981116,
    "YEAR_4": 248830.50935770044,
    "YEAR_5": 249340.36427558973,
    "YEAR_6": 249850.219193479,
    "YEAR_7": 250360.07411136825,
    "YEAR_8": 250869.9290292575,
    "YEAR_9": 251379.78394714682,
    "YEAR_10": 251889.63886503608,
    "AD_Reve": 2495952.9173453436
  },
  {
    "DMA": "Lexington",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1149525,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 229905,
    "No of HHS for Ads": 574.7625,
    "Revenue per 1000 Devices Hour": 14.656443750000001,
    "Revenue per 1000 Devices per day": 58.625775000000004,
    "Revenue per month(Video Ad)": 1758.7732500000002,
    "Revenue per month (Banner Ad)": 17587.732500000002,
    "Total Revenue per month from all Ads": 19346.505750000004,
    "YEAR_1": 232377.65095032327,
    "YEAR_2": 232856.73884193762,
    "YEAR_3": 233335.82673355192,
    "YEAR_4": 233814.9146251663,
    "YEAR_5": 234294.0025167806,
    "YEAR_6": 234773.09040839493,
    "YEAR_7": 235252.17830000925,
    "YEAR_8": 235731.26619162358,
    "YEAR_9": 236210.35408323794,
    "YEAR_10": 236689.44197485226,
    "AD_Reve": 2345335.464625878
  },
  {
    "DMA": "Dayton",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1106595,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 221319,
    "No of HHS for Ads": 553.2975,
    "Revenue per 1000 Devices Hour": 14.10908625,
    "Revenue per 1000 Devices per day": 56.436345,
    "Revenue per month(Video Ad)": 1693.0903500000002,
    "Revenue per month (Banner Ad)": 16930.9035,
    "Total Revenue per month from all Ads": 18623.99385,
    "YEAR_1": 223699.30767349375,
    "YEAR_2": 224160.503615662,
    "YEAR_3": 224621.69955783023,
    "YEAR_4": 225082.89549999856,
    "YEAR_5": 225544.09144216677,
    "YEAR_6": 226005.28738433504,
    "YEAR_7": 226466.48332650328,
    "YEAR_8": 226927.67926867155,
    "YEAR_9": 227388.87521083982,
    "YEAR_10": 227850.07115300803,
    "AD_Reve": 2257746.894132509
  },
  {
    "DMA": "Tucson-Sierra Vista",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1103760,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 220752,
    "No of HHS for Ads": 551.88,
    "Revenue per 1000 Devices Hour": 14.07294,
    "Revenue per 1000 Devices per day": 56.29176,
    "Revenue per month(Video Ad)": 1688.7528,
    "Revenue per month (Banner Ad)": 16887.528,
    "Total Revenue per month from all Ads": 18576.280799999997,
    "YEAR_1": 223126.20953257103,
    "YEAR_2": 223586.22393090793,
    "YEAR_3": 224046.23832924486,
    "YEAR_4": 224506.25272758177,
    "YEAR_5": 224966.26712591868,
    "YEAR_6": 225426.2815242556,
    "YEAR_7": 225886.29592259252,
    "YEAR_8": 226346.3103209294,
    "YEAR_9": 226806.32471926633,
    "YEAR_10": 227266.33911760323,
    "AD_Reve": 2251962.7432508715
  },
  {
    "DMA": "Honolulu",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1089675,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 217935,
    "No of HHS for Ads": 544.8375,
    "Revenue per 1000 Devices Hour": 13.893356249999998,
    "Revenue per 1000 Devices per day": 55.57342499999999,
    "Revenue per month(Video Ad)": 1667.2027499999997,
    "Revenue per month (Banner Ad)": 16672.027499999997,
    "Total Revenue per month from all Ads": 18339.230249999997,
    "YEAR_1": 220278.91241973292,
    "YEAR_2": 220733.056608241,
    "YEAR_3": 221187.2007967492,
    "YEAR_4": 221641.3449852574,
    "YEAR_5": 222095.48917376547,
    "YEAR_6": 222549.6333622737,
    "YEAR_7": 223003.7775507819,
    "YEAR_8": 223457.92173929,
    "YEAR_9": 223912.0659277982,
    "YEAR_10": 224366.21011630638,
    "AD_Reve": 2223225.6126801963
  },
  {
    "DMA": "Spokane",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1084297.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 216859.5,
    "No of HHS for Ads": 542.1487500000001,
    "Revenue per 1000 Devices Hour": 13.824793125000001,
    "Revenue per 1000 Devices per day": 55.299172500000005,
    "Revenue per month(Video Ad)": 1658.975175,
    "Revenue per month (Banner Ad)": 16589.75175,
    "Total Revenue per month from all Ads": 18248.726925,
    "YEAR_1": 219191.84531115732,
    "YEAR_2": 219643.74831731874,
    "YEAR_3": 220095.6513234801,
    "YEAR_4": 220547.5543296415,
    "YEAR_5": 220999.4573358029,
    "YEAR_6": 221451.36034196432,
    "YEAR_7": 221903.26334812574,
    "YEAR_8": 222355.16635428715,
    "YEAR_9": 222807.06936044857,
    "YEAR_10": 223258.97236661,
    "AD_Reve": 2212254.088388836
  },
  {
    "DMA": "Des Moines-Ames",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1062697.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 212539.5,
    "No of HHS for Ads": 531.34875,
    "Revenue per 1000 Devices Hour": 13.549393124999998,
    "Revenue per 1000 Devices per day": 54.19757249999999,
    "Revenue per month(Video Ad)": 1625.9271749999998,
    "Revenue per month (Banner Ad)": 16259.271749999998,
    "Total Revenue per month from all Ads": 17885.198924999997,
    "YEAR_1": 214825.3832850796,
    "YEAR_2": 215268.284052526,
    "YEAR_3": 215711.18481997238,
    "YEAR_4": 216154.08558741875,
    "YEAR_5": 216596.98635486513,
    "YEAR_6": 217039.88712231154,
    "YEAR_7": 217482.78788975798,
    "YEAR_8": 217925.68865720433,
    "YEAR_9": 218368.58942465074,
    "YEAR_10": 218811.49019209715,
    "AD_Reve": 2168184.367385884
  },
  {
    "DMA": "Green Bay-Appleton",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1060177.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 212035.5,
    "No of HHS for Ads": 530.08875,
    "Revenue per 1000 Devices Hour": 13.517263125000001,
    "Revenue per 1000 Devices per day": 54.069052500000005,
    "Revenue per month(Video Ad)": 1622.0715750000002,
    "Revenue per month (Banner Ad)": 16220.715750000001,
    "Total Revenue per month from all Ads": 17842.787325,
    "YEAR_1": 214315.96271537058,
    "YEAR_2": 214757.81322163355,
    "YEAR_3": 215199.6637278965,
    "YEAR_4": 215641.5142341595,
    "YEAR_5": 216083.3647404225,
    "YEAR_6": 216525.21524668543,
    "YEAR_7": 216967.0657529484,
    "YEAR_8": 217408.91625921143,
    "YEAR_9": 217850.76676547437,
    "YEAR_10": 218292.61727173737,
    "AD_Reve": 2163042.89993554
  },
  {
    "DMA": "Wichita-Hutchinson Plus",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1029645,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 205929,
    "No of HHS for Ads": 514.8225,
    "Revenue per 1000 Devices Hour": 13.127973749999999,
    "Revenue per 1000 Devices per day": 52.511894999999996,
    "Revenue per month(Video Ad)": 1575.35685,
    "Revenue per month (Banner Ad)": 15753.5685,
    "Total Revenue per month from all Ads": 17328.925349999998,
    "YEAR_1": 208143.7867055919,
    "YEAR_2": 208572.91217233796,
    "YEAR_3": 209002.037639084,
    "YEAR_4": 209431.16310583,
    "YEAR_5": 209860.28857257607,
    "YEAR_6": 210289.41403932206,
    "YEAR_7": 210718.53950606813,
    "YEAR_8": 211147.6649728142,
    "YEAR_9": 211576.7904395602,
    "YEAR_10": 212005.91590630624,
    "AD_Reve": 2100748.5130594913
  },
  {
    "DMA": "Roanoke-Lynchburg",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1026877.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 205375.5,
    "No of HHS for Ads": 513.43875,
    "Revenue per 1000 Devices Hour": 13.092688125,
    "Revenue per 1000 Devices per day": 52.3707525,
    "Revenue per month(Video Ad)": 1571.122575,
    "Revenue per month (Banner Ad)": 15711.225750000001,
    "Total Revenue per month from all Ads": 17282.348325000003,
    "YEAR_1": 207584.33375850078,
    "YEAR_2": 208012.30581341145,
    "YEAR_3": 208440.27786832213,
    "YEAR_4": 208868.24992323277,
    "YEAR_5": 209296.22197814344,
    "YEAR_6": 209724.19403305414,
    "YEAR_7": 210152.16608796484,
    "YEAR_8": 210580.13814287554,
    "YEAR_9": 211008.1101977862,
    "YEAR_10": 211436.0822526968,
    "AD_Reve": 2095102.0800559882
  },
  {
    "DMA": "Madison",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1023075,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 204615,
    "No of HHS for Ads": 511.5375,
    "Revenue per 1000 Devices Hour": 13.04420625,
    "Revenue per 1000 Devices per day": 52.176825,
    "Revenue per month(Video Ad)": 1565.30475,
    "Revenue per month (Banner Ad)": 15653.0475,
    "Total Revenue per month from all Ads": 17218.35225,
    "YEAR_1": 206815.65450599327,
    "YEAR_2": 207242.04179179683,
    "YEAR_3": 207668.42907760036,
    "YEAR_4": 208094.81636340392,
    "YEAR_5": 208521.20364920748,
    "YEAR_6": 208947.59093501105,
    "YEAR_7": 209373.9782208146,
    "YEAR_8": 209800.36550661817,
    "YEAR_9": 210226.75279242173,
    "YEAR_10": 210653.1400782253,
    "AD_Reve": 2087343.9729210925
  },
  {
    "DMA": "Omaha",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 1016527.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 203305.5,
    "No of HHS for Ads": 508.26375,
    "Revenue per 1000 Devices Hour": 12.960725624999998,
    "Revenue per 1000 Devices per day": 51.842902499999994,
    "Revenue per month(Video Ad)": 1555.2870749999997,
    "Revenue per month (Banner Ad)": 15552.870749999998,
    "Total Revenue per month from all Ads": 17108.157825,
    "YEAR_1": 205492.0707043385,
    "YEAR_2": 205915.72918653153,
    "YEAR_3": 206339.38766872464,
    "YEAR_4": 206763.04615091768,
    "YEAR_5": 207186.70463311073,
    "YEAR_6": 207610.3631153038,
    "YEAR_7": 208034.0215974969,
    "YEAR_8": 208457.68007968995,
    "YEAR_9": 208881.33856188302,
    "YEAR_10": 209304.9970440761,
    "AD_Reve": 2073985.3387420727
  },
  {
    "DMA": "Flint-Saginaw-Bay City",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 994612.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 198922.5,
    "No of HHS for Ads": 497.30625000000003,
    "Revenue per 1000 Devices Hour": 12.681309375000001,
    "Revenue per 1000 Devices per day": 50.725237500000006,
    "Revenue per month(Video Ad)": 1521.757125,
    "Revenue per month (Banner Ad)": 15217.57125,
    "Total Revenue per month from all Ads": 16739.328375,
    "YEAR_1": 201061.93110704713,
    "YEAR_2": 201476.4560678773,
    "YEAR_3": 201890.9810287074,
    "YEAR_4": 202305.50598953757,
    "YEAR_5": 202720.0309503677,
    "YEAR_6": 203134.55591119782,
    "YEAR_7": 203549.08087202796,
    "YEAR_8": 203963.6058328581,
    "YEAR_9": 204378.13079368824,
    "YEAR_10": 204792.65575451835,
    "AD_Reve": 2029272.9343078271
  },
  {
    "DMA": "Springfield",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 981765,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 196353,
    "No of HHS for Ads": 490.8825,
    "Revenue per 1000 Devices Hour": 12.51750375,
    "Revenue per 1000 Devices per day": 50.070015,
    "Revenue per month(Video Ad)": 1502.10045,
    "Revenue per month (Banner Ad)": 15021.0045,
    "Total Revenue per month from all Ads": 16523.10495,
    "YEAR_1": 198464.79588111967,
    "YEAR_2": 198873.96638538077,
    "YEAR_3": 199283.13688964187,
    "YEAR_4": 199692.30739390297,
    "YEAR_5": 200101.47789816407,
    "YEAR_6": 200510.64840242517,
    "YEAR_7": 200919.81890668627,
    "YEAR_8": 201328.98941094737,
    "YEAR_9": 201738.15991520847,
    "YEAR_10": 202147.33041946962,
    "AD_Reve": 2003060.631502946
  },
  {
    "DMA": "Columbia",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 980032.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 196006.5,
    "No of HHS for Ads": 490.01625,
    "Revenue per 1000 Devices Hour": 12.495414375000001,
    "Revenue per 1000 Devices per day": 49.981657500000004,
    "Revenue per month(Video Ad)": 1499.4497250000002,
    "Revenue per month (Banner Ad)": 14994.497250000002,
    "Total Revenue per month from all Ads": 16493.946975000003,
    "YEAR_1": 198114.56923944468,
    "YEAR_2": 198523.01768914223,
    "YEAR_3": 198931.46613883972,
    "YEAR_4": 199339.91458853724,
    "YEAR_5": 199748.36303823473,
    "YEAR_6": 200156.81148793222,
    "YEAR_7": 200565.25993762974,
    "YEAR_8": 200973.70838732723,
    "YEAR_9": 201382.15683702476,
    "YEAR_10": 201790.60528672225,
    "AD_Reve": 1999525.8726308348
  },
  {
    "DMA": "Rochester",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 976927.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 195385.5,
    "No of HHS for Ads": 488.46375,
    "Revenue per 1000 Devices Hour": 12.455825625000001,
    "Revenue per 1000 Devices per day": 49.823302500000004,
    "Revenue per month(Video Ad)": 1494.6990750000002,
    "Revenue per month (Banner Ad)": 14946.990750000003,
    "Total Revenue per month from all Ads": 16441.689825,
    "YEAR_1": 197486.89032319598,
    "YEAR_2": 197894.04470107824,
    "YEAR_3": 198301.19907896052,
    "YEAR_4": 198708.35345684268,
    "YEAR_5": 199115.50783472497,
    "YEAR_6": 199522.6622126071,
    "YEAR_7": 199929.81659048935,
    "YEAR_8": 200336.97096837155,
    "YEAR_9": 200744.12534625377,
    "YEAR_10": 201151.2797241361,
    "AD_Reve": 1993190.8502366603
  },
  {
    "DMA": "Portland",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 965542.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 193108.5,
    "No of HHS for Ads": 482.77125,
    "Revenue per 1000 Devices Hour": 12.310666875,
    "Revenue per 1000 Devices per day": 49.2426675,
    "Revenue per month(Video Ad)": 1477.280025,
    "Revenue per month (Banner Ad)": 14772.80025,
    "Total Revenue per month from all Ads": 16250.080275,
    "YEAR_1": 195185.40096361755,
    "YEAR_2": 195587.8104115104,
    "YEAR_3": 195990.21985940327,
    "YEAR_4": 196392.62930729616,
    "YEAR_5": 196795.03875518893,
    "YEAR_6": 197197.4482030818,
    "YEAR_7": 197599.85765097468,
    "YEAR_8": 198002.26709886757,
    "YEAR_9": 198404.6765467604,
    "YEAR_10": 198807.08599465323,
    "AD_Reve": 1969962.434791354
  },
  {
    "DMA": "Charleston-Huntington",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 962212.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 192442.5,
    "No of HHS for Ads": 481.10625,
    "Revenue per 1000 Devices Hour": 12.268209374999998,
    "Revenue per 1000 Devices per day": 49.07283749999999,
    "Revenue per month(Video Ad)": 1472.1851249999997,
    "Revenue per month (Banner Ad)": 14721.851249999998,
    "Total Revenue per month from all Ads": 16194.036374999998,
    "YEAR_1": 194512.23806793056,
    "YEAR_2": 194913.25967068816,
    "YEAR_3": 195314.28127344578,
    "YEAR_4": 195715.30287620344,
    "YEAR_5": 196116.32447896103,
    "YEAR_6": 196517.3460817187,
    "YEAR_7": 196918.3676844763,
    "YEAR_8": 197319.38928723388,
    "YEAR_9": 197720.4108899915,
    "YEAR_10": 198121.43249274918,
    "AD_Reve": 1963168.3528033984
  },
  {
    "DMA": "Toledo",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 954855,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 190971,
    "No of HHS for Ads": 477.4275,
    "Revenue per 1000 Devices Hour": 12.17440125,
    "Revenue per 1000 Devices per day": 48.697605,
    "Revenue per month(Video Ad)": 1460.9281500000002,
    "Revenue per month (Banner Ad)": 14609.281500000001,
    "Total Revenue per month from all Ads": 16070.20965,
    "YEAR_1": 193024.91194029787,
    "YEAR_2": 193422.86715549318,
    "YEAR_3": 193820.8223706885,
    "YEAR_4": 194218.77758588383,
    "YEAR_5": 194616.73280107917,
    "YEAR_6": 195014.68801627448,
    "YEAR_7": 195412.6432314698,
    "YEAR_8": 195810.59844666513,
    "YEAR_9": 196208.55366186047,
    "YEAR_10": 196606.50887705578,
    "AD_Reve": 1948157.104086768
  },
  {
    "DMA": "Huntsville-Decatur-Florence",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 927832.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 185566.5,
    "No of HHS for Ads": 463.91625,
    "Revenue per 1000 Devices Hour": 11.829864375,
    "Revenue per 1000 Devices per day": 47.3194575,
    "Revenue per month(Video Ad)": 1419.583725,
    "Revenue per month (Banner Ad)": 14195.83725,
    "Total Revenue per month from all Ads": 15615.420975,
    "YEAR_1": 187562.2860097569,
    "YEAR_2": 187948.97904922644,
    "YEAR_3": 188335.67208869604,
    "YEAR_4": 188722.3651281656,
    "YEAR_5": 189109.05816763517,
    "YEAR_6": 189495.75120710474,
    "YEAR_7": 189882.4442465743,
    "YEAR_8": 190269.13728604387,
    "YEAR_9": 190655.83032551344,
    "YEAR_10": 191042.523364983,
    "AD_Reve": 1893024.0468736994
  },
  {
    "DMA": "Waco-Temple-Bryan",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 917145,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 183429,
    "No of HHS for Ads": 458.5725,
    "Revenue per 1000 Devices Hour": 11.69359875,
    "Revenue per 1000 Devices per day": 46.774395,
    "Revenue per month(Video Ad)": 1403.23185,
    "Revenue per month (Banner Ad)": 14032.3185,
    "Total Revenue per month from all Ads": 15435.55035,
    "YEAR_1": 185401.79698643717,
    "YEAR_2": 185784.03579320922,
    "YEAR_3": 186166.27459998126,
    "YEAR_4": 186548.5134067533,
    "YEAR_5": 186930.75221352532,
    "YEAR_6": 187312.99102029734,
    "YEAR_7": 187695.2298270694,
    "YEAR_8": 188077.46863384143,
    "YEAR_9": 188459.70744061345,
    "YEAR_10": 188841.94624738552,
    "AD_Reve": 1871218.7161691138
  },
  {
    "DMA": "Harlingen-Weslaco-Brownsville-McAllen",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 907807.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 181561.5,
    "No of HHS for Ads": 453.90375,
    "Revenue per 1000 Devices Hour": 11.574545625,
    "Revenue per 1000 Devices per day": 46.2981825,
    "Revenue per month(Video Ad)": 1388.945475,
    "Revenue per month (Banner Ad)": 13889.45475,
    "Total Revenue per month from all Ads": 15278.400225000001,
    "YEAR_1": 183514.21183974732,
    "YEAR_2": 183892.55905374154,
    "YEAR_3": 184270.9062677358,
    "YEAR_4": 184649.25348172995,
    "YEAR_5": 185027.60069572413,
    "YEAR_6": 185405.94790971832,
    "YEAR_7": 185784.29512371254,
    "YEAR_8": 186162.64233770675,
    "YEAR_9": 186540.98955170094,
    "YEAR_10": 186919.3367656951,
    "AD_Reve": 1852167.7430272126
  },
  {
    "DMA": "Chattanooga",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 903397.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 180679.5,
    "No of HHS for Ads": 451.69875,
    "Revenue per 1000 Devices Hour": 11.518318125,
    "Revenue per 1000 Devices per day": 46.0732725,
    "Revenue per month(Video Ad)": 1382.198175,
    "Revenue per month (Banner Ad)": 13821.981749999999,
    "Total Revenue per month from all Ads": 15204.179924999999,
    "YEAR_1": 182622.72584275648,
    "YEAR_2": 182999.23509967967,
    "YEAR_3": 183375.74435660287,
    "YEAR_4": 183752.2536135261,
    "YEAR_5": 184128.7628704493,
    "YEAR_6": 184505.27212737253,
    "YEAR_7": 184881.78138429573,
    "YEAR_8": 185258.29064121892,
    "YEAR_9": 185634.79989814214,
    "YEAR_10": 186011.30915506536,
    "AD_Reve": 1843170.1749891092
  },
  {
    "DMA": "Syracuse",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 874462.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 174892.5,
    "No of HHS for Ads": 437.23125,
    "Revenue per 1000 Devices Hour": 11.149396874999999,
    "Revenue per 1000 Devices per day": 44.597587499999996,
    "Revenue per month(Video Ad)": 1337.9276249999998,
    "Revenue per month (Banner Ad)": 13379.276249999999,
    "Total Revenue per month from all Ads": 14717.203875,
    "YEAR_1": 176773.4860869899,
    "YEAR_2": 177137.93609496774,
    "YEAR_3": 177502.38610294566,
    "YEAR_4": 177866.83611092358,
    "YEAR_5": 178231.28611890148,
    "YEAR_6": 178595.73612687938,
    "YEAR_7": 178960.18613485727,
    "YEAR_8": 179324.63614283517,
    "YEAR_9": 179689.08615081306,
    "YEAR_10": 180053.536158791,
    "AD_Reve": 1784135.1112289045
  },
  {
    "DMA": "Colorado Springs-Pueblo",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 867892.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 173578.5,
    "No of HHS for Ads": 433.94625,
    "Revenue per 1000 Devices Hour": 11.065629375,
    "Revenue per 1000 Devices per day": 44.2625175,
    "Revenue per month(Video Ad)": 1327.875525,
    "Revenue per month (Banner Ad)": 13278.755249999998,
    "Total Revenue per month from all Ads": 14606.630774999998,
    "YEAR_1": 175445.3538873912,
    "YEAR_2": 175807.06571442666,
    "YEAR_3": 176168.77754146207,
    "YEAR_4": 176530.48936849748,
    "YEAR_5": 176892.2011955329,
    "YEAR_6": 177253.9130225683,
    "YEAR_7": 177615.62484960374,
    "YEAR_8": 177977.33667663916,
    "YEAR_9": 178339.04850367457,
    "YEAR_10": 178700.76033071004,
    "AD_Reve": 1770730.5710905064
  },
  {
    "DMA": "Savannah",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 850590,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 170118,
    "No of HHS for Ads": 425.295,
    "Revenue per 1000 Devices Hour": 10.845022499999999,
    "Revenue per 1000 Devices per day": 43.380089999999996,
    "Revenue per month(Video Ad)": 1301.4026999999999,
    "Revenue per month (Banner Ad)": 13014.026999999998,
    "Total Revenue per month from all Ads": 14315.429699999999,
    "YEAR_1": 171947.6358685852,
    "YEAR_2": 172302.13652731664,
    "YEAR_3": 172656.63718604803,
    "YEAR_4": 173011.1378447795,
    "YEAR_5": 173365.6385035109,
    "YEAR_6": 173720.1391622423,
    "YEAR_7": 174074.63982097374,
    "YEAR_8": 174429.14047970512,
    "YEAR_9": 174783.6411384366,
    "YEAR_10": 175138.141797168,
    "AD_Reve": 1735428.8883287658
  },
  {
    "DMA": "Charleston",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 842152.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 168430.5,
    "No of HHS for Ads": 421.07625,
    "Revenue per 1000 Devices Hour": 10.737444374999999,
    "Revenue per 1000 Devices per day": 42.949777499999996,
    "Revenue per month(Video Ad)": 1288.493325,
    "Revenue per month (Banner Ad)": 12884.933249999998,
    "Total Revenue per month from all Ads": 14173.426574999998,
    "YEAR_1": 170241.98663964862,
    "YEAR_2": 170592.970798882,
    "YEAR_3": 170943.95495811533,
    "YEAR_4": 171294.93911734872,
    "YEAR_5": 171645.9232765821,
    "YEAR_6": 171996.90743581546,
    "YEAR_7": 172347.8915950488,
    "YEAR_8": 172698.8757542822,
    "YEAR_9": 173049.85991351557,
    "YEAR_10": 173400.84407274894,
    "AD_Reve": 1718214.1535619877
  },
  {
    "DMA": "Shreveport",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 840420,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 168084,
    "No of HHS for Ads": 420.21000000000004,
    "Revenue per 1000 Devices Hour": 10.715355,
    "Revenue per 1000 Devices per day": 42.86142,
    "Revenue per month(Video Ad)": 1285.8426000000002,
    "Revenue per month (Banner Ad)": 12858.426000000001,
    "Total Revenue per month from all Ads": 14144.268600000001,
    "YEAR_1": 169891.75999797366,
    "YEAR_2": 170242.02210264344,
    "YEAR_3": 170592.28420731318,
    "YEAR_4": 170942.546311983,
    "YEAR_5": 171292.80841665273,
    "YEAR_6": 171643.07052132252,
    "YEAR_7": 171993.33262599225,
    "YEAR_8": 172343.59473066207,
    "YEAR_9": 172693.85683533177,
    "YEAR_10": 173044.1189400016,
    "AD_Reve": 1714679.3946898764
  },
  {
    "DMA": "Champaign-Springfield-Decatur",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 839430,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 167886,
    "No of HHS for Ads": 419.71500000000003,
    "Revenue per 1000 Devices Hour": 10.7027325,
    "Revenue per 1000 Devices per day": 42.81093,
    "Revenue per month(Video Ad)": 1284.3279,
    "Revenue per month (Banner Ad)": 12843.279,
    "Total Revenue per month from all Ads": 14127.6069,
    "YEAR_1": 169691.6304884451,
    "YEAR_2": 170041.47999050713,
    "YEAR_3": 170391.32949256912,
    "YEAR_4": 170741.17899463107,
    "YEAR_5": 171091.02849669306,
    "YEAR_6": 171440.87799875505,
    "YEAR_7": 171790.72750081707,
    "YEAR_8": 172140.57700287906,
    "YEAR_9": 172490.4265049411,
    "YEAR_10": 172840.2760070031,
    "AD_Reve": 1712659.532477241
  },
  {
    "DMA": "El Paso-Las Cruces",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 836392.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 167278.5,
    "No of HHS for Ads": 418.19625,
    "Revenue per 1000 Devices Hour": 10.664004375000001,
    "Revenue per 1000 Devices per day": 42.656017500000004,
    "Revenue per month(Video Ad)": 1279.6805250000002,
    "Revenue per month (Banner Ad)": 12796.805250000001,
    "Total Revenue per month from all Ads": 14076.485775000001,
    "YEAR_1": 169077.59676602794,
    "YEAR_2": 169426.1803282706,
    "YEAR_3": 169774.7638905133,
    "YEAR_4": 170123.34745275602,
    "YEAR_5": 170471.9310149987,
    "YEAR_6": 170820.5145772414,
    "YEAR_7": 171169.09813948412,
    "YEAR_8": 171517.6817017268,
    "YEAR_9": 171866.26526396952,
    "YEAR_10": 172214.8488262122,
    "AD_Reve": 1706462.2279612005
  },
  {
    "DMA": "Paducah-Cape Girardeau-Harrisburg",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 830857.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 166171.5,
    "No of HHS for Ads": 415.42875000000004,
    "Revenue per 1000 Devices Hour": 10.593433125,
    "Revenue per 1000 Devices per day": 42.3737325,
    "Revenue per month(Video Ad)": 1271.2119750000002,
    "Revenue per month (Banner Ad)": 12712.119750000002,
    "Total Revenue per month from all Ads": 13983.331725000002,
    "YEAR_1": 167958.6908718455,
    "YEAR_2": 168304.96761041752,
    "YEAR_3": 168651.24434898945,
    "YEAR_4": 168997.52108756144,
    "YEAR_5": 169343.79782613344,
    "YEAR_6": 169690.0745647054,
    "YEAR_7": 170036.35130327736,
    "YEAR_8": 170382.62804184936,
    "YEAR_9": 170728.90478042135,
    "YEAR_10": 171075.1815189933,
    "AD_Reve": 1695169.361954194
  },
  {
    "DMA": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 811980,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 162396,
    "No of HHS for Ads": 405.99,
    "Revenue per 1000 Devices Hour": 10.352745,
    "Revenue per 1000 Devices per day": 41.41098,
    "Revenue per month(Video Ad)": 1242.3294,
    "Revenue per month (Banner Ad)": 12423.294000000002,
    "Total Revenue per month from all Ads": 13665.623400000002,
    "YEAR_1": 164142.58499697133,
    "YEAR_2": 164480.9941539997,
    "YEAR_3": 164819.40331102803,
    "YEAR_4": 165157.8124680564,
    "YEAR_5": 165496.22162508473,
    "YEAR_6": 165834.63078211306,
    "YEAR_7": 166173.03993914142,
    "YEAR_8": 166511.44909616976,
    "YEAR_9": 166849.8582531981,
    "YEAR_10": 167188.26741022643,
    "AD_Reve": 1656654.262035989
  },
  {
    "DMA": "Burlington-Plattsburgh",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 810450,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 162090,
    "No of HHS for Ads": 405.225,
    "Revenue per 1000 Devices Hour": 10.333237500000001,
    "Revenue per 1000 Devices per day": 41.332950000000004,
    "Revenue per month(Video Ad)": 1239.9885000000002,
    "Revenue per month (Banner Ad)": 12399.885000000002,
    "Total Revenue per month from all Ads": 13639.873500000002,
    "YEAR_1": 163833.29393679084,
    "YEAR_2": 164171.0654352435,
    "YEAR_3": 164508.83693369624,
    "YEAR_4": 164846.60843214893,
    "YEAR_5": 165184.37993060163,
    "YEAR_6": 165522.1514290543,
    "YEAR_7": 165859.922927507,
    "YEAR_8": 166197.6944259597,
    "YEAR_9": 166535.46592441242,
    "YEAR_10": 166873.23742286512,
    "AD_Reve": 1653532.6567982796
  },
  {
    "DMA": "Baton Rouge",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 795375,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 159075,
    "No of HHS for Ads": 397.6875,
    "Revenue per 1000 Devices Hour": 10.14103125,
    "Revenue per 1000 Devices per day": 40.564125,
    "Revenue per month(Video Ad)": 1216.92375,
    "Revenue per month (Banner Ad)": 12169.2375,
    "Total Revenue per month from all Ads": 13386.16125,
    "YEAR_1": 160785.86731442407,
    "YEAR_2": 161117.35600044025,
    "YEAR_3": 161448.84468645643,
    "YEAR_4": 161780.3333724726,
    "YEAR_5": 162111.8220584888,
    "YEAR_6": 162443.31074450497,
    "YEAR_7": 162774.79943052118,
    "YEAR_8": 163106.28811653735,
    "YEAR_9": 163437.7768025535,
    "YEAR_10": 163769.2654885697,
    "AD_Reve": 1622775.6640149688
  },
  {
    "DMA": "Jackson",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 766620,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 153324,
    "No of HHS for Ads": 383.31,
    "Revenue per 1000 Devices Hour": 9.774405,
    "Revenue per 1000 Devices per day": 39.09762,
    "Revenue per month(Video Ad)": 1172.9286,
    "Revenue per month (Banner Ad)": 11729.286,
    "Total Revenue per month from all Ads": 12902.2146,
    "YEAR_1": 154973.01474220812,
    "YEAR_2": 155292.51919793495,
    "YEAR_3": 155612.02365366177,
    "YEAR_4": 155931.5281093886,
    "YEAR_5": 156251.0325651154,
    "YEAR_6": 156570.53702084222,
    "YEAR_7": 156890.04147656905,
    "YEAR_8": 157209.54593229588,
    "YEAR_9": 157529.05038802273,
    "YEAR_10": 157848.55484374956,
    "AD_Reve": 1564107.8479297885
  },
  {
    "DMA": "Fort Smith-Fayetteville-Springdale-Rogers",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 761197.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 152239.5,
    "No of HHS for Ads": 380.59875,
    "Revenue per 1000 Devices Hour": 9.705268124999998,
    "Revenue per 1000 Devices per day": 38.82107249999999,
    "Revenue per month(Video Ad)": 1164.6321749999997,
    "Revenue per month (Banner Ad)": 11646.321749999997,
    "Total Revenue per month from all Ads": 12810.953924999998,
    "YEAR_1": 153876.85083774483,
    "YEAR_2": 154194.09535646092,
    "YEAR_3": 154511.33987517701,
    "YEAR_4": 154828.58439389308,
    "YEAR_5": 155145.82891260917,
    "YEAR_6": 155463.07343132523,
    "YEAR_7": 155780.31795004132,
    "YEAR_8": 156097.5624687574,
    "YEAR_9": 156414.80698747348,
    "YEAR_10": 156732.05150618957,
    "AD_Reve": 1553044.511719672
  },
  {
    "DMA": "Boise",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 742590,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 148518,
    "No of HHS for Ads": 371.295,
    "Revenue per 1000 Devices Hour": 9.4680225,
    "Revenue per 1000 Devices per day": 37.87209,
    "Revenue per month(Video Ad)": 1136.1627,
    "Revenue per month (Banner Ad)": 11361.627,
    "Total Revenue per month from all Ads": 12497.789700000001,
    "YEAR_1": 150115.3257381967,
    "YEAR_2": 150424.81520335306,
    "YEAR_3": 150734.30466850943,
    "YEAR_4": 151043.79413366583,
    "YEAR_5": 151353.28359882222,
    "YEAR_6": 151662.7730639786,
    "YEAR_7": 151972.26252913495,
    "YEAR_8": 152281.75199429132,
    "YEAR_9": 152591.24145944772,
    "YEAR_10": 152900.73092460408,
    "AD_Reve": 1515080.2833140038
  },
  {
    "DMA": "South Bend-Elkhart",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 740430,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 148086,
    "No of HHS for Ads": 370.21500000000003,
    "Revenue per 1000 Devices Hour": 9.4404825,
    "Revenue per 1000 Devices per day": 37.76193,
    "Revenue per month(Video Ad)": 1132.8579,
    "Revenue per month (Banner Ad)": 11328.579,
    "Total Revenue per month from all Ads": 12461.4369,
    "YEAR_1": 149678.67953558892,
    "YEAR_2": 149987.2687768738,
    "YEAR_3": 150295.85801815864,
    "YEAR_4": 150604.44725944355,
    "YEAR_5": 150913.03650072843,
    "YEAR_6": 151221.62574201328,
    "YEAR_7": 151530.21498329818,
    "YEAR_8": 151838.80422458306,
    "YEAR_9": 152147.3934658679,
    "YEAR_10": 152455.9827071528,
    "AD_Reve": 1510673.3112137087
  },
  {
    "DMA": "Myrtle Beach-Florence",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 724725,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 144945,
    "No of HHS for Ads": 362.3625,
    "Revenue per 1000 Devices Hour": 9.240243750000001,
    "Revenue per 1000 Devices per day": 36.960975000000005,
    "Revenue per month(Video Ad)": 1108.8292500000002,
    "Revenue per month (Banner Ad)": 11088.292500000003,
    "Total Revenue per month from all Ads": 12197.121750000004,
    "YEAR_1": 146503.89777079495,
    "YEAR_2": 146805.94163434746,
    "YEAR_3": 147107.98549789996,
    "YEAR_4": 147410.02936145247,
    "YEAR_5": 147712.07322500498,
    "YEAR_6": 148014.1170885575,
    "YEAR_7": 148316.16095211002,
    "YEAR_8": 148618.20481566252,
    "YEAR_9": 148920.24867921503,
    "YEAR_10": 149222.29254276754,
    "AD_Reve": 1478630.9515678124
  },
  {
    "DMA": "Tri-Cities",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 721845,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 144369,
    "No of HHS for Ads": 360.9225,
    "Revenue per 1000 Devices Hour": 9.20352375,
    "Revenue per 1000 Devices per day": 36.814095,
    "Revenue per month(Video Ad)": 1104.4228500000002,
    "Revenue per month (Banner Ad)": 11044.228500000001,
    "Total Revenue per month from all Ads": 12148.651350000002,
    "YEAR_1": 145921.70283398454,
    "YEAR_2": 146222.54639904175,
    "YEAR_3": 146523.38996409893,
    "YEAR_4": 146824.23352915607,
    "YEAR_5": 147125.07709421325,
    "YEAR_6": 147425.92065927043,
    "YEAR_7": 147726.7642243276,
    "YEAR_8": 148027.60778938475,
    "YEAR_9": 148328.45135444196,
    "YEAR_10": 148629.2949194991,
    "AD_Reve": 1472754.9887674183
  },
  {
    "DMA": "Reno",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 690615,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 138123,
    "No of HHS for Ads": 345.3075,
    "Revenue per 1000 Devices Hour": 8.805341250000001,
    "Revenue per 1000 Devices per day": 35.221365000000006,
    "Revenue per month(Video Ad)": 1056.6409500000002,
    "Revenue per month (Banner Ad)": 10566.409500000002,
    "Total Revenue per month from all Ads": 11623.050450000002,
    "YEAR_1": 139608.5264879472,
    "YEAR_2": 139896.35431619562,
    "YEAR_3": 140184.182144444,
    "YEAR_4": 140472.00997269238,
    "YEAR_5": 140759.8378009408,
    "YEAR_6": 141047.66562918917,
    "YEAR_7": 141335.49345743755,
    "YEAR_8": 141623.32128568596,
    "YEAR_9": 141911.14911393434,
    "YEAR_10": 142198.97694218272,
    "AD_Reve": 1409037.5171506496
  },
  {
    "DMA": "Washington",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 686970,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 137394,
    "No of HHS for Ads": 343.485,
    "Revenue per 1000 Devices Hour": 8.758867500000001,
    "Revenue per 1000 Devices per day": 35.035470000000004,
    "Revenue per month(Video Ad)": 1051.0641,
    "Revenue per month (Banner Ad)": 10510.641,
    "Total Revenue per month from all Ads": 11561.7051,
    "YEAR_1": 138871.68602104654,
    "YEAR_2": 139157.99472151182,
    "YEAR_3": 139444.303421977,
    "YEAR_4": 139730.61212244225,
    "YEAR_5": 140016.9208229075,
    "YEAR_6": 140303.22952337272,
    "YEAR_7": 140589.53822383797,
    "YEAR_8": 140875.8469243032,
    "YEAR_9": 141162.15562476843,
    "YEAR_10": 141448.46432523365,
    "AD_Reve": 1401600.7517314008
  },
  {
    "DMA": "Davenport-Rock Island-Moline",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 680850,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 136170,
    "No of HHS for Ads": 340.425,
    "Revenue per 1000 Devices Hour": 8.6808375,
    "Revenue per 1000 Devices per day": 34.72335,
    "Revenue per month(Video Ad)": 1041.7005000000001,
    "Revenue per month (Banner Ad)": 10417.005000000001,
    "Total Revenue per month from all Ads": 11458.705500000002,
    "YEAR_1": 137634.52178032455,
    "YEAR_2": 137918.27984648722,
    "YEAR_3": 138202.03791264986,
    "YEAR_4": 138485.7959788125,
    "YEAR_5": 138769.55404497517,
    "YEAR_6": 139053.3121111378,
    "YEAR_7": 139337.07017730045,
    "YEAR_8": 139620.82824346313,
    "YEAR_9": 139904.58630962574,
    "YEAR_10": 140188.3443757884,
    "AD_Reve": 1389114.3307805648
  },
  {
    "DMA": "Tallahassee-Thomasville",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 673942.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 134788.5,
    "No of HHS for Ads": 336.97125,
    "Revenue per 1000 Devices Hour": 8.592766874999999,
    "Revenue per 1000 Devices per day": 34.371067499999995,
    "Revenue per month(Video Ad)": 1031.1320249999999,
    "Revenue per month (Banner Ad)": 10311.320249999999,
    "Total Revenue per month from all Ads": 11342.452275,
    "YEAR_1": 136238.16361156842,
    "YEAR_2": 136519.0428368087,
    "YEAR_3": 136799.92206204892,
    "YEAR_4": 137080.80128728918,
    "YEAR_5": 137361.68051252942,
    "YEAR_6": 137642.55973776965,
    "YEAR_7": 137923.4389630099,
    "YEAR_8": 138204.31818825015,
    "YEAR_9": 138485.19741349042,
    "YEAR_10": 138766.07663873065,
    "AD_Reve": 1375021.2012514954
  },
  {
    "DMA": "Lincoln-Hastings-Kearney",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 656212.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 131242.5,
    "No of HHS for Ads": 328.10625,
    "Revenue per 1000 Devices Hour": 8.366709375,
    "Revenue per 1000 Devices per day": 33.4668375,
    "Revenue per month(Video Ad)": 1004.0051249999999,
    "Revenue per month (Banner Ad)": 10040.051249999999,
    "Total Revenue per month from all Ads": 11044.056374999998,
    "YEAR_1": 132654.02603182965,
    "YEAR_2": 132927.51591945798,
    "YEAR_3": 133201.00580708633,
    "YEAR_4": 133474.49569471466,
    "YEAR_5": 133747.98558234298,
    "YEAR_6": 134021.47546997134,
    "YEAR_7": 134294.9653575997,
    "YEAR_8": 134568.45524522805,
    "YEAR_9": 134841.94513285637,
    "YEAR_10": 135115.4350204847,
    "AD_Reve": 1338847.3052615717
  },
  {
    "DMA": "Evansville",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 654727.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 130945.5,
    "No of HHS for Ads": 327.36375,
    "Revenue per 1000 Devices Hour": 8.347775625,
    "Revenue per 1000 Devices per day": 33.3911025,
    "Revenue per month(Video Ad)": 1001.7330750000001,
    "Revenue per month (Banner Ad)": 10017.330750000001,
    "Total Revenue per month from all Ads": 11019.063825000001,
    "YEAR_1": 132353.83176753682,
    "YEAR_2": 132626.70275125353,
    "YEAR_3": 132899.5737349702,
    "YEAR_4": 133172.44471868686,
    "YEAR_5": 133445.31570240358,
    "YEAR_6": 133718.18668612026,
    "YEAR_7": 133991.05766983694,
    "YEAR_8": 134263.92865355365,
    "YEAR_9": 134536.7996372703,
    "YEAR_10": 134809.670620987,
    "AD_Reve": 1335817.511942619
  },
  {
    "DMA": "Fort Wayne",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 642060,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 128412,
    "No of HHS for Ads": 321.03000000000003,
    "Revenue per 1000 Devices Hour": 8.186265,
    "Revenue per 1000 Devices per day": 32.74506,
    "Revenue per month(Video Ad)": 982.3518,
    "Revenue per month (Banner Ad)": 9823.518,
    "Total Revenue per month from all Ads": 10805.8698,
    "YEAR_1": 129793.08372516002,
    "YEAR_2": 130060.67527096358,
    "YEAR_3": 130328.26681676721,
    "YEAR_4": 130595.85836257083,
    "YEAR_5": 130863.44990837446,
    "YEAR_6": 131131.04145417808,
    "YEAR_7": 131398.63299998164,
    "YEAR_8": 131666.22454578528,
    "YEAR_9": 131933.8160915889,
    "YEAR_10": 132201.40763739252,
    "AD_Reve": 1309972.4568127627
  },
  {
    "DMA": "Johnstown-Altoona-State College",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 639675,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 127935,
    "No of HHS for Ads": 319.83750000000003,
    "Revenue per 1000 Devices Hour": 8.155856250000001,
    "Revenue per 1000 Devices per day": 32.623425000000005,
    "Revenue per month(Video Ad)": 978.7027500000002,
    "Revenue per month (Banner Ad)": 9787.027500000002,
    "Total Revenue per month from all Ads": 10765.730250000002,
    "YEAR_1": 129310.95354311395,
    "YEAR_2": 129577.55109172611,
    "YEAR_3": 129844.14864033827,
    "YEAR_4": 130110.74618895043,
    "YEAR_5": 130377.3437375626,
    "YEAR_6": 130643.94128617476,
    "YEAR_7": 130910.53883478692,
    "YEAR_8": 131177.13638339908,
    "YEAR_9": 131443.73393201127,
    "YEAR_10": 131710.3314806234,
    "AD_Reve": 1305106.425118687
  },
  {
    "DMA": "Augusta-Aiken",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 636975,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 127395,
    "No of HHS for Ads": 318.4875,
    "Revenue per 1000 Devices Hour": 8.12143125,
    "Revenue per 1000 Devices per day": 32.485725,
    "Revenue per month(Video Ad)": 974.5717500000001,
    "Revenue per month (Banner Ad)": 9745.7175,
    "Total Revenue per month from all Ads": 10720.289250000002,
    "YEAR_1": 128765.14578985421,
    "YEAR_2": 129030.61805862702,
    "YEAR_3": 129296.0903273998,
    "YEAR_4": 129561.56259617256,
    "YEAR_5": 129827.03486494537,
    "YEAR_6": 130092.50713371817,
    "YEAR_7": 130357.97940249094,
    "YEAR_8": 130623.45167126371,
    "YEAR_9": 130888.92394003652,
    "YEAR_10": 131154.3962088093,
    "AD_Reve": 1299597.7099933173
  },
  {
    "DMA": "Tyler-Longview(Lufkin & Nacogdoches)",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 634702.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 126940.5,
    "No of HHS for Ads": 317.35125,
    "Revenue per 1000 Devices Hour": 8.092456875,
    "Revenue per 1000 Devices per day": 32.3698275,
    "Revenue per month(Video Ad)": 971.094825,
    "Revenue per month (Banner Ad)": 9710.94825,
    "Total Revenue per month from all Ads": 10682.043075,
    "YEAR_1": 128305.75759752725,
    "YEAR_2": 128570.2827557686,
    "YEAR_3": 128834.80791400989,
    "YEAR_4": 129099.33307225123,
    "YEAR_5": 129363.8582304925,
    "YEAR_6": 129628.38338873384,
    "YEAR_7": 129892.90854697512,
    "YEAR_8": 130157.43370521646,
    "YEAR_9": 130421.95886345775,
    "YEAR_10": 130686.48402169907,
    "AD_Reve": 1294961.2080961317
  },
  {
    "DMA": "Sioux Falls-Mitchell",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 622305,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 124461,
    "No of HHS for Ads": 311.15250000000003,
    "Revenue per 1000 Devices Hour": 7.934388750000001,
    "Revenue per 1000 Devices per day": 31.737555000000004,
    "Revenue per month(Video Ad)": 952.1266500000002,
    "Revenue per month (Banner Ad)": 9521.266500000002,
    "Total Revenue per month from all Ads": 10473.393150000002,
    "YEAR_1": 125799.59033047644,
    "YEAR_2": 126058.9485787886,
    "YEAR_3": 126318.30682710084,
    "YEAR_4": 126577.66507541297,
    "YEAR_5": 126837.02332372518,
    "YEAR_6": 127096.38157203731,
    "YEAR_7": 127355.73982034952,
    "YEAR_8": 127615.09806866167,
    "YEAR_9": 127874.45631697387,
    "YEAR_10": 128133.81456528601,
    "AD_Reve": 1269667.0244788125
  },
  {
    "DMA": "Fargo-Valley City",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 598027.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 119605.5,
    "No of HHS for Ads": 299.01375,
    "Revenue per 1000 Devices Hour": 7.624850625,
    "Revenue per 1000 Devices per day": 30.4994025,
    "Revenue per month(Video Ad)": 914.982075,
    "Revenue per month (Banner Ad)": 9149.82075,
    "Total Revenue per month from all Ads": 10064.802825,
    "YEAR_1": 120891.86894908283,
    "YEAR_2": 121141.10905617262,
    "YEAR_3": 121390.3491632624,
    "YEAR_4": 121639.5892703522,
    "YEAR_5": 121888.82937744197,
    "YEAR_6": 122138.06948453176,
    "YEAR_7": 122387.30959162157,
    "YEAR_8": 122636.54969871137,
    "YEAR_9": 122885.78980580112,
    "YEAR_10": 123135.02991289094,
    "AD_Reve": 1220134.4943098687
  },
  {
    "DMA": "Springfield-Holyoke",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 594945,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 118989,
    "No of HHS for Ads": 297.4725,
    "Revenue per 1000 Devices Hour": 7.58554875,
    "Revenue per 1000 Devices per day": 30.342195,
    "Revenue per month(Video Ad)": 910.26585,
    "Revenue per month (Banner Ad)": 9102.6585,
    "Total Revenue per month from all Ads": 10012.92435,
    "YEAR_1": 120268.73843077797,
    "YEAR_2": 120516.69384338448,
    "YEAR_3": 120764.64925599097,
    "YEAR_4": 121012.60466859747,
    "YEAR_5": 121260.56008120396,
    "YEAR_6": 121508.51549381047,
    "YEAR_7": 121756.47090641697,
    "YEAR_8": 122004.42631902349,
    "YEAR_9": 122252.38173163,
    "YEAR_10": 122500.33714423649,
    "AD_Reve": 1213845.3778750724
  },
  {
    "DMA": "Lansing",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 586755,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 117351,
    "No of HHS for Ads": 293.3775,
    "Revenue per 1000 Devices Hour": 7.481126249999999,
    "Revenue per 1000 Devices per day": 29.924504999999996,
    "Revenue per month(Video Ad)": 897.7351499999999,
    "Revenue per month (Banner Ad)": 8977.351499999999,
    "Total Revenue per month from all Ads": 9875.08665,
    "YEAR_1": 118613.12157922347,
    "YEAR_2": 118857.66364298393,
    "YEAR_3": 119102.2057067443,
    "YEAR_4": 119346.74777050466,
    "YEAR_5": 119591.28983426512,
    "YEAR_6": 119835.83189802548,
    "YEAR_7": 120080.37396178584,
    "YEAR_8": 120324.91602554629,
    "YEAR_9": 120569.45808930667,
    "YEAR_10": 120814.00015306703,
    "AD_Reve": 1197135.6086614528
  },
  {
    "DMA": "Youngstown",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 585495,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 117099,
    "No of HHS for Ads": 292.7475,
    "Revenue per 1000 Devices Hour": 7.465061250000001,
    "Revenue per 1000 Devices per day": 29.860245000000003,
    "Revenue per month(Video Ad)": 895.80735,
    "Revenue per month (Banner Ad)": 8958.0735,
    "Total Revenue per month from all Ads": 9853.880850000001,
    "YEAR_1": 118358.41129436898,
    "YEAR_2": 118602.42822753769,
    "YEAR_3": 118846.44516070637,
    "YEAR_4": 119090.46209387504,
    "YEAR_5": 119334.47902704374,
    "YEAR_6": 119578.49596021243,
    "YEAR_7": 119822.5128933811,
    "YEAR_8": 120066.5298265498,
    "YEAR_9": 120310.54675971846,
    "YEAR_10": 120554.56369288717,
    "AD_Reve": 1194564.8749362808
  },
  {
    "DMA": "Yakima-Pasco-Richland-Kennewick",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 581310,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 116262,
    "No of HHS for Ads": 290.65500000000003,
    "Revenue per 1000 Devices Hour": 7.411702500000001,
    "Revenue per 1000 Devices per day": 29.646810000000006,
    "Revenue per month(Video Ad)": 889.4043000000001,
    "Revenue per month (Banner Ad)": 8894.043000000001,
    "Total Revenue per month from all Ads": 9783.447300000002,
    "YEAR_1": 117512.40927681646,
    "YEAR_2": 117754.68202623409,
    "YEAR_3": 117996.95477565174,
    "YEAR_4": 118239.22752506941,
    "YEAR_5": 118481.50027448704,
    "YEAR_6": 118723.77302390471,
    "YEAR_7": 118966.04577332237,
    "YEAR_8": 119208.31852274001,
    "YEAR_9": 119450.59127215766,
    "YEAR_10": 119692.86402157531,
    "AD_Reve": 1186026.3664919587
  },
  {
    "DMA": "Traverse City-Cadillac",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 571770,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 114354,
    "No of HHS for Ads": 285.885,
    "Revenue per 1000 Devices Hour": 7.2900675,
    "Revenue per 1000 Devices per day": 29.16027,
    "Revenue per month(Video Ad)": 874.8081,
    "Revenue per month (Banner Ad)": 8748.081,
    "Total Revenue per month from all Ads": 9622.8891,
    "YEAR_1": 115583.88854863211,
    "YEAR_2": 115822.18530928396,
    "YEAR_3": 116060.48206993581,
    "YEAR_4": 116298.77883058767,
    "YEAR_5": 116537.07559123954,
    "YEAR_6": 116775.37235189139,
    "YEAR_7": 117013.66911254326,
    "YEAR_8": 117251.96587319512,
    "YEAR_9": 117490.26263384696,
    "YEAR_10": 117728.55939449882,
    "AD_Reve": 1166562.2397156544
  },
  {
    "DMA": "Eugene",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 564390,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 112878,
    "No of HHS for Ads": 282.195,
    "Revenue per 1000 Devices Hour": 7.1959725,
    "Revenue per 1000 Devices per day": 28.78389,
    "Revenue per month(Video Ad)": 863.5167,
    "Revenue per month (Banner Ad)": 8635.167,
    "Total Revenue per month from all Ads": 9498.6837,
    "YEAR_1": 114092.01402305556,
    "YEAR_2": 114327.23501881311,
    "YEAR_3": 114562.4560145707,
    "YEAR_4": 114797.67701032825,
    "YEAR_5": 115032.8980060858,
    "YEAR_6": 115268.11900184337,
    "YEAR_7": 115503.33999760092,
    "YEAR_8": 115738.5609933585,
    "YEAR_9": 115973.78198911605,
    "YEAR_10": 116209.0029848736,
    "AD_Reve": 1151505.085039646
  },
  {
    "DMA": "Macon",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 563895,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 112779,
    "No of HHS for Ads": 281.9475,
    "Revenue per 1000 Devices Hour": 7.18966125,
    "Revenue per 1000 Devices per day": 28.758645,
    "Revenue per month(Video Ad)": 862.75935,
    "Revenue per month (Banner Ad)": 8627.5935,
    "Total Revenue per month from all Ads": 9490.352850000001,
    "YEAR_1": 113991.94926829128,
    "YEAR_2": 114226.96396274497,
    "YEAR_3": 114461.97865719865,
    "YEAR_4": 114696.99335165232,
    "YEAR_5": 114932.008046106,
    "YEAR_6": 115167.02274055967,
    "YEAR_7": 115402.03743501334,
    "YEAR_8": 115637.05212946703,
    "YEAR_9": 115872.06682392072,
    "YEAR_10": 116107.08151837437,
    "AD_Reve": 1150495.153933328
  },
  {
    "DMA": "Montgomery-Selma",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 560565,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 112113,
    "No of HHS for Ads": 280.2825,
    "Revenue per 1000 Devices Hour": 7.147203750000001,
    "Revenue per 1000 Devices per day": 28.588815000000004,
    "Revenue per month(Video Ad)": 857.6644500000001,
    "Revenue per month (Banner Ad)": 8576.6445,
    "Total Revenue per month from all Ads": 9434.30895,
    "YEAR_1": 113318.7863726043,
    "YEAR_2": 113552.41322192273,
    "YEAR_3": 113786.0400712412,
    "YEAR_4": 114019.66692055963,
    "YEAR_5": 114253.29376987807,
    "YEAR_6": 114486.92061919653,
    "YEAR_7": 114720.54746851498,
    "YEAR_8": 114954.17431783341,
    "YEAR_9": 115187.80116715188,
    "YEAR_10": 115421.42801647031,
    "AD_Reve": 1143701.071945373
  },
  {
    "DMA": "Peoria-Bloomington",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 558247.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 111649.5,
    "No of HHS for Ads": 279.12375000000003,
    "Revenue per 1000 Devices Hour": 7.117655625000001,
    "Revenue per 1000 Devices per day": 28.470622500000005,
    "Revenue per month(Video Ad)": 854.1186750000002,
    "Revenue per month (Banner Ad)": 8541.18675,
    "Total Revenue per month from all Ads": 9395.305425,
    "YEAR_1": 112850.3013843897,
    "YEAR_2": 113082.96236851269,
    "YEAR_3": 113315.62335263567,
    "YEAR_4": 113548.28433675865,
    "YEAR_5": 113780.94532088163,
    "YEAR_6": 114013.60630500462,
    "YEAR_7": 114246.2672891276,
    "YEAR_8": 114478.92827325057,
    "YEAR_9": 114711.58925737356,
    "YEAR_10": 114944.25024149656,
    "AD_Reve": 1138972.7581294314
  },
  {
    "DMA": "Bakersfield",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 556155,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 111231,
    "No of HHS for Ads": 278.0775,
    "Revenue per 1000 Devices Hour": 7.09097625,
    "Revenue per 1000 Devices per day": 28.363905,
    "Revenue per month(Video Ad)": 850.91715,
    "Revenue per month (Banner Ad)": 8509.1715,
    "Total Revenue per month from all Ads": 9360.08865,
    "YEAR_1": 112427.3003756134,
    "YEAR_2": 112659.08926786088,
    "YEAR_3": 112890.87816010836,
    "YEAR_4": 113122.6670523558,
    "YEAR_5": 113354.45594460327,
    "YEAR_6": 113586.24483685075,
    "YEAR_7": 113818.03372909821,
    "YEAR_8": 114049.82262134568,
    "YEAR_9": 114281.61151359313,
    "YEAR_10": 114513.40040584061,
    "AD_Reve": 1134703.5039072703
  },
  {
    "DMA": "Santa Barbara-Santa Maria-San Luis Obispo",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 542700,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 108540,
    "No of HHS for Ads": 271.35,
    "Revenue per 1000 Devices Hour": 6.919425,
    "Revenue per 1000 Devices per day": 27.6777,
    "Revenue per month(Video Ad)": 830.331,
    "Revenue per month (Banner Ad)": 8303.31,
    "Total Revenue per month from all Ads": 9133.641,
    "YEAR_1": 109707.35840520248,
    "YEAR_2": 109933.5396529171,
    "YEAR_3": 110159.72090063166,
    "YEAR_4": 110385.90214834627,
    "YEAR_5": 110612.08339606081,
    "YEAR_6": 110838.2646437754,
    "YEAR_7": 111064.44589148996,
    "YEAR_8": 111290.62713920452,
    "YEAR_9": 111516.80838691912,
    "YEAR_10": 111742.98963463368,
    "AD_Reve": 1107251.740199181
  },
  {
    "DMA": "Lafayette",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 540337.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 108067.5,
    "No of HHS for Ads": 270.16875,
    "Revenue per 1000 Devices Hour": 6.889303125,
    "Revenue per 1000 Devices per day": 27.5572125,
    "Revenue per month(Video Ad)": 826.716375,
    "Revenue per month (Banner Ad)": 8267.16375,
    "Total Revenue per month from all Ads": 9093.880125,
    "YEAR_1": 109229.77662110025,
    "YEAR_2": 109454.97324895539,
    "YEAR_3": 109680.16987681051,
    "YEAR_4": 109905.36650466561,
    "YEAR_5": 110130.56313252075,
    "YEAR_6": 110355.75976037588,
    "YEAR_7": 110580.95638823099,
    "YEAR_8": 110806.15301608611,
    "YEAR_9": 111031.34964394124,
    "YEAR_10": 111256.54627179637,
    "AD_Reve": 1102431.6144644832
  },
  {
    "DMA": "Columbus-Opelika",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 529402.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 105880.5,
    "No of HHS for Ads": 264.70125,
    "Revenue per 1000 Devices Hour": 6.749881875,
    "Revenue per 1000 Devices per day": 26.9995275,
    "Revenue per month(Video Ad)": 809.985825,
    "Revenue per month (Banner Ad)": 8099.858249999999,
    "Total Revenue per month from all Ads": 8909.844074999999,
    "YEAR_1": 107019.25522039841,
    "YEAR_2": 107239.89446490406,
    "YEAR_3": 107460.5337094097,
    "YEAR_4": 107681.17295391535,
    "YEAR_5": 107901.81219842102,
    "YEAR_6": 108122.45144292666,
    "YEAR_7": 108343.0906874323,
    "YEAR_8": 108563.72993193795,
    "YEAR_9": 108784.36917644361,
    "YEAR_10": 109005.00842094925,
    "AD_Reve": 1080121.3182067384
  },
  {
    "DMA": "Monterey-Salinas",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 523980,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 104796,
    "No of HHS for Ads": 261.99,
    "Revenue per 1000 Devices Hour": 6.680745,
    "Revenue per 1000 Devices per day": 26.72298,
    "Revenue per month(Video Ad)": 801.6894,
    "Revenue per month (Banner Ad)": 8016.894,
    "Total Revenue per month from all Ads": 8818.5834,
    "YEAR_1": 105923.09131593516,
    "YEAR_2": 106141.47062343007,
    "YEAR_3": 106359.84993092497,
    "YEAR_4": 106578.22923841987,
    "YEAR_5": 106796.60854591477,
    "YEAR_6": 107014.98785340966,
    "YEAR_7": 107233.36716090457,
    "YEAR_8": 107451.74646839948,
    "YEAR_9": 107670.12577589437,
    "YEAR_10": 107888.50508338927,
    "AD_Reve": 1069057.9819966224
  },
  {
    "DMA": "La Crosse-Eau Claire",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 510097.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 102019.5,
    "No of HHS for Ads": 255.04875,
    "Revenue per 1000 Devices Hour": 6.503743125,
    "Revenue per 1000 Devices per day": 26.0149725,
    "Revenue per month(Video Ad)": 780.449175,
    "Revenue per month (Banner Ad)": 7804.491749999999,
    "Total Revenue per month from all Ads": 8584.940924999999,
    "YEAR_1": 103116.72978459144,
    "YEAR_2": 103329.32327824557,
    "YEAR_3": 103541.91677189969,
    "YEAR_4": 103754.5102655538,
    "YEAR_5": 103967.10375920791,
    "YEAR_6": 104179.69725286202,
    "YEAR_7": 104392.29074651613,
    "YEAR_8": 104604.88424017023,
    "YEAR_9": 104817.47773382434,
    "YEAR_10": 105030.07122747845,
    "AD_Reve": 1040734.0050603495
  },
  {
    "DMA": "Wilmington",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 493965,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 98793,
    "No of HHS for Ads": 246.98250000000002,
    "Revenue per 1000 Devices Hour": 6.29805375,
    "Revenue per 1000 Devices per day": 25.192215,
    "Revenue per month(Video Ad)": 755.7664500000001,
    "Revenue per month (Banner Ad)": 7557.664500000001,
    "Total Revenue per month from all Ads": 8313.430950000002,
    "YEAR_1": 99855.52845886469,
    "YEAR_2": 100061.39840547854,
    "YEAR_3": 100267.26835209237,
    "YEAR_4": 100473.13829870621,
    "YEAR_5": 100679.00824532004,
    "YEAR_6": 100884.87819193391,
    "YEAR_7": 101090.74813854773,
    "YEAR_8": 101296.61808516158,
    "YEAR_9": 101502.48803177541,
    "YEAR_10": 101708.35797838925,
    "AD_Reve": 1007819.4321862698
  },
  {
    "DMA": "Corpus Christi",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 469102.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 93820.5,
    "No of HHS for Ads": 234.55125,
    "Revenue per 1000 Devices Hour": 5.981056875,
    "Revenue per 1000 Devices per day": 23.9242275,
    "Revenue per month(Video Ad)": 717.726825,
    "Revenue per month (Banner Ad)": 7177.268249999999,
    "Total Revenue per month from all Ads": 7894.995074999999,
    "YEAR_1": 94829.54873093148,
    "YEAR_2": 95025.05672569104,
    "YEAR_3": 95220.56472045062,
    "YEAR_4": 95416.07271521022,
    "YEAR_5": 95611.58070996981,
    "YEAR_6": 95807.0887047294,
    "YEAR_7": 96002.59669948897,
    "YEAR_8": 96198.10469424856,
    "YEAR_9": 96393.61268900815,
    "YEAR_10": 96589.12068376772,
    "AD_Reve": 957093.3470734959
  },
  {
    "DMA": "Amarillo",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 443430,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 88686,
    "No of HHS for Ads": 221.715,
    "Revenue per 1000 Devices Hour": 5.6537325,
    "Revenue per 1000 Devices per day": 22.61493,
    "Revenue per month(Video Ad)": 678.4479,
    "Revenue per month (Banner Ad)": 6784.479,
    "Total Revenue per month from all Ads": 7462.9269,
    "YEAR_1": 89639.82667702038,
    "YEAR_2": 89824.63513597389,
    "YEAR_3": 90009.4435949274,
    "YEAR_4": 90194.25205388092,
    "YEAR_5": 90379.06051283443,
    "YEAR_6": 90563.86897178796,
    "YEAR_7": 90748.67743074147,
    "YEAR_8": 90933.48588969499,
    "YEAR_9": 91118.29434864852,
    "YEAR_10": 91303.10280760203,
    "AD_Reve": 904714.6474231121
  },
  {
    "DMA": "Wausau-Rhinelander",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 425205,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 85041,
    "No of HHS for Ads": 212.6025,
    "Revenue per 1000 Devices Hour": 5.42136375,
    "Revenue per 1000 Devices per day": 21.685455,
    "Revenue per month(Video Ad)": 650.56365,
    "Revenue per month (Banner Ad)": 6505.6365000000005,
    "Total Revenue per month from all Ads": 7156.200150000001,
    "YEAR_1": 85955.62434251729,
    "YEAR_2": 86132.83716255502,
    "YEAR_3": 86310.04998259275,
    "YEAR_4": 86487.26280263049,
    "YEAR_5": 86664.47562266822,
    "YEAR_6": 86841.68844270596,
    "YEAR_7": 87018.90126274369,
    "YEAR_8": 87196.11408278142,
    "YEAR_9": 87373.32690281914,
    "YEAR_10": 87550.53972285688,
    "AD_Reve": 867530.8203268708
  },
  {
    "DMA": "Chico-Redding",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 425025,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 85005,
    "No of HHS for Ads": 212.51250000000002,
    "Revenue per 1000 Devices Hour": 5.41906875,
    "Revenue per 1000 Devices per day": 21.676275,
    "Revenue per month(Video Ad)": 650.2882500000001,
    "Revenue per month (Banner Ad)": 6502.882500000001,
    "Total Revenue per month from all Ads": 7153.170750000001,
    "YEAR_1": 85919.23715896666,
    "YEAR_2": 86096.37496034845,
    "YEAR_3": 86273.51276173019,
    "YEAR_4": 86450.65056311198,
    "YEAR_5": 86627.78836449374,
    "YEAR_6": 86804.92616587553,
    "YEAR_7": 86982.06396725729,
    "YEAR_8": 87159.20176863908,
    "YEAR_9": 87336.33957002083,
    "YEAR_10": 87513.4773714026,
    "AD_Reve": 867163.5726518462
  },
  {
    "DMA": "Columbus-Tupelo-West Point-Houston",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 424170,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 84834,
    "No of HHS for Ads": 212.085,
    "Revenue per 1000 Devices Hour": 5.408167499999999,
    "Revenue per 1000 Devices per day": 21.632669999999997,
    "Revenue per month(Video Ad)": 648.9800999999999,
    "Revenue per month (Banner Ad)": 6489.800999999999,
    "Total Revenue per month from all Ads": 7138.781099999998,
    "YEAR_1": 85746.39803710105,
    "YEAR_2": 85923.17949986701,
    "YEAR_3": 86099.96096263299,
    "YEAR_4": 86276.74242539894,
    "YEAR_5": 86453.52388816493,
    "YEAR_6": 86630.3053509309,
    "YEAR_7": 86807.08681369686,
    "YEAR_8": 86983.86827646283,
    "YEAR_9": 87160.6497392288,
    "YEAR_10": 87337.43120199477,
    "AD_Reve": 865419.146195479
  },
  {
    "DMA": "Salisbury",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 417307.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 83461.5,
    "No of HHS for Ads": 208.65375,
    "Revenue per 1000 Devices Hour": 5.320670625000001,
    "Revenue per 1000 Devices per day": 21.282682500000004,
    "Revenue per month(Video Ad)": 638.4804750000001,
    "Revenue per month (Banner Ad)": 6384.804750000001,
    "Total Revenue per month from all Ads": 7023.285225000001,
    "YEAR_1": 84359.13666423265,
    "YEAR_2": 84533.0580407402,
    "YEAR_3": 84706.97941724776,
    "YEAR_4": 84880.90079375531,
    "YEAR_5": 85054.82217026287,
    "YEAR_6": 85228.74354677042,
    "YEAR_7": 85402.66492327799,
    "YEAR_8": 85576.58629978554,
    "YEAR_9": 85750.5076762931,
    "YEAR_10": 85924.42905280065,
    "AD_Reve": 851417.8285851665
  },
  {
    "DMA": "Medford-Klamath Falls",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 411975,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 82395,
    "No of HHS for Ads": 205.9875,
    "Revenue per 1000 Devices Hour": 5.25268125,
    "Revenue per 1000 Devices per day": 21.010725,
    "Revenue per month(Video Ad)": 630.3217500000001,
    "Revenue per month (Banner Ad)": 6303.217500000001,
    "Total Revenue per month from all Ads": 6933.539250000001,
    "YEAR_1": 83281.16635154471,
    "YEAR_2": 83452.86530036949,
    "YEAR_3": 83624.56424919429,
    "YEAR_4": 83796.26319801906,
    "YEAR_5": 83967.96214684384,
    "YEAR_6": 84139.66109566865,
    "YEAR_7": 84311.36004449341,
    "YEAR_8": 84483.05899331823,
    "YEAR_9": 84654.757942143,
    "YEAR_10": 84826.4568909678,
    "AD_Reve": 840538.1162125625
  },
  {
    "DMA": "Columbia-Jefferson City",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 408870,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 81774,
    "No of HHS for Ads": 204.435,
    "Revenue per 1000 Devices Hour": 5.213092500000001,
    "Revenue per 1000 Devices per day": 20.852370000000004,
    "Revenue per month(Video Ad)": 625.5711000000001,
    "Revenue per month (Banner Ad)": 6255.711000000001,
    "Total Revenue per month from all Ads": 6881.282100000001,
    "YEAR_1": 82653.48743529605,
    "YEAR_2": 82823.89231230556,
    "YEAR_3": 82994.29718931505,
    "YEAR_4": 83164.70206632456,
    "YEAR_5": 83335.10694333405,
    "YEAR_6": 83505.51182034357,
    "YEAR_7": 83675.91669735308,
    "YEAR_8": 83846.32157436258,
    "YEAR_9": 84016.7264513721,
    "YEAR_10": 84187.13132838158,
    "AD_Reve": 834203.0938183882
  },
  {
    "DMA": "Rockford",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 408240,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 81648,
    "No of HHS for Ads": 204.12,
    "Revenue per 1000 Devices Hour": 5.2050600000000005,
    "Revenue per 1000 Devices per day": 20.820240000000002,
    "Revenue per month(Video Ad)": 624.6072,
    "Revenue per month (Banner Ad)": 6246.072,
    "Total Revenue per month from all Ads": 6870.6792000000005,
    "YEAR_1": 82526.13229286876,
    "YEAR_2": 82696.27460458242,
    "YEAR_3": 82866.41691629606,
    "YEAR_4": 83036.55922800972,
    "YEAR_5": 83206.70153972338,
    "YEAR_6": 83376.84385143704,
    "YEAR_7": 83546.98616315066,
    "YEAR_8": 83717.12847486432,
    "YEAR_9": 83887.27078657797,
    "YEAR_10": 84057.41309829161,
    "AD_Reve": 832917.7269558018
  },
  {
    "DMA": "Duluth-Superior",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 393210,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 78642,
    "No of HHS for Ads": 196.60500000000002,
    "Revenue per 1000 Devices Hour": 5.013427500000001,
    "Revenue per 1000 Devices per day": 20.053710000000002,
    "Revenue per month(Video Ad)": 601.6113,
    "Revenue per month (Banner Ad)": 6016.113,
    "Total Revenue per month from all Ads": 6617.7243,
    "YEAR_1": 79487.8024663897,
    "YEAR_2": 79651.68072033081,
    "YEAR_3": 79815.55897427193,
    "YEAR_4": 79979.43722821306,
    "YEAR_5": 80143.31548215417,
    "YEAR_6": 80307.19373609532,
    "YEAR_7": 80471.07199003642,
    "YEAR_8": 80634.95024397757,
    "YEAR_9": 80798.82849791869,
    "YEAR_10": 80962.7067518598,
    "AD_Reve": 802252.5460912476
  },
  {
    "DMA": "Monroe-El Dorado",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 391365,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 78273,
    "No of HHS for Ads": 195.6825,
    "Revenue per 1000 Devices Hour": 4.989903750000001,
    "Revenue per 1000 Devices per day": 19.959615000000003,
    "Revenue per month(Video Ad)": 598.7884500000001,
    "Revenue per month (Banner Ad)": 5987.884500000001,
    "Total Revenue per month from all Ads": 6586.672950000001,
    "YEAR_1": 79114.83383499556,
    "YEAR_2": 79277.9431477131,
    "YEAR_3": 79441.05246043066,
    "YEAR_4": 79604.16177314821,
    "YEAR_5": 79767.27108586577,
    "YEAR_6": 79930.38039858331,
    "YEAR_7": 80093.48971130086,
    "YEAR_8": 80256.59902401842,
    "YEAR_9": 80419.70833673596,
    "YEAR_10": 80582.81764945351,
    "AD_Reve": 798488.2574222453
  },
  {
    "DMA": "Topeka",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 390082.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 78016.5,
    "No of HHS for Ads": 195.04125,
    "Revenue per 1000 Devices Hour": 4.973551875,
    "Revenue per 1000 Devices per day": 19.8942075,
    "Revenue per month(Video Ad)": 596.826225,
    "Revenue per month (Banner Ad)": 5968.26225,
    "Total Revenue per month from all Ads": 6565.088475,
    "YEAR_1": 78855.57515219718,
    "YEAR_2": 79018.14995699102,
    "YEAR_3": 79180.72476178486,
    "YEAR_4": 79343.29956657872,
    "YEAR_5": 79505.87437137257,
    "YEAR_6": 79668.44917616641,
    "YEAR_7": 79831.02398096026,
    "YEAR_8": 79993.5987857541,
    "YEAR_9": 80156.17359054796,
    "YEAR_10": 80318.74839534181,
    "AD_Reve": 795871.6177376949
  },
  {
    "DMA": "Lubbock",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 385762.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 77152.5,
    "No of HHS for Ads": 192.88125,
    "Revenue per 1000 Devices Hour": 4.918471874999999,
    "Revenue per 1000 Devices per day": 19.673887499999996,
    "Revenue per month(Video Ad)": 590.2166249999999,
    "Revenue per month (Banner Ad)": 5902.166249999999,
    "Total Revenue per month from all Ads": 6492.382874999999,
    "YEAR_1": 77982.28274698164,
    "YEAR_2": 78143.05710403247,
    "YEAR_3": 78303.83146108333,
    "YEAR_4": 78464.60581813417,
    "YEAR_5": 78625.380175185,
    "YEAR_6": 78786.15453223586,
    "YEAR_7": 78946.9288892867,
    "YEAR_8": 79107.70324633757,
    "YEAR_9": 79268.47760338838,
    "YEAR_10": 79429.25196043926,
    "AD_Reve": 787057.6735371044
  },
  {
    "DMA": "Beaumont-Port Arthur",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 380160,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 76032,
    "No of HHS for Ads": 190.08,
    "Revenue per 1000 Devices Hour": 4.84704,
    "Revenue per 1000 Devices per day": 19.38816,
    "Revenue per month(Video Ad)": 581.6448,
    "Revenue per month (Banner Ad)": 5816.448,
    "Total Revenue per month from all Ads": 6398.0928,
    "YEAR_1": 76849.73165896775,
    "YEAR_2": 77008.17106035187,
    "YEAR_3": 77166.61046173603,
    "YEAR_4": 77325.04986312016,
    "YEAR_5": 77483.4892645043,
    "YEAR_6": 77641.92866588842,
    "YEAR_7": 77800.36806727259,
    "YEAR_8": 77958.80746865671,
    "YEAR_9": 78117.24687004086,
    "YEAR_10": 78275.68627142499,
    "AD_Reve": 775627.0896519638
  },
  {
    "DMA": "Palm Springs",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 378472.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 75694.5,
    "No of HHS for Ads": 189.23625,
    "Revenue per 1000 Devices Hour": 4.825524375,
    "Revenue per 1000 Devices per day": 19.3020975,
    "Revenue per month(Video Ad)": 579.062925,
    "Revenue per month (Banner Ad)": 5790.62925,
    "Total Revenue per month from all Ads": 6369.692175,
    "YEAR_1": 76508.60181318042,
    "YEAR_2": 76666.33791466495,
    "YEAR_3": 76824.07401614948,
    "YEAR_4": 76981.810117634,
    "YEAR_5": 77139.54621911854,
    "YEAR_6": 77297.28232060307,
    "YEAR_7": 77455.0184220876,
    "YEAR_8": 77612.75452357212,
    "YEAR_9": 77770.49062505667,
    "YEAR_10": 77928.2267265412,
    "AD_Reve": 772184.1426986081
  },
  {
    "DMA": "Minot-Bismarck-Dickinson-Williston",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 373927.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 74785.5,
    "No of HHS for Ads": 186.96375,
    "Revenue per 1000 Devices Hour": 4.767575625,
    "Revenue per 1000 Devices per day": 19.0703025,
    "Revenue per month(Video Ad)": 572.109075,
    "Revenue per month (Banner Ad)": 5721.090749999999,
    "Total Revenue per month from all Ads": 6293.199825,
    "YEAR_1": 75589.82542852654,
    "YEAR_2": 75745.66730894813,
    "YEAR_3": 75901.50918936971,
    "YEAR_4": 76057.3510697913,
    "YEAR_5": 76213.19295021288,
    "YEAR_6": 76369.03483063445,
    "YEAR_7": 76524.87671105604,
    "YEAR_8": 76680.71859147762,
    "YEAR_9": 76836.56047189921,
    "YEAR_10": 76992.40235232077,
    "AD_Reve": 762911.1389042367
  },
  {
    "DMA": "Anchorage",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 362722.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 72544.5,
    "No of HHS for Ads": 181.36125,
    "Revenue per 1000 Devices Hour": 4.624711875,
    "Revenue per 1000 Devices per day": 18.4988475,
    "Revenue per month(Video Ad)": 554.965425,
    "Revenue per month (Banner Ad)": 5549.65425,
    "Total Revenue per month from all Ads": 6104.619675,
    "YEAR_1": 73324.72325249873,
    "YEAR_2": 73475.89522158692,
    "YEAR_3": 73627.06719067508,
    "YEAR_4": 73778.23915976325,
    "YEAR_5": 73929.41112885143,
    "YEAR_6": 74080.5830979396,
    "YEAR_7": 74231.75506702777,
    "YEAR_8": 74382.92703611594,
    "YEAR_9": 74534.09900520412,
    "YEAR_10": 74685.27097429229,
    "AD_Reve": 740049.9711339552
  },
  {
    "DMA": "Odessa-Midland",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 360450,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 72090,
    "No of HHS for Ads": 180.225,
    "Revenue per 1000 Devices Hour": 4.595737499999999,
    "Revenue per 1000 Devices per day": 18.382949999999997,
    "Revenue per month(Video Ad)": 551.4884999999999,
    "Revenue per month (Banner Ad)": 5514.884999999999,
    "Total Revenue per month from all Ads": 6066.3735,
    "YEAR_1": 72865.33506017181,
    "YEAR_2": 73015.55991872853,
    "YEAR_3": 73165.7847772852,
    "YEAR_4": 73316.0096358419,
    "YEAR_5": 73466.23449439861,
    "YEAR_6": 73616.4593529553,
    "YEAR_7": 73766.684211512,
    "YEAR_8": 73916.9090700687,
    "YEAR_9": 74067.1339286254,
    "YEAR_10": 74217.35878718208,
    "AD_Reve": 735413.4692367695
  },
  {
    "DMA": "Wichita Falls-Lawton",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 350077.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 70015.5,
    "No of HHS for Ads": 175.03875,
    "Revenue per 1000 Devices Hour": 4.463488125,
    "Revenue per 1000 Devices per day": 17.8539525,
    "Revenue per month(Video Ad)": 535.618575,
    "Revenue per month (Banner Ad)": 5356.18575,
    "Total Revenue per month from all Ads": 5891.804324999999,
    "YEAR_1": 70768.52360806575,
    "YEAR_2": 70914.42551657284,
    "YEAR_3": 71060.32742507993,
    "YEAR_4": 71206.22933358702,
    "YEAR_5": 71352.13124209411,
    "YEAR_6": 71498.03315060122,
    "YEAR_7": 71643.93505910829,
    "YEAR_8": 71789.83696761541,
    "YEAR_9": 71935.73887612249,
    "YEAR_10": 72081.64078462958,
    "AD_Reve": 714250.8219634767
  },
  {
    "DMA": "Sioux City",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 349740,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 69948,
    "No of HHS for Ads": 174.87,
    "Revenue per 1000 Devices Hour": 4.459185000000001,
    "Revenue per 1000 Devices per day": 17.836740000000002,
    "Revenue per month(Video Ad)": 535.1022,
    "Revenue per month (Banner Ad)": 5351.022000000001,
    "Total Revenue per month from all Ads": 5886.124200000001,
    "YEAR_1": 70700.2976389083,
    "YEAR_2": 70846.05888743547,
    "YEAR_3": 70991.82013596265,
    "YEAR_4": 71137.58138448981,
    "YEAR_5": 71283.34263301699,
    "YEAR_6": 71429.10388154414,
    "YEAR_7": 71574.86513007132,
    "YEAR_8": 71720.62637859849,
    "YEAR_9": 71866.38762712567,
    "YEAR_10": 72012.14887565286,
    "AD_Reve": 713562.2325728057
  },
  {
    "DMA": "Erie",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 342945,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 68589,
    "No of HHS for Ads": 171.4725,
    "Revenue per 1000 Devices Hour": 4.37254875,
    "Revenue per 1000 Devices per day": 17.490195,
    "Revenue per month(Video Ad)": 524.70585,
    "Revenue per month (Banner Ad)": 5247.058500000001,
    "Total Revenue per month from all Ads": 5771.764350000001,
    "YEAR_1": 69326.68145987135,
    "YEAR_2": 69469.61075413608,
    "YEAR_3": 69612.54004840084,
    "YEAR_4": 69755.46934266559,
    "YEAR_5": 69898.39863693033,
    "YEAR_6": 70041.32793119508,
    "YEAR_7": 70184.2572254598,
    "YEAR_8": 70327.18651972455,
    "YEAR_9": 70470.11581398928,
    "YEAR_10": 70613.04510825404,
    "AD_Reve": 699698.632840627
  },
  {
    "DMA": "Rochester-Mason City-Austin",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 342427.5,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 68485.5,
    "No of HHS for Ads": 171.21375,
    "Revenue per 1000 Devices Hour": 4.365950625,
    "Revenue per 1000 Devices per day": 17.4638025,
    "Revenue per month(Video Ad)": 523.914075,
    "Revenue per month (Banner Ad)": 5239.1407500000005,
    "Total Revenue per month from all Ads": 5763.054825,
    "YEAR_1": 69222.06830716322,
    "YEAR_2": 69364.7819227921,
    "YEAR_3": 69507.49553842095,
    "YEAR_4": 69650.20915404982,
    "YEAR_5": 69792.92276967867,
    "YEAR_6": 69935.63638530753,
    "YEAR_7": 70078.35000093639,
    "YEAR_8": 70221.06361656525,
    "YEAR_9": 70363.77723219412,
    "YEAR_10": 70506.49084782298,
    "AD_Reve": 698642.7957749311
  },
  {
    "DMA": "Joplin-Pittsburg",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 340110,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 68022,
    "No of HHS for Ads": 170.055,
    "Revenue per 1000 Devices Hour": 4.3364025,
    "Revenue per 1000 Devices per day": 17.34561,
    "Revenue per month(Video Ad)": 520.3683,
    "Revenue per month (Banner Ad)": 5203.683,
    "Total Revenue per month from all Ads": 5724.0513,
    "YEAR_1": 68753.58331894864,
    "YEAR_2": 68895.33106938204,
    "YEAR_3": 69037.07881981543,
    "YEAR_4": 69178.82657024883,
    "YEAR_5": 69320.57432068222,
    "YEAR_6": 69462.32207111562,
    "YEAR_7": 69604.06982154901,
    "YEAR_8": 69745.8175719824,
    "YEAR_9": 69887.5653224158,
    "YEAR_10": 70029.3130728492,
    "AD_Reve": 693914.4819589892
  },
  {
    "DMA": "Panama City",
    "PlatformName": "Reve(e)",
    "Total Smart Devices": 339075,
    "Assuming 5 Devices": 5,
    "% Of HH's for Ads": 0.0025,
    "No of Ads in An hour": 3,
    "CPL": 10,
    "% of Revenue after agency commission": 0.85,
    "No of Hours assumed for running Ads": 4,
    "Number of days in month": 30,
    "Revenue per month Banner Ad divide": 10,
    "% increase of Households Monthly": 0.01293,
    "%increse in App Growth Monthly": 0.0133,
    "DMA Consdered": "Y",
    "No of Smart homes assuming 5 devices per home": 67815,
    "No of HHS for Ads": 169.5375,
    "Revenue per 1000 Devices Hour": 4.323206249999999,
    "Revenue per 1000 Devices per day": 17.292824999999997,
    "Revenue per month(Video Ad)": 518.7847499999999,
    "Revenue per month (Banner Ad)": 5187.847499999999,
    "Total Revenue per month from all Ads": 5706.632249999999,
    "YEAR_1": 68544.3570135324,
    "YEAR_2": 68685.67340669403,
    "YEAR_3": 68826.98979985568,
    "YEAR_4": 68968.30619301731,
    "YEAR_5": 69109.62258617894,
    "YEAR_6": 69250.93897934057,
    "YEAR_7": 69392.25537250222,
    "YEAR_8": 69533.57176566386,
    "YEAR_9": 69674.88815882549,
    "YEAR_10": 69816.20455198712,
    "AD_Reve": 691802.8078275976
  }
];





export default jsonData;