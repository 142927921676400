export const moreFeaturesData = {
  moreFeatures: {
    title: "Explore more features in the SwissBorg app",
    cards: [
      {
        imgSrc: "/Images/moreFeatures/moreFeaturesA.svg",
        text: "BORG TOKEN",
      },
      {
        imgSrc: "/Images/moreFeatures/moreFeaturesB.svg",
        text: "SwissBorg Earn",
      },
      {
        imgSrc:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmoreFeatures%2FmoreFeaturesC.webp?alt=media&token=3d2bf6ba-4250-4cd4-aeb4-0baf015a2e51",
        text: "Supported Assets",
      },
      {
        imgSrc:
          "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FmoreFeatures%2FmoreFeaturesD.webp?alt=media&token=47ec456f-24a1-4b68-9c7c-a0afc9dc88a6",
        text: "Rewards Program",
      },
    ],
  },
};
  