import React, { useEffect, useState } from "react";
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
import Style from "./index.module.css";
import ProductExp from "../../components/ProductEndExplaner";
import MapComponent from "./mapcomponent";
import Img from "../../components/Image";
import Carousel from "./CarouselComponent/Carousel";
import Blobs from "../../molecules/blobs";
import ModalForm from "../Form";

import { useLocation } from "react-router-dom";

let Contact = () => {
  let location = useLocation();
  // console.log(location);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    console.log("Sending form data to server...");

    closeModal();
  };
  useEffect(() => {
    if (location.hash === "#openform") {
      setTimeout(() => {
        openModal();
      }, 500);
    }
  }, []);
  return (
    <div className={Style.maindivcontactspage}>
      <Blobs left={"85%"} top={"0%"} color="yellow"></Blobs>
      <Blobs left={"-10%"} top={"10%"}></Blobs>

      <section className={Style.topcont1}>
        <div id={Style.contacthead}>
          <h1>Hola,how can we help?</h1>
          <p>
            Ready to transform your enterprise with digital
            transformation-as-a-service? Get in touch with our expert team today
            and embark on a journey towards unparalleled innovation and success.
          </p>
          {/* <h2>Get in Touch</h2> */}
          <button
            className={Style.btn_touch}
            onClick={() => {
              window.location.href = "/getInTouch";
              // openModal();
            }}
          >
            Get in Touch
          </button>
          <ModalForm
            isOpen={modalIsOpen}
            closeModal={closeModal}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            formData={formData}
          />
          <div className={Style.botconthead}>
            <div>
              <h1>Ready To Help, across borders!</h1>
            </div>
          </div>
        </div>
      </section>

      <section className={Style.botcont}>
        <div id={Style.mapcont}>
          <div className={Style.MapMain}>
            <MapComponent />
          </div>

          <div className={Style.mapcontlocations}>
            <div className={Style.maplocationshead}>Our Locations</div>

            <div className={Style.mapcontent1}>
              <div className={Style.mapcontent1section1}>
                <Img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Flocation%20pin.webp?alt=media&token=f5855445-b17a-4010-84b0-c44a4ee4521f"
                  }
                />
              </div>
              <div className={Style.mapcontent1section2}>
                <div className={Style.section2head}>United States</div>
                <div className={Style.section2desc}>
                  1968 S Coast Hwy, Suite 5011, Laguna Beach, CA 92651, United
                  States
                </div>
                <div className={Style.section2bottom}>
                  <div className={Style.logo}>
                    <Img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Fphone.webp?alt=media&token=df2abe62-8cc2-46a5-addb-57895146889d"
                      }
                    />
                  </div>
                  <div className={Style.num}>+1 (415) 624 7431</div>
                </div>
                <div className={Style.section2bottom}>
                  <div className={Style.logo.envelope}>
                    <Img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Fenvelope.webp?alt=media&token=ec5925dc-391f-4bed-95e6-6e51817304c2"
                      }
                    />
                  </div>
                  <div>info@nexusconnects.ai</div>
                </div>
              </div>
            </div>

            <section className={Style.horizontalLineMain}>
              <div className={Style.horizontalLine}></div>
            </section>

            <div className={Style.mapcontent1}>
              <div className={Style.mapcontent1section1}>
                <Img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Flocation%20pin.webp?alt=media&token=f5855445-b17a-4010-84b0-c44a4ee4521f"
                  }
                />
              </div>
              <div className={Style.mapcontent1section2}>
                <div className={Style.section2head}>India</div>
                <div className={Style.section2desc}>
                  5th Floor, NAVAYUGA VIZVA, GachiBowli, Hyderabad, India, 50032
                </div>
                <div className={Style.section2bottom}>
                  <div className={Style.logo}>
                    <Img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Fphone.webp?alt=media&token=df2abe62-8cc2-46a5-addb-57895146889d"
                      }
                    />
                  </div>
                  <div className={Style.num}>+91 (402) 980 0716</div>
                </div>
                <div className={Style.section2bottom}>
                  <div className={Style.logo.envelope}>
                    <Img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Fenvelope.webp?alt=media&token=ec5925dc-391f-4bed-95e6-6e51817304c2"
                      }
                    />
                  </div>
                  <div>info@nexusconnects.ai</div>
                </div>
              </div>
            </div>

            <section className={Style.horizontalLineMain}>
              <div className={Style.horizontalLine}></div>
            </section>

            <div className={Style.mapcontent1}>
              <div className={Style.mapcontent1section1}>
                <Img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Flocation%20pin.webp?alt=media&token=f5855445-b17a-4010-84b0-c44a4ee4521f"
                  }
                />
              </div>
              <div className={Style.mapcontent1section2}>
                <div className={Style.section2head}>Singapore</div>
                <div className={Style.section2desc}>
                  11 Woodlands Close #07-13, Woodlands 11, Singapore, 737853
                </div>
                <div className={Style.section2bottom}>
                  <div className={Style.logo}>
                    <Img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Fphone.webp?alt=media&token=df2abe62-8cc2-46a5-addb-57895146889d"
                      }
                    />
                  </div>
                  <div className={Style.num}>+65 6587 4666</div>
                </div>
                <div className={Style.section2bottom}>
                  <div className={Style.logo.envelope}>
                    <Img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2Fcontact%2Fenvelope.webp?alt=media&token=ec5925dc-391f-4bed-95e6-6e51817304c2"
                      }
                    />
                  </div>
                  <div>info@nexusconnects.ai</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={Style.CarouselMain}>
        <Carousel></Carousel>
      </div>

      {/* <section className={Style.topcont1}> */}
      <ProductExp
        heading={"Your Trusted Digital Transformation Partner"}
        content={
          "Get in touch with our expert team to understand how Mobius Platform can accelerate your digital transformation journey"
        }
        url={
          "https://calendly.com/d/2sn-wnj-xnc/sales-discovery-call?primary_color=a513a7"
        }
        btn={"Book Now"}
      ></ProductExp>
      {/* </section> */}
    </div>
  );
};

export default Contact;
