import React from "react";
import style from "./index.module.css";
import Img from "../../components/Image";

function ViewProject() {
  return (
    <div className={style.viewContainer}>
      <div className={style.viewContentContainer}>
        <div className={style.viewContent}>
          <div className={style.viewLeft}>
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FviewProject%2FswissBorg.webp?alt=media&token=fe959a9d-6236-456a-a298-de8fa91941fe"></Img>
          </div>
          <div className={style.viewCenter}>
            <div className={style.viewCenterName}>
              <h1>SwissBorg Series A</h1>
              <p>From token holder to community member.</p>
              <p>From community member to business partner.</p>
            </div>
            <div className={style.viewCenterBtn}>
              <button>Ended March 31, 2023</button>
              <button>View project</button>
            </div>
          </div>
          <div className={style.viewRight}>
            <Img src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FviewProject%2Frobo.webp?alt=media&token=2bd4552f-3a4a-47d6-941f-1d67aa54292b"></Img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProject;
