import { useEffect, useRef } from "react";
import st from "./index.module.css";

const ConferenceBanner = () => {
  const EventVideoRef = useRef(null);

  useEffect(() => {
    if (EventVideoRef.current) {
      EventVideoRef.current.controls = false; // Ensure controls are hidden
      EventVideoRef.current.autoplay = true; // Autoplay video
      EventVideoRef.current.loop = true; // Loop the video
    }
  }, []);
  return (
    <div className={st.container}>
      <div className={st.videoContainer}>
        <video playsInline autoPlay muted loop controls ref={EventVideoRef}>
          <source
            // src={process.env.PUBLIC_URL + "/video/test.mp4"}
            src="https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/videos%2FconferanceBanner%2Ftest.mp4?alt=media&token=28bf5a7e-d02e-4497-8806-85ed1833194b"
            type="video/mp4"
          />
        </video>
        <div className={st.contentContainer}>
          <span id={st.head}>
            Meet us at The ATSC NextGen Broadcast Conference 2024
          </span>
          <span id={st.dnt}>
            June 12-14, 2024 | Marriott Marquis Washington, DC
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConferenceBanner;
