import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "process.env.REACT_APP_API_KEY",
  authDomain: "nexusconnects-bfe18.firebaseapp.com",
  projectId: "nexusconnects-bfe18",
  databaseURL: "https://nexusconnects-bfe18-default-rtdb.firebaseio.com/",
  storageBucket: "nexusconnects-bfe18.appspot.com",
  messagingSenderId: "832171023316",
  appId: "1:832171023316:web:b0432dac34fd5d89f39441",
  measurementId: "G-0R33XD8JLP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Firebase database instance
const database = getDatabase(app);

// Get the Firebase storage instance
const storage = getStorage(app);

export { database, storage };
